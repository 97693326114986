import styled from 'styled-components';

export const FaqSection = styled.section`
    padding: 98px 4.8rem 98px;
    max-width: 1440px;
    margin: 0 auto;
    background: #f8fbf6;
    .faq-section {
        max-width: 630px;
        .title-box {
            margin-bottom: 32px;
            .small-title {
                font-size: 19px;
                line-height: 180%;
                color: #6ea44c;
                margin-bottom: 5px;
            }
        }
        .faq-accordion {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 20px;
        }
        .other-info {
            background: white;
            padding: 20px 32px;
            border-radius: 25px;
            font-weight: 500;
            box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
            margin-top: 20px;
            .small-title {
                font-size: 19px;
                line-height: 27px;
            }
            .other-option-list {
                li {
                    margin-top: 10px;
                    .green-link {
                        gap: 16px;
                        .link-icon {
                            font-size: 29px;
                        }
                        &:active {
                            color: #6ea44c;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        padding: 24px 20px;
        .faq-section {
            .title-box {
                text-align: left;
                margin-bottom: 20px;
                h3 {
                    font-size: 25px;
                }
                .small-title {
                    margin-bottom: 0;
                    font-size: 16px;
                    line-height: 180%;
                }
            }
            .faq-accordion {
                margin-top: 10px;
            }
        }
    }
`;

export const SearchBox = styled.div`
    max-width: 630px;
    border-radius: 25px;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
    .form-control {
        width: 100%;
        border-radius: 25px;
        padding: 8px 26px;
        height: 45px;
        &::placeholder {
            color: #004d37;
        }
        border: 1px solid #eaeaea;
    }
    .search-btn {
        right: 0;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        width: auto;
        left: 0;
        transform: none;
    }
`;
