import React from 'react';
import { ImageContainer, StyledContiner, TextContainer, Title, Description, ButtonContainer, Error } from './Style';
import Button from '../../newComponents/Button';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FaSpinner } from 'react-icons/fa';

const ImageDescription = props => {
    const {
        titleStyle, 
        descriptionStyle, 
        imageContainer, 
        textContainer, 
        container,
        handleClick,
        button,
        buttonformattedMessageId,
        error,
        buttonDisable,
        isLoader,
        isDisable
    } = props;
    return props.inverse ? (
        <StyledContiner style={container}>
            <TextContainer style={textContainer}>
                <Title style= {titleStyle} inverse={true}>{props.title}</Title>
                <Description style= {descriptionStyle} inverse={true}>{props.description}</Description>
                {button && <ButtonContainer>
                    <Button
                        onClick={handleClick}
                        variant={isDisable || buttonDisable?"disabled":"filled"}
                        disabled={isDisable || buttonDisable}
                    >
                        {isLoader && isDisable && <FaSpinner style={{animation:"spin infinite 2s linear",marginRight: "8px"}} />}

                        {isLoader && isDisable ? (
                            <FormattedMessage id="MyNaturePage.ActivateRole.Partner.loader" />
                        ) : (
                            <FormattedMessage id={buttonformattedMessageId} />
                        )}
                        
                    </Button>
                </ButtonContainer>}
                {error && error.value && <Error>{error.message}</Error>}
            </TextContainer>
            <ImageContainer style= {imageContainer}>
                <img src={props.src} width="100%" height="100%" />
            </ImageContainer>
        </StyledContiner>
    ) : (
        <StyledContiner style={container}>
            <ImageContainer style= {imageContainer}>
                <img src={props.src} width="100%" height="100%" />
            </ImageContainer>
            <TextContainer style={textContainer}>
                <Title style= {titleStyle} inverse={false}>{props.title}</Title>
                <Description style= {descriptionStyle} inverse={false}>{props.description}</Description>
                {button && <ButtonContainer>
                    <Button
                        onClick={handleClick}
                        variant={isDisable || buttonDisable?"disabled":"filled"}
                        disabled={isDisable || buttonDisable}
                    >
                        {isLoader && isDisable && <FaSpinner style={{animation:"spin infinite 2s linear",marginRight: "8px"}} />}

                        {isLoader && isDisable ? (
                            <FormattedMessage id="MyNaturePage.ActivateRole.Partner.loader" />
                        ) : (
                            <FormattedMessage id={buttonformattedMessageId} />
                        )}
                    </Button>
                </ButtonContainer>}
                {error && error.value && <Error>{error.message}</Error>}
            </TextContainer>
        </StyledContiner>
    );
};

const { string, object, func, bool} =PropTypes;

ImageDescription.propTypes = {
    titleStyle : object,
    descriptionStyle : object, 
    imageContainer : object,
    textContainer : object, 
    container : object,
    handleClick : func,
    button : bool,
    buttonformattedMessageId : string
}

  
ImageDescription.defaultProps = {
    titleStyle : {},
    descriptionStyle : {}, 
    imageContainer : {},
    textContainer : {}, 
    container : {},
    handleClick : ()=> {},
    button : false,
    buttonformattedMessageId : ""
  };

export default ImageDescription;
