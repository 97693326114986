import React, { useState, useRef, useEffect } from 'react';
import {
    AccountContainer,
    AccountForm,
    Title,
    SubTitle,
    Form,
    Row,
    Holder,
    Wrapper,
    Error,
    StyledSuccessMessage,
    Label,
    Input,
    InputButton,
    InputBox,
    ButtonContainer,
    Dropdown,
    DropdownItem,
} from './Styles';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowDown } from '../../../../assets/newAssets/arrow-down.svg';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../newComponents/Button';
import { fetchCurrentUser } from '../../../../ducks/user.duck';
import FileUpload from '../../FileUpload';
import {
    updateOrganization,
    getCurrentOrganization,
    getCurrentUser,
    uploadImage,
    updateUser,
    updateAccount,
    getOrganizationSectors,
} from '../../../../coreApi';
import { STRIPE_SUPPORTED_TAX_COUNTRIES } from './TaxIdTypes';

const validateInputFields = (data, optionalFields) => {
    let isValid = true;
    let allKeys = Object.keys(data);
    for (let key of allKeys) {
        if (!optionalFields.includes(key)) {
            if (!(data[key] != null && data[key] != '')) {
                isValid = false;
                break;
            }
        }
    }
    return isValid;
};
function isTaxIdTypePresent(data, taxIdType) {
    return data.taxIdTypes.some(type => type.id === taxIdType);
}
function getTaxIdType(data, taxIdType) {
    return data.find(type => type.id === taxIdType);
}

function ProfileForm(props) {
    const [isOpenSec, setIsOpenSec] = useState(false);
    const [isOpenRev, setIsOpenRev] = useState(false);
    const [isOpenTaxCountry, setOpenTaxCountry] = useState(false);
    const [isOpenTaxIdTypes, setOpenTaxIdTypes] = useState(false);
    // const [isOpenInv, setIsOpenInv] = useState(false);
    const [selectedOptionSec, setSelectedOptionSec] = useState('');
    const [selectedOptionRev, setSelectedOptionRev] = useState('');
    const [selectedTaxCountry, setSelectedTaxCountry] = useState('');
    const [taxIdType, setSelectedTaxIdType] = useState('');
    const [taxIdPlaceholder, setTaxIdPlaceholder] = useState('');
    // const [selectedOptionInv, setSelectedOptionInv] = useState();
    const [imageData, setImageData] = useState();
    const [userDetails, setUserDetails] = useState({});
    const [sector, setSector] = useState(null);
    const [errorType, setErrorType] = useState(0);
    const [successAccount, setSuccessAccount] = useState(false);
    const [userData, setUserData] = useState({
        organizationName: '',
        country: '',
        city: '',
        state: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        sector: '',
        taxId: '',
        revenue: '',
        inventoryYear: new Date().getFullYear(),
    });
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            const resOrg = await getCurrentOrganization();
            const resUser = await getCurrentUser();

            if (resOrg && resUser) {
                setUserDetails(resUser.data);
                if (resOrg.data[0] && resOrg.data[0].taxIds) {
                    resOrg.data[0].taxIdType = resOrg.data[0].taxIds[0].taxIdType;
                    resOrg.data[0].taxId = resOrg.data[0].taxIds[0].taxId;
                    for (let value of STRIPE_SUPPORTED_TAX_COUNTRIES) {
                        if (isTaxIdTypePresent(value, resOrg.data[0].taxIdType)) {
                            const taxIdTypeObj = getTaxIdType(
                                value.taxIdTypes,
                                resOrg.data[0].taxIdType
                            );
                            setSelectedTaxCountry(value);
                            setSelectedTaxIdType(taxIdTypeObj.id);
                            setTaxIdPlaceholder(taxIdTypeObj.defaultTaxId);
                        }
                    }
                }
                delete resOrg.data[0].taxIds;
                setUserData({ ...userData, ...resOrg.data[0] });
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await getOrganizationSectors().then(response => {
                setSector(response.data);
            });
        };
        fetchData();
    }, []);

    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);
    const containerRef3 = useRef(null);
    const containerRef4 = useRef(null);
    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
            setIsOpenSec(false);
        }
        if (containerRef2.current && !containerRef2.current.contains(e.target)) {
            setIsOpenRev(false);
        }
        if (containerRef3.current && !containerRef3.current.contains(e.target)) {
            setOpenTaxCountry(false);
        }
        if (containerRef4.current && !containerRef4.current.contains(e.target)) {
            setOpenTaxIdTypes(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [containerRef1, containerRef2]);

    const openSector = () => {
        setIsOpenSec(!isOpenSec);
    };
    const openTaxCountries = () => {
        setOpenTaxCountry(value => !value);
    };
    const openTaxIdTypes = () => {
        setOpenTaxIdTypes(value => !value);
    };
    const openRevenue = () => {
        setIsOpenRev(!isOpenRev);
    };
    // const openInv = () => {
    //     setIsOpenInv(!isOpenInv);
    // };
    function onSelectedSec(value) {
        setSelectedOptionSec(value);
        setIsOpenSec(false);
        setUserData({ ...userData, sector: value });
    }
    function onSelectedRev(value) {
        setSelectedOptionRev(value);
        setIsOpenRev(false);
        setUserData({ ...userData, revenue: value });
    }
    function onSelectedTaxCountry(value) {
        setSelectedTaxCountry(value);
        setSelectedTaxIdType('');
        setOpenTaxCountry(false);
    }
    function onSelectedTaxIdType(value) {
        setSelectedTaxIdType(value.id);
        setOpenTaxIdTypes(false);
        setUserData({ ...userData, taxIdType: value.id });
        setTaxIdPlaceholder(value.defaultTaxId);
    }
    // function onSelectedInv(value) {
    //     setSelectedOptionInv(value);
    //     setIsOpenInv(false);
    //     setUserData({ ...userData, inventoryYear: value });
    // }
    const revenue = [
        'Micro-org/ Start-up: <US$1 million',
        'Small org: <US$10 million',
        'Medium org: <US$ 100 million',
        'Large org: >US$ 100 million',
    ];
    const year = ['2018', '2019', '2020', '2021'];
    let name, value;
    const handleChange = e => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    };

    const postData = async e => {
        const newOrganizationDetails = {
            organizationName: userData.organizationName,
            sector: userData.sector ? userData.sector : selectedOptionSec,
            revenue: userData.revenue ? userData.revenue : selectedOptionRev,
            // otherDetails: userData.otherDetails,
            city: userData.city,
            country: userData.country,
            inventoryYear: userData.inventoryYear,
            state: userData.state ? userData.state : '',
            postalCode: userData.postalCode ? userData.postalCode : '',
            addressLine1: userData.addressLine1 ? userData.addressLine1 : '',
            addressLine2: userData.addressLine2 ? userData.addressLine2 : '',
            taxIdType: userData.taxId ? userData.taxIdType : '',
            taxId: userData.taxId ? userData.taxId : '',
            // inventoryYear: userData.inventoryYear ? userData.inventoryYear : selectedOptionInv,
        };

        if (imageData) {
            const send = await uploadImage(imageData);
            const newUserDetails = {};
            if (send.data && send.data.fileId) {
                newUserDetails.profilePicture = send.data.fileId;
            }
            await updateAccount(newUserDetails).then(() => {
                dispatch(fetchCurrentUser());
            });
        }
        const res = await updateOrganization(newOrganizationDetails)
            .then(response => {
                setSuccessAccount(true);
            })
            .catch(error => {
                if (error.response.data === 'Organization already present') {
                    setErrorType(2);
                } else if (error.response.data === 'invalidTaxId') {
                    setErrorType(3);
                } else {
                    setErrorType(1);
                }
                setSuccessAccount(false);
            });
    };
    const OrgPlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.Name.Placeholder',
    });
    const CountryPlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.Country.Placeholder',
    });
    const CityPlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.City.Placeholder',
    });
    const StatePlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.State.Placeholder',
    });
    const line1Placeholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.Line1.Placeholder',
    });
    const line2Placeholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.Line2.Placeholder',
    });
    const postalCodePlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.PostalCode.Placeholder',
    });
    const RevenuePlaceholder = props.intl.formatMessage({
        id: 'OurNaturePage.Profile.Organisation.Revenue.Placeholder',
    });

    const mandatoryTaxIdTypePlaceholder = props.intl.formatMessage({
        id: 'OrganisationCalculator.Error.MandatoryTaxIdType',
    });
    const SuccessMessage = (
        <StyledSuccessMessage>
            {' '}
            <FormattedMessage id="OurNaturePage.Profile.Success" />{' '}
        </StyledSuccessMessage>
    );
    return (
        <AccountForm>
            <Title>
                <FormattedMessage id="OurNaturePage.Profile.ProfileTitle" />
            </Title>
            <AccountContainer>
                <SubTitle>
                    <FormattedMessage id="OurNaturePage.Profile.ProfileSubTitle" />
                </SubTitle>
                <Wrapper>
                    <FileUpload
                        picture={Object.entries(userDetails) != 0 && userDetails.profilePicture}
                        imgData={data => {
                            setImageData(data);
                        }}
                    />
                </Wrapper>
                <Form>
                    <Row>
                        <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Name" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={OrgPlaceholder}
                                name="organizationName"
                                value={userData.organizationName}
                                onChange={handleChange}
                            />
                        </Holder>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Country" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={CountryPlaceholder}
                                name="country"
                                value={userData.country}
                                onChange={handleChange}
                            />
                        </Holder>
                        <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.City" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={CityPlaceholder}
                                name="city"
                                value={userData.city}
                                onChange={handleChange}
                            />
                        </Holder>
                        <Holder>
                            <Label htmlFor="state">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.State" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={StatePlaceholder}
                                name="state"
                                value={userData.state}
                                onChange={handleChange}
                            />
                        </Holder>
                    </Row>
                    <Row>
                        <Holder>
                            <Label htmlFor="addressLine1">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Line1" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={line1Placeholder}
                                name="addressLine1"
                                value={userData.addressLine1}
                                onChange={handleChange}
                            />
                        </Holder>
                        <Holder>
                            <Label htmlFor="addressLine2">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Line2" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={line2Placeholder}
                                name="addressLine2"
                                value={userData.addressLine2}
                                onChange={handleChange}
                            />
                        </Holder>
                        <Holder>
                            <Label htmlFor="postalCode">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.PostalCode" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={postalCodePlaceholder}
                                name="postalCode"
                                value={userData.postalCode}
                                onChange={handleChange}
                            />
                        </Holder>
                    </Row>
                    <Row>
                        <Holder medium>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Sector" />
                            </Label>
                            <InputButton name="sector" onClick={openSector}>
                                {selectedOptionSec || (userData && userData.sector)}{' '}
                                <ArrowDown className="arrow" width="34" height="34" />
                            </InputButton>
                            {isOpenSec && (
                                <Dropdown ref={containerRef1}>
                                    {sector &&
                                        sector.map((item, i) => (
                                            <DropdownItem
                                                key={i}
                                                onClick={() => onSelectedSec(item)}
                                            >
                                                {item}
                                            </DropdownItem>
                                        ))}
                                </Dropdown>
                            )}
                        </Holder>
                        <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.Revenue" />
                            </Label>
                            <InputButton
                                name="revenue"
                                placeholder={RevenuePlaceholder}
                                onClick={openRevenue}
                            >
                                {selectedOptionRev || (userData && userData.revenue)}{' '}
                                <ArrowDown className="arrow" width="34" height="34" />
                            </InputButton>
                            {isOpenRev && (
                                <Dropdown ref={containerRef2}>
                                    {revenue.map((item, i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedRev(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )}
                        </Holder>

                        {/* <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.InventoryYear" />
                            </Label>
                            <InputButton name="inventoryYear" onClick={openInv}>
                                {selectedOptionInv || (userData && userData.inventoryYear)}{' '}
                                <ArrowDown className="arrow" width="34" height="34" />
                            </InputButton>
                            {isOpenInv && (
                                <Dropdown ref={containerRef3}>
                                    {year.map((item, i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedInv(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )}
                        </Holder> */}
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Holder medium>
                            <Label htmlFor="taxCountry">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.TaxCountry" />
                            </Label>
                            <InputButton name="taxCountry" onClick={openTaxCountries}>
                                {selectedTaxCountry.country}
                                <ArrowDown className="arrow" width="34" height="34" />
                            </InputButton>
                            {isOpenTaxCountry && (
                                <Dropdown ref={containerRef3}>
                                    {STRIPE_SUPPORTED_TAX_COUNTRIES.map((element, i) => (
                                        <DropdownItem
                                            key={i}
                                            onClick={() => onSelectedTaxCountry(element)}
                                        >
                                            {element.country}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )}
                        </Holder>
                        <Holder medium>
                            <Label htmlFor="taxIdType">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.TaxIdType" />
                            </Label>
                            <InputButton name="taxIdType" onClick={openTaxIdTypes}>
                                {taxIdType}
                                <ArrowDown className="arrow" width="34" height="34" />
                            </InputButton>
                            {isOpenTaxIdTypes && (
                                <Dropdown ref={containerRef4}>
                                    {selectedTaxCountry &&
                                        selectedTaxCountry.taxIdTypes.map((element, i) => (
                                            <DropdownItem
                                                key={i}
                                                onClick={() => onSelectedTaxIdType(element)}
                                            >
                                                {element.id}
                                            </DropdownItem>
                                        ))}
                                </Dropdown>
                            )}
                        </Holder>
                    </Row>
                    <Row>
                        <Holder>
                            <Label htmlFor="taxId">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.TaxId" />
                            </Label>
                            <Input
                                type="text"
                                placeholder={
                                    !taxIdType ? mandatoryTaxIdTypePlaceholder : taxIdPlaceholder
                                }
                                name="taxId"
                                disabled={!taxIdType}
                                value={userData.taxId}
                                onChange={handleChange}
                            />
                        </Holder>
                    </Row>

                    {/*<Row style={{ marginTop: '20px' }}>*/}
                    {/*    <Holder>*/}
                    {/*        <Label htmlFor="name">*/}
                    {/*            <FormattedMessage id="OurNaturePage.Profile.Organisation.OtherDetails" />*/}
                    {/*        </Label>*/}
                    {/*        <InputBox*/}
                    {/*            type="text"*/}
                    {/*            name="otherDetails"*/}
                    {/*            onChange={handleChange}*/}
                    {/*            value={userData.otherDetails}*/}
                    {/*        ></InputBox>*/}
                    {/*    </Holder>*/}
                    {/*</Row>*/}
                    <Error>
                        {errorType == 1 ? (
                            <FormattedMessage id="OrganisationCalculator.Error.Field" />
                        ) : errorType == 2 ? (
                            <FormattedMessage id="OrganisationCalculator.Error.OrganisationPresent" />
                        ) : errorType == 3 ? (
                            <FormattedMessage id="OrganisationCalculator.Error.InvalidTaxId" />
                        ) : null}
                    </Error>
                    <ButtonContainer>
                        {validateInputFields(userData, [
                            'state',
                            'addressLine1',
                            'addressLine2',
                            'postalCode',
                            'taxIdType',
                            'taxId',
                            'otherDetails',
                        ]) ? (
                            <Button onClick={postData} variant="filled" size="lg">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.UpdateUser" />
                            </Button>
                        ) : (
                            <Button variant="disabled" size="lg">
                                <FormattedMessage id="OurNaturePage.Profile.Organisation.UpdateUser" />
                            </Button>
                        )}
                    </ButtonContainer>
                    {successAccount && SuccessMessage}
                </Form>
            </AccountContainer>
        </AccountForm>
    );
}

export default ProfileForm;
