import styled from 'styled-components';

export const GuardianRoleSection = styled.div`
    max-width: 420px;
    .title {
        font-size: 19px;
        line-height: 27px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    .title-box {
        margin-bottom: 12px;
        .small-title {
            margin-bottom: 20px;
        }
        .choose-title {
            font-weight: 600;
        }
    }
    .guardian-role {
        margin-bottom: 8px;
    }
    .radio-box {
        padding-left: 0;
        input:checked + .guardian-role {
            background: #6ea44c;
            color: white;
            border-radius: 8px;
            .green-link {
                color: white;
            }
        }
    }

    @media (max-width: 1138px) {
        padding: 0 20px;
        margin-top: 45px;
        margin: 0 auto;
        .title {
            font-size: 16px;
        }
    }
`;
