import React from 'react';
import heroimage from '../../assets/heroFaq.jpg';
import {
    ExternalLink,
    Footer,
    Hero,
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
} from '../../components';
import { StaticPage } from '../../containers';
import css from './FaqsPage.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';

const PortugueseFaq = props => {
    return (
        <StaticPage
            title="Perguntas mais frequentes"
            esquema={{
                '@context': 'http://schema.org',
                '@type': 'AboutPage',
                descrição: 'Qual é a pegada ambiental?',
                nome: 'Perguntas mais frequentes',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <Header
                        transparent
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain>
                    <Hero size="medium" title="Perguntas Frequentes" image={heroimage} />
                    <div id="huellaambiental" className={css.sectionWhite}>
                        <section className={css.sectionContent}>
                            <h2> Qual é a pegada ambiental? </h2>
                            <p>
                                {' '}
                                É o efeito que cada pessoa, organização ou produto causa ao longo do
                                seu ciclo de vida. Por exemplo, em uma pessoa, a pegada ambiental é
                                a soma da energia que consomem, a quantidade de emissões de CO2 que
                                geram, a água que consomem, os resíduos que produzem, entre outros.
                                No caso de um produto, como um hambúrguer, sua pegada ambiental é
                                tudo o que a natureza precisa para produzi-lo: água, ração para
                                gado, energia, produção e transporte.{' '}
                            </p>
                            <p>
                                {' '}
                                De acordo com o tipo de recurso, existem diferentes tipos de pegadas
                                e como medi-las:{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    <h3> Pegada de carbono: </h3>
                                    <p>
                                        {' '}
                                        Mede a quantidade de emissões de CO2 que produzimos na
                                        atmosfera como parte de nossas atividades diárias ou nos
                                        processos de produção. É medido em toneladas de dióxido de
                                        carbono equivalente (tCO2eq). Se você quiser saber sua
                                        pegada de carbono, pode visitar estas calculadoras:{' '}
                                    </p>
                                    <ul className={css.list}>
                                        <li>
                                            {' '}
                                            <ExternalLink href="https://libelula.com.pe/descubre/">
                                                {' '}
                                                https://libelula.com.pe/descubre/{' '}
                                            </ExternalLink>{' '}
                                        </li>
                                        <li>
                                            {' '}
                                            <ExternalLink href="https://calculator.carbonfootprint.com">
                                                {' '}
                                                https://calculator.carbonfootprint.com{' '}
                                            </ExternalLink>{' '}
                                            (Dica: escolha a versão em espanhol){' '}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3> Pegada hídrica: </h3>
                                    <p>
                                        {' '}
                                        Refere-se à quantidade de água doce que consumimos no dia a
                                        dia (uso direto), bem como a que é necessária para produzir
                                        os bens que consumimos (uso indireto). Pode ser expresso em
                                        litros de água por dia para pessoas e em metros cúbicos para
                                        empresas e organizações. Se você quiser saber sua pegada
                                        hídrica, pode visitar esta calculadora da Autoridade
                                        Nacional de Águas (ANA):{' '}
                                    </p>
                                </li>
                                <li>
                                    <h3> Pegada de resíduos sólidos: </h3>
                                    <p>
                                        {' '}
                                        É a quantidade de resíduos orgânicos e inorgânicos que você
                                        produz por dia como parte de suas atividades. É expresso em
                                        quilos por dia. No Peru, uma pessoa média produz um quilo de
                                        lixo todos os dias.{' '}
                                        <ExternalLink href="http://www.vidasostenible.org/ciudadanos/mide-tu-huella-ecologica/encuesta-del-residuos-y-materiales/">
                                            {' '}
                                            Nesta página você pode calcular a quantidade de resíduos
                                            que você produz{' '}
                                        </ExternalLink>{' '}
                                    </p>
                                </li>
                                <li>
                                    <h3> Pegada ecológica: </h3>
                                    <p>
                                        {' '}
                                        É uma ferramenta de contabilidade de recursos naturais
                                        baseada em uma abordagem abrangente. Compare a demanda por
                                        recursos naturais de nossa sociedade com a capacidade da
                                        natureza de satisfazer essa demanda; seu objetivo é mostrar
                                        os ativos ecológicos disponíveis e onde estão sendo
                                        utilizados. Essa ferramenta é vital para lidar com os riscos
                                        de nosso atual uso excessivo de recursos em nível nacional e
                                        global.{' '}
                                        <ExternalLink href="https://www.footprintcalculator.org/">
                                            {' '}
                                            Na página da Global Footprint Network, você pode medir
                                            sua pegada ecológica{' '}
                                        </ExternalLink>{' '}
                                        (escolha a versão em espanhol){' '}
                                    </p>
                                </li>
                            </ul>
                            <p>
                                {' '}
                                Cada pegada nos dá indicações sobre o efeito de nossos costumes e
                                comportamentos sobre os recursos e a saúde do planeta. Meça sua
                                pegada frequentemente com as diferentes calculadoras e crie um
                                registro de como ela está evoluindo.{' '}
                            </p>
                        </section>
                    </div>

                    <div id="serviciosnaturaleza">
                        <section className={css.sectionContent}>
                            <h2> O que são serviços naturais? </h2>
                            <p>
                                {' '}
                                Vamos imaginar a Terra como uma enorme fábrica de bens e serviços
                                que todos usamos: água limpa, ar puro, terra estável para viver,
                                alimentos, recursos para a produção, espaços verdes onde podemos
                                relaxar, montanhas e montanhas cobertas de neve que fazem parte do
                                visão de mundo das populações indígenas. Todos eles - e outros - são
                                chamados de serviços da natureza, ambientais ou ecossistêmicos.{' '}
                            </p>

                            <p>
                                {' '}
                                A sociedade e a economia não existiriam sem os bens e serviços que
                                os ecossistemas naturais nos fornecem. Por exemplo, se os
                                ecossistemas perdessem a capacidade de regular o ciclo da água, ou
                                se as abelhas desaparecessem impedindo a polinização natural das
                                plantas, a vida se tornaria mais difícil para quem a habita,
                                desencadeando até uma crise global de econômico.{' '}
                            </p>

                            <p>
                                {' '}
                                Em 2005, a Avaliação Ecossistêmica do Milênio definiu os serviços da
                                natureza como “os benefícios que as pessoas obtêm dos ecossistemas”
                                e propôs uma classificação para eles: sustentadores, reguladores,
                                provisionadores e culturais.{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <strong> Serviços de provisão </strong> são aqueles que fornecem
                                    recursos para uso direto de seres vivos, como água, alimentos,
                                    madeira, fibras vegetais, combustível, necessários para as
                                    atividades diárias.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Serviços de regulação </strong> são aqueles que mantêm
                                    o planeta e seus recursos em funcionamento adequado, como
                                    controle do clima, circulação da água e controle da erosão.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Serviços culturais </strong> são aqueles que nos
                                    fornecem valores espirituais, recreação, educação. Por exemplo,
                                    uma paisagem bem preservada permite-nos usufruí-la em várias
                                    atividades como o turismo e desportos, além de ser parte
                                    essencial da religião de muitas pessoas.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Serviços de manutenção </strong> são responsáveis
                                    ​​pela reciclagem de nutrientes, formação do solo e
                                    fotossíntese. Como o nome indica, eles são os responsáveis ​​por
                                    'sustentar' as funções básicas do planeta.{' '}
                                </li>
                            </ul>
                            <p>
                                {' '}
                                Apesar de sua vital importância, nossa sociedade e nossa economia
                                não reconhecem os serviços que a natureza nos oferece e, portanto,
                                muitos deles estão em processo de degradação.{' '}
                            </p>
                            <p>
                                {' '}
                                Existem maneiras de garantir que esses serviços sejam mantidos e a
                                natureza restaurada. Chamamos isso de esquemas de compensação ou
                                incentivos para serviços ecossistêmicos. Trata-se de formar alianças
                                entre os usuários dos serviços e aqueles que os mantêm por meio de
                                seu trabalho de conservação e sistemas de produção sustentáveis. O
                                serviço prestado pela Regenera é uma forma de compensação
                                compensando a pegada de carbono.{' '}
                            </p>
                        </section>
                    </div>

                    <div id="reducirhuella" className={css.sectionWhite}>
                        <section className={css.sectionContent}>
                            <h2> Como posso reduzir minha pegada ambiental? </h2>
                            <p>
                                {' '}
                                Existem muitas maneiras de contribuir para a redução de nossa pegada
                                ambiental. Alguns exigem uma mudança profunda em nossos hábitos,
                                enquanto outros exigem apenas um pouco de ordem em nossa rotina. Em
                                termos gerais, devemos revisar nosso padrão de consumo e torná-lo
                                mais eficiente.{' '}
                            </p>

                            <p>
                                {' '}
                                Aqui está uma lista de mudanças e ações que você pode realizar para
                                ter uma pegada menor:{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <h3> Coma menos carne </h3> É difícil para muitos, mas leve em
                                    consideração que a indústria da carne gera quase um terço dos
                                    gases de efeito estufa globais. Levar uma vida vegetariana ou
                                    vegana é o mais recomendado, mas se você não está disposto a
                                    parar de comer carne, tente reduzir seu consumo para uma ou duas
                                    vezes por semana, evitando as carnes vermelhas, que são as que
                                    têm o impacto ambiental mais negativo .{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Economize energia </h3> Avalie seu consumo de energia em
                                    casa e no trabalho. Use a tecnologia LED para iluminar, desligue
                                    os aparelhos que não estiver usando, evite manter carregados os
                                    aparelhos que precisam ser carregados. E se estiver ao seu
                                    alcance, adquira fontes renováveis ​​de energia, como painéis
                                    solares. Se você gerencia uma grande empresa, informe-se sobre
                                    energia renovável com seu fornecedor de eletricidade.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Mova-se sem motores </h3> Economizar combustíveis fósseis é
                                    um ponto importante para reduzir nossa pegada ambiental. Se você
                                    tem um carro, use-o com responsabilidade, organize bem sua
                                    programação diária para evitar viagens desnecessárias. Use gás
                                    natural, se possível. Tente se deslocar de bicicleta, andar
                                    mais, usar transporte público. Se não pode ficar sem o carro,
                                    organize uma carona, ou seja, circule em grupos, economizando
                                    combustível. Se você é um usuário frequente de aviões, tente
                                    reduzir seus voos ao máximo.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Consumir com responsabilidade </h3> Parte do problema atual
                                    é que nossos padrões de consumo explodiram, fazendo com que as
                                    empresas produzissem cada vez mais. Faça da sua moda usar roupas
                                    e equipamentos até que eles se esgotem, não os troque se ainda
                                    funcionarem bem.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Cuidado com o que você come </h3> Em média, uma família
                                    desperdiça 30% de sua comida. Compre sua comida com
                                    responsabilidade, cozinhe o que for necessário. Dividir sua
                                    comida ajudará a minimizar as sobras. Você verá como isso
                                    impacta positivamente sua economia (e o planeta).{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Compre local </h3> Sempre que possível, compre alimentos
                                    frescos, de preferência de produtores locais. Em muitas cidades
                                    há uma grande variedade de feiras que vendem produtos orgânicos,
                                    explore sua cidade em sua pesquisa.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Elimine o plástico descartável </h3> Garrafas, canudos,
                                    sacolas, utensílios de cozinha, elimine-os de sua vida. Existem
                                    inúmeras alternativas no mercado para suplantá-los. Faça dessa
                                    prática um hábito, é mais fácil do que você pensa.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Reciclar </h3> Separe o lixo e recicle tudo o que puder:
                                    plástico, papel, vidro, baterias, eletrodomésticos, baterias,
                                    tudo tem um posto de reciclagem na sua cidade. Se você quiser
                                    dar um passo adiante, faça compostagem de seu lixo orgânico.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Cuidar da água </h3> Este é o elemento essencial para a
                                    vida e é o que mais escasseará com o avanço da crise climática.
                                    Tome banho apenas por 5 minutos, feche a torneira ao escovar os
                                    dentes, ao lavar o banheiro, torne seu sistema de lavagem de
                                    roupas mais eficiente, verifique constantemente seus canos e
                                    sistemas em sua casa e escritório para possíveis vazamentos.{' '}
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="trasnformasHuella">
                        <section className={css.sectionContent}>
                            <h2>
                                {' '}
                                Como posso transformar minha pegada ambiental em ações positivas?{' '}
                            </h2>
                            <p>
                                {' '}
                                Não é suficiente reduzir a nossa pegada ambiental, é também
                                essencial tomar medidas concretas para ter um impacto líquido
                                positivo no planeta. Aqui estão algumas ideias para começar:{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <h3> Plante árvores ou ajude a restaurar a natureza </h3> As
                                    árvores são o melhor mecanismo para capturar o carbono do ar.
                                    Procure em sua localidade organizações que apóiem ​​o
                                    reflorestamento de áreas degradadas e participe como voluntário.
                                    Se você tiver espaço suficiente, plante árvores em seu jardim,
                                    na porta de sua casa. Junte-se a seus vizinhos e transforme seu
                                    bairro ou vizinhança com árvores. Além de capturar carbono, as
                                    árvores criam um microclima temperado nos meses quentes,
                                    estabilizam o solo, melhoram a decoração e criá-los é uma grande
                                    alegria para você.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3> Cuide do mar </h3> Sabemos que os oceanos - que cobrem 71%
                                    da superfície da Terra - estão ameaçados pela poluição e pelo
                                    aquecimento global. Ao visitar a praia, não jogue lixo e deixe o
                                    espaço que ocupou o mais limpo possível. Lembre-se que os ralos
                                    das principais cidades terminam no mar, muitas vezes sem
                                    tratamento prévio, por isso pense bem na descarga dos vasos
                                    sanitários e das pias. O óleo de cozinha usado é um forte
                                    contaminante. Se você puder, participe de campanhas de limpeza
                                    de praia, existem muitas iniciativas ativas.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3>
                                        {' '}
                                        Apoie áreas protegidas ou proteja a natureza em sua
                                        localidade{' '}
                                    </h3>{' '}
                                    Se você mora perto de áreas naturais protegidas, pode ser um
                                    guarda florestal voluntário ou participar das atividades que a
                                    sede da área organiza de acordo com suas habilidades ou
                                    profissão, como vigilância, restauração, monitoramento, entre
                                    outros. Das cidades também podemos apoiar com trabalho
                                    voluntário em campanhas específicas e quando as visitamos como
                                    turistas respeitando a natureza e as indicações dos
                                    guardas-florestais. Se em sua localidade houver áreas naturais
                                    desprotegidas ou degradadas, você pode se juntar a seus vizinhos
                                    para apoiar seus cuidados ou restauração.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <h3>
                                        {' '}
                                        Escolha um dos planos da Regenera para apoiar a população
                                        rural no cuidado de suas terras{' '}
                                    </h3>{' '}
                                    Em todo o mundo, há pessoas que trabalham todos os dias para
                                    proteger e restaurar espaços naturais - florestas, pântanos,
                                    pastagens - que prestam serviços à bacia hidrográfica e ao país
                                    onde estão inseridos. Regenera quantifica esses serviços e
                                    conecta esses residentes e comunidades rurais com pessoas e
                                    empresas que desejam reconhecer seus esforços.{' '}
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="comofunciona" className={css.sectionWhite}>
                        <section className={css.sectionContent}>
                            <h2> Como funciona o Regenera? </h2>
                            <p>
                                {' '}
                                Na Regenera, trabalhamos com parceiros urbanos para medir, reduzir e
                                compensar sua pegada ambiental, apoiando tutores rurais na
                                conservação e restauração da natureza no Peru. Assim, contribuímos
                                para a solução do problema da degradação dos ecossistemas e das
                                mudanças climáticas, em consonância com as políticas e valores do
                                nosso país. 80% do pagamento da indenização é direcionado aos nossos
                                tutores rurais e aliados técnicos, assim divididos:{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <strong>
                                        {' '}
                                        60% para comunidades e proprietários privados{' '}
                                    </strong>{' '}
                                    que realizam trabalhos de conservação, proteção e restauração em
                                    suas terras.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> 20% para parceiros técnicos, organizações </strong> que
                                    trabalham em conjunto com os tutores e garantem o cumprimento
                                    dos acordos.{' '}
                                </li>
                            </ul>

                            <h3> É assim que trabalhamos com parceiros urbanos </h3>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <strong> Saber: </strong> ajudamos pessoas, organizações e
                                    empresas a medir sua pegada de carbono, seja por meio de uma
                                    calculadora aberta ou de um inventário detalhado de acordo com
                                    uma metodologia de medição apropriada para cada caso.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Melhoria: </strong> sugerimos maneiras de reduzir as
                                    emissões e, em seguida, compensar a pegada residual com ações de
                                    conservação e reflorestamento realizadas por nossos
                                    responsáveis.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Compartilhamento: </strong> transferimos 80% da receita
                                    de compensação, seja pessoal ou empresarial, para nossos tutores
                                    rurais.{' '}
                                </li>
                            </ul>

                            <h3> É assim que trabalhamos com tutores rurais e aliados técnicos </h3>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <strong> Saber: </strong> guardiões rurais - que têm títulos e
                                    direitos claros sobre seus territórios - são aconselhados a
                                    aprender sobre o carbono e outros ativos naturais em seus
                                    ecossistemas.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Melhoria: </strong> ações de organização, fiscalização,
                                    restauração e produção sustentável são apoiadas. Estabelecemos
                                    um esquema de incentivos para os resultados do desmatamento
                                    evitado e restauração de ecossistemas. Fazemos uma avaliação
                                    mensal e anual do trabalho, que é discutida e aprovada em sua
                                    Assembleia Comunitária ou de Associados.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Compartilhamento: </strong> o pagamento que os tutores
                                    recebem é investido em: (1) pagamento de salários / salários
                                    comunitários, (2) prioridades comunitárias, como educação, saúde
                                    e energia, e (3) necessidades familiares de acordo com o acordo
                                    de montagem.{' '}
                                </li>
                            </ul>

                            <h3>
                                {' '}
                                É assim que nos alinhamos com os Objetivos de Desenvolvimento
                                Sustentável (ODS){' '}
                            </h3>
                            <p>
                                {' '}
                                Trabalhar com a Regenera é uma forma concreta de trabalhar em
                                direção aos Objetivos de Desenvolvimento Sustentável da ONU, pois
                                estamos alinhados para atingir os seguintes objetivos:{' '}
                            </p>
                            <ul className={css.list}>
                                <li>
                                    {' '}
                                    <strong> Fim da pobreza: </strong> estamos dando valor ao
                                    capital natural renovável, um bem das populações rurais pobres.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Igualdade de gênero: </strong> trabalhamos com
                                    comunidades e organizações que promovem a igualdade de gênero.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Água potável e saneamento: </strong> contribuímos para
                                    a regulamentação e segurança da água em várias paisagens.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Energia não poluente: </strong> promovemos o uso de
                                    energia renovável.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong>
                                        {' '}
                                        Trabalho decente e crescimento econômico:{' '}
                                    </strong>{' '}
                                    criamos empregos para as populações rurais{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Ação para o clima: </strong> ajudamos a reduzir as
                                    emissões e capturar carbono nos ecossistemas.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Vida subaquática: </strong> protegemos rios e lagos, e
                                    estamos iniciando trabalhos em ecossistemas marinhos-costeiros.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Vida terrestre: </strong> cuidamos de mais de 50.000
                                    hectares sob acordos de conservação e restauração.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <strong> Parcerias para os ODS: </strong> promovemos parcerias
                                    público-privadas comunitárias.{' '}
                                </li>
                            </ul>
                        </section>
                    </div>
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(PortugueseFaq));
