import { Link } from 'react-router-dom';
import styled from 'styled-components';
import thumbprint from '../../assets/newAssets/thumbprint.svg';

export const Testimony = styled.section`
    background-color: #000000;
    box-sizing: border-box;
    padding: 40px;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 500px) {
        padding: 40px 0px;
    }
`;

export const SectionContent = styled.div`
    width: 100%;
    box-sizing: content-box;
`;

export const Heading = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 5px;
    margin-top: 20px;
    color: #ffffff;
`;

export const TestimonialsTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 38.4px;
    line-height: 52px;
    margin: 5px;
    text-align: center;

    color: #7bb548;
`;

export const TestimonyCard = styled.div`
    text-align: center;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
`;

export const TestimonyListDesktop = styled.div`
    margin: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;
export const TestimonyListMobile = styled.div`
    margin: 40px 10px;
    box-sizing: border-box;
    @media screen and (min-width: 1000px) {
        display: none;
    }
`;

export const TestimonyPic = styled.img`
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 100%;
    object-position: top;
`;

export const TestimonyDescription = styled.p`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
`;

export const TestimonyPeople = styled.p`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #ffffff;
`;
export const TestimonyPeopleName = styled.span`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #ffffff;
`;

export const Communtiy = styled.section`
    box-sizing: border-box;
    padding: 40px;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 500px) {
        padding-top: 0px;
    }
`;

export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 38.4px;
    line-height: 52px;
    text-align: center;
    padding: 20px 0px;
    color: #000000;
    @media screen and (max-width: 500px) {
        font-size: 25px;
        line-height: 34px;
    }
`;

export const CommunityList = styled.div`
    margin: 100px 80px 150px 80px;
    & > div:nth-child(1) > div:nth-child(3) {
        bottom: -80px;
    }
    @media screen and (max-width: 500px) {
        margin: 50px 0px 70px 0px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Guide = styled.section`
    margin: 60px 7% 0px 7%;
    box-sizing: border-box;
    text-align: center;
    @media screen and (max-width: 800px) {
        margin: 30px 3% 0px 3%;
    }
`;

export const StyledContainerDesktop = styled.div`
    background-image: url(${thumbprint});
    background-position: center;
    width: 100%;
    margin: 40px 0px;
    padding: 40px 5%;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        padding: 20px 0px;
        margin: 20px 0px;
        display: none;
    }
`;

export const StyledContainerDeskTopWithoutBgImage = styled.div`
    background-position: center;
    width: 100%;
    margin: 40px 0px;
    padding: 40px 5%;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        padding: 20px 0px;
        margin: 20px 0px;
        display: none;
    }
`;

export const StyledContainerMobile = styled.div`
    background-image: url(${thumbprint});
    background-position: center;
    width: 100%;
    margin: 40px 0px;
    padding: 40px 5%;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        padding: 0px;
        margin: 0px;
    }
    @media screen and (min-width: 800px) {
        display: none;
    }
`;

export const SignupButton = styled(Link)`
    background: #f29509;
    border-radius: 8px;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 20px;
    width: 267px;
    height: 64px;
    color: #ffffff;
    border: transparent;
    display: inline-block;

    &:hover {
       text-decoration: none; !important;
    }
`;

export const Plan = styled.section`
    margin: 40px 6%;
    text-align: center;
    @media screen and (max-width: 727px) {
        margin: 20px 20px;
    }
`;
export const PlanText = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin: 20px auto;
    @media screen and (max-width: 500px) {
        font-size: 19px;
        line-height: 26px;
    }
`;

export const OrgHeading = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    color: #7bb548;
    padding: 20px;
    @media screen and (max-width: 500px) {
        padding: 20px 0px;
        font-size: 25px;
        line-height: 34px;
    }
`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 40px 5%;
    @media screen and (max-width: 900px) {
        padding: 40px 0;
    }
    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 32%;
    justify-content: space-between;
    & > button {
        border: 3px solid #000000;
        border-radius: 8px;
        padding: 12px;
        margin: 10px 0px 20px 0px;
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        height: 40%;
        @media screen and (max-width: 700px) {
            margin-bottom: 40px;
        }
    }
    @media screen and (max-width: 700px) {
        width: 80%;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const OrgDescription = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
`;
export const Personal = styled(Link)`
    display: inline-block;
    width: 100%;
    margin-top: 26px;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    color: #000000;
`;

export const GetStarted = styled.section`
    margin: 40px 6%;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    gap:220px;
    flex-direction: row;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        & > div:nth-child(1) {
            margin-bottom: 40px;
        }
        gap:0px;
    }
    @media screen and (max-width: 500px) {
        margin: 20px 0px;
        gap:0px;
    }
`;
export const GetStartedHeading = styled.div`
max-width:380px;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 41px;
    color: #000000;
    margin-bottom: 40px;
    text-align: left;
    @media screen and (max-width: 800px) {
        width: 100%;
        font-size: 30px;
    }
`;
export const GetStartedContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 45%;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const ReasonContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
    align-items: flex-start;
    width: 100%;
    gap:25px;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;
export const Icon = styled.div`
    width: 24px;
    height: auto;
    padding-top: 5px;
`;
export const Reason = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    width: 65%;
    @media screen and (max-width: 800px) {
        width: 90%;
    }
`;

export const ContactUs = styled.section`
    margin: 10px 6% 100px 6%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > button {
        width: 20%;
        background: #f29509;
        border-radius: 8px;
        @media screen and (max-width: 1000px) {
            width: 30%;
        }
        @media screen and (max-width: 800px) {
            width: 60%;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        margin-bottom: 50px;
    }
`;
export const ContactUsText = styled.span`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 45%;
    margin-right: 90px;
    text-align: center;
    @media screen and (max-width: 800px) {
        margin-right: 0px;
        margin-bottom: 40px;
        width: 100%;
    }
`;

export const Separator = styled.div`
    width: 80%;
    margin: 40px auto;
    border: 1px solid #000000;
`;

export const ModalText = styled.div`
    padding: 10px;
    text-align: center;
    margin: 20px;
`;
