import React, { useEffect, useState } from 'react';
import { getKycDocumentsList } from '../../../../../../coreApi';
import { FormattedMessage } from 'react-intl';
import { 
    DocumentsTitle,
    DocumentsMain,
    DocumentsText,
    DocumentsTableMain,
    DocumentsTableRow,
    DocumentsTableItem
} from '../Styles';
const DocumentsList = () => {

    const [documentsList, setDocumentsList] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await getKycDocumentsList()
            if (response) {
                setDocumentsList(response.data);
            }
        })();
    }, []);

    const documentsData = 
        documentsList && 
        documentsList.map(doc => (
            <>
                <DocumentsTableRow>
                    <DocumentsTableItem width="50%">{doc.originalFileName}</DocumentsTableItem>
                    <DocumentsTableItem >{doc.ownerFirstName} {doc.ownerLastName}</DocumentsTableItem>
                </DocumentsTableRow>
            </>
        ))

    return (
        <>
            <DocumentsTitle marginTop="35px" marginLeft="95px"><FormattedMessage id="NewGuardiansPage.Documents.documents" /></DocumentsTitle>
            <DocumentsMain>
                <DocumentsTitle marginTop="50px" marginLeft="60px"><FormattedMessage id="NewGuardiansPage.Documents.uploaded" /></DocumentsTitle>
                <DocumentsText><FormattedMessage id="NewGuardiansPage.Documents.uploadedMessage" /></DocumentsText>
                <DocumentsTableMain>
                    <DocumentsTableRow>
                        <DocumentsTableItem width="50%"><FormattedMessage id="NewGuardiansPage.Documents.tableTitle" /></DocumentsTableItem>
                        <DocumentsTableItem><FormattedMessage id="NewGuardiansPage.Documents.tableOwner" /></DocumentsTableItem>
                    </DocumentsTableRow>
                    {documentsData}
                </DocumentsTableMain>
            </DocumentsMain>
        </>
    );
}

export default DocumentsList;