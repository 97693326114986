import React, { useState } from 'react';
import PropTypes, { number, string, bool } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from './CalculatorTimeline.css';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-line.svg';

const TimelineItem = ({ label, number, isCompleted, isCurrent }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className={isCurrent || isCompleted ? css.timelineItemCurrent : css.timelineItem}>
        <span>
          {isCompleted ? <CheckIcon style={{ width: 20, color: '#34a853' }} /> : number + 1}
        </span>
      </div>
      <span className={css.timelineItemLabel}>
        {label}
      </span>
    </div>
  );
};

const Timeline = ({ currentItem, timelineItems }) => {
  return (
    <div className={css.timelineContainer}>
      {timelineItems &&
        timelineItems.map(item => (
          <React.Fragment key={`calculator-timeline-${item.id}`}>
            <div className={css.horizontalLine} />
            <TimelineItem
              key={item.id}
              label={item.label}
              number={item.id}
              isCompleted={item.id < currentItem}
              isCurrent={item.id === currentItem}
            />
            {item.id === timelineItems.length - 1 && <div className={css.horizontalLine} />}
          </React.Fragment>
        ))}
      <span className={css.timelineItemLabelCurrent}>
        {timelineItems[currentItem].label}
      </span>
    </div>
  );
};

Timeline.propTypes = {
  label: string, 
  number: number, 
  isCompleted: bool, 
  isCurrent: bool
};

export default Timeline;
