import styled from 'styled-components';

export const WhyJoinMissionSection = styled.section`
    padding: 70px 4.8rem 110px;
    text-align: center;
    position: relative;
    &::before {
        content: ' ';
        background-image: url(${({ heroLeaf }) => heroLeaf});
        background-size: 100% auto;
        width: 104px;
        height: 178px;
        position: absolute;
        right: 0;
        top: -60px;
    }
    .take-action-group {
        max-width: 1200px;
        width: 100%;
        flex: 1;
        gap: 50px 0;

        .title-box {
            max-width: 630px;
            margin: 0 auto;
        }
        .icon-text-container {
            align-self: stretch;
            gap: 20px;
            .iconFrame-box {
                &:after {
                    content: none;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 30px 20px 42px;
        &::before {
            content: none;
        }
        .take-action-group {
            gap: 32px;
            .icon-text-container {
                flex-wrap: wrap;
                justify-content: center;
                gap: 40px;
            }
            padding-bottom: 24px;
        }
    }
`;
