import React from 'react';
import { Left, Hide } from './Styles';
import { injectIntl } from 'react-intl';
import LayoutWrapperSideNav from '../LeftNav/LayoutWrapperSideNav';
import DropdownComponent from '../../../../newComponents/Dropdown/dropdown';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import css from '../../../myNaturePage/components/LayoutWrapperSideNav/LayoutWrapperSideNav.css';
import classNames from 'classnames';

const NewLeftNav = (props) => {
    const classes = classNames(css.root);
    const currentRole = props.user && props.user.currentRole
    const activatedRoles = props.user && props.user.activatedRoles;
    const history = useHistory();
    
    const tabs = [
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Home'}),
            selected: '/guardian-profile/home'===props.location.pathname,
            linkProps: {
                name: 'guardian-profile/home',
            },
        },
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Landscape'}),
            selected: '/guardian-profile/landandlandscape'===props.location.pathname,
            linkProps: {
                name: 'guardian-profile/landandlandscape',
            },
        },
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Income'}),
            selected: '/guardian-profile/income'===props.location.pathname,
            linkProps: {
                name: 'guardian-profile/income',
            },
        },
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Documents'}),
            selected: '/guardian-profile/documents'===props.location.pathname,
            linkProps: {
                name: 'guardian-profile/documents',
            },
        },
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Profile'}),
            selected: '/guardian-profile/profileandaccount'===props.location.pathname,
            linkProps: {
                name: 'guardian-profile/profileandaccount',
            },
        },
        {
            text: props.intl.formatMessage({id: 'NewGuardiansPage.SideNav.Support'}),
            selected: '/friends-profile/FaqsPage'===props.location.pathname,
            linkProps: {
                name: 'FaqsPage',
            },
        },
        {
            text: props.intl.formatMessage({id: 'MyNaturePage.Profile.Back'}),
            linkProps: {
                name: 'LandingPage',
            },
        }
    ];
    return (
        <Left >
            <aside className={classes}>
                <DropdownComponent fields={activatedRoles} onClick={(selectedRole) =>{
                    history.push(`/profile-check?role=${selectedRole}`)
                }} currentRole={currentRole}/>
            </aside>
            <Hide>
            <LayoutWrapperSideNav tabs={tabs} user={props.user}/>
            </Hide>
        </Left>
    );
}

const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
    user: storeState.user.currentUser
        ? {
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
});
const LeftNav = compose(
    withRouter,
    connect(
      mapStateToProps,
    ),
    injectIntl
)(NewLeftNav);

export default LeftNav;
