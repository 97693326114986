import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Header from '../section/Header/Header.js';
import Footer from '../section/Footer/Footer.js';
import Tabs from '../section/Tabs/Tabs.js';
import Button from '../../../newComponents/Button';
import {
  getCurrentOrganization,
  getOrganizationEmissionResult,
  getEmissionActivities,
  getOrganizationEmissionDetails,
} from '../../../coreApi';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import CalculatorResult from '../../../assets/newAssets/calculatorResult.svg';
import Logo from '../../../assets/newAssets/thumbPrintRedOrg.svg';
import {
  ResultContainer,
  Details,
  Heading,
  Content,
  EmissionContainer,
  EmissionHeading,
  EmissionValue,
  LogoContainer,
  EmissionContent,
  EmissionPercent,
  EmissionScopeValue,
  RedLogo,
  FooterNote,
  EmissionResult,
  ResultPageContainer,
} from './Styles.js';
import { useHistory, withRouter, useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';
import { styles } from 'styled-system';
import { FormattedMessage } from 'react-intl';
const Result = props => {
  const [orgDetails, setOrgDetails] = useState({});
  const [company, setCompany] = useState('');
  const [sector, setSector] = useState('');
  const [year, setYear] = useState(null);
  const [result, setResult] = useState({
    scope1: 0,
    scope2: 0,
    scope3: 0,
    totalEmission: 0,
  });
  const [orgEmissionDetails, setOrgEmissionDetails] = useState({});
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const Details = location.state;
      // const Details = await getCurrentOrganization()
      // .then((response) =>{
      getOrganizationEmissionResult(Details.data.id, Details.data.inventoryYear).then(response => {
        setResult(response.data);
      });

      //     return response;
      // })
      if (!isNil(Details)) {
        setCompany(Details.data.organizationName);
        setSector(Details.data.sector);
        setYear(Details.data.inventoryYear);
      }
      setOrgDetails(Details);
    };
    fetchData();
  }, []);

  return (
    <ResultPageContainer>
      {!isEmpty(orgDetails) ? (
        <Header user={props.user} orgDetails={orgDetails.data.organizationName} />
      ) : (
        <Header orgDetails={null} />
      )}
      <Tabs id="Result" />
      <ResultContainer>
        <Details>
          <Content>
            <Heading>
              <FormattedMessage id="OrganisationCalculator.Result.CompanyDetails" />
            </Heading>
            <div>
              <FormattedMessage id="OrganisationCalculator.Result.Name" /> : <span>{company} </span>
            </div>
            <div>
              <FormattedMessage id="OrganisationCalculator.Result.Year" /> : <span>{year}</span>
            </div>
            <div>
              <FormattedMessage id="OrganisationCalculator.Result.category" /> :{' '}
              <span>{sector}</span>
            </div>
          </Content>
          {/* <Content> 
                        <Heading>
                        <FormattedMessage id="OrganisationCalculator.Result.Summary.Heading"/> 
                        </Heading>
                        <div><FormattedMessage id="OrganisationCalculator.Result.Summary"/></div>
                    </Content> */}
        </Details>
        <EmissionContainer>
          <EmissionHeading>
            <FormattedMessage id="OrganisationCalculator.Result.TotalEmission" />
          </EmissionHeading>
          <EmissionValue>{(result.totalEmission / 1000).toFixed(2)} tCO2e</EmissionValue>
        </EmissionContainer>
        <LogoContainer>
          <img src={CalculatorResult} alt="result logo" width="100%" height="100%" />
        </LogoContainer>
        <EmissionResult>
          <EmissionContent>
            <EmissionHeading>
              <FormattedMessage id="OrganisationCalculator.Result.DirectEmission" />
            </EmissionHeading>
            <EmissionScopeValue>{(result.scope1 / 1000).toFixed(2)} tCO2e</EmissionScopeValue>
            <EmissionPercent>
              <RedLogo>
                <img src={Logo} alt="result logo" width="100%" height="100%" />
              </RedLogo>
              {result.totalEmission === 0
                ? 0
                : Math.round((result.scope1 / result.totalEmission) * 100)}
              %
            </EmissionPercent>
          </EmissionContent>
          <EmissionContent>
            <EmissionHeading>
              <FormattedMessage id="OrganisationCalculator.Result.IndirectEmission" />
            </EmissionHeading>
            <EmissionScopeValue>{(result.scope2 / 1000).toFixed(2)} tCO2e</EmissionScopeValue>
            <EmissionPercent>
              <RedLogo>
                <img src={Logo} alt="result logo" width="100%" height="100%" />
              </RedLogo>
              {result.totalEmission === 0
                ? 0
                : Math.round((result.scope2 / result.totalEmission) * 100)}
              %
            </EmissionPercent>
          </EmissionContent>
          <EmissionContent>
            <EmissionHeading>
              <FormattedMessage id="OrganisationCalculator.Result.OthersEmission" />
            </EmissionHeading>
            <EmissionScopeValue>{(result.scope3 / 1000).toFixed(2)} tCO2e</EmissionScopeValue>
            <EmissionPercent>
              <RedLogo>
                <img src={Logo} alt="result logo" width="100%" height="100%" />
              </RedLogo>
              {result.totalEmission === 0
                ? 0
                : Math.round((result.scope3 / result.totalEmission) * 100)}
              %
            </EmissionPercent>
          </EmissionContent>
        </EmissionResult>
      </ResultContainer>
      <FooterNote>
        <Heading>
          <FormattedMessage id="OrganisationCalculator.Result.FooterNote" />
        </Heading>
        <Button onClick={() => history.push('/organizations')}>
          <FormattedMessage id="OrganisationCalculator.Button.Subscribe" />
        </Button>
      </FooterNote>
      <Footer />
    </ResultPageContainer>
  );
};

const mapStateToProps = storeState => ({
  user: storeState.user.currentUser
    ? {
        initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
        name:
          storeState.user.currentUser.attributes.profile.firstName +
          ' ' +
          storeState.user.currentUser.attributes.profile.lastName,
      }
    : null,
  userDetails: storeState.user.currentUser,
});

const ResultPage = connect(mapStateToProps)(withRouter(Result));
export default ResultPage;
