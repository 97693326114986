import React from 'react'
import { connect } from 'react-redux';
import thumbprint from '../../../../assets/newAssets/thumbprintWhite.svg';
import thumbprintRed from '../../../../assets/newAssets/myNaturethumbprintRed.svg';
import thumbprintGreen from '../../../../assets/newAssets/thumbprintGreen.svg';
import Button from '../../../../newComponents/Button';
import { FormattedMessage } from 'react-intl';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import {
    ImageDiv, Title, Container1, Container2, Print,
    Impact, SubTitle, Data, Result, Text, Container,
    ButtonRow, ButtonContainer, ImageContainer
} from './Styles';
import CustomHook from '../CustomHook';
const ImpactImage = connect(changeLanguage)((props) => {
    const plans = CustomHook();

    return (
        <ImageContainer>
            <ImageDiv>
                <Container>
                    {(Object.keys(plans.ecoResults).length !== 0 && (Object.keys(plans.ecoResults).length !== 0 || Object.keys(plans.dataPlans).length !== 0)) ?
                        (<><Container1>
                            <Title><FormattedMessage id="MyNaturePage.Impact.Title.CurrentImpactAnnual" /></Title>
                            <Result>
                                <Impact>
                                    <SubTitle><FormattedMessage id="MyNaturePage.Impact.Title.EcologicalImpact" /></SubTitle>
                                    <Data>
                                        {plans.ecoResults.total_ef_icl_biodiversity &&
                                            (plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact > 0) ?
                                            <>
                                                <img className="smallThumbprint" src={thumbprintRed} />
                                                <Text red>
                                                    - {plans.ecoResults.total_ef_icl_biodiversity
                                                        && Math.abs(plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact).toFixed(1)}
                                                    &nbsp;<br/><FormattedMessage id="MyNaturePage.ImpactImage.GlobalHectors" />
                                                </Text>
                                            </>
                                            :
                                            <>
                                                <img className="smallThumbprint" src={thumbprintGreen} />
                                                <Text green>
                                                    + {plans.ecoResults.total_ef_icl_biodiversity
                                                        && Math.abs(plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact).toFixed(1)}
                                                    &nbsp;<br/><FormattedMessage id="MyNaturePage.ImpactImage.GlobalHectors" />
                                                </Text>
                                            </>
                                        }
                                    </Data>
                                </Impact>
                                <Impact>
                                    <SubTitle><FormattedMessage id="MyNaturePage.Impact.Title.ClimateImpact" /></SubTitle>
                                    <Data>
                                        {plans.ecoResults.total_cf && (plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact > 0) ?
                                            <>
                                                <img className="smallThumbprint" src={thumbprintRed} />
                                                <Text red>
                                                    - {plans.ecoResults.total_cf && Math.abs(plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact).toFixed(1)} <br/><FormattedMessage id="MyNaturePage.ImpactImage.Tonnes" />
                                                </Text>
                                            </>
                                            :
                                            <>
                                                <img className="smallThumbprint" src={thumbprintGreen} />
                                                <Text green>
                                                    + {plans.ecoResults.total_cf && Math.abs(plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact).toFixed(1)} <br/><FormattedMessage id="MyNaturePage.ImpactImage.Tonnes" />
                                                </Text>
                                            </>
                                        }
                                    </Data>
                                </Impact>

                            </Result>
                            <ButtonRow>
                                <div className="text"><FormattedMessage id="MyNaturePage.Impact.Title.UpgradePlan" /></div>
                                <ButtonContainer>
                                    <Button
                                        variant="filled"
                                        size="md"
                                        path="/people"
                                    >
                                        <FormattedMessage id="MyNaturePage.Impact.Button.DoMore" />
                                    </Button>
                                </ButtonContainer>
                            </ButtonRow>
                        </Container1>
                            <img src={thumbprint} />
                        </>
                        )
                        :
                        (<Container2>
                            <img className="noDataThumbprint" src={thumbprint} />
                            <Title className="title" noData><FormattedMessage id="MyNaturePage.Impact.Title.ThankyouMessage" /></Title>
                        </Container2>)
                    }
                </Container>
            </ImageDiv>
        </ImageContainer>
    )
});

export default ImpactImage
