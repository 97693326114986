import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const signupValScheme = Yup.object({
    firstName: Yup.string().required(
        <FormattedMessage id="NewPartnersPage.Setup.Account.firstName.errMsg" />
    ),
    lastName: Yup.string().required(
        <FormattedMessage id="NewPartnersPage.Setup.Account.lastName.errMsg" />
    ),
    email: Yup.string()
        .email(<FormattedMessage id="NewPartnersPage.Setup.Account.emailInv.errMsg" />)
        .required(<FormattedMessage id="NewPartnersPage.Setup.Account.emailReq.errMsg" />),
    password: Yup.string()
        .min(8, <FormattedMessage id="NewPartnersPage.Setup.Account.pwd.errMinMsg" />)
        .required(<FormattedMessage id="NewPartnersPage.Setup.Account.pwd.errMsg" />),

    referer: Yup.string().required('Referer is required'),
    planTarget: Yup.string().required('Plan subscription beneficiary is required'),
});
