import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import isologo from '../../assets/isologo.png';
import css from './LikeListing.css';
import * as coreAPI from '../../coreApi'


class LikeListing extends Component{

  constructor(props) {
    super(props);
    this.state = {
      points: this.props.points,
    };
    this.sendData = this.sendData.bind(this);
    this.intl = this.props.intl;

  }

  sendData(){
    console.log(this.props.id)
    coreAPI.voteForAction(this.props.id).then((response) => {
       this.setState((prevstate)=>{return {points:prevstate.points+1}});
    }).catch( (error) => {
      console.log(error)
    })
  }


  render() {
    const storyLikeButtonText  = this.intl.formatMessage({ id: 'StoryLikeButton.text' });
    return (
      <div className={`${css.likelisting} ${this.props.className}`}>

        <button className={css.buttonLogo} onClick={()=>{this.sendData()}}><img className={css.logoimage} src={isologo}/></button>
    <p className={css.counter}>{this.state.points} {storyLikeButtonText}</p>
      </div>
    );
  }
};

LikeListing.defaultProps = {
  className: null,
  points: 0,
  listingId:''
};


export default injectIntl(LikeListing);
