import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import idb from '../../../../assets/newAssets/company-logo/idb.png';
import unrestoration from '../../../../assets/newAssets/company-logo/un-restoration-logo.png';
import cbb from '../../../../assets/newAssets/company-logo/certified-b-corp.png';
import ncc from '../../../../assets/newAssets/company-logo/natural-capital-coalition.png';
import peruco from '../../../../assets/newAssets/company-logo/peru_co.png';
import LogoSlider from '../../../../newComponents/LogoSlider/LogoSlider';
import { Alliances } from './Styles';

const CompanyLogos = () => {
    // company logos
    const sliderData = [
        { id: 1, img: `${idb}` },
        { id: 2, img: `${unrestoration}` },
        { id: 3, img: `${cbb}` },
        { id: 4, img: `${ncc}` },
        { id: 5, img: `${peruco}` },
    ];

    const sliderSettings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: true,
        cssEase: 'linear',
        arrows: false,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Alliances>
            <div className="company-group flex justify-start flex-col">
                <div className="title-box">
                    <h2>
                        <FormattedMessage id="NewLandingPage.companyLogos.title" />
                    </h2>
                </div>
                <div className="company-logo-container">
                    <LogoSlider settings={sliderSettings} data={sliderData} />
                </div>
            </div>
        </Alliances>
    );
};

export default CompanyLogos;
