import styled from 'styled-components';

export const TitleContainer = styled.section`
    min-height: 270px;
    background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
    background-size: cover;
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        min-height: 200px;
        margin-top: 60px;
    }
`;
export const Center = styled.div`
    max-width: 925px;
    align-self: center;
    top: -18px;
    margin: 26px 20px;
    .title-box {
        h1 {
            font-size: 48px;
            line-height: 58px;
            color: white;
            position: relative;
            .border {
                img {
                    position: absolute;
                    bottom: -8px;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }
        .subtitle {
            color: white;
            margin-top: 20px;
            max-width: 522px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        top: 0;
        margin-top: 0;
        .title-box {
            h1 {
                font-size: 42px;
                line-height: 52px;
                .border {
                    display: block;
                    img {
                        width: auto;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .title-box {
            h1 {
                font-size: 32px;
            }
        }
    }
`;

export const MainContainer = styled.div`
    /* parent aka root is flexbox, this container takes all available space */
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;

    /* This container uses flexbox layout */

    flex-direction: row;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        flex-direction: column;
        width: 100%;
    }
`;

export const SideBarNav = styled.aside`
    /* Layout */
    display: flex;
    padding: 112px 0 82px calc((100% - 1056px) / 2);

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: #fbfbfb;

    @media (max-width: ${({ theme }) => theme.media.desktop}) {
        padding: 112px 0 82px 36px;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0 24px 0 24px;
        border-top-width: 1px;
        border-top-color: #e7e7e7;
        border-top-style: solid;
        background-color: #fbfbfb;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .tabs {
        display: flex;
        flex-direction: column;
        min-height: auto;

        margin-top: 24px;
        padding-top: 0;

        @media (max-width: ${({ theme }) => theme.media.tab}) {
            flex-direction: row;
            overflow-x: auto;
            min-height: 48px;
            margin-top: 0px;
            padding-top: 10px;
        }
    }

    .tab {
        display: flex;
        align-items: flex-end;
        height: 100%;
        margin-left: 0;

        &:first-child {
            margin-left: 0;
        }

        @media (max-width: ${({ theme }) => theme.media.tab}) {
            margin-left: 16px;
        }
    }
`;

export const MainContent = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    padding: 112px 36px 82px 59px;
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px;
    }

    .content {
        max-width: 563px;
    }

    h2,
    h3,
    h4 {
        /* Adjust heading margins to work with the reduced body font size  */
        margin: 23px 0 19px 0;
    }
    .lastUpdated {
        margin-top: 0;
        margin-bottom: 55px;
    }

    ul {
        padding-left: 30px;
        margin-top: 30px;
    }

    ul li {
        position: relative;
        margin-bottom: 40px;
    }

    ul li:before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: -30px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #6ea44c;
    }

    ul li li {
        margin-bottom: 15px;
    }

    ul li li:before {
        width: 10px;
        height: 1px;
        border-radius: 0;
    }
    .green-link {
        gap: 16px;
        .link-icon {
            font-size: 29px;
        }
        &:active {
            color: #6ea44c;
        }
        &:hover {
            text-decoration: underline;
        }
    }
`;
