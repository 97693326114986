import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Page } from '../../components';
import { TabNav } from '../../components';

import config from '../../config';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import imageD from '../../assets/newAssets/icons/blog-title-line-d.svg';
import imageM from '../../assets/newAssets/icons/blog-title-line-m.svg';
import EnglishTermsOfService from './TermsOfService.en';

import SpanishTermsOfService from './TermsOfService.es';

import { TitleContainer, Center, MainContainer, SideBarNav, MainContent } from './Styles';

const TermsOfServicePageComponent = ({ location, history, user, intl }) => {
    const locale = intl && intl.locale;
    function FuncTranslate() {
        if (locale == 'en') {
            return <EnglishTermsOfService />;
        } else {
            return <SpanishTermsOfService />;
        }
    }

    const tabs = [
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
            selected: false,
            linkProps: {
                name: 'PrivacyPolicyPage',
            },
        },
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
            selected: true,
            linkProps: {
                name: 'TermsOfServicePage',
            },
        },
    ];
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'TermsOfServicePage.schemaTitle' }, { siteTitle });
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
    };
    return (
        <Page title={schemaTitle} schema={schema}>
            <Header currentPath={location.pathname} user={user} history={history} />

            <TitleContainer
                className="flex justify-center relative"
                bgGreenTexture={bgGreenTexture}
            >
                <Center className="mx-auto relative">
                    <div className="title-box text-center">
                        <h1>
                            <span className="border">
                                <FormattedMessage id="TermsOfServicePage.heading" />
                                <picture>
                                    <source media="(max-width:768px)" srcset={imageM} />
                                    <img src={imageD} className="w-auto" alt="Terms Of Service" />
                                </picture>
                            </span>
                        </h1>
                    </div>
                </Center>
            </TitleContainer>
            <MainContainer>
                <SideBarNav>
                    {tabs ? (
                        <TabNav rootClassName="tabs" tabRootClassName="tab" tabs={tabs} />
                    ) : null}
                </SideBarNav>
                <MainContent role="main">
                    <div className="content">
                        <FuncTranslate></FuncTranslate>
                    </div>
                </MainContent>
            </MainContainer>

            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = state => {
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
          }
        : null;
    return {
        user,
    };
};

const TermsOfServicePage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(TermsOfServicePageComponent);

export default TermsOfServicePage;
