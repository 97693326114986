import styled from 'styled-components';

export const VidTestimonial = styled.section`
    background: #004532 url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
    background-size: cover;
    padding: 90px 4.8rem 93px;
    text-align: center;

    .two-cols {
        max-width: 100%;
        flex: 1;
        gap: 80px;
        .testimonal-slider {
            box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            width: 100%;
            max-width: 480px;
            .slide-box {
                border-radius: 8px;
                background-color: white;
                padding: 40px 66px 66px;
                box-sizing: border-box;
                border-radius: 8px;

                .profile-info-parent {
                    gap: 18px;
                    .profile-img {
                        max-width: 150px;
                        text-align: center;
                        img {
                            border-radius: 50%;
                            max-width: 160px;
                            border: 3px solid #6ea44c;
                        }
                    }
                    .profile-info-quote {
                        font-size: 19px;
                        line-height: 1.3;
                        color: ${({ theme }) => theme.colors.title_green};
                        font-weight: 600;
                        gap: 5px;
                        .profile-name {
                            font-size: 16px;
                            font-weight: 400;
                            color: ${({ theme }) => theme.colors.grey};
                        }
                    }
                }
            }
        }
        .video-section {
            video {
                width: 100%;
                max-width: 596px;
                height: auto;
                border-radius: 8px;
            }
        }
        .testimonal-information {
            gap: 12px 0;
            text-align: left;
            max-width: 330px;
            h3.white-text {
                font-size: 48px;
                line-height: 58px;
                font-weight: 600;
                color: white;
                .border {
                    margin: 0 10px;
                    display: inline-block;
                    height: 60px;
                    img {
                        left: 2px;
                        bottom: -2px;
                        width: 182px;
                    }
                }
            }
            .parent-info {
                gap: 16px;
                .testimonial-text {
                    line-height: 180%;
                    color: white;
                }
            }
        }
    }
    @media (max-width: 1170px) {
        padding: 24px 20px 80px;
        .two-cols {
            flex-wrap: wrap;
            gap: 32px;
            padding: 0;
            .testimonal-slider {
                max-width: none;
                order: 1;
                box-shadow: none;
                .slick-slider {
                    margin-left: -20px;
                    margin-right: -20px;
                    .slick-dots {
                        bottom: -45px;
                    }
                }
                .slick-slide > div {
                    margin: 0 8px;
                }
                .slide-box {
                    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
                    padding: 20px 16px 20px;
                    .profile-info-parent {
                        display: inline-block;
                        .profile-img {
                            margin-bottom: 8px;
                        }
                        .profile-info-quote {
                            display: inline-block;
                            .profile-quote {
                                margin-bottom: 4px;
                            }
                        }
                    }
                }
                .slick-center {
                    .profile-name {
                        margin-top: 6px;
                    }
                }
            }
            .video-section {
                margin-top: -50px;
                video {
                    width: 100%;
                }
            }

            .testimonal-information {
                order: 0;
                text-align: left;
                gap: 8px;
                max-width: max-content;
                h3.white-text {
                    font-size: 28px;
                    line-height: 38px;
                    .border {
                        img {
                            display: none;
                        }
                    }
                }
                .parent-info {
                    gap: 12px;
                    margin: 0 auto;
                }
            }
        }
    }
`;
