import React, { useEffect, useState } from 'react';
import {ClipLoader} from 'react-spinners';
import Header from './../../newComponents/Header/Header';
import {LoaderWrapper} from './Styles';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import { fetchCurrentUser } from '../../ducks/user.duck';

import {changeRole} from '../../coreApi'

const ProfileCheck = (props) => {

    const {
        location,
        history, 
        user,
    } = props;

    const requestedRole = new URLSearchParams(location.search).get('role');
    const dispatch = useDispatch();
    const historyHook = useHistory();
    const currentRole = user && user.currentRole;
    const [loaded,setLoaded] = useState(false);
    const role = {
        'partner': 'partner-profile/profileandaccount',
        'friend': 'friends-profile/profileandaccount',
        'guardian': 'guardian-profile/profileandaccount'
    }
    

    useEffect(()=>{
        if(isNil(requestedRole)){
            historyHook.push(role[currentRole])
        }

        const fetch = async () =>{
            if(currentRole && requestedRole ){
                if(currentRole!=requestedRole){
                    await changeRole(requestedRole)
                    .then((response)=>{
                        const authToken = response.data;

                        if(!isNil(authToken)){
                            localStorage.setItem('authToken',authToken);
                            dispatch(fetchCurrentUser());
                        }
                }).catch((e)=>{
                    historyHook.push(role[currentRole])
                })
            }
            if(currentRole === requestedRole){
                historyHook.push(role[requestedRole])
            }
            }
        }
        fetch();
        
    },[currentRole, requestedRole]);

    return (
        <div>
            <Header currentPath={location.pathname} user={user} history={history} />
            {!loaded && <LoaderWrapper>
                <ClipLoader/> 
            </LoaderWrapper>}
           
        </div>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
            currentRole: storeState.user.currentUser.currentRole,
        }
        : null,

});

export default connect(mapStateToProps)(withRouter(ProfileCheck));