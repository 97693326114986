import styled from 'styled-components';
import { colors } from '../../../../theme';
export const AccountForm = styled.div`
    margin: 35px auto;
    width: 92%;
`;
export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 24px;
    font-weight: 700;
    margin: 15px;
`;
export const SubTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-size: ${props => (props.small ? '14px' : '19px')};
    font-weight: 500;
    margin: 20px 0px 35px 10px;
    position: ${props => (props.small ? 'relative' : '')};
    width: ${props => (props.small ? '400px' : '')};
    @media screen and (max-width: 768px) {
        margin: 20px 0px 15px 10px;
        width: ${props => (props.small ? '200px' : '')};
    }
`;
export const AccountContainer = styled.div`
    padding: 15px 5% 15px 5%;
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        height: auto;
    }
`;
export const Form = styled.div`
    display: flex;
    margin: 10px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
    }
`;
export const Wrapper = styled.div`
    float: right;
    @media screen and (max-width: 1024px) {
        float: none;
    }
`;

export const Holder = styled.div`
    margin-right: 10px;
    width: 100%;
    position: relative;
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        margin-right: 0px;
    }
`;

export const Row = styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom: 10px;
    &:nth-child(2) {
        & ${Holder}:nth-child(1){
            width:13%
        }
        & ${Holder}:nth-child(2){
            width:37%
        }
        & ${Holder}:nth-child(3){
            width:50%;
        }
      }
      &:nth-child(3) {
        & ${Holder}:nth-child(1){
            width:50%;
        }
        & ${Holder}:nth-child(2){
            width:50%;
            margin:right: 0px;
        }
      }
      &:nth-child(4) {
        margin-top:20px;
      }
    @media screen and (max-width: 1090px) {
        width: 95%;
        display:flex;
        &:nth-child(2) {
            & ${Holder}:nth-child(1){
                margin-top:10px;
                width:100%
            }
            & ${Holder}:nth-child(2){
                margin-top:10px;
                width:100%
            }
            & ${Holder}:nth-child(3){
                margin-top:10px;
                width:100%;
            }
          }
          &:nth-child(3) {
            & ${Holder}:nth-child(1){
                margin-top:10px;
                width:100%
            }
            & ${Holder}:nth-child(2){
                margin-top:10px;
                width:100%
            }
          }
    }
    @media screen and (max-width: 1090px) {
        width: 95%;
        display:flex;
        flex-direction: column;
    }
`;
export const InputButton = styled.div`
    color: #2d3748;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #a9a9a9;
    padding: 10px;
    .arrow {
        position: absolute;
        top: 10px;
        right: 20px;
        fill: #a9a9a9;
    }
`;

export const Label = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 19px;
    font-weight: 500;
    color: #4a5568;
`;
export const Input = styled.input`
    padding: 10px;
    border: 1px solid #a9a9a9;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 57px;
    color: #2d3748;
`;
export const InputBox = styled.textarea`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 20px;
    height: 120px;
    padding: 15px;
    border: 1px solid #a9a9a9;
    color: #2d3748;
    box-sizing: border-box;
    border-radius: 5px;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const ButtonContainer = styled.div`
    position: relative;
    width: 300px;

    & > button {
        max-width: 300px;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        width: 95%;
    }
`;

export const Dropdown = styled.ul`
    text-decoration: none;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0px;
    margin-top: 0px;
    background-color: white;
    font-family: 'Galano Grotesque';
    z-index: 200;
    width: -webkit-fill-available;
    cursor: pointer;
    height: 200px;
    overflow: scroll;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;
export const DropdownItem = styled.li`
    text-decoration: none;
    text-align: left;
    position: relative;
    background-color: white;
    padding: 10px;
    cursor: pointer;
    font-family: 'Galano Grotesque';
    &:hover {
        background-color: #c5c5c5;
    }
    @media screen and (max-width: 500px) {
        position: relative;
        width: auto;
    }
`;

export const Error = styled.div`
    color: #ff0000;
    text-align: left;
    padding: 20px 0px;
`;
export const StyledSuccessMessage = styled.p`
    font-size: 17px;
    font-weight: 500;
    font-family: 'Galano Grotesque';
    color: #000000;
    line-height: 17px;
`;
