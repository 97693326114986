import styled from 'styled-components';
import { colors } from '../../../../theme';
import { typography } from 'styled-system';
import { NavLink } from 'react-router-dom';

export const StyledLoginMenuContainer = styled.div`
    font-family: 'Galano Grotesque';
    display: flex;
    & > div {
        width: 45px;
        height: 45px;
        margin-right: 20px;
    }

    & > div > button {
        padding: 0px;
    }
`;

export const StyledUsername = styled.span`
    font-family: 'Galano Grotesque';
    ${typography}
    width: auto;
    line-height: 95px;
    margin-left: 30px;
    font-weight: 700;
    font-size: 50px;
    color: ${colors.black};
    font-family: 'Galano Grotesque';
    @media screen and (max-width: 860px) {
        display: none;
    }
`;
export const StyledUserIcon = styled.span`
    font-family: 'Galano Grotesque';
    ${typography}
    width: 67px;
    height: 69px;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    background-color: ${colors.green};
    border-radius: 50%;
    color: ${colors.white};
    font-family: 'Galano Grotesque';
    @media screen and (max-width: 860px) {
        width: 46px;
        height: 45px;
    }
`;

export const StyledDesktopListItem = styled.li`
    font-family: 'Galano Grotesque';
    @media screen and (max-width: 860px) {
        display: none;
    }
`;

export const StyledNavLink = styled(NavLink)`
    ${typography}
    font-family: 'Galano Grotesque';
    font-style: normal;
    text-decoration: none;
    color: ${colors.gray};
    ${({ transparent }) =>
        transparent &&
        `
    color: ${colors.white}; `}

    @media screen and (max-width: 860px) {
        color: ${colors.white};
        ${({ active }) => active && `color: ${colors.green}; `}
    }
    ${({ active }) => active && `color: ${colors.green}; `}
    &:hover {
        color: ${colors.green};
        text-decoration: none;
    }
    ${({ type }) =>
        type === 'primary' &&
        `
      margin: 0px;
    `}
    @media screen and (max-width: 860px) {
        line-height: 45px;
    }
`;

export const StyledList = styled.ul`
    padding-left: 26px;
    align-items: center;
    color: #ffffff;
    float: right;
    & > li > a {
        font-family: Galano Grotesque;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        line-height: 60px;
    }
`;

export const StyledContainer = styled.div`
    font-family: 'Galano Grotesque';
    display: grid;
    grid-template-columns: 15% 70% 15%;
    align-items: center;
    height: 126px;
    padding: 0px 30px;
    z-index: 10;
    background-color: #ffffff;
    .name {
        float: left;
        @media screen and (max-width: 860px) {
            display: none;
        }
    }
    ${({ transparent }) =>
        transparent &&
        `
    @media screen and (min-width: 861px) {
      position: absolute;
      width: 100%;
    }
  `}
    @media screen and (max-width: 860px) {
        padding: 10px;
        padding-top: 10px;
        grid-template-columns: 1fr auto;
    }
`;

export const StyledDesktopLogoContainer = styled.div`
    align-self: center;
    width: 150px;
    background-color: #ffffff;
    @media screen and (max-width: 860px) {
        display: none;
    }
`;

export const StyledMobileLogoContainer = styled.div`
    align-self: center;
    margin-top: 10px;
    width: 165px;
    background-color: ${props => (props.pop ? '#000000' : '#ffffff')};
    @media screen and (min-width: 861px) {
        display: none;
    }
`;

export const StyledMobileMenuContainer = styled.div`
    @media screen and (min-width: 861px) {
        display: none;
    }
`;

export const StyledPopper = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    position: absolute;
    z-index: 999;
    top: 4rem;
    width: fit-content;
    background-color: ${props => (props.big ? '#ffffff' : '#000000')};
    @media screen and (max-width: 860px) {
        background-color: ${props => (props.big ? '#ffffff' : '#000000')};
        padding-left: 5%;
        color: #ffffff;
        padding-top: 50px;
        top: -1rem;
        z-index: 1000;
        right: 0rem;
        width: 100%;
    }
`;

export const StyledMenuMobile = styled.div`
    color: #ffffff;
    display: flex;
    flex-direction: column-reverse;
`;

export const StyledMenuDesktop = styled.div`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 126px;
    width: 100%;
    justify-content: end;
    & > :nth-child(1) {
        display: flex;
    }
    & > :nth-child(2) {
        display: flex;
        gap: 24px;
    }
    @media screen and (max-width: 860px) {
        display: none;
    }
`;

export const MenuHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 26px;
    color: #ffffff;
    background-color: #000000;
    font-weight: 700;
    font-size: 18px;
    padding-left: 26px;
    padding-top: 40px;
    & > p {
        font-family: Galano Grotesque;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        @media screen and (min-width: 861px) {
            font-family: 'Galano Grotesque';
            font-style: normal;
            color: #ffffff;
            margin: 5px auto;
            border-radius: 5px;
        }
    }
    @media screen and (min-width: 861px) {
        padding: 2px;
        background-color: #000000;
        border-radius: 10px;
    }
`;

export const User = styled.div`
    margin-left: 60px;
    background: none;
    border: none;
    color: white;
    @media screen and (max-width: 860px) {
        margin-right: 0px;
        margin-top: 0px;
        margin-left: 0;
    }
`;

export const StyledButton = styled.button`
    border: none;
    font-family: 'Galano Grotesque';
    color: #ffffff;
    margin-left: ${props => (props.pop ? '30px' : '6%')};
    margin-right: 10px;
    background-color: ${props => (props.pop ? '#C5C5C5' : '')};
    padding: 5px;
    border-radius: 5px;
    margin-top: ${props => (props.small ? '10px' : '0px')};
    font-size: ${props => (props.pop ? '16px' : '27px')};
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
`;
export const StyledButtonLanguage = styled.button`
    background: none;
    border: none;
    color: white;
    cursor: pointer;
`;

export const StyledLanguagesList = styled.ul`
    margin: 100px auto 50px 18px;
    display: flex;
    list-style: none;
    color: ${colors.white};
    & > li > button {
        font-size: 18px;
    }
`;

export const StyledListItem = styled.li`
    & > button {
        font-weight: 400;
        padding: 0px 6px 0px 6px;
        ${({ active }) =>
            active &&
            `
       font-weight: 700; 
      `}
    }
`;

export const StyledDropdownBox = styled.ul`
    position: absolute;
    background: #f9f9f9;
    padding: 14px 15px 30px 15px;
    z-index: 10;
    right: 0px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
`;

export const StyledDropdown = styled.div`
    position: relative;
`;

export const StyledOption = styled.li`
    cursor: pointer;
    color: ${colors.gray};
    display: flex;
    align-items: center;
    &:hover {
        color: ${colors.green};
    }
    margin: 5px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    ${({ selected, transparent }) =>
        selected && transparent
            ? `
    color: ${colors.white};
      margin: 0px;
    &:hover {
      color: ${colors.white};
    }
    `
            : selected &&
              `color: ${colors.black};
      margin: 0px;
    &:hover {
      color: ${colors.black};
    }
    `}
`;

export const StyledNavItem = styled(NavLink)`
    font-family: 'Galano Grotesque';
    text-decoration: none;
    list-styles: none;
    color: ${colors.black};
    font-size: 18px;
    font-weight: 700;

    &:hover {
        color: ${colors.green};
        text-decoration: none;
    }
`;

export const MenuContainer = styled.div`
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    margin-top: 10px;
    right: 0px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
    z-index: 10;
    width: 240px;
`;

export const StyledMenuItem = styled.li`
    height: 100%;
    width: 100%;
    margin: 10px 0px;
`;

export const StyledLogoutButton = styled.button`
    border: none;
    width: 100%;
    text-align: left;
    padding: 20px;
    border-top: 1px solid ${colors.lightGray};
    color: ${colors.black};
    font-family: 'Galano Grotesque';
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
        color: ${colors.green};
    }
`;

export const StyledLoginButton = styled.button`
    font-weight: 500;
    font-size: 18px;
    font-family: 'Galano Grotesque';
    cursor: pointer;
    border: none;
    color: ${colors.gray};
`;
export const Image = styled.img`
    width: 67px;
    height: 69px;
    border-radius: 50%;
    border: 1px solid #c5c5c5;
`;
