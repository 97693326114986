import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';

export const ResultPageContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
`;
export const ResultContainer = styled.div`
    margin: 30px 8%;
    height:auto;
    background: #F9FFF4;
    border: 1px solid #3F911C;
    box-sizing: border-box;
    padding:8%;
    @media screen and (max-width:400px ){
        margin:30px 10px;
        paddding:50x 30px;
    }
`;

export const Details = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    margin-top:50px;
    margin-bottom:40px;
    box-sizing:border-box;
    @media screen and (max-width:800px ){
        margin:0px 0px;
        flex-direction:column;
        width:100%;
    }
`;
export const Heading = styled.div`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing:border-box;
    color: #242424;
`;
export const Content = styled.div`
    width:45%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    flex-wrap:wrap;
    box-sizing:border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #242424;
    &  span{
    font-weight: 700;
    }
    @media screen and (max-width:800px ){
        margin:30px 10px;
        width:100%;
    }
`;
export const EmissionContainer = styled.div`
    text-align:center;
    display:flex;
    flex-wrap:wrap;
    box-sizing:border-box;
    flex-direction:column;
    @media screen and (max-width:800px ){
        width:100%;
        margin-top:20px;
        padding-top:20px;
    }
`;

export const EmissionHeading = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing:border-box;
    color: #000000;
    @media screen and (max-width:800px ){
        width:100%;
    }
    @media screen and (max-width:400px ){
        text-align:center;
    }
`;

export const EmissionValue = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 60.1705px;
    line-height: 81px;
    box-sizing:border-box;
    color: #000000;
    @media screen and (max-width:800px ){
        font-size: 30px;
        line-height: 41px; 
        padding:20px 0px;     
    }
    

`;
export const EmissionScopeValue = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing:border-box;
    color: #000000;
`;

export const LogoContainer = styled.div`
    text-align:center;
    margin:60px auto;
    width:60%;
    box-sizing:border-box;
    @media screen and (max-width:800px ){
        width:100%;
        margin:30px auto
    }
`;
export const RedLogo = styled.div`
    width:20%;
    height:auto;
    margin-right:20px;
    box-sizing:border-box;
    @media screen and (max-width:840px ){
        width:10%;
    }
    @media screen and (max-width:600px ){
        width:20%;
    }
`;
export const EmissionContent = styled.div`
    width:30%;
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    box-sizing:border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: #242424;
    @media screen and (max-width:840px ){
        width:100%;
        flex-direction:column;
        text-align:center;
        padding:20px;
    }
`;
export const EmissionResult = styled.div`
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    width:100%;
    box-sizing:border-box;
    margin:70px auto;
    text-align:center;
    @media screen and (max-width:840px ){
        width:100%;
        flex-direction:column;
        text-align:center;
    }
`;
export const EmissionPercent =styled.div`
    display:flex;
    align-item:center;
    justify-content:center;
    flex-wrap:wrap;
    box-sizing:border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 69.6923px;
    line-height: 94px;
    width:100%;

    color: #000000;
`;

export const FooterNote = styled.div`
    display:flex;
    justify-content:space-between;
    margin: 60px 8% 200px 8%;
    height:auto;
    box-sizing:border-box;
    & > Heading{
        width:20%;
    }
    & > Button {
        width:60%;
        background:#F29509;
    }
    @media screen and (max-width:900px ){
        width:95%;
        flex-direction:column;
        text-align:center;
        margin:0px auto;
        justify-content:center;
        box-sizing:border-box;
        & > Button{
            margin:40px auto;
        }
        & > Heading{
            width:100%;
            margin:40px 20px ;
        }
    }
    @media screen and (max-width:520px ){
        box-sizing:border-box;
        & > Button{
            width:100%;
            margin:40px auto;
        }
        & > Heading{
            width:100%;
            margin:40px 20px ;
        }
    }
`;