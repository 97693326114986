import styled from 'styled-components';

export const NatureTogether = styled.section`
    background: url(${({ missionBackground }) => missionBackground[0]});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 4.8rem 50px;
    text-align: center;
    min-height: 590px;

    .mission-group {
        width: 100%;
        max-width: 835px;
        gap: 40px;
        .title-box {
            text-align: center;
            color: white;
            .small {
                font-size: 19px;
                line-height: 180%;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: 0.24rem;
            }
            h3 {
                font-size: 48px;
                line-height: 58px;
                font-weight: 600;
                color: white;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        background: url(${({ missionBackground }) => missionBackground[1]}) no-repeat center center;
        background-size: cover;
        min-height: 440px;
        padding: 50px 20px 50px;
        .mission-group {
            gap: 12px;
            .title-box {
                .small {
                    font-size: 17px;
                }
                h3 {
                    font-size: 42px;
                    line-height: 52px;
                }
            }
        }
    }
`;

export const PositiveImpact = styled.section`
    padding: 110px 4.8rem 110px;
    position: relative;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.title_green};
    .impact-info {
        max-width: 1060px;
        gap: 44px;
        h3 {
            color: white;
        }
        .impact-group {
            .impact-info-numbers {
                color: white;
                gap: 12px;
                .total-numbers-info {
                    font-size: 48px;
                    line-height: 58px;
                    font-weight: 600;
                    span {
                        font-size: 19px;
                        line-height: 27px;
                        display: block;
                    }
                }
                .short-info {
                    line-height: 180%;
                    font-weight: 400;
                }
            }
            .green-info {
                font-size: 21px;
                line-height: 27px;
                color: ${({ theme }) => theme.colors.light_green};
                font-weight: 500;
                margin-top: 60px;
            }
        }
    }

    &::after {
        content: '';
        background: url(${({ hangPetal }) => hangPetal});
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: absolute;
        width: 207px;
        height: 286px;
        right: -50px;
        top: -100px;
        z-index: 2;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 37px 45px 46px;
        .impact-info {
            .grid {
                gap: 28px;
                .impact-group {
                    .impact-info-numbers {
                        gap: 4px;
                        .total-numbers-info {
                            font-size: 42px;
                            line-height: 52px;
                            span {
                                font-size: 17px;
                                line-height: 25px;
                            }
                        }
                    }
                    .green-info {
                        margin-top: 24px;
                    }
                }
            }
        }
        &::after {
            top: -65px;
            width: 145px;
        }
    }
`;
