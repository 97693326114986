import React from 'react';
import { Left, Hide } from './Styles';
import { injectIntl } from 'react-intl';
import LayoutWrapperSideNav from '../../components/LayoutWrapperSideNav/LayoutWrapperSideNav';
import DropdownComponent from '../../../../newComponents/Dropdown/dropdown';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import css from '../../components/LayoutWrapperSideNav/LayoutWrapperSideNav.css';

const NewLeftNav = props => {

    const classes = classNames(css.root);
    const currentRole = props.user && props.user.currentRole
    const activatedRoles = props.user && props.user.activatedRoles;
    const history = useHistory();

    const tabs = [
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.home' }),
            selected: '/partner-profile/home' === props.location.pathname,
            linkProps: {
                name: 'partner-profile/home',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.footprint' }),
            selected: '/partner-profile/footprint' === props.location.pathname,
            linkProps: {
                name: 'partner-profile/footprint',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.landscape' }),
            selected: '/partner-profile/landscape' === props.location.pathname,
            linkProps: {
                name: 'partner-profile/landscape',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.impact' }),
            selected: '/partner-profile/impact' === props.location.pathname,
            linkProps: {
                name: 'partner-profile/impact',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.profileandaccount' }),
            selected: '/partner-profile/profileandaccount' === props.location.pathname,
            linkProps: {
                name: 'partner-profile/profileandaccount',
            },
        },
        {
            text: props.intl.formatMessage({id: 'OurNaturePage.SideNav.activatePartnerRoles'}),
            selected: '/partner-profile/activateroles'===props.location.pathname,
            linkProps: {
                name: 'partner-profile/activateroles',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.SideNav.support' }),
            selected: '/FaqsPage' === props.location.pathname,
            linkProps: {
                name: 'FaqsPage',
            },
        },
        {
            text: props.intl.formatMessage({ id: 'OurNaturePage.Profile.Back' }),
            linkProps: {
                name: 'LandingPage',
            },
        },
    ];
    return (
        <Left>
            <aside className={classes}>
                <DropdownComponent fields={activatedRoles} onClick={(selectedRole) =>{
                    history.push(`/profile-check?role=${selectedRole}`)
                }} currentRole={currentRole}/>
            </aside>
            <Hide>
                <LayoutWrapperSideNav tabs={tabs} user={props.user}/>
            </Hide>
        </Left>
    );
};
const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
    user: storeState.user.currentUser
        ? {
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
});
const LeftNav = compose(withRouter, connect(mapStateToProps), injectIntl)(NewLeftNav);

export default LeftNav;
