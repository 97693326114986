import axios from 'axios';
import { encodeURIComponent, sessionStorage, window } from 'window-or-global';
import * as sdkTypes from './util/sdkTypes';
// Axios config

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000';

export const publicAxiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

export const privateAxiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

const removeCaptchaKey = obj => {
    const { captchaToken, ...rest } = obj;
    return rest;
};

const translationInterceptors = [
    function(config) {
        const locale = window.localStorage.getItem('localePreference');
        if (locale) {
            config.headers['Accept-Language'] = locale;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    },
];

publicAxiosInstance.interceptors.request.use(...translationInterceptors);
privateAxiosInstance.interceptors.request.use(...translationInterceptors);

privateAxiosInstance.interceptors.request.use(
    function(config) {
        const authToken = getAuthToken();
        if (authToken) {
            config.headers['Authorization'] = authToken;
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

privateAxiosInstance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        //Handle it more specifically with error code
        if (error.response && error.response.status === 401) {
            clearStorage();
        }
        return Promise.reject(error);
    }
);

// local storage config

function getAuthToken() {
    return window.localStorage.getItem('authToken');
}

function clearStorage() {
    localStorage.removeItem('authToken');
}

export function storeGuestUser(guestUserDetails) {
    sessionStorage.setItem('guest', JSON.stringify(guestUserDetails));
}

export function getGuestUserDetails() {
    let guestUserDetails = sessionStorage.getItem('guest');
    if (guestUserDetails) {
        return JSON.parse(guestUserDetails);
    } else {
        return null;
    }
}

// Public api calls

export const signUpUser = signUpRequest => {
    return publicAxiosInstance.post('/account/signup', removeCaptchaKey(signUpRequest), {
        headers: { 'g-recaptcha-response': signUpRequest['captchaToken'] },
    });
};
export const signUpUserWithGoogle = signUpRequest => {
    return publicAxiosInstance.post('/account/signupwithgoogle', removeCaptchaKey(signUpRequest), {
        headers: { 'g-recaptcha-response': signUpRequest['captchaToken'] },
    });
};

export const loginUser = ({ username, password }) => {
    return publicAxiosInstance
        .post('/auth/login', {
            email: username,
            password: password,
        })
        .then(response => {
            window.localStorage.setItem('authToken', response.data.authToken);
        });
};

export const loginWithGoogle = googleAccessToken => {
    return publicAxiosInstance
        .post('/auth/login-with-google', { accessToken: googleAccessToken })
        .then(response => {
            window.localStorage.setItem('authToken', response.data.authToken);
        });
};

export const forgotPassword = (email, captchaToken) => {
    return publicAxiosInstance.post(
        '/account/forgot-password',
        { email },
        { headers: { 'g-recaptcha-response': captchaToken } }
    );
};

export const resetPassword = (token, password) => {
    return publicAxiosInstance.post('/account/reset-password', { token, password });
};

export const allLandscape = () => {
    return publicAxiosInstance.get('/landscape/all');
};

export const getLandScapeDetails = landscapeId => {
    return publicAxiosInstance.get(`/landscape/${landscapeId}`);
};

export const getLandScapeDetailsBySlug = landscapeSlug => {
    return publicAxiosInstance.get(`/landscape/${landscapeSlug}`);
};

export const getAllLandscapePlans = () => {
    return publicAxiosInstance.get('/landscape/plan');
};

export const subscribeToNewsLetter = (subscriptionInfo, captchaToken) => {
    return publicAxiosInstance.post('/engagement/subscribe-to-newsletter', subscriptionInfo, {
        headers: { 'g-recaptcha-response': captchaToken },
    });
};

export const allActions = () => {
    return publicAxiosInstance.get('/action/all');
};

export const getActionDetails = actionSlug => {
    return publicAxiosInstance.get(`/action/${actionSlug}`);
};

export const voteForAction = actionSlug => {
    return publicAxiosInstance.post(`/action/green-print/${actionSlug}`);
};

export const getUserDetails = userId => {
    return publicAxiosInstance.get(`/user/user-details/${userId}`);
};

// Optional authentication

export const calculateFootprint = answers => {
    return privateAxiosInstance.post(`/footprint_calculations`, answers);
};

export const fetch_calculateFootprint = () => {
    return privateAxiosInstance.get(`/fetch-footprint-calculations`);
};

// Private api calls

export const getCurrentOrganization = () => {
    return privateAxiosInstance.get('/organization/fetch-details');
};

export const getOrganizationSectors = () => {
    return privateAxiosInstance.get('/organization-calculator/sectors');
};

export const getUnits = () => {
    return privateAxiosInstance.get('/organization-calculator/units');
};

export const getOrganizationEmissionDetails = (scope, orgId, year) => {
    return privateAxiosInstance.get(
        `/organization-calculator/organization/fetch-emission-activities?scope=${scope}&organizationId=${orgId}&year=${year}`
    );
};

export const getCurrentUser = () => {
    return privateAxiosInstance.get('/account/current-user');
};

export const saveOrganisationEmissionActivities = (emissionActivities, organizationId, year) => {
    return privateAxiosInstance.post(
        '/organization-calculator/organization/save-emission-activities',
        { emissionActivities, organizationId, year }
    );
};

export const subscribeToLandscapePlan = async (planId, stripeToken) => {
    return privateAxiosInstance.post('/subscription/subscribe', {
        stripeToken: stripeToken,
        planId: planId,
    });
};
export const changeLandscapePreference = async (planId, subscriptionId) => {
    return privateAxiosInstance.post('/subscription/subscribe/change-landscape', {
        subscriptionId: subscriptionId,
        planId: planId,
    });
};

export const subscribeToLandscapePlanGuest = async (planId, stripeToken, email) => {
    return publicAxiosInstance.post('/subscription/subscribe/guest', {
        stripeToken: stripeToken,
        planId: planId,
        email: email,
    });
};

export const logOutUser = () => {
    return privateAxiosInstance.post('/auth/logout', {}).finally(() => {
        clearStorage();
    });
};

export const verifyEmail = verificationToken => {
    return privateAxiosInstance.post(
        `/account/verify-email?token=${encodeURIComponent(verificationToken)}`
    );
};

export const sendVerificationEmail = () => {
    return privateAxiosInstance.post(`/account/request-email-verification`);
};

export const sendGuardianInterestedEmail = () => {
    return privateAxiosInstance.post('/account/guardian-interested');
};

export const getMessages = async () => {
    return [];
};

export const getPaymentHistory = async () => {
    return privateAxiosInstance.get('/subscription/payment-history');
};

export const getMySubscription = async () => {
    return privateAxiosInstance.get('/subscription/my-subscription');
};
export const getMySubscriptionPlans = async () => {
    return privateAxiosInstance.get('/subscription/my-plans');
};

export const cancelSubscription = async subscriptionId => {
    return privateAxiosInstance.post(`/subscription/cancel`, {
        subscriptionId,
    });
};
export const updateOrganization = async newOrganizationDetails => {
    return privateAxiosInstance.post(`/organization/save-details`, newOrganizationDetails);
};

export const getEmissionActivities = scope => {
    return privateAxiosInstance.get(
        `/organization-calculator/fetch-emission-activities?scope=${scope}`
    );
};

export const getOrganizationEmissionResult = (orgId, year) => {
    return privateAxiosInstance.get(
        `/organization-calculator/organization/fetch-all-emission-activities?organizationId=${orgId}&year=${year}`
    );
};
export const getOrganizationEmissionResultOurNature = orgId => {
    return privateAxiosInstance.get(
        `/organization-calculator/organization/fetch-all-emission-results?organizationId=${orgId}`
    );
};

export const updateUser = async newUserDetails => {
    return privateAxiosInstance.post(`/user/update`, newUserDetails);
};

export const updateAccount = async newAccountDetails => {
    return privateAxiosInstance.post(`/account/update`, newAccountDetails);
};

export const updateCardDetails = async stripeSourceId => {
    return privateAxiosInstance.post(`/user/update-card`, {
        sourceId: stripeSourceId,
    });
};

export const uploadImage = async file => {
    const formData = new FormData();
    formData.append('file', file);
    return privateAxiosInstance.post('/file/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const changeEmail = async (newEmail, password) => {
    return privateAxiosInstance.post(`/account/change-email`, {
        newEmail: newEmail,
        currentPassword: password,
    });
};

export const changePassword = async (currentPassword, newPassword) => {
    return privateAxiosInstance.post(`/account/change-password`, {
        currentPassword: currentPassword,
        password: newPassword,
    });
};

export const newAction = async actionDetails => {
    return privateAxiosInstance.post('/action/new', actionDetails);
};

// MailChimp E-Commerce APIs

export const getCart = async userEmail => {
    return privateAxiosInstance.post(`/ecommerce/get-cart`, {
        cartId: userEmail,
    });
};

export const createCart = async cartItem => {
    return privateAxiosInstance.post(`/ecommerce/add-cart`, cartItem);
};

export const deleteCart = async userEmail => {
    return privateAxiosInstance.post(`/ecommerce/delete-cart`, {
        user: userEmail,
    });
};

export const updateCart = async cartItem => {
    return privateAxiosInstance.post(`/ecommerce/update-cart`, cartItem);
};

export const addOrder = async order => {
    return privateAxiosInstance.post(`/ecommerce/add-order`, order);
};

// roles

export const changeRole = async role => {
    return privateAxiosInstance.post('/account/change-role', {
        role: role,
    });
};

export const activateRole = async role => {
    return privateAxiosInstance.post('/account/activate-role', {
        role: role,
    });
};

export const activateUserRole = async userRole => {
    return privateAxiosInstance.post('/account/user/activate-role', {
        userRole: userRole,
    });
};

export const getAccountRoles = () => {
    return privateAxiosInstance.get('/account/get-roles');
};

export const getUserRoles = () => {
    return privateAxiosInstance.get('/account/user/get-roles');
};

// SDK functions

export const checkAuth = async () => {
    const authToken = getAuthToken();
    if (!!authToken) {
        return { isAnonymous: false };
    } else {
        return { isAnonymous: true };
    }
};

export const coreAPISDK = {
    createInstance: sdkConfig => {
        return {
            authInfo: checkAuth,
            currentUser: getCurrentUser,
        };
    },
    // We will remove the types in the end
    types: {
        UUID: sdkTypes.UUID,
        LatLng: sdkTypes.LatLng,
        LatLngBounds: sdkTypes.LatLngBounds,
        Money: sdkTypes.Money,
    },
};

export const saveUserInterestForGuardian = interestData => {
    return privateAxiosInstance.post(`/guardian-show-interest`, interestData);
};

export const saveUserCountryForGuardian = countryName => {
    return privateAxiosInstance.post(`/guardian-update-country`, countryName);
};

export const generateGuardianInterestDocument = ({ name, email, region, documentLanguage }) => {
    return privateAxiosInstance.post(`/generate-interest-document`, {
        name,
        email,
        region,
        documentLanguage,
    });
};

export const signGuardianInterestDocument = ({ name, email, signatureDataUrl }) => {
    return privateAxiosInstance.post(`/sign-interest-document`, { name, email, signatureDataUrl });
};

export const getUserInterestForGuardian = () => {
    return privateAxiosInstance.get(`/guardian-read-interest`);
};

export const getGuardianSubRoles = () => {
    return privateAxiosInstance.get(`/get-sub-roles?accountType=guardian`);
};

export const sendLandscapeProposalData = emailData => {
    return privateAxiosInstance.post(`/landscape/proposal`, { ...emailData });
};

export const graphqlFetchQuery = query => {
    return axios
        .post(
            process.env.REACT_APP_GQL_URL,
            { query: query },
            {
                headers: { 'Content-Type': 'application/json' },
            }
        )
        .then(response => response.data.data);
};

export const getGuardianStripeInfo = () => {
    return privateAxiosInstance.get(`/stripe-account?accountType=guardian`);
};

export const getGuardianStripePayoutsList = () => {
    return privateAxiosInstance.get(`/stripe-account/payouts-list?accountType=guardian`);
};

export const uploadKycDocuments = async file => {
    const formData = new FormData();
    formData.append('file', file);
    return privateAxiosInstance.post('/interest/submit-kyc-documents', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getKycDocumentsList = () => {
    return privateAxiosInstance.get(`/interest/kyc-documents`);
};

export const getGuardianLandscapes = () => {
    return privateAxiosInstance.get(`/guardian-read-landscapes`);
};

export const getGuardianShapefiles = () => {
    return privateAxiosInstance.get(`/guardian/get-shapefiles`);
};

export const getStripeSupportedLocales = () => {
    return privateAxiosInstance.get('/customer/supported-locales');
};

export const getStripeCustomerLocale = () => {
    return privateAxiosInstance.get('/customer/fetch-locale');
};

export const updateStripeCustomerLanguage = userLocales => {
    return privateAxiosInstance.post('/customer/update-locales', {
        locales: [userLocales],
    });
};
