import React from 'react';
import { ErrorMessage, useField } from 'formik';

const TextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <>
            {label ? (
                <label htmlFor={field.name} className="small">
                    {label}
                </label>
            ) : (
                <></>
            )}
            <input
                className={`form-control shadow-none `}
                {...field}
                {...props}
                onBlur={e => {
                    field.onBlur(e);
                    helpers.setTouched(true); // Mark the field as touched after the blur event
                }}
                onChange={e => {
                    field.onChange(e);
                    if (meta.error && meta.touched) {
                        helpers.setTouched(false); // Hide the error once the user starts typing
                    }
                }}
                autoComplete="off"
            />

            {meta.touched && meta.error ? (
                <ErrorMessage component="span" name={field.name} className="error-text small" />
            ) : null}
        </>
    );
};

export default TextField;
