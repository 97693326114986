import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ImageUpload } from '../../assets/newAssets/imageUpload.svg';
const InputDiv = styled.input`
    display: none;
`;
const Image = styled.img`
    margin: 0 auto;
    cursor: pointer;
    width: 159px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid #c5c5c5;
    float: left;
`;
const Change = styled.button`
    color: #ffffff;
    position: relative;
    background: #c5c5c5;
    list-style-type: none;
    margin: 5px auto;
    font-size: 11px;
    padding: 5px;
    width: fit-content;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 5px;
`;
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ErrDiv = styled.div`
    color: red;
    position: relative;
    list-style-type: none;
    margin: 5px auto;
    font-size: 11px;
    padding: 5px;
    width: fit-content;
`;

function FileUpload(props) {
    const [displayFile, setDisplayFile] = useState();
    const hiddenFileInput = React.useRef(null);
    const profilePicture = props.picture;
    const [change, setChange] = useState(false);
    const [error, setError] = useState(false);
    const saveFile = e => {
        let str = e.target.files[0] && e.target.files[0].type.split('/')[0];
        if (str === 'image') {
            setDisplayFile(URL.createObjectURL(e.target.files[0]));
            if (props.imgData) props.imgData(e.target.files[0]);
        } else {
            setError(true);
        }
    };

    const handleClick = event => {
        // setChange(true);
        hiddenFileInput.current.click();
    };
    return (
        <div>
            <InputDiv
                type="file"
                inputProps={{ accept: 'image/*' }}
                ref={hiddenFileInput}
                onChange={saveFile}
            />
            <ImageContainer>
                {profilePicture && !displayFile ? (
                    <Image src={profilePicture} />
                ) : displayFile ? (
                    <Image src={displayFile} />
                ) : (
                    <ImageUpload onClick={handleClick} />
                )}

                {profilePicture || displayFile ? (
                    <Change onClick={handleClick}>
                        <FormattedMessage id="OurNaturePage.Profile.ChangeImage" />
                    </Change>
                ) : (
                    <Change onClick={handleClick}>
                        <FormattedMessage id="OurNaturePage.Profile.AddImage" />
                    </Change>
                )}
                {error && <ErrDiv>*input type image</ErrDiv>}
            </ImageContainer>
        </div>
    );
}

export default FileUpload;
