import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';


export const DetailsContainer =styled.div`
width: 100%;
height: 100%;
margin: 0px;
padding: 0px;
overflow-x: hidden; 
`;