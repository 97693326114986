import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../theme';

export const FactContainer=styled.div`
    background: #edffe1;
    width: 50%;
    padding: 30px 56px 30px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #277455;
    position: relative;
    border-radius: 10px;
    float:left;
    display:flex;
    justify-content:space-between;
    @media screen and (max-width: 840px) {
        display:none;
    width: 50%;
    padding: 30px 25px 30px 60px;
    /* font-size: 12px; */
    }
    @media (--viewportXLarge) {
    width: 45%;
    font-size: 20px;
    }
`;

export const Exclamation=styled.img`
    width: 8%;
    position:relative;
    top:-30%;
    margin-right:25px;
    @media (--viewportMedium) {
    width: 7%;
    }
    @media (--viewportLarge) {
    width: 6%;
    }
    @media (--viewportLargeWithPaddings) {
    width: 4%;
    }
    @media (--viewportXLarge) {
    width: 3%;
    }
`;