import { Frame, MobileComp } from '../../../Styles';
import Header from '../../Header/Header';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { FormattedMessage } from 'react-intl';
import Footer from '../../../components/Footer';
import React, { useState, useEffect } from 'react';
import CustomHook from '../../../components/CustomHook';
import Button from '../../../../../newComponents/Button';
import { withRouter, useHistory } from 'react-router-dom';
import ImpactImage from '../../../components/ImpactImage';
import CarbonLineChart from '../../../components/Chart/CarbonImpactChart';
import thumbprintGreen from '../../../../../assets/newAssets/thumbprintGreen.svg';
import thumbprintRed from '../../../../../assets/newAssets/myNaturethumbprintRed.svg';
import {
    ImpactMain, Title, CardTitle,
    Chart, CardChart, Tbody,
    SubTitle, Input, Text,
    InputBox, Data, Body,
    ButtonContainer, Disclaimer,
    NoData, GrayText
} from './Styles';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/partner_profile.png';


const OurNatureImpactPage = ({ location, history, user }) => {
    const [carbonArray, setCarbonArray] = useState([]);
    const plans = CustomHook();

    const historyPath = useHistory();
    useEffect(() => {
        if (user && user.userType === "personas") {
            historyPath.push('/friends-profile/impact');
        }
    }, [user])

    useEffect(() => {
        if (!plans) {
            return;
        }
        if (carbonArray.length > 0)
            return;
        if (plans.planArray && plans.planArray.length > 0 && plans.totalEmission) {
            compute(plans.planArray, plans.totalEmission);
        }

    }, [plans]);
    function compute(planArray, totalEmission) {
        let carbon = planArray.map((item, i) => {
            return item - totalEmission;
        })
        setCarbonArray(carbon);
    }
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Partner profile<br/>& account</>}
                image={HeroImage}
            />
            <ImpactMain >
                <Title>
                    <FormattedMessage id="OurNaturePage.Impact.Title" />
                </Title>
                <CardTitle>
                    <FormattedMessage id="OurNaturePage.Impact.Title.CurrentImpact" />
                </CardTitle>
                <ImpactImage />
                <CardTitle>
                    <FormattedMessage id="OurNaturePage.Impact.Title.NetImpact" />
                </CardTitle>
                {plans.totalEmission !== 0 ? (<>
                    <CardChart>
                        <CardTitle chart><FormattedMessage id="OurNaturePage.Impact.Title.ViewImpact" /></CardTitle>
                        <Body>
                            <Tbody>
                                <InputBox>
                                    <Input type="radio" name="carbon"
                                    />
                                    <SubTitle><FormattedMessage id="OurNaturePage.Impact.Title.CarbonFootprintReduced" /></SubTitle>
                                </InputBox>
                                <Data>
                                    {plans.totalEmission && (plans.totalEmission - plans.climateImpact > 0) ?
                                        <>
                                            <Text red>
                                                - {plans.totalEmission && Math.abs(plans.totalEmission - plans.climateImpact).toFixed(1)} <br/>tonnes
                                            </Text>
                                            <img className="smallThumbprint" src={thumbprintRed} />
                                        </>
                                        :
                                        <>
                                            <Text green>
                                                + {plans.totalEmission && Math.abs(plans.totalEmission - plans.climateImpact).toFixed(1)} <br/>tonnes
                                            </Text>
                                            <img className="smallThumbprint" src={thumbprintGreen} />
                                        </>
                                    }
                                </Data>
                            </Tbody>
                        </Body>
                        {carbonArray && carbonArray.length > 0 && plans && plans.year &&
                            <Chart>
                                {/* {carbonArray.length > 0 && <Disclaimer>*<FormattedMessage id="OurNaturePage.Footprint.Disclaimer"/> </Disclaimer>} */}
                                <CarbonLineChart data={carbonArray} year={plans.year}/>
                            </Chart>
                        }

                        <ButtonContainer>
                            <Button
                                variant="filled"
                                size="md"
                                path="/organizations"
                            >
                                <FormattedMessage id="OurNaturePage.Impact.Button.Invest" />
                            </Button>
                        </ButtonContainer>
                        <br />
                    </CardChart>
                </>) :
                    (<NoData>
                        <GrayText empty> <FormattedMessage id="OurNaturePage.Footprint.NoRecord" /> </GrayText>
                        <br /><br />
                        <ButtonContainer>
                            <Button
                                variant="filled"
                                size="lg"
                                path="/organisation/calculator"
                            >
                                <FormattedMessage id="OurNaturePage.Footprint.Calculate" />
                            </Button>
                        </ButtonContainer>
                    </NoData>)
                }
                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </ImpactMain>
            <RightNav />
        </Frame>
    )
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
            userType: storeState.user.currentUser.attributes.profile.userType,
        }
        : null,
});
export default connect(mapStateToProps)(withRouter(OurNatureImpactPage));
