import styled from 'styled-components';

export const GuardianRoleSection = styled.section`
    .role-box {
        padding: 12px;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.02);
        border: 1px solid #ddedd0;
        border-radius: 8px;
        gap: 8px;
        .role-img {
            min-width: 106px;
        }
        .role-infobox {
            .role-title {
                font-size: 19px;
                font-weight: 600;
                line-height: 27px;
                margin-bottom: 0;
            }
            p {
                line-height: 150%;
                margin-top: 0;
            }
            .green-link {
                margin: 3px 0;
                svg {
                    font-size: 20px;
                    transition: all 1s ease-out;
                }
                span {
                    line-height: 23px;
                }
                &:hover {
                    svg {
                        right: -5px;
                    }
                }
            }
        }
        &:hover {
            border: 1px solid #6ea44c;
            background-color: #6ea44c;
            color: white;
            .role-infobox {
                .green-link {
                    color: white;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .role-box {
            padding: 10px;
            .role-infobox {
                .role-title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 0;
                }
                p {
                    line-height: 150%;
                    margin-top: 0;
                }
                .green-link {
                    margin: 3px 0;
                    svg {
                        font-size: 20px;
                        transition: all 1s ease-out;
                    }
                    span {
                        line-height: 23px;
                    }
                    &:hover {
                        svg {
                            right: -5px;
                        }
                    }
                }
            }
            &:hover {
                border: 1px solid #6ea44c;
                background-color: #6ea44c;
                color: white;
                .role-infobox {
                    .green-link {
                        color: white;
                    }
                }
            }
        }
    }
`;
