import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FaAnglesRight } from 'react-icons/fa6';
import { FaAnglesLeft } from 'react-icons/fa6';

import { StepSection } from './Styles';

const StepNavigation = ({ step, checkToggle }) => {
    // toggle width
    const [isToggled, setIsToggled] = useState(false);
    const toggleClick = () => {
        setIsToggled(!isToggled);
        if (checkToggle) {
            checkToggle(isToggled);
        }
    };
    return (
        <StepSection style={{ maxWidth: isToggled ? '129px' : '37%' }}>
            <button className="arrow-btn absolute" onClick={toggleClick}>
                {isToggled ? <FaAnglesRight /> : <FaAnglesLeft />}
            </button>

            <div className={isToggled ? 'togglestep' : ''}>
                <div
                    className={`step-one step-each ${1 < step ? 'complete-check' : ''} ${
                        1 === step ? 'start' : ''
                    } relative mx-auto`}
                >
                    <div className="step-title">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step1.title" />
                    </div>
                    <div className="step-text small">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step1.desc" />
                    </div>
                </div>
                <div
                    className={`step-two step-each ${2 < step ? 'complete-check' : ''} ${
                        2 === step ? 'start' : ''
                    } relative mx-auto`}
                >
                    <div className="step-title">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step2.title" />
                    </div>
                    <div className="step-text small">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step2.desc" />
                    </div>
                </div>
                <div
                    className={`step-three step-each ${3 < step ? 'complete-check' : ''} ${
                        3 === step ? 'start' : ''
                    } relative mx-auto`}
                >
                    <div className="step-title">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step3.title" />
                    </div>
                    <div className="step-text small">
                        <FormattedMessage id="GuardianSubscription.StepForm.Step3.desc" />
                    </div>
                </div>
            </div>
        </StepSection>
    );
};

export default StepNavigation;
