import React, { useRef } from 'react';
import { plans as allPlans, plans } from '../../paymentData';
import css from './ChoosePlans.css';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NamedLink } from '../../components';
import { Redirect, withRouter } from 'react-router-dom';
import { console } from 'window-or-global';

const individualPlans = ['polen', 'semilla', 'arbol', 'bosque', 'paisaje'];
const organizationPlans = ['semillaEmpresas', 'bosqueEmpresas', 'arbolEmpresas'];

const isIndividualPlan = planType => individualPlans.includes(planType);

const PlansComparison = ({
    selectedPlan,
    selectedLandscape,
    onSelectionPlan,
    recommendedPlan,
    ...props
}) => {
    let CarouselListings = useRef(null);

    const selected = selectedPlan;

    let { userType } = props;
    if (!userType) {
        userType = 'personas';
    }

    const isPlanApplicable = planDetails =>
        userType === 'personas'
            ? isIndividualPlan(planDetails.planType)
            : !isIndividualPlan(planDetails.planType);

    const filteredPlans = selectedLandscape
        ? selectedLandscape.plans
              .filter(isPlanApplicable)
              .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount)
        : plans
              .filter(isPlanApplicable)
              .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount);

    const redirectTo = onSelectionPlan ? 'ChoosePayment' : 'PaymentLogin';

    const currentSelectedPlan = selectedPlan
        ? allPlans.find(frontendPlans => frontendPlans.planType === selectedPlan.planType)
        : undefined;
    // const planData = plans.find(plan => plan.planType === selectedPlan)

    if (onSelectionPlan === 'paymentPage' && selectedPlan) {
        return (
            <Redirect
                to={{
                    pathname: '/subscribe/payment',
                    selectedLandscape: selectedLandscape,
                    selectedPlan,
                    currentPlan: currentSelectedPlan,
                }}
            ></Redirect>
        );
    }

    const images = filteredPlans.map((plan, index) => {
        const currentPlan = allPlans.find(
            frontendPlans => frontendPlans.planType === plan.planType
        );
        if (!currentPlan) {
            return null;
        }
        const isRecommend = recommendedPlan ? recommendedPlan == plan.planType : false;

        let redirectParams;
        if (redirectTo === 'PaymentLogin') {
            redirectParams = {
                selectedPlan: currentPlan.planType,
            };
        } else if (redirectTo === 'ChoosePayment') {
            redirectParams = {
                selectedLandscape: selectedLandscape,
                selectedPlan: plan,
                currentPlan: currentPlan,
            };

            if (selectedPlan != null && selectedPlan == plan.planType) {
                return (
                    <Redirect
                        key={index}
                        to={{
                            pathname: '/subscribe/payment',
                            selectedLandscape: selectedLandscape,
                            selectedPlan: plan,
                            currentPlan: currentPlan,
                        }}
                    ></Redirect>
                );
            }
        }

        return (
            <div key={index} className={userType !== 'personas' ? css.planCardOrg : css.planCard}>
                <h5 style={{ fontSize: 15, color: '#FF7A00', fontWeight: 700 }}>
                    {isRecommend ? 'Recommended for you' : ''}
                </h5>
                <img src={currentPlan.image} alt={currentPlan.title} />
                <h5 style={{ fontSize: '18px', fontWeight: 700 }}>
                    <FormattedMessage
                        id="PlanComparison.plan.title"
                        values={{ planName: currentPlan.title }}
                    />
                </h5>
                <h5 style={{ fontSize: '16px', fontWeight: 700 }}>{currentPlan.membership}</h5>
                <p style={{ fontSize: '13px' }}>{currentPlan.description}</p>
                <h3 style={{ marginTop: '30px', fontSize: '15px' }}>
                    <FormattedMessage
                        values={{
                            price: <>{plan.amount / 300}</>,
                            currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
                        }}
                        id="ChoosePlanPage.plan.price"
                    />
                </h3>
                <NamedLink name={redirectTo} to={redirectParams}>
                    <button className={isRecommend ? css.orangeButton : css.whiteButton}>
                        <FormattedMessage
                            values={{
                                plan: <>{currentPlan.title}</>,
                            }}
                            id="ChoosePlanPage.plan.choose"
                        />
                    </button>
                </NamedLink>
            </div>
        );
    });

    return (
        <>
            <div
                className={css.planCardsDesktop}
                style={{ gridTemplateColumns: `repeat(${images.length}, 1fr)` }}
            >
                {images}
            </div>
            <div className={css.planCardsMobile}>
                <AliceCarousel
                    buttonsDisabled={true}
                    autoPlayInterval={2000}
                    dotsDisabled={true}
                    infinite={false}
                    disableAutoPlayOnAction={true}
                    autoPlay={false}
                    responsive={{ 0: { items: 1 }, 767: { items: 3 }, 1023: { items: 4 } }}
                    items={images}
                    ref={el => (CarouselListings = el)}
                />
                <button
                    className={css.carouselBackButton}
                    onClick={() => CarouselListings.slidePrev()}
                >
                    <FaChevronLeft className={css.carouselChevron} />
                </button>
                <button
                    className={css.carouselNextButton}
                    onClick={() => CarouselListings.slideNext()}
                >
                    <FaChevronRight className={css.carouselChevron} />
                </button>
            </div>
        </>
    );
};

export default PlansComparison;
