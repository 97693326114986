import React from 'react';
import { ExternalLink } from '../../../../../components';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

const Contactsub = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
    .title-link {
        font-weight: 400;

        .spanTitle {
            padding: 0px 20px;
        }
        a {
            color: ${({ theme }) => theme.colors.title_green};
            font-weight: 700;
            padding: 10px 20px;
            display: block;
            &:hover {
                background-color: ${({ theme }) => theme.colors.bgnavlink_hover};
                ${({ theme }) => theme.colors.title_green};
            }
        }
    }
`;

const ContactSubmenu = () => {
    return (
        <div className="submenu dropdown-contact" onClick={e => e.stopPropagation()}>
            <Contactsub>
                <div className="title-link">
                    <div className="spanTitle">
                        <FormattedMessage id="NewLandingPage.header.writeTo" />:
                    </div>
                    <ExternalLink href="mailto:community@regenera.earth">
                        community@regenera.earth
                    </ExternalLink>
                </div>
                <div className="title-link">
                    <div className="spanTitle">
                        <FormattedMessage id="NewLandingPage.header.or" />:
                    </div>
                    <ExternalLink href="https://calendly.com/frankhajek/30min">
                        <FormattedMessage id="NewLandingPage.header.scheduleCall" />
                    </ExternalLink>
                </div>
            </Contactsub>
        </div>
    );
};

export default ContactSubmenu;
