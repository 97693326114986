import React, { useState } from 'react';
import Header from './Sections/Header/Header';
import HeroSection from './Sections/HeroSection/HeroSection';
import TakeAction from './Sections/TakeAction';
import CompanyLogos from './Sections/WorkWith/CompanyLogos';
//import OurCommunity from './Sections/OurCommunity/OurCommunity';
//import OurLandscapes from './Sections/OurLandscapes/OurLandscapes';
//import OurStory from './Sections/OurStory/OurStory';
import Footer from './Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import OurLatestStories from './Sections/OurLatestStories/OurLatestStories';
import { StaticPage } from '../../containers';
import PartnersImpact from './Sections/PartnersImpact/PartnersImpact';
import BenefitsMembership from './Sections/BenefitsMembership/BenefitsMembership';
import Mission from './Sections/Mission/Mission';
import Testimonials from './Sections/Testimonials/Testimonials';

const NewLandingPage = ({ location, history, user }) => {
    return (
        <StaticPage
            title="Make Nature your business - Regenera | Carbon Offsetting Company Peru | Reduce Ecological & Carbon Footprints in Peru"
            description="Regenera: Best environmental consulting company in Peru, our team helps your organization make a positive impact on nature, climate, and communities. Beyond carbon offsets and tree planting, Regenera supports the management of living landscapes, led by those who know their lands best."
            schema={{
                '@context': 'http://schema.org',
                '@type': 'HomePage',
                description:
                    'Empresas e instituciones de todo tamaño que velan por la naturaleza transformando sus impactos ambientales en acciones positivas.',
                name: 'Home',
            }}
        >
            <div>
                <Header currentPath={location.pathname} user={user} history={history} />
                <HeroSection history={history} />
                <TakeAction history={history} />
                <CompanyLogos />

                <PartnersImpact history={history} />

                <BenefitsMembership />
                <Mission />
                <Testimonials />
                <Footer history={history} />
            </div>
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(NewLandingPage));
