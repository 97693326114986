import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { OrangeBtn } from '../../../newComponents/Button/ButtonSkin';
import CustomRadioSelect from '../../../newComponents/SelectRadioDropdown';

import { Formik, Form } from 'formik';
import TextField from '../../../newComponents/Form/TextField';
import ReCaptcha from 'react-google-recaptcha';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

import * as Yup from 'yup';
import { countryCodes } from '../../../translations/countryCodes';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import CheckBoxInfo from '../../../newComponents/checkbox';
import { sendLandscapeProposal } from '../utils';

const ModalFormBoxContainer = styled.div`
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    width: 100%;
    max-width: 505px;

    .form-box {
        position: relative;
        .form-title {
            font-size: 19px;
            line-height: 27px;
            font-weight: 600;
            margin-bottom: 27px;
        }
        .form-section {
            gap: 16px;
            .one-col {
                .consent-box {
                    input[type='checkbox'] {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        .cta {
            text-align: right;
            margin-top: 20px;
            margin-bottom: 7px;
            button {
                width: auto;
            }
            .login-cta {
                line-height: 150%;
                margin-top: 27px;
                a {
                    font-weight: 600;
                    color: ${({ theme }) => theme.colors.title_green};
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .thankyou-box {
        padding: 100px 0;
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-box {
            padding-top: 15px;
            .form-title {
                font-size: 16px;
                line-height: 180%;
                margin-bottom: 10px;
            }
            .cta {
                .login-cta {
                    margin-top: 10px;
                }
            }
        }
        .form-section {
            gap: 24px;
        }
    }
`;

const ModalFormBox = ({ closeBtn, intl }) => {
    const reCaptchaRefMessage = useRef(null);
    const [showError, setShowError] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const transformedCountryCodes = [
        {
            name: 'countryTarget',
            value: <FormattedMessage id="GuardiansPage.SelectCountry.SelectCountry" />,
        }, // Default object
        ...countryCodes.map(({ code, en }) => ({
            name: en,
            value: en,
        })),
    ];
    // firstName
    const firstNameLabel = intl.formatMessage({
        id: 'ModalForm.firstNameLabel',
    });
    const firstNamePlaceholder = intl.formatMessage({
        id: 'ModalForm.firstNamePlaceholder',
    });

    // lastName
    const lastNameLabel = intl.formatMessage({
        id: 'ModalForm.lastNameLabel',
    });
    const lastNamePlaceholder = intl.formatMessage({
        id: 'ModalForm.lastNamePlaceholder',
    });

    const emailLabel = intl.formatMessage({
        id: 'ModalForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'ModalForm.emailPlaceholder',
    });

    const regionLabel = intl.formatMessage({
        id: 'ModalForm.regionLabel',
    });
    const regionPlaceholder = intl.formatMessage({
        id: 'ModalForm.regionPlaceholder',
    });

    const countryLabel = intl.formatMessage({
        id: 'ModalForm.countryLabel',
    });

    ///
    const privacyLink = (
        <Link to="/privacy-policy" target="_blank">
            <FormattedMessage id="ModalForm.privacyPolicyLinkText" />
        </Link>
    );
    const consentLabel = (
        <FormattedMessage id="ModalForm.Privacy.Consent" values={{ privacyLink }} />
    );

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        region: '',
        privacyConsent: true,
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required(<FormattedMessage id="ModalForm.firstName.errMsg" />),
        lastName: Yup.string().required(<FormattedMessage id="ModalForm.lastName.errMsg" />),
        email: Yup.string()
            .email(<FormattedMessage id="ModalForm.emailInv.errMsg" />)
            .required(<FormattedMessage id="ModalForm.emailReq.errMsg" />),
        country: Yup.string().required(<FormattedMessage id="ModalForm.country.errMsg" />),
        region: Yup.string().required(<FormattedMessage id="ModalForm.region.errMsg" />),
        privacyConsent: Yup.bool().oneOf(
            [true],
            <FormattedMessage id="ModalForm.privacyConsent.errMsg" />
        ),
    });

    const onSubmit = async values => {
        const { ...restValues } = values;
        console.log('valuesform', values);
        try {
            let captchaToken;
            if (reCaptchaRefMessage.current) {
                captchaToken = await reCaptchaRefMessage.current.executeAsync();
                reCaptchaRefMessage.current.reset();
            }

            if (!!captchaToken && !!restValues) {
                const proposalData = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    region: values.region,
                    country: values.country,
                };
                const result = await sendLandscapeProposal(proposalData);
                if (result.status === 'success') {
                    setIsEmailSent(true);
                } else {
                    setShowError(result.message);
                }
            } else {
                console.error('Failed to generate captchaToken');

                return; // Exit early if captchaToken is undefined
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <ModalFormBoxContainer className="modal-box-class">
                {/* form starts */}
                {showError && (
                    <div className="error-text small">
                        <FormattedMessage id="AuthenticationPage.signupFailed" />
                    </div>
                )}
                {!isEmailSent && (
                    <div className="form-box w-full mx-auto flex flex-col">
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {formik => {
                                return (
                                    <Form onChange={() => setShowError(false)}>
                                        <ReCaptcha
                                            ref={reCaptchaRefMessage}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            size="invisible"
                                        />

                                        <div className="form-section flex flex-col">
                                            <div className="two-col flex space-between">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="text"
                                                        label={firstNameLabel}
                                                        name="firstName"
                                                        placeholder={firstNamePlaceholder}
                                                        aria-describedby="firstnamehelp"
                                                        value={formik.values.firstName}
                                                        className={`form-control  ${formik.touched
                                                            .firstName &&
                                                            formik.errors.firstName &&
                                                            'input-error'}`}
                                                    />
                                                </div>
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="text"
                                                        label={lastNameLabel}
                                                        name="lastName"
                                                        placeholder={lastNamePlaceholder}
                                                        value={formik.values.lastName}
                                                        aria-describedby="lastnamehelp"
                                                        className={`form-control  ${formik.touched
                                                            .lastName &&
                                                            formik.errors.lastName &&
                                                            'input-error'}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="one-col">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="email"
                                                        label={emailLabel}
                                                        name="email"
                                                        placeholder={emailPlaceholder}
                                                        aria-describedby="emailhelp"
                                                        className={`form-control  ${formik.touched
                                                            .email &&
                                                            formik.errors.email &&
                                                            'input-error'}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="one-col">
                                                <div className="input-box-section">
                                                    <label for="email" className="small">
                                                        {countryLabel}
                                                    </label>
                                                    <div className="select-box">
                                                        <CustomRadioSelect
                                                            name="country"
                                                            optionsList={transformedCountryCodes}
                                                            value={formik.values.country}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'country',
                                                                    value
                                                                )
                                                            }
                                                            disabled={false}
                                                            classname={`form-control text-left ${
                                                                formik.errors.country
                                                                    ? 'input-error'
                                                                    : ''
                                                            }`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="one-col">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="text"
                                                        label={regionLabel}
                                                        name="region"
                                                        placeholder={regionPlaceholder}
                                                        aria-describedby="regionhelp"
                                                        className={`form-control  ${formik.touched
                                                            .region &&
                                                            formik.errors.region &&
                                                            'input-error'}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="one-col">
                                                <CheckBoxInfo
                                                    labelName={consentLabel}
                                                    labelId="privacyConsent"
                                                    name="privacyConsent"
                                                    checkboxSection="consent-box"
                                                    isChecked={isChecked}
                                                    onChange={() => {
                                                        setIsChecked(!isChecked);
                                                        formik.setFieldValue(
                                                            'privacyConsent',
                                                            !isChecked
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="cta mx-auto">
                                            <OrangeBtn
                                                type="submit"
                                                disabled={!formik.isValid}
                                                className={!formik.isValid ? 'disabled' : null}
                                            >
                                                <FormattedMessage id="ModalForm.Send" />
                                            </OrangeBtn>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                )}
                {/* forms ends */}

                {/* thank you box */}
                {isEmailSent && (
                    <div className="thankyou-box text-center">
                        <h3>
                            <FormattedMessage id="ModalForm.Thanks" />
                        </h3>
                        <FormattedHTMLMessage
                            id="ModalForm.Thanks.Description"
                            values={{
                                p: chunks => <p>{chunks}</p>,
                            }}
                        />
                    </div>
                )}

                {/* thank you box ends */}
                <button
                    onClick={closeBtn}
                    className="modal-close-btn flex items-center justify-center"
                >
                    <IoClose />
                </button>
            </ModalFormBoxContainer>
        </>
    );
};

export default compose(injectIntl)(ModalFormBox);
