export const getDateFromTimestamp = (unixTimestamp) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObj = new Date(unixTimestamp * 1000);
    const month = months[dateObj.getMonth()]
    const year = dateObj.getFullYear()
    return month + ' ' + year;
}

export const getNextMonth = (unixTimestamp) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObj = new Date(unixTimestamp * 1000);
    return months[dateObj.getMonth() + 1];
}

export const getTotalPayout = (payoutList) => {
    let totalPay = 0;
    payoutList.map(pay => (
        totalPay = totalPay + pay.amount
    ));
    const payout = (payoutList[0].currency == "usd" ? "$" : "S/.") + ' ' + totalPay/100;
    return payout;
}