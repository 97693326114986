import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OrgHeader, StyledLogoContainer, StyledCityContainer,StyledSmallCityContainer, ManageUser, ListContainer, ListButton, LogoGroup } from './Styles';
import regeneraLogo from '../../../../assets/newAssets/logo-regenera-negativo.svg'
import city from '../../../../assets/newAssets/city.svg';
import Button from '../../../../newComponents/Button';
import Footer from '../../section/Footer/Footer';
import Arrow from '../../../../assets/newAssets/arrow-down-orange.svg';
import Setting from '../../../../assets/newAssets/setting.svg';
import Logout from '../../../../assets/newAssets/logout.svg';
import { isNil } from 'lodash';
import { logout } from '../../../../ducks/Auth.duck';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import { IntlContext } from '../../../../IntlContext';
import hamburger from '../../../../assets/newAssets/hamburger.svg';
import SmallCity from '../../../../assets/newAssets/city-small.svg';
import {
    StyledUsername,
    StyledMobileMenuContainer,
    StyledPopper,
    StyledMenuMobile,
    MenuHeaderContainer,
    StyledButton,
    StyledLanguagesList,
    StyledListItem,
    StyledDropdown,
    MenuContainer,
    StyledLogoutButton,
    StyledLoginMenuContainer,
    StyledNavLink,
    StyledList,
    StyledManageUser,
    StyledDesktopListItem,
  } from './Styles';

  const secondaryNavItems = [
    {
      path: '/#ourcommunity',
      title: <FormattedMessage id="NewLandingPage.header.becomeEarthling" />,
    },
    {
      path: '/#ourstory',
      title: <FormattedMessage id="NewLandingPage.header.ourStory" />,
    },
    // {
    //   path: '/subscribe/landscapes',
    //   title: <FormattedMessage id="NewLandingPage.header.subPlans" />,
    // },
  ];

  const primaryNavItems = [
    {
      path: '/organisation/calculator',
      title: <FormattedMessage id="OrganisationCalculator.Tabs.CompanyDetails" />,
    },
    // {
    //   path: '/organisation/calculator/direct-emission',
    //   title: <FormattedMessage id="OrganisationCalculator.Tabs.DirectEmission" />,
    // },
    // {
    //   path: '/organisation/calculator/electricity-emission',
    //   title: <FormattedMessage id="OrganisationCalculator.Tabs.ElectricityEmission" />,
    // },
    // {
    //   path: '/organisation/calculator/indirect-emission',
    //   title: <FormattedMessage id="OrganisationCalculator.Tabs.OtherEmission" />,
    // },
    // {
    //   path: '/organisation/calculator/result',
    //   title: <FormattedMessage id="OrganisationCalculator.Tabs.Results" />,
    // },
  ];
  
  const NavItem = ({ path, title, active, bold, type, data, transparent }) => {
    return (
      <StyledNavLink
        active={active}
        to={data ? { pathname: path, state: { ...data } } : path}
        bold={bold}
        fontSize={type === 'primary' ? { _: 1, xl: 2 } : [1]}
        fontWeight={type === 'primary' ? [6] : [3, 3, 4]}
        transparent={transparent}
      >
        {title}
      </StyledNavLink>
    );
  };
  
  const NavbarMenu = connect(null, { logout })(({ currentPath, user, transparent, logout }) => {
    return (
      <>
        <StyledManageUser>
            <div>Manage Users</div>
            {!isNil(user)?<div>{user.name}</div>:<div></div>}
            {!isNil(user)?<button onClick={() => logout()}>Log out</button>:null}
        </StyledManageUser> 
        <StyledList>
          {primaryNavItems.map(item => (
            <li key={item.title}>
              <NavItem
                path={item.path}
                title={item.title}
                active={currentPath === item.path}
                bold
                type="primary"
                transparent={transparent}
              />
            </li>
          ))}
        </StyledList>
           
      </>
    );
  });
  const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
  });

const LoginMenu = connect(null, { logout })(({ items, user, logout, transparent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
  
    const handleOutsideClick = e => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [containerRef]);
  
    const open = () => {
      setIsOpen(!isOpen);
    };
  
    const close = () => {
      setIsOpen(false);
    };
  
    return (
      <StyledDropdown ref={containerRef}>
        <StyledButton onClick={open}>
          <StyledUsername fontSize={1} fontWeight={6}>
            {user.initials}
          </StyledUsername>
        </StyledButton>
  
        {isOpen && (
          <MenuContainer>
            <StyledLogoutButton onClick={() => logout()}>
              <FormattedMessage id="NewLandingPage.header.logout" />
            </StyledLogoutButton>
          </MenuContainer>
        )}
      </StyledDropdown>
    );
  });

const MobileHeader = connect(mapStateToProps, { changeLanguage })(
    ({ currentPath, selectedLanguage, changeLanguage, languages, user }) => {
      const [showPopper, setShowPopper] = useState(false);
      const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(IntlContext);
  
      useEffect(() => {
        //call switch langugae here
        if (selectedLanguage.short === 'EN') {
          switchToEnglish();
        } else if (selectedLanguage.short === 'ES') {
          switchToSpanish();
        } else {
          switchToPortuguese();
        }
      }, [selectedLanguage]);
  
      const showHandler = () => {
        setShowPopper(true);
      };
  
      const closeHandler = () => {
        setShowPopper(false);
      };
  
      return (
        <StyledMobileMenuContainer>
          {!showPopper ? (
            <StyledLoginMenuContainer>
              {user && (
                <LoginMenu
                  user={user}
                />
              )}
              <StyledButton onClick={showHandler}>
                <img src={hamburger} alt="hamburger" />
              </StyledButton>
            </StyledLoginMenuContainer>
          ) : (
            <StyledPopper>
              <MenuHeaderContainer>
                <p>Menu</p>
                <StyledButton onClick={closeHandler}>X</StyledButton>
              </MenuHeaderContainer>
              <StyledMenuMobile>
                <NavbarMenu currentPath={currentPath} user={user} />
              </StyledMenuMobile>
              <StyledLanguagesList>
                {languages.map((item, index) => (
                  <React.Fragment key={`mobile-${item.name}`}>
                    {index > 0 && <li>-</li>}
                    <StyledListItem
                      active={
                        selectedLanguage &&
                        selectedLanguage.name.toLowerCase() === item.name.toLowerCase()
                      }
                    >
                      <StyledButton onClick={() => changeLanguage(item.short)}>
                        {item.name}
                      </StyledButton>
                    </StyledListItem>
                  </React.Fragment>
                ))}
              </StyledLanguagesList>
            </StyledPopper>
          )}
        </StyledMobileMenuContainer>
      );
    }
  );


const Header = connect(null, { logout })((props)=>{

    const [Organisation,setOrganisation]=useState('Add Organization');
    const [userType,setUserType]=useState('');
    const [user,setUser]=useState('');
    const userDetails = useSelector((state) => state.user.currentUser);
    useEffect(() => {
        const fetchData = async () => {
            const organizationName = props.orgDetails;
            if(!isNil(organizationName)){
                setOrganisation(organizationName);
            }
            if(!isNil(userDetails)){
                setUser(userDetails.attributes.profile.firstName);
                setUserType(userDetails.attributes.profile.userType);
            }
        }
        fetchData();
    }, [props.orgDetails,userDetails]);
    return (
        <>
        <OrgHeader>
           <StyledLogoContainer>
               <a href="/"><img src={regeneraLogo} alt="logo" height='100%' width='100%'/></a>
           </StyledLogoContainer>
           
           <MobileHeader user={props.user}/>
           <StyledSmallCityContainer>
               <img src={SmallCity} alt='city image' height='100%' width='100%'/>
           </StyledSmallCityContainer>
           <StyledCityContainer>
               <img src={city} alt='city image' height='100%' width='100%'/>
           </StyledCityContainer>
           <ManageUser>
               <ListContainer>
                    {Organisation}
                    <LogoGroup>
                        <a href="#"><img src={Arrow} alt="logo" height='100%' width='100%'/></a>
                    </LogoGroup>
               </ListContainer>
               <ListContainer>
                    {user}
                    <LogoGroup>
                        <a href={userType === 'personas' ? '/friends-profile/profileandaccount':'/partner-profile/profileandaccount'}><img src={Setting} alt="logo" height='100%' width='100%'/></a>
                        <a href="#"><img src={Logout} alt="logo" height='100%' width='100%' onClick={() => props.logout()}/></a>
                    </LogoGroup>
               </ListContainer>
               {/* <ListButton>
                   <Button variant="filled" size="lg">Manage Users</Button>
               </ListButton> */}
           </ManageUser>
        </OrgHeader>
        </>
    );
})

export default Header;
