import styled from 'styled-components';

export const HowitWorksSection = styled.section`
    padding: 110px 4.8rem 100px;
    background-color: #f8fbf6;
    .title-box {
        margin-bottom: 60px;
    }
    .steps-how {
        max-width: 1065px;
        .regular {
            display: flex;
            flex-direction: column;
            gap: 100px;
        }
        .img-text {
            align-items: flex-start;
            gap: 106px;
            .picture-box {
                flex: 1 0 auto;
                img {
                    border-radius: 8px;
                }
            }
        }
        .text-section {
            color: ${({ theme }) => theme.colors.text_color};
            line-height: 180%;
            margin-top: 16px;
            .li-list {
                position: relative;
                padding-left: 20px;
                &:first-child {
                    display: none;
                }
                &::before {
                    content: '•';
                    position: absolute;
                    left: 0;
                    top: -2px;
                    font-size: 30px;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 20px 0px;
        .title-box {
            margin-bottom: 0;
            padding: 0 24px;
        }
        .steps-how {
            .step-row {
                box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
                padding: 16px;
                background-color: #fff;
                margin-top: 20px;
                margin: 20px 20px 35px;
                border-radius: 8px;
                .img-text {
                    gap: 10px;
                    .picture-box {
                        align-self: center;
                    }
                }
            }
            .slick-slider {
                padding-bottom: 45px;
            }
            .slick-next,
            .slick-prev {
                width: auto;
                height: 45px;
                border-radius: 30px;
                color: #fff;
                padding: 8px 39px;
                font-size: 16px;
                font-weight: 600;
                background: #6ea44c;
                bottom: 20px;
                top: auto;
                z-index: 99;
                transform: translate(0);
            }
            .slick-next {
                right: 15px;
                &::before {
                    content: none;
                }
            }
            .slick-prev {
                left: 15px;
                &::before {
                    content: none;
                }
            }
            .slick-disabled {
                background: #cecece;
            }
            .slick-dots {
                bottom: 30px;
            }
            .faq-link {
                padding-left: 20px;
                margin-top: 28px;
                a {
                    display: block;
                }
            }
        }
    }
`;
