import React, { useState } from 'react';
import css from './Hero.css';
import { Modal, SecondaryButton } from '../../components';
import { FaRegPlayCircle } from 'react-icons/fa';

const heroSize = size => {
    if (size === 'medium') {
        return css.heroMedium;
    } else if (size === 'big') {
        return css.heroBig;
    }
};

const Hero = props => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={`${css.hero} ${heroSize(props.size)}`}>
            <img
                alt=""
                src={props.image}
                className={css.image}
                style={{ objectPosition: props.backgroundPosition }}
            />
            <div className={css.sectionContent}>
                <h1 className={`${css.title} `}>{props.title}</h1>
                <p className={`${css.subtitle}`}>{props.subtitle}</p>
            </div>
        </div>
    );
};

Hero.defaultProps = {
    size: 'big',
    backgroundPosition: 'center center',
    videotext: 'Ver video',
    urlvideoId: null,
};

export default Hero;
