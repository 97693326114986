import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import alberguebl from '../../../../assets/newAssets/company-logo/albergue-bl.png';
import candorbl from '../../../../assets/newAssets/company-logo/candor-bl.png';
import centificabl from '../../../../assets/newAssets/company-logo/centifica-bl.png';
import delosibl from '../../../../assets/newAssets/company-logo/delosi-bl.png';
import explorandesbl from '../../../../assets/newAssets/company-logo/explorandes-bl.png';
import floranfaunabl from '../../../../assets/newAssets/company-logo/floranfauna-bl.png';
import gerpalbl from '../../../../assets/newAssets/company-logo/gerpal-bl.png';
import pukaninabl from '../../../../assets/newAssets/company-logo/pukanina-bl.png';
import seperubl from '../../../../assets/newAssets/company-logo/seperu-bl.png';
import toyotabl from '../../../../assets/newAssets/company-logo/toyota-bl.png';
import tukuybl from '../../../../assets/newAssets/company-logo/tukuy-bl.png';

import amazonastl from '../../../../assets/newAssets/company-logo/amazonas-tl.png';
import andeanlodgestl from '../../../../assets/newAssets/company-logo/andeanlodges-tl.png';
import antiguatl from '../../../../assets/newAssets/company-logo/antigua-tl.png';
import auquitl from '../../../../assets/newAssets/company-logo/auqui-tl.png';
import aynicentertl from '../../../../assets/newAssets/company-logo/aynicenter-tl.png';
import hosegtl from '../../../../assets/newAssets/company-logo/hoseg-tl.png';
import markhamcollegetl from '../../../../assets/newAssets/company-logo/markhamcollege-tl.png';
import primatl from '../../../../assets/newAssets/company-logo/prima-tl.png';
import rainforesttl from '../../../../assets/newAssets/company-logo/rainforest-tl.png';
import saexpeditionstl from '../../../../assets/newAssets/company-logo/saexpeditions-tl.png';
import strategiktl from '../../../../assets/newAssets/company-logo/strategik-tl.png';
import trentl from '../../../../assets/newAssets/company-logo/tren-tl.png';
import zooperutl from '../../../../assets/newAssets/company-logo/zooperu-tl.png';

import LogoSlider from '../../../../newComponents/LogoSlider/LogoSlider';
import { Alliances } from './Styles';

const NetworkLogos = () => {
    // company logos

    const sliderDataTop = [
        { id: 1, img: `${amazonastl}` },
        { id: 2, img: `${andeanlodgestl}` },
        { id: 3, img: `${antiguatl}` },
        { id: 4, img: `${auquitl}` },
        { id: 5, img: `${aynicentertl}` },
        { id: 6, img: `${hosegtl}` },
        { id: 7, img: `${markhamcollegetl}` },
        { id: 8, img: `${primatl}` },
        { id: 9, img: `${rainforesttl}` },
        { id: 10, img: `${saexpeditionstl}` },
        { id: 11, img: `${strategiktl}` },
        { id: 12, img: `${trentl}` },
        { id: 13, img: `${zooperutl}` },
    ];

    const sliderDataBottom = [
        { id: 1, img: `${alberguebl}` },
        { id: 2, img: `${candorbl}` },
        { id: 3, img: `${centificabl}` },
        { id: 4, img: `${delosibl}` },
        { id: 5, img: `${explorandesbl}` },
        { id: 6, img: `${floranfaunabl}` },
        { id: 7, img: `${gerpalbl}` },
        { id: 8, img: `${pukaninabl}` },
        { id: 9, img: `${seperubl}` },
        { id: 10, img: `${toyotabl}` },
        { id: 11, img: `${tukuybl}` },
    ];

    const sliderSettings = {
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: true,
        cssEase: 'linear',
        arrows: false,
        infinite: true,

        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };

    const sliderSettingsBottom = {
        ...sliderSettings,
        speed: 10000,
    };

    return (
        <Alliances>
            <div className="company-group flex justify-start flex-col">
                <div className="title-box">
                    <h2>
                        <FormattedMessage id="About.networklogos.title" />
                    </h2>
                </div>
                <div className="company-logo-container">
                    <LogoSlider settings={sliderSettings} data={sliderDataTop} />
                </div>
                <div className="company-logo-container">
                    <LogoSlider settings={sliderSettingsBottom} data={sliderDataBottom} />
                </div>
            </div>
        </Alliances>
    );
};

export default NetworkLogos;
