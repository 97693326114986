import styled from 'styled-components';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';

export const LandscapeMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3EC;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;

export const StyledImageContainer = styled.div`
    text-align: left;
    background-color: #000000;
    height: auto;
    width: 45%;
    break-inside: avoid;
    margin-bottom: 25px;
    & > img {
        width: 100%;
        height: 244px;
        object-fit: cover;
        @media screen and (max-width: 768px) {
            height: 450px;
            max-width: 100%;
        }
        @media screen and (max-width: 425px) {
            height: 200px;
        }
    }
    .btn {
        margin: 26px 0px;
        & > a > button {
            display: block;
            margin: 0 auto;
        }
        @media screen and (max-width: 568px) {
            margin: 26px 0px;
        }
    }
    @media screen and (max-width: 568px) {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`;
export const ButtonBox = styled.div`
    margin-top: 30px;
    padding-left: 0px;
    padding-right: 40px;

    & > a > button {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px auto;
    padding: 25px 25px 35px 25px;
    height: auto;
    column-gap: 25px;
    .grid-item {
        background-color: white;
        padding: 40px;
        margin-bottom: 20px;
        border-radius: 10px;
        break-inside: avoid;
        text-align: center;
        .link {
            color: #000000;
            text-decoration: none;
        }
    }
    .grid-item:hover {
        box-shadow: 0px 8px 8px 0 #f2f2f2, 0px 10px 20px 0 #f2f2f2;
        transition: 0.5s;
        margin-top: -2px;
    }
    .after-btn {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 25px 15px 35px 15px;

        .after-btn > a > button {
            padding: 5px;
            font-size: 16px;
            max-width: 465px;
            width: 100%;
        }
    }
    @media screen and (max-width: 568px) {
        columns: 1;
        width: 100%;
        padding: 25px 15px 35px 15px;
    }
`;
export const TextBox = styled.div`
    width: 100%;
    color: #ffffff;
    text-align: center;
    @media screen and (max-width: 568px) {
        margin-left: 0px;
        width: 100%;
    }
`;
export const Title = styled.p`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 700;
    font-size: 27.7083px;
    line-height: 37px;
`;
export const Desc = styled.p`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
`;
export const CardTitle = styled.h2`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    width: fit-content;
    color: #000000;
    margin-left: 40px;
    margin-top: 20px;
    @media screen and (max-width: 568px) {
        width: 70%;
        margin-right: 0px;
    }
`;

export const StyledActionContainer = styled.div`
    & > :first-child > :first-child {
        text-align: center;
    }
`;

export const Chart = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
`;
export const Box = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
`;

export const Select = styled.div`
    width: 30%;
    margin: 30px 42px;
    & > select > option {
        background-color: #ffffff;
    }
`;
export const Empty = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
`;
export const GrayText = styled.div`
    padding-top: 20px;
    color: #5a5a5a;
    margin: auto;
    width: 70%;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: ${props => (props.empty ? '24px' : '19px')};
    line-height: 26px;
    text-align: center;
`;
export const ButtonContainer = styled.div`
    margin: 40px auto;
    width: fit-content;
`;
