import styled from 'styled-components';


export const ToggleWrapper = styled.div`
    margin: 20px auto;
    width: 92%;
    padding:20px;
    background-color: #FFFFFF;
    border-radius: 5px;
    display:flex;
    justify-content:space-between;
`;

export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
    margin-left:50px;
    @media screen and (max-width: 500px) {
        margin-left:10px;
    }
`;

export const PlusLogo = styled.div`
    width:20px;
    margin-right:30px;
`;
