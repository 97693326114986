import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Header from '../section/Header/Header.js';
import Footer from '../section/Footer/Footer.js';
import Tabs from '../section/Tabs/Tabs.js';
import Heading from '../section/Heading/Heading.js';
import ActivityCard from './components/ActivityCard/activityCard.js';
import ActivityCard4 from './components/ActivityCard4/activityCard4.js';
import ActivityCardNoAdd from './components/ActivityCardNoAdd/activityCard.js';
import Form from '../section/ProfileForm/ProfileForm.js';
import ScopeResult from './components/ScopeResult/scopeResult.js';
import { DetailsContainer, SaveButton, Error } from './Style.js';
import Button from '../../../newComponents/Button';
import {
    getCurrentOrganization,
    saveOrganisationEmissionActivities,
    getEmissionActivities,
    getOrganizationEmissionDetails,
    getUnits,
} from '../../../coreApi';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';
import { FormattedMessage } from 'react-intl';

const IndirectEmission = props => {
    const [orgDetails, setOrgDetails] = useState({});
    const [error, setError] = useState(false);
    const [duplicateError, setDuplicateError] = useState(false);
    const [units, setUnits] = useState({});
    const [emissionActivityDetails, setEmissionActivityDetails] = useState({});
    const [orgEmissionDetails, setOrgEmissionDetails] = useState({});
    const result = useRef(null);
    let activities1 = [{}];
    let activities2 = [{}];
    let activities3 = [{}];
    const location = useLocation();
    useEffect(() => {
        const fetchData = async () => {
            const Details = location.state;
            // const Details = await getCurrentOrganization()
            // .then((response) =>{
            setOrgDetails(Details);
            getOrganizationEmissionDetails(
                'scope3',
                Details.data.id,
                Details.data.inventoryYear
            ).then(response => {
                setOrgEmissionDetails(response.data);
            });
            //      return response;
            // })
            getEmissionActivities('scope3').then(response => {
                setEmissionActivityDetails(response.data);
            });

            getUnits().then(response => {
                setUnits(response.data);
            });
        };
        fetchData();
    }, []);
    const PostData = () => {
        const activities = [...activities1, ...activities2, ...activities3];
        let desiredData = [];
        activities.map(data => {
            if (!isNil(data.activityId) && !isNil(data.value) && !isNil(data.unit)) {
                if (!isEmpty(data.activityId) && data.value >= 0 && !isEmpty(data.unit)) {
                    desiredData.push({ id: data.activityId, value: data.value, unit: data.unit });
                }
            }
        });
        saveOrganisationEmissionActivities(
            desiredData,
            orgDetails.data.id,
            orgDetails.data.inventoryYear
        )
            .then(response => {
                getOrganizationEmissionDetails(
                    'scope3',
                    orgDetails.data.id,
                    orgDetails.data.inventoryYear
                ).then(response => {
                    setOrgEmissionDetails(response.data);
                    if (isEmpty(response.data['scope1'])) {
                        setError(true);
                        setDuplicateError(false);
                    } else {
                        setError(false);
                        setDuplicateError(false);
                    }
                });
                return response;
            })
            .catch(response => {
                setDuplicateError(true);
                setError(false);
            });
    };
    const saveActivities = (data, type) => {
        if (type === 'Transportation') {
            activities1 = [...data];
        }
        if (type === 'Water Use and Treatment') {
            activities2 = [...data];
        }
        if (type === 'Waste Disposal') {
            activities3 = [...data];
        }
    };
    return (
        <DetailsContainer>
            {!isEmpty(orgDetails) ? (
                <Header user={props.user} orgDetails={orgDetails.data.organizationName} />
            ) : (
                <Header orgDetails={null} />
            )}
            <Tabs id="IndirectEmission" />
            <Heading
                headingId="OrganisationCalculator.IndirectEmission.Heading"
                subHeadingId="OrganisationCalculator.IndirectEmission.SubHeading"
            />
            {!isEmpty(emissionActivityDetails) && !isEmpty(units) ? (
                <ActivityCard
                    key={uuid()}
                    headingId="OrganisationCalculator.Transportation.Heading"
                    subHeadingId="OrganisationCalculator.Transportation.SubHeading"
                    type="Transportation"
                    scope="scope3"
                    emissionActivityDetails={emissionActivityDetails}
                    units={units}
                    label="Transport Type"
                    emissionActivities={saveActivities}
                    emissionActivitiesData={orgEmissionDetails}
                />
            ) : null}
            {!isEmpty(emissionActivityDetails) && !isEmpty(units) ? (
                <ActivityCardNoAdd
                    key={uuid()}
                    headingId="OrganisationCalculator.WaterUseAndTreatment.Heading"
                    subHeadingId="OrganisationCalculator.WaterUseAndTreatment.SubHeading"
                    type="Water Use and Treatment"
                    scope="scope3"
                    emissionActivityDetails={emissionActivityDetails}
                    units={units}
                    label="Water Consumption"
                    emissionActivities={saveActivities}
                    emissionActivitiesData={orgEmissionDetails}
                />
            ) : null}
            {!isEmpty(emissionActivityDetails) && !isEmpty(units) ? (
                <ActivityCard4
                    key={uuid()}
                    headingId="OrganisationCalculator.WasteDisposal.Heading"
                    subHeadingId="OrganisationCalculator.WasteDisposal.SubHeading"
                    type="Waste Disposal"
                    scope="scope3"
                    emissionActivityDetails={emissionActivityDetails}
                    units={units}
                    label="Waste Disposal"
                    label2="Waste Disposal Type"
                    emissionActivities={saveActivities}
                    emissionActivitiesData={orgEmissionDetails}
                />
            ) : null}
            <SaveButton>
                {duplicateError ? (
                    <Error>
                        <FormattedMessage id="OrganisationCalculator.Error.DuplicateActivity" />
                    </Error>
                ) : null}
                {isNil(orgEmissionDetails['scope3']) && error ? (
                    <Error>
                        <FormattedMessage id="OrganisationCalculator.Error.EmptyEmissionDetails" />
                    </Error>
                ) : null}
                <Button onClick={() => PostData()}>
                    <FormattedMessage id="OrganisationCalculator.Button.Save" />
                </Button>
            </SaveButton>
            {!isEmpty(orgEmissionDetails) && !isNil(orgEmissionDetails['scope3']) ? (
                <ScopeResult
                    orgDetails={orgDetails}
                    refProp={result}
                    emissionActivitiesData={orgEmissionDetails}
                    type={['Transportation', 'Water Use and Treatment', 'Waste Disposal']}
                    scope="scope3"
                    label="Water Consumption"
                    path="/organisation/calculator/result"
                />
            ) : null}
            <Footer />
        </DetailsContainer>
    );
};
const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
    userDetails: storeState.user.currentUser,
});

const IndirectEmissionPage = connect(mapStateToProps)(withRouter(IndirectEmission));
export default IndirectEmissionPage;
