import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button, Form } from '../../components';
import * as coreAPI from '../../coreApi';
import css from './NewAction.css';

const TITLE_MAX_LENGTH = 60;

const NewAction = props => {
    const { intl, history } = props;

    const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionFormStory.title' });

    const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionFormStory.description',
    });

    const submitButtonText = intl.formatMessage({ id: 'NewAction.SubmitButtonText' });

    const [actionTitle, setActionTitle] = useState('');
    const [actionDescription, setActionDescription] = useState('');
    const [actionPicture, setActionPicture] = useState(null);
    const [fileName, setFileName] = useState('');
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [landId, setLandId] = useState(0);
    const handleSubmit = e => {
        e.preventDefault();
        if (actionTitle.trim() != '' && actionDescription.trim() != '' && actionPicture != null) {
            setSubmitInProgress(true);
            coreAPI
                .uploadImage(actionPicture)
                .then(response => {
                    const fileId = response.data.fileId;
                    const newActionRequest = {
                        title: actionTitle.trim(),
                        description: actionDescription.trim(),
                        image: fileId,
                        landscapeId: landId,
                    };
                    setSubmitInProgress(true);
                    coreAPI
                        .newAction(newActionRequest)
                        .then(response => {
                            setSubmitDisabled(true);
                            setShowSuccessMessage(true);
                            setTimeout(() => {
                                history.push('/');
                            }, 2000);
                        })
                        .catch(error => {
                            console.log(error);
                            setErrorMessage('action-creation-failed');
                        })
                        .finally(() => {
                            setSubmitInProgress(false);
                        });
                })
                .catch(error => {
                    console.log(error);
                    setErrorMessage('image-upload-failed');
                })
                .finally(() => {
                    setSubmitInProgress(false);
                });
        } else {
            setErrorMessage('Failed');
        }
    };

    const checkForm = (isFileSelect = false) => {
        if (
            actionTitle.trim() != '' &&
            actionDescription.trim() != '' &&
            (actionPicture != null || isFileSelect)
        ) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    };

    const handleChange = e => {
        if (e.target.name == 'title') {
            setActionTitle(e.target.value);
        } else if (e.target.name == 'description') {
            setActionDescription(e.target.value);
        }
        checkForm();
    };

    const onFileChange = e => {
        if (e.target.files[0]) {
            setFileName(e.target.files[0].name);
            setActionPicture(e.target.files[0]);
            checkForm(true);
        }
    };

    function handleLandChange(event) {
        setLandId(event.target.value);
    }

    return (
        <div className={css.root}>
            <h1 className={css.title}>
                <FormattedMessage id="EditListingDescriptionPanel.createListingStoryTitle" />
            </h1>
            <Form onSubmit={handleSubmit}>
                <div className={css.actionTitle}>
                    <label htmlFor="title">{titleMessage}</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        label={titleMessage}
                        placeholder=""
                        maxLength={TITLE_MAX_LENGTH}
                        value={actionTitle}
                        onChange={handleChange}
                        autoFocus
                    />
                </div>
                <div className={css.actionDescription}>
                    <label htmlFor="description">{descriptionMessage}</label>
                    <textarea
                        // type='text'
                        row="40"
                        cols="50"
                        id="description"
                        name="description"
                        label={descriptionMessage}
                        value={actionDescription}
                        onChange={handleChange}
                        placeholder=""
                        maxLength={600}
                    />
                </div>
                <div className={css.actionPhoto}>
                    <label htmlFor="actionImage" className={css.actionImageLabel}>
                        <span>
                            <FormattedMessage id="EditListingPhotosForm.chooseImage" />
                        </span>
                    </label>
                    <input
                        type="file"
                        className={css.actionImageInput}
                        id="actionImage"
                        accept="image/*"
                        name="actionImage"
                        placeholder=""
                        maxLength={600}
                        onChange={onFileChange}
                    />
                    <span className={css.actionPhotoFileName}>{fileName}</span>
                </div>
                <div className={css.actionTitle}>
                    <label htmlFor="landscape">Select the landscape you want to support</label>
                    <select onChange={handleLandChange}>
                        <option value="1">Manu</option>
                        <option value="4">Canete</option>
                        <option value="2">Machu Picchu</option>
                        <option value="3">Requena</option>
                        <option value="5">Abancay</option>
                    </select>
                </div>
                <div>
                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        // ready={submitReady}
                    >
                        {submitButtonText}
                    </Button>
                </div>
                <div>
                    {errorMessage ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                        </p>
                    ) : null}
                </div>
                <div>
                    {showSuccessMessage ? (
                        <p className={css.success}>
                            <FormattedMessage id="NewAction.SuccessMessage" />
                        </p>
                    ) : null}
                </div>
                <br />
            </Form>
        </div>
    );
};

export default withRouter(injectIntl(NewAction));
