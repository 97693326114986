import React from 'react';
import styles from './styles.module.css';
import Button from '../Button';
import { string, arrayOf, shape, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import BlogTag from '../BlogTag';

const propTypes = {
    /** URL of the blog image */
    imageUrl: string,
    /** Title of the blog */
    title: string,
    /** Expected reading time for the blog */
    readTime: string,
    /** Array of tags */
    tags: arrayOf(shape({ id: string, name: string })),
    /** slug of the blog */
    slug: string,
    /** history object */
    history: object,
    /** summary of blog */
    summary: string,
    /** writer of blog */
    writer: string,
    /** publishDate of blog */
    publishDate: string,
};

const FeaturedBlog = ({
    imageUrl,
    title,
    summary,
    writer,
    publishDate,
    readTime,
    tags,
    history,
    slug,
}) => {
    const handleClick = slug => () => {
        history.push(`/blog/${slug}`);
    };

    return (
        <div className={styles.wrapper}>
            <img src={imageUrl} alt="nature" />
            <div className={styles.blogMeta}>
                <p className={styles.blogTitle}>{title}</p>
                <p className={styles.summary}>{summary}</p>
                <span className={styles.readTime}>By {writer}</span>
                <span className={styles.readTime}>{publishDate}</span>
                <span className={styles.readTime}>{readTime}min read</span>
                <div className={styles.tags}>
                    {tags
                        ? tags.map(element => (
                              <BlogTag
                                  key={element.id}
                                  tagName={element.name}
                                  cardType="Featuredcard"
                              />
                          ))
                        : null}
                </div>
                <Button onClick={handleClick(slug)} variant={'filled'}>
                    Read on
                </Button>
            </div>
        </div>
    );
};
FeaturedBlog.propTypes = propTypes;
export default withRouter(FeaturedBlog);
