import styled from 'styled-components';
import { colors } from '../../../../theme';

export const Input = styled.input`
    width: 100%;

    padding: 2px 12px;
    border: 1px solid #ddedd0;
    border-radius: 6px;
    line-height: 29px;
    font-size: 1rem;

    &:focus,
    &:hover {
        border: 1px solid ${colors.actionGreen};

        outline-color: ${colors.actionGreen};
    }
    ${({ status }) => status === 'failure' && `border: 1px solid ${colors.actionOrange};`}
`;

export const StyledSuccessMessage = styled.p`
    font-size: 17px;
    font-weight: 500;
    color: ${colors.white};
    line-height: 17px;
`;

export const Message = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.title_green};
    margin: 0px;
    line-height: 180%;
`;

export const ErrorMessage = styled(Message)`
    color: ${colors.actionOrange};

    left: 0px;
    @media screen and (max-width: 425px) {
        top: 0px;
        left: 0px;
    }
`;
