import React, { useState, useRef, useEffect } from 'react';
import LogoImageMobile from '../../../../assets/newAssets/logo-regenera-negativo.svg';
import LogoImageMobileWhite from '../../../../assets/newAssets/LogoImageMobileWhite.svg';
import hamburger from '../../../../assets/newAssets/hamburger.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/newAssets/arrow-down.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { document } from 'window-or-global';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import { IntlContext } from '../../../../IntlContext';
import { logout } from '../../../../ducks/Auth.duck';
import {
    StyledUserIcon,
    StyledUsername,
    StyledDesktopListItem,
    StyledNavLink,
    StyledList,
    StyledContainer,
    StyledDesktopLogoContainer,
    StyledMobileLogoContainer,
    StyledMobileMenuContainer,
    StyledPopper,
    StyledMenuMobile,
    StyledMenuDesktop,
    MenuHeaderContainer,
    StyledButton,
    StyledButtonLanguage,
    StyledLanguagesList,
    StyledListItem,
    StyledDropdownBox,
    StyledDropdown,
    StyledOption,
    StyledNavItem,
    MenuContainer,
    StyledMenuItem,
    StyledLogoutButton,
    StyledLoginMenuContainer,
    User,
    Image,
} from './Styles';

const primaryNavItems = [
    {
        path: '/friends-profile/home',
        title: <FormattedMessage id="MyNaturePage.SideNav.home" />,
    },
    {
        path: '/friends-profile/footprint',
        title: <FormattedMessage id="MyNaturePage.SideNav.footprint" />,
    },
    {
        path: '/friends-profile/landscape',
        title: <FormattedMessage id="MyNaturePage.SideNav.landscape" />,
    },
    {
        path: '/friends-profile/impact',
        title: <FormattedMessage id="MyNaturePage.SideNav.impact" />,
    },
    {
        path: '/friends-profile/profileandaccount',
        title: <FormattedMessage id="MyNaturePage.SideNav.profileandaccount" />,
    },
    {
        path: '/friends-profile/activateaccount',
        title: <FormattedMessage id="MyNaturePage.SideNav.activateaccount" />,
    },
    {
        path: '/faq',
        title: <FormattedMessage id="MyNaturePage.SideNav.support" />,
    },
];

const guardianNavItems = [
    {
        path: '/guardian-profile/home',
        title: <FormattedMessage id="NewGuardiansPage.SideNav.Home" />,
    },
    {
        path: '/guardian-profile/landandlandscape',
        title: <FormattedMessage id="NewGuardiansPage.SideNav.Landscape" />,
    },
    {
        path: '/guardian-profile/income',
        title: <FormattedMessage id="NewGuardiansPage.SideNav.Income" />,
    },
    {
        path: '/guardian-profile/documents',
        title: <FormattedMessage id="NewGuardiansPage.SideNav.Documents" />,
    },
    {
        path: '/guardian-profile/profileandaccount',
        title: <FormattedMessage id="NewGuardiansPage.SideNav.Profile" />,
    },
    {
        path: '/faq',
        title: <FormattedMessage id="MyNaturePage.SideNav.support" />,
    },
];

const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
              profileImage: storeState.user.currentUser.profileImage.url,
              currentRole: storeState.user.currentUser.currentRole,
          }
        : null,
});

const NavItem = ({ path, title, active, bold, type, data, transparent }) => {
    return (
        <StyledNavLink
            active={active}
            to={data ? { pathname: path, state: { ...data } } : path}
            bold={bold}
            fontSize={type === 'primary' ? { _: 1, xl: 2 } : [1]}
            fontWeight={type === 'primary' ? [6] : [3, 3, 4]}
            transparent={transparent}
        >
            {title}
        </StyledNavLink>
    );
};

const LoginMenu = connect(mapStateToProps)(({ user, intl }) => {
    const username = user.name.split(' ');
    const title = intl.formatMessage(
        { id: 'TopbarMobileMenu.greeting' },
        { displayName: username[0] }
    );
    return (
        <User>
            <StyledUserIcon fontSize={1} fontWeight={6}>
                {user.profileImage ? <Image src={user.profileImage} /> : user.initials}
            </StyledUserIcon>
            <StyledUsername>{title}</StyledUsername>
        </User>
    );
});

const Dropdown = connect(mapStateToProps, { changeLanguage })(
    ({ languages: items, changeLanguage, selectedLanguage, transparent }) => {
        const [isOpen, setIsOpen] = useState(false);
        const containerRef = useRef(null);
        const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(
            IntlContext
        );

        useEffect(() => {
            //call switch langugae here
            if (selectedLanguage.short === 'EN') {
                switchToEnglish();
            } else if (selectedLanguage.short === 'ES') {
                switchToSpanish();
            } else {
                switchToPortuguese();
            }
        }, [selectedLanguage]);

        const handleOutsideClick = e => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [containerRef]);

        const open = () => {
            setIsOpen(!isOpen);
        };

        const close = () => {
            setIsOpen(false);
        };

        return (
            <StyledDropdown ref={containerRef}>
                <StyledOption selected={true} onClick={open} transparent={transparent}>
                    {selectedLanguage.short}
                    <span style={{ marginLetf: 5 }}>
                        <ArrowDown />
                    </span>
                </StyledOption>
                {isOpen && (
                    <StyledDropdownBox>
                        {items.map(item => (
                            <StyledOption
                                onClick={() => {
                                    changeLanguage(item.short);
                                    close();
                                }}
                                key={`desk-${item.short}`}
                            >
                                {item.name}
                            </StyledOption>
                        ))}
                    </StyledDropdownBox>
                )}
            </StyledDropdown>
        );
    }
);

const NavbarMenu = connect(null, { logout })(
    ({ currentPath, user, intl, transparent, logout, small }) => {
        const [showPopper, setShowPopper] = useState(false);
        const containerRef = useRef(null);
        const showHandler = () => {
            setShowPopper(!showPopper);
        };
        const handleOutsideClick = e => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setShowPopper(false);
            }
        };
        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [containerRef]);
        return (
            <>
                <StyledList>
                    <StyledDesktopListItem>
                        <Dropdown transparent={transparent} />
                    </StyledDesktopListItem>
                    <StyledButton onClick={showHandler} ref={containerRef}>
                        <img src={hamburger} alt="hamburger" />
                    </StyledButton>
                </StyledList>
                {showPopper && (
                    <StyledPopper big ref={containerRef}>
                        <StyledButton pop onClick={() => logout()}>
                            <FormattedMessage id="NewLandingPage.header.logout" />
                        </StyledButton>
                    </StyledPopper>
                )}
            </>
        );
    }
);

const MobileHeader = connect(mapStateToProps, { changeLanguage, logout })(
    ({ currentPath, selectedLanguage, changeLanguage, languages, user, logout, intl }) => {
        const [showPopper, setShowPopper] = useState(false);
        const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(
            IntlContext
        );

        useEffect(() => {
            //call switch langugae here
            if (selectedLanguage.short === 'EN') {
                switchToEnglish();
            } else if (selectedLanguage.short === 'ES') {
                switchToSpanish();
            } else {
                switchToPortuguese();
            }
        }, [selectedLanguage]);

        const showHandler = () => {
            setShowPopper(true);
        };

        const closeHandler = () => {
            setShowPopper(false);
        };

        const navList = user.currentRole === 'guardian' ?  guardianNavItems : primaryNavItems;

        return (
            <StyledMobileMenuContainer>
                {!showPopper ? (
                    <StyledLoginMenuContainer>
                        {user && (
                            <LoginMenu
                                user={user}
                                intl={intl}
                                items={[
                                    {
                                        path: '/account/tus-listings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.myLandscapes" />
                                        ),
                                    },
                                    {
                                        path: '/new-action',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.addItem" />
                                        ),
                                    },
                                    {
                                        path: '/profile-settings',
                                        label: (
                                            <FormattedMessage id="NewLandingPage.header.profileSettings" />
                                        ),
                                    },
                                ]}
                            />
                        )}
                        <StyledButton small onClick={showHandler}>
                            <img src={hamburger} alt="hamburger" />
                        </StyledButton>
                    </StyledLoginMenuContainer>
                ) : (
                    <StyledPopper>
                        <StyledMobileLogoContainer pop>
                            <a href="/">
                                <img src={LogoImageMobileWhite} alt="logo" />
                            </a>
                        </StyledMobileLogoContainer>
                        <MenuHeaderContainer>
                            <p>
                                {' '}
                                <FormattedMessage id="MyNaturePage.Header.Mobile.Menu" />{' '}
                            </p>
                            <StyledButton pop onClick={closeHandler}>
                                X
                            </StyledButton>
                        </MenuHeaderContainer>
                        <StyledMenuMobile>
                            <StyledList>
                                {navList.map(item => (
                                    <li key={item.title}>
                                        <NavItem
                                            path={item.path}
                                            title={item.title}
                                            active={currentPath === item.path}
                                            bold
                                            type="primary"
                                        />
                                    </li>
                                ))}
                            </StyledList>
                        </StyledMenuMobile>
                        <StyledButton onClick={() => logout()}>
                            <FormattedMessage id="NewLandingPage.header.logout" />
                        </StyledButton>
                        <StyledLanguagesList>
                            {languages.map((item, index) => (
                                <React.Fragment key={`mobile-${item.name}`}>
                                    {index > 0 && <li>-</li>}
                                    <StyledListItem
                                        active={
                                            selectedLanguage &&
                                            selectedLanguage.name.toLowerCase() ===
                                                item.name.toLowerCase()
                                        }
                                    >
                                        <StyledButton onClick={() => changeLanguage(item.short)}>
                                            {item.name}
                                        </StyledButton>
                                    </StyledListItem>
                                </React.Fragment>
                            ))}
                        </StyledLanguagesList>
                    </StyledPopper>
                )}
            </StyledMobileMenuContainer>
        );
    }
);

const DesktopHeader = ({ currentPath, user, transparent, intl }) => {
    return (
        <StyledMenuDesktop>
            <NavbarMenu
                currentPath={currentPath}
                user={user}
                intl={intl}
                transparent={transparent}
            />
        </StyledMenuDesktop>
    );
};

const NewHeader = ({ currentPath, user, transparent, intl }) => {
    //setUserData(userDetails);
    return (
        <StyledContainer transparent={transparent}>
            <StyledMobileLogoContainer>
                <a href="/">
                    <img src={LogoImageMobile} alt="logo" />
                </a>
            </StyledMobileLogoContainer>
            <StyledDesktopLogoContainer>
                <a href="/">
                    <img src={LogoImageMobile} alt="logo" width="100%" height="100%" />
                </a>
            </StyledDesktopLogoContainer>
            <MobileHeader currentPath={currentPath} user={user} intl={intl} />
            <div className="name">{user && <LoginMenu user={user} intl={intl} />}</div>
            <DesktopHeader
                currentPath={currentPath}
                user={user}
                intl={intl}
                transparent={transparent}
            />
        </StyledContainer>
    );
};

const Header = compose(withRouter, connect(mapStateToProps), injectIntl)(NewHeader);

export default Header;
