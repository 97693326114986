import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

//import { SliderSection } from './Styles';
//import Carousel from '../../../../newComponents/Carousel/Carousel';

// const HeroSection = () => {
//     return (
//         <SliderSection>
//             <div className="slider-container">
//                 {/* slider content */}
//                 <div class="signup-button-frame">
//                     <div class="header-frame flex flex-col justify-start">
//                         <h1 class="make-nature-your-business-container">
//                             Make Nature
//                             <br />
//                             your business
//                         </h1>
//                         <div class="we-help-organisations">
//                             We help organisations measure, reduce, and transform their environmental
//                             impact. This enables us to work with communities and other land managers
//                             to protect and restore their landscapes. Together, we regenerate Nature
//                             and climate.
//                         </div>
//                         <OrangeBtn class="sign-up-btn">
//                             <Link to="#">Sign up</Link>
//                         </OrangeBtn>
//                     </div>
//                 </div>
//                 {/* slider content end */}
//                 <Carousel />
//             </div>
//         </SliderSection>
//     );
// };

import Slider from 'react-slick';
import styled from 'styled-components';
import slider1 from '../../../../assets/newAssets/sliders/slider-image.png';
import slider2 from '../../../../assets/newAssets/sliders/slider-two.png';
import slider3 from '../../../../assets/newAssets/sliders/slider-three.png';
import slider1Mob from '../../../../assets/newAssets/sliders/slider-1-mobile.png';

const SliderSection = styled.section`
    /* Slider */
    .slick-slider {
        position: relative;

        display: block;
        box-sizing: border-box;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: none;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;

        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .slick-track:before,
    .slick-track:after {
        display: table;

        content: '';
    }
    .slick-track:after {
        clear: both;
    }
    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;

        height: 100%;
        min-height: 1px;
    }
    [dir='rtl'] .slick-slide {
        float: right;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-loading .slick-slide {
        visibility: hidden;
    }
    .slick-vertical .slick-slide {
        display: block;

        height: auto;

        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }

    /* Slider */
    .slick-loading .slick-list {
        background: #fff url('./ajax-loader.gif') center center no-repeat;
    }

    /* Icons */
    @font-face {
        font-family: 'slick';
        font-weight: normal;
        font-style: normal;

        src: url('./fonts/slick.eot');
        src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'),
            url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'),
            url('./fonts/slick.svg#slick') format('svg');
    }
    /* Arrows */
    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;

        position: absolute;
        top: 50%;

        display: block;

        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);

        cursor: pointer;

        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: transparent;
        outline: none;
        background: transparent;
    }
    .slick-prev:hover:before,
    .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
        opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 20px;
        line-height: 1;

        opacity: 0.75;
        color: white;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .slick-prev {
        left: -25px;
    }
    [dir='rtl'] .slick-prev {
        right: -25px;
        left: auto;
    }
    .slick-prev:before {
        content: '←';
    }
    [dir='rtl'] .slick-prev:before {
        content: '→';
    }

    .slick-next {
        right: -25px;
    }
    [dir='rtl'] .slick-next {
        right: auto;
        left: -25px;
    }
    .slick-next:before {
        content: '→';
    }
    [dir='rtl'] .slick-next:before {
        content: '←';
    }

    /* Dots */
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }

    .slick-dots {
        position: absolute;
        bottom: -25px;

        display: block;

        width: 100%;
        padding: 0;
        margin: 0;

        list-style: none;

        text-align: center;
    }
    .slick-dots li {
        position: relative;

        display: inline-block;

        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;

        cursor: pointer;
    }
    .slick-dots li button {
        font-size: 0;
        line-height: 0;

        display: block;

        width: 20px;
        height: 20px;
        padding: 5px;

        cursor: pointer;

        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus {
        outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
        opacity: 1;
    }
    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;

        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        content: '•';
        text-align: center;

        opacity: 0.25;
        color: black;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: black;
    }

    position: relative;
    .slide-box {
        position: relative;
        img {
            max-width: 100%;
            width: 100%;
        }
    }
    .signup-button-frame {
        position: absolute;
        max-width: 470px;
        padding: 0 0 0 4.8rem;
        z-index: 2;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        .header-frame {
            align-self: stretch;
            align-items: flex-start;
            gap: 16px 0;
        }

        .make-nature-your-business-container {
            margin: 0;
            align-self: stretch;
            position: relative;
            font-size: 48px;
            line-height: 58px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.white};
            width: 420px;
        }
        .we-help-organisations {
            font-size: 18px;
            color: ${({ theme }) => theme.colors.white};
            font-weight: normal;
            line-height: 160%;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        margin-top: 66px;
        .signup-button-frame {
            padding: 0 20px;
            text-align: center;
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
            width: 100%;
            .header-frame {
                align-items: center;
                .make-nature-your-business-container {
                    font-size: 42px;
                    line-height: 52px;
                }
                .we-help-organisations {
                    font-size: 16px;
                    line-height: 170%;
                }
                .sign-up-btn {
                    a {
                        display: block;
                    }
                }
            }
        }
    }
`;

const HeroSection = () => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <SliderSection>
            <div>
                {/* slider content */}
                <div className="signup-button-frame">
                    <div className="header-frame flex flex-col justify-start">
                        <h1 className="make-nature-your-business-container">
                            <FormattedMessage
                                id="NewLandingPage.hero.title"
                                values={{
                                    br: <br />,
                                }}
                            />
                        </h1>
                        <div className="we-help-organisations">
                            <FormattedMessage id="NewLandingPage.hero.subTitle" />
                        </div>
                        <OrangeBtn className="sign-up-btn">
                            <Link to="/signup">
                                <FormattedMessage id="NewLandingPage.hero.signUp" />
                            </Link>
                        </OrangeBtn>
                    </div>
                </div>
                {/* slider content end */}
                <Slider {...settings}>
                    <div className="slide-box">
                        <picture>
                            <source media="(max-width:700px)" srcset={slider1Mob} />
                            <img src={slider1} alt="" />
                        </picture>
                    </div>
                    <div className="slide-box">
                        <picture>
                            <source media="(max-width:700px)" srcset={slider1Mob} />
                            <img src={slider2} alt="" />
                        </picture>
                    </div>
                    <div className="slide-box">
                        <picture>
                            <source media="(max-width:700px)" srcset={slider1Mob} />
                            <img src={slider3} alt="" />
                        </picture>
                    </div>
                </Slider>
            </div>
        </SliderSection>
    );
};

export default HeroSection;

// import React from 'react';
// import HeroTitle from '../../../../newModules/HeroTitle';
// import ButtonCombo from '../../../../newModules/ButtonCombo';
// import animal from '../../../../assets/newAssets/animal.jpg';
// import animalMobile from '../../../../assets/newAssets/animal-mobile.jpg';
// import { useSelector } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
// import { useState } from 'react';
// import {
//     StyledHeroSection,
//     StyledImageDesktop,
//     StyledImageMobile,
//     StyledHeroTitleContainer,
//     StyledButtonComboContainer,
//     Error,
// } from './Styles';

// const imageCarousel = [
//     {
//         index: 0,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-1.jpg',
//     },
//     {
//         index: 1,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-2.jpg',
//     },
//     {
//         index: 2,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-3.jpg',
//     },
//     {
//         index: 3,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-4.jpg',
//     },
//     {
//         index: 4,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-5.jpg',
//     },
//     {
//         index: 5,
//         image: 'https://regenera-public.s3.amazonaws.com/carousel/carousel-6.jpg',
//     },
// ];

// function getRandomInt(max) {
//     return Math.floor(Math.random() * max);
// }
// const HeroSection = () => {
//     const error = useSelector(state => state.Toast.error);
//     const [visible, setVisible] = useState(useSelector(state => state.Toast.visible));
//     const index = getRandomInt(6);
//     if (visible) {
//         setTimeout(() => {
//             setVisible(false);
//         }, 10000);
//     }
//     return (
//         <StyledHeroSection>
//             {visible ? <Error visible={visible}> {error}</Error> : null}
//             <StyledImageDesktop src={imageCarousel[index].image} alt="animal" />

//             <StyledImageMobile src={imageCarousel[index].image} alt="animal" />
//             <StyledHeroTitleContainer
//                 position="absolute"
//                 top={{ xl: 358, lg: 260, sm: 196, _: 150 }}
//             >
//                 <HeroTitle
//                     title={<FormattedMessage id="NewLandingPage.hero.title" />}
//                     subtitle={<FormattedMessage id="NewLandingPage.hero.subTitle" />}
//                     variant={{ _: 'black', md: 'white' }}
//                 />
//             </StyledHeroTitleContainer>
//             <StyledButtonComboContainer
//                 position="absolute"
//                 top={{ _: 350, sm: 400, md: 440, lg: 400, xl: 490 }}
//             >
//                 <ButtonCombo
//                     primaryLabel={<FormattedMessage id="NewLandingPage.hero.primaryButton" />}
//                     secondaryLabel={<FormattedMessage id="NewLandingPage.hero.secondaryButton" />}
//                     primaryLabelLink1={
//                         <FormattedMessage id="NewLandingPage.hero.primaryButtonLink1" />
//                     }
//                     primaryLabelLink2={
//                         <FormattedMessage id="NewLandingPage.hero.primaryButtonLink2" />
//                     }
//                     primaryLabelLink3={
//                         <FormattedMessage id="NewLandingPage.hero.primaryButtonLink3" />
//                     }
//                     primaryPath="#"
//                     pathIndividuals="/people"
//                     pathOrganisations="/organizations"
//                     pathLand="/land-manager"
//                     secondaryPath="/#ourstory"
//                 />
//             </StyledButtonComboContainer>
//         </StyledHeroSection>
//     );
// };

// export default HeroSection;
