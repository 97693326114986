import styled from 'styled-components';


export const ModalWrapper = styled.div`
    position: fixed;
    box-sizing: border-box;
    padding: 100px;
    padding-top: 300px;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(19,19,19,0.4);
    z-index: 100;
    text-align:center;
`;

export const ModalContent = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background-color: white;
    z-index: 101;
    padding:20px;
`;

export const CrossContainer = styled.div`
    width:20px;
    position: relative;
    float: right;
    & > img{
        &:hover{
            cursor:pointer;
        }
    }
`
export const CrossButtton = styled.button`
    width:20px;
    position: relative;
    float: right;
    margin-right: 100px;
    margin-top: 30px;
`
