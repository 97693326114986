// common
export const colors = {
    white: '#fff',
    black: '#212529',
    darkOrange: '#CF6400',
    green: '#7BB548',
    actionGreen: '#75ac38',
    softGreen: '#DDEDD0',
    sand: '#EFE7DA',
    actionOrange: '#DD6B17',
    gray: '#424242',
    lightGray: '#c4c4c4',
    darkGray: '#7E7E7E',
    pink: '#ff8989',
    lightPink: '#FFC7C7',
    title_green: '#004D37',
    text_color: '#424A46',
    grey: '#545C56',
    light_green: '#6EA44C',
    orange: '#DD6B17',
    light_orange: '#ef8539',
    border: 'rgba(98, 84, 243, 0.5)',
    bgnavlink_hover: '#eee',
    hr: '#ffffff',
    gradient: 'linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)',
    shadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
    shadowSupport: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
};

export const fonts = {
    ibm: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
    sofiapro: 'sofiapro, Helvetica, Arial, sans-serif',
};

export const breakpoints = ['425px', '768px', '1024px', '1440px', '2560px'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export const fontSizes = [12, 16, 18, 24, 36, 48, 72];

export const space = [0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512];

export const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900];
export const borderRadius = [0, 4, 10, 25];

export const shadows = {
    sm: '0 4px 8px 0 rgb(0 0 0 / 10%)',
};

export const media = { mobile: '768px', tab: '1024px', desktop: '1200px' };

const theme = {
    colors,
    fonts,
    fontSizes,
    space,
    fontWeights,
    breakpoints,
    shadows,
    borderRadius,
    media,
};

export default theme;
