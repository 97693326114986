import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../../theme';


export const CardContainer =styled.div`
    height:auto;
    width:100%;
`;

export const Card =styled.div`
    margin:45px auto;
    height:auto;
    width:90%;
    background: #F9FFF4;
    border: 1px solid #3F911C;
    box-sizing: border-box;
`;