import styled from 'styled-components';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';
export const FootprintMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3D6;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;
export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 30px;
    font-weight: 700;
    width: fit-content;
    margin: ${props => (props.box ? '40px auto' : '45px')};
    @media screen and (max-width: 768px) {
        display: ${props => (props.box ? 'none' : '')};
        margin: ${props => (props.box ? '40px auto' : '35px 0px 20px 20px')};
    }
`;
export const Navigation = styled.div`
    margin: 0px auto;
    width: 92%;
    padding: 20px;
    height: 72px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    .visible {
        cursor: pointer;
        visibility: visible;
    }
    .invisible {
        cursor: pointer;
        visibility: hidden;
    }
`;
export const Date = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 26px;
    font-weight: 700;
    width: 100%;
    text-align: center;
`;

export const ResultContainer = styled.div`
    margin: 30px 4%;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 4% 8% 4% 8%;
    @media screen and (max-width: 400px) {
        margin: 30px 20px;
        paddding: 50x 30px;
    }
`;
export const NoData = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
`;
export const GrayText = styled.div`
    padding-top: 20px;
    color: #5a5a5a;
    margin: auto;
    width: 70%;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: ${props => (props.empty ? '24px' : '19px')};
    line-height: 26px;
    text-align: center;
`;
export const Details = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 40px;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        margin: 0px 0px;
        flex-direction: column;
        width: 100%;
    }
`;
export const Heading = styled.div`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing: border-box;
    color: #242424;
`;
export const Content = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #242424;
    & span {
        font-weight: 700;
    }
    @media screen and (max-width: 800px) {
        margin: 30px 10px;
        width: 100%;
    }
`;
export const EmissionContainer = styled.div`
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        width: 100%;
        margin-top: 20px;
        padding-top: 20px;
    }
`;
export const EmissionHeading = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing: border-box;
    color: #000000;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
    @media screen and (max-width: 400px) {
        text-align: center;
    }
`;
export const EmissionValue = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 60.1705px;
    line-height: 81px;
    box-sizing: border-box;
    color: #000000;
    @media screen and (max-width: 800px) {
        font-size: 30px;
        line-height: 41px;
        padding: 20px 0px;
    }
`;
export const EmissionScopeValue = styled.span`
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    box-sizing: border-box;
    color: #000000;
`;
export const LogoContainer = styled.div`
    text-align: center;
    margin: 60px auto;
    width: 60%;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        width: 100%;
        margin: 30px auto;
    }
`;
export const RedLogo = styled.div`
    width: 20%;
    height: auto;
    margin-right: 20px;
    box-sizing: border-box;
`;
export const EmissionContent = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: #242424;
    @media screen and (max-width: 800px) {
        width: 100%;
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }
`;
export const EmissionResult = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin: 70px auto;
    text-align: center;
    @media screen and (max-width: 800px) {
        width: 100%;
        flex-direction: column;
        text-align: center;
    }
`;
export const EmissionPercent = styled.div`
    display: flex;
    align-item: center;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 69.6923px;
    line-height: 94px;
    width: 100%;
    color: #000000;
`;
export const ButtonContainer = styled.div`
    margin: 40px auto;
    width: fit-content;
    @media screen and (max-width: 800px) {
        margin: 40px 10%;
        width: auto;
        & > a {
            width: 100%;
        }
    }
`;
