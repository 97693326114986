import React, { useState } from 'react';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import HeroSection from './Sections/HeroSection/HeroSection';
import ToJoin from './Sections/ReadyToJoin/ReadyToJoin';
//import CompanyLogos from './Sections/WorkWith/CompanyLogos';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';

import FounderBox from './Sections/FounderBox/FounderBox';
import Team from './Sections/Team/Team';
import BusinessCertificate from './Sections/BusinessCertification/BusinessCertificate';

import NetworkLogos from './Sections/Network/NetworkLogos';

const AboutUsPage = ({ location, history, user }) => {
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <HeroSection history={history} />
            <FounderBox />
            <Team />
            <BusinessCertificate />
            <NetworkLogos />
            <ToJoin />
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(AboutUsPage));
