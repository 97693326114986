import React, { useState } from 'react';
import {ResultContainer, 
        Header, 
        RedLogo, 
        Heading, 
        ChartContainer, 
        Legend, 
        Result, 
        ScopeResultData,
        Percent,
        LegendText,
        ScopeResultContainer,
        Emission,
        ButtonContainer} from './styles.js';
import logo from '../../../../../assets/newAssets/thumbPrintRedOrg.svg';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../../newComponents/Button';
import Chart from '../Chart/chart.js';
import { useEffect } from 'react';
import {isNil, isEmpty} from 'lodash';
import { useHistory } from 'react-router';
 const ScopeResult = (props) =>{
     const history = useHistory();
    const reducer = (previousValue, currentValue) => previousValue + currentValue;
    const [emissionData,setEmissionData]=useState([]);
    const [totalEmission,setTotalEmission]=useState();
    useEffect(() =>{
         let data= new Array(props.type.length);
        if(!isNil(props.emissionActivitiesData[props.scope])||!isEmpty(props.emissionActivitiesData[props.scope])){
            props.type.map((type,i) => {
                data[i]=props.emissionActivitiesData[props.scope].filter((data) => data.groupName===type)
                .map((data)=> data.emissionKgCo2ePerUnit).reduce(reducer,0);
            });
            setEmissionData(data);
            setTotalEmission(data.reduce(reducer,0));
            props.refProp.current.scrollIntoView();
        }
    },[props.emissionActivitiesData])

    const handleClick = () =>{
        history.push(props.path,{data:props.orgDetails.data});
    }
     return(
            <ResultContainer 
             ref={props.refProp}>
                <Header>
                    <RedLogo>
                        <img src={logo} alt='red finger print'/>
                    </RedLogo>
                    <Heading><FormattedMessage id="OrganisationCalculator.ElectricityEmission.ScopeResult.Heading" /></Heading>
                </Header>
                <Result>
                    <ChartContainer>
                        {emissionData.length>0?<Chart emissionData={emissionData}/>:null}
                        <Emission>{Math.round((totalEmission/1000) * 100) / 100} tCO2e</Emission>
                    </ChartContainer>
                    <ScopeResultContainer>
                        <ScopeResultData>
                            <Legend inputColor="#E95527"/>
                            {totalEmission===0?<Percent>0%</Percent>:<Percent>{Math.round((emissionData[0]/totalEmission)*100)}%</Percent>}
                            <LegendText><FormattedMessage id="OrganisationCalculator.PurchasedElectricity.Heading"/></LegendText>
                        </ScopeResultData>
                        <ButtonContainer>
                            <Button onClick={() =>{ handleClick()}}><FormattedMessage id="OrganisationCalculator.Button.Proceed"/></Button>
                        </ButtonContainer>
                    </ScopeResultContainer>
                </Result>
            </ResultContainer>
     );
 }

 export default ScopeResult;