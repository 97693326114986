import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../../theme';

export const ResultContainer = styled.div`
    height:auto;
    width:100%; 
    background: #000000;
    border:1px solid black;
    border-radius: 5px;
    padding-bottom:130px;
`;

export const  Header = styled.div`
   margin:47px 0px 50px 69px;
    display:flex;
    align-items:center;
    height:auto;
    box-sizing:border-box;
    @media screen and (max-width:800px){
        margin:5% 5%  
    }
`;

export const  RedLogo = styled.div`
   width:112px;
   height:auto;
   margin: 0px 26px 0px 0px; 
   @media screen and (max-width:800px){
        width:40%;
    }
`;

export const  Heading = styled.div`
    padding: 10px;
    font-weight: bold;
    font-size: 50px;
    line-height: 172.5%;
    color: #FFFFFF;
    box-sizing:border-box;
    @media screen and (max-width:800px){
        font-size: 30px;
        line-height: 41px;  
    }
`;

export const ChartContainer = styled.div`
    display:flex;
    flex-direction:column;
    width:30%;
    height:30%;
    margin-left:10%;
    margin-bottom:5%;
    @media screen and (max-width:800px){
        width:100%;
        height:100%;
        align-items:flex-start;
        & > :nth-child(1){
            width:80%;
        }
        & > :nth-child(2){
            text-align:initial;
        }
    }
`;

export const Legend = styled.div`
    margin-right:25px;
    width:45px;
    height:45px;
    background-color: ${props => props.inputColor || "#E95527"}
`;

export const Result = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    @media screen and (max-width:800px){
        flex-direction:column;
    }
`;
export const ScopeResultData = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:50px;

    @media screen and (max-width:800px){
        align-items:center;
        wdith:100%;
        flex-wrap:wrap;
        margin-left:5%;
        margin-top:5%;
    }
`;
export const LegendText = styled.div`
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #FFFFFF;
    @media screen and (max-width:800px){
        wdith:100%;
    }
`;

export const Percent = styled.div`
    font-weight: bold;
    font-size: 51px;
    margin-right:35px;
    line-height: 69px;
    color: #FFFFFF;
`;

export const ScopeResultContainer = styled.div`
        display:flex;
        flex-direction:column;
        flex-wrap:wrap;
        margin-left:80px;
        margin-right:150px;
        @media screen and (max-width:800px){
            width:100%;
            margin-left:5%;
            margin-right:5%;
        }
`;

export const Emission = styled.div`
    font-style: normal;
    font-weight: bold;
    text-align:center;
    font-size: 51px;
    line-height: 69px;
    color: #FFFFFF;
`;

export const ButtonContainer = styled.div`
    display:inline-block;
    & > Button{
        width:347px;
        height:73px;
        background: #F29509;
        border-radius: 5px;
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-weight: bold;
        font-size: 23.1858px;
        line-height: 31px;
        color: #FFFFFF;
        
    }
    @media screen and (max-width:800px){
        text-align:center;
     }
`;

