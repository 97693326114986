import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../theme';

export const HeadingContainer=styled.div`
    text-align:center;
    margin:68px 0px 5px 0px;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    box-sizing:border-box;
`;

export const SubHeadingContainer=styled.div`
    width:52%;
    margin:auto;
    text-align:center;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #242424;
    @media screen and (max-width:800px){
        text-align:initial; 
        width:100%;
        padding:0px 20px;
        margin:0px auto;
    }

`;