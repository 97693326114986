import styled from 'styled-components';
import { colors } from '../../theme';

export const StyledDropdownBox = styled.ul`
    position: absolute;
    width: 200px;
    background: #f9f9f9;
    padding: 15px 50px 15px 30px;
    z-index: 10;
    right: 0px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
    white-space: nowrap;
`;

export const StyledOption = styled.li`
    cursor: pointer;
    color: ${colors.gray};
    display: flex;
    align-items: center;
    &:hover {
        color: ${colors.green};
    }
    margin: 5px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    .external-share-link {
        color: ${colors.text_color};
        display: flex;
        gap: 10px;
        &:hover {
            font-weight: 800;
        }
    }
    .disabled {
        color: ${colors.lightGray};
        cursor: default;
        &:hover {
            font-weight: 600;
        }
    }
`;
