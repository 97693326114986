import styled from 'styled-components';

export const DropdownWrapper = styled.div`
    position: relative;
`;
export const InputWraper = styled.div`
    display: flex;
    align-items:center;
    text-align:center;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 5px;
    @media screen and (max-width: 860px) {
        max-width:290px;
        border: 1px solid #ffffff;
        align-items:flex-start;
        text-align:left;
        background-color:black;
      }
`;

export const InpuField = styled.div`
    width: 80%;
    text-align:center;
    border-right: 1px solid #000000;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 172.5%;
    color: #000000;
    padding:5px; 
    @media screen and (max-width: 860px) {
        border-right: 1px solid #ffffff;
        color: #ffffff;
        background-color:black;
      }
`;

export const LogoWrapper = styled.div`
    width:20%;
    @media screen and (max-width: 860px) {
        width:45px;
      }
`;

export const Dropdown = styled.div`
    position: absolute;
    z-index: 100;
    width:100%;
    background-color: white;
    box-shadow: 2px 4px 13px rgb(0 0 0 / 11%);
    border-radius: 2px;
    @media screen and (max-width: 860px) {
        background-color: black;
        color: #ffffff;
        max-width:290px;
      }
`;

export const DropdownField = styled.div`
    width:100%;
    padding:10px 20px;
    text-align: center;
    border-top: 1px solid #e9ecef;
    &:hover{
        background-color: #e9ecef;
    }
`;