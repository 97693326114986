import React, { useState, useRef } from "react";
import Button from "../../../../../../newComponents/Button";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { uploadKycDocuments } from "../../../../../../coreApi";
import { FormattedMessage } from 'react-intl';
import DragAndDrop from "../../../../../ourNaturePage/components/DragAndDrop";
import {
    UploadDocumentTitle,
    UploadDocumentMain,
    UploadDocumentMessage,
    DragAndDropDocumentText,
    DragAndDropDocumentIcon,
    SpaceBreak,
    DragAndDropDocumentMessage,
    DragAndDropDocumentMain
} from '../Styles';

const DocumentUpload = () => {

    const [isVisible, setVisible] = useState(false);

    const hiddenFileInput = useRef(null);

    const handleUploadClick = (e) => {
        hiddenFileInput.current.click();
    }

    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded) {
            await uploadKycDocuments(fileUploaded);
            setVisible(true);
        }
    };

    const handleUploadMore = async (event) => {
        hiddenFileInput.current.click();
    }

    const handleDrop = async (file) => {
        if(file) {
            await uploadKycDocuments(file[0]);
            setVisible(true);
        }
    }

    const successComp = 
        isVisible && 
                <UploadDocumentMain>
                    <SpaceBreak bkgColor="FFFFFF" margin="22px" />
                    <DragAndDropDocumentIcon>
                        <CheckCircleOutlineOutlinedIcon style={{color:"#7BB548", width:95, height:95}} />
                    </DragAndDropDocumentIcon>
                    <DragAndDropDocumentText><FormattedMessage id="NewGuardiansPage.Home.success" /></DragAndDropDocumentText>
                    <DragAndDropDocumentMessage><FormattedMessage id="NewGuardiansPage.Home.successMessage" /></DragAndDropDocumentMessage>
                    <SpaceBreak bkgColor="FFFFFF" margin="15px" />
                    <DragAndDropDocumentIcon>
                        <Button
                            onClick={handleUploadMore}
                            variant="filled"
                            size="md"
                        ><FormattedMessage id="NewGuardiansPage.Home.proceed" /></Button>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{display: 'none'}} 
                        />
                    </DragAndDropDocumentIcon>
                    <SpaceBreak bkgColor="FFFFFF" margin="35px" />
                </UploadDocumentMain>
    
    const initialComp = 
        !isVisible &&
                <UploadDocumentMain>
                    <DragAndDrop handleDrop={handleDrop}>
                        <UploadDocumentTitle><FormattedMessage id="NewGuardiansPage.Home.upload" /></UploadDocumentTitle>
                        <UploadDocumentMessage><FormattedMessage id="NewGuardiansPage.Home.uploadMessage" /></UploadDocumentMessage>
                        <DragAndDropDocumentMain bkgColor="#D3D3D3">
                            <DragAndDropDocumentIcon>
                                <CloudUploadOutlinedIcon style={{color: '#F29509', fontSize:70, marginTop:70}} />
                            </DragAndDropDocumentIcon>
                            <DragAndDropDocumentText><FormattedMessage id="NewGuardiansPage.Home.dragAndDrop" /></DragAndDropDocumentText>
                            <DragAndDropDocumentText><FormattedMessage id="NewGuardiansPage.Home.dragAndDropOr" /></DragAndDropDocumentText>
                            <DragAndDropDocumentIcon>
                                <Button
                                    onClick={handleUploadClick}
                                    variant="filled"
                                    size="md"
                                ><FormattedMessage id="NewGuardiansPage.Home.browse" /></Button>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{display: 'none'}} 
                                />
                            </DragAndDropDocumentIcon>
                        </DragAndDropDocumentMain>
                    </DragAndDrop>
                </UploadDocumentMain>
    
    return (
        <>
            {initialComp}
            {successComp}
        </>
    );
}

export default DocumentUpload;