import React, { useState } from 'react';
import css from './Hero.css';
import { Modal, SecondaryButton } from '../../components';
import { FaRegPlayCircle } from 'react-icons/fa';

const heroSize = size => {
  if (size === 'medium') {
    return css.heroMedium;
  } else if (size === 'big') {
    return css.heroBig;
  }
};

const Hero = props => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={`${css.hero} ${heroSize(props.size)}`}>
      <img
        alt=""
        src={props.image}
        className={css.image}
        style={{ objectPosition: props.backgroundPosition }}
      />
      <div className={css.sectionContent}>
        <h1 className={css.title}>{props.title}</h1>
        <p className={`${css.subtitle} ${props.isFriendsPage ? css.subtitleFriends : ''}`}>
          {props.subtitle}
        </p>
        {props.urlvideoId ? (
          <React.Fragment>
            <SecondaryButton className={css.videoButton} onClick={() => setModalOpen(true)}>
              <span className={css.videoButton_text}>{props.videotext}</span>
              <FaRegPlayCircle />
            </SecondaryButton>

            <Modal
              lightCloseButton={true}
              containerClassName={css.modalVideo}
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              onManageDisableScrolling={() => {}}
            >
              {modalOpen ? (
                <React.Fragment>
                  <iframe
                    className={css.video}
                    src={`https://player.vimeo.com/video/${props.urlvideoId}?autoplay=1&color=2DC000&title=0&byline=0&portrait=0`}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </React.Fragment>
              ) : null}
            </Modal>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

Hero.defaultProps = {
  size: 'big',
  backgroundPosition: 'center center',
  videotext: 'Ver video',
  urlvideoId: null,
};

export default Hero;
