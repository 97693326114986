
 import React, { useState } from 'react';
 import PropTypes from 'prop-types';
 import cross from './../../assets/newAssets/cross.svg';
 import {
  ModalWrapper,
  ModalContent,
  CrossContainer,
 } from './Styles.js';
 
 const ModalComponent =  (props) => {
 
  const {children, onClose, isOpen} = props;
   
   return(<>
     {isOpen && <ModalWrapper>
      <ModalContent>
        <CrossContainer>
          <img src={cross} 
          width="100%" 
          height="100%"
          onClick={onClose}
          role="button"
          tabIndex="0"
          />
        </CrossContainer>
        {children}
      </ModalContent>
    </ModalWrapper>}
    </>
   ) 
 }
 
 ModalComponent.defaultProps = {
   
 };
 
 const { bool, func, node, string } = PropTypes;
 
 ModalComponent.propTypes = {
   
 };
 
 export default ModalComponent;
 