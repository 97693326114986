import React from 'react';

import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { GuardianRoleSection } from './Styles';
const GuardianRole = ({ roleImg, roleImgM, roleTitle, rolePara, linkText, classrole }) => {
    return (
        <GuardianRoleSection className={classrole}>
            <div className="role-box flex">
                <div className="role-img">
                    <picture>
                        <source media="(max-width:768px)" srcset={roleImgM} />
                        <img src={roleImg} className="w-full" alt="role-img" />
                    </picture>
                </div>
                <div className="role-infobox">
                    <h5 className="role-title">{roleTitle}</h5>
                    <p className="small">{rolePara}</p>
                    <Link to="#" className="green-link flex items-center small">
                        <span>{linkText}</span> <MdKeyboardArrowRight className="relative" />
                    </Link>
                </div>
            </div>
        </GuardianRoleSection>
    );
};

export default GuardianRole;
