import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Header from '../section/Header/Header.js';
import Footer from '../section/Footer/Footer.js';
import Tabs from '../section/Tabs/Tabs.js';
import Heading from '../section/Heading/Heading.js';
import ActivityCard from './components/ActivityCard/activityCard.js';
import Form from '../section/ProfileForm/ProfileForm.js';
import ScopeResult from './components/ScopeResult/scopeResult.js';
import { DetailsContainer, SaveButton, Error } from './Styles.js';
import Button from '../../../newComponents/Button';
import {
    getCurrentOrganization,
    saveOrganisationEmissionActivities,
    getEmissionActivities,
    getOrganizationEmissionDetails,
    getUnits,
} from '../../../coreApi';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';
import { FormattedMessage } from 'react-intl';

const ElectricityEmission = props => {
    const [orgDetails, setOrgDetails] = useState({});
    const [error, setError] = useState(false);
    const [duplicateError, setDuplicateError] = useState(false);
    const [units, setUnits] = useState({});
    const [emissionActivityDetails, setEmissionActivityDetails] = useState({});
    const [orgEmissionDetails, setOrgEmissionDetails] = useState({});
    const result = useRef(null);
    let activities1 = [{}];
    let activities2 = [{}];
    const location = useLocation();
    useEffect(() => {
        const fetchData = async () => {
            const Details = location.state;
            // const Details = await getCurrentOrganization()
            // .then((response) =>{
            setOrgDetails(Details);
            getOrganizationEmissionDetails(
                'scope2',
                Details.data.id,
                Details.data.inventoryYear
            ).then(response => {
                setOrgEmissionDetails(response.data);
            });
            //      return response;
            // })
            getEmissionActivities('scope2').then(response => {
                setEmissionActivityDetails(response.data);
            });

            getUnits().then(response => {
                setUnits(response.data);
            });
        };
        fetchData();
    }, []);
    const PostData = () => {
        const activities = [...activities1, ...activities2];
        let desiredData = [];
        activities.map(data => {
            if (!isNil(data.activityId) && !isNil(data.value) && !isNil(data.unit)) {
                if (!isEmpty(data.activityId) && data.value >= 0 && !isEmpty(data.unit)) {
                    desiredData.push({ id: data.activityId, value: data.value, unit: data.unit });
                }
            }
        });
        saveOrganisationEmissionActivities(
            desiredData,
            orgDetails.data.id,
            orgDetails.data.inventoryYear
        )
            .then(response => {
                getOrganizationEmissionDetails(
                    'scope2',
                    orgDetails.data.id,
                    orgDetails.data.inventoryYear
                ).then(response => {
                    setOrgEmissionDetails(response.data);
                    if (isEmpty(response.data['scope1'])) {
                        setError(true);
                        setDuplicateError(false);
                    } else {
                        setError(false);
                        setDuplicateError(false);
                    }
                });
                return response;
            })
            .catch(response => {
                setDuplicateError(true);
                setError(false);
            });
    };

    const saveActivities = (data, type) => {
        if (type === 'Purchased Electricity') {
            activities1 = [...data];
        }
    };
    return (
        <DetailsContainer>
            {!isEmpty(orgDetails) ? (
                <Header user={props.user} orgDetails={orgDetails.data.organizationName} />
            ) : (
                <Header orgDetails={null} />
            )}
            <Tabs id="ElectricityEmission" />
            <Heading
                headingId="OrganisationCalculator.ElectricityEmission.Heading"
                subHeadingId="OrganisationCalculator.ElectricityEmission.SubHeading"
            />
            {!isEmpty(emissionActivityDetails) && !isEmpty(units) ? (
                <ActivityCard
                    key={uuid()}
                    headingId="OrganisationCalculator.PurchasedElectricity.Heading"
                    subHeadingId="OrganisationCalculator.PurchasedElectricity.SubHeading"
                    type="Purchased Electricity"
                    emissionActivityDetails={emissionActivityDetails}
                    units={units}
                    scope="scope2"
                    emissionActivities={saveActivities}
                    emissionActivitiesData={orgEmissionDetails}
                />
            ) : null}
            <SaveButton>
                {duplicateError ? (
                    <Error>
                        <FormattedMessage id="OrganisationCalculator.Error.DuplicateActivity" />
                    </Error>
                ) : null}
                {isNil(orgEmissionDetails['scope2']) && error ? (
                    <Error>
                        <FormattedMessage id="OrganisationCalculator.Error.EmptyEmissionDetails" />
                    </Error>
                ) : null}
                <Button onClick={() => PostData()}>
                    <FormattedMessage id="OrganisationCalculator.Button.Save" />
                </Button>
            </SaveButton>
            {!isEmpty(orgEmissionDetails) && !isNil(orgEmissionDetails['scope2']) ? (
                <ScopeResult
                    orgDetails={orgDetails}
                    refProp={result}
                    emissionActivitiesData={orgEmissionDetails}
                    type={['Purchased Electricity']}
                    scope="scope2"
                    path="/organisation/calculator/indirect-emission"
                />
            ) : null}
            <Footer />
        </DetailsContainer>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
    userDetails: storeState.user.currentUser,
});

const ElectricityEmissionPage = connect(mapStateToProps)(withRouter(ElectricityEmission));
export default ElectricityEmissionPage;
