import styled from 'styled-components';
import homeImage from '../../../../assets/newAssets/myNatureHome.jpeg';

export const ImageContainer = styled.div`
    padding: 40px;
    @media screen and (max-width: 668px) {
        padding: 0px;
    }
`;
export const ImageDiv = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 0px;
    background-position: center;
    background: url(${homeImage});
    background-size: cover;
    @media screen and (max-width: 568px) {
        margin: 0px;
        padding: 0px;
        width: 100%;
        background-position: top;
        height: 680px;
        background-size: cover;
    }
`;
export const Title = styled.h2`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    width: ${props => (props.noData ? '40%' : '100%')};
    color: ${props => (props.section ? '#000000' : '#FFFFFF')};
    @media screen and (max-width: 568px) {
        width: 70%;
        margin-right: 0px;
    }
`;
export const Container1 = styled.div`
    display: flex;
    width: 80%;
    padding: 10px 20px 20px 20px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0px;
    @media screen and (max-width: 568px) {
        width: fit-content;
        margin-top: 35px;
    }
`;
export const Print = styled.div``;
export const Container2 = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
    .title {
        margin-top: 0px;
    }
    .noDataThumbprint {
        width: 30%;
        height: 80%;
    }
    @media screen and (max-width: 568px) {
        flex-direction: column;
        align-items: flex-start;
        .title {
            margin-left: 20px;
        }
        .noDataThumbprint {
            margin-top: 60px;
            width: 53%;
            height: 53%;
        }
    }
`;
export const Result = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 568px) {
        width: 80%;
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const ButtonRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-right: 50px;
    justify-content: space-between;
    .text {
        width: 40%;
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
    }
    @media screen and (max-width: 568px) {
        flex-direction: column;
        .text {
            width: 100%;
            margin-bottom: 10px;
        }
    }
`;
export const ButtonContainer = styled.div`
    box-sizing: border-box;

    & > a > button {
        padding-left: 30px;
        padding-right: 30px;
        width: 300px;
    }
    @media screen and (max-width: 768px) {
        width: 55%;
        & > a > button {
            padding-left: 30px;
            padding-right: 30px;
            width: 260px;
        }
    }
`;
export const Container = styled.div`
    display: flex;
    margin: 0px auto;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 5px 10px;
    & > img {
        width: 20%;
        height: 86%;
        right: 5%;
        position: absolute;
        top: 20px;
        @media screen and (max-width: 568px) {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        margin: 0px;
        padding: 0px;
    }
`;
export const SubTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    align-items: baseline;
    margin-bottom: 20px;
    @media screen and (max-width: 568px) {
        margin-bottom: 0px;
    }
`;
export const Impact = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: baseline;
    margin-right: 10px;
    @media screen and (max-width: 568px) {
        width: 100%;
        margin-bottom: 15px;
    }
`;
export const Data = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    .smallThumbprint {
        color: green;
        height: 100%;
        width: 20%;
        float: left;
        margin-right: 30px;
    }
    @media screen and (max-width: 568px) {
        align-items: center;
        justify-content: space-between;
        height: 130px;
        width: 50%;
        .smallThumbprint {
            height: 55%;
            width: 65%;
            margin-right: 20px;
        }
    }
`;
export const Text = styled.p`
    color: ${props => (props.red ? '#FF0505' : '#7BB548')};
    width: 100%;
    height: auto;
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    font-family: 'Helvetica';
`;
