import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './PaymentSuccess.css';
import { FaFacebook } from 'react-icons/fa';
import { addOrder, deleteCart } from '../../../../coreApi';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../../../ducks/user.duck';
import { updateUser } from '../../../../coreApi';

const PaymentSuccess = ({
    currentSelectedPlan,
    selectedLandScape,
    handleCartStatus,
    user,
    subscriptionId,
}) => {
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS
    const { amount, plan } = currentSelectedPlan;
    const { name } = selectedLandScape;
    const dispatch = useDispatch();
    const order = {
        orderId: subscriptionId,
        customer: {
            id: user && user.email,
            email_address: user && user.email,
            opt_in_status: true,
            first_name: user && user.firstName,
            last_name: user && user.lastName,
        },
        order: [
            {
                id: user && user.email,
                product_id:
                    currentSelectedPlan && currentSelectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${currentSelectedPlan.plan}_${currentSelectedPlan.planType}`,
                product_variant_id:
                    currentSelectedPlan && currentSelectedPlan.plan == 'polen'
                        ? 'polen'
                        : `${currentSelectedPlan.plan}_${currentSelectedPlan.planType}`,
                quantity: 1,
                price: currentSelectedPlan.amount / 100,
            },
        ],
    };

    useEffect(() => {
        const newCount = user && (parseInt(user.cartCount) + 1).toString();
        const cartId = user.email + '_' + user.cartCount;
        addOrder(order).then(() => {
            deleteCart(cartId).then(() => {
                handleCartStatus(false);
                updateUser({ cartCount: newCount }).then(() => {
                    dispatch(fetchCurrentUser());
                });
            });
        });
    }, []);

    useEffect(() => {
        googleAnalytics && window.gtag('event', 'conversion', {
            send_to: 'AW-10887015223/t5xTCJXbi8IDELfWqsco',
            value: amount / 100,
            currency: 'USD',
        });
    }, []);

    useEffect(() => {
        googleAnalytics && window.gtag('event', 'plan_subscription', {
            send_to:'G-34FQJZ2P9G',
            plan: currentSelectedPlan.plan,
            price: currentSelectedPlan.amount / 100,
            landscape: name
        });
    }, []);
    return (
        <div className={css.paymentSuccessContainer} id="paymentSuccess">
            <h3>
                <FormattedMessage
                    id="NewFriendsPage.Setup.PaymentSucc.congratesMsg"
                    values={{
                        landscape: <>{name}</>,
                        amount: <>{amount / 300}</>,
                        plan: <>{plan}</>,
                    }}
                />
            </h3>
            <div className={css.actionBoxContainer}>
                <div className={css.actionBox}>
                    <h3 style={{ fontSize: 30 }}>
                        <FormattedMessage id="FinalReportPage.discover" />
                    </h3>
                    <p style={{ fontSize: 16 }}>
                        <FormattedMessage id="FinalReportPage.download.journey" />
                    </p>
                    <a
                        className="_2vmz"
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://${process.env.REACT_APP_BASE_URL}/calculator`}
                        target="_blank"
                        id="u_0_1_Bl"
                    >
                        <button className={css.fbButton}>
                            <FaFacebook className={css.socialIcons} />
                            <span>
                                <FormattedMessage id="FinalReportPage.download.report" />
                            </span>
                        </button>
                    </a>
                </div>
                <div className={css.actionBox}>
                    <h3 style={{ fontSize: 30 }}>
                        <FormattedMessage id="FinalReportPage.tell" />
                    </h3>

                    <p style={{ fontSize: 16 }}>
                        <FormattedMessage id="FinalReportPage.share" />
                    </p>

                    <button
                        onClick={() =>
                            window.open(
                                `mailto:email@example.com?subject=Calculate your ecological and carbon footprint&body=Hi, Calculate your ecological and carbon footprint`
                            )
                        }
                        className={css.orangeButton}
                    >
                        <FormattedMessage id="FinalReportPage.invite" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
