import React, { useState, useRef } from 'react';
import { StaticPage } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
} from '../../components';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Hero from '../../newComponents/Hero/Hero';
import { FormattedMessage } from 'react-intl';
import guardianHero from '../../assets/guardianHero.jpg';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import Carousel from 'react-grid-carousel';
import luis from '../../assets/luis.jpg';
import rufina from '../../assets/rufina.png';
import andres from '../../assets/andres.png';
import Video from '../../newComponents/Video';
import ImageDescription from '../../newComponents/ImageDescription/ImageDescription';
import SetupAccount from './SetupAccount';
import styles from './GuardianPage.module.css';
import Button from '../../newComponents/Button';
import { useHistory, withRouter } from 'react-router-dom';

import {
    Testimony,
    SectionContent,
    TestimonyList,
    TestimonialsTitle,
    TestimonyPic,
    TestimonyDescription,
    TestimonyPeople,
    TestimonyPeopleName,
    TestimonyCard,
    Heading,
    Communtiy,
    Title,
    CommunityList,
    LogoContainer,
    Guide,
    StyledContainer,
    SignupButton,
    Plan,
    OrgHeading,
    ButtonGroup,
    PlanText,
    OrgDescription,
    ButtonContainer,
    Personal,
    GetStarted,
    GetStartedContainer,
    GetStartedHeading,
    ReasonContainer,
    Icon,
    Reason,
    ContactUs,
    ContactUsText,
    Separator,
    StyledContainerDeskTopWithoutBgImage,
    StyledContainerMobile,
    TestimonyListDesktop,
    TestimonyListMobile,
} from '../Partners/Style';
import Community from '../components/Community';
import Check from '../../assets/newAssets/check.png';
import infographic1 from '../../assets/guardians/infographic1.png';
import infographic2 from '../../assets/guardians/infographic2.png';
import infographic3 from '../../assets/guardians/infographic3.png';
import infographic4 from '../../assets/guardians/infographic4.png';
import { connect } from 'react-redux';
import GuardianRoles from './GuardianRoles';

const HowItWorksGuide = [
    {
        img: infographic1,
        description: <FormattedMessage id="NewPartnersPage.Guide.Signup.Description" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Signup" />,
        inverse: false,
    },
    {
        img: infographic2,
        description: <FormattedMessage id="NewPartnersPage.Guide.Details.Description" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Details" />,
        inverse: true,
    },
    {
        img: infographic3,
        description: <FormattedMessage id="NewPartnersPage.Guide.Approval.Description" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Approval" />,
        inverse: false,
    },
    {
        img: infographic4,
        description: <FormattedMessage id="NewPartnersPage.Guide.Share.Description" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Share" />,
        inverse: true,
    },
];

const GuardiansPage = props => {
    const [isOpen, setisOpen] = useState(false);
    const accountSetupRef = useRef();

    //const [visibleForm, setVisibleForm] = useState('guardianRole');
    const [visibleForm, setVisibleForm] = useState('');

    const setSignUpVisible = () => {
        setVisibleForm('signup');
        accountSetupRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const funcOpen = () => {
        setisOpen(!isOpen);
    };

    const history = useHistory();

    const redirectToLogin = () => {
        history.push('/login');
    };

    return (
        <StaticPage
            title="Guardians"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'GuardiansPage',
                description:
                    'Land managers including local and Indigenous communities, farmers, agroforestry producers, companies, and non-profit organisations who protect and restore Nature through their work in the field.',
                name: 'Guardians',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <Header
                        transparent
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain>
                    <Hero
                        title={<FormattedMessage id="Guardian.title" />}
                        subtitle={<FormattedMessage id="Guardian.subtitle" />}
                        image={guardianHero}
                    />
                    <GetStarted>
                        <GetStartedContainer>
                            <GetStartedHeading>
                                <FormattedMessage id="NewPartnersPage.GetStarted.Heading" />
                            </GetStartedHeading>
                            {props.language === 'EN' ? (
                                <Video
                                    vimeo={true}
                                    onEnd={() => {
                                        setisOpen(!isOpen);
                                    }}
                                    onClick={funcOpen}
                                    open={isOpen}
                                    source="https://player.vimeo.com/video/262300183?autoplay=1"
                                    videoItem={{
                                        src:
                                            'https://i.vimeocdn.com/video/695913061-fe2788d89ff3b0d8edb6d4a07852ecc26eab5e146a673e5f5211fc6e869ccfae-d_640x360',
                                        srcset:
                                            'https://i.vimeocdn.com/video/695913061-fe2788d89ff3b0d8edb6d4a07852ecc26eab5e146a673e5f5211fc6e869ccfae-d_960x540 2x, https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_1280x720 3x, https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_1920x1080 4x',
                                        alt: 'REGENERA_EN',
                                    }}
                                />
                            ) : (
                                <Video
                                    vimeo={true}
                                    onEnd={() => {
                                        setisOpen(!isOpen);
                                    }}
                                    onClick={funcOpen}
                                    open={isOpen}
                                    source="https://player.vimeo.com/video/259797549?autoplay=1"
                                    videoItem={{
                                        src:
                                            'https://i.vimeocdn.com/video/689030522-a075499d931afe19386266fa481baa716a6902c65ad4f1d4275800454670a4a7-d_640x360',
                                        srcset:
                                            'https://i.vimeocdn.com/video/689030522-a075499d931afe19386266fa481baa716a6902c65ad4f1d4275800454670a4a7-d_960x540 2x, https://i.vimeocdn.com/video/689030522-a075499d931afe19386266fa481baa716a6902c65ad4f1d4275800454670a4a7-d_1280x720 3x, https://i.vimeocdn.com/video/689030522-a075499d931afe19386266fa481baa716a6902c65ad4f1d4275800454670a4a7-d_1920x1080 4x',
                                        alt: 'REGENERA_ES',
                                    }}
                                />
                            )}

                            {!props.user ? (
                                <div className={styles.actionButtons}>
                                    <Button
                                        minWidth={'230px'}
                                        variant={'filled'}
                                        onClick={setSignUpVisible}
                                        testId="btn-guardian-signup-top" // Specify testId prop here data-testid="btnGuardianSignUpTop"
                                    >
                                        Sign me up
                                    </Button>
                                    <Button
                                        onClick={redirectToLogin}
                                        testId="btn-guardian-login"
                                        variant={'invertedGhost'}
                                        minWidth={'300px'}
                                    >
                                        I'm already a member
                                    </Button>
                                </div>
                            ) : null}
                        </GetStartedContainer>
                        <GetStartedContainer>
                            <GetStartedHeading>
                                <FormattedMessage id="NewGuardiansPage.Reasons.Heading" />
                            </GetStartedHeading>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewGuardiansPage.Reason.1" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="100%" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewGuardiansPage.Reason.2" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewGuardiansPage.Reason.3" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewGuardiansPage.Reason.4" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewGuardiansPage.Reason.5" />
                                </Reason>
                            </ReasonContainer>
                        </GetStartedContainer>
                    </GetStarted>
                    {props.user ? null : (
                        <div ref={accountSetupRef}>
                            {visibleForm === 'signup' && (
                                <>
                                    <h3 className={styles.setupAccountMainHeading}>
                                        <FormattedMessage id="NewGuardiansPage.Setup.Account.title" />
                                    </h3>
                                    <h3 className={styles.setupAccountMainHeading}>
                                        <FormattedMessage id="NewGuardiansPage.Setup.Account.subtitle" />
                                    </h3>
                                    <SetupAccount />
                                </>
                            )}
                        </div>
                    )}

                    {props.user ? <GuardianRoles /> : null}

                    <Guide>
                        <Title>
                            <FormattedMessage id="NewPartnersPage.Guide" />
                        </Title>
                        <StyledContainerDeskTopWithoutBgImage>
                            {HowItWorksGuide.map((item, index) => {
                                return (
                                    <ImageDescription
                                        src={item.img}
                                        description={item.description}
                                        title={item.title}
                                        inverse={item.inverse}
                                    />
                                );
                            })}
                        </StyledContainerDeskTopWithoutBgImage>
                        <StyledContainerMobile>
                            {HowItWorksGuide.map((item, index) => {
                                return (
                                    <ImageDescription
                                        src={item.img}
                                        description={item.description}
                                        title={item.title}
                                        inverse={false}
                                    />
                                );
                            })}
                        </StyledContainerMobile>
                    </Guide>
                    {!props.user ? (
                        <div className={styles.signupButtonContainer}>
                            <Button
                                testId="btn-guardian-signup-bottom"
                                size="md"
                                variant={'filled'}
                                onClick={setSignUpVisible}
                            >
                                Sign me up
                            </Button>
                        </div>
                    ) : null}
                    <Community />
                    <Testimony id="testimonials">
                        <SectionContent>
                            <Heading>
                                <FormattedMessage id="GuardiansPage.Community" />
                            </Heading>
                            <TestimonialsTitle>
                                <FormattedMessage id="Testimonials.title.guardians" />
                            </TestimonialsTitle>
                            <TestimonyListDesktop>
                                <TestimonyCard>
                                    <TestimonyPic src={rufina} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.rufina.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.rufina.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.rufina.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                                <TestimonyCard>
                                    <TestimonyPic src={luis} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.luis.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.luis.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.luis.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                                <TestimonyCard>
                                    <TestimonyPic src={andres} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.andres.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.andres.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.andres.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                            </TestimonyListDesktop>
                            <TestimonyListMobile>
                                <Carousel
                                    cols={2}
                                    rows={1}
                                    gap={40}
                                    loop
                                    showArrow={true}
                                    hideDots={true}
                                    scrollSnap={false}
                                    mobileBreakpoint={359}
                                    responsiveLayout={[
                                        {
                                            breakpoint: 800,
                                            cols: 1,
                                            rows: 1,
                                            gap: 10,
                                        },
                                    ]}
                                >
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={rufina} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.rufina.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.rufina.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.rufina.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={luis} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.luis.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.luis.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.luis.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={andres} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.andres.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.andres.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.andres.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                </Carousel>
                            </TestimonyListMobile>
                        </SectionContent>
                    </Testimony>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer history={props.history} />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(GuardiansPage));
