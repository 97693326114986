import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Frame, MobileComp } from '../../../Styles';
import Header from '../../Header/Header';
import { Doughnut } from 'react-chartjs-2';
import LeftNav from '../../LeftNav/LeftNav';
import { FormattedMessage } from 'react-intl';
import RightNav from '../../RightNav/RightNav';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/Footer';
import Button from '../../../../../newComponents/Button';
import LineChart from '../../../components/Chart/Chart';
import React, { useEffect, useState, useRef } from 'react';
import { fetch_calculateFootprint } from '../../../../../coreApi';
import deadly from '../../../../../assets/calculator/deadly.svg';
import serious from '../../../../../assets/calculator/serious.svg';
import Co2Cloud from '../../../../../assets/calculator/co2cloud-new.svg';
import catastrophic from '../../../../../assets/calculator/catastrophic.svg';
import { ReactComponent as Earth } from '../../../../../assets/calculator/earth.svg';
import { ReactComponent as LeftArrow } from '../../../../../assets/newAssets/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/newAssets/RightArrow.svg';
import {
    WarnImage,
    ButtonContainer,
    Disclaimer,
    ResultCo2CloudImageContainer,
    FootprintMain,
    Title,
    Navigation,
    ResultCardItems,
    HighlightNumber,
    EcologicalChartWrapper,
    Footprint,
    Date,
    Chart,
    ResultCardWrapper,
    EcologicalChartDonut,
    EcologicalChartLabels,
    GrayText,
    ResultCo2CloudImage,
    ResultCo2CloudImageText,
    ResultCardItemBorderImage,
    ResultGlobalWarmingText,
    ResultCardItemBorder,
    EarthWrapper,
    ResultEarthText,
    CarbonResult,
    ResultCardCFItem,
} from './Styles';

import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
});

const MyNatureFootprintPage = connect(mapStateToProps)(({ location, history, user }) => {
    const [fractionEarth, setFractionEarth] = useState(0);
    const [ecoResults, setEcoResults] = useState(null);
    const [LandDeforested, setLandDeforested] = useState(null);
    const [date, setDate] = useState(null);
    const [index, setIndex] = useState(0);
    const [data, setData] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const historyPath = useHistory();
    useEffect(() => {
        if (user && user.userType === 'empresa') {
            historyPath.push('/partner-profile/footprint');
        }
    }, [user]);

    useEffect(() => {
        let isComponentMounted = true;
        const fetchData = async () => {
            const res = await fetch_calculateFootprint();
            if (isComponentMounted) {
                if (!isEmpty(res.data.data)) {
                    setData(res.data.data);
                    setGraphData(res.data);
                    planetHandler(res.data.data[0].planets);
                    setEcoResults(res.data.data[0]);
                    setLandDeforested(res.data.data[0].landDeforested);
                    setDate(res.data.data[0].updatedAt);
                }
            }
        };
        fetchData();
        return () => {
            isComponentMounted = false;
        };
    }, []);

    function handleDateLeft() {
        const currIndex = index + 1;
        setIndex(currIndex);
        if (currIndex < data.length) {
            planetHandler(data[currIndex].planets);
            setLandDeforested(data[currIndex].landDeforested);
            setEcoResults(data[currIndex]);
            setDate(data[currIndex].updatedAt);
        }
    }
    function handleDateRight() {
        const currIndex = index - 1;
        if (currIndex >= 0) {
            setIndex(currIndex);
            planetHandler(data[currIndex].planets);
            setLandDeforested(data[currIndex].landDeforested);
            setEcoResults(data[currIndex]);
            setDate(data[currIndex].updatedAt);
        }
    }

    const planetHandler = planets => {
        let whole = Math.floor(planets);
        let fraction = planets - whole;
        fraction = 132 * fraction;
        setFractionEarth(fraction);
    };

    const capPlanets = planets => {
        if (planets > 5) {
            return 5;
        } else {
            return planets;
        }
    };

    const squareMetersHandler = hectars => {
        let squareMeters = (hectars * 10000).toString().split('.')[0];
        let result = '';

        let i = squareMeters.length - 1;

        while (i > 0) {
            if (i - 3 > 0) {
                result = ',' + squareMeters.substring(i - 2, i + 1) + result;
            } else {
                result = squareMeters.substring(i - 2, i + 1) + result;
            }
            i = i - 3;
        }
        return result;
        /* convert hectars into square meters */
    };

    const footballFieldsHandler = hectars => {
        return Math.round(hectars * 0.82);
        /* convert hectars into football fields */
    };

    const earths = Array.from(
        Array(ecoResults && (ecoResults.planets ? Math.floor(capPlanets(ecoResults.planets)) : 0)),
        (e, i) => {
            return (
                <Earth width="80px" key={i}>
                    {i}
                </Earth>
            );
        }
    );
    let chartData = [];

    if (ecoResults && ecoResults.ef_percentage) {
        chartData = [
            ecoResults.ef_percentage.residence.toFixed(1),
            ecoResults.ef_percentage.energy.toFixed(1),
            ecoResults.ef_percentage.transport.toFixed(1),
            ecoResults.ef_percentage.food.toFixed(1),
            ecoResults.ef_percentage.waste.toFixed(1),
        ];
    }
    const inputRef = useRef(null);

    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={
                    <>
                        Friend profile
                        <br />& account
                    </>
                }
                image={HeroImage}
            />
            <FootprintMain>
                <Title>
                    <FormattedMessage id="MyNaturePage.Footprint.Title" />
                </Title>
                {data &&
                    data.length > 0 &&
                    (data.length === 1 && date ? (
                        <Navigation>
                            <Date className="date">{date.slice(0, 10)}</Date>
                        </Navigation>
                    ) : (
                        date && (
                            <Navigation>
                                <LeftArrow
                                    className={index < data.length - 1 ? 'visible' : 'invisible'}
                                    onClick={handleDateLeft}
                                />
                                <Date className="date">{date.slice(0, 10)}</Date>
                                <RightArrow
                                    className={index > 0 ? 'visible' : 'invisible'}
                                    onClick={handleDateRight}
                                />
                            </Navigation>
                        )
                    ))}
                {ecoResults ? (
                    <Footprint>
                        <Title box>
                            <FormattedMessage id="MyNaturePage.Footprint.Title.Ecological" />
                        </Title>
                        <>
                            <ResultCardWrapper>
                                <ResultCardItems>
                                    <HighlightNumber>
                                        {ecoResults.total_ef_icl_biodiversity
                                            ? ecoResults.total_ef_icl_biodiversity.toFixed(1)
                                            : ''}
                                        &nbsp;global hectares
                                    </HighlightNumber>
                                    <EcologicalChartWrapper>
                                        <EcologicalChartDonut>
                                            <Doughnut
                                                data={{
                                                    datasets: [
                                                        {
                                                            data: chartData,
                                                            backgroundColor: [
                                                                '#c511bd',
                                                                '#d70505',
                                                                '#e96201',
                                                                '#b7d972',
                                                                '#008ad8',
                                                            ],
                                                            borderWidth: 0,
                                                        },
                                                    ],
                                                }}
                                                height={156}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    cutoutPercentage: 40,
                                                    legend: {
                                                        display: false,
                                                    },
                                                    tooltips: {
                                                        enabled: true,
                                                    },
                                                }}
                                            />
                                        </EcologicalChartDonut>
                                        <EcologicalChartLabels>
                                            <div>
                                                <span
                                                    style={{ color: '#c511bd', fontSize: '20px' }}
                                                >
                                                    &#9632;
                                                </span>
                                                <FormattedMessage id="CalculatorPage.results.ef.category.residence" />
                                            </div>
                                            <div>
                                                <span
                                                    style={{ color: '#d70505', fontSize: '20px' }}
                                                >
                                                    &#9632;
                                                </span>
                                                <FormattedMessage id="CalculatorPage.results.ef.category.energy" />
                                            </div>
                                            <div>
                                                <span
                                                    style={{ color: '#e96201', fontSize: '20px' }}
                                                >
                                                    &#9632;
                                                </span>
                                                <FormattedMessage id="CalculatorPage.results.ef.category.transport" />
                                            </div>
                                            <div>
                                                <span
                                                    style={{ color: '#b7d972', fontSize: '20px' }}
                                                >
                                                    &#9632;
                                                </span>
                                                <FormattedMessage id="CalculatorPage.results.ef.category.food" />
                                            </div>
                                            <div>
                                                <span
                                                    style={{ color: '#008ad8', fontSize: '20px' }}
                                                >
                                                    &#9632;
                                                </span>
                                                <FormattedMessage id="CalculatorPage.results.ef.category.waste" />
                                            </div>
                                        </EcologicalChartLabels>
                                    </EcologicalChartWrapper>
                                    <GrayText>
                                        <FormattedMessage
                                            id="CalculatorPage.results.ef.hectares"
                                            values={{
                                                squareMeters: (
                                                    <>
                                                        {squareMetersHandler(
                                                            ecoResults.total_ef_icl_biodiversity
                                                        )}
                                                    </>
                                                ),
                                                footballFields: (
                                                    <>
                                                        {footballFieldsHandler(
                                                            ecoResults.total_ef_icl_biodiversity
                                                        )}
                                                    </>
                                                ),
                                            }}
                                        />
                                    </GrayText>
                                </ResultCardItems>
                                <ResultCardItemBorder>
                                    <EarthWrapper>
                                        {earths}
                                        {fractionEarth > 0 &&
                                        Math.floor(capPlanets(ecoResults.planets)) < 5 ? (
                                            <Earth
                                                width="80px"
                                                viewBox={`${-(132 - fractionEarth)} 0 133 132`}
                                                //style={{ marginLeft: `${60 - (132 - fractionEarth) - 30}` }}
                                                style={{
                                                    marginLeft: `${-(
                                                        80 -
                                                        (fractionEarth / 132) * 80
                                                    )}`,
                                                }}
                                            />
                                        ) : null}
                                    </EarthWrapper>
                                    <ResultEarthText>
                                        <FormattedMessage
                                            id="CalculatorPage.results.ef.planet"
                                            values={{
                                                planet: ecoResults.planets ? (
                                                    <span style={{ color: '#fd9c29' }}>
                                                        {Number(
                                                            capPlanets(ecoResults.planets)
                                                        ).toFixed(1)}
                                                    </span>
                                                ) : (
                                                    ''
                                                ),
                                            }}
                                        />
                                    </ResultEarthText>
                                </ResultCardItemBorder>
                            </ResultCardWrapper>
                        </>
                    </Footprint>
                ) : (
                    <Footprint empty>
                        <GrayText empty>
                            {' '}
                            <FormattedMessage id="MyNaturePage.Footprint.NoRecord" />{' '}
                        </GrayText>
                        <ButtonContainer>
                            <Button
                                variant="filled"
                                size="lg"
                                path="/people/personal-footprint-calculator"
                            >
                                <FormattedMessage id="MyNaturePage.Footprint.Calculate" />
                            </Button>
                        </ButtonContainer>
                    </Footprint>
                )}
                {ecoResults && (
                    <Footprint>
                        <Title box>
                            <FormattedMessage id="MyNaturePage.Footprint.Title.Carbon" />
                        </Title>
                        <CarbonResult>
                            <ResultCardWrapper>
                                <ResultCardCFItem>
                                    <GrayText>
                                        {ecoResults.total_cf
                                            ? parseInt(ecoResults.total_cf.toFixed(1) * 1000)
                                            : ''}{' '}
                                        kg CO<sub>2</sub> emmission equivalent to {LandDeforested}{' '}
                                        square meters of Amazon rain forest deforested.
                                    </GrayText>
                                    <ResultCo2CloudImageContainer>
                                        <ResultCo2CloudImage src={Co2Cloud} />
                                        <ResultCo2CloudImageText>
                                            <FormattedMessage
                                                id="CalculatorPage.results.cf.cloudText"
                                                values={{
                                                    CO2: (
                                                        <>
                                                            CO<sub>2</sub>
                                                            <br />
                                                        </>
                                                    ),
                                                    warning: (
                                                        <>
                                                            <br />
                                                            {ecoResults.increase_in_temp < 2 ? (
                                                                <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.serious" />
                                                            ) : ecoResults.increase_in_temp <= 4 ? (
                                                                <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.critical" />
                                                            ) : (
                                                                <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.catastrophic" />
                                                            )}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </ResultCo2CloudImageText>
                                    </ResultCo2CloudImageContainer>
                                    <div>
                                        <HighlightNumber>
                                            {ecoResults.total_cf
                                                ? ecoResults.total_cf.toFixed(1)
                                                : ''}{' '}
                                            tonnes
                                        </HighlightNumber>
                                        <br />
                                        <HighlightNumber black>
                                            <FormattedMessage
                                                id="CalculatorPage.results.cf.co2"
                                                values={{
                                                    CO2: (
                                                        <>
                                                            CO<sub>2</sub>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </HighlightNumber>
                                    </div>
                                </ResultCardCFItem>

                                <ResultCardItemBorderImage>
                                    <WarnImage
                                        src={
                                            ecoResults.increase_in_temp <= 2
                                                ? serious
                                                : ecoResults.increase_in_temp <= 4
                                                ? deadly
                                                : catastrophic
                                        }
                                    />
                                    <ResultGlobalWarmingText>
                                        <FormattedMessage id="CalculatorPage.results.cf.planet.context" />
                                        <span className="orange">
                                            <FormattedMessage
                                                id="CalculatorPage.results.cf.planet.result"
                                                values={{
                                                    warming: ecoResults.increase_in_temp ? (
                                                        <span style={{ color: '#fd9c29' }}>
                                                            {ecoResults.increase_in_temp < 6
                                                                ? ecoResults.increase_in_temp.toFixed(
                                                                      1
                                                                  )
                                                                : 6}
                                                            &nbsp;degrees
                                                        </span>
                                                    ) : (
                                                        ''
                                                    ),
                                                    result: (
                                                        <FormattedMessage
                                                            id={
                                                                ecoResults.increase_in_temp <= 2
                                                                    ? 'CalculatorPage.results.cf.planet.result.serious'
                                                                    : ecoResults.increase_in_temp <=
                                                                      4
                                                                    ? 'CalculatorPage.results.cf.planet.result.deadly'
                                                                    : 'CalculatorPage.results.cf.planet.result.catastrophic'
                                                            }
                                                        />
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </ResultGlobalWarmingText>
                                </ResultCardItemBorderImage>
                            </ResultCardWrapper>
                        </CarbonResult>
                    </Footprint>
                )}
                {graphData && (
                    <Chart>
                        {data && data.length > 1 && (
                            <Disclaimer>
                                *<FormattedMessage id="MyNaturePage.Footprint.Disclaimer" />{' '}
                            </Disclaimer>
                        )}
                        {graphData && <LineChart data={graphData} />}
                    </Chart>
                )}
                <Footer />
                <MobileComp>
                    <RightNav />
                </MobileComp>
            </FootprintMain>
            <RightNav />
        </Frame>
    );
});
export default MyNatureFootprintPage;
