import React from 'react';
import {CardContainer, Card} from './Styles.js';
import CardHeading from '../../../section/CardHeading/cardHeading.js';
import {ActivityList} from '../../components/ActivityList/activityList.js'
const ActivityCard = (props)=>{
    return (
        <>
            <CardContainer>
                <Card>
                    <CardHeading 
                        headingId={props.headingId} 
                        subHeadingId={props.subHeadingId}
                />
                <ActivityList 
                type={props.type}
                scope={props.scope}
                emissionActivityDetails={props.emissionActivityDetails}
                units={props.units}
                emissionActivities={props.emissionActivities}
                emissionActivitiesData={props.emissionActivitiesData}/>       
                </Card>
            </CardContainer>
        </>
    );
}
export default ActivityCard;