import React from 'react';
import Slider from 'react-slick';

const LogoSlider = ({ settings, data }) => {
    return (
        <Slider {...settings}>
            {data.map(item => (
                <div key={item.id} className="co-logo">
                    <img src={item.img} alt="company logos" />
                </div>
            ))}
        </Slider>
    );
};
export default LogoSlider;
