import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LightGreenBtn } from './Button/ButtonSkin';
import featureLinkIcon from '../assets/newAssets/icons/feature-link-icon.svg';

import { Link } from 'react-router-dom';

const SelectPlanDiv = styled.div`
    padding: 15px;
    border-radius: 8px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
    .plan-intro {
        img {
            max-height: 37px;
        }
        .name-cost {
            margin-left: 8px;
            .plan-name {
                font-weight: 600;
                line-height: 180%;
            }
            .plan-cost {
                font-size: 19px;
                font-weight: 500;
                line-height: 27px;
            }
        }
    }

    .plan-data-box {
        flex-wrap: wrap;
        .plan-info {
            line-height: 150%;
            font-weight: 300;
            min-height: 100px;
        }
        .feature-boxes {
            padding: 8px 0;
            border-top: 1px solid #f6f6f6;
            .impact-title {
                font-weight: 600;
                line-height: 180%;
            }
            .feature {
                .feature-title {
                    font-size: 19px;
                    line-height: 27px;
                    font-weight: 500;
                }
                .short {
                    line-height: 150%;
                    font-weight: 300;
                }
                .list {
                    margin-top: 8px;
                    li {
                        line-height: 180%;
                        margin-top: 4px;
                        position: relative;
                        gap: 8px;
                        align-items: center;
                        .img-box {
                            min-width: 22px;
                            text-align: center;
                        }
                        .list-txt {
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    .extra {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-radius: 8px 8px 8px 8px;
        padding-left: 14px;
        box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease, transform 0.5s ease;
        // transform: translateY(100%);
        z-index: 10;

        &.expanded {
            max-height: 80%; /* Adjust this percentage as needed */
            transform: translateY(0);
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;
            z-index: 11;
        }
    }
    .action {
        margin-top: auto;
        .action-link {
            font-size: 14px;
            color: #6ea44c;
            line-height: 180%;
            gap: 4px;
            font-weight: 600;
            &:hover {
                gap: 6px;
                img {
                    scale: 1.2;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .plan-intro {
            .name-cost {
                margin-left: 8px;
                .plan-cost {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
        .plan-data-box {
            .plan-info {
                min-height: auto;
                padding-bottom: 8px;
            }
        }
    }
`;

const SelectPlanBox = ({
    planBoxClass,
    planImg,
    planName,
    planInfo,
    planCost,
    planduration,
    actionLink,
    actionLinkShow,
    actionLinkHide,
    listTitle,
    impactList,
    featureList,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const slidingPanelRef = useRef(null);

    const toggleExpand = e => {
        e.preventDefault(); // Prevent default link behavior
        setIsExpanded(!isExpanded);
    };

    const handleClickOutside = event => {
        if (slidingPanelRef.current && !slidingPanelRef.current.contains(event.target)) {
            const slidingPanels = document.querySelectorAll('.action');
            let clickedInsideAnyPanel = false;

            slidingPanels.forEach(panel => {
                if (panel.contains(event.target)) {
                    clickedInsideAnyPanel = true;
                }
            });

            if (!clickedInsideAnyPanel) {
                setIsExpanded(false);
            }
        }
    };

    useEffect(() => {
        if (isExpanded) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isExpanded]);
    return (
        <SelectPlanDiv className={`flex flex-col ${planBoxClass}`}>
            <div className="plan-intro">
                <div className="plan-img-price flex">
                    <img src={planImg} alt={planName} />
                    <div className="name-cost">
                        <div className="plan-name small">{planName}</div>
                        <div className="plan-cost">
                            ${planCost}
                            <span>{planduration}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="plan-data-box flex">
                <div className="plan-info">
                    <small>{planInfo}</small>
                </div>
                <div className="feature-boxes w-full">
                    <div className="impact-title">{listTitle}</div>
                    <div className="feature">
                        <ul className="list">
                            {impactList.map(item => (
                                <li className="flex items-center">
                                    <div className="img-box flex justify-center items-center">
                                        <img src={item.impactIc} alt={item.listTxt} />
                                    </div>
                                    <span className="list-txt small">{item.listTxt}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div
                ref={slidingPanelRef}
                className={`plan-data-box flex extra ${isExpanded ? 'expanded' : ''}`}
            >
                <button className="close-btn" onClick={toggleExpand}>
                    &times;
                </button>
                <div className="feature-boxes w-full">
                    <div className="feature">
                        <ul className="list">
                            {featureList.map(item => (
                                <div className="feature">
                                    {item.featureTitle && (
                                        <>
                                            <div className="feature-title">{item.featureTitle}</div>
                                            <div className="short">
                                                <small>{item.featureShort}</small>
                                            </div>
                                        </>
                                    )}
                                    <ul className="list">
                                        {item.featureList &&
                                            item.featureList.map(innritem => {
                                                return <li>{innritem}</li>;
                                            })}

                                        {item.impactIc && (
                                            <li className="flex items-center">
                                                <div className="img-box flex justify-center items-center">
                                                    <img src={item.impactIc} alt={item.listTxt} />
                                                </div>
                                                <span className="list-txt small">
                                                    {item.listTxt}
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className="action">
                <a
                    href={actionLink}
                    onClick={toggleExpand}
                    className="action-link smooth flex items-center"
                >
                    {isExpanded ? actionLinkHide : actionLinkShow}{' '}
                    <img src={featureLinkIcon} alt="action icon" />
                </a>
            </div> */}
        </SelectPlanDiv>
    );
};

export default SelectPlanBox;
