import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as ArrowDown } from '../../../../../assets/newAssets/arrow-down-grey.svg';
import { ItemContainer,Dropdown, DropdownItem, Input, Holder, Row, Label,InputButton, ButtonContainer } from './Styles.js';
import { getEmissionActivities, getUnits} from '../../../../../coreApi';
import Button from '../../../../../newComponents/Button';
import { FormattedMessage } from 'react-intl';
 export const ActivityItem4 = (props) =>{


    const [isOpenWaste, setIsOpenWaste] = useState(false);
    const [selectedOptionWaste, setSelectedOptionWaste] = useState("");

    const [isOpenWasteType, setIsOpenWasteType] = useState(false);
    const [selectedOptionWasteType, setSelectedOptionWasteType] = useState("");

    const [isOpenUnit, setIsOpenUnit] = useState(false);
    const [selectedOptionUnit, setSelectedOptionUnit] = useState("");
    
    const [activities,setActivities]=useState([]);
    const [Waste,setWaste] = useState([]);
    const [WasteType,setWasteType] = useState([]);
    const [Units,setUnits] = useState([]);
    const [UnitType,setUnitType] = useState([]);
    const [userData, setUserData] = useState(props.data);

    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);
    const containerRef3 = useRef(null);
    const inputField    = useRef(null);

    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
            setIsOpenWaste(false);
        }
        if (containerRef2.current && !containerRef2.current.contains(e.target)) {
            setIsOpenUnit(false);
        }
        if (containerRef3.current && !containerRef3.current.contains(e.target)) {
            setIsOpenWasteType(false);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            const emissionActivitiesData = props.emissionActivityDetails;
            let Waste=[];
            emissionActivitiesData.map((data) => {
                if(data.groupName===props.type)
                {
                    setActivities(data.activities);
                    Waste=data.activities.map(item => item.activityName)
                        .filter((value, index, self) => self.indexOf(value) === index)
                }
            })
                setWaste(Waste);
             const Units = props.units;
             setUnits(Units);
        }
        fetchData();
    }, []);
   
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [containerRef1,containerRef2,containerRef3]);

    const openWaste = () => {
        setIsOpenWaste(!isOpenWaste);
    };
    const openWasteType = () => {

        const wasteType=[];
        activities.map((data,i) => {
            if(props.data.Waste!=''&&data.activityName===props.data.Waste)
            {
                wasteType.push(data.subType);
            }
        })
        setWasteType(wasteType);

        if(props.data.Waste==''){
            setIsOpenWasteType(false);
        }
        else{
            setIsOpenWasteType(!isOpenWasteType);
        }
    };
    const openUnit = () => {
        activities.map((data,i) => {
            if(props.data.Waste!=''&&data.activityName===props.data.Waste)
            {
                setUnitType(Units[data.quantityType]);
            }
        })
        if(props.data.Waste==''){
            setIsOpenUnit(false);
        }
        else{
            setIsOpenUnit(!isOpenUnit);
        }
        
    };

    function onSelectedWaste(value) {
        setSelectedOptionWaste(value);
        setIsOpenWaste(false);
        setUserData({...userData,Waste:value});
        
        props.data.Waste=value;
        const wasteType=[];
        activities.map((data,i) => {
            if(props.data.Waste!=''&&data.activityName===props.data.Waste)
            {
                setUnitType(Units[data.quantityType]);
                wasteType.push(data.subType);
            }
        })
        setWasteType(wasteType);
        props.saveData(props.data);
        onSelectedWasteType(wasteType[0]);
    };
    function onSelectedWasteType(value) {
        setSelectedOptionWasteType(value);
        setIsOpenWasteType(false);
        setUserData({...userData,WasteType:value});
        props.data.WasteType=value;

        const index= activities.findIndex((data) => data.activityName===props.data.Waste&&data.subType==value);
        if(index!=-1){
            props.data.activityId=activities[index].id;
        }
        props.saveData(props.data);
        if(index!=-1){
            onSelectedUnit(activities[index].efUnit);
        }
    };

    function onSelectedUnit(value) {
        setSelectedOptionUnit(value);
        setUserData({...userData,unit:value});
        setIsOpenUnit(false);
        props.data.unit=value;
        props.saveData(props.data);
    };
    let name, value;
    const handleChange = e => {
        const validNumber=/^(\d*|\d+\.\d{0,3}|\d*\.\d{1,3})$/;
        if(validNumber.test(e.target.value))
        {
            name = e.target.name;
            value =e.target.value;
            setUserData({ ...userData, [name]: value });
            if(value===""){
                props.data.value=0;
            }
            else{
                props.data.value=parseFloat(value);
            }
            
            props.saveData(props.data);
        }
    };
    
     return(
        <ItemContainer>
            <Row>
                <Holder>
                <Label htmlFor="name">
                            {props.label}
                        </Label>
                    <InputButton
                        name="Waste"
                        onClick={openWaste}
                    >
                        {(selectedOptionWaste || userData && userData.Waste)} <ArrowDown className="arrow" width="20px" height="20px" />
                    </InputButton>
                    {isOpenWaste && (
                        <Dropdown ref={containerRef1}>
                            {Waste.map((item,i) => (
                                <DropdownItem key={i} onClick={() => onSelectedWaste(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )
                    }
                </Holder>
                <Holder>
                <Label htmlFor="name">
                            {props.label2}
                        </Label>
                    <InputButton
                        name="WasteType"
                        onClick={openWasteType}
                    >
                        {(selectedOptionWasteType || userData && userData.WasteType)} <ArrowDown className="arrow" width="20px" height="20px" />
                    </InputButton>
                    {isOpenWasteType && (
                        <Dropdown ref={containerRef3}>
                            {WasteType.map((item,i) => (
                                <DropdownItem key={i} onClick={() => onSelectedWasteType(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )
                    }
                </Holder>
                <Holder>
                <Label htmlFor="name">
                            Value
                        </Label>
                    <Input
                        type="text"
                        placeholder="Value"
                        name="value"
                        value={userData.value}
                        onChange={handleChange}
                        ref={inputField}
                    />
                </Holder>
                <Holder>
                <Label htmlFor="name">
                            Unit
                        </Label>
                    <InputButton
                        name="unit"
                        onClick={openUnit}
                    >
                        {(selectedOptionUnit|| userData && userData.unit)} <ArrowDown className="arrow" width="20px" height="20px" />
                    </InputButton>
                    {isOpenUnit && (
                        <Dropdown ref={containerRef2}>
                            {UnitType.map((item,i) => (
                                <DropdownItem key={i} onClick={() => onSelectedUnit(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )
                    }
                </Holder>
                <ButtonContainer>
                        <Button
                            onClick={() => {props.deleteData(props.data.id)}}
                            variant="filled"
                            size="lg"
                        >
                            <FormattedMessage id="OrganisationCalculator.Button.Remove"/>
                        </Button>
                </ButtonContainer>
            </Row>
            
        </ItemContainer>
     );
 }

 export default ActivityItem4;