import styles from './styles.module.css';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ExternalLink } from '../../components';
import ShareBlogIcon from '../ShareBlogIcon';
import { facebook, instagram, twitter, linkedin, telegram, linkcopy } from '../ShareBlogIcon/icons';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';
import { StyledDropdownBox, StyledOption } from './Styles';

const ShareBlog = props => {
    const [isLinkCopied, setLinkCopied] = useState(false);
    const currentUrl = window.location.href;

    const handleClickShare = () => {
        navigator.clipboard.writeText(currentUrl).then(
            function() {
                setLinkCopied(true);
            },
            function() {
                setLinkCopied(false);
            }
        );
    };

    return (
        <StyledDropdownBox>
            <StyledOption key="desk-facebook">
                <ExternalLink
                    className="external-share-link"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        currentUrl
                    )}`}
                >
                    <ShareBlogIcon icon={facebook} />
                    Facebook
                </ExternalLink>
            </StyledOption>

            <StyledOption key="desk-twitter">
                <ExternalLink
                    className="external-share-link"
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                        currentUrl
                    )}&text=${encodeURIComponent(props.blogDetails.title)}`}
                >
                    <ShareBlogIcon icon={twitter} />X (Twitter)
                </ExternalLink>
            </StyledOption>
            <StyledOption key="desk-linkedin">
                <ExternalLink
                    className="external-share-link"
                    href={`https://www.linkedin.com/cws/share?url=${encodeURIComponent(
                        currentUrl
                    )}&text=${encodeURIComponent(props.blogDetails.title)}`}
                >
                    <ShareBlogIcon icon={linkedin} />
                    Linkedin
                </ExternalLink>
            </StyledOption>
            <StyledOption key="desk-instagram">
                <ExternalLink className="external-share-link" href={`https://www.instagram.com`}>
                    <ShareBlogIcon icon={instagram} />
                    Instagram
                </ExternalLink>
            </StyledOption>
            <StyledOption key="desk-telegram">
                <ExternalLink
                    className="external-share-link"
                    href={`https://t.me/share/url?url=${encodeURIComponent(
                        currentUrl
                    )}&text=${encodeURIComponent(props.blogDetails.title)}`}
                >
                    <ShareBlogIcon icon={telegram} />
                    Telegram
                </ExternalLink>
            </StyledOption>
            <StyledOption key="desk-copy">
                <ExternalLink
                    className={`external-share-link ${isLinkCopied ? 'disabled' : ''}`}
                    onClick={isLinkCopied ? null : handleClickShare}
                    disabled={isLinkCopied}
                >
                    <ShareBlogIcon icon={linkcopy} />
                    {isLinkCopied ? (
                        <FormattedMessage id="Blogs.ShareBlog.linkcopied" />
                    ) : (
                        <FormattedMessage id="Blogs.ShareBlog.copylink" />
                    )}
                </ExternalLink>
            </StyledOption>
        </StyledDropdownBox>
    );
};

export default withRouter(ShareBlog);
