import styled from 'styled-components';

export const PartnerSection = styled.section`
    .business-nature {
        background: #004532 url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat;
        background-size: cover;
        .nature-img {
            gap: 20px;
            overflow: hidden;
            .picture-box {
                margin-bottom: -5px;
            }
        }
        .content {
            text-align: center;
            padding-right: 4.8rem;
            .title-box {
                max-width: 522px;
                margin: 0 auto;
                .small-title {
                    display: none;
                }
                h3 {
                    font-size: 48px;
                    line-height: 58px;
                    color: white;
                }
            }
            .button-box {
                margin-top: 30px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .business-nature {
            .nature-img {
                flex-direction: row;
            }
            .content {
                text-align: center;
                padding-right: 30px;
                .title-box {
                    h3 {
                        font-size: 28px;
                        line-height: 38px;
                    }
                }
                .button-box {
                    margin-top: 30px;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .business-nature {
            .nature-img {
                flex-direction: column;
            }
            .content {
                padding: 55px 20px 55px;
            }
        }
    }
`;
