import {
    saveUserInterestForGuardian,
    getUserInterestForGuardian,
    getGuardianSubRoles,
    generateGuardianInterestDocument,
    signGuardianInterestDocument,
    saveUserCountryForGuardian,
} from '../../coreApi';

export const saveUserInterestForGuardianRole = async interestData => {
    try {
        await saveUserInterestForGuardian(interestData);
        return {
            status: 'success',
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
    }
};

export const saveUserCountryForGuardianRole = async countryName => {
    try {
        await saveUserCountryForGuardian(countryName);
        return {
            status: 'success',
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
    }
};

export const getUserInterest = async () => {
    try {
        const result = await getUserInterestForGuardian();
        return {
            status: 'success',
            subRoleId: result.data.guardianSubRoleId,
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
    }
};

export const getGuardianRoles = async () => {
    try {
        const result = await getGuardianSubRoles();
        return result.data;
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    }
};

export const generateInterestDocument = async ({ name, email, region, documentLanguage }) => {
    try {
        const result = await generateGuardianInterestDocument({
            name,
            email,
            region,
            documentLanguage,
        });
        console.log('result', result);
        return {
            status: 'success',
            data: result.data,
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Document generation failed!.',
        };
    } finally {
    }
};

export const signInterestDocument = async ({ name, email, signatureDataUrl }) => {
    try {
        const result = await signGuardianInterestDocument({ name, email, signatureDataUrl });
        console.log('result', result);
        return {
            status: 'success',
            data: result.data,
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.' + err,
        };
    } finally {
    }
};
