import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import documentSign from '../../../../assets/newAssets/icons/doc-create-ic.webp';
import { signInterestDocument } from '../../utils';

const CreatingSignSection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .title-box {
        img {
            max-width: 67px;
            margin-bottom: 32px;
        }
    }
    .creation-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 50px;
            .progress-bar {
                height: 15px;
                max-width: 285px;
                border-radius: 25px;
                --bulma-progress-border-radius: 25px;
                --bulma-progress-bar-background-color: #ffffff;
                --bulma-progress-value-background-color: #6ea44c;
                --bulma-progress-indeterminate-duration: 1.5s;
                border: 1px solid #ccc;
                appearance: none;
                border: none;
                overflow: hidden;
                padding: 0;
            }
        }
    }
    @media (max-width: 500px) {
        .creation-box {
            padding: 100px 20px 120px;
        }
    }
`;

const CreatingSign = ({ name, email, signatureDataUrl, onSignedDocumentReady }) => {
    const [progress, setProgress] = useState(75);

    const [showError, setShowError] = useState(null);
    useEffect(() => {
        const signDocument = async () => {
            try {
                const response = await signInterestDocument({ name, email, signatureDataUrl });

                if (response.status === 'success') {
                    const data = await response.data;

                    setProgress(data.progress);
                    onSignedDocumentReady(data.documentUrl);
                } else {
                    setShowError(response.message);
                }
            } catch (err) {
                setShowError(err.message);
            }
        };

        signDocument();
    }, [name, email, onSignedDocumentReady]);

    return (
        <CreatingSignSection className="mx-auto flex justify-center items-center">
            <div className="creation-box mx-auto text-center relative">
                <div className="title-box text-center">
                    <img src={documentSign} alt="signing doc process" />
                    <h2>
                        <FormattedMessage id="GuardianSubscription.DocuSign.SigningDoc.title" />
                    </h2>
                </div>
                <p>
                    <FormattedMessage id="GuardianSubscription.DocuSign.SigningDoc.desc" />
                </p>
                <div className="input-box-section">
                    <progress className="progress-bar w-full mx-auto" value={progress} max={100} />
                </div>
            </div>
        </CreatingSignSection>
    );
};

export default CreatingSign;
