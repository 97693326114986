import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Page,
  Hero,
} from '../../components';
import { PasswordChangeForm } from '../../forms';

import { changePassword, changePasswordClear } from './PasswordChangePage.duck';
import css from './PasswordChangePage.css';
import heroimage from '../../assets/detalleselva.jpg';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

export const PasswordChangePageComponent = props => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    onChange,
    onSubmitChangePassword,
    passwordChanged,
    scrollingDisabled,
    intl,
  } = props;

  const tabs = [
    {
      text: <FormattedMessage id="ContactDetailsPage.paisaje" />,
      selected: false,
      linkProps: {
        name: 'YourListingsPage',
      },
    },
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.messages" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'InboxPageOrders',
    //   },
    // },
    {
      text: <FormattedMessage id="ContactDetailsPage.profileSettings" />,
      selected: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ContactDetailsPage.contactDetailsTabTitle" />,
      selected: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="ContactDetailsPage.passwordTabTitle" />,
      selected: true,
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    {
      text: <FormattedMessage id="ContactDetailsPage.updateCard" />,
      selected: false,
      linkProps: {
        name: 'UpdateCardPage',
      },
    },
    {
      text: <FormattedMessage id="PaymentHistoryPage.title" />,
      selected: false,
      linkProps: {
        name: 'PaymentHistoryPage',
      },
    },
  ];
  const user = ensureCurrentUser(currentUser);
  const currentFirstName = user.attributes.profile.firstName || '';
  const changePasswordForm =
    currentUser && currentUser.id ? (
      <PasswordChangeForm
        className={css.form}
        changePasswordError={changePasswordError}
        currentUser={currentUser}
        onSubmit={onSubmitChangePassword}
        onChange={onChange}
        inProgress={changePasswordInProgress}
        ready={passwordChanged}
      />
    ) : null;

  const title = intl.formatMessage({ id: 'PasswordChangePage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <Header
            transparent
            currentPath={props.location.pathname}
            user={props.user}
            history={props.history}
          />

          <Hero
            size="medium"
            title={intl.formatMessage(
              { id: 'TopbarMobileMenu.greeting' },
              { displayName: currentFirstName }
            )}
            image={heroimage}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PasswordChangePage.heading" />
            </h1>
            {changePasswordForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer history={props.history}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PasswordChangePageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.PasswordChangePage;
  const { currentUser } = state.user;
  const user = state.user.currentUser
  ? {
      initials: state.user.currentUser.attributes.profile.abbreviatedName,
      name:
      state.user.currentUser.attributes.profile.firstName +
        ' ' +
        state.user.currentUser.attributes.profile.lastName,
    }
  : null;
  return {
    user,
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: values => dispatch(changePassword(values)),
});

const PasswordChangePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PasswordChangePageComponent);

export default PasswordChangePage;
