import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 60px;
  cursor: pointer;

  & > svg {
    fill: white;
  }
  & > svg:hover {
    fill: black;
  }
`;
