import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const RangeSliderContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 0.7rem;
    margin-bottom: 2.5rem;
    .rangedata {
        font-size: 42px;
        line-height: 52px;
        font-weight: 500;
        margin-bottom: 15px;
    }
`;

const Tooltip = styled.div`
    position: absolute;
    top: 7.5rem;

    span {
        position: absolute;
        text-align: center;
        display: inline-block;
        line-height: 1;
        padding: 0.125rem 0.25rem;
        text-wrap: nowrap;
        border-radius: 0.125rem;
        top: 0;
        font-size: 1rem;
        font-weight: 600;
        line-height: 150%;
        color: #6EA44C;

       
    }
        left: 50%;
        transform: translate(-50%, 0);
    }
`;

const RangeInput = styled.input.attrs({ type: 'range' })`
    -webkit-appearance: none;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.7rem;
        cursor: pointer;
        background: linear-gradient(
            90deg,
            #6ba132 var(--range-progress),
            #ddedd0 var(--range-progress)
        );
        border-radius: 1rem;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 0.25rem solid #6ba132;
        box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
        border-radius: 50%;
        background: #6ba132;
        cursor: pointer;
        height: 28px;
        width: 28px;
        transform: translateY(calc(-50% + 6px));
    }
`;

const hectaresToIncome = hectares => {
    const incomeMap = {
        10: 20,
        50: 40,
        100: 60,
        500: 140,
        1000: 200,
        5000: 450,
        10000: 650,
        50000: 1400,
        100000: 2000,
    };
    return incomeMap[hectares];
};
const RangeSlider = ({ period, unit }) => {
    const [amt, setAmt] = useState('');

    const [hectares, setHectares] = useState(10);
    const [income, setIncome] = useState(hectaresToIncome(10));

    const logScaleValues = [10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000];

    const handleInputChange = e => {
        const index = e.target.value;
        const hectaresValue = logScaleValues[index];
        setHectares(hectaresValue);
        setIncome(hectaresToIncome(hectaresValue));
    };

    useEffect(() => {
        const newValue = (logScaleValues.indexOf(hectares) * 100) / (logScaleValues.length - 1);
        const newPosition = 16 - newValue * 0.32;
        document.documentElement.style.setProperty(
            '--range-progress',
            `calc(${newValue}% + (${newPosition}px))`
        );
    }, [hectares]);

    return (
        <RangeSliderContainer>
            <Tooltip
                style={{
                    left: `calc(${(logScaleValues.indexOf(hectares) * 100) /
                        (logScaleValues.length - 1)}% + (${1 -
                        ((logScaleValues.indexOf(hectares) * 100) / (logScaleValues.length - 1)) *
                            0.32}px))`,
                }}
            >
                <span>
                    {hectares} {unit}
                </span>
            </Tooltip>
            <div className="rangedata">
                ${income}
                <small>/{period}</small>
            </div>
            <RangeInput
                step="1"
                value={logScaleValues.indexOf(hectares)}
                min="0"
                max={logScaleValues.length - 1}
                onChange={handleInputChange}
            />
        </RangeSliderContainer>
    );
};

export default RangeSlider;
