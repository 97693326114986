import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import Header from '../../../newPages/NewLandingPage/Sections/Header/Header';
import SelectSearch from '../../../newComponents/SelectSearch';
import { getAllCategories, searchBlogs, getAllBlogsByTag, handleQueryLanguage } from '../utils';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import BlogSearchResultCard from '../../../newComponents/BlogSearchResultCard';
import { IconSpinner } from '../../../components';
import Footer from '../../../newPages/NewLandingPage/Sections/Footer/Footer';
import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import NewFooter from '../../../newComponents/NewFooter';
import BlogContributeOptions from '../../../newComponents/BlogContributeOptions';
import { FormattedMessage } from 'react-intl';

const BlogSearchResultsPage = props => {
    const [categories, setCategories] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [blogItems, setBlogItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const { search } = useLocation();
    const values = queryString.parse(search);
    const [eop, setEOP] = useState(false);
    const [iniSearchResults, setIniSearchResults] = useState('');

    const fetchMoreData = () => {
        setTimeout(() => {
            if (blogItems.length + 6 <= searchResults.length) {
                setBlogItems(
                    blogItems.concat(searchResults.slice(blogItems.length, blogItems.length + 6))
                );
            } else {
                setBlogItems(
                    blogItems.concat(searchResults.slice(blogItems.length, searchResults.length))
                );
                setHasMore(false);
            }
        }, 500);
    };

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            const categoriesResponse = await getAllCategories(queryLang);
            if (categoriesResponse.status === 'success') {
                setCategories(categoriesResponse.data);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setIniSearchResults('');
            setSearchResults('');
            const queryLang = await handleQueryLanguage(props.language);
            let result;
            if (values.tag) {
                result = await getAllBlogsByTag(queryLang, values.tag);
            } else {
                if (values.category !== 'all') {
                    result = await searchBlogs(queryLang, values.q, values.category);
                } else {
                    result = await searchBlogs(queryLang, values.q);
                }
            }
            if (result.status === 'success') {
                if (result.data.length > 3) {
                    setEOP(false);
                    setSearchResults(result.data.slice(3, result.data.length));
                    setIniSearchResults(result.data.slice(0, 3));
                } else {
                    setEOP(true);
                    setIniSearchResults(result.data.slice(0, result.data.length));
                }
            }
        })();
    }, [values.q, values.category, values.tag]);

    return (
        <>
            <div className={styles.blogSearchPage}>
                <Header
                    currentPath={props.location.pathname}
                    user={props.user}
                    history={props.history}
                />
                <SelectSearch
                    categoryValue={values.category}
                    defaultSearchQuery={values.q}
                    categories={categories}
                />
                {values.q && (
                    <p className={styles.searchTerms}>
                        <span>
                            <FormattedMessage id="Blogs.Search.success" />
                        </span>
                        {values.q}
                    </p>
                )}
                {iniSearchResults && iniSearchResults.length === 0 ? (
                    <p className={styles.noResultsFound}>
                        <FormattedMessage id="Blogs.Search.failure" />
                    </p>
                ) : null}
                {iniSearchResults ? (
                    <>
                        <div className={styles.resultsSection}>
                            {iniSearchResults.map(element => (
                                <BlogSearchResultCard
                                    key={uuidv4()}
                                    imageUrl={element.blog_details.featuredImage}
                                    title={element.title}
                                    summary={element.blog_details.summary}
                                    writer={element.writer.name}
                                    publishDate={element.blog_details.publishDate}
                                    readTime={element.blog_details.readTime}
                                    tags={element.taxonomy.tags}
                                    visible={true}
                                    slug={element.slug}
                                />
                            ))}
                        </div>
                        <p style={{ display: eop ? 'block' : 'none', textAlign: 'center' }}>
                            <b>
                                <FormattedMessage id="Blogs.Search.endofpage" />
                            </b>
                        </p>
                    </>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconSpinner />
                    </div>
                )}
                {searchResults ? (
                    <>
                        <InfiniteScroll
                            dataLength={blogItems.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            scrollThreshold={0.0}
                            initialScrollY={0}
                        >
                            <div className={styles.resultsSection}>
                                {blogItems.map(element => (
                                    <BlogSearchResultCard
                                        key={uuidv4()}
                                        imageUrl={element.blog_details.featuredImage}
                                        title={element.title}
                                        summary={element.blog_details.summary}
                                        writer={element.writer.name}
                                        publishDate={element.blog_details.publishDate}
                                        readTime={element.blog_details.readTime}
                                        tags={element.taxonomy.tags}
                                        visible={true}
                                        slug={element.slug}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                        <p style={{ textAlign: 'center' }}>
                            <b>
                                <FormattedMessage id="Blogs.Search.endofpage" />
                            </b>
                        </p>
                    </>
                ) : null}
            </div>
            <BlogContributeOptions />
            <NewFooter />
        </>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(BlogSearchResultsPage));
