import React from 'react';
import SliderReview from 'react-slick';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import borderImg from '../../../../assets/newAssets/title-border-small.png';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';
import testimonial_img1 from '../../../../assets/newAssets/testimonials/rufina-rivera.webp';
import testimonial_img2 from '../../../../assets/newAssets/testimonials/modesto_challco.webp';
import testimonial_img3 from '../../../../assets/newAssets/testimonials/zacarias_zambrano.webp';

import { VidTestimonial } from './Styles';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                className: 'center',
                arrows: false,
                dots: true,
                speed: 300,
                centerPadding: '35px',
                infinite: true,
                adaptiveHeight: true,
            },
        },
    ],
};
const GuardianTestimonialContent = ({ testimonialClass }) => {
    const testimonialData = [
        {
            id: 1,
            profileImg: testimonial_img1,
            profileQuote: <FormattedMessage id="GuardiansPage.testimonials.quote.rufinarivera" />,
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.rufinarivera" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.rufinarivera" />
            ),
        },
        {
            id: 2,
            profileImg: testimonial_img2,
            profileQuote: <FormattedMessage id="GuardiansPage.testimonials.quote.modestochallco" />,
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.modestochallco" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.modestochallco" />
            ),
        },
        {
            id: 3,
            profileImg: testimonial_img3,
            profileQuote: (
                <FormattedMessage id="GuardiansPage.testimonials.quote.zacariaszambrano" />
            ),
            profileName: <FormattedMessage id="GuardiansPage.testimonials.name.zacariaszambrano" />,
            profilePost: (
                <FormattedMessage id="GuardiansPage.testimonials.designation.zacariaszambrano" />
            ),
        },
    ];

    return (
        <VidTestimonial bgGreenTexture={bgGreenTexture} className={testimonialClass}>
            <div className="two-cols flex justify-center items-center">
                <div className="testimonal-information flex flex-col items-start">
                    <h3 className="white-text">
                        <FormattedMessage id="GuardiansPage.testimonials.titleOne" />
                        <span className="border relative">
                            <FormattedMessage id="GuardiansPage.testimonials.titleLine" />

                            <img src={borderImg} className="absolute w-full" alt="border" />
                        </span>
                        <FormattedMessage id="GuardiansPage.testimonials.titleTwo" />
                    </h3>

                    <div className="parent-info flex flex-col">
                        <div className="testimonial-text"></div>
                        <div className="button-box">
                            <OrangeBtn>
                                <Link to="/land-managers/role">
                                    <FormattedMessage id="GuardiansPage.testimonials.titleBtn" />
                                </Link>
                            </OrangeBtn>
                        </div>
                    </div>
                </div>

                <div className="testimonal-slider">
                    <SliderReview {...settings}>
                        {testimonialData.map(item => (
                            <div className="slide-box">
                                <div className="profile-info-parent flex flex-col">
                                    <div className="profile-img mx-auto">
                                        <img alt="profile img" src={item.profileImg} />
                                    </div>
                                    <div className="profile-info-quote flex flex-col">
                                        <div className="profile-quote">{item.profileQuote}</div>
                                        <div className="profile-name">
                                            <strong>{item.profileName}</strong> - {item.profilePost}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SliderReview>
                </div>
            </div>
        </VidTestimonial>
    );
};

export default GuardianTestimonialContent;
