
import React, { Component,  useEffect,useState  } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import css from './ProfilePage.css';
import heroimage from '../../assets/detalleselva.jpg';
import { NotFoundPage, TopbarContainer } from '../../containers';
import {Footer, Hero, LayoutSingleColumn, LayoutWrapperFooter, LayoutWrapperMain, LayoutWrapperTopbar, ListingCard, NamedLink, Page, Reviews } from '../../components';
import * as coreAPI from '../../coreApi'
import classNames from 'classnames';
import imageLoader from './profileImages';

const ProfilePage = (props) => {


    const {intl} = props

    const userId = props.params.id
    const userSlug = props.params.slug

    const [loadProgress, setLoadProgress] = useState(true)
    const [userData, setUserData] = useState(null)

   

    useEffect( () => {
        coreAPI.getUserDetails(userSlug).then( (response) => {
          setUserData(response.data)
          setLoadProgress(false)
        }).catch(error => {
          setLoadProgress(false)
        })
    }, [userSlug])

    
    if(loadProgress == true) {
        return <div>Loading..</div>
    }

    if(loadProgress == false && userData === null) {
        return <NotFoundPage />;
    }


    const hasBio = false
    const hasOrgName = false 
    const displayName = ''
    const organizationName = (userData && userData.metadata && userData.metadata.organizationName) || ''

    const listingsContainerClasses = classNames(css.listingsContainer, {
        [css.withBioMissingAbove]: !hasBio,
      });

    
    const publicData = (userData && userData.metadata) || {}

    const hasLandscape = userData && userData.landscapes.length > 0  
    const userLandscapes = (userData && userData.landscapes ) || []

    const mainContent = (
        <React.Fragment>
        <div className={css.contentContainer}>
            <div className={css.avatar}>
                  <div className={css.profilePictureWrapper}>
                      <img className={css.profilePicture} src={userData.profilePicture}></img>
                  </div>
            </div>
          {hasLandscape ? (
            <div className={listingsContainerClasses}>
              <h2 className={css.titleSection}><FormattedMessage id="ProfilePage.organizationLandscape.title" values={{organizationName}}/></h2>
              <ul className={css.listings}>
                {userLandscapes.map(l => (
                  <li className={css.listing} key={l.id}>
                    <ListingCard listing={l} />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          </div>
          <div className={css.graySection}>
            <div className={css.contentContainer}>
              {hasBio ? <p className={css.bio}>{''}</p> : null}
              <div className={css.contentSection}>
  
              </div>
              <div className={css.contentSection}>
                <h2 className={css.titleSection}><FormattedMessage id="ProfilePage.organizationWork.title"/></h2>
                {
                  publicData.video ?
                  <iframe className={css.video} src={`https://player.vimeo.com/video/${publicData.video.replace('https://vimeo.com/','')}`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
                  : null
                }
                <p>{publicData.ourWorkWithRegenera}</p>
              </div>
  
              <div className={css.contentSection}>
                <h2 className={css.titleSection}><FormattedMessage id="ProfilePage.passion.title"/></h2>
                <p>{publicData.ourPassion}</p>
              </div>
  
            </div>
          </div>
  
          <div className={css.contentContainer}>
            <div className={css.contentSection}>
              <h2 className={css.titleSection}><FormattedMessage id="ProfilePage.organizationGallery.title" values={{organizationName}}/></h2>
              <div className={css.photoShow}>
                {
                  publicData.staticProfileImages ?
                  imageLoader(publicData.staticProfileImages,'pics').map((item)=>
                    <div>
                      <img src={item.url}/>
                      <p>{item.description}</p>
                    </div>
                  )
                  : null
                }
              </div>
            </div>
        </div>
        </React.Fragment>
    );

    const schemaTitle = intl.formatMessage(
        {
          id: 'ProfilePage.schemaTitle',
        },
        {
          name: hasOrgName || displayName,
          siteTitle: 'Regenera',
        }
      );
    
    const scrollingDisabled = false 

    const content = mainContent

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <Hero size='medium' title='' image={publicData.staticProfileImages ? imageLoader(publicData.staticProfileImages,'cover') : heroimage}/>
              {content}

          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
}

export default injectIntl(ProfilePage);