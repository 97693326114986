import styled from 'styled-components';

export const MainForm = styled.section`
    display: flex;
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        display: block;
    }
`;

export const FormSection = styled.section`
    width: 100%;
    padding: 57px 5% 50px;

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        margin-top: 66px;
        padding: 20px;
        .breadcrumb {
            margin-bottom: 56px;
            gap: 29px;

            a {
                position: relative;
                font-size: 14px;
                color: #6ea44c;
                &::after {
                    content: '>';
                    position: absolute;
                    right: -18px;
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
            span {
                position: relative;
                font-size: 14px;
                color: #004d37;

                &::after {
                    content: '>';
                    position: absolute;
                    right: -18px;
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
            .active {
                font-weight: 600;
                color: #004d37;
            }
        }
    }
`;
