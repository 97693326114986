import React, { forwardRef, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../../newComponents/Form/FormikControl';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Button from '../../../../newComponents/Button';
import stripe from '../../../../assets/newAssets/stripe.png';
import css from './Payment.css';
import {
    CardCVCElement,
    CardExpiryElement,
    CardNumberElement,
    injectStripe,
} from 'react-stripe-elements';
import { FaSpinner } from 'react-icons/fa';
import ReCaptcha from 'react-google-recaptcha';
import { PlanPaymentInfo } from './planPaymentInfo';
import { paymentInitialValues } from './PaymentInitialValues';
import { paymentValScheme } from './PaymentValScheme';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as coreAPI from '../../../../coreApi';
import LandScapeSelection from '../LandscapeSelection';
import { extractPlanID } from '../../../Personas/helper';
import { subUnitDivisors } from '../../../../currency-config';

const createOptions = () => {
    return {
        hidePostalCode: true,
        showIcon: true,
        style: {
            base: {
                fontSize: '18px',
                fontWeight: 700,
                color: '#2D3748',
                fontFamily: 'Galano Grotesque',
            },
        },
    };
};

const Payment = props => {
    const {
        selectedPlan: { planType },
        handlePaymentStatus,
        updateSubscriptionPlan,
        selectedPlan,
        supportLandscape,
        selectedLandScape,
        handleLandscapeSelection,
        updatePlanID,
        planID,
        intl,
        handleDisable,
        isDisable,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [tcError, setTCError] = useState(false);
    const [tcCheck, setTCCheck] = useState(false);
    const [name, setName] = useState('');
    const [emailUpdateCheck, setEmailUpdateCheck] = useState(false);
    const history = useHistory();
    const TCRef = useRef();
    const reCaptchaRef = useRef(null);
    const [numberRef, setNumberRef] = useState(null);
    const [expiryRef, setExpiryRef] = useState(null);
    const [cvvRef, setCvvRef] = useState(null);

    const clearCardFields = () => {
        numberRef.clear();
        expiryRef.clear();
        cvvRef.clear();
    };

    const {
        currentUser: { attributes },
    } = useSelector(state => state.user);

    const currentLocale = intl.locale;

    const onSubmit = async (values, actions) => {
        handlePaymentStatus(false);
        handleDisable(true);
        setPaymentError(false);
        setTCError(false);
        if (!tcCheck) {
            setTCError(true);
            handleDisable(false);
            TCRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (tcCheck) {
            try {
                if (!planID) {
                    return;
                }
                let token = { id: '' };
                let response = { token: {} };
                response = await props.stripe.createToken({ name });
                token = { ...token, ...response.token };
                coreAPI.subscribeToLandscapePlan(planID, token.id).then(async response => {
                    if (response.data) {
                        if (response.data.status === 'requires_action') {
                            // We perform 3D Secure authentication

                            const { paymentIntent, error } = await props.stripe.confirmCardPayment(
                                response.data.clientSecret
                            );
                            if (error) {
                                //alert('Error in payment, please try again later');
                                handleDisable(false);
                                setPaymentError(true);
                            }
                            if (paymentIntent.status === 'succeeded') {
                                updateSubscriptionPlan(response.data.subscription.subscriptionId);
                                handlePaymentStatus(true);
                                setPaymentError(false);
                            }
                            //alert(`Payment successful, payment ID - ${response.data.id}`);

                            //const res2 = await axios.get(`http://localhost:5000/check/${res.data.id}`);
                        } else if (response.data.status === 'requires_payment_method') {
                            //alert('Error in payment, please try again later');
                            handleDisable(false);
                            setPaymentError(true);
                        } else if (response.data.status === 'succeeded') {
                            updateSubscriptionPlan(response.data.subscription.subscriptionId);
                            handlePaymentStatus(true);
                            setPaymentError(false);
                        } else {
                            handleDisable(false);
                            setPaymentError(true);
                        }
                    } else {
                        handleDisable(false);
                        setPaymentError(true);
                    }
                    clearCardFields();
                });
            } catch (e) {
                handleDisable(false);
                console.error(e);
                setLoading(false);
                setPaymentError(true);
                clearCardFields();
            }
        }

        if (emailUpdateCheck) {
            try {
                let captchaToken;
                if (reCaptchaRef.current) {
                    captchaToken = await reCaptchaRef.current.executeAsync();
                    reCaptchaRef.current.reset();
                }
                const subscribetoNewsLetterResp = await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: `${attributes.profile.firstName}`,
                        lastname: `${attributes.profile.lastName}`,
                        email: `${attributes.email}`,
                        language: currentLocale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
                console.log({ subscribetoNewsLetterResp });
            } catch (error) {
                console.log('Subcribe to Newsletter error', error);
            }
        }
        setLoading(false);
    };
    const initialValues = {};
    const validationSchema = paymentValScheme;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, isValid, setFieldValue }) => (
                <div className={css.payment} id="payment">
                    <div className={`${css.paymentFormContainer}`}>
                        <h5>
                            <FormattedMessage id="NewPartnersPage.Setup.Payment.title" />
                        </h5>
                        <Form className={css.paymentForm}>
                            <div className={css.paymentFormFieldContainer}>
                                <ReCaptcha
                                    ref={reCaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    size="invisible"
                                />
                                <div className={css.formElementWrapper}>
                                    <label>
                                        <FormattedMessage id="NewPartnersPage.Setup.Payment.cardHolderName" />
                                    </label>
                                    <div className={css.stripeElemWrapper}>
                                        <input
                                            type="text"
                                            className={css.cardInputName}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={css.formElementWrapper}>
                                    <label>
                                        <FormattedMessage id="NewPartnersPage.Setup.Payment.cardNumber" />
                                    </label>
                                    <div className={css.stripeElemWrapper}>
                                        <CardNumberElement
                                            {...createOptions()}
                                            onReady={e => setNumberRef(e)}
                                        />
                                    </div>
                                </div>
                                <div className={css.cardAddInfo}>
                                    <div
                                        className={css.formElementWrapper}
                                        style={{
                                            maxWidth: '264px',
                                            marginRight: '28px',
                                            width: '100%',
                                        }}
                                    >
                                        <label>
                                            <FormattedMessage id="NewPartnersPage.Setup.Payment.expiryDate" />
                                        </label>
                                        <div
                                            className={`${css.stripeElemWrapper} ${css.stripeHalfElmLeft}`}
                                        >
                                            <CardExpiryElement
                                                {...createOptions()}
                                                onReady={e => setExpiryRef(e)}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={css.formElementWrapper}
                                        style={{ maxWidth: '181px', width: '100%' }}
                                    >
                                        <label>
                                            <FormattedMessage id="NewPartnersPage.Setup.Payment.CVC" />
                                        </label>
                                        <div
                                            className={`${css.stripeElemWrapper} ${css.stripeHalfElmRight}`}
                                        >
                                            <CardCVCElement
                                                {...createOptions()}
                                                onReady={e => setCvvRef(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={css.stripeCard}>
                                    <h4>{PlanPaymentInfo[planType]}</h4>
                                    <div className={css.stripeCardImgCont}>
                                        <img src={stripe} />
                                        <h5>
                                            <FormattedMessage id="NewPartnersPage.Setup.Payment.StripeMsg" />
                                        </h5>
                                    </div>
                                </div>
                                <div className={css.checkBox} ref={TCRef}>
                                    <input
                                        type="checkbox"
                                        id="termsandcond"
                                        checked={tcCheck}
                                        onClick={() => setTCCheck(!tcCheck)}
                                    />
                                    <label htmlFor="termsandcond">
                                        <FormattedMessage id="NewPartnersPage.Setup.Payment.tc1p1" />
                                        <Link to={'/terms-of-service'} target="_blank">
                                            <FormattedMessage id="NewPartnersPage.Setup.Payment.tc1p2" />
                                        </Link>
                                        <FormattedMessage id="NewPartnersPage.Setup.Payment.tc1p3" />
                                        <Link to={'/privacy-policy'} target="_blank">
                                            <FormattedMessage id="NewPartnersPage.Setup.Payment.tc1p4" />
                                        </Link>
                                    </label>
                                </div>
                                <div className={css.checkBoxError}>
                                    {!tcCheck && tcError && (
                                        <div className={css.error}>
                                            <p>
                                                <FormattedMessage id="NewPartnersPage.Setup.Payment.tcErrMsg" />
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <div className={css.checkBox}>
                                    <input
                                        type="checkbox"
                                        id="emailupdate"
                                        checked={emailUpdateCheck}
                                        onClick={() => setEmailUpdateCheck(!emailUpdateCheck)}
                                    />
                                    <label htmlFor="emailupdate">
                                        <FormattedMessage id="NewPartnersPage.Setup.Payment.tc2" />
                                    </label>
                                </div>
                            </div>
                            <LandScapeSelection
                                selectedPlan={selectedPlan}
                                supportLandscape={supportLandscape}
                                handleLandscapeSelection={handleLandscapeSelection}
                                updatePlanID={updatePlanID}
                            />
                            <Button
                                variant={isDisable ? 'disabled' : 'filled'}
                                disabled={isDisable ? true : false}
                                testId="btn-partner-joinpay"
                            >
                                {isDisable && <FaSpinner className={css.spinner} />}

                                {isDisable ? (
                                    <FormattedMessage id="NewPartnersPage.Button.Processing" />
                                ) : (
                                    <FormattedMessage id="NewPartnersPage.Setup.Payment.JoinAndPayBtn" />
                                )}
                            </Button>
                            {paymentError && (
                                <div className={css.error}>
                                    <p>
                                        <FormattedMessage id="PaymentError.title" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="PaymentError.subtitle" />
                                    </p>
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default injectStripe(injectIntl(Payment));
