import styled from 'styled-components';

export const FounderContainer = styled.section`
    .autor-section {
        background-color: #f8fbf6;
        padding: 110px 4.8rem;
        max-width: 1920px;
    }

    .autor-section {
        .imgBoxClass {
            flex: 1 0 50%;
            max-width: 522px;
        }
        .img-text-container {
            align-items: stretch;
        }
    }

    .img-text-container {
        margin: 0 auto;
        &::before {
            content: ' ';
            background-image: url(${({ heroLeaf }) => heroLeaf});
            background-size: 100% auto;
            width: 104px;
            height: 178px;
            position: absolute;
            right: 0;
            top: -60px;
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .autor-section {
            padding: 24px 20px;
            .img-text-container {
                .imgBoxClass {
                    order: 1;
                    min-width: auto;
                }
                &::before {
                    content: none;
                }
                .content {
                    .content-box {
                        p {
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const ImageText = styled.div`
    position: relative;
    margin: 0 auto;

    .container {
        gap: 128px;
        .content {
            max-width: 1200px;
            .title-box {
                margin-bottom: 16px;
                .small-title {
                    font-size: 19px;
                    line-height: 180%;
                    letter-spacing: 3.5px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.colors.light_green};
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }
                .border {
                    position: relative;
                    img {
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                    }
                }
            }
            .content-box {
                p {
                    margin-top: 25px;
                    color: ${({ theme }) => theme.colors.text_color};
                }
                .sign-text {
                    max-width: 130px;
                    line-height: 180%;
                    margin-top: 25px;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .container {
            flex-direction: column;
            gap: 24px;
            .content {
                .title-box {
                    margin-bottom: 8px;
                    .small-title {
                        font-size: 17px;
                        line-height: 180%;
                    }
                }
            }
        }
    }
`;
