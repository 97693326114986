import React from 'react'
import {Card, StyledLink } from './Styles';
import friendsImage from '../../assets/newAssets/friendsImage.png';
import partnerImage from '../../assets/newAssets/partnerImage.png';
import CardImage from '../CardImageContainer/CardImage';
import {FormattedMessage} from 'react-intl';

const RightNavCard = (props) => {
    const {user} = props;
    const currentRole = user && user.currentRole
    const rolePresent = user && (user.activatedRoles.includes('partner') || user.activatedRoles.includes('guardian'));
    return (
        <>{
            rolePresent && 
            <Card>
            <CardImage
                image={currentRole=="partner"?partnerImage:friendsImage}
            />
            {currentRole == "partner" ? 
                <StyledLink to={'/profile-check?role=partner'}>
                    <p className="title"><FormattedMessage id="NewPartnersPage.Profile.Dropdown.Title"/></p>
                </StyledLink>
                :
                <StyledLink to={'/profile-check?role=friend'}>
                    <p className="title"><FormattedMessage id="NewFriendsPage.Profile.Dropdown.Title"/></p>
                </StyledLink>
            }
            </Card>
        }
        </>
    )
}

export default RightNavCard
