import React from 'react';
import { css } from './Form.css';
// import CheckboxGroup from './CheckboxGroup';
import Input from './Input';
import Select from './Select';
import Password from './Password';
// import TextArea from './TextArea';
// import AsyncSelectField from './AsyncSelect';
import { Field } from 'formik';

const FormikControl = props => {
  const { control, ...rest } = props;
  const { name, loadOptions, label, placeholder } = rest;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    // case 'textarea':
    //   return <TextArea {...rest} />;
    case 'password':
      return <Password {...rest} />;
    case 'select':
      return <Select {...rest} />;
    // case 'async-select':
    //   return (
    //     <Field
    //       name={name}
    //       component={AsyncSelectField}
    //       loadOptions={loadOptions}
    //       label={label}
    //       placeholder={placeholder}
    //     />
    //   );
    // case 'checkbox':
    //   return <CheckboxGroup {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
