import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import Button from '../../../../../newComponents/Button';
import { 
  getCurrentUser, 
  updateUser, 
  changePassword, 
  uploadImage, 
  getStripeSupportedLocales,
  getStripeCustomerLocale,
  updateStripeCustomerLanguage
} from '../../../../../coreApi';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ReactComponent as ArrowDown } from '../../../../../assets/newAssets/arrow-down.svg';
import ProfileForm from '../../../components/ProfileForm';
import Footer from '../../../components/Footer';
import { compose } from 'redux';
import DragAndDrop from '../../../components/DragAndDrop';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from '../../../../../containers/UpdateCardPage/CheckoutFormUpdate';
import { isEmpty, isNil } from 'lodash';
import eye from '../../../../../assets/newAssets/eye.png'
import {
  DropdownHolder,
  DropdownInputButton,
  LanguageDropdown,
  LanguageDropdownItem,
  LanguageButton
} from "./Styles"
import {
    ProfileAndAccountMain,
    Title,
    SubTitle,
    PasswordForm,
    Label,
    ErrorMessage,
    ButtonContainer,
    Password,
    ReEnterPassword,
    PasswordFormContainer,
    Element,
    PaymentHistory,
    IncreaseImpact,
    ElementRow,
    Para,
    ElementButtonContainer1,
    ElementButtonContainer2,
    Story,
    Work,
    Content,
    ConfirmPassword,
    PasswordTitle,
    PasswordDescription,
    TextArea,
    StyledSuccessMessage,
    Row, Input, PasswordContainer,
    ImageContainer, Image, UploadContainerImage,
    FileImage, ErrorDiv, Wrapper, 
    CardDetailsForm, CardContainer,InputPassword,Eye,
    LanguageSelectionContainer, LanguageSelectionContent, LanguageSelectionTitle, LanguageSelectionDescription, 
    LanguagePreferencesContainer
} from './Styles';

import { ReactComponent as CrossImage } from '../../../../../assets/newAssets/CrossImage.svg';
import { ReactComponent as ChooseFile } from '../../../../../assets/newAssets/chooseFile.svg';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/partner_profile.png';
import ToggleComponent from '../../../../../newComponents/ToggleComponent/toggleComponent';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
        }
        : null,

});

const NewOurNatureProfileAndAccountPage = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {
    const [userPassion, setUserPassion] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [successPassword, setSuccessPassword] = useState(false);
    const [errorWork, setErrorWork] = useState(false);
    const [errorLink, setErrorLink] = useState(false);
    const [successWork, setSuccessWork] = useState(false);
    const [errorPassion, setErrorPassion] = useState(false);
    const [successPassion, setSuccessPassion] = useState(false);
    const [nonMatchingPassword, setNonMatchingPassword] = useState(false);
    const [landscapeImage, setLandscapeImage] = useState([]);
    const [metadata, setMetadata] = useState();
    const [stripe, setStripe] = useState(null);
    const [userWork, setUserWork] = useState({
        link: "",
        work: ""
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [rePasswordShown, setRePasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const historyPath = useHistory();
    const [error, setError] = useState(false);
    const containerRef1 = useRef(null);
    const [isOpenLang, setIsOpenLang] = useState(false);
    const [selectedOptionLang, setSelectedOptionLang] = useState('');
    const [Languages, setLanguages] = useState(null);
    const [langSuccess, setLangSuccess] = useState(false);
    const [langError, setLangError] = useState(false);
    const [langLoading, setlangLoading] = useState(false);
    
    useEffect(() => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    }, [user])

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    const toggleRePassword = () => {
        setRePasswordShown(!rePasswordShown);
      };

    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
      };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCurrentUser();
            if (res.data.metadata) {
                setMetadata(res.data.metadata);
                setLandscapeImage(res.data.metadata.landscapeImage);
            }
            if (landscapeImage && landscapeImage.length < 4) {
                setError(false);
            }
        }
        fetchData();
        if (landscapeImage && landscapeImage.length < 4) {
            setError(false);
        }
    }, []);

    useEffect(() => {
        fetchSupportedLocales();
        fetchUserLocales();
      }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [containerRef1]);
    
      const fetchUserLocales = async () =>{
           getStripeCustomerLocale().then((response) =>{
          setSelectedOptionLang(response.data)
        })
      }
      const fetchSupportedLocales = async () =>{
           getStripeSupportedLocales().then((response)=>{
          setLanguages(response.data)
        })
      } 

    const handleDrop = async (files) => {
        if (landscapeImage && (landscapeImage.length > 4 || (files.length + landscapeImage.length > 4))) {
            setError(true);
            return;
        }
        if (landscapeImage && landscapeImage.length < 4) {
            setError(false);
        }
        let landscape = landscapeImage || [];
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            const send = await uploadImage(files[i]);
            if (send.data && send.data.filePath) {
                landscape.push(send.data.filePath);
            }
        }
        const newLandscape = landscape.map((item) => {
            return item.split('/').pop();
        });
        const userMetadata = {
            ...metadata,
            landscapeImage: newLandscape,
        }
        setMetadata(userMetadata);
        await updateUser({ metadata: userMetadata });
        setLandscapeImage((item) => { return [...landscape] });
    }

    const deleteImage = async (img) => {
        if (landscapeImage) {
            const updated = landscapeImage.filter((landscape) => {
                return landscape !== img;
            });

            const newUpdated = updated.map((item) => {
                return item.split('/').pop();
            });
            if (newUpdated) {
                const userMetadata = {
                    ...metadata,
                    landscapeImage: newUpdated,
                }
                setMetadata(userMetadata);
                await updateUser({ metadata: userMetadata });
            }

            setLandscapeImage(updated);
        }
        if (landscapeImage && landscapeImage.length < 4) {
            setError(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCurrentUser();
            if (res.data.metadata) {
                setMetadata(res.data.metadata);
                if (res.data.metadata.ourPassion) {
                    setUserPassion(res.data.metadata.ourPassion);
                }
                if (res.data.metadata.userWork) {
                    setUserWork(res.data.metadata.userWork);
                }
                if (res.data.metadata.landscapeImage) {
                    setLandscapeImage(res.data.metadata.landscapeImage);
                }
            }
        }
        fetchData();
    }, []);

    const handlePassionChange = e => {
        setUserPassion(e.target.value)
    };
    const handleWorkChange = e => {
        name = e.target.name;
        value = e.target.value;
        setUserWork({ ...userWork, [name]: value })
    };
    const handlePassion = async (inter) => {
        let landscape = landscapeImage || [];
        const newLandscape = landscape.map((item) => {
            return item.split('/').pop();
        });
        const userMetadata = {
            ...metadata,
            landscapeImage: newLandscape,
            ourPassion: userPassion,
        }
        setMetadata(userMetadata);
        if (userPassion.length < 5 || userPassion.length < 5) {
            setErrorPassion(true);
        }
        else {
            setSuccessPassion(true);
            setErrorPassion(false)
            await updateUser({ metadata: userMetadata });
        }
    };
    const handleWork = async (inter) => {
        let landscape = landscapeImage || [];
        const newLandscape = landscape.map((item) => {
            return item.split('/').pop();
        });
        const userMetadata = {
            ...metadata,
            landscapeImage: newLandscape,
            userWork: userWork
        }
        const link = !isNil(metadata)&&!isEmpty(metadata)?
                    !isNil(metadata.userWork)&&!isEmpty(metadata.userWork)?
                    !isNil(metadata.userWork.link)?metadata.userWork.link:"":"":"";

        const work = !isNil(metadata)&&!isEmpty(metadata)?
                    !isNil(metadata.userWork)&&!isEmpty(metadata.userWork)?
                    !isNil(metadata.userWork.work)?metadata.userWork.work:"":"":"";

        setMetadata(userMetadata);
        if ( userWork.work.length < 5) {
            setErrorWork(true);
        }
        else {
            setSuccessWork(true);
            setErrorWork(false)
            await updateUser({ metadata: {...userMetadata,userWork : {work:userWork.work,link:link}} });
        }
        const validateUrl=/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        if ( !isEmpty(userWork.link) && !validateUrl.test(userWork.link)) {
            setErrorLink(true);
        }
        else {
            setSuccessWork(true);
            setErrorLink(false);
            await updateUser({ metadata: {...userMetadata,userWork : {work:work,link:userWork.link}} });
        }
    };

    let name, value;
    const [userPassword, setUserPassword] = useState({
        password: "",
        rePassword: "",
        confirmPassword: ""
    });
    const handlePassChange = e => {
        name = e.target.name;
        value = e.target.value;
        setUserPassword({ ...userPassword, [name]: value });
    };
    const updatePassword = async (e) => {

        if (userPassword.password.length < 8 || userPassword.rePassword.length < 8) {
            setErrorPassword(true);
            setSuccessPassword(false);
            setInvalidPassword(false);
            setNonMatchingPassword(false);
        }
        else if(userPassword.rePassword !== userPassword.confirmPassword) {
            setNonMatchingPassword(true);
            setErrorPassword(false);
            setSuccessPassword(false);
            setInvalidPassword(false);
        }
        else {
            await changePassword(userPassword.password, userPassword.rePassword).then(() =>{
                setSuccessPassword(true);
                setErrorPassword(false);
                setInvalidPassword(false);
                setNonMatchingPassword(false);
            }).catch(() =>{
                setSuccessPassword(false);
                setErrorPassword(true);
                setInvalidPassword(false);
                setNonMatchingPassword(false);
            })
        }
    }
    const handlePasswordError = () => {
        setInvalidPassword(true);
        setSuccessPassword(false);
        setErrorPassword(false);
    }
    const SuccessMessage = <StyledSuccessMessage><FormattedMessage id= "OurNaturePage.Profile.Success"/></StyledSuccessMessage>;

    const [isInvoiceVisible,setIsInvoiceVisible]= useState(false);
    const [isCardVisible,setIsCardVisible]= useState(false);
    const [isPasswordVisible,setIsPasswordVisible]= useState(false);

    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
          setIsOpenLang(false);
        }
      };
    
    function onSelectedLang(value) {
        setSelectedOptionLang(value);
        setIsOpenLang(false);
        setLangError(false);
        setLangSuccess(false);
    }
    const openLang = () => {
      setIsOpenLang(!isOpenLang);
    };

    const updateUserLanguage = async () =>{
      setlangLoading(true);
      updateStripeCustomerLanguage(selectedOptionLang).then(async ()=>{
        setLangError(false);
        setLangSuccess(true);
        await fetchUserLocales()
        setlangLoading(false);
      }).catch(()=>{
        setLangSuccess(false);
        setLangError(true);
        setlangLoading(false);
      })
      
    }
    
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Partner profile<br/>& account</>}
                image={HeroImage}
            />
            <ProfileAndAccountMain>
                <ProfileForm intl={intl} />
                
                <ToggleComponent 
                    titleId="OurNaturePage.Profile.Toggle.Invoices"
                    onClick={()=>{
                        setIsInvoiceVisible(!isInvoiceVisible)
                    }} 
                    isVisible={isInvoiceVisible}
                />

                {isInvoiceVisible &&  
                  <LanguageSelectionContainer>
                    <LanguageSelectionTitle>
                      <FormattedMessage id="OurNaturePage.Profile.LanguageSelection.Title" />
                    </LanguageSelectionTitle>
                    <LanguageSelectionContent>
                      <LanguageSelectionDescription >
                        <FormattedMessage id="OurNaturePage.Profile.LanguageSelection.Description" />
                      </LanguageSelectionDescription>
                      <LanguagePreferencesContainer>
                        <DropdownHolder medium>
                          <Label htmlFor="name">
                              <FormattedMessage id="OurNaturePage.Profile.LanguageSelection.Dropdown.Title" />
                          </Label>
                          <DropdownInputButton name="Languages" onClick={openLang}>
                              <span>{selectedOptionLang}{' '}</span>
                              <ArrowDown className="arrow" width="34" height="34" />
                          </DropdownInputButton>
                          {isOpenLang && (
                            <LanguageDropdown ref={containerRef1}>
                            {Languages &&
                              Languages.map((item, i) => (
                              <LanguageDropdownItem
                                key={i}
                                onClick={() => onSelectedLang(item)}
                              >
                                {item}
                              </LanguageDropdownItem>
                              ))}
                            </LanguageDropdown>
                          )}
                        </DropdownHolder>

                        <LanguageButton>
                          {!langLoading? 
                          <Button
                              onClick={updateUserLanguage}
                              variant="filled"
                              size="lg"
                              >
                                  <FormattedMessage id="OurNaturePage.Profile.LanguageSelection.Dropdown.Button"/>
                          </Button> : 
                          <Button
                                  onClick={() =>{}}
                                  variant="disabled"
                                  size="lg"
                              >
                                  <FormattedMessage id="OurNaturePage.Profile..Dropdown.Button.Disabled"/>
                          </Button>}
                        </LanguageButton>
                      {langSuccess && <StyledSuccessMessage><FormattedMessage id="OurNaturePage.Profile..Dropdown.Language.Update.Success"/></StyledSuccessMessage>}
                      {langError && <ErrorMessage><FormattedMessage id="OurNaturePage.Profile..Dropdown.Button.Update.Error"/></ErrorMessage>}
                      </LanguagePreferencesContainer>
                    </LanguageSelectionContent>
                  </LanguageSelectionContainer>}

                 <ToggleComponent 
                    titleId="MyNaturePage.Profile.Toggle.UpdateCard"
                    onClick={()=>{
                        setIsCardVisible(!isCardVisible)
                    }} 
                    isVisible={isCardVisible}
                />

                {isCardVisible && <CardDetailsForm>
                    <Title>
                        <FormattedMessage id="MyNaturePage.Profile.CardTitle" />
                    </Title>
                    <CardContainer>
                        <StripeProvider stripe={stripe}>
                            <Elements>
                                <InjectedCheckoutForm />
                            </Elements>
                        </StripeProvider>
                    </CardContainer>
                </CardDetailsForm>}

                <ToggleComponent 
                    titleId="MyNaturePage.Profile.Toggle.Password"
                    onClick={()=>{
                        setIsPasswordVisible(!isPasswordVisible)
                    }} 
                    isVisible={isPasswordVisible}
                />

                {isPasswordVisible && <PasswordForm>
                    <PasswordTitle>
                        <FormattedMessage id="MyNaturePage.Profile.Password.Title" />
                    </PasswordTitle>
                    <PasswordContainer>
                        <PasswordDescription >
                            <FormattedMessage id="MyNaturePage.Profile.Password.Description" />
                        </PasswordDescription>
                        <PasswordFormContainer>
                            <Row column={true}>
                                <Password>
                                    <Label htmlFor="password">
                                        <FormattedMessage id="OurNaturePage.Profile.PasswordTitle" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={passwordShown ? "text" : "password"}
                                        placeholder="********"
                                        name="password"
                                        onChange={handlePassChange}
                                        />
                                        <Eye onClick={togglePassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </Password>
                                <ReEnterPassword>
                                    <Label htmlFor="rePasswrod">
                                        <FormattedMessage id="OurNaturePage.Profile.RePasswordLabel" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={rePasswordShown ? "text" : "password"}
                                            placeholder="********"
                                            name="rePassword"
                                            onChange={handlePassChange}
                                        />
                                        <Eye onClick={toggleRePassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </ReEnterPassword>
                                <ConfirmPassword>
                                    <Label htmlFor="confirmPassword">
                                        <FormattedMessage id="MyNaturePage.Profile.ConfirmPasswordLabel" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={confirmPasswordShown ? "text" : "password"}
                                            placeholder="*************"
                                            name="confirmPassword"
                                            onChange={handlePassChange}
                                        />
                                        <Eye onClick={toggleConfirmPassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </ConfirmPassword>
                            </Row>
                            {nonMatchingPassword && <ErrorMessage><FormattedMessage id="MyNaturePage.Error.ConfirmPassword" /></ErrorMessage>}
                            {errorPassword && <ErrorMessage><FormattedMessage id= "OurNaturePage.Error.Password"/></ErrorMessage>}
                            {invalidPassword && <ErrorMessage><FormattedMessage id="OurNaturePage.Error.InvalidPassword" /></ErrorMessage>}
                            <ButtonContainer>
                                {userPassword && 
                                userPassword.password.length>=8 && 
                                userPassword.rePassword.length>=8 && 
                                userPassword.confirmPassword.length>=8? 
                                <Button
                                    onClick={updatePassword}
                                    variant="filled"
                                    size="lg"
                                    >
                                        <FormattedMessage id="OurNaturePage.Profile.UpdatePassword"/>
                                </Button> : 
                                <Button
                                        onClick={handlePasswordError}
                                        variant="disabled"
                                        size="lg"
                                    >
                                        <FormattedMessage id="OurNaturePage.Profile.UpdatePassword"/>
                                </Button>}
                            </ButtonContainer>
                            {successPassword && (SuccessMessage)}
                        </PasswordFormContainer>
                    </PasswordContainer>
                </PasswordForm>}
                <Element>
                    <ElementRow>
                        <PaymentHistory>
                            <Title>
                                <FormattedMessage id="OurNaturePage.Profile.PaymentTitle" />
                            </Title>
                            <Para>
                                <FormattedMessage id="OurNaturePage.Profile.PaymentDesc" />
                            </Para>
                            <ElementButtonContainer1>
                                <Button
                                    path = "/account/payment-history"
                                    variant="filled"
                                    size="md"
                                >
                                    <FormattedMessage id="OurNaturePage.Profile.PaymentDetails" />
                                </Button>
                            </ElementButtonContainer1>
                        </PaymentHistory>
                        <IncreaseImpact>
                            <Title>
                                <FormattedMessage id="OurNaturePage.Profile.ImpactTitle" />
                            </Title>
                            <Para>
                                <FormattedMessage id="OurNaturePage.Profile.ImpactDesc" />
                            </Para>
                            <ElementButtonContainer2>
                                <Button
                                    variant="filled"
                                    size="md"
                                    path="/organizations"
                                >
                                    <FormattedMessage id="OurNaturePage.Profile.ImpactPlan" />
                                </Button>
                            </ElementButtonContainer2>
                        </IncreaseImpact>
                    </ElementRow>
                </Element>
                <Story>
                    <Title><FormattedMessage id="OurNaturePage.Profile.Passion" /></Title>
                    <SubTitle small>
                        <FormattedMessage id="OurNaturePage.Profile.PassionSubTitle" />
                    </SubTitle>
                    <Content>
                        <TextArea
                            value={userPassion}
                            onChange={handlePassionChange}
                        />
                        {errorPassion && <ErrorMessage><FormattedMessage id= "OurNaturePage.Error.DescribeMore"/></ErrorMessage>}
                        <ButtonContainer>
                            <Button
                                onClick={handlePassion}
                                variant="filled"
                                size="lg"
                            >
                                <FormattedMessage id="OurNaturePage.Profile.UpdateUserPassion" />
                            </Button>
                        </ButtonContainer>
                        {successPassion && (SuccessMessage)}
                    </Content>
                </Story>
                <Work>
                    <Title><FormattedMessage id="OurNaturePage.Profile.Work" /></Title>
                    <SubTitle small>
                        <FormattedMessage id="OurNaturePage.Profile.WorkSubTitle" />
                    </SubTitle>
                    <Content>
                        <Title light ><FormattedMessage id="OurNaturePage.Profile.Link" /></Title>
                        <TextArea small
                            name="link"
                            placeholder="https://"
                            value={userWork && userWork.link}
                            onChange={handleWorkChange}
                        />
                        {errorLink && <ErrorMessage><FormattedMessage id= "OurNaturePage.Error.Link"/></ErrorMessage>}
                        <TextArea
                            name="work"
                            placeholder="Description"
                            value={userWork && userWork.work}
                            onChange={handleWorkChange}
                        />
                        {errorWork && <ErrorMessage><FormattedMessage id= "OurNaturePage.Error.DescribeMore"/></ErrorMessage>}
                        <ButtonContainer>
                            <Button
                                onClick={handleWork}
                                variant="filled"
                                size="lg"
                            >
                                <FormattedMessage id="OurNaturePage.Profile.UpdateUserWork" />
                            </Button>
                        </ButtonContainer>
                        {successWork && (!errorWork||!errorLink) && (SuccessMessage)}
                    </Content>
                </Work>
                <Work>
                    <Title><FormattedMessage id="OurNaturePage.Profile.LandscapeImages.Title" /></Title>
                    <SubTitle small><FormattedMessage id="OurNaturePage.Profile.LandscapeImages.Subtitle" /></SubTitle>
                    {/* <FileList /> */}
                    <>
                        {
                            <>
                                {error && <ErrorDiv> *<FormattedMessage id= "OurNaturePage.Error.Image"/></ErrorDiv>}
                                {landscapeImage && landscapeImage.length > 0 &&
                                    <ImageContainer>
                                        {landscapeImage.map((item, i) => (
                                            <div className="imagebox">
                                                <CrossImage key={i} onClick={() => { deleteImage(item) }} />
                                                <Image src={item} key={i} />
                                            </div>
                                        ))
                                        }
                                    </ImageContainer>
                                }
                            </>
                        }
                        <FileImage>
                            <UploadContainerImage>
                                <DragAndDrop handleDrop={handleDrop}>
                                    <Wrapper>
                                        <ChooseFile />
                                        <h1><FormattedMessage id="OurNaturePage.Profile.DropFile" /> </h1>
                                    </Wrapper>
                                </DragAndDrop>
                            </UploadContainerImage>
                        </FileImage>
                    </>
                </Work>

                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </ProfileAndAccountMain>
            <RightNav />
        </Frame>
    )
}
);
const OurNatureProfileAndAccountPage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(NewOurNatureProfileAndAccountPage);


export default OurNatureProfileAndAccountPage;

