import styled from 'styled-components';

export const ProfileAndAccountMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3D6;
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;