import * as coreAPI from '../../coreApi';

export const fetchDescription = async (id) => {
    const resp = await coreAPI.getLandScapeDetails(id)
    return resp.data
}

export const fetchLandscapes = async () => {
    return new Promise(async (resolve, reject) => {
        const resp = await coreAPI.getAllLandscapePlans()
        resolve(resp.data)
    })
}