import styled from 'styled-components';

export const ProtectLandSection = styled.section`
    padding: 70px 4.8rem 100px;
    text-align: center;
    position: relative;
    .protect-land-box {
        max-width: 414px;
        padding: 0 10px;
        .title-box {
            margin-bottom: 14px;
        }
        .range-box {
            margin-bottom: 45px;
        }
        .base {
            font-weight: 300;
            line-height: 150%;
            color: #004d37;
            margin-bottom: 14px;
        }
        button {
            max-width: 360px;
        }
    }
    &::before {
        content: '';
        background: url(${({ GuardianLeafLeft }) => GuardianLeafLeft});
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: absolute;
        width: 129px;
        height: 373px;
        right: auto;
        left: 0;
        top: 0px;
        z-index: 2;
    }
    &::after {
        content: '';
        background: url(${({ GuardianLeafRight }) => GuardianLeafRight});
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: absolute;
        width: 121px;
        height: 340px;
        right: 0;
        top: -70px;
        z-index: 2;
    }
    .take-action-group {
        max-width: 1200px;
        width: 100%;
        flex: 1;
        gap: 50px 0;

        .title-box {
            max-width: 630px;
            margin: 0 auto;
        }
        .icon-text-container {
            align-self: stretch;
            gap: 20px;
            .iconFrame-box {
                &:after {
                    content: none;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 20px 24px;
        &::before,
        &::after {
            content: none;
        }
    }
`;
