import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import FeatureBlogCard from '../../newComponents/FeatureBlogCard';
import SelectSearch from '../../newComponents/SelectSearch';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import { withRouter } from 'react-router-dom';
import { Page } from '../../components';
import FeaturedBlog from '../../newComponents/FeaturedBlog';
import styles from './styles.module.css';
import { getLatestBlogs, getAllCategories, getBlogsByCategory, handleQueryLanguage } from './utils';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import BlogCategory from '../../newComponents/BlogCategory';
import { useState, useEffect } from 'react';
import { IconSpinner } from '../../components';
import { v4 as uuidv4 } from 'uuid';
import BlogNewsletterForm from '../../newComponents/BlogNewsletterForm';
import BlogContributeOptions from '../../newComponents/BlogContributeOptions';
import NewFooter from '../../newComponents/NewFooter';

const BlogsPage = props => {
    const [latestBlogs, setLatestBlogs] = useState('');
    const [categories, setCategories] = useState('');
    const [categoriesBlogs, setCategoriesBlogs] = useState('');

    let CarouselListings = useRef(null);
    const allLatestBlogsForCarousel =
        latestBlogs &&
        latestBlogs.map(element => (
            <FeatureBlogCard
                slug={element.slug}
                imageUrl={element.blog_details.featuredImage}
                title={element.title}
                summary={element.blog_details.summary}
                writer={element.writer.name}
                publishDate={element.blog_details.publishDate}
                readTime={element.blog_details.readTime}
                tags={element.taxonomy.tags}
            />
        ));

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            const categoriesResponse = await getAllCategories(queryLang);
            if (categoriesResponse.status === 'success') {
                const emptyArray = [];
                categoriesResponse.data.map(() => emptyArray.push(''));
                setCategoriesBlogs(emptyArray);
                setCategories(categoriesResponse.data);
            }
            const response = await getLatestBlogs(queryLang);
            if (response.status === 'success') {
                setLatestBlogs(response.data);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            if (categories && categoriesBlogs !== '') {
                for (let i = 0; i < categories.length; i++) {
                    const response = await getBlogsByCategory(queryLang, categories[i].name);
                    if (response.status === 'success') {
                        categoriesBlogs[i] = response.data;
                        setCategoriesBlogs([...categoriesBlogs]);
                    }
                }
            }
        })();
    }, [categories]);

    return (
        <>
            <div className={styles.blogsPage}>
                <Page>
                    <Header
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />

                    <SelectSearch categories={categories} />

                    {latestBlogs ? (
                        <>
                            <FeaturedBlog
                                imageUrl={latestBlogs[0].blog_details.featuredImage}
                                title={latestBlogs[0].title}
                                summary={latestBlogs[0].blog_details.summary}
                                writer={latestBlogs[0].writer.name}
                                publishDate={latestBlogs[0].blog_details.publishDate}
                                readTime={latestBlogs[0].blog_details.readTime}
                                tags={latestBlogs[0].taxonomy.tags}
                                slug={latestBlogs[0].slug}
                            />
                            <div className={styles.featureBlogsBottom}>
                                <FeatureBlogCard
                                    imageUrl={latestBlogs[1].blog_details.featuredImage}
                                    title={latestBlogs[1].title}
                                    summary={latestBlogs[1].blog_details.summary}
                                    writer={latestBlogs[1].writer.name}
                                    publishDate={latestBlogs[1].blog_details.publishDate}
                                    readTime={latestBlogs[1].blog_details.readTime}
                                    tags={latestBlogs[1].taxonomy.tags}
                                    slug={latestBlogs[1].slug}
                                />
                                <FeatureBlogCard
                                    imageUrl={latestBlogs[2].blog_details.featuredImage}
                                    title={latestBlogs[2].title}
                                    summary={latestBlogs[2].blog_details.summary}
                                    writer={latestBlogs[2].writer.name}
                                    publishDate={latestBlogs[2].blog_details.publishDate}
                                    readTime={latestBlogs[2].blog_details.readTime}
                                    tags={latestBlogs[2].taxonomy.tags}
                                    slug={latestBlogs[2].slug}
                                />
                            </div>
                            <div className={styles.planCardsMobile}>
                                <AliceCarousel
                                    buttonsDisabled={true}
                                    autoPlayInterval={2000}
                                    dotsDisabled={true}
                                    infinite={false}
                                    disableAutoPlayOnAction={true}
                                    autoPlay={false}
                                    responsive={{
                                        0: { items: 1, itemsFit: 'contain' },
                                        805: { items: 1, itemsFit: 'contain' },
                                    }}
                                    items={allLatestBlogsForCarousel}
                                    ref={el => (CarouselListings = el)}
                                />
                                <button
                                    className={styles.carouselBackButton}
                                    onClick={() => CarouselListings.slidePrev()}
                                >
                                    <FaChevronLeft className={styles.carouselChevron} />
                                </button>
                                <button
                                    className={styles.carouselNextButton}
                                    onClick={() => CarouselListings.slideNext()}
                                >
                                    <FaChevronRight className={styles.carouselChevron} />
                                </button>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <IconSpinner />
                        </div>
                    )}
                    {categories && categoriesBlogs
                        ? categoriesBlogs.map((element, i) =>
                              i === 2 ? (
                                  <>
                                      <BlogNewsletterForm />
                                      <BlogCategory
                                          key={uuidv4()}
                                          category={categories[i]}
                                          blogs={element}
                                      />
                                  </>
                              ) : (
                                  <BlogCategory
                                      key={uuidv4()}
                                      category={categories[i]}
                                      blogs={element}
                                  />
                              )
                          )
                        : null}
                </Page>
            </div>
            <BlogContributeOptions />
            <NewFooter />
        </>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(BlogsPage));
