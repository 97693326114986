import styled from 'styled-components';

export const MyIncomeTitle = styled.p`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-top: 40px;
    margin-left: 85px;
    @media screen and (max-width: 1024px) {
        margin-left: 55px;
    }
`;

export const MyIncomeMain = styled.div`
    display: flex;
    font-style: normal;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 55px 35px 0px 35px;
    padding: 50px 70px;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 10px;
    @media screen and (max-width: 1024px) {
        margin: 20px 25px 0px 25px;
        padding: 0px 30px;
        flex-direction: column;
    }
`;

export const MyIncomeMainItem = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    justify-content: space-between;
    color: #000000;
    font-weight: 700;
    @media screen and (max-width: 1024px) {
        padding:40px 0px;
    }
`;

export const Line = styled.div`
    content: "";
    border: 1px solid rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 1pxpx;
    }
`;

export const MyIncomeItemValue = styled.div`
    font-size: 79px;
    line-height: 107px;
    opacity: ${props => props.opacity};
    @media screen and (max-width: 1024px) {
        font-size: 70px;
        line-height: 95px;
    }
`;

export const MyIncomeItemTitle = styled.div`
    font-size: 23px;
    line-height: 31px;
    opacity: ${props => props.opacity};
    @media screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const IncomeDetailsTitle = styled.p`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-top: 50px;
    margin-left: 75px;
    @media screen and (max-width: 1024px) {
        margin-left: 70px;
    }
`;

export const IncomeDetailsMain = styled.p`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 38px 35px;
    padding: 0px;
    font-weight: 500;
    @media screen and (max-width: 1024px) {
        margin: 38px 25px;
    }
`;

export const IncomeDetailsItem = styled.p`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:space-between;
    width: 100%;
    padding: 20px 42px;
    margin: 0px;
    font-weight: 500;
    color: ${props => props.color};
    background-color: ${props => props.bkgColor};
`;

export const IncomeDetailsItemData = styled.p`
    width:50%;
    font-size: 17px;
    line-height: 183%;
    font-family: 'Galano Grotesque';
    @media screen and (max-width: 1024px) {
        font-size: 15px;
        line-height: 98%;
        width:auto;
    }
`;