import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import CustomToolTip from '../../../newComponents/CustomToolTip/CustomToolTip';
import peruMap from '../../../assets/newAssets/peru-map.svg';
import locationPin from '../../../assets/newAssets/icons/location-pin.svg';

const MapLinks = () => {
    return (
        <>
            <img src={peruMap} alt="peru map" />

            <Link to="/landscapes/requena" className="top-one-location">
                <CustomToolTip label="Requena">
                    <img src={locationPin} alt="Requena" />
                </CustomToolTip>
            </Link>
            <Link to="/landscapes/canete" className="center-two-location">
                <CustomToolTip label="Cañete">
                    <img src={locationPin} alt="Cañete" />
                </CustomToolTip>
            </Link>
            <Link to="/landscapes/abancay" className="bottom-three-location">
                <CustomToolTip label="Abancay">
                    <img src={locationPin} alt="Abancay" />
                </CustomToolTip>
            </Link>

            <Link to="/landscapes/machu-picchu" className="bottom-four-location">
                <CustomToolTip label="Machu Picchu">
                    <img src={locationPin} alt="Machu Picchu" />
                </CustomToolTip>
            </Link>
            <Link to="/landscapes/manu" className="bottom-five-location">
                <CustomToolTip label="Manu">
                    <img src={locationPin} alt="Manu" />
                </CustomToolTip>
            </Link>
            <Link to="/landscapes/paucartambo" className="bottom-six-location">
                <CustomToolTip label="Paucartambo">
                    <img src={locationPin} alt="Paucartambo" />
                </CustomToolTip>
            </Link>
        </>
    );
};
export default MapLinks;
