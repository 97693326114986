import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import { IntlContext } from '../../../../../IntlContext';

const StyledButton = styled.button`
    background: none;
    border: none;
    color: white;
    cursor: pointer;
`;

const LangSubmenu = () => {
    const { languages, selectedLanguage } = useSelector(storeState => storeState.Languages);
    const dispatch = useDispatch();

    const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(IntlContext);

    useEffect(() => {
        //call switch langugae here
        if (selectedLanguage.short === 'EN') {
            switchToEnglish();
        } else if (selectedLanguage.short === 'ES') {
            switchToSpanish();
        } else {
            switchToPortuguese();
        }
    }, [selectedLanguage]);
    return (
        <div className="submenu">
            <ul>
                {languages.map((item, index) => (
                    <li>
                        <StyledButton
                            onClick={e => {
                                e.preventDefault(); // Prevent default form submission behavior
                                dispatch(changeLanguage(item.short));
                            }}
                        >
                            {item.name}
                        </StyledButton>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LangSubmenu;
