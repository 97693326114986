import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../theme'
// import styled from 'styled-components';
// import { colors } from '../../../../theme';
import { typography } from 'styled-system';
import { NavLink } from 'react-router-dom';

export const StyledLoginMenuContainer = styled.div`
  display: flex;
  & > div {
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }

  & > div > button {
    padding: 0px;
  }
`;

export const StyledUsername = styled.span`
  ${typography}
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.green};
  border-radius: 50%;
  color: ${colors.white};
  font-family: 'Galano Grotesque';

  & > :nth-child(1) {
    cursor: pointer;
  }
`;

export const StyledDesktopListItem = styled.li`
  @media screen and (max-width: 890px) {
    display: none;
  }
`;
export const StyledManageUser = styled.div`
  font-family: Galano Grotesque;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 49px;
  display:flex;
  flex-direction:column;
  color:white;
  padding-left:26px;
  padding-top:50px;
  & > :nth-Child(1){
    color:white;
  }
  & > :nth-Child(2){
    color:white;
    padding-left:20px;
  }
  & > :nth-Child(3){
    
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    text-align:initial;
  }
`;
export const StyledNavLink = styled(NavLink)`
  ${typography}
  font-family: 'Galano Grotesque';
  font-style: normal;
  text-decoration: none;
  font-size: 18px;
  line-height: 49px;
  font-weight: 500;
  color: ${colors.gray};
  ${({ transparent }) =>
    transparent && `
    color: ${colors.white}; `}

  @media screen and (max-width: 890px) {
    color: ${colors.white};
    ${({ active }) => active && `color: ${colors.green}; `}
  }
  ${({ active }) => active && `color: ${colors.green}; `}
  &:hover {
    color: ${colors.green};
    text-decoration: none;
  }
  ${({ type }) =>
    type === 'primary' &&
    `
      margin: 0px;
    `}
`;

export const StyledList = styled.ul`
  list-style: none;
  padding-left: 26px;
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  line-height: 32px;
  padding: 0px 30px;
  z-index: 10;

  ${({transparent}) => transparent && `
    @media screen and (min-width: 769px) {
      position: absolute;
      width: 100%;
    }
  `}
  @media screen and (max-width: 890px) {
    padding: 30px;
    padding-top: 36px;
  }
`;

export const StyledDesktopLogoContainer = styled.div`
  align-self: center;
  width: 200px;
  @media screen and (max-width: 890px) {
    display: none;
  }
`;

export const StyledMobileLogoContainer = styled.div`
  align-self: center;
  width: 185px;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const StyledMobileMenuContainer = styled.div`
  @media screen and (min-width: 890px) {
    display: none;
  }
`;

export const StyledPopper = styled.div`
  position: absolute;
  z-index: 999;
  right: 1rem;
  top: 1rem;
  width: 100%;
  max-width: 345px;

  background-color: #000000;
`;

export const StyledMenuMobile = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const StyledMenuDesktop = styled.div`
  @media screen and (max-width: 890px) {
    display: none;
  }
  display: grid;
  grid-template-rows: auto auto;
  padding-bottom: 36px;
  padding-top: 10px;

  & > :nth-child(1) {
    display: flex;
    gap: 24px;

    margin-left: auto;
  }

  & > :nth-child(2) {
    display: flex;
    gap: 24px;

    margin: 0px;
  }
`;

export const MenuHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 26px;

  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  padding-left: 26px;
  padding-top: 40px;

  & > :nth-child(1) {
    font-family: 'Galano Grotesque';
    font-style: normal;
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const StyledButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  @media screen and (max-width: 350px) {
    width:50px;
  }
`;

export const StyledLanguagesList = styled.ul`
  margin-bottom: 50px;
  margin-top: 100px;
  display: flex;
  list-style: none;
  padding-left: 26px;
  color: ${colors.white};
`;

export const StyledListItem = styled.li`
  & > button {
    font-weight: 400;
    padding: 0px 6px 0px 6px;
    ${({ active }) =>
      active &&
      `
       font-weight: 700; 
      `}
  }
`;

export const StyledDropdownBox = styled.ul`
  position: absolute;
  background: #f9f9f9;
  padding: 14px 15px 30px 15px;
  z-index: 10;
  right: 0px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
`;

export const StyledDropdown = styled.div`
  position: relative;
`;

export const StyledOption = styled.li`
  cursor: pointer;
  color: ${colors.gray};
  display: flex;
  align-items: center;
  &:hover {
    color: ${colors.green};
  }
  margin: 5px 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  ${({ selected , transparent}) =>
    selected && transparent ? `
    color: ${colors.white};
      margin: 0px;
    &:hover {
      color: ${colors.white};
    }
    `
    : selected && 
    `color: ${colors.black};
      margin: 0px;
    &:hover {
      color: ${colors.black};
    }
    `}
`;

export const StyledNavItem = styled(NavLink)`
  font-family: 'Galano Grotesque';
  text-decoration: none;
  list-styles: none;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 700;

  &:hover {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const MenuContainer = styled.div`
  background: white;
  border-radius: 5px;
  position: absolute;
  margin-top: 10px;
  right: 0px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: 10;
  width: 240px;
`;

export const StyledMenuItem = styled.li`
  height: 100%;
  width: 100%;
  margin: 10px 0px;
`;

export const StyledLogoutButton = styled.button`
  border: none;
  width: 100%;
  text-align: left;
  padding: 20px;
  border-top: 1px solid ${colors.lightGray};
  color: ${colors.black};
  font-family: 'Galano Grotesque';
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: ${colors.green};
  }
`;

export const StyledLoginButton = styled.button`
  font-weight: 500;
  font-size: 18px;
  font-family: 'Galano Grotesque';
  cursor: pointer;
  border: none;
  color: ${colors.gray};
`;


export const OrgHeader=styled.div`
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px 20px;
    overflow-x: hidden; 
    display:flex;
    justify-content:space-between;
    @media screen and (max-width: 890px){
      align-items:center;
      justify-content:space-between;
      margin:37px auto;
      flex-wrap:wrap;
      
    }
`;

export const StyledLogoContainer=styled.div`
    height:82px;
    margin-top:37px;
    margin-left:45px;
    display:inline-block;
    width:206px;
    @media screen and (max-width: 890px){
      margin:0px 0px;
      padding-right:20px;
    }
    @media screen and (max-width: 350px){
      width:150px
    }
`;

export const StyledSmallCityContainer=styled.div`
    display:none;
    @media screen and (max-width: 890px){
      display:inline-block;
      width:100%;
      height:100%;
      margin:0px 30px;
      margin-top:50px;
  }
`;
export const StyledCityContainer=styled.div`
    height:331px;
    width:743px;
    @media screen and (max-width:890px ){
      display:none;
  }
`;
export const ManageUser=styled.div`
    height:111px;
    width:287px;
    margin-top:39px;
    margin-right:16px;
    border:1px solid #E4E4E4;
    border-radius: 5px;
    @media screen and (max-width:890px ){
      display:none;
  }
`;
export const ListContainer=styled.div`
    height:56px;
    width:100%;
    display:flex;
    justify-content: space-between;
    flex-Wrap:wrap;
    padding:20px;
    border-bottom:1px solid #F2F2F2;
    box-sizing:border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
`;

export const ListButton=styled.div`
    height:88px;
    width:100%;
    display:flex;
    justify-content:space-betweeen;
    flex-Wrap:wrap;
    padding:18px;
    box-sizing:border-box;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    text-align: center;
    color: #FFFFFF;

    & > Button{
        padding:5px;
        background-color:#F29509;
    }
`;

export const LogoGroup=styled.span`
    display:flex;
    align-item:center;
    & > a{
        margin: 0px 10px 
    }
`;
