import React from 'react';
import styles from './styles.module.css';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useState } from 'react';
import { array, oneOfType, string, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';

const propTypes = {
    categories: oneOfType([string, array]),
    history: object,
    defaultSearchQuery: string,
    categoryValue: string,
};

const SelectSearch = ({ categories, history, defaultSearchQuery, categoryValue }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isDropDownVisible, setDropDownVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClickSelect = e => {
        if (isDropDownVisible) {
            const id = e.target.dataset.categoryid;
            const category = categories.find(element => element.categoryId == id);
            setSelectedCategory(category);
            closeDropDown();
        }
    };

    const closeDropDown = () => {
        setDropDownVisible(false);
    };

    const toggleDropDown = () => {
        setDropDownVisible(value => !value);
    };

    const handleChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleClickSearch = () => {
        if (searchQuery.length > 0) {
            history.push(`/blog/s?q=${searchQuery}&category=${selectedCategory.name || 'all'}`);
            window.location.reload(true);
        }
    };

    useEffect(() => {
        setSearchQuery(defaultSearchQuery);
        categories &&
            categories.map(element => {
                if (element.name === categoryValue) {
                    setSelectedCategory(element);
                }
            });
    }, [categories]);

    const handleClickBlogs = () => {
        history.push('/blog');
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.blogsHeading}>
                <span onClick={handleClickBlogs}>Blog</span>
            </div>
            <div className={styles.searchBox}>
                <div className={styles.dropdown}>
                    <div onClick={toggleDropDown} className={styles.defaultOption}>
                        <span>{selectedCategory ? selectedCategory.name : 'Category'}</span>{' '}
                        <MdKeyboardArrowUp
                            className={`${styles.mdKeyArrow} ${
                                isDropDownVisible ? '' : styles.mdKeyArrowRotate
                            }`}
                            fill="#797979"
                            size={25}
                        />
                    </div>
                    <ul
                        className={`${styles.options} ${
                            isDropDownVisible ? styles.open : styles.close
                        }`}
                    >
                        {categories
                            ? categories.map(element => (
                                  <li
                                      key={element.categoryId}
                                      data-categoryid={element.categoryId}
                                      onClick={handleClickSelect}
                                  >
                                      {element.name}
                                  </li>
                              ))
                            : null}
                    </ul>
                </div>
                <div className={styles.searchField}>
                    <input
                        value={searchQuery}
                        onChange={handleChange}
                        type="text"
                        className="input"
                        placeholder="Key words"
                    />
                </div>
                <button onClick={handleClickSearch}>Search</button>
            </div>
        </div>
    );
};
SelectSearch.propTypes = propTypes;
SelectSearch.defaultProps = {
    defaultSearchQuery: '',
    categoryValue: '',
};
export default withRouter(SelectSearch);
