import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ExternalLink } from '../../components';

import { LightGreenBtn } from '../Button/ButtonSkin';
import AccordionSection from '../Accordion';

import userIcon from '../../assets/newAssets/icons/user-icon.svg';
import { RiSearchLine } from 'react-icons/ri';
import { SiWhatsapp } from 'react-icons/si';

import { FaqSection, SearchBox } from './Styles';

const questionData = [
    {
        id: 1,
        question: <FormattedMessage id="KnowledgeBase.question.1" />,
        answer: (
            <FormattedHTMLMessage
                id="KnowledgeBase.answer.1"
                values={{
                    p: chunks => <p>{chunks}</p>,
                }}
            />
        ),
    },
    {
        id: 2,
        question: <FormattedMessage id="KnowledgeBase.question.2" />,
        answer: (
            <FormattedHTMLMessage
                id="KnowledgeBase.answer.2"
                values={{
                    p: chunks => <p>{chunks}</p>,
                }}
            />
        ),
    },
    {
        id: 3,
        question: <FormattedMessage id="KnowledgeBase.question.3" />,
        answer: (
            <FormattedHTMLMessage
                id="KnowledgeBase.answer.3"
                values={{
                    p: chunks => <p>{chunks}</p>,
                }}
            />
        ),
    },
    {
        id: 4,
        question: <FormattedMessage id="KnowledgeBase.question.4" />,
        answer: (
            <FormattedHTMLMessage
                id="KnowledgeBase.answer.4"
                values={{
                    p: chunks => <p>{chunks}</p>,
                }}
            />
        ),
    },
    {
        id: 5,
        question: <FormattedMessage id="KnowledgeBase.question.5" />,
        answer: (
            <FormattedHTMLMessage
                id="KnowledgeBase.answer.5"
                values={{
                    p: chunks => <p>{chunks}</p>,
                }}
            />
        ),
    },
];

const Faqs = () => {
    return (
        <FaqSection>
            <div className="faq-section mx-auto">
                <div className="title-box text-center">
                    <div className="small-title uppercase">
                        <FormattedMessage id="KnowledgeBase.questions" />
                    </div>
                    <h3>
                        <FormattedMessage id="KnowledgeBase.questions.wondering" />
                    </h3>
                </div>
                {/* <SearchBox className="mx-auto w-full flex relative">
                    <input
                        type="text"
                        className="form-control w-full"
                        placeholder="Search..."
                        aria-label="blog search"
                        aria-describedby="blog search"
                    />
                    <LightGreenBtn className="search-btn absolute" type="submit">
                        Search
                    </LightGreenBtn>
                </SearchBox> */}

                <div className="faq-accordion">
                    {questionData.map(curEle => {
                        const { id } = curEle;
                        return <AccordionSection key={id} {...curEle} />;
                    })}
                </div>
                <div className="other-info">
                    <h4 className="small-title">
                        <FormattedMessage id="KnowledgeBase.otherInfo" />
                    </h4>
                    <ul className="other-option-list">
                        {/* <li>
                            <Link to="#" className="green-link flex items-center">
                                <RiSearchLine className="link-icon" />{' '}
                                <small>Go to our knowledge centre for all questions</small>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="green-link flex items-center">
                                <SiWhatsapp className="link-icon" />{' '}
                                <small>Chat to a Guardian</small>
                            </Link>
                        </li> */}
                        <li>
                            <ExternalLink
                                href="https://calendly.com/frankhajek/30min"
                                className="green-link flex items-center"
                            >
                                <img src={userIcon} alt="user icon" />{' '}
                                <small>
                                    <FormattedMessage id="KnowledgeBase.videoCall" />
                                </small>
                            </ExternalLink>
                        </li>
                    </ul>
                </div>
            </div>
        </FaqSection>
    );
};

export default Faqs;
