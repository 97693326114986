import React, { Component } from 'react';
import {injectIntl} from 'react-intl';
import SpanishFaq from './Faq.es';
import EnglishFaq from './Faq.en';
import PortugueseFaq from './Faq.pt'



class FaqsPage extends Component{

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  render(){
    const locale = (this.props.intl && this.props.intl.locale) || 'es'
    if(locale == 'en'){
      return <EnglishFaq/>
    }else if(locale == 'pt') {
      return <PortugueseFaq/>
    }
    else {
      return <SpanishFaq/>
    }
  }
};

export default injectIntl(FaqsPage);
