import React, { Component } from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage } from '../../containers';
import { types } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import { createInstance } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as coreAPI from '../../coreApi';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    NamedLink,
    Hero,
    ExternalLink,
    UpButton,
    ListingCard,
} from '../../components';

import css from './Paisajes.css';
import heroimage from '../../assets/paisajes.jpg';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class AboutPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            landscapes: [],
        };
    }

    componentDidMount() {
        coreAPI
            .allLandscape()
            .then(response => {
                this.setState({ landscapes: response.data });
            })
            .catch(error => {
                console.log(error);
            });

        // sdk.listings.query({include:['images'],'fields.image': ['variants.landscape-crop2x'],'limit.images': 1,}).then(response=>{
        //   this.setState({listing: response.data.data.filter(item => item.attributes.publicData.category !== 'story'),images:response.data.included})
        // })
    }
    render() {
        const { intl } = this.props;
        return (
            <StaticPage
                title={intl.formatMessage({ id: 'Paisajes.PageTitle' })}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'AboutPage',
                    description:
                        'Gracias a nuestros miembros, estamos constantemente invirtiendo en la recuperación y protección de la naturaleza.',
                    name: 'Paisajes',
                }}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={this.props.location.pathname}
                            user={this.props.user}
                            history={this.props.history}
                        />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain>
                        <Hero
                            title={intl.formatMessage({ id: 'Paisajes.SectionTitle' })}
                            subtitle={intl.formatMessage({ id: 'Paisajes.SectionSubTitle' })}
                            image={heroimage}
                        />
                        <section className={css.sectionContent}>
                            <div className={css.paisajes}>
                                {this.state.landscapes.map((item, index) => {
                                    const slug = createSlug(item.titleSlug);
                                    console.log('ssslug', item.titleSlug);
                                    return (
                                        <NamedLink
                                            className={css.picContainer}
                                            name="ListingPage"
                                            params={{ id: item.id, slug }}
                                        >
                                            <img className={css.pic} src={item.image} />
                                            <div className={css.textwrapper}>
                                                <p className={css.pictext}>{item.name}</p>
                                                <div className={css.linkButton} aria-hidden="true">
                                                    <FormattedMessage id="Paisajes.listingLink" />
                                                </div>
                                            </div>
                                        </NamedLink>
                                    );
                                })}
                            </div>
                        </section>
                        <UpButton />
                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer history={this.props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </StaticPage>
        );
    }
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(injectIntl(AboutPage)));
