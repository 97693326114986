import React, { useState } from 'react';
import styles from './style.module.css';
import CollectiveGuardian from '../../assets/guardians/collectiveGuardian.png';
import Button from '../Button';
import { FaCheck } from 'react-icons/fa';
import { string, func, bool, any } from 'prop-types';
import GreenCheck from '../../assets/guardians/greenCheck.png';

const propTypes = {
    /** id of the card*/
    id: string,
    /** selected card id */
    selectedCardId: string,
    /** function to handle selected card */
    handleClickSelect: func,
    /** role title */
    title: string,
    /** role description */
    description: string,
    /** submitted status */
    submitted: bool,
    handleClickSelectClick: func,
    isSubmitting: bool,
    img: any,
};

const ChooseGuardianRole = ({
    id,
    selectedCardId,
    handleClickSelect,
    title,
    description,
    submitted,
    handleClickSelectClick,
    selectClickedCardId,
    isSubmitting,
    img,
}) => {
    const isSelected = id === selectedCardId;
    const isSelectClicked = id === selectClickedCardId;

    return (
        <div
            className={`${styles.chooseGuardianRoleCard} ${isSelected &&
                styles.chooseGuardianRoleCardSubmitted}`}
        >
            <img src={img} alt="guardian" />
            <h1 className={styles.guardianRole}>{title}</h1>
            <p className={styles.guardianRoleDesc}>{description}</p>
            {!isSelected ? (
                isSelectClicked ? (
                    <div className={styles.expressInterestButton}>
                        <Button disabled={submitted} variant={submitted ? 'disabled' : 'filled'}>
                            <FaCheck className={styles.checkIcon} />
                            Selected
                        </Button>
                        <Button
                            disabled={submitted || isSubmitting}
                            onClick={handleClickSelect(id)}
                            size="md"
                            variant={submitted || isSubmitting ? 'disabled' : 'invertedGhost'}
                        >
                            Confirm
                        </Button>
                    </div>
                ) : (
                    <div className={styles.expressInterestButton}>
                        <Button
                            disabled={submitted}
                            onClick={handleClickSelectClick(id)}
                            variant={submitted ? 'disabled' : 'filled'}
                            size={'lg'}
                        >
                            Express interest in role
                        </Button>
                    </div>
                )
            ) : (
                <>
                    <div className={styles.expressInterestButtonSubmitted}>
                        <Button
                            disabled={submitted}
                            size={'lg'}
                            variant={submitted ? 'disabled' : 'filled'}
                        >
                            <FaCheck className={styles.checkIcon} />
                            Selected
                        </Button>
                    </div>
                    <div className={styles.checkedIcon}>
                        <img src={GreenCheck} alt="Check" />
                    </div>
                </>
            )}
        </div>
    );
};
ChooseGuardianRole.propTypes = propTypes;
export default ChooseGuardianRole;
