import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

const PaymentLineChart = (props) => {
  const reference = useRef();

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
  const labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: props.year,
        data: props.data,
        borderColor: "rgb(123, 181, 72, 0.5)",
        // backgroundColor: "rgb(123, 181, 72, 0.5)",
        // fill: "start",
        yAxisID: 'y',
      }
    ]
  };


  const config = {
    type: "line",
    data: data,
    options: {
      layout: {
        padding: {
          top: 5,
        }
      },
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: "",
          position: "bottom"
        }
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          title: { 
            text: "Cummulative Payments (in USD)", 
            display: true ,
            font: function(context) {
              var width = context.chart.width;
              var size = Math.round(width / 45);
               return {
                 size: size,
                weight: 700
              };
            },
          },
          grid: {
            display: false,
          }
        },
        x: {
          grid: {
            display: false,
          }
        }
      }
    }
  };
  useEffect(() => {
    const ctx = reference.current;
    new Chart(ctx, {
      ...config,
    });
  }, []);
  return (
    <div className="line-chart">
      <canvas ref={reference} width="400" height="275" ></canvas>
    </div>
  );
}

export default PaymentLineChart;