import styled from 'styled-components';

export const AreaTitle = styled.div`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-left: 70px;
    margin-top: 70px;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 24px;
        margin-left: 35px;
        margin-top: 35px;
    }
`;

export const NoData = styled.div`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-left: 70px;
    margin-top: 70px;
    height: 500px;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 24px;
        margin-left: 35px;
        margin-top: 35px;
    }
`;

export const AreaDetails = styled.div`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 22px;
    line-height: 183%;
    color: #000000;
    margin-left: 70px;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 24px;
        margin-left: 35px;
    }
`;

export const Heading = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-left: 85px;
    margin-top: 38px;
    @media screen and (max-width: 1024px) {
        margin-left: 35px;
        margin-top: 55px;
    }
`;