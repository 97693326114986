import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { getGuardianStripeInfo, getGuardianStripePayoutsList } from "../../../../../../coreApi";
import { getNextMonth, getTotalPayout } from '../utils';
import { 
    MyIncomeTitle,
    MyIncomeMain,
    MyIncomeMainItem,
    MyIncomeItemValue,
    MyIncomeItemTitle,
    Line
} from '../Styles';

const MyIncome = () => {

    const [nextPayout, setNextPayout] = useState('');
    const [payoutList, setPayoutList] = useState('');

    useEffect(() => {
        (async () => {
            const response = await getGuardianStripeInfo()
            const nextPayoutData = response.data.response.available;
            if (nextPayoutData) {
                setNextPayout(nextPayoutData);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getGuardianStripePayoutsList()
            const payoutData = response.data.response.data;
            if (payoutData) {
                setPayoutList(payoutData);
            }
        })();
    }, []);

    const nextPayoutMonth = 
        payoutList &&
        getNextMonth(payoutList[0].arrival_date)

    const data = 
        nextPayout &&
        nextPayout.map(pay => (
            <MyIncomeItemValue opacity={1}>
                {pay.currency == "usd" ? "$" : "S/."} {pay.amount/100}
            </MyIncomeItemValue>
        ));
    
    const totalPayout = 
        payoutList &&
        getTotalPayout(payoutList)

    return (
        <>
            <MyIncomeTitle><FormattedMessage id="NewGuardiansPage.Income.title" /></MyIncomeTitle>
            <MyIncomeMain>
                <MyIncomeMainItem>
                    <MyIncomeItemValue opacity={0.4}>
                        {totalPayout}
                    </MyIncomeItemValue>
                    <MyIncomeItemTitle opacity={0.4}>
                        <FormattedMessage id="NewGuardiansPage.Income.totalIncomeText" />
                    </MyIncomeItemTitle>
                </MyIncomeMainItem>
                <Line />
                <MyIncomeMainItem>
                    {data}
                    <MyIncomeItemTitle opacity={1}>
                        <FormattedMessage id="NewGuardiansPage.Income.payoutText" />{nextPayoutMonth}
                    </MyIncomeItemTitle>
                </MyIncomeMainItem>
            </MyIncomeMain>
        </>
    );
}

export default MyIncome;