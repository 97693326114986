import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import eileen from '../../assets/Eileen-Wong.jpg';
import heroimage from '../../assets/heroempresas.jpg';
import kurt from '../../assets/Kurt-Holle2.jpg';

import renzo from '../../assets/renzo.jpg';
import PlansComparison from './ChoosePlans/PlansComparison';
import ImageDescription from '../../newComponents/ImageDescription/ImageDescription';
import infographic1 from '../../assets/Partners/infographic1.png';
import infographic2 from '../../assets/Partners/infographic2.png';
import infographic3 from '../../assets/Partners/infographic3.png';
import infographic4 from '../../assets/Partners/infographic4.png';
import Button from '../../newComponents/Button';
import {
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    Modal,
    NamedLink,
    UpButton,
} from '../../components';
import Hero from '../../newComponents/Hero/Hero';
import { StaticPage } from '../../containers';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import {
    Testimony,
    SectionContent,
    TestimonialsTitle,
    TestimonyPic,
    TestimonyDescription,
    TestimonyPeople,
    TestimonyPeopleName,
    TestimonyCard,
    Heading,
    Title,
    Guide,
    SignupButton,
    Plan,
    PlanText,
    Personal,
    GetStarted,
    GetStartedContainer,
    GetStartedHeading,
    ReasonContainer,
    Icon,
    Reason,
    ContactUs,
    ContactUsText,
    Separator,
    StyledContainerDesktop,
    StyledContainerMobile,
    TestimonyListDesktop,
    TestimonyListMobile,
    ModalText,
} from './Style';
import css from '../Personas/Personas.css';
import Carousel from 'react-grid-carousel';
import Check from '../../assets/newAssets/check.png';
import AccountSetup from './sections/AccountSetup';
import Payment from './sections/Payment';
import PaymentSuccess from './sections/PaymentSuccess';
import { Elements, StripeProvider } from 'react-stripe-elements';
import * as coreAPI from '../../coreApi';
import Video from '../../newComponents/Video';
import ModalComponent from './../../newComponents/Modal/modal';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import Community from '../components/Community';

const orgVideosId = [
    { name: 'HOSEG ', id: '301856766' },
    { name: 'TEDxTukuy ', id: '294922118' },
    { name: 'Explorandes  ', id: '280582005' },
    { name: 'Flora & Fauna ', id: '280660735' },
    { name: 'Amazonas', id: '278549592' },
    { name: 'Andean Lodges', id: '278020970' },
    { name: 'Auquí', id: '276474362' },
    { name: 'GBC', id: '302335654' },
];

const guide = [
    {
        img: infographic1,
        description: <FormattedMessage id="NewPartnersPage.Guide.Role" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Role.Title" />,
        inverse: false,
    },
    {
        img: infographic2,
        description: <FormattedMessage id="NewPartnersPage.Guide.Plan" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Plan.Title" />,
        inverse: true,
    },
    {
        img: infographic3,
        description: <FormattedMessage id="NewPartnersPage.Guide.Footprint" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Footprint.Title" />,
        inverse: false,
    },
    {
        img: infographic4,
        description: <FormattedMessage id="NewPartnersPage.Guide.Spread" />,
        title: <FormattedMessage id="NewPartnersPage.Guide.Spread.Title" />,
        inverse: true,
    },
];
function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const BusinessPage = props => {
    const [state, setState] = useState({
        isOpen: false,
        randomVideo: randomIntFromInterval(0, orgVideosId.length - 1),
    });
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
    const [isOpen, setisOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [planID, setPlanID] = useState(null);
    const [selectedLandScape, setSelectedLandScape] = useState({});
    const [stripe, setStripe] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [planDetails, setPlanDetails] = useState({
        planType: 'empresa',
        selectedPlan: {},
        isPlanSelected: false,
        paymentStatus: false,
    });
    const [cartPresent, setCartPresent] = useState(false);
    const planRef = useRef(null);
    const paymentSuccessRef = useRef();
    const paymentRef = useRef();
    const accountSetupRef = useRef();

    const [modalActivateRole, setModalActivateRole] = useState(false);
    const [modalChangeRole, setModalChangeRole] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(false);

    const dispatch = useDispatch();

    const handleActivateAccount = async () => {
        setIsLoading(true);
        setDone(false);
        await coreAPI
            .activateRole('partner')
            .then(async response => {
                const authToken = response.data;
                if (!isNil(authToken)) {
                    localStorage.setItem('authToken', authToken);
                    dispatch(fetchCurrentUser());
                }
            })
            .then(() => {
                setIsLoading(false);
                setDone(true);
            });
    };

    const handleChangeAccount = async () => {
        setIsLoading(true);
        setDone(false);
        await coreAPI
            .changeRole('partner')
            .then(response => {
                const authToken = response.data;
                if (!isNil(authToken)) {
                    localStorage.setItem('authToken', authToken);
                    dispatch(fetchCurrentUser());
                }
            })
            .then(() => {
                setIsLoading(false);
                setDone(true);
            });
    };

    const funcOpen = () => {
        setisOpen(!isOpen);
    };

    const handleCartStatus = cartPresent => {
        setCartPresent(cartPresent);
    };

    const handlePlanSelection = selectedPlan => {
        const { user } = props;
        const isPartner = user && user.currentRole === 'partner';
        const rolePresent = user && user.activatedRoles.includes('partner');
        if (user && !rolePresent) {
            setModalActivateRole(true);
        } else if (user && !isPartner) {
            setModalChangeRole(true);
        } else {
            setPlanDetails({
                ...planDetails,
                selectedPlan,
                isPlanSelected: true,
                paymentStatus: false,
            });

            accountSetupRef.current.scrollIntoView({ behavior: 'smooth' });

            if (selectedPlan) {
                googleAnalytics &&
                    window.gtag('event', 'get_plan_info', {
                        send_to: 'G-34FQJZ2P9G',
                        plan_type: planDetails.planType,
                        plan: selectedPlan.plan,
                        price: selectedPlan.amount / 100,
                    });
            }
        }
    };

    const handleLandscapeSelection = selectedLandScape => {
        setSelectedLandScape(selectedLandScape);
    };

    const updatePlanID = planID => {
        setPlanID(planID);
    };

    const handlePaymentStatus = paymentStatus => {
        setPlanDetails({ ...planDetails, paymentStatus });
        // window.scrollTo({ top: paymentSuccessRef.current.offsetTop, behavior: 'smooth' });
        paymentSuccessRef.current.scrollIntoView({
            behavior: 'smooth',
        });
        setIsDisable(false);
    };

    const updateSubscriptionPlan = subscriptionId => {
        setSubscriptionId(subscriptionId);
    };

    const supportLandscape = () => {
        coreAPI.changeLandscapePreference('plan_JKQm1tmh1QzCIw', subscriptionId).then(response => {
            // console.log({ response });
        });
    };

    const scrollToPayment = () => {
        paymentRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDisable = disable => {
        setIsDisable(disable);
    };

    useEffect(() => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
    }, []);

    return (
        <StaticPage
            title="Organizaciones"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AboutPage',
                description:
                    'Empresas e instituciones de todo tamaño que velan por la naturaleza transformando sus impactos ambientales en acciones positivas.',
                name: 'Organizaciones',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <Header
                        transparent
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain>
                    <Hero
                        title={<FormattedMessage id="Business.title" />}
                        subtitle={<FormattedMessage id="Business.subtitle" />}
                        image={heroimage}
                    />

                    <Plan>
                        <PlanText>
                            <FormattedMessage id="NewPartnersPage.Plan.Signup" />
                            <br /> <FormattedMessage id="NewPartnersPage.Plan.Contribution" />{' '}
                        </PlanText>
                        <Personal to="/people">
                            <FormattedMessage id="NewPartnersPage.Redirect.NewFriendsPage" />
                        </Personal>
                    </Plan>
                    <ModalComponent
                        onClose={() => {
                            setModalActivateRole(false);
                        }}
                        isOpen={modalActivateRole}
                    >
                        <ModalText>
                            <FormattedMessage id="NewPartnersPage.Modal.Activate.Title" />
                        </ModalText>
                        <Button
                            onClick={handleActivateAccount}
                            disabled={isLoading || done}
                            variant={isLoading || done ? 'disabled' : 'filled'}
                        >
                            {!done && (
                                <span>
                                    {isLoading ? (
                                        <FormattedMessage id="NewPartnersPage.Modal.Activating" />
                                    ) : (
                                        <FormattedMessage id="NewPartnersPage.Modal.Activate" />
                                    )}
                                </span>
                            )}
                            {done && <FormattedMessage id="NewPartnersPage.Modal.Activated" />}
                        </Button>
                    </ModalComponent>
                    <ModalComponent
                        onClose={() => {
                            setModalChangeRole(false);
                        }}
                        isOpen={modalChangeRole}
                    >
                        <ModalText>
                            <FormattedMessage id="NewPartnersPage.Modal.Title" />
                        </ModalText>
                        <Button
                            onClick={handleChangeAccount}
                            disabled={isLoading || done}
                            variant={isLoading || done ? 'disabled' : 'filled'}
                        >
                            {!done && (
                                <span>
                                    {isLoading ? (
                                        <FormattedMessage id="NewPartnersPage.Modal.Switching" />
                                    ) : (
                                        <FormattedMessage id="NewPartnersPage.Modal.Switch" />
                                    )}
                                </span>
                            )}
                            {done && <FormattedMessage id="NewPartnersPage.Modal.Switched" />}
                        </Button>
                    </ModalComponent>
                    <section id="become" className={css.plans} ref={planRef}>
                        <div className={`${css.sectionContent} ${css.planWrapper}`}>
                            <PlansComparison
                                user={props.user}
                                userType={planDetails.planType}
                                handlePlanSelection={handlePlanSelection}
                                selectedPlan={planDetails.selectedPlan}
                                cart={cartPresent}
                                handleCartStatus={handleCartStatus}
                            />
                        </div>
                        <ContactUs>
                            <ContactUsText>
                                <FormattedMessage id="NewPartnersPage.ContactUs.Description" />
                            </ContactUsText>
                            <Button
                                onClick={() => {
                                    setIsFormOpen(true);
                                }}
                            >
                                {' '}
                                <FormattedMessage id="NewPartnersPage.ContactUs" />
                            </Button>
                        </ContactUs>
                    </section>

                    <GetStarted>
                        <GetStartedContainer>
                            <GetStartedHeading>
                                <FormattedMessage id="NewPartnersPage.GetStarted.Heading" />
                            </GetStartedHeading>
                            <Video
                                vimeo={true}
                                onEnd={() => {
                                    setisOpen(!isOpen);
                                }}
                                onClick={funcOpen}
                                open={isOpen}
                                source="https://player.vimeo.com/video/278549592?autoplay=1"
                                videoItem={{
                                    src:
                                        'https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_640x360',
                                    srcset:
                                        'https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_960x540 2x, https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_1280x720 3x, https://i.vimeocdn.com/video/711623443-8bb7a1b0c8c002f022f6f7f97141c517cc8ab137a74fd39b2854c90479ca83d4-d_1920x1080 4x',
                                    alt: 'REGENERA_EN',
                                }}
                            />
                        </GetStartedContainer>
                        <GetStartedContainer>
                            <GetStartedHeading>
                                <FormattedMessage id="NewPartnersPage.Reasons.Heading" />
                            </GetStartedHeading>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewPartnersPage.Reason.1" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="100%" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewPartnersPage.Reason.2" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewPartnersPage.Reason.3" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewPartnersPage.Reason.4" />
                                </Reason>
                            </ReasonContainer>
                            <ReasonContainer>
                                <Icon>
                                    <img src={Check} width="100%" height="auto" />
                                </Icon>
                                <Reason>
                                    <FormattedMessage id="NewPartnersPage.Reason.5" />
                                </Reason>
                            </ReasonContainer>
                        </GetStartedContainer>
                    </GetStarted>

                    <div ref={accountSetupRef}>
                        {planDetails.isPlanSelected && (
                            <section className={`${css.sectionContent} ${css.planChooseSuccess}`}>
                                <h4>
                                    <FormattedMessage id="NewPartnersPage.Setup.Account.title" />
                                </h4>
                                {!props.user && (
                                    <h4>
                                        <FormattedMessage id="NewPartnersPage.Setup.Account.title.subText" />
                                    </h4>
                                )}
                            </section>
                        )}
                    </div>

                    {planDetails.isPlanSelected && (
                        <section className={`${css.sectionContent} ${css.accPaymentCont}`}>
                            <AccountSetup scrollToPayment={scrollToPayment} />

                            <div ref={paymentRef}>
                                {props.user && (
                                    <>
                                        <Separator />
                                        <StripeProvider stripe={stripe}>
                                            <Elements>
                                                <Payment
                                                    selectedPlan={planDetails.selectedPlan}
                                                    handlePaymentStatus={handlePaymentStatus}
                                                    updateSubscriptionPlan={updateSubscriptionPlan}
                                                    supportLandscape={supportLandscape}
                                                    selectedLandScape={selectedLandScape}
                                                    handleLandscapeSelection={
                                                        handleLandscapeSelection
                                                    }
                                                    updatePlanID={updatePlanID}
                                                    planID={planID}
                                                    handleDisable={handleDisable}
                                                    isDisable={isDisable}
                                                />
                                            </Elements>
                                        </StripeProvider>
                                    </>
                                )}
                            </div>
                        </section>
                    )}

                    <section ref={paymentSuccessRef}>
                        {planDetails.paymentStatus && (
                            <PaymentSuccess
                                currentSelectedPlan={planDetails.selectedPlan}
                                selectedLandScape={selectedLandScape}
                                user={props.user}
                                handleCartStatus={handleCartStatus}
                                subscriptionId={subscriptionId}
                            />
                        )}
                    </section>
                    <Guide>
                        <Title>
                            <FormattedMessage id="NewPartnersPage.Guide" />
                        </Title>
                        <StyledContainerDesktop>
                            {guide.map((item, index) => {
                                return (
                                    <ImageDescription
                                        src={item.img}
                                        description={item.description}
                                        title={item.title}
                                        inverse={item.inverse}
                                    />
                                );
                            })}
                        </StyledContainerDesktop>
                        <StyledContainerMobile>
                            {guide.map((item, index) => {
                                return (
                                    <ImageDescription
                                        src={item.img}
                                        description={item.description}
                                        title={item.title}
                                        inverse={false}
                                    />
                                );
                            })}
                        </StyledContainerMobile>
                        {props.user ? null : (
                            <SignupButton to="/signup" data-testid="btn-partner-signup">
                                <FormattedMessage id="NewPartnersPage.Guide.SignUp" />
                            </SignupButton>
                        )}
                    </Guide>
                    <Community />
                    <Modal
                        isOpen={isFormOpen}
                        onClose={() => {
                            setIsFormOpen(false);
                            console.log('Closing modal');
                        }}
                        onManageDisableScrolling={() => {}}
                    >
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSdIKFuCEnpgdTL1R0gG401t3QbiiZZ1sbR8RapUxajCMCRBkg/viewform?embedded=true"
                            width="700"
                            height="520"
                            frameborder="0"
                            marginheight="0"
                            marginwidth="0"
                        >
                            Cargando…
                        </iframe>
                    </Modal>
                    <Testimony id="testimonials">
                        <SectionContent>
                            <Heading>
                                <FormattedMessage id="NewPartnersPage.Community" />
                            </Heading>
                            <TestimonialsTitle>
                                <FormattedMessage id="Testimonials.title.organisation" />
                            </TestimonialsTitle>
                            <TestimonyListDesktop>
                                <TestimonyCard>
                                    <TestimonyPic src={renzo} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.renzo.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.renzo.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.renzo.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                                <TestimonyCard>
                                    <TestimonyPic src={kurt} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.kurt.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.kurt.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.kurt.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                                <TestimonyCard>
                                    <TestimonyPic src={eileen} />
                                    <TestimonyDescription>
                                        <FormattedMessage id="Testimonials.eileen.description" />
                                    </TestimonyDescription>
                                    <TestimonyPeople>
                                        <strong>
                                            <FormattedMessage id="Testimonials.eileen.name" />
                                        </strong>{' '}
                                        <TestimonyPeopleName>
                                            {' '}
                                            - <FormattedMessage id="Testimonials.eileen.title" />
                                        </TestimonyPeopleName>
                                    </TestimonyPeople>
                                </TestimonyCard>
                            </TestimonyListDesktop>
                            <TestimonyListMobile>
                                <Carousel
                                    cols={2}
                                    rows={1}
                                    gap={40}
                                    loop
                                    showArrow={true}
                                    hideDots={true}
                                    scrollSnap={false}
                                    mobileBreakpoint={359}
                                    responsiveLayout={[
                                        {
                                            breakpoint: 800,
                                            cols: 1,
                                            rows: 1,
                                            gap: 10,
                                        },
                                    ]}
                                >
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={renzo} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.renzo.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.renzo.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.renzo.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={kurt} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.kurt.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.kurt.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.kurt.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <TestimonyCard>
                                            <TestimonyPic src={eileen} />
                                            <TestimonyDescription>
                                                <FormattedMessage id="Testimonials.eileen.description" />
                                            </TestimonyDescription>
                                            <TestimonyPeople>
                                                <strong>
                                                    <FormattedMessage id="Testimonials.eileen.name" />
                                                </strong>{' '}
                                                <TestimonyPeopleName>
                                                    {' '}
                                                    -{' '}
                                                    <FormattedMessage id="Testimonials.eileen.title" />
                                                </TestimonyPeopleName>
                                            </TestimonyPeople>
                                        </TestimonyCard>
                                    </Carousel.Item>
                                </Carousel>
                            </TestimonyListMobile>
                        </SectionContent>
                    </Testimony>

                    <UpButton />
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer history={props.history} />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(BusinessPage));
