import React from 'react';
import { Right, Title, Card, StyledLink, Text } from './Styles';
import roundedArrow from '../../../../assets/newAssets/roundedArrow.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import RightNavCard from '../../../../newComponents/RightNavCard/RightNavCard';

const NewRightNav = props => {
    const path = '/people/personal-footprint-calculator';
    return (
        <Right>
            <Title>
                <FormattedMessage id="MyNaturePage.SideNav.QuickLinks" />
            </Title>
            <Card>
                <StyledLink to={path}>
                    <p className="title">
                        <FormattedMessage id="MyNaturePage.SideNav.CalculatorLink" />
                    </p>
                    <img src={roundedArrow} />
                </StyledLink>
                <br /> <br />
                <Text>
                    <FormattedMessage id="MyNaturePage.SideNav.CalculatorDesc" />
                </Text>
            </Card>
            <RightNavCard user={props.user} />
        </Right>
    );
};

const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
    user: storeState.user.currentUser
        ? {
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
});
const RightNav = compose(withRouter, connect(mapStateToProps), injectIntl)(NewRightNav);

export default RightNav;
