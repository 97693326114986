import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import Button from '../../../../../newComponents/Button';
import 
{ getCurrentUser, 
    updateUser, 
    updateAccount, 
    uploadImage, 
    changePassword,
    getStripeSupportedLocales,
    getStripeCustomerLocale,
    updateStripeCustomerLanguage } from '../../../../../coreApi';
import { ReactComponent as ArrowDown } from '../../../../../assets/newAssets/arrow-down.svg';
import { fetchCurrentUser } from '../../../../../ducks/user.duck';
import InjectedCheckoutForm from '../../../../../containers/UpdateCardPage/CheckoutFormUpdate';
import { StripeProvider, Elements } from 'react-stripe-elements';
import FileUpload from '../../../FileUpload';
import { format } from './fiFormatter';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { isNil, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
    DropdownHolder,
    DropdownInputButton,
    LanguageDropdown,
    LanguageDropdownItem,
    LanguageButton
  } from "./Styles"
import {
    ProfileAndAccountMain,
    AccountForm,
    Title,
    AccountContainer,
    SubTitle,
    PasswordForm,
    Form,
    FormContainer,
    PhotoUpload,
    Label,
    ErrorMessage,
    ButtonContainer,
    Password,
    ReEnterPassword,
    PasswordFormContainer,
    Element,
    PaymentHistory,
    IncreaseImpact,
    ElementRow,
    Para,
    ElementButtonContainer1,
    ElementButtonContainer2,
    Story,
    Interest,
    Content,
    ConfirmPassword,
    PasswordTitle,
    PasswordDescription,
    TextArea, StyledSuccessMessage,
    FirstName, LastName, CardDetailsForm, CardContainer, InterestButton, NewDiv,
    Row, Row1, Row2, Row3, Email, Number, City, Country, Input, PasswordContainer, InputPassword, Eye,
    LanguageSelectionContainer, LanguageSelectionContent, LanguageSelectionTitle, LanguageSelectionDescription, 
    LanguagePreferencesContainer
} from './Styles'
import eye from '../../../../../assets/newAssets/eye.png'
import Footer from '../../../components/Footer';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';

import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import ToggleComponent from '../../../../../newComponents/ToggleComponent/toggleComponent';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
        }
        : null,

});

const NewMyNatureProfileAndAccountPage = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false);
    const [errorCountry, setErrorCountry] = useState(false);
    const [errorCity, setErrorCity] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [nonMatchingPassword, setNonMatchingPassword] = useState(false);
    const [successAccount, setSuccessAccount] = useState(false);
    const [successPassword, setSuccessPassword] = useState(false);
    const [successStory, setSuccessStory] = useState(false);
    const [stripe, setStripe] = useState(null);
    const [metadata, setMetadata] = useState();
    const historyPath = useHistory();
    const [passwordShown, setPasswordShown] = useState(false);
    const [rePasswordShown, setRePasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const dispatch = useDispatch();
    const containerRef1 = useRef(null);
    const [isOpenLang, setIsOpenLang] = useState(false);
    const [selectedOptionLang, setSelectedOptionLang] = useState('');
    const [Languages, setLanguages] = useState(null);
    const [langSuccess, setLangSuccess] = useState(false);
    const [langError, setLangError] = useState(false);
    const [langLoading, setlangLoading] = useState(false);
    
    const SuccessMessage = <StyledSuccessMessage><FormattedMessage  id="OurNaturePage.Profile.Success"/></StyledSuccessMessage>;

    useEffect(() => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    }, [user])
    const [imageData, setImageData] = useState();
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
        city: "",
        metadata: {},
    });
    const [userPassword, setUserPassword] = useState({
        password: "",
        rePassword: "",
        confirmPassword: ""
    });
    let checkForm = (isNil(userData.firstName) || isEmpty(userData.firstName)) || (isNil(userData.lastName) || isEmpty(userData.lastName))
        || (isNil(userData.email) || isEmpty(userData.email)) || (isNil(userData.phoneNumber) || isEmpty(userData.phoneNumber))
        || (isNil(userData.city) || isEmpty(userData.city)) || (isNil(userData.country) || isEmpty(userData.country));
    useEffect(() => {
        const fetchData = async () => {
            const res = await getCurrentUser();
            setUserData(res.data);
            if (res.data.metadata) {
                setMetadata(res.data.metadata);
                setUserStory(res.data.metadata.userStory);
                if (res.data.metadata.interest instanceof Array)
                    UpdateInterest(res.data.metadata.interest);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        fetchSupportedLocales();
        fetchUserLocales();
      }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [containerRef1]);
    
      const fetchUserLocales = async () =>{
           getStripeCustomerLocale().then((response) =>{
          setSelectedOptionLang(response.data)
        })
      }
      const fetchSupportedLocales = async () =>{
           getStripeSupportedLocales().then((response)=>{
          setLanguages(response.data)
        })
      } 

    let name, value;
    const handleChange = e => {
        name = e.target.name;
        value = e.target.value;
        if((name==='firstName'||name==='lastName'||name==='city'||name==='country')){
            value=value.replace(/[^A-Za-z ]/ig, '')
            setUserData({ ...userData, [name]: value });
        }
        else if(name==='phoneNumber'){
            setUserData({ ...userData, [name]: value });
        }
    };
    const handlePassChange = e => {
        name = e.target.name;
        value = e.target.value;
        setUserPassword({ ...userPassword, [name]: value });
    };

    // const updatePassword = async (e) => {
    //     e.preventDefault();

    //     if (userPassword.password.length < 5 || userPassword.rePassword.length < 5 || (userPassword.password != userPassword.rePassword)) {
    //         setErrorPassword(true);
    //         setErrorRePassword(true);
    //         setSuccessPassword(false);
    //     }
    //     else {
    //         setSuccessPassword(true);
    //         const res = await changePassword(userPassword.password, userPassword.rePassword);
    //     }

    // }
    useEffect(() => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email) === true) {
            setErrorEmail(false);
        }
        if (userData.firstName && userData.firstName.length > 2) {
            setErrorFirstName(false);
        }
        if (userData.lastName && userData.lastName.length > 2) {
            setErrorLastName(false);
        }
        if (userData.city && userData.city.length > 2) {
            setErrorCity(false);
        }
        if (userData.country && userData.country.length > 2) {
            setErrorCountry(false);
        }
        if (userData.phoneNumber && userData.phoneNumber.length > 8) {
            setErrorNumber(false);
        }
    });
    const updatePassword = async (e) => {

        if (userPassword.password.length < 8 || userPassword.rePassword.length < 8) {
            setErrorPassword(true);
            setSuccessPassword(false);
            setInvalidPassword(false);
            setNonMatchingPassword(false);
        }
        else if(userPassword.rePassword !== userPassword.confirmPassword) {
            setNonMatchingPassword(true);
            setErrorPassword(false);
            setSuccessPassword(false);
            setInvalidPassword(false);
        }
        else {
            await changePassword(userPassword.password, userPassword.rePassword).then(() =>{
                setSuccessPassword(true);
                setErrorPassword(false);
                setInvalidPassword(false);
                setNonMatchingPassword(false);
            }).catch(() =>{
                setSuccessPassword(false);
                setErrorPassword(true);
                setInvalidPassword(false);
                setNonMatchingPassword(false);
            })
        }
    }
    const postData = async e => {
        e.preventDefault();
        let emailError = false;
        let firstNameError = false;
        let lastNameError = false;
        let cityError = false;
        let countryError = false;
        let numberError = false;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email) === false) {
            emailError = true;
            setErrorEmail(true);
            setSuccessAccount(false);
        }
        if (userData.firstName && userData.firstName.length < 2) {
            firstNameError = true;
            setErrorFirstName(true);
            setSuccessAccount(false);
        }
        if (userData.lastName && userData.lastName.length < 2) {
            lastNameError = true;
            setErrorLastName(true);
            setSuccessAccount(false);
        }
        if (userData.city && userData.city.length < 2) {
            cityError = true;
            setErrorCity(true);
            setSuccessAccount(false);
        }
        if (userData.country && userData.country.length < 2) {
            countryError = true;
            setErrorCountry(true);
            setSuccessAccount(false);
        }
        if (userData.phoneNumber && userData.phoneNumber.length < 10) {
            numberError = true;
            setErrorNumber(true);
            setSuccessAccount(false);
        }

        if (emailError === false && firstNameError === false &&
            lastNameError === false && cityError === false &&
            countryError === false && numberError === false) {
            const newUserDetails = {
                city: userData.city, country: userData.country,
            };
            const newAccountDetails = {
                phoneNumber: userData.phoneNumber,
                firstName: userData.firstName, lastName: userData.lastName,
            };
            if (imageData) {
                const send = await uploadImage(imageData);
                if (send.data && send.data.fileId) {
                    newAccountDetails.profilePicture = send.data.fileId;
                }
            }
            await updateAccount(newAccountDetails).then(async() =>{
                await updateUser(newUserDetails).then(()=>{
                    dispatch(fetchCurrentUser());
                })
                
            });
            setSuccessAccount(true);

        }
    };
    let interestArray = [
        {
            id: '1',
            name: 'Birds',
            selected: true,
        },
        {
            id: '2',
            name: 'Hiking',
            selected: false,
        },
        {
            id: '3',
            name: 'Rivers',
            selected: false,
        },
        {
            id: '4',
            name: 'Mountain climbing',
            selected: true,
        },
        {
            id: '5',
            name: 'Tree planting',
            selected: false,
        },
        {
            id: '6',
            name: 'Nature walks',
            selected: true,
        },
        {
            id: '7',
            name: 'Swimming',
            selected: false,
        },
        {
            id: '8',
            name: 'Agro forestry',
            selected: false,
        },
        {
            id: '9',
            name: 'Natural resources',
            selected: true,
        }
    ];
    const [userStory, setUserStory] = useState('');
    const [interest, UpdateInterest] = useState(interestArray);
    const handleStoryChange = e => {
        setUserStory(e.target.value)
    };
    function handleSelect(id) {
        const inter = interest.slice();
        let obj = inter.find((o, i) => {
            if (o.id === id) {
                inter[i] = { ...inter[i], selected: !inter[i].selected };
            }
        });
        UpdateInterest(inter);
        handleInterest(inter);
    };
    const handleStory = async () => {
        setSuccessStory(true);
        const userMetadata = {
            ...metadata,
            userStory: userStory,
        }
        setMetadata(userMetadata);
        await updateUser({ metadata: userMetadata });
    };
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };
    const toggleRePassword = () => {
        setRePasswordShown(!rePasswordShown);
      };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
      };
    const handleInterest = async (inter) => {
        const userMetadata = {
            ...metadata,
            interest: inter,
        }
        setMetadata(userMetadata);
        await updateUser({ metadata: userMetadata });
    };
    const handlePasswordError = () => {
        setInvalidPassword(true);
        setSuccessPassword(false);
        setErrorPassword(false);
    }
    const firstNamePlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.firstNamePlaceholder' });
    const lastNamePlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.lastNamePlaceholder' });
    const emailPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.EmailPlaceholder' });
    const numberPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.NumberPlaceholder' });
    const cityPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.CityPlaceholder' });
    const countryPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.CountryPlaceholder' });

    const [isInvoiceVisible,setIsInvoiceVisible]= useState(false);
    const [isCardVisible,setIsCardVisible]= useState(false);
    const [isPasswordVisible,setIsPasswordVisible]= useState(false);

    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
          setIsOpenLang(false);
        }
      };
    
    function onSelectedLang(value) {
        setSelectedOptionLang(value);
        setIsOpenLang(false);
        setLangError(false);
        setLangSuccess(false);
    }
    const openLang = () => {
      setIsOpenLang(!isOpenLang);
    };

    const updateUserLanguage = async () =>{
      setlangLoading(true);
      updateStripeCustomerLanguage(selectedOptionLang).then(async ()=>{
        setLangError(false);
        setLangSuccess(true);
        await fetchUserLocales()
        setlangLoading(false);
      }).catch(()=>{
        setLangSuccess(false);
        setLangError(true);
        setlangLoading(false);
      })
    }
    
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Friend profile<br/>& account</>}
                image={HeroImage}
            />
            <ProfileAndAccountMain>
                <AccountForm>
                    <Title>
                        <FormattedMessage id="MyNaturePage.Profile.ProfileTitle" />
                    </Title>
                    <AccountContainer>
                        <SubTitle>
                            <FormattedMessage id="MyNaturePage.Profile.ProfileSubTitle" />
                        </SubTitle>
                        <FormContainer>
                            <NewDiv>
                                <Form>
                                    <Row1
                                        status={(errorFirstName) && 'failure'}
                                    >
                                        <FirstName
                                            status={(errorFirstName) && 'failure'}
                                        >
                                            <Label htmlFor="name">
                                                <FormattedMessage id="MyNaturePage.Profile.firstNameLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={firstNamePlaceholder}
                                                name="firstName"
                                                status={(errorFirstName) && 'failure'}
                                                value={userData.firstName}
                                                onChange={handleChange}

                                            />
                                            {(errorFirstName) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.LessWords" />
                                                </ErrorMessage>
                                            )}
                                        </FirstName>
                                        <LastName
                                            status={(errorLastName) && 'failure'}
                                        >
                                            <Label htmlFor="name">
                                                <FormattedMessage id="MyNaturePage.Profile.lastNameLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={lastNamePlaceholder}
                                                name="lastName"
                                                status={(errorLastName) && 'failure'}
                                                value={userData.lastName}
                                                onChange={handleChange}
                                            />
                                            {(errorLastName) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.LessWords" />
                                                </ErrorMessage>
                                            )}
                                        </LastName>
                                    </Row1>
                                    <Row2>
                                        <Email
                                            status={(errorEmail) && 'failure'}
                                        >
                                            <Label htmlFor="email">
                                                <FormattedMessage id="MyNaturePage.Profile.EmailLabel" />
                                            </Label>
                                            <Input
                                                type="email"
                                                placeholder={emailPlaceholder}
                                                status={(errorEmail) && 'failure'}
                                                name="email"
                                                value={userData.email}
                                                onChange={handleChange}
                                            />
                                            {(errorEmail) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Email" />
                                                </ErrorMessage>
                                            )}
                                        </Email>
                                        <Number
                                            status={(errorNumber) && 'failure'}
                                        >
                                            <Label htmlFor="number">
                                                <FormattedMessage id="MyNaturePage.Profile.NumberLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder={numberPlaceholder}
                                                status={(errorNumber) && 'failure'}
                                                name="phoneNumber"
                                                value={format(userData.phoneNumber)}
                                                onChange={handleChange}
                                            />
                                            {(errorNumber) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Number" />
                                                </ErrorMessage>
                                            )}
                                        </Number>
                                    </Row2>
                                    <Row3>
                                        <City
                                            status={(errorCity) && 'failure'}
                                        >
                                            <Label htmlFor="city">
                                                <FormattedMessage id="MyNaturePage.Profile.CityLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={cityPlaceholder}
                                                status={(errorCity) && 'failure'}
                                                name="city"
                                                value={userData.city}
                                                onChange={handleChange}
                                            />
                                            {(errorCity) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.City" />
                                                </ErrorMessage>
                                            )}
                                        </City>
                                        <Country
                                            status={(errorCountry) && 'failure'}
                                        >
                                            <Label htmlFor="country">
                                                <FormattedMessage id="MyNaturePage.Profile.CountryLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder={countryPlaceholder}
                                                status={(errorCountry) && 'failure'}
                                                name="country"
                                                value={userData.country}
                                                onChange={handleChange}
                                            />
                                            {(errorCountry) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Country" />
                                                </ErrorMessage>
                                            )}
                                        </Country>
                                    </Row3>
                                </Form>

                                <PhotoUpload>
                                    <FileUpload picture={userData.profilePicture} imgData={(data) => { setImageData(data) }} />
                                </PhotoUpload>
                            </NewDiv>
                            <ButtonContainer>
                                {!checkForm ? <Button
                                    onClick={postData}
                                    variant="filled"
                                    size="lg"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.UpdateUser" />
                                </Button> : <Button
                                    variant="disabled"
                                    size="lg"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.UpdateUser" />
                                </Button>}

                            </ButtonContainer>
                            {successAccount && (SuccessMessage)}
                        </FormContainer>
                    </AccountContainer>
                </AccountForm>

                
                <ToggleComponent 
                    titleId="MyNaturePage.Profile.Toggle.Invoices"
                    onClick={()=>{
                        setIsInvoiceVisible(!isInvoiceVisible)
                    }} 
                    isVisible={isInvoiceVisible}
                />

                {isInvoiceVisible &&  
                  <LanguageSelectionContainer>
                    <LanguageSelectionTitle>
                      <FormattedMessage id="MyNaturePage.Profile.LanguageSelection.Title" />
                    </LanguageSelectionTitle>
                    <LanguageSelectionContent>
                      <LanguageSelectionDescription >
                        <FormattedMessage id="MyNaturePage.Profile.LanguageSelection.Description" />
                      </LanguageSelectionDescription>
                      <LanguagePreferencesContainer>
                        <DropdownHolder medium>
                          <Label htmlFor="name">
                              <FormattedMessage id="MyNaturePage.Profile.LanguageSelection.Dropdown.Title" />
                          </Label>
                          <DropdownInputButton name="Languages" onClick={openLang}>
                              <span>{selectedOptionLang}{' '}</span>
                              <ArrowDown className="arrow" width="34" height="34" />
                          </DropdownInputButton>
                          {isOpenLang && (
                            <LanguageDropdown ref={containerRef1}>
                            {Languages &&
                              Languages.map((item, i) => (
                              <LanguageDropdownItem
                                key={i}
                                onClick={() => onSelectedLang(item)}
                              >
                                {item}
                              </LanguageDropdownItem>
                              ))}
                            </LanguageDropdown>
                          )}
                        </DropdownHolder>

                        <LanguageButton>
                          {!langLoading? 
                          <Button
                              onClick={updateUserLanguage}
                              variant="filled"
                              size="lg"
                              >
                                  <FormattedMessage id="MyNaturePage.Profile.LanguageSelection.Dropdown.Button"/>
                          </Button> : 
                          <Button
                                  onClick={() =>{}}
                                  variant="disabled"
                                  size="lg"
                              >
                                  <FormattedMessage id="MyNaturePage.Profile..Dropdown.Button.Disabled"/>
                          </Button>}
                        </LanguageButton>
                      {langSuccess && <StyledSuccessMessage><FormattedMessage id="MyNaturePage.Profile..Dropdown.Language.Update.Success"/></StyledSuccessMessage>}
                      {langError && <ErrorMessage><FormattedMessage id="<MyNaturePage.Profile..Dropdown.Button.Update.Error"/></ErrorMessage>}
                      </LanguagePreferencesContainer>
                    </LanguageSelectionContent>
                  </LanguageSelectionContainer>}

                <ToggleComponent 
                    titleId="MyNaturePage.Profile.Toggle.UpdateCard"
                    onClick={()=>{
                        setIsCardVisible(!isCardVisible)
                    }} 
                    isVisible={isCardVisible}
                />

                {isCardVisible && <CardDetailsForm>
                    <CardContainer>
                        <StripeProvider stripe={stripe}>
                            <Elements>
                                <InjectedCheckoutForm />
                            </Elements>
                        </StripeProvider>
                    </CardContainer>
                </CardDetailsForm>}

                <ToggleComponent 
                    titleId="MyNaturePage.Profile.Toggle.Password"
                    onClick={()=>{
                        setIsPasswordVisible(!isPasswordVisible)
                    }} 
                    isVisible={isPasswordVisible}
                />

                {isPasswordVisible &&
                <PasswordForm>
                    <PasswordTitle>
                        <FormattedMessage id="MyNaturePage.Profile.Password.Title" />
                    </PasswordTitle>
                    <PasswordContainer>
                        <PasswordDescription >
                            <FormattedMessage id="MyNaturePage.Profile.Password.Description" />
                        </PasswordDescription>
                        <PasswordFormContainer>
                            <Row column={true}>
                                <Password>
                                    <Label htmlFor="password">
                                        <FormattedMessage id="MyNaturePage.Profile.PasswordLabel" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={passwordShown ? "text" : "password"}
                                            placeholder="*************"
                                            name="password"
                                            onChange={handlePassChange}
                                        />
                                        <Eye onClick={togglePassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </Password>
                                <ReEnterPassword>
                                    <Label htmlFor="rePasswrod">
                                        <FormattedMessage id="MyNaturePage.Profile.RePasswordLabel" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={rePasswordShown ? "text" : "password"}
                                            placeholder="*************"
                                            name="rePassword"
                                            onChange={handlePassChange}
                                        />
                                        <Eye onClick={toggleRePassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </ReEnterPassword>
                                <ConfirmPassword>
                                    <Label htmlFor="confirmPassword">
                                        <FormattedMessage id="MyNaturePage.Profile.ConfirmPasswordLabel" />
                                    </Label>
                                    <InputPassword>
                                        <Input
                                            type={confirmPasswordShown ? "text" : "password"}
                                            placeholder="*************"
                                            name="confirmPassword"
                                            onChange={handlePassChange}
                                        />
                                        <Eye onClick={toggleConfirmPassword}><img src={eye}/></Eye>
                                    </InputPassword>
                                </ConfirmPassword>
                            </Row>
                            {nonMatchingPassword && <ErrorMessage><FormattedMessage id="MyNaturePage.Error.ConfirmPassword" /></ErrorMessage>}
                            {errorPassword && <ErrorMessage><FormattedMessage id="MyNaturePage.Error.Password" /></ErrorMessage>}
                            {invalidPassword && <ErrorMessage><FormattedMessage id="MyNaturePage.Error.InvalidPassword" /></ErrorMessage>}
                            <ButtonContainer>
                                {userPassword && 
                                userPassword.password.length>=8 && 
                                userPassword.rePassword.length>=8 && 
                                userPassword.confirmPassword.length>=8? 
                                <Button
                                    onClick={updatePassword}
                                    variant="filled"
                                    size="lg"
                                    >
                                        <FormattedMessage id="OurNaturePage.Profile.UpdatePassword"/>
                                </Button> : 
                                <Button
                                    onClick={handlePasswordError}
                                    variant="disabled"
                                    size="lg"
                                    >
                                        <FormattedMessage id="OurNaturePage.Profile.UpdatePassword"/>
                                </Button>}
                            </ButtonContainer>
                            {successPassword && (SuccessMessage)}
                        </PasswordFormContainer>
                    </PasswordContainer>
                </PasswordForm>}
                <Element>
                    <ElementRow>
                        <PaymentHistory>
                            <Title>
                                <FormattedMessage id="MyNaturePage.Profile.PaymentTitle" />
                            </Title>
                            <Para>
                                 <FormattedMessage id="MyNaturePage.Profile.Payment"/>
                            </Para>
                            <ElementButtonContainer1>
                                <Button
                                    path="/account/payment-history"
                                    variant="filled"
                                    size="md"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.PaymentDetails" />
                                </Button>
                            </ElementButtonContainer1>
                        </PaymentHistory>
                        <IncreaseImpact>
                            <Title>
                                <FormattedMessage id="MyNaturePage.Profile.ImpactTitle" />
                            </Title>
                            <Para>
                              <FormattedMessage id="MyNaturePage.Profile.Impact"/>
                            </Para>
                            <ElementButtonContainer2>
                                <Button
                                    variant="filled"
                                    size="md"
                                    path="/people"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.ImpactPlan" />
                                </Button>
                            </ElementButtonContainer2>
                        </IncreaseImpact>
                    </ElementRow>
                </Element>
                <Story>
                    <Title>
                        <FormattedMessage id="MyNaturePage.Profile.StoryTitle" />
                    </Title>
                    <SubTitle small>
                        <FormattedMessage id="MyNaturePage.Profile.StorySubTitle" />
                    </SubTitle>
                    <Content>
                        <TextArea
                            placeholder="Tell us about your story here..."
                            maxLength="300"
                            value={userStory}
                            onChange={handleStoryChange}
                        />
                        <ButtonContainer>
                            {userStory && userStory.length > 0 ? <Button
                                onClick={handleStory}
                                variant="filled"
                                size="lg"
                            >
                                <FormattedMessage id='MyNaturePage.Profile.UpdateUser' />
                            </Button> : <Button
                                variant="disabled"
                                size="lg"
                                disabled={true}
                            >
                                <FormattedMessage id='MyNaturePage.Profile.UpdateUser' />
                            </Button>}
                        </ButtonContainer>
                        {successStory && (SuccessMessage)}
                    </Content>
                </Story>
                <Interest>
                    <Title>
                        <FormattedMessage id="MyNaturePage.Profile.InterestsTitle" />
                    </Title>
                    <SubTitle small>
                        <FormattedMessage id="MyNaturePage.Profile.InterestsSubTitle" />
                    </SubTitle>
                    <Content>
                        {interest && interest.length > 0 && interest.map(item => (
                            <InterestButton {...item} onClick={() => { handleSelect(item.id) }}>{item.name}</InterestButton>
                        ))
                        }
                    </Content>
                </Interest>
                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </ProfileAndAccountMain>
            <RightNav />
        </Frame>
    )
}
);
const MyNatureProfileAndAccountPage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(NewMyNatureProfileAndAccountPage);
export default MyNatureProfileAndAccountPage;
