import styled from 'styled-components';

export const TitleContainer = styled.section`
    min-height: 270px;
    background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
    background-size: cover;
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        min-height: 200px;
        margin-top: 60px;
    }
`;
export const Center = styled.div`
    max-width: 925px;
    align-self: center;
    top: -18px;
    margin: 26px 20px;
    .title-box {
        h1 {
            font-size: 48px;
            line-height: 58px;
            color: white;
            position: relative;
            .border {
                img {
                    position: absolute;
                    bottom: -8px;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }
        .subtitle {
            color: white;
            margin-top: 20px;
            max-width: 522px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        top: 0;
        margin-top: 0;
        .title-box {
            h1 {
                font-size: 42px;
                line-height: 52px;
                .border {
                    display: block;
                    img {
                        width: auto;
                    }
                }
            }
        }
    }
`;

export const FaqFlow = styled.section`
    .sectionContent {
        width: 100%;

        padding: 0 10px 0 10px;
        margin: 0px auto 30px auto;
    }

    .list {
        padding-left: 30px;
        margin: 20px;
    }

    .list li {
        position: relative;
        margin-bottom: 20px;
        .green-link {
            gap: 16px;
            .link-icon {
                font-size: 29px;
            }
            &:active {
                color: #6ea44c;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .list li h4 {
        font-weight: normal;
    }

    .list li h4:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -30px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #6ea44c;
    }

    .list li h3:before {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        left: -30px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #6ea44c;
    }

    .list li li {
        margin-bottom: 15px;
    }

    .list li li:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -30px;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: #004d37;
    }

    .faq-wrap {
        max-width: 1065px;
        padding: 100px 4.8rem;
        gap: 0 120px;
        flex-direction: column;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .faq-wrap {
            padding: 40px 20px;
            gap: 50px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .faq-wrap {
            flex-wrap: wrap;
        }
    }
`;

export const HelpBoxContainer = styled.section`
    position: relative;
    background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
    background-size: cover;
    text-align: center;
    padding: 0 20px;
    .green-box {
        min-height: 380px;
        gap: 30px;
        max-width: 870px;
        .title-box {
            padding: 0 20px;
            margin-bottom: 4px;
            h3,
            p {
                color: white;
            }
        }
        .btn-group {
            gap: 12px;
            button {
                width: auto;
                max-width: 288px;
                margin: 0 auto;
            }
        }
    }
`;
