import React, { useEffect, useState, useRef } from 'react';
import Header from '../../Header/Header';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LeftArrow } from '../../../../../assets/newAssets/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/newAssets/RightArrow.svg';
import {
    getCurrentOrganization,
    getOrganizationEmissionResultOurNature,
} from '../../../../../coreApi';
import { isNil } from 'lodash';
import CalculatorResult from '../../../../../assets/newAssets/calculatorResult.svg';
import Logo from '../../../../../assets/newAssets/thumbPrintRedOrg.svg';
import Footer from '../../../components/Footer';
import Button from '../../../../../newComponents/Button';
import {
    FootprintMain,
    Title,
    Navigation,
    Date,
    Details,
    Heading,
    Content,
    ResultContainer,
    EmissionContainer,
    EmissionHeading,
    EmissionValue,
    LogoContainer,
    EmissionContent,
    EmissionPercent,
    EmissionScopeValue,
    RedLogo,
    FooterNote,
    EmissionResult,
    NoData,
    GrayText,
    ButtonContainer,
} from './Styles';
import { FormattedMessage } from 'react-intl';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/partner_profile.png';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
});

const OurNatureFootprintPage = connect(mapStateToProps)(({ location, history, user }) => {
    const [orgDetails, setOrgDetails] = useState({});
    const [company, setCompany] = useState(null);
    const [sector, setSector] = useState(null);
    const [result, setResult] = useState([]);
    const [scope1, setScope1] = useState(null);
    const [scope2, setScope2] = useState(null);
    const [scope3, setScope3] = useState(null);
    const [totalEmission, setTotalEmission] = useState(null);
    const [year, setYear] = useState(null);
    const [index, setIndex] = useState(0);

    const historyPath = useHistory();
    useEffect(() => {
        if (user && user.userType === 'personas') {
            historyPath.push('/friends-profile/footprint');
        }
    }, [user]);

    function handleDateLeft() {
        const currIndex = index + 1;
        setIndex(currIndex);
        if (currIndex < result.length) {
            setYear(result[currIndex].year);
            setScope1(result[currIndex].scope1);
            setScope2(result[currIndex].scope2);
            setScope3(result[currIndex].scope3);
            setTotalEmission(result[currIndex].totalEmission);
        }
    }
    function handleDateRight() {
        const currIndex = index - 1;
        if (currIndex >= 0) {
            setIndex(currIndex);
            setYear(result[currIndex].year);
            setScope1(result[currIndex].scope1);
            setScope2(result[currIndex].scope2);
            setScope3(result[currIndex].scope3);
            setTotalEmission(result[currIndex].totalEmission);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const Details = await getCurrentOrganization();
            if (!isNil(Details)) {
                const emission = await getOrganizationEmissionResultOurNature(Details.data[0].id);
                if (!isNil(emission)) {
                    let data = emission.data.sort((a, b) => {
                        if (a.year > b.year) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    setResult(data);
                    setYear(data[0].year);
                    setScope1(data[0].scope1);
                    setScope2(data[0].scope2);
                    setScope3(data[0].scope3);
                    setTotalEmission(data[0].totalEmission);
                }
                setCompany(Details.data[0].organizationName);
                setSector(Details.data[0].sector);
            }
            setOrgDetails(Details);
        };
        fetchData();
    }, []);

    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Partner profile<br/>& account</>}
                image={HeroImage}
            />
            <FootprintMain>
                <Title>
                    {' '}
                    <FormattedMessage id="OurNaturePage.Footprint.Title" />{' '}
                </Title>
                {result.length > 0 &&
                    (result.length === 1 ? (
                        <Navigation>
                            <Date className="date">{year}</Date>
                        </Navigation>
                    ) : (
                        year && (
                            <Navigation>
                                <LeftArrow
                                    className={index < result.length - 1 ? 'visible' : 'invisible'}
                                    onClick={handleDateLeft}
                                />
                                <Date className="date">{year}</Date>
                                <RightArrow
                                    className={index > 0 ? 'visible' : 'invisible'}
                                    onClick={handleDateRight}
                                />
                            </Navigation>
                        )
                    ))}
                {totalEmission &&
                totalEmission >= 0 &&
                scope1 && scope1 >= 0 &&
                scope2 && scope2 >= 0 &&
                scope3 && scope3 >= 0 &&
                year &&
                company &&
                sector ? (
                    <ResultContainer>
                        <Details>
                            <Content>
                                <Heading>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company" />
                                </Heading>
                                <div>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.Name" /> :{' '}
                                    <span>{company} </span>
                                </div>
                                <div>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.Year" /> :{' '}
                                    <span>{year}</span>
                                </div>
                                <div>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.Category" />{' '}
                                    : <span>{sector}</span>
                                </div>
                            </Content>
                            {/* <Content>
                                <Heading>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.Summary.Title" />
                                </Heading>
                                <div><FormattedMessage id="OurNaturePage.Footprint.Company.Summary.Desc" /></div>
                            </Content> */}
                        </Details>
                        <EmissionContainer>
                            <EmissionHeading>
                                <FormattedMessage id="OurNaturePage.Footprint.Company.Emission" />
                            </EmissionHeading>
                            <EmissionValue>{(totalEmission / 1000).toFixed(2)} tCO2e</EmissionValue>
                        </EmissionContainer>
                        <LogoContainer>
                            <img
                                src={CalculatorResult}
                                alt="result logo"
                                width="100%"
                                height="100%"
                            />
                        </LogoContainer>
                        <EmissionResult>
                            <EmissionContent>
                                <EmissionHeading>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.DirectEmission" />
                                </EmissionHeading>
                                <EmissionScopeValue>
                                    {(scope1 / 1000).toFixed(2)} tCO2e
                                </EmissionScopeValue>
                                <EmissionPercent>
                                    <RedLogo>
                                        <img
                                            src={Logo}
                                            alt="result logo"
                                            width="100%"
                                            height="100%"
                                        />
                                    </RedLogo>
                                    {Math.round((scope1 / totalEmission) * 100)}%
                                </EmissionPercent>
                            </EmissionContent>
                            <EmissionContent>
                                <EmissionHeading>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.IndirectEmission" />
                                </EmissionHeading>
                                <EmissionScopeValue>
                                    {(scope2 / 1000).toFixed(2)} tCO2e
                                </EmissionScopeValue>
                                <EmissionPercent>
                                    <RedLogo>
                                        <img
                                            src={Logo}
                                            alt="result logo"
                                            width="100%"
                                            height="100%"
                                        />
                                    </RedLogo>
                                    {Math.round((scope2 / totalEmission) * 100)}%
                                </EmissionPercent>
                            </EmissionContent>
                            <EmissionContent>
                                <EmissionHeading>
                                    <FormattedMessage id="OurNaturePage.Footprint.Company.OtherEmission" />
                                </EmissionHeading>
                                <EmissionScopeValue>
                                    {(scope3 / 1000).toFixed(2)} tCO2e
                                </EmissionScopeValue>
                                <EmissionPercent>
                                    <RedLogo>
                                        <img
                                            src={Logo}
                                            alt="result logo"
                                            width="100%"
                                            height="100%"
                                        />
                                    </RedLogo>
                                    {Math.round((scope3 / totalEmission) * 100)}%
                                </EmissionPercent>
                            </EmissionContent>
                        </EmissionResult>
                    </ResultContainer>
                ) : (
                    <NoData>
                        <GrayText empty>
                            {' '}
                            <FormattedMessage id="OurNaturePage.Footprint.NoRecord" />{' '}
                        </GrayText>
                        <ButtonContainer>
                            <Button variant="filled" size="lg" path="/organisation/calculator">
                                <FormattedMessage id="OurNaturePage.Footprint.Calculate" />
                            </Button>
                        </ButtonContainer>
                    </NoData>
                )}
                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </FootprintMain>
            <RightNav />
        </Frame>
    );
});
export default OurNatureFootprintPage;
