import React from 'react';
import ReactMarkdown from 'react-markdown';
import { LikeListing, UserCard } from '../../components';
import css from './ListingPage.css';

const ListingStorieContent = props => {
    const { content, title, currentListing, onContactUser, currentUser } = props;

    return (
        <React.Fragment>
            <div>
                <h1 className={`${css.titledark} ${css.title}`}>{title}</h1>
                <ReactMarkdown source={content} escapeHtml={false} />
            </div>
            <hr className={css.storyseparator} />
            <LikeListing
                className={css.storyfavoritecoutner}
                id={currentListing.slug}
                points={currentListing.greenPrintCount || 0}
            />
            <UserCard
                userDetails={currentListing.userDetails}
                currentUser={currentUser}
                onContactUser={onContactUser}
            />
        </React.Fragment>
    );
};

export default ListingStorieContent;
