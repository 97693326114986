import React, { useState, useRef, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setPlanTarget } from '../../../ducks/subscriptionReducer.duck';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import * as coreAPI from '../../../coreApi';

import { isNil } from 'lodash';
import { fetchCurrentUser } from '../../../ducks/user.duck';
import ReCaptcha from 'react-google-recaptcha';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';
import { GoogleLogin } from 'react-google-login';
import {
    login,
    signInWithGoogle,
    authenticationInProgress,
    signup,
    savereferer,
    signUpWithGoogle,
} from '../../../ducks/Auth.duck';
import { isSignupEmailTakenError } from '../../../util/errors';

import { activateRole, changeRole, updateAccount } from '../../../coreApi';

import CheckBoxInfo from '../../../newComponents/checkbox';
import CustomRadioSelect from '../../../newComponents/SelectRadioDropdown';
import TextField from '../../../newComponents/Form/TextField';
import { OrangeBtn } from '../../../newComponents/Button/ButtonSkin';
import { signupInitialValues } from './SignupInitialValues';
import { signupValScheme } from './SignupValScheme';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StepForm from '../StepForm';

const StepOneSection = styled.section`
    max-width: 440px;
    .form-section {
        gap: 16px;
        padding: 0 14px;
    }
    .googleSignUp {
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    .googleSignUp h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    .googleSignUp > button {
        width: 100%;
        border-radius: 15px;
    }
    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 20px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .login-cta {
            line-height: 150%;
            margin-top: 27px;
            a {
                font-weight: 600;
                color: ${({ theme }) => theme.colors.title_green};
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-section {
            gap: 24px;
        }
    }
`;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;
const Signup = props => {
    const {
        isAuthenticated,
        loginError,
        signupError,
        intl,
        currentUser,
        planTarget,
        location,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [showError, setShowError] = useState(true);
    const [regularAccError, setRegularAccError] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    // Track if the referer value was selected by the user during the authenticated phase
    const [refererSelected, setRefererSelected] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const togglepassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const reCaptchaRefSignup = useRef(null);
    const reCaptchaRefSignupNewsletter = useRef(null);
    const formikRef = useRef(); // Create a ref to access Formik's methods

    const consentLabel = intl.formatMessage({
        id: 'SignupForm.NewsletterForm.Consent',
    });

    const optionsReferer = [
        { name: 'referer', value: <FormattedMessage id="SignupForm.referer.hear" /> },
        { name: 'friends-family', value: <FormattedMessage id="SignupForm.referer.friends" /> },
        { name: 'google', value: <FormattedMessage id="SignupForm.referer.google" /> },
        { name: 'news-media', value: <FormattedMessage id="SignupForm.referer.news" /> },
    ];

    const optionsPlanTarget = [
        { name: 'planTarget', value: <FormattedMessage id="SignupForm.planTarget.whom" /> },
        { name: 'personas', value: <FormattedMessage id="SignupForm.planTarget.myself" /> },
        { name: 'familia', value: <FormattedMessage id="SignupForm.planTarget.family" /> },
        { name: 'empresa', value: <FormattedMessage id="SignupForm.planTarget.organisation" /> },
    ];

    const validationSchema = signupValScheme;

    const onSubmit = async (values, actions) => {
        const { planTarget, ...restValues } = values;

        try {
            setLoading(true);
            setShowError(false);

            if (isChecked) {
                await checkCaptchaNewsLetter(restValues);
            }

            let captchaToken;
            if (reCaptchaRefSignup.current) {
                captchaToken = await reCaptchaRefSignup.current.executeAsync();
                reCaptchaRefSignup.current.reset();
            }

            if (!!captchaToken && !!restValues) {
                await props.submitSignup({ ...restValues, captchaToken });

                if (planTarget === 'empresa') {
                    const response = await activateRole('partner');
                    const authToken = response.data;
                    if (authToken) {
                        localStorage.setItem('authToken', authToken);
                    }
                }

                await props.fetchCurrentUser();
            } else {
                console.error('Failed to generate captchaToken');

                return; // Exit early if captchaToken is undefined
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setRegularAccError(true);
            setShowError(true);
        }
    };

    const onContinue = async values => {
        const { planTarget, referer } = values;

        try {
            setLoading(true);
            setShowError(false);

            if (!!referer) {
                // await props.saveReferer({ ...restValues });

                if (refererSelected) {
                    try {
                        await updateAccount({ referer });
                        console.log('Account updated with referer:', referer);
                    } catch (err) {
                        console.error('Failed to update account:', err);
                    }
                }

                if (planTarget === 'empresa') {
                    const isPartner = currentUser && currentUser.currentRole === 'partner';
                    const rolePresent =
                        currentUser && currentUser.activatedRoles.includes('partner');
                    if (currentUser && !rolePresent) {
                        const response = await activateRole('partner');
                        const authToken = response.data;
                        if (authToken) {
                            localStorage.setItem('authToken', authToken);
                        }
                    } else if (currentUser && !isPartner) {
                        const response = await changeRole('partner');
                        const authToken = response.data;
                        if (authToken) {
                            localStorage.setItem('authToken', authToken);
                        }
                    } else {
                    }
                } else {
                    const isFriend = currentUser && currentUser.currentRole === 'friend';

                    if (currentUser && !isFriend) {
                        const response = await changeRole('friend');
                        const authToken = response.data;
                        if (authToken) {
                            localStorage.setItem('authToken', authToken);
                        }
                    }
                }

                await props.fetchCurrentUser();
                dispatch(setPlanTarget(planTarget));
                history.push('/subscription/confirm-plan');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setRegularAccError(true);
            setShowError(true);
        }
    };
    const checkCaptchaNewsLetter = async restValues => {
        let captchaToken;
        if (reCaptchaRefSignupNewsletter.current) {
            captchaToken = await reCaptchaRefSignupNewsletter.current.executeAsync();
            reCaptchaRefSignupNewsletter.current.reset();
        }

        if (!!captchaToken && !!restValues) {
            try {
                await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: restValues.firstName,
                        lastname: restValues.lastName,
                        email: restValues.email,
                        language: intl.locale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const responseGoogle = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRefSignup.current) {
                    captchaToken = await reCaptchaRefSignup.current.executeAsync();
                    reCaptchaRefSignup.current.reset();
                }
                await props
                    .submitSignupWithGoogle({
                        tokenId: response.tokenId,
                        captchaToken,
                    })
                    .then(async () => {
                        await activateRole('partner').then(async response => {
                            const authToken = response.data;
                            if (!isNil(authToken)) {
                                localStorage.setItem('authToken', authToken);
                                await props.fetchCurrentUser();
                            }
                        });
                    });
            } catch (error) {
                console.log({ 'Google Sign In Error': error });
            } finally {
                setRegularAccError(false);
                setShowError(true);
                //setScroll(true);
            }
        }
    };

    const currentUserDetails = isAuthenticated
        ? {
              firstName: (currentUser && currentUser.attributes.profile.firstName) || '',
              lastName: (currentUser && currentUser.attributes.profile.lastName) || '',
              email: (currentUser && currentUser.attributes.email) || '',
              password: 'dummytext@123',
              referer: (currentUser && currentUser.attributes.profile.referer) || '',
              planTarget: planTarget || '',
          }
        : {
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              referer: '',
              planTarget: planTarget || '',
          };
    const initialValues = { ...signupInitialValues, ...currentUserDetails };
    console.log('currentUserDetails', signupInitialValues, currentUserDetails, initialValues);

    // firstName
    const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
    });
    const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
    });

    // lastName
    const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
    });
    const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
    });

    const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
    });

    // password
    const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
    });
    const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
    });

    ///

    const termsLink = (
        <Link to="/terms-of-service" target="_blank">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </Link>
    );

    const privacyLink = (
        <Link to="/privacy-policy" target="_blank">
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </Link>
    );

    return (
        <StepForm step={1} isNextDisabled={!isFormValid} formikRef={formikRef}>
            <StepOneSection className={`mx-auto flex flex-col`}>
                <div className="form-title">
                    <FormattedMessage id="Subscription.StepForm.Step1.title" />
                    <div className="title-info text-center show-tab">
                        <FormattedMessage id="Subscription.StepForm.Step1.desc" />
                    </div>
                </div>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={isAuthenticated ? onContinue : onSubmit}
                    validateOnMount={true}
                >
                    {formik => {
                        setIsFormValid(formik.isValid);
                        return (
                            <Form onChange={() => setShowError(false)}>
                                <ReCaptcha
                                    ref={reCaptchaRefSignup}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    size="invisible"
                                />
                                <ReCaptcha
                                    ref={reCaptchaRefSignupNewsletter}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    size="invisible"
                                />
                                <div className="form-section flex flex-col">
                                    <div className="two-col flex space-between">
                                        <div className="input-box-section">
                                            <TextField
                                                type="text"
                                                label={firstNameLabel}
                                                name="firstName"
                                                placeholder={firstNamePlaceholder}
                                                aria-describedby="firstnamehelp"
                                                value={formik.values.firstName}
                                                className={`form-control  ${formik.touched
                                                    .firstName &&
                                                    formik.errors.firstName &&
                                                    'input-error'}`}
                                                disabled={isAuthenticated}
                                            />
                                        </div>
                                        <div className="input-box-section">
                                            <TextField
                                                type="text"
                                                label={lastNameLabel}
                                                name="lastName"
                                                placeholder={lastNamePlaceholder}
                                                value={formik.values.lastName}
                                                aria-describedby="lastnamehelp"
                                                className={`form-control  ${formik.touched
                                                    .lastName &&
                                                    formik.errors.lastName &&
                                                    'input-error'}`}
                                                disabled={isAuthenticated}
                                            />
                                        </div>
                                    </div>
                                    <div className="one-col">
                                        <div className="input-box-section">
                                            <TextField
                                                type="email"
                                                label={emailLabel}
                                                name="email"
                                                placeholder={emailPlaceholder}
                                                aria-describedby="emailhelp"
                                                className={`form-control  ${formik.touched.email &&
                                                    formik.errors.email &&
                                                    'input-error'}`}
                                                disabled={isAuthenticated}
                                            />
                                        </div>
                                    </div>
                                    {!isAuthenticated && (
                                        <div className="one-col">
                                            <div className="input-box-section">
                                                <div className="input-group relative">
                                                    <TextField
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={passwordLabel}
                                                        name="password"
                                                        placeholder={passwordPlaceholder}
                                                        aria-describedby="passwordhelp"
                                                        className={`form-control  ${formik.touched
                                                            .password &&
                                                            formik.errors.password &&
                                                            'input-error'}`}
                                                        disabled={isAuthenticated}
                                                    />
                                                    <span
                                                        onClick={togglepassword}
                                                        className="input-group-prepend absolute"
                                                    >
                                                        {showPassword ? (
                                                            <FaRegEye />
                                                        ) : (
                                                            <FaRegEyeSlash />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="one-col">
                                        <CustomRadioSelect
                                            name="referer"
                                            classname={`form-control ${
                                                formik.errors.referer ? 'input-error' : ''
                                            }`}
                                            optionsList={optionsReferer}
                                            value={formik.values.referer}
                                            onChange={value => {
                                                formik.setFieldValue('referer', value);
                                                if (
                                                    currentUserDetails.referer === '' &&
                                                    value !== ''
                                                ) {
                                                    setRefererSelected(true);
                                                }
                                            }}
                                            disabled={
                                                isAuthenticated && !!currentUserDetails.referer
                                            }
                                        />
                                        <ErrorMessage
                                            name="referer"
                                            component="span"
                                            className="error-text small"
                                        />
                                    </div>
                                    <div className="one-col">
                                        <CustomRadioSelect
                                            name="planTarget"
                                            optionsList={optionsPlanTarget}
                                            value={formik.values.planTarget}
                                            onChange={value =>
                                                formik.setFieldValue('planTarget', value)
                                            }
                                            disabled={false}
                                            classname={`form-control ${
                                                formik.errors.planTarget ? 'input-error' : ''
                                            }`}
                                        />
                                        <ErrorMessage
                                            name="planTarget"
                                            component="span"
                                            className="error-text small"
                                        />
                                    </div>
                                    {!isAuthenticated && (
                                        <div className="one-col">
                                            <CheckBoxInfo
                                                labelName={consentLabel}
                                                labelId="consent"
                                                checkboxSection="consent-box"
                                                isChecked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="cta mx-auto">
                                    <OrangeBtn
                                        type="submit"
                                        disabled={!formik.isValid}
                                        className={!formik.isValid ? 'disabled' : null}
                                    >
                                        {isAuthenticated ? (
                                            <FormattedMessage id="NewPartnersPage.Setup.Account.continue" />
                                        ) : (
                                            <FormattedMessage id="Subscription.StepForm.Signup.btn" />
                                        )}
                                    </OrangeBtn>
                                    {(signupError || loginError) && regularAccError && showError && (
                                        <div className="error-text small">
                                            {isSignupEmailTakenError(signupError) ? (
                                                <>
                                                    <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                                                </>
                                            ) : (
                                                <FormattedMessage id="AuthenticationPage.signupFailed" />
                                            )}
                                        </div>
                                    )}

                                    {!isAuthenticated && (
                                        <>
                                            <div className="googleSignUp">
                                                <h5>or</h5>
                                                {googleClientId && (
                                                    <GoogleLogin
                                                        className="login-w-google flex items-center justify-center"
                                                        clientId={googleClientId}
                                                        disabled={isAuthenticated}
                                                        buttonText={
                                                            <FormattedMessage id="NewPartnersPage.Setup.Account.GoogleBtn" />
                                                        }
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                    />
                                                )}
                                            </div>

                                            <div className="login-cta small">
                                                <FormattedMessage
                                                    id="SignupForm.termsAndConditionsAcceptText"
                                                    values={{ termsLink }}
                                                />
                                                <FormattedMessage
                                                    id="SignupForm.privacyPoliceAcceptText"
                                                    values={{ privacyLink }}
                                                />
                                            </div>

                                            <div className="login-cta small">
                                                <FormattedMessage id="SignupForm.AlreadyAccount" />{' '}
                                                <Link
                                                    to={{
                                                        pathname: '/login',
                                                        state: {
                                                            from:
                                                                location.pathname + location.search,
                                                        }, // Pass the current path as `from`
                                                    }}
                                                >
                                                    <FormattedMessage id="SignupForm.LoginTxt" />
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>

                <div className="step-nav flex mx-auto">
                    <div className="bullet current"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                </div>
            </StepOneSection>
        </StepForm>
    );
};

const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
    submitSignup: params => dispatch(signup(params)),
    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
    fetchCurrentUser: params => dispatch(fetchCurrentUser()),
});

const mapStateToProps = state => {
    const { isAuthenticated, loginError, signupError } = state.Auth;
    const { planTarget } = state.subscription;
    const { currentUser } = state.user;
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        signupError,
        planTarget,
    };
};

export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(Signup));
