import React from 'react';

import {CardImageContainer} from './Styles';

const CardImage = (props) =>{

    const {image } = props;
    return(
        <CardImageContainer>
            <img src={image} width="100%" height="100%"/>
        </CardImageContainer>
    )
}

export default CardImage;