import React from 'react';
import { FormattedMessage } from 'react-intl';
import businessImage from '../../../../assets/newAssets/b-corp-regener.png';
import businessImageMobile from '../../../../assets/newAssets/b-corp-regener-m.png';

import idb from '../../../../assets/newAssets/company-logo/idb.png';
import unrestoration from '../../../../assets/newAssets/company-logo/un-restoration-logo.png';
import ncc from '../../../../assets/newAssets/company-logo/natural-capital-coalition.png';
import peruco from '../../../../assets/newAssets/company-logo/peru_co.png';
import { BusinessContainer, ImageText } from './Styles';

const BusinessCertificate = () => {
    const backLogos = [
        { id: 1, img: `${idb}` },
        { id: 2, img: `${unrestoration}` },
        { id: 3, img: `${ncc}` },
        { id: 4, img: `${peruco}` },
    ];
    const cologoback = backLogos.map(backLogo => (
        <div key={backLogo.id} className="logo">
            <img src={backLogo.img} alt="backing company" />
        </div>
    ));
    const para = (
        <p>
            <FormattedMessage id="About.business.message" />
        </p>
    );

    return (
        <BusinessContainer>
            <ImageText className="corporation-section">
                <div className="flex items-center justify-space-between container business-container">
                    <picture className="img-box img-b-certified">
                        <source media="(max-width:768px)" srcset={businessImageMobile} />
                        <img src={businessImage} className="w-full" alt="" />
                    </picture>
                    <div className="content mx-auto">
                        <div className="title-box">
                            <div className="small-title">
                                <FormattedMessage id="About.business.bcorpCert" />
                            </div>
                            <h3>
                                <FormattedMessage id="About.business.bcorpTitle" />
                            </h3>
                        </div>
                        <div className="content-box">
                            {para}

                            <div className="group-backing">
                                <div className="title-back">
                                    <FormattedMessage id="About.business.backTitle" />
                                </div>
                                <div className="group-logo flex items-center">{cologoback}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ImageText>
        </BusinessContainer>
    );
};

export default BusinessCertificate;
