import {
    saveUserInterestForGuardian,
    getUserInterestForGuardian,
    getGuardianSubRoles,
} from '../../../coreApi';

export const saveUserInterestForGuardianRole = async (guardianSubRoleId, setSubmitting) => {
    setSubmitting(true);
    try {
        await saveUserInterestForGuardian(guardianSubRoleId);
        return {
            status: 'success',
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
        setSubmitting(false);
    }
};

export const getUserInterest = async setSubmitting => {
    setSubmitting(true);
    try {
        const result = await getUserInterestForGuardian();
        return {
            status: 'success',
            subRoleId: result.data.guardianSubRoleId,
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
        setSubmitting(false);
    }
};

export const getGuardianRoles = async () => {
    try {
        const result = await getGuardianSubRoles();
        return result.data;
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    }
};
