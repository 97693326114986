import * as coreAPI from '../coreApi';

let exportSdk;

const isServer = () => typeof window === 'undefined';


if (isServer()) {
  // Use eval to skip webpack from bundling SDK in Node
  // eslint-disable-next-line no-eval
  exportSdk = eval('require')('sharetribe-flex-sdk');
} else {
  exportSdk = coreAPI.coreAPISDK
  // exportSdk = { types: { UUID: sdkTypes.UUID, 
  //   LatLng: sdkTypes.LatLng,
  //   LatLngBounds: sdkTypes.LatLngBounds,
  //    Money: sdkTypes.Money
  //   },
  //   createInstance: () => {}
  // };
}

const { createInstance, types } = exportSdk;

export { createInstance, types };
