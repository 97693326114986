import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
    FaFacebookSquare,
    FaInstagram,
    FaLinkedin,
    FaMedium,
    FaWhatsappSquare,
} from 'react-icons/fa';
import blogo from '../../assets/b-certified.svg';
import natureLogo from '../../assets/nature-logo.png';

import innovate from '../../assets/innovate.png';
import ministerio from '../../assets/ministerio.png';
import ncc from '../../assets/ncc.png';
import sistemab from '../../assets/sistemab.png';
import tropforest from '../../assets/trop-forest.png';
import peru2021 from '../../assets/peru2021.jpg';
import perubgc from '../../assets/Peru-GBC-Gris.jpg';
import { IntlContext } from '../../IntlContext';

import * as coreAPI from '../../coreApi';
import ReCAPTCHA from 'react-google-recaptcha';

import {
    IconSocialMediaFacebook,
    IconSocialMediaInstagram,
    IconSocialMediaTwitter,
    Logo,
    PrimaryButton,
    ExternalLink,
    NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
    const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
    const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
    const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

    const fbLink = siteFacebookPage ? (
        <ExternalLink
            key="linkToFacebook"
            href={siteFacebookPage}
            className={css.icon}
            title={goToFb}
        >
            <IconSocialMediaFacebook />
        </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
        <ExternalLink
            key="linkToTwitter"
            href={siteTwitterPage}
            className={css.icon}
            title={goToTwitter}
        >
            <IconSocialMediaTwitter />
        </ExternalLink>
    ) : null;

    const instragramLink = siteInstagramPage ? (
        <ExternalLink
            key="linkToInstagram"
            href={siteInstagramPage}
            className={css.icon}
            title={goToInsta}
        >
            <IconSocialMediaInstagram />
        </ExternalLink>
    ) : null;
    return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
    const { rootClassName, className, intl } = props;
    const classes = classNames(rootClassName || css.root, className);
    const [messageStatus, setmessageStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({ firstname: '', lastname: '', email: '' });
    const [submitTry, setSubmitTry] = useState(false);
    const [errors, setErrors] = useState({ firstname: true, lastname: true, email: true });

    const { switchToEnglish, switchToSpanish, switchToPortuguese } = React.useContext(IntlContext);

    const currentLocale = intl.locale;

    const handleChange = e => {
        const target = e.target;
        setForm({ ...form, [target.name]: target.value });
    };

    useEffect(() => {
        setErrors({
            firstname: form.firstname == '',
            lastname: form.lastname == '',
            email: !/.+@.+\.[A-Za-z]+$/.test(form.email),
        });
    }, [form]);

    const reCaptchaRef = React.createRef();

    const checkEmailSubscriptionCaptcha = async captchaToken => {
        console.log('Token', captchaToken);
        if (!!captchaToken && form.firstname !== '' && form.lastname !== '' && form.email !== '') {
            try {
                await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: form.firstname,
                        lastname: form.lastname,
                        email: form.email,
                        language: currentLocale,
                        statusIfNew: "subscribed",
                        status: "subscribed",
                    },
                    captchaToken
                );
                setmessageStatus('sended');
                setIsLoading(false);
            } catch (e) {
                setmessageStatus('error');
                setIsLoading(false);
            }
        }
    };

    const handleNewsletter = async e => {
        e.preventDefault();
        setSubmitTry(true);
        if (!errors.firstname && !errors.lastname && !errors.email) {
            setIsLoading(true);
            reCaptchaRef.current.execute();
            try {
                await coreAPI.subscribeToNewsLetter({
                    firstname: form.firstname,
                    lastname: form.lastname,
                    email: form.email,
                    language: currentLocale,
                    statusIfNew: "subscribed",
                    status: "subscribed"
                });
                setmessageStatus('sended');
                setIsLoading(false);
            } catch (e) {
                setmessageStatus('error');
                setIsLoading(false);
            }
        } else {
        }
    };

    const whatsAppLink = process.env.REACT_APP_WHATSAPP_LINK || 'https://wa.me/';

    return (
        <div className={classes}>
            {!props.calculatorFlag ? (
                <div className={css.logosContainer}>
                    <h2 className={css.alliesTitle}>
                        <FormattedMessage id="Footer.allianceTitle" />
                    </h2>
                    <div className={css.allies}>
                        <img className={css.alliesPic} src={ncc} />
                        <img className={css.alliesPic} src={tropforest} />
                        <img className={css.alliesPic} src={sistemab} />
                        <img className={css.alliesPic} src={innovate} />
                        <img className={css.alliesPic} src={ministerio} />
                        <img className={css.alliesPic} src={peru2021} />
                        <img className={css.alliesPic} src={perubgc} />
                    </div>
                </div>
            ) : null}
            <div className={css.topBorderWrapper}>
                <div className={css.content}>
                    <div className={css.links}>
                        <div className={css.organization} id="organization">
                            <NamedLink name="LandingPage" className={css.logoLink}>
                                <Logo format="desktop" className={css.logo} />
                            </NamedLink>
                            <div className={css.organizationInfo}>
                                <p>
                                    <FormattedMessage id="Footer.organizationInfoTitle" />
                                </p>

                                <div className={css.bLogoContainer}>
                                    <div className={css.bLogoContainerPng}>
                                        <ExternalLink href="https://www.natureservicesperu.com/">
                                            <img className={css.logosFooter} src={natureLogo} />
                                        </ExternalLink>
                                        <img className={css.logosFooter} src={blogo} />
                                    </div>
                                    <p className={css.bLogoText}>
                                        <FormattedMessage id="Footer.organizationInfoCertificate" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={css.infoLinks}>
                            <p className={css.titleColumn}>
                                <strong>
                                    <FormattedMessage id="Footer.faq.Title" />
                                </strong>
                            </p>
                            <ul>
                                <li className={css.infoLinksItem}>
                                    <NamedLink
                                        name="FaqsPage"
                                        className={css.infoLinksItem}
                                        to={{ hash: 'huellaambiental' }}
                                    >
                                        <FormattedMessage id="Footer.faq.huellaambiental" />
                                    </NamedLink>
                                </li>
                                <li className={css.infoLinksItem}>
                                    <NamedLink
                                        name="FaqsPage"
                                        className={css.infoLinksItem}
                                        to={{ hash: 'serviciosnaturaleza' }}
                                    >
                                        <FormattedMessage id="Footer.faq.serviciosnaturaleza" />
                                    </NamedLink>
                                </li>
                                <li className={css.infoLinksItem}>
                                    <NamedLink
                                        name="FaqsPage"
                                        className={css.infoLinksItem}
                                        to={{ hash: 'reducirhuella' }}
                                    >
                                        <FormattedMessage id="Footer.faq.reducirhuella" />
                                    </NamedLink>
                                </li>
                                <li className={css.infoLinksItem}>
                                    <NamedLink
                                        name="FaqsPage"
                                        className={css.infoLinksItem}
                                        to={{ hash: 'trasnformasHuella' }}
                                    >
                                        <FormattedMessage id="Footer.faq.trasnformasHuella" />
                                    </NamedLink>
                                </li>
                                <li className={css.infoLinksItem}>
                                    <NamedLink
                                        name="FaqsPage"
                                        className={css.infoLinksItem}
                                        to={{ hash: 'comofunciona' }}
                                    >
                                        <FormattedMessage id="Footer.faq.comofunciona" />
                                    </NamedLink>
                                </li>
                                <li className={css.infoLinksItem}>
                                    <NamedLink name="FaqsPage" className={css.infoLinksItem}>
                                        <FormattedMessage id="Footer.faq.seeAll" />
                                    </NamedLink>
                                </li>
                            </ul>
                            <div>
                                <FormattedMessage id="Footer.language.changeTitle" />
                                {currentLocale != 'es' ? (
                                    <a
                                        onClick={e => {
                                            switchToSpanish();
                                        }}
                                    >
                                        {' '}
                                        <FormattedMessage id="Footer.language.spanish" />{' '}
                                    </a>
                                ) : (
                                    ''
                                )}
                                {currentLocale != 'en' ? (
                                    <a
                                        onClick={e => {
                                            switchToEnglish();
                                        }}
                                    >
                                        {' '}
                                        <FormattedMessage id="Footer.language.english" />{' '}
                                    </a>
                                ) : (
                                    ''
                                )}
                                {currentLocale != 'pt' ? (
                                    <a
                                        onClick={e => {
                                            switchToPortuguese();
                                        }}
                                    >
                                        {' '}
                                        <FormattedMessage id="Footer.language.portuguese" />{' '}
                                    </a>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={css.searches}>
                            <p className={css.titleColumn}>
                                <strong>
                                    <FormattedMessage id="Footer.socialMediaFollow" />
                                </strong>
                            </p>
                            <div className={css.socialIconWrapper}>
                                <ExternalLink href="https://www.facebook.com/peru.regenera/">
                                    <FaFacebookSquare className={css.socialIcon} />
                                </ExternalLink>
                                <ExternalLink href="https://www.instagram.com/peru.regenera/?hl=es-la">
                                    <FaInstagram className={css.socialIcon} />
                                </ExternalLink>
                                <ExternalLink href="https://www.linkedin.com/showcase/regenera-peru/">
                                    <FaLinkedin className={css.socialIcon} />
                                </ExternalLink>
                                <ExternalLink href="https://medium.com/regenera">
                                    <FaMedium className={css.socialIcon} />
                                </ExternalLink>
                                <ExternalLink href={whatsAppLink}>
                                    <FaWhatsappSquare className={css.socialIcon} />
                                </ExternalLink>
                            </div>
                            <p>
                                <strong>
                                    <FormattedMessage id="Footer.contact" />
                                </strong>
                            </p>
                            <p>Av. El Sol 627-B. Of. 404 Cusco - Perú</p>
                            <a
                                className={css.mailLink}
                                href={`mailto:${process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}`}
                            >
                                {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}
                            </a>
                            <ReCAPTCHA
                                ref={reCaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                onChange={checkEmailSubscriptionCaptcha}
                            />
                            <form>
                                {!messageStatus ? (
                                    <React.Fragment>
                                        <label>
                                            <FormattedMessage id="Footer.newsLetter.subscribeTitle" />
                                        </label>
                                        <div className={css.fieldContainer}>
                                            <input
                                                onChange={handleChange}
                                                name="firstname"
                                                value={form.firstname}
                                                className={css.suscriptionInput}
                                                placeholder={intl.formatMessage({
                                                    id: 'Footer.newsLetter.name.placeHolder',
                                                })}
                                            />
                                            {errors.firstname && submitTry ? (
                                                <span className={css.errorlabel}>
                                                    <FormattedMessage id="Footer.newsLetter.name.errorText" />
                                                </span>
                                            ) : null}
                                        </div>
                                        <div className={css.fieldContainer}>
                                            <input
                                                onChange={handleChange}
                                                name="lastname"
                                                value={form.lastname}
                                                className={css.suscriptionInput}
                                                placeholder={intl.formatMessage({
                                                    id: 'Footer.newsLetter.name.placeHolder',
                                                })}
                                            />
                                            {errors.lastname && submitTry ? (
                                                <span className={css.errorlabel}>
                                                    <FormattedMessage id="Footer.newsLetter.name.errorText" />
                                                </span>
                                            ) : null}
                                        </div>
                                        <div className={css.fieldContainer}>
                                            <input
                                                onChange={handleChange}
                                                name="email"
                                                value={form.email}
                                                className={css.suscriptionInput}
                                                placeholder={intl.formatMessage({
                                                    id: 'Footer.newsLetter.email.placeHolder',
                                                })}
                                            />
                                            {errors.email && submitTry ? (
                                                <span className={css.errorlabel}>
                                                    <FormattedMessage id="Footer.newsLetter.email.errorText" />
                                                </span>
                                            ) : null}
                                        </div>
                                        <PrimaryButton
                                            disabled={isLoading}
                                            inProgress={isLoading}
                                            onClick={handleNewsletter}
                                        >
                                            {isLoading ? (
                                                <FormattedMessage id="Footer.newsLetter.subscribeButton.inProgress" />
                                            ) : (
                                                <FormattedMessage id="Footer.newsLetter.subscribeButton.text" />
                                            )}
                                        </PrimaryButton>
                                    </React.Fragment>
                                ) : null}
                                {messageStatus === 'sended' ? (
                                    <p>
                                        <FormattedMessage id="Footer.newsLetter.subscription.success" />
                                    </p>
                                ) : null}
                                {messageStatus === 'error' ? (
                                    <p>
                                        <FormattedMessage id="Footer.newsLetter.subscription.failed" />
                                    </p>
                                ) : null}
                            </form>
                        </div>
                    </div>
                    <div className={css.legalContainer}>
                        <NamedLink className={css.legalTextLink} name="PrivacyPolicyPage">
                            <FormattedMessage id="Footer.privacyPolicy" />
                        </NamedLink>
                        <NamedLink className={css.legalTextLink} name="TermsOfServicePage">
                            <FormattedMessage id="Footer.termsOfService" />
                        </NamedLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.defaultProps = {
    rootClassName: null,
    className: null,
    calculatorFlag: false,
};

Footer.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
};

export default injectIntl(Footer);
