import { FormattedMessage } from 'react-intl';
import React from 'react';

export const PlanPaymentInfo = {
    semillaEmpresas: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewPartnersPage.Setup.ChoosePlan.paymentInfo.seed"
        />
    ),
    arbolEmpresas: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewPartnersPage.Setup.ChoosePlan.paymentInfo.tree"
        />
    ),
    bosqueEmpresas: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewPartnersPage.Setup.ChoosePlan.paymentInfo.forest"
        />
    ),
};
