export const applicableLandscapes = (allPlans, planType) => {
    return allPlans.filter(allPlan => allPlan.plans.map(plan => plan.planType).includes(planType));
};

export const extractPlanID = (allPlans, planType, landScapeID) => {
    const getLandScapeDetail = allPlans.find(allPlan => allPlan.id === Number(landScapeID));

    const planDetails =
        getLandScapeDetail &&
        getLandScapeDetail.plans.find(
            plan => plan.planType.toLowerCase() === planType && plan.isActive
        );
    return planDetails && planDetails.planId;
};
