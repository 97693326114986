import styled from 'styled-components';

export const BusinessContainer = styled.section`
    .corporation-section {
        background-color: #f8fbf6;
        padding: 110px 4.8rem;
        max-width: 1920px;
    }

    .corporation-section {
        background-color: white;
    }

    .business-container {
        margin: 0 auto;
        max-width: 1065px;
        background-color: white;
        &::before {
            content: none;
            background-size: 100% auto;
            width: 104px;
            height: 178px;
            position: absolute;
            right: 0;
            top: -60px;
        }
        .img-b-certified {
            order: 1;
            min-width: 414px;
            box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
        }
        .logo {
            height: 38px;
            img {
                height: 100%;
                width: auto;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .corporation-section {
            padding: 24px 20px;
        }
        .corporation-section {
            padding-bottom: 40px;
            .img-b-certified {
                order: 0;
                min-width: auto;
            }
            .content {
                .content-box {
                    p {
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
`;

export const ImageText = styled.section`
    position: relative;
    margin: 0 auto;
    .container {
        gap: 128px;
        .content {
            max-width: 1200px;
            .title-box {
                margin-bottom: 16px;
                .small-title {
                    font-size: 19px;
                    line-height: 180%;
                    letter-spacing: 3.5px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.colors.light_green};
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }
            }
            .content-box {
                p {
                    margin-top: 25px;
                    color: ${({ theme }) => theme.colors.text_color};
                }

                .group-backing {
                    margin-top: 35px;
                    .title-back {
                        font-size: 19px;
                        line-height: 27px;
                        color: ${({ theme }) => theme.colors.text_color};
                        margin-bottom: 20px;
                        font-weight: 600;
                    }
                    .group-logo {
                        filter: grayscale();
                        gap: 24px;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .container {
            flex-direction: column;
            gap: 24px;
            .content {
                .title-box {
                    margin-bottom: 8px;
                    .small-title {
                        font-size: 17px;
                        line-height: 180%;
                    }
                }
                .content-box {
                    .group-backing {
                        margin-top: 24px;
                        .title-back {
                            font-size: 17px;
                            line-height: 24px;
                            margin-bottom: 8px;
                        }
                        .group-logo {
                            gap: 20px;
                        }
                    }
                }
            }
        }
    }
`;
