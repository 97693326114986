import React from 'react';
import { ExternalLink } from '../../components';
import { StaticPage } from '../../containers';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { OrangeBtn, LightGreenBtn } from '../../newComponents/Button/ButtonSkin';

import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import imageD from '../../assets/newAssets/icons/blog-title-line-d.svg';
import imageM from '../../assets/newAssets/icons/blog-title-line-m.svg';
import { TitleContainer, Center, FaqFlow, HelpBoxContainer } from './Styles';

const EnglishFaq = ({ location, history, user }) => {
    return (
        <StaticPage
            title="Frequently Asked Questions"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AboutPage',
                description: 'What is the environmental footprint?',
                name: 'Frequently Asked Questions',
            }}
        >
            <Header currentPath={location.pathname} user={user} history={history} />

            <TitleContainer
                className="flex justify-center relative"
                bgGreenTexture={bgGreenTexture}
            >
                <Center className="mx-auto relative">
                    <div className="title-box text-center">
                        <h1>
                            <span className="border">
                                Frequently asked questions
                                <picture>
                                    <source media="(max-width:768px)" srcset={imageM} />
                                    <img
                                        src={imageD}
                                        className="w-auto"
                                        alt="Frequently asked questions"
                                    />
                                </picture>
                            </span>
                        </h1>
                    </div>
                </Center>
            </TitleContainer>
            <FaqFlow>
                <div className="faq-wrap flex mx-auto">
                    <div id="huellaambiental">
                        <section className="sectionContent">
                            <h2> What is the environmental footprint? </h2>

                            <p>
                                It is the effect that each person, organization or product has
                                throughout its life cycle. For example, in a person, the
                                environmental footprint is the sum of the energy they consume, the
                                amount of CO2 emissions they generate, the water they consume, the
                                waste they produce, among others. In the case of a product, such as
                                a hamburger, its environmental footprint is all that nature needs to
                                produce it: water, livestock feed, energy, production and
                                transportation.
                            </p>
                            <p>
                                According to the type of resource, there are different types of
                                footprints and how to measure them:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h3> Carbon footprint: </h3>
                                    <p>
                                        It measures the amount of CO2 emissions that we make into
                                        the atmosphere as part of our daily activities or in
                                        production processes. It is measured in tons of carbon
                                        dioxide equivalent (tCO2eq). If you want to know your carbon
                                        footprint, you can visit these calculators:
                                    </p>
                                    <ul className={'list'}>
                                        <li>
                                            <ExternalLink
                                                href="https://libelula.com.pe/descubre/"
                                                className="green-link flex items-center"
                                            >
                                                https://libelula.com.pe/descubre/
                                            </ExternalLink>
                                        </li>
                                        <li>
                                            <ExternalLink
                                                href="https://calculator.carbonfootprint.com"
                                                className="green-link flex items-center"
                                            >
                                                https://calculator.carbonfootprint.com
                                            </ExternalLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3> Water footprint: </h3>
                                    <p>
                                        It refers to the amount of fresh water that we consume in
                                        our daily life (direct use), as well as the amount needed to
                                        produce the goods that we consume (indirect use). It can be
                                        expressed in liters of water per day for people and in cubic
                                        meters for companies and organizations. If you want to know
                                        your water footprint you can visit this calculator from the
                                        National Water Authority (ANA):
                                    </p>
                                </li>
                                <li>
                                    <h3> Solid waste footprint: </h3>
                                    <p>
                                        It is the amount of organic and inorganic waste that you
                                        produce per day as part of your activities. It is expressed
                                        in kilos per day. In Peru, an average person produces a kilo
                                        of waste every day.
                                        <ExternalLink
                                            href="http://www.vidasostenible.org/ciudadanos/mide-tu-huella-ecologica/encuesta-del-residuos-y-materiales/"
                                            className="green-link flex items-center"
                                        >
                                            On this page you can calculate the amount of waste you
                                            produce
                                        </ExternalLink>
                                    </p>
                                </li>
                                <li>
                                    <h3> Ecological footprint: </h3>
                                    <p>
                                        It is a natural resource accounting tool based on a
                                        comprehensive approach. Compare the demand for natural
                                        resources of our society with the capacity of nature to
                                        satisfy this demand; its objective is to show the ecological
                                        assets available and where they are being used. This tool is
                                        vital to address the risks of our current overuse of
                                        resources at the national and global level.
                                        <ExternalLink
                                            href="https://www.footprintcalculator.org/"
                                            className="green-link flex items-center"
                                        >
                                            On the Global Footprint Network page you can measure
                                            your ecological footprint
                                        </ExternalLink>
                                    </p>
                                </li>
                            </ul>
                            <p>
                                Each Footprint gives us indications about the effect of our customs
                                and behaviors on the resources and health of the planet. Measure
                                your footprint frequently with the different calculators and create
                                a record of how it evolves.
                            </p>
                        </section>
                    </div>

                    <div id="serviciosnaturaleza">
                        <section className={'sectionContent'}>
                            <h2> What are Nature services? </h2>
                            <p>
                                Let's imagine the Earth as a huge factory of goods and services that
                                we all use: clean water, clean air, stable land to live in, food,
                                resources for production, green spaces where we can relax, mountains
                                and snow-covered mountains that are part of the worldview of
                                indigenous populations. All of them - and others - are called
                                nature, environmental or ecosystem services.
                            </p>

                            <p>
                                Society and the economy would not exist without the goods and
                                services that natural ecosystems provide us. For example, if
                                ecosystems lost the ability to regulate the water cycle, or if bees
                                disappeared preventing the natural pollination of plants, then life
                                would become more difficult for those who inhabit it, even
                                triggering a global crisis at social and economical.
                            </p>

                            <p>
                                In 2005, the Millennium Ecosystem Assessment defined nature's
                                services as “the benefits that people obtain from ecosystems” and
                                proposed a classification for them: sustainers, regulators,
                                provisioners and cultural.
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong> Provision services </strong> are those that provide
                                        resources for direct use by living beings, such as water,
                                        food, wood, vegetable fibers, fuel, necessary for daily
                                        activities.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Regulation services </strong> are those that keep
                                        the planet and its resources in proper operation, such as
                                        climate control, water circulation and erosion control.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Cultural services </strong> are those that provide
                                        us with spiritual values, recreation, education. For
                                        example, a well-preserved landscape allows us to enjoy it in
                                        various activities such as tourism and sports, as well as
                                        being an essential part of the religion of many people.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Sustaining services </strong> are in charge of
                                        nutrient recycling, soil formation and photosynthesis. As
                                        their name indicates, they are those who are in charge of
                                        'sustaining' the basic functions of the planet.
                                    </h4>
                                </li>
                            </ul>
                            <p>
                                Despite its vital importance, our society and our economy do not
                                recognize the services that nature provides us and therefore most of
                                them are undergoing a process of degradation.
                            </p>
                            <p>
                                There are ways to make sure these services are maintained and nature
                                is restored. We call this compensation schemes or incentives for
                                ecosystem services. It is about forging alliances between service
                                users and those who maintain them through their conservation work
                                and sustainable production systems. The service provided by Regenera
                                is a form of compensation by offsetting the carbon footprint.
                            </p>
                        </section>
                    </div>

                    <div id="reducirhuella">
                        <section className={'sectionContent'}>
                            <h2> How can I reduce my environmental footprint? </h2>
                            <p>
                                There are many ways we can contribute to reducing our environmental
                                footprint. Some require a profound change in our habits, while
                                others require only a little order in our routine. In general terms,
                                we must review our consumption pattern and make it more efficient.
                            </p>

                            <p>
                                Here's a list of changes and actions you can take to give yourself a
                                smaller footprint:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h3> Eat less meat </h3>
                                    <p>
                                        It is difficult for many, but take into account that the
                                        meat industry generates almost a third of the global
                                        greenhouse gases. Leading a vegetarian or vegan life is the
                                        best option, but if you are not willing to stop eating meat,
                                        then try to reduce your consumption to once or twice a week,
                                        avoiding red meat, which are the ones that have the most
                                        negative environmental impact.
                                    </p>
                                </li>
                                <li>
                                    <h3>Save energy</h3>
                                    <p>
                                        Evaluate your energy consumption at home and at work. Use
                                        LED technology to illuminate, unplug the appliances that you
                                        are not using, avoid keeping the devices that require
                                        charging charged. And if it is within your means, acquire
                                        renewable sources of energy, such as solar panels. If you
                                        manage a large company, find out about renewable energy from
                                        your electricity supplier.
                                    </p>
                                </li>
                                <li>
                                    <h3> Move without engines </h3>
                                    <p>
                                        Saving fossil fuels is an important point in reducing our
                                        environmental footprint. If you have a car, use it
                                        responsibly, organize your daily schedule well to avoid
                                        unnecessary trips. Use natural gas if possible. Try to move
                                        by bicycle, walk more, use public transport. If you can't do
                                        without the car, organize a carpool, that is, move around in
                                        groups, thus saving fuel. If you are a frequent user of
                                        airplanes, try to reduce your flights as much as possible.
                                    </p>
                                </li>
                                <li>
                                    <h3> Consume responsibly </h3>
                                    <p>
                                        Part of the current problem is that our consumption patterns
                                        have exploded, causing companies to produce more and more.
                                        Make it your fashion to wear clothes and equipment until
                                        they run out, not change them if they still work well.
                                    </p>
                                </li>
                                <li>
                                    <h3> Watch what you eat </h3>
                                    <p>
                                        On average, a family wastes 30% of its food. Buy your food
                                        responsibly, cook what is necessary. Portioning your food
                                        will help you minimize leftovers. You will see how this
                                        positively impacts your economy (and the planet).
                                    </p>
                                </li>
                                <li>
                                    <h3>Buy local</h3>
                                    <p>
                                        Whenever possible, buy your food fresh, preferably from
                                        local producers. In many cities there is a wide range of
                                        fairs that sell organic products, explore your city in your
                                        search.
                                    </p>
                                </li>
                                <li>
                                    <h3>Eliminate single-use plastic</h3>
                                    <p>
                                        Bottles, straws, bags, kitchenware, eliminate them from your
                                        life. There are numerous alternatives on the market to
                                        supplant them. Make this practice a habit, it's easier than
                                        you think.
                                    </p>
                                </li>
                                <li>
                                    <h3>Recycle</h3>
                                    <p>
                                        Segregate your waste and recycle everything you can:
                                        plastic, paper, glass, batteries, electrical appliances,
                                        batteries, everything has a recycling point in your city. If
                                        you want to go a step further, compost your organic waste.
                                    </p>
                                </li>
                                <li>
                                    <h3>Take care of water</h3>
                                    <p>
                                        This is the essential element for life, and it is the one
                                        that will be scarce the most as the climate crisis advances.
                                        Take a shower only for 5 minutes, close the tap when you
                                        brush your teeth, when you wash your toilet, make your
                                        clothes washing system more efficient, constantly check your
                                        pipes and systems in your home and office for possible
                                        leaks.
                                    </p>
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="trasnformasHuella">
                        <section className={'sectionContent'}>
                            <h2>
                                How can I transform my environmental footprint into positive
                                actions?
                            </h2>
                            <p>
                                It is not enough to reduce our environmental footprint, it is also
                                essential to take concrete actions to have a net positive impact on
                                the planet. Here are some ideas to get started:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h3> Plant trees or help restore nature </h3>
                                    <p>
                                        Trees are the best mechanism for capturing carbon from the
                                        air. Search your locality for organizations that support the
                                        reforestation of degraded areas and participate as a
                                        volunteer. If you have enough space, plant trees in your
                                        garden, at the door of your house. Join your neighbors and
                                        transform your neighborhood or neighborhood with trees. In
                                        addition to capturing carbon, trees create a temperate
                                        microclimate in hot months, stabilize the soil, improve
                                        ornamentation, and raising them will give you great joy.
                                    </p>
                                </li>
                                <li>
                                    <h3> Take care of the sea </h3>
                                    <p>
                                        We know that the oceans - which cover 71% of the earth's
                                        surface - are threatened by pollution and global warming.
                                        When you visit the beach, do not litter and leave the space
                                        you occupied as clean as possible. Remember that the drains
                                        of the main cities end in the sea, many times without
                                        previous treatment, so think carefully about what you flush
                                        down the toilets and sinks. Used cooking oil is a strong
                                        contaminant. If you can, participate in beach cleaning
                                        campaigns, there are many active initiatives.
                                    </p>
                                </li>
                                <li>
                                    <h3>
                                        Support protected areas or protect nature in your locality
                                    </h3>
                                    <p>
                                        If you live close to protected natural areas you can be a
                                        volunteer park ranger or participate in the activities that
                                        the area's headquarters organize according to your skills or
                                        profession, such as surveillance, restoration, monitoring,
                                        among others. From the cities we can also support with
                                        volunteer work in specific campaigns and when we visit them
                                        as tourists respecting nature and the indications of the
                                        park rangers. If in your locality there are unprotected or
                                        degraded natural areas, you can join with your neighbors to
                                        support their care or restoration.
                                    </p>
                                </li>
                                <li>
                                    <h3>
                                        Choose one of Regenera's plans to support rural people to
                                        take care of their lands
                                    </h3>
                                    <p>
                                        All over the world, there are people who are working every
                                        day to protect and restore natural spaces - forests,
                                        wetlands, grasslands - that provide services to the
                                        watershed and the country where they are located. Regenera
                                        quantifies these services and connects these rural residents
                                        and communities with people and companies that want to
                                        recognize their efforts.
                                    </p>
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="comofunciona">
                        <section className={'sectionContent'}>
                            <h2> How does Regenera work? </h2>
                            <p>
                                At Regenera we work with urban partners to measure, reduce and
                                offset their environmental footprint, supporting rural guardians to
                                conserve and restore nature in Peru. Thus, we contribute to solving
                                the problem of ecosystem degradation and climate change, in line
                                with the policies and values ​​of our country. 80% of the
                                compensation payment is directed to our rural guardians and
                                technical allies, divided as follows:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong> 60% for communities and private owners </strong>{' '}
                                        that carry out conservation, protection and restoration work
                                        on their lands.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> 20% for technical partners, organizations </strong>
                                        that work hand in hand with guardians and guarantee
                                        compliance with the agreements.
                                    </h4>
                                </li>
                            </ul>

                            <h3> This is how we work with urban partners </h3>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong> Know: </strong> We help people, organizations and
                                        companies to measure their carbon footprint, either through
                                        an open calculator or a detailed inventory according to a
                                        measurement methodology appropriate to each case.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Improvement: </strong> We suggest ways to reduce
                                        emissions, and then offset the residual footprint with
                                        conservation and reforestation actions carried out by our
                                        guardians.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Share: </strong> We transfer 80% of compensation
                                        income, be it personal or business, to our rural guardians.
                                    </h4>
                                </li>
                            </ul>

                            <h3> This is how we work with rural guardians and technical allies </h3>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong> Know: </strong> Rural guardians - who have clear
                                        titles and rights over their territories - are advised to
                                        learn about carbon and other natural assets in their
                                        ecosystems.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Improvement: </strong> Organization, surveillance,
                                        restoration and sustainable production actions are
                                        supported. We establish an incentive scheme for the results
                                        of avoided deforestation and ecosystem restoration. We carry
                                        out a monthly and annual evaluation of the work, which is
                                        discussed and approved in your Community or Associates
                                        Assembly.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Share: </strong> The payment that the guardians
                                        receive is invested in: (1) payment of communal wages /
                                        wages, (2) communal priorities such as education, health and
                                        energy, and (3) family needs according to Assembly
                                        agreement.
                                    </h4>
                                </li>
                            </ul>

                            <h3>
                                This is how we align ourselves with the Sustainable Development
                                Goals (SDG)
                            </h3>
                            <p>
                                Working with Regenera is a concrete way of working towards the UN
                                Sustainable Development Goals, as we are aligned to achieve the
                                following goals:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong> End of poverty: </strong> We are giving value to
                                        renewable natural capital, an asset of poor rural
                                        populations.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Gender equality: </strong> We work with communities
                                        and organizations that promote gender equality.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Clean water and sanitation: </strong> We contribute
                                        to regulation and water security in various landscapes.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Non-polluting energy: </strong> We promote the use
                                        of renewable energy.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Decent work and economic growth: </strong> We
                                        create jobs for rural populations.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Action for the climate: </strong> We help to reduce
                                        emissions and capture carbon in ecosystems.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Underwater life: </strong> We protect rivers and
                                        lakes, and we have been initiating work in marine-coastal
                                        ecosystems.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Terrestrial life: </strong> We take care of more
                                        than 50,000 hectares under conservation and restoration
                                        agreements.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong> Partnerships for the SDGs: </strong> We promote
                                        community public-private partnerships.
                                    </h4>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </FaqFlow>
            <HelpBoxContainer bgGreenTexture={bgGreenTexture}>
                <div className="green-box flex justify-center flex-col mx-auto">
                    <div className="title-box text-center mx-auto">
                        <h3>Still need help?</h3>
                        <p>Contact a team member.</p>
                    </div>
                    <div className="btn-group flex justify-center flex-wrap mx-auto">
                        <OrangeBtn>
                            <Link to="#">Send an e-mail</Link>
                        </OrangeBtn>
                        <LightGreenBtn>
                            <Link to="#">Schedule a call</Link>
                        </LightGreenBtn>
                    </div>
                </div>
            </HelpBoxContainer>
            <Footer history={history} />
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(EnglishFaq));
