import styled from 'styled-components';

export const DocumentsTitle = styled.p`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-top: ${props => props.marginTop};
    margin-left: ${props => props.marginLeft};
    @media screen and (max-width: 1024px) {
        margin-top: 42px;
        margin-left: 55px;
    }
`;

export const DocumentsMain = styled.div`
    margin: 30px 35px 100px 35px;
    border-radius: 10px;
    background-color: white;
    color: #000000;
    @media screen and (max-width: 1024px) {
        margin: 35px 20px 90px 20px; 
    }
`;

export const DocumentsText = styled.p`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
    margin-top: 20px;
    margin-left: 60px;
    @media screen and (max-width: 1024px) {
        margin-top: 30px;
        margin-left: 35px;
    }
`;

export const DocumentsTableMain = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 35px 60px;
    padding: 0px;
    @media screen and (max-width: 1024px) {
        margin: 30px 35px;
    }
`;

export const DocumentsTableRow = styled.p`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 18px 36px;
    margin: 0px;
    border-bottom: 1px solid black;
`;

export const DocumentsTableItem = styled.p`
    width: ${props => props.width};
    margin: 0px;
    padding: 0px;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 31px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  
    @media screen and (max-width: 1024px) {
        font-size: 13px;
        line-height: 18px;
    }
`;