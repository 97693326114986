import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedLandscape, setPlanId } from '../../ducks/subscriptionReducer.duck';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LandscapeSlider from 'react-slick';
import { IoIosInformationCircle } from 'react-icons/io';
import mapIcon from '../../assets/newAssets/icons/map-icon.svg';
import cottonIcon from '../../assets/newAssets/icons/cotton-icon.svg';
import leafIcon from '../../assets/newAssets/icons/leaf-icon.svg';
import image34 from '../../assets/newAssets/image34.webp';
import image34m from '../../assets/newAssets/image34-m.webp';
import { applicableLandscapes, extractPlanID } from './helper';

import StepForm from './StepForm';
import CustomSelect from '../../newComponents/SelectDropdown/SelectDropdown';

import { fetchLandscapes } from '../../components/ChooseLandscape/fetchLandscapes';

const StepThreeSection = styled.section`
    margin-top: 60px;
    width: 100%;
    max-width: 630px;
    .fee-n-box {
        .fee {
            line-height: 180%;
        }
        .tooltip-box {
            top: 35px;
            right: 0;
            background-color: white;
            z-index: 9;
        }
    }
    .select-landscape-box {
        .select-box {
            max-width: 305px;
        }
    }
    .landscape-slider-container {
        margin-top: 35px;
        margin-left: -14px;
        .slide-box {
            padding: 12px 16px;
        }
        .img-n-content {
            display: flex;
            box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            .img-box {
                img {
                    border-radius: 8px;
                    width: 245px;
                    min-height: 376px;
                    object-fit: cover;
                }
            }
            .content-box {
                padding: 24px 32px;
                border-radius: 8px;
                background-color: white;
                z-index: 9;
                left: -18px;
                .title-n-text {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #f6f6f6;
                    h4 {
                        font-size: 19px;
                        line-height: 27px;
                        margin-bottom: 8px;
                    }
                    p {
                        line-height: 180%;
                    }
                }
                .list {
                    padding-top: 20px;
                    li {
                        line-height: 28px;
                        margin-top: 8px;
                        gap: 12px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .icon-box {
                            min-width: 26px;
                            text-align: center;
                        }
                    }
                }
                .cta-btn {
                    margin-top: 15px;
                    max-width: 305px;
                }
            }
        }
        .slick-dots {
            bottom: -30px;
        }
    }
    .cta {
        max-width: 305px;
        margin-top: 10px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
    }
    .fixed-bottom {
        position: relative;
        margin-top: 35px;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .select-landscape-box {
            margin-top: 35px;
            .select-box {
                padding: 0 20px;
                max-width: 305px;
                margin: auto;
            }
        }
        .landscape-slider-container {
            margin-top: 15px;
            margin-left: -20px;
            margin-right: -20px;

            .slide-box {
                padding: 12px;
                max-width: 292px;
            }
            .img-n-content {
                flex-direction: column;
                .content-box {
                    padding: 16px;
                    top: -18px;
                    left: 0;
                    .title-n-text {
                        padding-bottom: 12px;
                        border-bottom: 1px solid #f6f6f6;
                        h4 {
                            margin-bottom: 5px;
                        }
                        p {
                            margin-top: 0;
                            line-height: 180%;
                        }
                    }
                    .list {
                        padding-top: 12px;
                    }
                }
            }
        }
        .fee-n-box {
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
            .tooltip-box {
                bottom: 35px;
                top: auto;
            }
        }
        .cta {
            position: static;
            top: 0px;
            left: 0px;
        }
        .fixed-bottom {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: white;
            z-index: 10;
            padding: 10px;
            box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
            &.visible {
                position: relative;
                box-shadow: none;
            }
        }
    }
`;

const SelectLandscape = ({ classname }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [landscapes, setLandscapes] = useState([]);
    //const [selectedLandscape, setSelectedLandscape] = useState({});
    const [selectedLandscapeID, setSelectedLandscapeID] = useState(1);

    const [planID, setPlanID] = useState(null);
    const selectedPlan = useSelector(state => state.subscription.selectedPlan);
    const selectedLandscape = useSelector(state => state.subscription.selectedLandscape);

    const sliderRef = useRef(null);
    const feeRef = useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: false,
        variableWidth: false,
        afterChange: currentSlide => {
            console.log('currentslide', currentSlide);
            const objectId = landscapes && landscapes[currentSlide].id;
            const filtered = landscapes && landscapes.filter(item => item.id === objectId);

            //setSelectedLandscape(filtered[0]);
            dispatch(setSelectedLandscape(filtered[0]));
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,

                    arrows: false,
                    dots: true,
                    speed: 300,
                    centerPadding: '35px',
                    infinite: false,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,

                    arrows: false,
                    dots: true,
                    speed: 300,
                    centerPadding: '35px',
                    infinite: false,

                    variableWidth: true,
                },
            },
        ],
    };

    const landscapeData = [
        {
            id: 1,
            img: `${mapIcon}`,
        },
        {
            id: 2,
            img: `${cottonIcon}`,
        },
        {
            id: 3,
            img: `${leafIcon}`,
        },
    ];

    const [isVisible, setIsVisible] = useState(false);

    const getHighlightedText = data => {
        let temp = data.slice(4);
        const index = temp.indexOf('**');
        const highlighted = temp.slice(0, index);
        const normal = temp.slice(index + 2);
        return { text: normal, highlightedPart: highlighted };
    };
    const handlePinClick = id => {
        const index = landscapes && landscapes.findIndex(item => item.id === id);
        const filtered = landscapes && landscapes.filter(item => item.id === id);
        //setSelectedLandscape(filtered[0]);
        dispatch(setSelectedLandscape(filtered[0]));
        sliderRef.current.slickGoTo(index);
    };

    const handleLandscapeSubmit = event => {
        event.preventDefault();

        if (selectedLandscape) {
            history.push('/subscription/payment');
        }
    };

    const updatePlanID = planID => {
        setPlanID(planID);
        dispatch(setPlanId(planID));
    };

    useEffect(() => {
        fetchLandscapes()
            .then(resp => {
                const applicableLS = applicableLandscapes(resp, selectedPlan.planType);

                setLandscapes(applicableLS);
                //setSelectedLandscape(resp[0]);
                dispatch(setSelectedLandscape(resp[0]));
            })
            .catch(err => {
                console.log(err);
            });
    }, [selectedPlan]);

    useEffect(() => {
        console.log('selectedLandscape.id', selectedLandscape);

        const planID = extractPlanID(landscapes, selectedPlan.planType, selectedLandscape.id);
        updatePlanID(planID);
    }, [selectedLandscape]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else if (!entry.isIntersecting) {
                    setIsVisible(false);
                }
            },
            {
                root: null,
                threshold: 0.1,
            }
        );

        if (feeRef.current) {
            observer.observe(feeRef.current);
        }

        return () => {
            if (feeRef.current) {
                observer.unobserve(feeRef.current);
            }
        };
    }, [feeRef]);

    console.log('landscapes', landscapes, planID);
    return (
        <StepForm step={2} isNextDisabled={!planID}>
            <StepThreeSection className={`mx-auto flex flex-col ${classname}`}>
                {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                    <div className="fee-n-box w-full box-shadow absolute hide-tab">
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                {' '}
                                <FormattedMessage id="Subscription.StepForm.MonthlyFee" />
                            </div>
                            <div className="big-txt flex items-center small">
                                ${selectedPlan.planCost / 300}{' '}
                                <span className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </span>
                                <div className="tooltip-box">
                                    <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                </div>
                            </div>
                        </div>
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.Impact" />
                            </div>
                            <div className="big-txt flex items-center small">
                                {selectedPlan.impactList[0].listTxt} /{' '}
                                <FormattedMessage id="Subscription.StepForm.Year" />
                            </div>
                        </div>
                    </div>
                )}
                <div className="form-title">
                    <FormattedMessage id="Subscription.StepForm.Step3.title" />
                    <div className="title-info text-center show-tab">
                        <FormattedMessage id="Subscription.StepForm.Step3.desc" />
                    </div>
                </div>

                <div className="select-landscape-box">
                    <div className="select-box">
                        {landscapes && landscapes.length > 0 && (
                            <CustomSelect
                                options={landscapes}
                                handlePinClick={handlePinClick}
                                selectedPin={selectedLandscape && selectedLandscape.id}
                            />
                        )}
                    </div>
                </div>
                <div className="landscape-slider-container">
                    <LandscapeSlider {...settings} ref={sliderRef}>
                        {/* slider 1 */}

                        {landscapes &&
                            landscapes.length > 0 &&
                            landscapes.map(landscape => (
                                <div className="slide-box">
                                    <div className="img-n-content">
                                        <div className="img-box">
                                            <picture>
                                                <source
                                                    media="(max-width:1023px)"
                                                    srcset={landscape && landscape.image}
                                                />
                                                <img
                                                    src={landscape && landscape.image}
                                                    className="w-full"
                                                    alt=""
                                                />
                                            </picture>
                                        </div>
                                        <div className="content-box relative">
                                            <div className="title-n-text">
                                                <h4 className="title">
                                                    <FormattedMessage
                                                        id="NewLandingPage.ourLandscapes.landscapeTitle"
                                                        values={{
                                                            landscape: landscape.name,
                                                        }}
                                                    />
                                                </h4>
                                                <p>
                                                    <FormattedMessage
                                                        id="NewLandingPage.ourLandscapes.landscapeDesc"
                                                        values={{
                                                            description:
                                                                landscape.metadata.description.indexOf(
                                                                    '.'
                                                                ) === -1
                                                                    ? landscape.metadata.description
                                                                    : landscape.metadata.description
                                                                          .split('.')[0]
                                                                          .trim() + '.',
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            <ul className="list">
                                                {landscape.metadata.impact
                                                    .split('\n')
                                                    .map(itemSet => getHighlightedText(itemSet))
                                                    .map((item, index) => (
                                                        <li className="flex items-center">
                                                            <div className="icon-box flex items-center justify-center">
                                                                <img
                                                                    src={landscapeData[index].img}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="number-info">
                                                                <strong>
                                                                    {item.highlightedPart}
                                                                </strong>{' '}
                                                                {item.text}
                                                            </div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {/* slider 2 */}
                    </LandscapeSlider>
                </div>
                {/* fee related box and tooltip */}
                {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                    <div ref={feeRef} className="fee-n-box w-full box-shadow relative show-tab">
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.MonthlyFee" />
                            </div>
                            <div className="big-txt flex items-center small">
                                ${selectedPlan.planCost / 300}{' '}
                                <Link className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </Link>
                                <div className="tooltip-box">
                                    <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                </div>
                            </div>
                        </div>
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.Impact" />
                            </div>
                            <div className="big-txt flex items-center small">
                                {selectedPlan.impactList[0].listTxt} /{' '}
                                <FormattedMessage id="Subscription.StepForm.Year" />
                            </div>
                        </div>
                    </div>
                )}
                <div className={`fixed-bottom ${isVisible ? 'visible' : ''}`}>
                    <div className="cta mx-auto">
                        <OrangeBtn
                            type="button"
                            disabled={!planID}
                            className={!planID ? 'disabled' : null}
                            onClick={handleLandscapeSubmit}
                        >
                            <FormattedMessage id="Subscription.StepForm.SelectContinue" />
                        </OrangeBtn>
                    </div>
                </div>

                <div className="step-nav flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                    <div className="bullet"></div>
                </div>
            </StepThreeSection>
        </StepForm>
    );
};

export default SelectLandscape;
