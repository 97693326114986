import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import * as coreAPI from '../../../../../coreApi';
import { FormattedMessage } from 'react-intl';
import SectionInfo from '../../../../../newComponents/SectionInfo';
import Image from '../../../../../newComponents/Image';
import Button from '../../../../../newComponents/Button';
import { ExternalLink } from '../../../../../components';
import Footer from '../../../components/Footer';
import PaymentLineChart from '../../../components/Chart/PaymentChart';
import { isEmpty } from 'lodash';
import { NamedLink } from '../../../../../components';
import { plans } from '../../../../../paymentDataNew';
import {
    LandscapeMain,
    StyledImageContainer,
    TextBox,
    Title,
    Desc,
    Container,
    CardTitle,
    Chart,
    Box,
    ButtonBox,
    Select,
    ButtonContainer,
    Empty,
    GrayText,
} from './Styles';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';

export const fetchActions = async landscapeId => {
    return new Promise(async (resolve, reject) => {
        const resp = await coreAPI.allActions();
        const filtered = resp.data.filter(action => action.landscapeId === landscapeId);
        resolve(filtered);
    });
};

const MyNatureLandscapePage = ({ location, history, user }) => {
    const [landscapes, setLandscapes] = useState(null);
    const [actions, setActions] = useState([]);
    const [selectYear, setSelectYear] = useState('');
    const [year, setYear] = useState([]);
    const [currentYearArray, setCurrentYearArray] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [nextYearArray, setNextYearArray] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [payments, setPayments] = useState([]);
    const historyPath = useHistory();

    useEffect(() => {
        if (user && user.userType === 'empresa') {
            historyPath.push('/partner-profile/landscape');
        }
    }, [user]);

    useEffect(() => {
        coreAPI
            .getMySubscription()
            .then(resp => {
                if (resp) {
                    let data = [
                        ...new Map(resp.data.map(item => [item.landscape['id'], item])).values(),
                    ];
                    setLandscapes(data);
                    computeGraph(resp.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);
    const handleYear = e => {
        setSelectYear(e.target.value);
    };
    function computeGraph(data) {
        let y;
        let currentYear = 0;
        data &&
            data.map(item => {
                let str = item.createdAt.split('-');
                y = parseInt(str[0], 10);
                let m = parseInt(str[1], 10);
                currentYear = currentYear == 0 ? y : currentYear;

                const planType = item.landscape_plan.planType;
                const planAmount = plans.find(plan => plan.planType == planType).amount;
                if (currentYear == y) {
                    for (let i = m - 1; i < 12; i++) {
                        currentYearArray[i] = currentYearArray[i] + planAmount / 300;
                    }
                } else {
                    currentYearArray.fill(0);
                    nextYearArray.fill(0);

                    for (let i = m - 1; i < 12; i++) {
                        currentYearArray[i] =
                            currentYearArray[i] + nextYearArray[i] + planAmount / 300;
                    }

                    currentYear = y;
                }

                for (let i = 0; i < m - 10; i++) {
                    nextYearArray[i] = nextYearArray[i] + planAmount / 300;
                }
            });
        let yearArray = [];
        yearArray.push(y);
        yearArray.push(y + 1);
        setYear(yearArray);
        let previousArray = 0;
        currentYearArray.map((item, i) => {
            if (i == 0) {
                previousArray = currentYearArray[0];
            } else {
                if (item == 0) {
                    currentYearArray[i] = previousArray;
                } else {
                    currentYearArray[i] = previousArray + currentYearArray[i];
                    previousArray = currentYearArray[i];
                }
            }
        });
        nextYearArray.map((item, i) => {
            if (item == 0) {
                nextYearArray[i] = 0;
            } else {
                nextYearArray[i] = previousArray + nextYearArray[i];
                previousArray = nextYearArray[i];
            }
        });

        setCurrentYearArray(currentYearArray);
        setNextYearArray(nextYearArray);
    }
    useEffect(() => {
        if (!isEmpty(landscapes)) {
            landscapes.map(item => {
                fetchActions(item.landscape.id)
                    .then(resp => {
                        if (resp.length > 0) {
                            setActions(actions => [...actions, ...resp]);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
        }
    }, [landscapes]);
    const linkText = (
        <NamedLink name="Paisajes">
            {' '}
            <FormattedMessage id="YourListingsPage.LinkText" />{' '}
        </NamedLink>
    );
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Friend profile<br/>& account</>}
                image={HeroImage}
            />
            <LandscapeMain>
                {!isEmpty(landscapes) ? (
                    <>
                        <ButtonBox>
                            <CardTitle>
                                <FormattedMessage id="MyNaturePage.Landscape.Title" />
                            </CardTitle>
                            <Button variant="filled" size="lg" path="/landscapes">
                                <FormattedMessage id="MyNaturePage.Landscape.Button.Invest" />
                            </Button>
                        </ButtonBox>
                        <Container>
                            {landscapes &&
                                landscapes.map(item => {
                                    return (
                                        <StyledImageContainer>
                                            <Image
                                                source={item.landscape.image}
                                                size={'md'}
                                                rounded={false}
                                            />
                                            {item.landscape.name && item.landscape.description && (
                                                <TextBox>
                                                    <Title>
                                                        <FormattedMessage
                                                            id="NewLandingPage.ourLandscapes.landscapeTitle"
                                                            values={{
                                                                landscape: item.landscape.name,
                                                            }}
                                                        />
                                                    </Title>
                                                    <Desc>
                                                        {/* {item.landscape.description} */}
                                                        <FormattedMessage
                                                            id="NewLandingPage.ourLandscapes.landscapeDesc"
                                                            values={{
                                                                description:
                                                                    item.landscape.description.indexOf(
                                                                        '.'
                                                                    ) === -1
                                                                        ? item.landscape.description
                                                                        : item.landscape.description
                                                                              .split('.')[0]
                                                                              .trim() + '.',
                                                            }}
                                                        />
                                                    </Desc>
                                                </TextBox>
                                            )}
                                            <div className="btn">
                                                <Button
                                                    variant="filled"
                                                    size="md"
                                                    path={`/landscape/${item.landscape.name}/${item.landscape.id}`}
                                                >
                                                    <FormattedMessage id="MyNaturePage.Landscape.Button.Read" />
                                                </Button>
                                            </div>
                                            <br />
                                        </StyledImageContainer>
                                    );
                                })}
                            <div className="after-btn">
                                <Button variant="filled" size="lg" path="/landscapes">
                                    <FormattedMessage id="MyNaturePage.Landscape.Button.Invest" />
                                </Button>
                            </div>
                        </Container>
                        <CardTitle>
                            <FormattedMessage id="MyNaturePage.Landscape.Title.WhatsNew" />
                        </CardTitle>
                        {actions && actions.length > 0 ? (
                            <Container>
                                {actions.map((post, i) => {
                                    return (
                                        <div className="grid-item">
                                            <ExternalLink
                                                className="link"
                                                onClick={() => {
                                                    window.location.href = `/action/${post.slug}`;
                                                }}
                                            >
                                                <SectionInfo
                                                    heading={post.title}
                                                    data={post.description}
                                                    align="left"
                                                    color="black"
                                                    textSize="md"
                                                />
                                                <br />
                                                <br />
                                                <Image
                                                    source={post.image}
                                                    title={post.title}
                                                    size="md"
                                                />
                                            </ExternalLink>
                                        </div>
                                    );
                                })}
                            </Container>
                        ) : (
                            <Empty>
                                <GrayText empty>
                                    {' '}
                                    No action found
                                    <ButtonContainer>
                                        <Button variant="filled" size="lg" path="/new-action">
                                            Add an Action
                                        </Button>
                                    </ButtonContainer>
                                </GrayText>
                            </Empty>
                        )}

                        <CardTitle>
                            <FormattedMessage id="MyNaturePage.Landscape.Title.Contribution" />
                        </CardTitle>
                        <Select>
                            <select id="dropdown" placeholder="select year" onChange={handleYear}>
                                <option value="">Select year</option>
                                {year && year.length > 0 && (
                                    <>
                                        <option value="current">{year[0]}</option>
                                        <option value="next">{year[1]}</option>
                                    </>
                                )}
                            </select>
                        </Select>
                        {selectYear === 'current' && (
                            <Chart>
                                {<PaymentLineChart data={currentYearArray} year={year[0]} />}
                            </Chart>
                        )}
                        {selectYear === 'next' && (
                            <Chart>
                                {<PaymentLineChart data={nextYearArray} year={year[1]} />}
                            </Chart>
                        )}
                    </>
                ) : (
                    <Box>
                        <p>
                            {' '}
                            <FormattedMessage
                                id="YourListingsPage.NoListing"
                                values={{ linkText }}
                            />{' '}
                        </p>
                    </Box>
                )}
                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </LandscapeMain>
            <RightNav />
        </Frame>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(MyNatureLandscapePage));
