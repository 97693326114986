import amazonas1 from "../../assets/aliado/AmazonasExplorer/amazonas1.jpg";
import amazonas2 from "../../assets/aliado/AmazonasExplorer/amazonas2.jpg";
import amazonas3 from "../../assets/aliado/AmazonasExplorer/amazonas3.jpg";
import amazonasHero from "../../assets/aliado/AmazonasExplorer/amazonashero.jpg";
import andean1 from "../../assets/aliado/AndeanLodges/andean1.jpg";
import andean2 from "../../assets/aliado/AndeanLodges/andean2.jpg";
import andean3 from "../../assets/aliado/AndeanLodges/andean3.jpg";
import andean4 from "../../assets/aliado/AndeanLodges/andean4.jpg";
import andean5 from "../../assets/aliado/AndeanLodges/andean5.jpg";
import andean6 from "../../assets/aliado/AndeanLodges/andean6.jpg";
import auqui1 from "../../assets/aliado/Auqui/auqui1.jpg";
import auqui2 from "../../assets/aliado/Auqui/auqui2.jpg";
import auqui3 from "../../assets/aliado/Auqui/auqui3.jpg";
import auqui4 from "../../assets/aliado/Auqui/auqui4.jpg";
import anyni1 from "../../assets/aliado/AyniCenter/anyni1.jpeg";
import anyni2 from "../../assets/aliado/AyniCenter/anyni2.jpeg";
import anyni3 from "../../assets/aliado/AyniCenter/anyni3.jpeg";
import anyni4 from "../../assets/aliado/AyniCenter/anyni4.jpeg";
import anyni5 from "../../assets/aliado/AyniCenter/anyni5.jpg";
import CEDES01 from "../../assets/aliado/CEDES/CEDES-01.jpg";
import CEDES02 from "../../assets/aliado/CEDES/CEDES-02.jpg";
import CEDES03 from "../../assets/aliado/CEDES/CEDES-03.jpg";
import CEDES04 from "../../assets/aliado/CEDES/CEDES-04.jpg";
import cedesHero from "../../assets/aliado/CEDES/cedeshero.jpg";
import CEDIA_Aguaje from "../../assets/aliado/CEDIA/CEDIA-Aguaje.jpg";
import CEDIA_claro from "../../assets/aliado/CEDIA/CEDIA-claro-para-chacra.jpg";
import CEDIA_cosecha from "../../assets/aliado/CEDIA/CEDIA-cosecha sostenible.jpg";
import CEDIA_Cosecha from "../../assets/aliado/CEDIA/CEDIA-Cosecha-platanos.JPG";
import CEDIA_Familia from "../../assets/aliado/CEDIA/CEDIA-Familia-colonos.jpg";
import CEDIA_Nino from "../../assets/aliado/CEDIA/CEDIA-Nino-tortuga.jpeg";
import CEDIA_Pesca from "../../assets/aliado/CEDIA/CEDIA-Pesca.jpeg";
import CEDIA_pescando from "../../assets/aliado/CEDIA/CEDIA-pescando.jpeg";
import CEDIA_preparacion from "../../assets/aliado/CEDIA/CEDIA-preparacion-masato.JPG";
import CEDIA_recoleccion from "../../assets/aliado/CEDIA/CEDIA-recoleccion-aguaje.jpg";
import CEDIAHero from "../../assets/aliado/CEDIA/cediahero.jpg";
import cientifica1 from "../../assets/aliado/Cientifica/cientifica1.jpg";
import cientifica2 from "../../assets/aliado/Cientifica/cientifica2.jpg";
import cientifica3 from "../../assets/aliado/Cientifica/cientifica3.jpg";
import cientifica4 from "../../assets/aliado/Cientifica/cientifica4.jpg";
import cientifica5 from "../../assets/aliado/Cientifica/cientifica5.jpg";
import crees1 from "../../assets/aliado/CREES/crees1.JPG";
import crees2 from "../../assets/aliado/CREES/crees2.jpg";
import crees3 from "../../assets/aliado/CREES/crees3.jpg";
import crees4 from "../../assets/aliado/CREES/crees4.png";
import crees5 from "../../assets/aliado/CREES/crees5.JPG";
import elalbergue1 from "../../assets/aliado/ElAlbergue/elalbergue1.jpg";
import elalbergue2 from "../../assets/aliado/ElAlbergue/elalbergue2.jpeg";
import elalbergue3 from "../../assets/aliado/ElAlbergue/elalbergue3.jpg";
import elalbergue4 from "../../assets/aliado/ElAlbergue/elalbergue4.jpg";
import explorandes1 from "../../assets/aliado/Explorandes/explorandes1.jpg";
import explorandes2 from "../../assets/aliado/Explorandes/explorandes2.jpg";
import explorandes3 from "../../assets/aliado/Explorandes/explorandes3.jpg";
import explorandes4 from "../../assets/aliado/Explorandes/explorandes4.jpg";
import florayfauna1 from "../../assets/aliado/FloraYFauna/florayfauna1.jpg";
import florayfauna2 from "../../assets/aliado/FloraYFauna/florayfauna2.jpg";
import florayfauna3 from "../../assets/aliado/FloraYFauna/florayfauna3.jpg";
import florayfauna4 from "../../assets/aliado/FloraYFauna/florayfauna4.jpg";
import florayfauna5 from "../../assets/aliado/FloraYFauna/florayfauna5.jpg";
import florayfaunaHero from "../../assets/aliado/FloraYFauna/florayfaunahero.jpg";
import gerpal1 from "../../assets/aliado/Gerpal/gerpal1.jpg";
import gerpal2 from "../../assets/aliado/Gerpal/gerpal2.jpg";
import gerpal3 from "../../assets/aliado/Gerpal/gerpal3.jpg";
import gerpal4 from "../../assets/aliado/Gerpal/gerpal4.jpg";
import hoseg1 from "../../assets/aliado/Hoseg/hoseg1.jpg";
import hoseg2 from "../../assets/aliado/Hoseg/hoseg2.jpg";
import hoseg3 from "../../assets/aliado/Hoseg/hoseg3.jpg";
import hoseg4 from "../../assets/aliado/Hoseg/hoseg4.jpg";
import islavalles1 from "../../assets/aliado/islavalles/islavalles1.jpg";
import islavalles2 from "../../assets/aliado/islavalles/islavalles2.jpg";
import islavalles3 from "../../assets/aliado/islavalles/islavalles3.JPG";
import Kiunalla2 from "../../assets/aliado/Kiunalla/Kiunalla2.jpg";
import Kiunalla3 from "../../assets/aliado/Kiunalla/Kiunalla3.jpg";
import Kiunalla4 from "../../assets/aliado/Kiunalla/Kiunalla4.jpg";
import Kiunalla5 from "../../assets/aliado/Kiunalla/Kiunalla5.jpg";
import Kiunalla6 from "../../assets/aliado/Kiunalla/Kiunalla6.jpg";
import Kiunalla7 from "../../assets/aliado/Kiunalla/Kiunalla7.jpg";
import Kiunallacover from "../../assets/aliado/Kiunalla/Kiunallacover.jpg";
import nuevaesperanza1 from "../../assets/aliado/NuevaEsperanza/nuevaesperanza1.jpeg";
import nuevaesperanzaHero from "../../assets/aliado/NuevaEsperanza/nuevaesperanzahero.jpg";
import paloateparo1 from "../../assets/aliado/PalotoaTeparo/paloateparo1.jpg";
import paloateparo2 from "../../assets/aliado/PalotoaTeparo/paloateparo2.jpg";
import paloateparo3 from "../../assets/aliado/PalotoaTeparo/paloateparo3.jpg";
import paloateparo4 from "../../assets/aliado/PalotoaTeparo/paloateparo4.jpg";
import paloateparo5 from "../../assets/aliado/PalotoaTeparo/paloateparo5.jpg";
import Patronato_Bofedal from "../../assets/aliado/PatronatoNYC/Patronato-Bofedal.JPG";
import Patronato_hilando from "../../assets/aliado/PatronatoNYC/Patronato-hilando.jpg";
import Patronato_Muyucocha from "../../assets/aliado/PatronatoNYC/Patronato-Muyucocha.JPG";
import Patronato_piticocha from "../../assets/aliado/PatronatoNYC/Patronato-piticocha.jpg";
import peru20211 from "../../assets/aliado/Peru2021/peru20211.jpg";
import perugbc1 from "../../assets/aliado/PeruGBC/perugbc1.jpg";
import perugbc2 from "../../assets/aliado/PeruGBC/perugbc2.jpg";
import perugbc3 from "../../assets/aliado/PeruGBC/perugbc3.jpg";
import perugbc4 from "../../assets/aliado/PeruGBC/perugbc4.jpg";
import primaafp2 from "../../assets/aliado/PrimaAFP/primaafp2.jpg";
import primaafp3 from "../../assets/aliado/PrimaAFP/primaafp3.jpg";
import primaafp4 from "../../assets/aliado/PrimaAFP/primaafp4.jpg";
import primaafp5 from "../../assets/aliado/PrimaAFP/primaafp5.jpg";
import primaafpHero from "../../assets/aliado/PrimaAFP/primaafphero.jpg";
import pukanina1 from "../../assets/aliado/PukaNina/pukanina1.jpg";
import pukanina2 from "../../assets/aliado/PukaNina/pukanina2.jpg";
import pukanina3 from "../../assets/aliado/PukaNina/pukanina3.jpg";
import pukanina4 from "../../assets/aliado/PukaNina/pukanina4.jpg";
import rainforest1 from "../../assets/aliado/RainforestExpeditions/rainforest1.jpg";
import rainforest2 from "../../assets/aliado/RainforestExpeditions/rainforest2.jpg";
import rainforest3 from "../../assets/aliado/RainforestExpeditions/rainforest3.jpg";
import rainforest4 from "../../assets/aliado/RainforestExpeditions/rainforest4.jpg";
import rainforest5 from "../../assets/aliado/RainforestExpeditions/rainforest5.jpg";
import rainforest6 from "../../assets/aliado/RainforestExpeditions/rainforest6.jpg";
import SePeru1 from "../../assets/aliado/SePeru/SePeru-1.jpg";
import SePeru2 from "../../assets/aliado/SePeru/SePeru-2.jpg";
import SePeru3 from "../../assets/aliado/SePeru/SePeru-3.JPG";
import SePeru4 from "../../assets/aliado/SePeru/SePeru-4.JPG";
import shipetiari1 from "../../assets/aliado/Shipetiari/shipetiari1.jpg";
import shipetiari2 from "../../assets/aliado/Shipetiari/shipetiari2.jpg";
import shipetiari3 from "../../assets/aliado/Shipetiari/shipetiari3.jpg";
import shipetiari4 from "../../assets/aliado/Shipetiari/shipetiari4.jpg";
import sistemab1 from "../../assets/aliado/SistemaB/sistemab1.jpg";
import sistemab2 from "../../assets/aliado/SistemaB/sistemab2.jpg";
import sistemab3 from "../../assets/aliado/SistemaB/sistemab3.jpg";
import strategik1 from "../../assets/aliado/Strategik/strategik1.jpg";
import strategik2 from "../../assets/aliado/Strategik/strategik2.jpg";
import strategik3 from "../../assets/aliado/Strategik/strategik3.jpg";
import strategik4 from "../../assets/aliado/Strategik/strategik4.jpg";
import strategik5 from "../../assets/aliado/Strategik/strategik5.jpg";
import ted1 from "../../assets/aliado/TedXTukuy/ted1.jpg";
import ted2 from "../../assets/aliado/TedXTukuy/ted2.jpg";
import ted3 from "../../assets/aliado/TedXTukuy/ted3.jpg";
import ted4 from "../../assets/aliado/TedXTukuy/ted4.jpg";
import Wicungo1 from "../../assets/aliado/Wicungo/Wicungo1.jpg";
import Wicungo2 from "../../assets/aliado/Wicungo/Wicungo2.jpeg";
import Wicungo3 from "../../assets/aliado/Wicungo/Wicungo3.jpeg";


const cedesPhotos = [
  {url:CEDES01,description:''},
  {url:CEDES02,description:''},
  {url:CEDES03,description:''},
  {url:CEDES04,description:''},
]
const cediaPhotos = [
  {url:CEDIA_Aguaje,description:''},
  {url:CEDIA_claro,description:''},
  {url:CEDIA_cosecha,description:''},
  {url:CEDIA_Cosecha,description:''},
  {url:CEDIA_Familia,description:''},
  {url:CEDIA_Nino,description:''},
  {url:CEDIA_Pesca,description:''},
  {url:CEDIA_pescando,description:''},
  {url:CEDIA_preparacion,description:''},
  {url:CEDIA_recoleccion,description:''},
]
const patronatoPhotos = [
  {url:Patronato_Bofedal,description:''},
  {url:Patronato_hilando,description:''},
  {url:Patronato_Muyucocha,description:''},
]
const seperuPhotos = [
  {url:SePeru2,description:''},
  {url:SePeru3,description:''},
  {url:SePeru4,description:''},
]

const amazonasExplorerPhotos = [
  {url:amazonas1,description:''},
  {url:amazonas2,description:''},
  {url:amazonas3,description:''},
]

const andeanPhotos = [
  {url:andean2,description:''},
  {url:andean3,description:''},
  {url:andean4,description:''},
  {url:andean5,description:''},
  {url:andean6,description:''},
]

const auquiPhotos = [
  {url:auqui2,description:''},
  {url:auqui3,description:''},
  {url:auqui4,description:''},
]

const anyniPhotos = [
  {url:anyni1,description:''},
  {url:anyni2,description:''},
  {url:anyni3,description:''},
  {url:anyni4,description:''},
]

const cientificaPhotos = [
  {url:cientifica1,description:''},
  {url:cientifica2,description:''},
  {url:cientifica3,description:''},
  {url:cientifica4,description:''},
]

const elalberguePhotos = [
  {url:elalbergue2,description:''},
  {url:elalbergue3,description:''},
  {url:elalbergue4,description:''},
]

const explorandesPhotos = [
  {url:explorandes1,description:''},
  {url:explorandes2,description:''},
  {url:explorandes3,description:''},
]

const florayfaunaPhotos = [
  {url:florayfauna1,description:''},
  {url:florayfauna2,description:''},
  {url:florayfauna3,description:''},
  {url:florayfauna4,description:''},
  {url:florayfauna5,description:''},
]

const gerpalPhotos = [
  {url:gerpal1,description:''},
  {url:gerpal3,description:''},
  {url:gerpal4,description:''},
]

const hosegPhotos = [
  {url:hoseg1,description:''},
  {url:hoseg2,description:''},
  {url:hoseg3,description:''},
]

const peru2021Photos = [
  {url:peru20211,description:''},
]

const perugbcPhotos = [
  {url:perugbc1,description:''},
  {url:perugbc2,description:''},
  {url:perugbc3,description:''},
]

const primaafpPhotos = [
  {url:primaafp2,description:''},
  {url:primaafp3,description:''},
  {url:primaafp4,description:''},
  {url:primaafp5,description:''},
]

const pukaninaPhotos = [
  {url:pukanina1,description:''},
  {url:pukanina2,description:''},
  {url:pukanina4,description:''},
]

const rainforestPhotos = [
  {url:rainforest1,description:''},
  {url:rainforest2,description:''},
  {url:rainforest3,description:''},
  {url:rainforest4,description:''},
  {url:rainforest5,description:''},
]

const sistemabPhotos = [
  {url:sistemab1,description:''},
  {url:sistemab2,description:''},
]

const strategikPhotos = [
  {url:strategik1,description:''},
  {url:strategik3,description:''},
  {url:strategik4,description:''},
  {url:strategik5,description:''},
]

const tedPhotos = [
  {url:ted2,description:''},
  {url:ted3,description:''},
  {url:ted4,description:''},
]

const creesPhotos = [
  {url:crees1,description:''},
  {url:crees2,description:''},
  {url:crees3,description:''},
  {url:crees4,description:''},
]

const islavallesPhotos = [
  {url:islavalles2,description:''},
  {url:islavalles3,description:''},
]

const kiunallaPhotos = [
  {url:Kiunalla2,description:''},
  {url:Kiunalla3,description:''},
  {url:Kiunalla4,description:''},
  {url:Kiunalla5,description:''},
  {url:Kiunalla6,description:''},
  {url:Kiunalla7,description:''},
]

const nuevaesperanzaPhotos = [
  {url:nuevaesperanza1,description:''},
]

const paloateparoPhotos = [
  {url:paloateparo2,description:''},
  {url:paloateparo3,description:''},
  {url:paloateparo4,description:''},
  {url:paloateparo5,description:''},
]

const shipetiariPhotos = [
  {url:shipetiari2,description:''},
  {url:shipetiari3,description:''},
  {url:shipetiari4,description:''},
]

const wicungoPhotos = [
  {url:Wicungo1,description:''},
  {url:Wicungo2,description:''},
]

const allStaticPhotos = {
  cedesPhotos:{pics:cedesPhotos,cover:cedesHero},
  cediaPhotos:{pics:cediaPhotos,cover:CEDIAHero},
  patronatoPhotos:{pics:patronatoPhotos,cover:Patronato_piticocha},
  seperuPhotos:{pics:seperuPhotos,cover:SePeru1},
  amazonasExplorerPhotos:{pics:amazonasExplorerPhotos,cover:amazonasHero},
  andeanPhotos:{pics:andeanPhotos,cover:andean1},
  auquiPhotos:{pics:auquiPhotos,cover:auqui1},
  anyniPhotos:{pics:anyniPhotos,cover:anyni5},
  cientificaPhotos:{pics:cientificaPhotos,cover:cientifica5},
  elalberguePhotos:{pics:elalberguePhotos,cover:elalbergue1},
  explorandesPhotos:{pics:explorandesPhotos,cover:explorandes4},
  florayfaunaPhotos:{pics:florayfaunaPhotos,cover:florayfaunaHero},
  gerpalPhotos:{pics:gerpalPhotos,cover:gerpal2},
  hosegPhotos:{pics:hosegPhotos,cover:hoseg4},
  peru2021Photos:{pics:peru2021Photos,cover:peru20211},
  perugbcPhotos:{pics:perugbcPhotos,cover:perugbc4},
  primaafpPhotos:{pics:primaafpPhotos,cover:primaafpHero},
  pukaninaPhotos:{pics:pukaninaPhotos,cover:pukanina3},
  rainforestPhotos:{pics:rainforestPhotos,cover:rainforest6},
  sistemabPhotos:{pics:sistemabPhotos,cover:sistemab3},
  strategikPhotos:{pics:strategikPhotos,cover:strategik2},
  tedPhotos:{pics:tedPhotos,cover:ted1},
  creesPhotos:{pics:creesPhotos,cover:crees5},
  islavallesPhotos:{pics:islavallesPhotos,cover:islavalles1},
  kiunallaPhotos:{pics:kiunallaPhotos,cover:Kiunallacover},
  nuevaesperanzaPhotos:{pics:nuevaesperanzaPhotos,cover:nuevaesperanzaHero},
  paloateparoPhotos:{pics:paloateparoPhotos,cover:paloateparo1},
  shipetiariPhotos:{pics:shipetiariPhotos,cover:shipetiari1},
  wicungoPhotos:{pics:wicungoPhotos,cover:Wicungo3},
}


const imageLoader = (imageObject,type)=> {
  return allStaticPhotos[imageObject][type];
}

export default imageLoader;
