import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { GoogleLogin } from 'react-google-login';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import * as validators from '../../util/validators';
import { authenticationInProgress, signInWithGoogle } from '../../ducks/Auth.duck';
import { connect } from 'react-redux';

import css from './LoginForm.css';

const KEY_CODE_ENTER = 13;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;

const LoginFormComponent = props => {
    const { isAuthenticated, loginError, signupError, intl } = props;
    const [showError, setShowError] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [googleLoginFailed, setGoogleLoginFailed] = useState(false);
    const [regularAccError, setRegularAccError] = useState(false);
    const reCaptchaRef = useRef(null);

    const googleResponse = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRef.current) {
                    captchaToken = await reCaptchaRef.current.executeAsync();
                    reCaptchaRef.current.reset();
                }

                await props.submitLoginWithGoogle(response.tokenId);
                setRegularAccError(false);
                setShowError(true);
                setScroll(true);
            } catch (error) {
                setGoogleLoginFailed(true);
                console.log({ 'Google Sign In Error': error });
            }
        }
    };
    return (
        <>
            <FinalForm
                {...props}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        formId,
                        handleSubmit,
                        inProgress,
                        intl,
                        invalid,
                    } = fieldRenderProps;

                    // email
                    const emailLabel = props.intl.formatMessage({
                        id: 'LoginForm.emailLabel',
                    });
                    const emailPlaceholder = props.intl.formatMessage({
                        id: 'LoginForm.emailPlaceholder',
                    });
                    const emailRequiredMessage = props.intl.formatMessage({
                        id: 'LoginForm.emailRequired',
                    });
                    const emailRequired = validators.required(emailRequiredMessage);
                    const emailInvalidMessage = props.intl.formatMessage({
                        id: 'LoginForm.emailInvalid',
                    });
                    const emailValid = validators.emailFormatValid(emailInvalidMessage);

                    // password
                    const passwordLabel = props.intl.formatMessage({
                        id: 'LoginForm.passwordLabel',
                    });
                    const passwordPlaceholder = props.intl.formatMessage({
                        id: 'LoginForm.passwordPlaceholder',
                    });
                    const passwordRequiredMessage = props.intl.formatMessage({
                        id: 'LoginForm.passwordRequired',
                    });
                    const passwordRequired = validators.requiredStringNoTrim(
                        passwordRequiredMessage
                    );

                    const classes = classNames(rootClassName || css.root, className);
                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || submitInProgress;

                    const passwordRecoveryLink = (
                        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
                            <FormattedMessage id="LoginForm.forgotPassword" />
                        </NamedLink>
                    );
                    const signUpLinkText = (
                        <NamedLink name="SignupPage">
                            <FormattedMessage id="LoginForm.signup.linkText" />
                        </NamedLink>
                    );
                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            <h2 style={{ fontSize: '34px', textAlign: 'center' }}>
                                <FormattedMessage id="AuthenticationPage.loginLinkText" />
                            </h2>
                            <br />
                            {/* <p><FormattedMessage id="LoginForm.signup.text" values={{linkText: signUpLinkText}}/></p> */}
                            <div>
                                <FieldTextInput
                                    type="email"
                                    id={formId ? `${formId}.email` : 'email'}
                                    name="email"
                                    autoComplete="email"
                                    label={emailLabel}
                                    placeholder={emailPlaceholder}
                                    validate={validators.composeValidators(
                                        emailRequired,
                                        emailValid
                                    )}
                                />
                                <FieldTextInput
                                    className={css.password}
                                    type="password"
                                    id={formId ? `${formId}.password` : 'password'}
                                    name="password"
                                    autoComplete="current-password"
                                    label={passwordLabel}
                                    placeholder={passwordPlaceholder}
                                    validate={passwordRequired}
                                />
                            </div>
                            <br />
                            <p style={{ marginBottom: '-40px' }}>
                                <FormattedMessage
                                    id="LoginForm.signup.text"
                                    values={{ linkText: signUpLinkText }}
                                />
                            </p>

                            <div className={css.bottomWrapper}>
                                <p className={css.bottomWrapperText}>
                                    <span className={css.recoveryLinkInfo}>
                                        <FormattedMessage
                                            id="LoginForm.forgotPasswordInfo"
                                            values={{ passwordRecoveryLink }}
                                        />
                                    </span>
                                </p>
                                <PrimaryButton
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    data-testid="btn-login-form"
                                >
                                    <FormattedMessage id="LoginForm.logIn" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />
            <div className={css.googleLogIn}>
                <h5>or</h5>
                {googleClientId && (
                    <GoogleLogin
                        className={css.googleButton}
                        clientId={googleClientId}
                        disabled={isAuthenticated}
                        buttonText={intl.formatMessage({
                            id: 'NewPartnersPage.Setup.Account.GoogleBtn',
                        })}
                        onSuccess={googleResponse}
                        onFailure={googleResponse}
                        cookiePolicy={'single_host_origin'}
                    />
                )}
                {loginError && !regularAccError && showError && (
                    <div
                        style={{
                            fontSize: 16,
                            color: '#ff0000',
                            fontWeight: 500,
                            fontFamily: 'Galano Grotesque',
                        }}
                    ></div>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    submitLoginWithGoogle: params => dispatch(signInWithGoogle(params)),
});

const mapStateToProps = state => {
    const { isAuthenticated, loginError } = state.Auth;
    const { currentUser } = state.user;
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
    };
};

export default compose(injectIntl)(
    connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent)
);
