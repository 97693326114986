import React, { useState, useRef, useEffect } from 'react'
import {
    AccountContainer,
    AccountForm,
    Title,
    SubTitle,
    Error,
    Form,
    Row,
    Holder, Wrapper,
    Label, Input, InputButton, InputBox, ButtonContainer, Dropdown, DropdownItem
} from './Styles';
import { useHistory } from 'react-router';
import { ReactComponent as ArrowDown } from '../../../../assets/newAssets/arrow-down-grey.svg';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../newComponents/Button';
import FactCard from '../FactCard/factCard.js'
import { updateOrganization, getCurrentOrganization, 
    getCurrentUser, uploadImage, updateUser,getOrganizationSectors 
} from '../../../../coreApi';
import {withRouter} from 'react-router-dom'


function ProfileForm(props) {
    const [errorType,setErrorType] = useState(0);
    const [isOpenSec, setIsOpenSec] = useState(false);
    const [isOpenRev, setIsOpenRev] = useState(false);
    const [isOpenInv, setIsOpenInv] = useState(false);
    const [isOpenOrg, setIsOpenOrg] = useState(false);
    const [selectedOptionSec, setSelectedOptionSec] = useState("");
    const [selectedOptionRev, setSelectedOptionRev] = useState("");
    const [selectedOptionInv, setSelectedOptionInv] = useState();
    const [selectedOptionOrg, setSelectedOptionOrg] = useState("");
    const [organization, setOrganization] = useState([" "]);
    const [organizationData, setOrganizationData] = useState([]);
    const [sector,setSector] = useState([]);
    const history=useHistory();
    const [userData, setUserData] = useState({
        id:0,
        organizationName: '',
        country: '',
        city: '',
        sector: '',
        revenue: '',
        otherDetails: '',
        inventoryYear: null,
    });
    let resOrg;
    useEffect(() => {
        const fetchData = async () => {
            resOrg = props.orgDetails;
            const resUser = await getCurrentUser();
            const sector = await getOrganizationSectors();
            if(sector){
                setSector(sector.data);
            }
            if (resOrg && resUser) {
                // const orgNames=resOrg.data.map((item) => item.organizationName )
                // setOrganization(orgNames);
                setOrganizationData(resOrg.data);
                setUserData(resOrg.data[0]);
            }
        }
        fetchData();
    }, [props.orgDetails]);

    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);
    const containerRef3 = useRef(null);
    const containerRef4 = useRef(null);
    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
            setIsOpenSec(false);
        }
        if (containerRef2.current && !containerRef2.current.contains(e.target)) {
            setIsOpenRev(false);
        }
        if (containerRef3.current && !containerRef3.current.contains(e.target)) {
            setIsOpenInv(false);
        }
        if (containerRef4.current && !containerRef4.current.contains(e.target)) {
            setIsOpenInv(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [containerRef1, containerRef2, containerRef3, containerRef4]);

    const openSector = () => {
        setIsOpenSec(!isOpenSec);
    };
    // const openOrg = () => {
    //     setIsOpenOrg(!isOpenOrg);
    // };
    const openRevenue = () => {
        setIsOpenRev(!isOpenRev);
    };
    const openInv = () => {
        setIsOpenInv(!isOpenInv);
    };
    function onSelectedSec(value) {
        setSelectedOptionSec(value);
        setIsOpenSec(false);
        setUserData({ ...userData, sector: value });
    };
    function onSelectedRev(value) {
        setSelectedOptionRev(value);
        setIsOpenRev(false);
        setUserData({ ...userData, revenue: value });
    };
    // function onSelectedOrg(value) {
    //     const Index = organizationData.findIndex( (item) => item.organizationName===value);
    //     console.log(Index);
    //     setUserData(organizationData[Index])
    //     setSelectedOptionOrg(value);
    //     setIsOpenOrg(false);
    //     props.setOrgName(value);
    // };
    function onSelectedInv(value) {
        setSelectedOptionInv(value);
        setIsOpenInv(false);
        setUserData({ ...userData, inventoryYear: value });
    };
    const revenue = [
        "Micro-org/ Start-up: <US$1 million",
        "Small org: <US$10 million",
        "Medium org: <US$ 100 million",
        "Large org: >US$ 100 million",
    ];
    const year = new Array(4).fill( new Date().getFullYear() - 4).map((val , index)=> val+index+1);
    let name, value;
    const handleChange = e => {
        name = e.target.name;
        value = e.target.value;
        const validString=/^[a-zA-Z ]*$/;
        if((name==="country"||name==="city")&& validString.test(value))
        {
            setUserData({ ...userData, [name]: value });
        }
        else if(name==="organizationName"|| name==="otherDetails"){
            setUserData({ ...userData, [name]: value });
        }
    };

    const postData = async e => {
        e.preventDefault();
        const newOrganizationDetails = {
            organizationName: (userData.organizationName ), sector: (userData.sector ? userData.sector : selectedOptionSec),
            revenue: (userData.revenue ? userData.revenue : selectedOptionRev), otherDetails: userData.otherDetails,
            city: userData.city, country: userData.country, inventoryYear: (userData.year ? userData.year : selectedOptionInv)
        };
        const res = await updateOrganization(newOrganizationDetails).then((response)=>{
            if(response.data.id!=undefined){
                history.push('/organisation/calculator/direct-emission',{data:{...userData,id:response.data.id, inventoryYear:selectedOptionInv}});
            }
        }).catch((error) => {
            if(error.response.data==='Organization already present')
            {
                setErrorType(2);
            }
            else{
                setErrorType(1);
            }
        });
    };

    return (
            <AccountContainer>
                <Form>
                    <Row>
                        <Holder>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.OrganizationName"/>
                            </Label>
                            <Input
                                type="text"
                                placeholder="company x"
                                name="organizationName"
                                value={userData.organizationName}
                                onChange={handleChange}
                                maxLength="100"
                            />
                            {/* <InputButton
                                name="Organizations"
                                onClick={openOrg}
                            >
                                {(selectedOptionOrg || userData && userData.organizationName)} <ArrowDown className="arrow" width="20px" height="20px" />
                            </InputButton>
                            {isOpenOrg && (
                                <Dropdown ref={containerRef4}>
                                    {organization.map((item,i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedOrg(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )
                            } */}
                        </Holder>
                    </Row>
                    <Row>
                        <Holder>
                            <Label htmlFor="name">
                                <FormattedMessage id="OrganisationCalculator.ProfileForm.Country"/>
                            </Label>
                            <Input
                                type="text"
                                placeholder="Peru"
                                name="country"
                                value={userData.country}
                                onChange={handleChange}
                                maxLength = "50"
                            />
                        </Holder>
                        <Holder>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.HeadOfficeCity"/>
                            </Label>
                            <Input
                                type="text"
                                placeholder="Lima"
                                name="city"
                                value={userData.city}
                                onChange={handleChange}
                                maxLength = "50"
                            />
                        </Holder>
                        <Holder medium>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.Sector"/>
                            </Label>
                            <InputButton
                                name="sector"
                                onClick={openSector}
                            >
                                {(selectedOptionSec || userData && userData.sector)} <ArrowDown className="arrow" width="20px" height="20px" />
                            </InputButton>
                            {isOpenSec && (
                                <Dropdown ref={containerRef1}>
                                    {sector.map((item,i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedSec(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )
                            }
                        </Holder>
                    </Row>
                    <Row>
                        <Holder>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.AnnualRevenue"/>
                            </Label>
                            <InputButton
                                name="revenue"
                                onClick={openRevenue}
                            >
                                {userData && userData.revenue || selectedOptionRev} <ArrowDown className="arrow" width="20px" height="20px" />
                            </InputButton>
                            {isOpenRev && (
                                <Dropdown ref={containerRef2}>
                                    {revenue.map((item,i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedRev(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )
                            }
                        </Holder>
                        <Holder>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.Year"/>
                            </Label>
                            <InputButton
                                name="inventoryYear"
                                onClick={openInv}
                            >
                                {selectedOptionInv || (userData && userData.inventoryYear) } <ArrowDown className="arrow" width="20px" height="20px" />
                            </InputButton>
                            {isOpenInv && (
                                <Dropdown ref={containerRef3}>
                                    {year.map((item,i) => (
                                        <DropdownItem key={i} onClick={() => onSelectedInv(item)}>
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            )
                            }
                        </Holder>
                    </Row>
                    <Row>
                        <Holder>
                            <Label htmlFor="name">
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.OtherDetails"/>
                            </Label>
                            <InputBox
                                type="text"
                                name="otherDetails"
                                onChange={handleChange}
                                value={userData.otherDetails}
                                placeholder="Type here.."
                                maxLength = "300"
                            >
                            </InputBox>
                        </Holder>
                    </Row>
                    <Error >
                      {errorType==1?<FormattedMessage id="OrganisationCalculator.Error.Field"/>:errorType==2?<FormattedMessage id="OrganisationCalculator.Error.OrganisationPresent"/>:null}
                    </Error>
                    <ButtonContainer>
                        <FactCard/>
                        <Button
                            onClick={postData}
                            variant="filled"
                            size="lg"
                        >
                            <FormattedMessage id="OrganisationCalculator.ProfileForm.Button"/>
                        </Button>
                    </ButtonContainer>
                </Form>
            </AccountContainer>
    )
}

export default withRouter(ProfileForm)
