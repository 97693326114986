import React from 'react';

import {ProfileImageContainer, ProfileImageTitle} from './Styles';

const ProfileImage = (props) =>{

    const {image, title } = props;
    return(
        <ProfileImageContainer>
            <ProfileImageTitle>
            {title}
            </ProfileImageTitle>
            <img src={image} width="100%" height="100%"/>
        </ProfileImageContainer>
    )
}

export default ProfileImage;