import styled from 'styled-components';

export const HowitWorksSection = styled.section`
    padding: 110px 4.8rem 100px;
    background-color: #f8fbf6;
    .title-box {
        .arrow-img {
            margin: 5px 0;
        }
    }
    .steps-how {
        max-width: 1065px;
        .regular {
            display: flex;
            flex-direction: column;
            gap: 100px;
        }
        .img-text {
            align-items: flex-start;
            .picture-box {
                flex: 1 0 auto;
                img {
                    border-radius: 8px;
                }
            }
        }
        .text-section {
            color: ${({ theme }) => theme.colors.text_color};
            line-height: 180%;
            margin-top: 16px;
            .li-list {
                position: relative;
                padding-left: 20px;
                &:first-child {
                    display: none;
                }
                &::before {
                    content: '•';
                    position: absolute;
                    left: 0;
                    top: -2px;
                    font-size: 30px;
                }
            }
        }
        .faq-link {
            color: ${({ theme }) => theme.colors.text_color};
            a {
                font-weight: bold;
                color: ${({ theme }) => theme.colors.text_color};
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .center-text-btn {
        gap: 20px;
        padding: 70px 20px 0;
        button {
            max-width: auto;
            margin: 0 auto;
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 33px 0px 35px;
        .steps-how {
            .step-row {
                box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
                padding: 16px;
                background-color: #fff;
                margin-top: 20px;
                margin: 20px 20px 35px;
                border-radius: 8px;
                .img-text {
                    .picture-box {
                        align-self: center;
                    }
                }
            }
            .slick-slider {
                padding-bottom: 45px;
            }
            .slick-next,
            .slick-prev {
                width: auto;
                height: 30px;
                border-radius: 8px;
                color: #fff;
                padding: 8px 30px;
                font-size: 14px;
                font-weight: 600;
                background: #6ea44c;
                bottom: 0px;
                top: auto;
                z-index: 99;
                transform: translate(0);
            }
            .slick-next {
                right: 15px;
                &::before {
                    content: none;
                }
            }
            .slick-prev {
                left: 15px;
                &::before {
                    content: none;
                }
            }
            .slick-disabled {
                background: #cecece;
            }
            .slick-dots {
                bottom: 43px;
            }
            .faq-link {
                padding-left: 20px;
                margin-top: 28px;
                a {
                    display: block;
                }
            }
        }
        .center-text-btn {
            gap: 12px;
            padding: 45px 20px 0;
            button {
                max-width: 330px;
                margin: 0 auto;
            }
        }
        .btnFootprint {
            padding: 0 14px;
            font-size: 14px;
        }
    }
`;
