import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './Styles';

const ShareBlogIcon = ({ icon: SourceIcon }) => {
  return (
    <StyledContainer>
      <SourceIcon />
    </StyledContainer>
  );
};

ShareBlogIcon.propTypes = {
  icon: PropTypes.node.isRequired,
};

ShareBlogIcon.defaultProps = {
  icon: '',
};

export default ShareBlogIcon;