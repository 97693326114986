import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";

// Flex template application uses English translations as default.
import enTranslation from './translations/en.json';
import spanishMessages from './translations/es.json';
import portugueseMessages from './translations/pt.json';

import localeData from 'react-intl/locale-data/es';
import portugueseLocaleData from 'react-intl/locale-data/pt';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';

addLocaleData([...localeData, ...portugueseLocaleData]);

const Context = React.createContext();

const storeLocale = (locale) => {
    window.localStorage.setItem('localePreference', locale)
}

const getLocale = () => {
    let locale =  window.localStorage.getItem('localePreference');
    const supportedLocale = ['es', 'en', 'pt']
    if(locale && supportedLocale.includes(locale)) {
        return locale
    }else {
        const userlang = navigator.language || navigator.userLanguage
        return /en-[a-zA-Z0-9]*/g.test(userlang) || userlang === 'en' ? 'en' :
        /pt-[a-zA-Z0-9]*/g.test(userlang) || userlang === 'pt' ? 'pt' : 'es'
    }
}

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
      super(...args);
  
      this.switchToEnglish = () => {
        this.setState({ locale: "en", messages: enTranslation });
        moment.locale('en')
        storeLocale('en')
      }
       
  
      this.switchToSpanish = () => {
        this.setState({ locale: "es", messages: spanishMessages });
        moment.locale('es')
        storeLocale('es')
      }
       

      this.switchToPortuguese = () => {
        this.setState({ locale: "pt", messages: portugueseMessages });
        moment.locale('pt')
        storeLocale('pt')
      }
        
  
      // pass everything in state to avoid creating object inside render method (like explained in the documentation)
      this.state = {
        locale: "es",
        messages: spanishMessages,
        switchToEnglish: this.switchToEnglish,
        switchToSpanish: this.switchToSpanish,
        switchToPortuguese: this.switchToPortuguese
      };
    }

    componentDidMount() {
        const locale = getLocale()
        if(locale === 'en') {
            this.switchToEnglish()
        }else if( locale == 'pt') {
            this.switchToPortuguese()
        }else {
            this.switchToSpanish()
        }
    }
  
    render() {
      const { children } = this.props;
      const { locale, messages } = this.state;
      return (
        <Context.Provider value={this.state}>
          <IntlProvider
            key={locale}
            locale={locale}
            messages={messages}
            defaultLocale="es"
          >
            {children}
          </IntlProvider>
        </Context.Provider>
      );
    }
  }
  
  export { IntlProviderWrapper, Context as IntlContext };