import React from 'react';
import { FormattedMessage } from 'react-intl';

import { WhyJoinMissionSection } from './Styles';
import IconWithText from '../../../../newComponents/IconWithText';
import planChooseIcon from '../../../../assets/newAssets/icons/plan-choose-icon.svg';
import handPlantIcon from '../../../../assets/newAssets/icons/hand-plant-icon.svg';
import leadIcon from '../../../../assets/newAssets/icons/lead_icon.svg';
import heroLeaf from '../../../../assets/newAssets/hero-leaf.png';

const WhyJoinMission = () => {
    return (
        <WhyJoinMissionSection heroLeaf={heroLeaf}>
            <div className="take-action-group flex justify-start flex-col mx-auto">
                <div className="title-box">
                    <h2>{<FormattedMessage id="PartnersPage.takeAction.title" />}</h2>
                </div>
                <div className="icon-text-container flex justify-space-between flex-row items-start">
                    <IconWithText
                        icon={handPlantIcon}
                        title={<FormattedMessage id="PartnersPage.takeAction.title.reason1" />}
                        text={<FormattedMessage id="PartnersPage.takeAction.text.reason1" />}
                    />
                    <IconWithText
                        icon={planChooseIcon}
                        title={<FormattedMessage id="PartnersPage.takeAction.title.reason2" />}
                        text={<FormattedMessage id="PartnersPage.takeAction.text.reason2" />}
                    />
                    <IconWithText
                        icon={leadIcon}
                        title={<FormattedMessage id="PartnersPage.takeAction.title.reason3" />}
                        text={<FormattedMessage id="PartnersPage.takeAction.text.reason3" />}
                    />
                </div>
            </div>
        </WhyJoinMissionSection>
    );
};

export default WhyJoinMission;
