import pollen from '../assets/newAssets/icons/pollen.svg';
import landscape from '../assets/newAssets/icons/landscape.svg';
import seedling from '../assets/newAssets/icons/leaf.svg';
import tree from '../assets/newAssets/icons/tree.svg';
import forest from '../assets/newAssets/icons/forest.svg';

import mapIcon from '../assets/newAssets/icons/map-icon.svg';
import cottonIcon from '../assets/newAssets/icons/cotton-icon.svg';
import leafIcon from '../assets/newAssets/icons/leaf-icon.svg';

import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import React from 'react';

export const plans = [
    {
        id: 0,
        planTitle: <FormattedMessage id="ProductComparison.people.pollen.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.pollen.description" />,
        planCost: 600,
        landProtected: 1000,
        emissionComp: 1000,
        treePlanted: 1,
        planImage: pollen,
        plan: 'polen',
        planType: 'polen_price',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    <FormattedMessage id="ProductComparison.people.pollen.planList1" />,
                    <FormattedMessage id="ProductComparison.people.pollen.planList2" />,
                    <FormattedMessage id="ProductComparison.people.pollen.planList3" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.pollen.planList4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.pollen.planList5"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.pollen.planList6"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
        ],
        impactList: [
            { impactIc: `${mapIcon}`, listTxt: '1,000 m2' },
            { impactIc: `${cottonIcon}`, listTxt: '1,000 Kg CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTree"
                        values={{ trees: 1 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 1,
        planTitle: <FormattedMessage id="ProductComparison.people.seed.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.seed.description" />,
        planCost: 1500,
        landProtected: 5000,
        emissionComp: 5000,
        treePlanted: 1,
        planImage: seedling,
        plan: 'seed',
        planType: 'semilla',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    <FormattedMessage id="ProductComparison.people.seed.planList1" />,
                    <FormattedMessage id="ProductComparison.people.seed.planList2" />,
                    <FormattedMessage id="ProductComparison.people.seed.planList3" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.seed.planList4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.seed.planList5"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.seed.planList6"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
        ],
        impactList: [
            { impactIc: `${mapIcon}`, listTxt: '5,000 m2' },
            { impactIc: `${cottonIcon}`, listTxt: '5,000 Kg CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTree"
                        values={{ trees: 1 }}
                    />
                ),
            },
        ],
        isRecommended: true,
    },
    {
        id: 2,
        planTitle: <FormattedMessage id="ProductComparison.people.tree.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.tree.description" />,
        planCost: 3000,
        landProtected: 10000,
        emissionComp: 10000,
        treePlanted: 2,
        planImage: tree,
        plan: 'tree',
        planType: 'arbol',
        planTarget: 'personas',
        featureList: [
            {
                featureList: [
                    <FormattedMessage id="ProductComparison.people.tree.planList1" />,
                    <FormattedMessage id="ProductComparison.people.tree.planList2" />,
                    <FormattedMessage id="ProductComparison.people.tree.planList3" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.tree.planList4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.tree.planList5"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.tree.planList6"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
        ],
        impactList: [
            { impactIc: `${mapIcon}`, listTxt: '10,000 m2' },
            { impactIc: `${cottonIcon}`, listTxt: '10,000 Kg CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 2 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 3,
        planTitle: <FormattedMessage id="ProductComparison.people.forest.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.forest.description" />,
        planCost: 7500,
        landProtected: 25000,
        emissionComp: 25000,
        treePlanted: 4,
        planImage: forest,
        plan: 'forest',
        planType: 'bosque',
        planTarget: 'familia',
        featureList: [
            {
                featureList: [
                    <FormattedMessage id="ProductComparison.people.forest.planList1" />,
                    <FormattedMessage id="ProductComparison.people.forest.planList2" />,
                    <FormattedMessage id="ProductComparison.people.forest.planList3" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.forest.planList4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.forest.planList5"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.forest.planList6"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
        ],
        impactList: [
            { impactIc: `${mapIcon}`, listTxt: '25,000 m2' },
            { impactIc: `${cottonIcon}`, listTxt: '25,000 Kg CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 4 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 4,
        planTitle: <FormattedMessage id="ProductComparison.people.landscape.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.people.landscape.description" />,
        planCost: 30000,
        landProtected: 100000,
        emissionComp: 100000,
        treePlanted: 10,
        planImage: landscape,
        plan: 'landscape',
        planType: 'paisaje',
        planTarget: 'familia',
        featureList: [
            {
                featureList: [
                    <FormattedMessage id="ProductComparison.people.landscape.planList1" />,
                    <FormattedMessage id="ProductComparison.people.landscape.planList2" />,
                    <FormattedMessage id="ProductComparison.people.landscape.planList3" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.landscape.planList4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.landscape.planList5"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.people.landscape.planList6"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
        ],
        impactList: [
            { impactIc: `${mapIcon}`, listTxt: '100,000 m2' },
            { impactIc: `${cottonIcon}`, listTxt: '100,000 Kg CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 10 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 5,
        planTitle: <FormattedMessage id="ProductComparison.business.seed.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.seed.description" />,
        planCost: 9000,
        landProtected: 25000,
        emissionComp: 25000,
        treePlanted: 4,
        planImage: seedling,
        plan: 'seed',
        planType: 'semillaEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.seed.featureMeasure" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.seed.featureMeasureShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.seed.featureMeasure1" />,
                ],
            },
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.seed.featureImprove" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.seed.featureImproveShort" />
                ),
                featureList: [
                    <FormattedHTMLMessage
                        id="ProductComparison.business.seed.featureImprove1"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedMessage id="ProductComparison.business.seed.featureImprove2" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.seed.featureImprove3"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.seed.featureImprove4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                ],
            },
            {
                featureTitle: <FormattedMessage id="ProductComparison.business.seed.featureLead" />,
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.seed.featureLeadShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.seed.featureLead1" />,
                ],
            },
        ],
        impactList: [
            {
                impactIc: `${mapIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactHectares"
                        values={{ hectares: 2.5 }}
                    />
                ),
            },
            { impactIc: `${cottonIcon}`, listTxt: '25 tonnes CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 4 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 6,
        planTitle: <FormattedMessage id="ProductComparison.business.tree.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.tree.description" />,
        planCost: 36000,
        landProtected: 100000,
        emissionComp: 100000,
        treePlanted: 10,
        planImage: tree,
        plan: 'tree',
        planType: 'arbolEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.tree.featureMeasure" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.tree.featureMeasureShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.tree.featureMeasure1" />,
                    <FormattedMessage id="ProductComparison.business.tree.featureMeasure2" />,
                ],
            },
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.tree.featureImprove" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.tree.featureImproveShort" />
                ),
                featureList: [
                    <FormattedHTMLMessage
                        id="ProductComparison.business.tree.featureImprove1"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedMessage id="ProductComparison.business.tree.featureImprove2" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.tree.featureImprove3"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.tree.featureImprove4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedMessage id="ProductComparison.business.tree.featureImprove5" />,
                ],
            },
            {
                featureTitle: <FormattedMessage id="ProductComparison.business.tree.featureLead" />,
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.tree.featureLeadShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.tree.featureLead1" />,
                ],
            },
        ],
        impactList: [
            {
                impactIc: `${mapIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactHectares"
                        values={{ hectares: 10 }}
                    />
                ),
            },
            { impactIc: `${cottonIcon}`, listTxt: '100 tonnes CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 10 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
    {
        id: 7,
        planTitle: <FormattedMessage id="ProductComparison.business.forest.planName" />,
        planDescription: <FormattedMessage id="ProductComparison.business.forest.description" />,
        planCost: 90000,
        landProtected: 250000,
        emissionComp: 250000,
        treePlanted: 40,
        planImage: forest,
        plan: 'forest',
        planType: 'bosqueEmpresas',
        planTarget: 'empresa',
        featureList: [
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.forest.featureMeasure" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.forest.featureMeasureShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.forest.featureMeasure1" />,
                    <FormattedMessage id="ProductComparison.business.forest.featureMeasure2" />,
                ],
            },
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.forest.featureImprove" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.forest.featureImproveShort" />
                ),
                featureList: [
                    <FormattedHTMLMessage
                        id="ProductComparison.business.forest.featureImprove1"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedMessage id="ProductComparison.business.forest.featureImprove2" />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.forest.featureImprove3"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedHTMLMessage
                        id="ProductComparison.business.forest.featureImprove4"
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />,
                    <FormattedMessage id="ProductComparison.business.forest.featureImprove5" />,
                ],
            },
            {
                featureTitle: (
                    <FormattedMessage id="ProductComparison.business.forest.featureLead" />
                ),
                featureShort: (
                    <FormattedMessage id="ProductComparison.business.forest.featureLeadShort" />
                ),
                featureList: [
                    <FormattedMessage id="ProductComparison.business.forest.featureLead1" />,
                    <FormattedMessage id="ProductComparison.business.forest.featureLead2" />,
                ],
            },
        ],
        impactList: [
            {
                impactIc: `${mapIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactHectares"
                        values={{ hectares: 25 }}
                    />
                ),
            },
            { impactIc: `${cottonIcon}`, listTxt: '250 tonnes CO2' },
            {
                impactIc: `${leafIcon}`,
                listTxt: (
                    <FormattedMessage
                        id="ProductComparison.business.ImpactTrees"
                        values={{ trees: 40 }}
                    />
                ),
            },
        ],
        isRecommended: false,
    },
];
