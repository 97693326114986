import React from 'react';
import AmazonasExplorer from '../../assets/logo-aliados/AmazonasExplorer.png';
import AndeanLodges from '../../assets/logo-aliados/AndeanLodges.png';
import auqui from '../../assets/logo-aliados/auqui.jpg';
import ayni from '../../assets/logo-aliados/Ayni.png';
import cientifica from '../../assets/logo-aliados/cientifica.png';
import delosi from '../../assets/logo-aliados/delosi.jpg';
import explorandes from '../../assets/logo-aliados/explorandes.png';
import FloraYFauna from '../../assets/logo-aliados/FloraYFauna.jpg';
import albergue from '../../assets/logo-aliados/Albergue.png';
import afpPrima from '../../assets/logo-aliados/afpPrima.jpg';
import GBC from '../../assets/logo-aliados/GBC.png';
import gerpal from '../../assets/logo-aliados/gerpal.png';
import hoseg from '../../assets/logo-aliados/hoseg.png';
import peru2021 from '../../assets/logo-aliados/peru2021.jpg';
import pukanina from '../../assets/logo-aliados/pukanina.jpg';
import Rainforest from '../../assets/logo-aliados/Rainforest.jpg';
import sistemab from '../../assets/logo-aliados/siste.jpg';
import strategik from '../../assets/logo-aliados/strategik.png';
import tedx from '../../assets/logo-aliados/tedx.png';
import toyota from '../../assets/logo-aliados/toyota.jpg';
import { Communtiy, Title, CommunityList, LogoContainer } from '../Partners/Style';
import { FormattedMessage } from 'react-intl';
import Carousel from 'react-grid-carousel';
import { NamedLink } from '../../components';

const community = [
    { slug: '5da7b6cf-b5bb-4d42-b1ab-736c3f74e287', src: AmazonasExplorer, link: true },
    { slug: '5da7b454-5edc-4c2d-9922-a8455051a93f', src: AndeanLodges, link: true },
    { slug: '5da7b485-ba34-455d-b58a-33c46e616f64', src: auqui, link: true },
    { slug: '5da7b718-3427-404d-986a-c3ae5dcac06f', src: ayni, link: true },
    { slug: '5da7b526-60cd-4a75-8961-00c7ff8eab60', src: albergue, link: true },
    { slug: '5da7b296-901f-4c85-ae01-834edb9477a2', src: explorandes, link: true },
    { slug: '5da7b4a7-832f-4c37-b576-ef3f55edbc87', src: FloraYFauna, link: true },
    { slug: '5da7b6f6-1d41-4f30-97dd-74ad33066d55', src: gerpal, link: true },
    { slug: '5da7b6a8-3ae4-4ef5-9313-5d6ec787414c', src: hoseg, link: true },
    { slug: '5da7b5cc-d0b6-4113-9eac-a1cb9e3ead38', src: afpPrima, link: true },
    { slug: '5da7b325-8a45-4867-83d4-33ca35e76a18', src: pukanina, link: true },
    { slug: '5da7b59d-94d0-4adb-9ebe-8221c2343adf', src: Rainforest, link: true },
    { slug: '5da7b56a-ccd5-433f-b5f6-bcfdd0c08214', src: strategik, link: true },
    { slug: '5da7b603-8664-4164-baf0-b2693347b655', src: cientifica, link: true },
    { slug: '5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9', src: peru2021, link: true },
    { slug: '5da7b650-6609-43fd-b677-5d93500b633b', src: GBC, link: true },
    { slug: '5da7b385-c220-4af4-bd73-ce817e93f34a', src: sistemab, link: true },
    { slug: '5da7b352-8b0f-4d6b-8088-2981d17f8367', src: tedx, link: true },
    { slug: null, src: toyota, link: false },
    { slug: null, src: delosi, link: false },
];

function Community() {
    return (
        <Communtiy>
            <Title>
                <FormattedMessage id="Business.organizations.title" />
            </Title>
            <CommunityList>
                <Carousel
                    cols={5}
                    rows={2}
                    gap={100}
                    loop
                    hideArrow={true}
                    showDots={true}
                    scrollSnap={false}
                    mobileBreakpoint={359}
                    responsiveLayout={[
                        {
                            breakpoint: 1185,
                            cols: 4,
                            rows: 2,
                        },
                        {
                            breakpoint: 985,
                            cols: 3,
                            rows: 2,
                            gap: 30,
                        },
                        {
                            breakpoint: 740,
                            cols: 2,
                            rows: 2,
                            gap: 10,
                        },
                        {
                            breakpoint: 500,
                            cols: 2,
                            rows: 3,
                            gap: 30,
                        },
                    ]}
                >
                    {community.map((item, index) => {
                        return (
                            <Carousel.Item>
                                <LogoContainer key={index}>
                                    {item.link ? (
                                        <NamedLink name="ProfilePage" params={{ slug: item.slug }}>
                                            <img src={item.src} width="100%" height="auto" />
                                        </NamedLink>
                                    ) : (
                                        <img src={item.src} width="100%" height="auto" />
                                    )}
                                </LogoContainer>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </CommunityList>
        </Communtiy>
    );
}

export default Community;
