// reducers/subscriptionReducer.js
const initialState = {
    selectedRole: '',
};

const GuardianReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROLE':
            return { ...state, selectedRole: action.payload };
        default:
            return state;
    }
};

export const setSelectedRole = selectedRole => ({
    type: 'SET_SELECTED_ROLE',
    payload: selectedRole,
});

export default GuardianReducer;
