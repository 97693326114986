import React from 'react';
import {
    Logo,
    NamedLink,
} from '../../components';
import LogoImageMobile from '../Logo/logo-regenera-negativo.svg';
import config from '../../config';
import css from './TopbarCalculator.css';

const TopbarCalculator = () => {
    return (
        <div className={css.navWrapper}>
            <NamedLink name="LandingPage">
                {/* <Logo
                    format="mobile"
                    className={css.logo}
                    alt="alt text"
                /> */}
                <img src={LogoImageMobile} className={css.logo} alt={config.siteTitle} />
            </NamedLink>
        </div>
    );
};

export default TopbarCalculator;