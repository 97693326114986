import { graphqlFetchQuery } from '../../coreApi';

export const handleQueryLanguage = async lang => {
    if (lang === 'ES') {
        return 'es';
    }
    if (lang === 'PT') {
        return 'pt-br';
    }
    return 'en';
};

export const getLatestBlogsQuery = lang => {
    return `
      query getLatestBlogs {
        blogPosts (first: 3, where: {wpmlLanguage:"${lang}", orderby: {field: DATE, order: DESC}}){
          nodes {
            title
            content
            slug
            blog_details {
              readTime
              thumbnail
              featuredImage
              summary
              publishDate
            }
            writer {
              name
              about
              photo
            }
            taxonomy {
              category {
                name
                id
              }
              tags {
                name
                id
              }
            }
          }
        }
      }
    `;
};

export const getLatestBlogs = async lang => {
    try {
        const result = await graphqlFetchQuery(getLatestBlogsQuery(lang));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getAllCategoriesQuery = lang => {
    return ` 
      query getAllCategories {
        categories(where: {wpmlLanguage: "${lang}"}) {
          nodes {
            name
            slug
          }
        }
      }   
    `;
};

export const getAllCategories = async lang => {
    try {
        const result = await graphqlFetchQuery(getAllCategoriesQuery(lang));
        return { status: 'success', data: result.categories.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getAllLandscapesQuery = lang => {
    return ` 
    query getAllLandscapes {
      landscapes(where: {wpmlLanguage: "${lang}"}) {
        nodes {
          name
          slug
        }
      }
    }   
  `;
};

export const getAllLandscapes = async lang => {
    try {
        const result = await graphqlFetchQuery(getAllLandscapesQuery(lang));
        return { status: 'success', data: result.landscapes.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getBlogsByCategoryQuery = (lang, categoryName, count = 3) => {
    return `
    query getDataByCategory {
      blogPosts(
        first: ${count},
        where: {
          wpmlLanguage:"${lang}",
          orderby: {field: DATE, order: DESC},
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: ["${categoryName}"],
                taxonomy: CATEGORY,
                operator: IN,
                field: NAME
              },
            ]
          }
        }
      ){
        nodes{
            title
            slug
            blog_details {
              thumbnail
              featuredImage
              readTime
              summary
              publishDate
            }
            taxonomy {
              tags {
                name
                id
              }
            }
            writer {
              name
            }
          }
        }
      }
      `;
};

export const getBlogsByCategory = async (lang, categoryName) => {
    try {
        const result = await graphqlFetchQuery(getBlogsByCategoryQuery(lang, categoryName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getAllBlogsByCategoryQuery = (lang, categoryName) => {
    return `query getBlogsByCategory {
      blogPosts(
        first: 1000,
        where: {
          wpmlLanguage:"${lang}",
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: ["${categoryName}"],
                taxonomy: CATEGORY,
                operator: IN,
                field: NAME
              },
            ]
          }
        }
      ){
        nodes {
          title
          slug
          blog_details {
            thumbnail
            featuredImage
            readTime
            summary
            publishDate
          }
          taxonomy {
            tags {
              name
              id
            }
          }
          writer {
            name
          }
        }
      }
    } 
    `;
};

export const getAllBlogsByCategory = async (lang, categoryName) => {
    try {
        const result = await graphqlFetchQuery(getAllBlogsByCategoryQuery(lang, categoryName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getRelatedBlogsByTagsQuery = (lang, tagSlugs, count = 4) => {
    const formattedTags = tagSlugs.map(tag => `"${tag}"`).join(', ');
    return `query getRelatedBlogsByTags {
    blogPosts(
      first: ${count},
      where: {
        wpmlLanguage:"${lang}",
        taxQuery: {
          relation: OR,
          taxArray: [
            {
              terms: [${formattedTags}],
              taxonomy: TAG,
              operator: IN,
              field: SLUG
            },
          ]
        }
      }
    ){
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          readTime
          summary
          publishDate
        }
        taxonomy {
          category {
            name
            slug
          }
          tags {
            name
            slug
          }
          landscape {
            name
            id
            slug
          }
        }
        writer {
          name
        }
      }
    }
  } 
  `;
};

export const getRelatedBlogsByTags = async (lang, tagSlugs) => {
    try {
        const result = await graphqlFetchQuery(getRelatedBlogsByTagsQuery(lang, tagSlugs));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getBlogBySlugQuery = slug => {
    return `query getSingleBlogBySlug {
      blogPost(id: "${slug}", idType: SLUG) {
        title
        slug
        content
        blog_details {
          readTime
          thumbnail
          featuredImage
          summary
          publishDate
        }
        writer {
          name
          photo
          about
        }
        taxonomy {
          category {
            name
            id
            slug
          }
          tags {
            name
            id
            slug
          }
          landscape {
            name
            id
            slug
          }
        }
        locale {
          id
        }
        translated {
          title
          slug
          content
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            photo
            about
          }
          taxonomy {
            category {
              name
              id
              slug
            }
            tags {
              name
              id
              slug
            }
            landscape {
              name
              id
              slug
            }
          }
          locale {
            id
          }
        }
        translations {
          locale
          href
        }
      }
    }
    `;
};

export const getBlogBySlug = async slug => {
    try {
        const result = await graphqlFetchQuery(getBlogBySlugQuery(slug));
        return { status: 'success', data: result.blogPost };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

const searchBlogsWithAuthorQuery = (lang, after, writer) => {
    return `query getLatestBlogs {
    blogPosts (where: {wpmlLanguage: "${lang}", writerName: "${writer}", orderby: {field: DATE, order: DESC}}, first: 6, after:"${after}"){
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          publishDate
        }
        writer {
          name
        }
        taxonomy {
          category {
            name
            slug
          }
          tags {
            name
            slug
          }
          landscape {
            name
            slug
          }
        }
      }
    }
  }`;
};

const searchBlogsQuery = (lang, after, query) => {
    return `query getLatestBlogs {
      blogPosts (where: {wpmlLanguage: "${lang}", search: "${query}", orderby: {field: DATE, order: DESC}}, first: 6, after:"${after}"){
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          title
          slug
          blog_details {
            thumbnail
            featuredImage
            publishDate
          }
          writer {
            name
          }
          taxonomy {
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
            landscape {
              name
              slug
            }
          }
        }
      }
    }`;
};

const searchBlogsWithCategoryFilterQuery = (
    lang,
    after,
    query,
    category,
    landscape,
    tag = '',
    writer = ''
) => {
    const formattedCategory = category.map(slug => `"${slug}"`).join(', ');
    //const formattedTag = tag.map(slug => `"${slug}"`).join(', ');
    const formattedLandscape = landscape.map(slug => `"${slug}"`).join(', ');
    return `query getBlogsByCategory {
      
      blogPosts(
        
        where: {
          wpmlLanguage:"${lang}",
          search: "${query}",
          writerName: "${writer}",
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: [${formattedCategory}],
                taxonomy: CATEGORY,
                operator: IN,
                field: SLUG
              },
              {
                terms: ["${tag}"],
                taxonomy: TAG,
                operator: IN,
                field: SLUG
              },
              {
                terms: [${formattedLandscape}],
                taxonomy: LANDSCAPE,
                operator: IN,
                field: SLUG
              }
            ]
          }
          , orderby: {field: DATE, order: DESC}
        }
        , first: 6, after:"${after}"
      ){
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          title
          slug
          blog_details {
            thumbnail
            featuredImage
            publishDate
          }
          writer {
            name
          }
          taxonomy {
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
            landscape {
              name
              slug
            }
          }
        }
      }
    } 
    `;
};

export const searchBlogs = async (lang, after, query = '', category, landscape, tag, writer) => {
    try {
        let result;
        console.log(
            'query,category,landscape, tag, writer',
            query,
            category,
            landscape,
            tag,
            writer
        );
        if (category.length > 0 || landscape.length > 0 || tag) {
            result = await graphqlFetchQuery(
                searchBlogsWithCategoryFilterQuery(
                    lang,
                    after,
                    query,
                    category,
                    landscape,
                    tag,
                    writer
                )
            );
        } else if (writer) {
            result = await graphqlFetchQuery(searchBlogsWithAuthorQuery(lang, after, writer));
        } else {
            result = await graphqlFetchQuery(searchBlogsQuery(lang, after, query));
        }
        return { status: 'success', data: result.blogPosts };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

const getAllBlogsByTagQuery = (lang, tagName) => {
    return `query getDataByTag {
    blogPosts(
      first: 1000,
      where: {
        wpmlLanguage: "${lang}",
        taxQuery: {
          relation: OR,
          taxArray: [
            {
              terms: ["${tagName}"],
              taxonomy: TAG,
              operator: IN,
              field: NAME
            },
          ]
        }
      }
    ){
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          readTime
          summary
          publishDate
        }
        taxonomy {
          tags {
            name
            id
          }
        }
        writer {
          name
        }
      }
    }
  }  
  `;
};

export const getAllBlogsByTag = async (lang, tagName) => {
    try {
        const result = await graphqlFetchQuery(getAllBlogsByTagQuery(lang, tagName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getTenLatestBlogsQuery = lang => {
    return `
    query getLatestBlogs {
      blogPosts (first: 10, where: {wpmlLanguage:"${lang}", orderby: {field: DATE, order: DESC}}){
        nodes {
          title
          content
          slug
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            about
            photo
          }
          taxonomy {
            category {
              name
              id
            }
            tags {
              name
              id
            }
          }
        }
      }
    }
  `;
};

export const getTenLatestBlogs = async lang => {
    try {
        const result = await graphqlFetchQuery(getTenLatestBlogsQuery(lang));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getLatestBlogsByTagQuery = (lang, tagSlug) => {
    return `
  query getLatestBlogsByTag {

    blogPosts(
      first: 3
      where: {
       wpmlLanguage: "${lang}",
       taxQuery: {
        relation: AND, taxArray: [{terms: ["${tagSlug}"], taxonomy: TAG, operator: IN, field: SLUG}]
        }
      }
    ) {
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          publishDate
        }
      }
    }
  }
`;
};

export const getLatestBlogsByTag = async (lang, tagSlug) => {
    try {
        const result = await graphqlFetchQuery(getLatestBlogsByTagQuery(lang, tagSlug));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};
