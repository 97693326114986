import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

import ExternalLink from '../ExternalLink/ExternalLink';

const EnglishPrivacyPolicy = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective date: January 1, 2019</p>

      <p>Nature Services Peru SA ("we", "our") operates the website <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> (hereinafter, the "Service").</p>
      <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the options available to you in relation to that data.</p>
      <p>We use your data to provide and improve the Service. By using the Service, you consent to the collection and use of information in accordance with this policy. Unless this Privacy Policy defines otherwise, the terms used in it have the same meanings as our Terms and Conditions, available at <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink></p>
      <h2>
            <span>Definitions</span>
        </h2>
        <p>
            <span>Service</span>
        </p>
        <p>
            <span>Service is the website<ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink>operated by Nature Services Peru SA</span>
        </p>
        <p>
            <span>Personal information</span>
        </p>
        <p>
            <span>Personal data means data about a living natural person who can be identified from such data (or from such data and other information that we have or are likely to have available).</span>
        </p>
        <p>
            <span>Usage data</span>
        </p>
        <p>
            <span>Usage data is the data collected automatically, generated by the use of the Service or by the infrastructure of the Service itself (for example, the duration of the visit to a page).</span>
        </p>
        <p>
            <span>Cookies</span>
        </p>
        <p>
            <span>Cookies are small files stored on your device (computer or mobile device).</span>
        </p>
        <h2>
            <span>Information collection and use</span>
        </h2>
        <p>
            <span>We collect different types of information for various purposes to provide and improve the Service to you.</span>
        </p>
        <h3>
            <span>Types of data collected</span>
        </h3>
        <h4>
            <span>Personal information</span>
        </h4>
        <p>
            <span>When you use our Service, we may ask you to provide us with certain personally identifiable information that may be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to, the following:</span>
        </p>
        <ul>
            <li>
                <span>E-mail address</span>
            </li>
            <li>
                <span>Name and surname</span>
            </li>
            <li>
                <span>Phone number</span>
            </li>
            <li>
                <span>Address, town, province, postal code, city</span>
            </li>
        </ul>
        <p>
            <span>We may use your Personal Data to contact you in order to provide you with fact sheets, marketing or promotional materials and other information that may be of interest to you. You can opt out of receiving part or all of these communications by clicking on the unsubscribe link, following the instructions provided in the email message we send you, or by contacting us.</span>
        </p>
        <h4>
            <span>Usage data</span>
        </h4>
        <p>
            <span>We also collect information about how the Service is accessed and used ('Usage Data'). This Usage Data may include information such as your computer's Internet protocol address (for example, IP address), browser type, browser version, the pages you visit on our Service. , the time and date of your visit, the time you spend on those pages, unique device identifiers, and other diagnostic information.</span>
        </p>
        <h4>
            <span>Cookie and tracking data</span>
        </h4>
        <p>
            <span>We use cookies and similar tracking technologies to track activity on our Service and maintain certain information.</span>
        </p>
        <p>
            <span>Cookies are files with a small amount of data that may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies also used are beacons, tags, and scripts to collect and track information, as well as to improve and analyze our Service.</span>
        </p>
        <p>
            <span>You can instruct your browser to reject all cookies or to notify you when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service.</span>
        </p>
        <p>
            <span>Examples of Cookies we use:</span>
        </p>
        <ul>
            <li>
                <span>Session cookies.</span>
                <span>&nbsp;We use Session Cookies to operate our Service.</span>
            </li>
            <li>
                <span>Preference cookies.</span>
                <span>&nbsp;We use Preference Cookies to remember your preferences and various settings.</span>
            </li>
            <li>
                <span>Security cookies.</span>
                <span>&nbsp;We use Security Cookies for security purposes.</span>
            </li>
        </ul>
        <h2>
            <span>Use of data</span>
        </h2>
        <p>
            <span>Nature Services Peru SA uses the collected data for various purposes:</span>
        </p>
        <ul>
            <li>
                <span>Provide and maintain our Service</span>
            </li>
            <li>
                <span>Notify you of changes to our Service</span>
            </li>
            <li>
                <span>Allow you to participate in interactive features of our Service when you choose to do so</span>
            </li>
            <li>
                <span>Provide customer support</span>
            </li>
            <li>
                <span>Collect analytics that allow us to improve our Service</span>
            </li>
            <li>
                <span>Control the use of our Service</span>
            </li>
            <li>
                <span>Detect, avoid and address technical problems</span>
            </li>
            <li>
                <span>To offer you news, special offers and general information about other goods, services and events that we can offer and that are similar to those that you have already purchased or about which you have made inquiries, unless you have opted not to receive this information.</span>
            </li>
        </ul>
        <h2>
            <span>Data transfer</span>
        </h2>
        <p>
            <span>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.</span>
        </p>
        <p>
            <span>If you are outside of Peru and decide to provide us with information, please note that we transfer the data, including Personal Data, to Peru and that we process it there.</span>
        </p>
        <p>
            <span>Your acceptance of this Privacy Policy followed by your submission of this information represents your agreement to such transfer.</span>
        </p>
        <p>
            <span>Nature Services Peru SA will undertake all reasonable measures necessary to ensure that your data is treated safely and in accordance with this Privacy Policy and no transfer of your Personal Data will be made to an organization or country, unless there are adequate controls in place including the security of your data and other personal information.</span>
        </p>
        <h2>
            <span>Disclosure of data</span>
        </h2>
        <h3>
            <span>Disclosure by legal obligation</span>
        </h3>
        <p>
            <span>In certain circumstances, Nature Services Peru SA may be obliged to disclose your Personal Data when required by law or in response to valid requests from public authorities (for example, a court or a public body).</span>
        </p>
        <h3>
            <span>Legal requirements</span>
        </h3>
        <p>
            <span>Nature Services Peru SA may disclose your Personal Data in good faith when it considers that this action is necessary for the following:</span>
        </p>
        <ul>
            <li>
                <span>Comply with a legal obligation</span>
            </li>
            <li>
                <span>Protect and defend the rights or assets of Nature Services Peru SA</span>
            </li>
            <li>
                <span>Prevent or investigate possible infractions in relation to the Service</span>
            </li>
            <li>
                <span>Protect the personal safety of users of the Service or the public</span>
            </li>
            <li>
                <span>Protect yourself from legal consequences</span>
            </li>
        </ul>
        <h2>
            <span>Data security</span>
        </h2>
        <p>
            <span>The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. Although we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span>
        </p>
        <h2>
            <span>Service providers</span>
        </h2>
        <p>
            <span>We may contract with third parties and legal entities to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to provide services related to the Service, or to help us analyze how our Service is used.</span>
        </p>
        <p>
            <span>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span>
        </p>
        <h3>
            <span>Payments</span>
        </h3>
        <p>
            <span>We may offer payment products and / or services within the Service. In that case, we use third-party services for payment processing (for example, payment processors). We provide directly to our third party payment processors, whose use of personal data is regulated by their Privacy Policy. These payment processors meet the standards set by the PCI-DSS standard managed by the PCI SSC (PCI Security Standards Council) committee, which is a joint effort of brands such as Visa, MasterCard, American Express and Discover. The requirements of the PCI-DSS standard help ensure secure management of payment data.</span>
        </p>
        <p>
            <span>The payment processors we work with are: </span>
        </p>
        <p>
            <span>Stripe</span>
        </p>
        <p>
            <span>Its Privacy Policy can be consulted at </span>
            <span>
                <ExternalLink href="https://www.google.com/url?q=https://stripe.com/us/privacy&amp;sa=D&amp;ust=1571856898984000">https://stripe.com/us/privacy</ExternalLink>
            </span>
        </p>
        <h2>
            <span>Links to other sites</span>
        </h2>
        <p>
            <span>Our Service may contain links to other sites not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly recommend that you review the Privacy Policy of every site you visit.</span>
        </p>
        <p>
            <span>We do not have any control or responsibility for the content, privacy policies or practices of third party sites or services.</span>
        </p>
        <h2>
            <span>Minor privacy</span>
        </h2>
        <p>
            <span>Our service is not directed to anyone under the age of 18 (hereinafter, "Minor").</span>
        </p>
        <p>
            <span>We do not knowingly collect personally identifiable information from children under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from minors without verification of parental consent, we take steps to remove that information from our servers.</span>
        </p>
        <h2>
            <span>Changes to this Privacy Policy</span>
        </h2>
        <p>
            <span>We may update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</span>
        </p>
        <p>
            <span>We will inform you via e-mail and / or a prominent notice about our Service before the change takes effect and we will update the "effective date" at the top of this Privacy Policy.</span>
        </p>
        <p>
            <span>We recommend that you review this Privacy Policy periodically to see if any changes have been made. Changes to this Privacy Policy are effective when they are posted on this page.</span>
        </p>
        <h2>
            <span>Contact us</span>
        </h2>
        <p>
            <span>If you have any questions about this Privacy Policy, please contact us: </span>
        </p>
        <ul>
            <li>
                <span>By e-mail: {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}</span>
            </li>
            <li>
                <span>Visiting this page on our website: <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}/privacy-policy`}>{process.env.REACT_APP_BASE_URL}/privacy-policy</ExternalLink></span>
            </li>
        </ul>
    </div>
  );
};

EnglishPrivacyPolicy.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

EnglishPrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default EnglishPrivacyPolicy;
