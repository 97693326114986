import React, { useState, useEffect } from 'react';
import Header from '../section/Header/Header.js';
import Footer from '../section/Footer/Footer.js';
import Tabs from '../section/Tabs/Tabs.js';
import Heading from '../section/Heading/Heading.js';
import Form from '../section/ProfileForm/ProfileForm.js';
import {DetailsContainer} from './Style.js'
import { getCurrentOrganization } from '../../../coreApi';
import { isEmpty, isNil } from 'lodash';
import { useSelector, connect } from 'react-redux';
import { useHistory, Redirect, withRouter} from 'react-router';
import { useDispatch } from 'react-redux';
import { OrganisationErrorAction } from '../../../ducks/Toast.duck'
const OrganisationDetailsPage = (props)=>{
    const [orgDetails,setOrgDetails]=useState({});
    const [orgName,setOrgName]=useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.user && props.user.userType === "personas") {
            dispatch(OrganisationErrorAction({visible:true,error:'Log in as organisation to access footprint calculator for organisations'}))
            history.push('/');
        }
        const fetchData = async () => {
            const Details = await getCurrentOrganization();
            setOrgDetails(Details);
            setOrgName(Details.data[0].organizationName)
        }
        fetchData();
        
    }, [props.user]);
    const organisationName = (orgName) =>{
        setOrgName(orgName)
    }

    return (
        <DetailsContainer>
            {!isEmpty(orgDetails)?<Header user={props.user} orgDetails={orgName}/>:<Header user={props.user} orgDetails={null}/>}
            <Tabs id='CompanyDetails'/>
            <Heading 
                headingId="OrganisationCalculator.DetailsPage.Heading" 
                subHeadingId="OrganisationCalculator.DetailsPage.SubHeading"
            />
            {!isEmpty(orgDetails)?<Form setOrgName={organisationName} orgDetails={orgDetails}/>:<Form  orgDetails={null}/>}
            <Footer/>
        </DetailsContainer>
      ) ;
    
    
}

const mapStateToProps = (storeState) => ({
    user: storeState.user.currentUser
    ? {
      initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
      name:
        storeState.user.currentUser.attributes.profile.firstName +
        ' ' +
        storeState.user.currentUser.attributes.profile.lastName,
        userType: storeState.user.currentUser.attributes.profile.userType,
    }
    : null,
    userDetails: storeState.user.currentUser
  })

  export default connect(mapStateToProps)(withRouter(OrganisationDetailsPage))

