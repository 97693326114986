import styled from 'styled-components';

export const ProfileImageContainer = styled.div`
    position:relative;
    width:82%;
    object-fit:contain;
`;

export const ProfileImageTitle = styled.div`
    position:absolute;
    top:30%;
    left:100px;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    line-height: 50px;
    color: #FFFFFF;

    @media screen and (max-width: 860px) {
        font-size: 28px;
        line-height: 32px;
    }
`;