import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticPage } from '../../../containers';
import Header from '../../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../../newPages/NewLandingPage/Sections/Footer/Footer';
import * as coreAPI from '../../../coreApi';
import { richText } from '../../../util/richText';
import { NamedLink } from '../../../components';
import { getLatestBlogsByTag } from '../../../newContainers/Blogs/utils';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BecomeMember from '../../../newComponents/BecomePartner';
import { Link } from 'react-router-dom';
import { LightGreenBtn, OrangeBtn, TrimmedBtn } from '../../../newComponents/Button/ButtonSkin';
import {
    LandscapeDetailSection,
    PositiveImpact,
    ThreeColLandscape,
    ThanksOrganisation,
    LandscapeNav,
    SupportLandscape,
} from './Styles';

import { MdEmail } from 'react-icons/md';
import partnerTag from '../../../assets/newAssets/partner-tag.svg';
import thumbPrintGreen from '../../../assets/newAssets/thumbprintGreen.svg';

import acuicultura from '../../../assets/actividades/acuicultura.jpg';
import agriculturaManu from '../../../assets/actividades/agriculturaManu.jpg';
import agriculturaOrganica from '../../../assets/actividades/agriculturaOrganica.jpg';
import artesaniaManu from '../../../assets/actividades/artesaniaManu.jpg';
import artesanias from '../../../assets/actividades/artesanias.jpg';
import ganaderiaAlpaquera from '../../../assets/actividades/ganaderiaAlpaquera.jpg';
import manejoForestal from '../../../assets/actividades/manejoForestal.jpg';
import manejoPesquero from '../../../assets/actividades/manejoPesquero.jpg';
import turismo from '../../../assets/actividades/turismo.jpg';
import turismoComunitario from '../../../assets/actividades/turismoComunitario.jpg';
import turismoManu from '../../../assets/actividades/turismoManu.jpg';

import gestionDelAgua from '../../../assets/actividades/manejoForestal.jpg';
import conservacionDelBosque from '../../../assets/actividades/manejoForestal.jpg';
import restauracionDelBosque from '../../../assets/actividades/manejoForestal.jpg';

import reforestacion from '../../../assets/actividades/reforestacion.png'; // Reforestation
import ecoturismo from '../../../assets/actividades/ecoturismo.png'; // Ecotourism
import cocaLegal from '../../../assets/actividades/cocaLegal.png'; // Legal coca leaf

import aguilaArpia from '../../../assets/especies/aguilaArpia.jpg';
import arahuana from '../../../assets/especies/arahuana.jpg';
import camaronDeRio from '../../../assets/especies/camaronDeRio.jpg';
import colibri from '../../../assets/especies/colibri.jpg';
import colibriDeRaquetas from '../../../assets/especies/colibriDeRaquetas.jpg';
import colibriDeVientreBlanco from '../../../assets/especies/colibriDeVientreBlanco.jpg';
import condorAndino from '../../../assets/especies/condorAndino.jpg';
import guacamayoEscarlata from '../../../assets/especies/guacamayoEscarlata.jpg';
import jaguar from '../../../assets/especies/jaguar.jpg';
import loboDeRio from '../../../assets/especies/loboDeRio.jpg';
import mirloAcuático from '../../../assets/especies/mirloAcuático.jpg';
import nutriaDeRio from '../../../assets/especies/nutriaDeRio.jpg';
import osoDeAnteojos from '../../../assets/especies/osoDeAnteojos.jpg';
import primate from '../../../assets/especies/primate.jpg';
import tapir from '../../../assets/especies/tapir.jpg';
import venadoDeColaBlanca from '../../../assets/especies/venadoDeColaBlanca.jpg';
import vicunas from '../../../assets/especies/vicunas.jpg';

import osoAndino from '../../../assets/especies/osoAndino.png'; // Andean Bear
import gallitoDeLasRocas from '../../../assets/especies/gallitoDeLasRocas.png'; // Andean Cock-of-the-Rock
import colibriPicoDeHoz from '../../../assets/especies/colibriPicoDeHoz.png'; // Buff-tailed Sicklebill

import development from '../../../assets/icons/development.svg';
import enterprise from '../../../assets/icons/enterprise.svg';
import mountainsfull from '../../../assets/icons/mountainsfull.svg';
import afpPrima from '../../../assets/logo-aliados/afpPrima.jpg';
import peruGBC from '../../../assets/logo-aliados/PeruGreenBuildingCouncil.png';
import amazonasExplorer from '../../../assets/logo-aliados/AmazonasExplorer.png';
import andeanLodges from '../../../assets/logo-aliados/AndeanLodges.png';
import auqui from '../../../assets/logo-aliados/auqui.jpg';
import cedes from '../../../assets/logo-aliados/cedes.jpg';
import cedia from '../../../assets/logo-aliados/cedia.jpg';
import cientifica from '../../../assets/logo-aliados/cientifica.png';
import explorandes from '../../../assets/logo-aliados/explorandes.png';
import floraYFauna from '../../../assets/logo-aliados/FloraYFauna.jpg';
import gerpal from '../../../assets/logo-aliados/gerpal.png';
import helvetasPeru from '../../../assets/logo-aliados/helvetasPeru.png';
import hoseg from '../../../assets/logo-aliados/hoseg.png';
import CaritasAbancay from '../../../assets/logo-aliados/CaritasAbancay.png';
import CienPies from '../../../assets/logo-aliados/CienPies.png';
import TextilElAmazonas from '../../../assets/logo-aliados/TextilElAmazonas.jpg';
import CandorJoyeria from '../../../assets/logo-aliados/CandorJoyeria.jpeg';

import patronatoNYC from '../../../assets/logo-aliados/patronatoNYC.jpg';
import peru2021 from '../../../assets/logo-aliados/peru2021.jpg';
import pukaNina from '../../../assets/logo-aliados/pukanina.jpg';

import sanDiegoZoo from '../../../assets/logo-aliados/SanDiegoZoo.jpg';
import sePeru from '../../../assets/logo-aliados/SePeru.jpg';
import strategik from '../../../assets/logo-aliados/strategik.png';

import pristinet from '../../../assets/logo-aliados/pristinet.jpeg';
import markham from '../../../assets/logo-aliados/markhamCollege.png';
import giz from '../../../assets/logo-aliados/LogoGiz.jpg';
import rainforest from '../../../assets/logo-aliados/rainforest.jpeg';

import elPolen from '../../../assets/logo-aliados/ElPolen.jpg';
import tedXTukuy from '../../../assets/logo-aliados/TEDxTukuy.png';
import delosi from '../../../assets/logo-aliados/Delosi.png';
import laBodega138 from '../../../assets/logo-aliados/LaBodega138.png';

import wendyWeeks from '../../../assets/logo-aliados/WendyWeeks.png';
import saExpeditions from '../../../assets/logo-aliados/SAExpeditions.png';
import toyota from '../../../assets/logo-aliados/ToyotaComp.jpg';

import ayni from '../../../assets/logo-aliados/Ayni.png';
import responsibleTravel from '../../../assets/logo-aliados/ResponsibleTravelBlack.png';
import peruForLess from '../../../assets/logo-aliados/PeruforLess.jpg';
import terraExplorer from '../../../assets/logo-aliados/TerraExplorerPeru.jpg';
import antiguaCasona from '../../../assets/logo-aliados/AntiguaCasonaSanBlas.png';
import actionPeruTreks from '../../../assets/logo-aliados/ActionPeruTreks.png';
import exothermic from '../../../assets/logo-aliados/exothermic.webp';

import ccArin from '../../../assets/logos-guardianes/ccArin.webp';
import ccKiunalla from '../../../assets/logos-guardianes/ccKiunalla.svg';
import ccLaraos from '../../../assets/logos-guardianes/ccLaraos.svg';
import ccOllantaytambo from '../../../assets/logos-guardianes/ccOllantaytambo.jpg';
import ccPiscacucho from '../../../assets/logos-guardianes/ccPiscacucho.svg';
import ccMisquiyaco from '../../../assets/logos-guardianes/ccMisquiyaco.svg';

import ccTanta from '../../../assets/logos-guardianes/ccTanta.svg';
import ccTomas from '../../../assets/logos-guardianes/ccTomas.svg';

import ccKarqueque from '../../../assets/logos-guardianes/ccKarqueque.svg';
import ccCcoya from '../../../assets/logos-guardianes/ccCcoya.svg';
import ccCcollpa from '../../../assets/logos-guardianes/ccCcollpa.svg';
import ccMicaelaBastidas from '../../../assets/logos-guardianes/ccMicaelaBastidas.svg';
import ccAtumpata from '../../../assets/logos-guardianes/ccAtumpata.svg';
import ccLlanucancha from '../../../assets/logos-guardianes/ccLlanucancha.svg';

import cnMatoriato from '../../../assets/logos-guardianes/cnMatoriato.webp';
import cnNuevaEsperanza from '../../../assets/logos-guardianes/cnNuevaEsperanza.svg';
import cnWicungo from '../../../assets/logos-guardianes/cnWicungo.svg';
import cnCuringa from '../../../assets/logos-guardianes/cnCuringa.svg';
import cnFrontera from '../../../assets/logos-guardianes/cnFrontera.svg';
import cnEspana from '../../../assets/logos-guardianes/cnEspana.svg';

import cnShipetari from '../../../assets/logos-guardianes/comunidadNativaShipetari.jpg';
import CREES from '../../../assets/logos-guardianes/CREES.jpg';
import familiaChallco from '../../../assets/logos-guardianes/familiaChallco.svg';
import FamiliaIsidro from '../../../assets/logos-guardianes/FamiliaIsidro.jpg';

import valleSagradoVerde from '../../../assets/logos-guardianes/ValleSagradoVerde.png';

import fecoritayb from '../../../assets/logos-guardianes/FECORITAYB.png';
import IslaDeLosValles from '../../../assets/logos-guardianes/IslaDeLosValles.jpg';
import cnPalotoaTeparo from '../../../assets/logos-guardianes/PalotoaTeparo.jpg';

import predioAquize from '../../../assets/logos-guardianes/predioAquize.svg';
import predioHuaman from '../../../assets/logos-guardianes/predioHuaman.svg';

import predioCoila from '../../../assets/logos-guardianes/predioCoila.svg';
import predioQuispe from '../../../assets/logos-guardianes/predioQuispe.svg';
import predioVargas from '../../../assets/logos-guardianes/predioVargas.svg';
import predioAlegre from '../../../assets/logos-guardianes/predioAlegre.svg';

import abancay from '../../../assets/maps/abancay.webp';
import canete from '../../../assets/maps/canete.webp';
import manu from '../../../assets/maps/manu.webp';
import requena from '../../../assets/maps/requena.webp';
import machupicchu from '../../../assets/maps/machupicchu.webp';
import paucartambo from '../../../assets/maps/paucartambo.webp';
import bgGreenTexture from '../../../assets/newAssets/bg-texture-green.jpg';

const logoAliados = {
    afpPrima: { image: afpPrima, id: '5da7b5cc-d0b6-4113-9eac-a1cb9e3ead38' },
    peruGBC: { image: peruGBC },
    amazonasExplorer: { image: amazonasExplorer, id: '5da7b6cf-b5bb-4d42-b1ab-736c3f74e287' },
    andeanLodges: { image: andeanLodges, id: '5da7b454-5edc-4c2d-9922-a8455051a93f' },
    auqui: { image: auqui, id: '5da7b485-ba34-455d-b58a-33c46e616f64' },
    cientifica: { image: cientifica, id: '5da7b603-8664-4164-baf0-b2693347b655' },
    explorandes: { image: explorandes, id: '5da7b296-901f-4c85-ae01-834edb9477a2' },
    gerpal: { image: gerpal, id: '5da7b6f6-1d41-4f30-97dd-74ad33066d55' },
    hoseg: { image: hoseg, id: '5da7b6a8-3ae4-4ef5-9313-5d6ec787414c' },
    pukaNina: { image: pukaNina, id: '5da7b325-8a45-4867-83d4-33ca35e76a18' },
    strategik: { image: strategik, id: '5da7b56a-ccd5-433f-b5f6-bcfdd0c08214' },
    floraYFauna: { image: floraYFauna, id: '5da7b4a7-832f-4c37-b576-ef3f55edbc87' },
    peru2021: { image: peru2021, id: '5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9' },
    rainforest: { image: rainforest, id: '5da7b59d-94d0-4adb-9ebe-8221c2343adf' },
    CienPies: { image: CienPies },
    TextilElAmazonas: { image: TextilElAmazonas },
    CandorJoyeria: { image: CandorJoyeria },
    pristinet: { image: pristinet },
    markham: { image: markham },
    giz: { image: giz },
    elPolen: { image: elPolen },
    tedXTukuy: { image: tedXTukuy },
    delosi: { image: delosi },
    laBodega138: { image: laBodega138 },
    sanDiegoZoo: { image: sanDiegoZoo },
    sePeru: { image: sePeru },
    wendyWeeks: { image: wendyWeeks },

    saExpeditions: { image: saExpeditions },
    toyota: { image: toyota },
    ayni: { image: ayni },
    responsibleTravel: { image: responsibleTravel },
    peruForLess: { image: peruForLess },
    terraExplorer: { image: terraExplorer },
    antiguaCasona: { image: antiguaCasona },
    actionPeruTreks: { image: actionPeruTreks },
    exothermic: { image: exothermic },
};

const species = {
    colibri: colibri,
    primate: primate,
    aguilaArpia: aguilaArpia,
    arahuana: arahuana,
    camaronDeRio: camaronDeRio,
    colibriDeRaquetas: colibriDeRaquetas,
    colibriDeVientreBlanco: colibriDeVientreBlanco,
    condorAndino: condorAndino,
    mirloAcuático: mirloAcuático,
    nutriaDeRio: nutriaDeRio,
    osoDeAnteojos: osoDeAnteojos,
    tapir: tapir,
    venadoDeColaBlanca: venadoDeColaBlanca,
    vicunas: vicunas,
    guacamayoEscarlata: guacamayoEscarlata,
    jaguar: jaguar,
    loboDeRio: loboDeRio,
    osoAndino: osoAndino,
    gallitoDeLasRocas: gallitoDeLasRocas,
    colibriPicoDeHoz: colibriPicoDeHoz,
};

const activities = {
    acuicultura: acuicultura,
    agriculturaOrganica: agriculturaOrganica,
    artesanias: artesanias,
    ganaderiaAlpaquera: ganaderiaAlpaquera,
    manejoForestal: manejoForestal,
    manejoPesquero: manejoPesquero,
    turismo: turismo,
    turismoComunitario: turismoComunitario,
    artesaniaManu: artesaniaManu,
    turismoManu: turismoManu,
    agriculturaManu: agriculturaManu,
    reforestacion: reforestacion,
    ecoturismo: ecoturismo,
    cocaLegal: cocaLegal,
    gestionDelAgua: gestionDelAgua,
    conservacionDelBosque: conservacionDelBosque,
    restauracionDelBosque: restauracionDelBosque,
};

const logoGuardians = {
    ccArin: ccArin,
    cedes: cedes,
    helvetasPeru: helvetasPeru,
    ccKiunalla: ccKiunalla,
    ccLaraos: ccLaraos,
    ccOllantaytambo: ccOllantaytambo,
    ccPiscacucho: ccPiscacucho,
    ccMisquiyaco: ccMisquiyaco,
    ccTanta: ccTanta,
    ccTomas: ccTomas,
    ccKarqueque: ccKarqueque,
    ccCcoya: ccCcoya,
    ccCcollpa: ccCcollpa,
    ccMicaelaBastidas: ccMicaelaBastidas,
    ccAtumpata: ccAtumpata,
    ccLlanucancha: ccLlanucancha,
    CaritasAbancay: CaritasAbancay,
    cnNuevaEsperanza: cnNuevaEsperanza,
    cnWicungo: cnWicungo,
    cnShipetari: cnShipetari,
    cnFrontera: cnFrontera,
    cnCuringa: cnCuringa,
    cnEspana: cnEspana,
    cnPalotoaTeparo: cnPalotoaTeparo,
    familiaChallco: familiaChallco,
    FamiliaIsidro: FamiliaIsidro,
    IslaDeLosValles: IslaDeLosValles,
    CREES: CREES,
    cedia: cedia,
    fecoritayb: fecoritayb,
    cnMatoriato: cnMatoriato,
    sanDiegoZoo: sanDiegoZoo,
    predioCoila: predioCoila,
    predioQuispe: predioQuispe,
    predioVargas: predioVargas,
    predioAlegre: predioAlegre,
    predioAquize: predioAquize,
    predioHuaman: predioHuaman,
    sePeru: sePeru,
    valleSagradoVerde: valleSagradoVerde,
    patronatoNYC: patronatoNYC,
};
const maps = {
    abancay: abancay,
    canete: canete,
    manu: manu,
    requena: requena,
    'machu-picchu': machupicchu,
    paucartambo: paucartambo,
};

const LandscapeDetail = ({ location, history, user, params, language }) => {
    console.log('params', params);
    const landscapeSlug = params.slug;
    const [loadProgress, setLoadProgress] = useState(true);
    const [actionData, setActionData] = useState([]);
    const [landscapeData, setLandScapeData] = useState(null);
    const [showBanner, setShowBanner] = useState(false);
    useEffect(() => {
        coreAPI
            .getLandScapeDetailsBySlug(landscapeSlug)
            .then(response => {
                console.log(response);
                setLandScapeData(response.data);
                setLoadProgress(false);
            })
            .catch(error => {
                setLoadProgress(false);
            });

        (async () => {
            const response = await getLatestBlogsByTag(language.toLowerCase(), landscapeSlug);
            if (response.status === 'success') {
                console.log('response.data', response.data);
                setActionData(response.data);
            }
        })();
    }, [landscapeSlug]);

    const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;
    const getHighlightedText = data => {
        let temp = data.slice(4);
        const index = temp.indexOf('**');
        const highlighted = temp.slice(0, index);
        const normal = temp.slice(index + 2);
        return { text: normal, highlightedPart: highlighted };
    };
    const userType = user && user.userType;
    const profile = userType === 'personas' ? 'Personas' : 'BusinessPage';
    const joinButton = (
        <TrimmedBtn>
            <NamedLink name={`${profile}`}>
                <FormattedMessage id="LandscapeListing.supportLandscapePageLink" />
            </NamedLink>
        </TrimmedBtn>
    );

    const getNextLandscape = currentLandscape => {
        console.log('currentLandscape', currentLandscape);
        const landscapes = Object.keys(maps); // Get all the landscape names as an array
        const currentIndex = landscapes.indexOf(currentLandscape); // Find the index of the current landscape

        // Calculate the next index (looping back to 0 if necessary)
        const nextIndex = (currentIndex + 1) % landscapes.length;

        return landscapes[nextIndex]; // Return the next city
    };

    const preText = ['Landscape extent:', 'Altitude range:', 'Population:'];
    return (
        <StaticPage
            title="Make Nature your business - Regenera | Carbon Offsetting Company Peru | Reduce Ecological & Carbon Footprints in Peru"
            description="Regenera: Best environmental consulting company in Peru, our team helps your organization make a positive impact on nature, climate, and communities. Beyond carbon offsets and tree planting, Regenera supports the management of living landscapes, led by those who know their lands best."
            schema={{
                '@context': 'http://schema.org',
                '@type': 'HomePage',
                description:
                    'Empresas e instituciones de todo tamaño que velan por la naturaleza transformando sus impactos ambientales en acciones positivas.',
                name: 'Home',
            }}
        >
            <Header currentPath={location.pathname} user={user} history={history} />
            {landscapeData && (
                <LandscapeDetailSection>
                    <div className="hero-landscape">
                        <img
                            src={landscapeData.image}
                            className="w-full landscape-image"
                            alt={landscapeData.name}
                        />
                        <div className="hero-content-box mx-auto">
                            <div className="title-box relative">
                                <div>
                                    <div className="small-title">
                                        {landscapeData.metadata.slogan}
                                    </div>
                                    <h1>{landscapeData.name}</h1>
                                </div>
                                <div className="hide-mobile">{joinButton}</div>
                            </div>
                            <div className="two-col-content flex">
                                <div className="text">
                                    <p>
                                        {richText(landscapeData.metadata.description, {
                                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                                            longWordClass: 'longWord',
                                        })}
                                    </p>
                                </div>

                                <div className="list-icons">
                                    <div className="hide-desk show-mobile support-container">
                                        {joinButton}
                                    </div>
                                    <ul>
                                        {landscapeData.metadata.paisaje
                                            .split('\n')
                                            .map(itemSet => getHighlightedText(itemSet))
                                            .map((item, index) => (
                                                <li className="flex items-center" key={item.id}>
                                                    <div className="number-info">
                                                        {preText[index]}{' '}
                                                        <strong> {item.highlightedPart}</strong>{' '}
                                                        {item.text}
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="landscape-partners">
                                <p className="small title">
                                    <FormattedMessage id="LandscapeDetail.PartnerThanks" />
                                </p>
                                <div className="partners-images flex flex-wrap items-center">
                                    {landscapeData.metadata.guardians.map((item, index) => (
                                        <div key={index} className="partner-img-box">
                                            <img
                                                className="w-full"
                                                src={logoGuardians[item]}
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <PositiveImpact>
                        <div className="impact-info mx-auto flex flex-col">
                            <h3>
                                <FormattedMessage id="LandscapeDetail.PositiveImpact" />
                            </h3>
                            <div className="impact-group">
                                <div className="grid grid-three-column">
                                    {landscapeData.metadata.impact
                                        .split('\n')
                                        .map(itemSet => getHighlightedText(itemSet))
                                        .map((item, index) => (
                                            <div
                                                key={index}
                                                className="impact-info-numbers flex flex-col"
                                            >
                                                <div className="total-numbers-info">
                                                    {item.highlightedPart}
                                                    <span> {item.text}</span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </PositiveImpact>

                    <ThreeColLandscape bgGreenTexture={bgGreenTexture}>
                        <div className="three-cols-box flex">
                            <div className="map-box">
                                <div className="img-box">
                                    <img
                                        className="landscape-map"
                                        src={maps[landscapeData.metadata.staticmaps]}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div className="species-activities flex flex-col">
                                <div className="three-col-box">
                                    <div className="title-box gray-color">
                                        <div className="small-title">
                                            <FormattedMessage id="LandscapeDetail.Species.Title" />
                                        </div>
                                        <small>
                                            <FormattedMessage id="LandscapeDetail.Species.Subtitle" />
                                        </small>
                                    </div>
                                    <div className="three-col-imgs flex">
                                        {landscapeData.metadata.species.map((item, index) => (
                                            <div key={index} className="img-text-box text-center">
                                                <img src={species[item]} className="w-full" />

                                                <div className="img-text small grey-color">
                                                    <FormattedMessage
                                                        id={item}
                                                        defaultMessage={item.replace(
                                                            /([A-Z])/g,
                                                            ' $1'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="three-col-box bg-lightgreen">
                                    <div className="title-box">
                                        <div className="small-title">
                                            <FormattedMessage id="LandscapeDetail.Activities.Title" />
                                        </div>
                                        <small>
                                            <FormattedMessage id="LandscapeDetail.Activities.Subtitle" />
                                        </small>
                                    </div>
                                    <div className="three-col-imgs flex">
                                        {landscapeData.metadata.activities.map((item, index) => (
                                            <div key={index} className="img-text-box text-center">
                                                <img
                                                    src={activities[item]}
                                                    className="w-full"
                                                    alt=""
                                                />

                                                <div className="img-text small">
                                                    <FormattedMessage
                                                        id={item}
                                                        defaultMessage={item.replace(
                                                            /([A-Z])/g,
                                                            ' $1'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="network-lists flex flex-col">
                                <div className="landscape-network">
                                    <div className="small-title text-white">
                                        <FormattedMessage id="LandscapeDetail.Landscape.Network" />
                                    </div>
                                    <ul>
                                        {landscapeData.metadata.red
                                            .split('\n')
                                            .map(itemSet => getHighlightedText(itemSet))
                                            .map((item, index) => (
                                                <li className="small flex justify-space-between items-center">
                                                    {item.text}

                                                    <span className="count text-white small-title">
                                                        {item.highlightedPart}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                <div className="list-boxs">
                                    <div className="recent-actions">
                                        <div className="list-recent-action flex flex-col">
                                            {actionData && actionData.length > 0 ? (
                                                actionData &&
                                                actionData.map(item => (
                                                    <Link
                                                        to={`/blog/${item.slug}`}
                                                        className="article-thumb-section flex items-center"
                                                    >
                                                        <img
                                                            src={item.blog_details.thumbnail}
                                                            alt=""
                                                        />
                                                        <div className="action-content">
                                                            <h6 className="article-title">
                                                                {item.title}
                                                            </h6>
                                                            <div className="link-text">
                                                                <FormattedMessage id="NewLandingPage.partnersImpact.articleLinkText" />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                            ) : (
                                                <Link
                                                    to={`news`}
                                                    className="article-thumb-section flex items-center"
                                                >
                                                    <img
                                                        src={thumbPrintGreen}
                                                        className="placeholderImg"
                                                    />
                                                    <div className="action-content">
                                                        <h6 className="article-title">
                                                            <FormattedMessage id="NewLandingPage.partnersImpact.recentActionsComingText" />
                                                        </h6>
                                                        <div className="link-text">
                                                            <FormattedMessage id="NewLandingPage.partnersImpact.articleLinkText" />
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="show-mobile">
                                <div className="propose-box">
                                    <h4 className="small">
                                        <FormattedMessage id="LandscapeDetail.Landscape.Question" />
                                    </h4>
                                    <Link to="#" className="green-link flex items-center">
                                        <MdEmail className="link-icon" />
                                        <span>
                                            <FormattedMessage id="LandscapeDetail.Landscape.SendMessage" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </ThreeColLandscape>

                    <ThanksOrganisation>
                        <div className="box-partners flex justify-space-between">
                            <div className="content-box-partner">
                                <h3>
                                    <FormattedMessage id="LandscapeDetail.Landscape.PartnerOrganizations" />
                                </h3>
                                <p>
                                    <FormattedMessage id="LandscapeDetail.Landscape.PlanCopy" />
                                </p>
                                <OrangeBtn className="action-btn">
                                    <Link to="/organizations">
                                        <FormattedMessage id="LandscapeDetail.BecomePartner" />
                                    </Link>
                                </OrangeBtn>
                            </div>
                            <div className="partner-logos-box relative">
                                <div className="green-text">
                                    <FormattedMessage id="LandscapeDetail.PartnerOrganizations.Title" />
                                </div>
                                <div className="group-logos flex flex-wrap items-center justify-center">
                                    {landscapeData.metadata.allies.length > 0 ? (
                                        landscapeData.metadata.allies.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {logoAliados[item].id ? (
                                                    <div className="img-box">
                                                        <NamedLink
                                                            name="ProfilePage"
                                                            params={{ slug: logoAliados[item].id }}
                                                        >
                                                            <img
                                                                src={logoAliados[item].image}
                                                                className="w-full"
                                                                alt="partner logo"
                                                            />
                                                        </NamedLink>
                                                    </div>
                                                ) : (
                                                    <div className="img-box">
                                                        <img
                                                            src={logoAliados[item].image}
                                                            className="w-full"
                                                            alt="partner logo"
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <h4>
                                            <br />
                                            <FormattedMessage id="LandscapeListing.allied.notFound" />
                                        </h4>
                                    )}
                                </div>
                                <img
                                    src={partnerTag}
                                    className="absolute partner-tag"
                                    alt="regenera partner"
                                />
                            </div>
                        </div>
                    </ThanksOrganisation>
                    <div className="hide-mobile">
                        <BecomeMember
                            title={<FormattedMessage id="LandscapeDetail.Landscape.Question" />}
                            linkto="#"
                            btnText={<FormattedMessage id="LandscapeDetail.Landscape.SendEmail" />}
                        />
                    </div>

                    <LandscapeNav>
                        <div className="group-next-prev flex justify-space-between">
                            <LightGreenBtn className="back-next-btn">
                                <Link to="/landscapes">
                                    <span className="hide-mobile">
                                        <FormattedMessage id="LandscapeDetail.Landscape.Backto" />
                                    </span>{' '}
                                    <span className="show-mobile">
                                        <FormattedMessage id="LandscapeDetail.Landscape.All" />
                                    </span>{' '}
                                    <FormattedMessage id="LandscapeDetail.Landscapes.Label" />
                                </Link>
                            </LightGreenBtn>
                            <LightGreenBtn className="back-next-btn">
                                <Link
                                    to={`/landscapes/${getNextLandscape(landscapeData.titleSlug)}`}
                                >
                                    <span className="hide-mobile">
                                        <FormattedMessage id="LandscapeDetail.Landscape.View" />{' '}
                                    </span>
                                    <FormattedMessage id="LandscapeDetail.Landscape.Next" />{' '}
                                    <span className="hide-mobile">
                                        <FormattedMessage id="LandscapeDetail.Landscape.Label" />
                                    </span>
                                </Link>
                            </LightGreenBtn>
                        </div>
                    </LandscapeNav>
                    {/* <SupportLandscape>
                        <div className="stickyCall">
                            <div className="stickyContainer">
                                <p className="stickyTitle"> {landscapeData.name} </p>
                                {joinButton}
                            </div>
                        </div>
                    </SupportLandscape> */}
                </LandscapeDetailSection>
            )}
            <Footer history={history} />
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(LandscapeDetail));
