import React, { useState, useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import ReCaptcha from 'react-google-recaptcha';
import { compose } from 'redux';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter, Redirect } from 'react-router-dom';
import { login, authenticationInProgress, signInWithGoogle } from '../../ducks/Auth.duck';
import { connect } from 'react-redux';
import { Page, NamedRedirect } from '../../components';
import TextField from '../../newComponents/Form/TextField';
import { LoginValScheme } from './LoginValScheme';
import { NamedLink } from '../../components';

import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import CheckBoxInfo from '../../newComponents/checkbox';
import leftLeafSignup from '../../assets/newAssets/left-leaf-signup.webp';
import rightLeafSignup from '../../assets/newAssets/right-leaf-signup.webp';
import titleBorderLine from '../../assets/newAssets/title-border-line.svg';
import bkgImage from '../../assets/mainhero.jpg';
import { GoogleLogin } from 'react-google-login';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const LoginSection = styled.section`
    .left-section {
        width: 100%;
        max-width: 36%;
        padding: 60px 30px 70px 80px;
        background-color: ${({ theme }) => theme.colors.title_green};
        background-image: url(${({ leftLeafSignup }) => leftLeafSignup});
        background-repeat: no-repeat;
        background-position: left bottom;

        background-image: url(${({ leftLeafSignup }) => leftLeafSignup}),
            url(${({ bkgImage }) => bkgImage});
        background-repeat: no-repeat, no-repeat;
        background-position: left bottom, -950px 0;

        h1 {
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
        }
        .border {
            position: relative;
            img {
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
            }
        }
        .circle-box {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            border: 3px solid white;
            overflow: hidden;
            bottom: 10px;
            left: 70px;
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
    .googleSignUp {
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    .googleSignUp h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    .googleSignUp > button {
        width: 100%;
        border-radius: 15px;
    }
    .form-box {
        flex: 1 1 auto;
        padding: 80px 5% 70px;
        box-sizing: content-box;
        position: relative;
        max-width: 420px;
        .form-title {
            font-size: 19px;
            line-height: 27px;
            font-weight: 600;
            margin-bottom: 27px;
        }
        .form-section {
            gap: 16px;
        }
        .cta {
            padding: 0 14px;
            max-width: 305px;
            margin-top: 20px;
            margin-bottom: 7px;
            text-align: center;
            button {
                width: 100%;
            }
            .login-cta {
                line-height: 150%;
                margin-top: 27px;
                a {
                    font-weight: 600;
                    color: ${({ theme }) => theme.colors.title_green};
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .left-section {
            max-width: 100%;
            background-image: url(${({ rightLeafSignup }) => rightLeafSignup});
            background-position: right -40px;
            padding: 28px 10px;
            margin-top: 65px;
            h1 {
                font-size: 42px;
                line-height: 53px;
                text-align: center;
                br {
                    display: none;
                }
            }
        }
        .form-box {
            padding-top: 15px;
            .form-title {
                font-size: 16px;
                line-height: 180%;
                margin-bottom: 10px;
            }
            .cta {
                .login-cta {
                    margin-top: 10px;
                }
            }
        }
        .form-section {
            gap: 24px;
        }
    }
`;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;
const Login = ({
    location,
    history,
    user,
    isAuthenticated,
    loginError,
    submitLoginWithGoogle,
    submitLogin,
    intl,
}) => {
    const from = location.state && location.state.from ? location.state.from : null;
    console.log('loginerror', loginError);
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const [googleLoginFailed, setGoogleLoginFailed] = useState(false);

    const togglepassword = () => {
        setShowPassword(!showPassword);
    };

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
        return <Redirect to={from} />;
    } else if (isAuthenticated && user) {
        return <NamedRedirect name="LandingPage" />;
    }

    const loginErrorMessage = (
        <div className="error-text small">
            <FormattedMessage id="AuthenticationPage.loginFailed" />
        </div>
    );

    const loginErrorDisplay = loginError || googleLoginFailed ? loginErrorMessage : null;

    const reCaptchaRefLogin = React.createRef();

    const checkCaptcha = captchaToken => {
        // console.log('form details', this.state.signUpDetails)
        if (!!captchaToken && !!this.state.signUpDetails) {
            try {
                reCaptchaRefLogin.current.execute();
                //submitSignup({ ...this.state.signUpDetails, captchaToken });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleSubmitLogin = async values => {
        const { ...restValues } = values;

        try {
            setShowError(false);

            if (!!restValues) {
                await submitLogin({ ...restValues });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setShowError(true);
        }
    };

    const googleResponse = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRefLogin.current) {
                    captchaToken = await reCaptchaRefLogin.current.executeAsync();
                    reCaptchaRefLogin.current.reset();
                }

                await submitLoginWithGoogle(response.tokenId);

                setShowError(true);
            } catch (error) {
                setGoogleLoginFailed(true);
                setShowError(true);
                console.log({ 'Google Sign In Error': error });
            }
        }
    };

    //form fields translation

    const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
    });

    const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
    });
    const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
    });

    const signUpLinkText = (
        <NamedLink name="SignupPage">
            <FormattedMessage id="LoginForm.signup.linkText" />
        </NamedLink>
    );
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LoginSection
                className="flex flex-wrap"
                leftLeafSignup={leftLeafSignup}
                rightLeafSignup={rightLeafSignup}
                bkgImage={bkgImage}
            >
                <div className="left-section relative">
                    <h1 className="text-white">
                        <FormattedHTMLMessage
                            id="AuthenticationPage.hero.title"
                            values={{
                                img: `${titleBorderLine}`,
                            }}
                        />
                    </h1>
                </div>

                <div className="form-box mx-auto flex flex-col">
                    {showError && loginErrorDisplay}
                    <div className="form-title text-center">
                        <FormattedMessage id="AuthenticationPage.loginLinkText" />
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginValScheme}
                        onSubmit={handleSubmitLogin}
                        validateOnMount={true}
                    >
                        {formik => (
                            <Form
                                onChange={() => {
                                    setShowError(false);
                                }}
                            >
                                <ReCaptcha
                                    ref={reCaptchaRefLogin}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    size="invisible"
                                />
                                <div className="form-section flex flex-col">
                                    <div className="one-col">
                                        <div className="input-box-section">
                                            <TextField
                                                type="email"
                                                label={emailLabel}
                                                name="email"
                                                placeholder={emailPlaceholder}
                                                aria-describedby="emailhelp"
                                                className={`form-control  ${formik.touched.email &&
                                                    formik.errors.email &&
                                                    'input-error'}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="one-col">
                                        <div className="input-box-section">
                                            <div className="input-group relative">
                                                <TextField
                                                    type={showPassword ? 'text' : 'password'}
                                                    label={passwordLabel}
                                                    name="password"
                                                    placeholder={passwordPlaceholder}
                                                    aria-describedby="passwordhelp"
                                                    className={`form-control  ${formik.touched
                                                        .password &&
                                                        formik.errors.password &&
                                                        'input-error'}`}
                                                />
                                                <span
                                                    onClick={togglepassword}
                                                    className="input-group-prepend absolute"
                                                >
                                                    {showPassword ? (
                                                        <FaRegEye />
                                                    ) : (
                                                        <FaRegEyeSlash />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cta mx-auto">
                                    <OrangeBtn
                                        type="submit"
                                        disabled={!formik.isValid}
                                        className={!formik.isValid ? 'disabled' : null}
                                    >
                                        <FormattedMessage id="LoginForm.logIn" />
                                    </OrangeBtn>
                                    <div className="login-cta small">
                                        <FormattedMessage id="LoginForm.forgotPasswordInfo" />{' '}
                                        <Link to="/recover-password">
                                            <FormattedMessage id="LoginForm.forgotPassword" />
                                        </Link>
                                    </div>
                                    <div className="googleSignUp">
                                        <h5>or</h5>
                                        {googleClientId && (
                                            <GoogleLogin
                                                className="login-w-google flex items-center justify-center"
                                                clientId={googleClientId}
                                                disabled={isAuthenticated}
                                                buttonText={
                                                    <FormattedMessage id="NewPartnersPage.Setup.Account.GoogleBtn" />
                                                }
                                                onSuccess={googleResponse}
                                                onFailure={googleResponse}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        )}
                                    </div>

                                    <div className="login-cta small">
                                        <FormattedMessage id="LoginForm.signup.text" />{' '}
                                        <Link to="/signup">
                                            {' '}
                                            <FormattedMessage id="LoginForm.signup.linkText" />
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </LoginSection>
            <Footer history={history} />
        </Page>
    );
};

const mapDispatchToProps = dispatch => ({
    submitLoginWithGoogle: params => dispatch(signInWithGoogle(params)),
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
});

const mapStateToProps = storeState => ({
    isAuthenticated: storeState.Auth.isAuthenticated,
    loginError: storeState.Auth.loginError,
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

const initialValues = {
    email: '',
    password: '',
};
export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(Login));
