import styled from 'styled-components';
import { space, layout, grid, flexbox } from 'styled-system';
import { colors } from '../../../../theme';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../../../assets/newAssets/checkbox.svg';

export const Triangle = styled.div`
    visibility: hidden;
    position: absolute;
    position: absolute;
    left: 20px;
    bottom: 30px;
    background-color: #ebebeb;
    -webkit-clip-path: polygon(0 0, 20% 100%, 100% 0);
    clip-path: polygon(0 0, 20% 100%, 100% 0);
    width: 50px;
    height: 15px;
`;

export const StyledLocationPopper = styled.div`
    visibility: hidden;
    padding: 20px;
    width: 230px;
    bottom: 45px;
    left: 20px;
    & > p {
        margin: 0px;
        font-size: 13px;
    }
    background: white;
    position: absolute;
`;
export const GridItem = styled.div`
    width: 70%;
    height: auto;
    ${({ makeSmall }) =>
        makeSmall &&
        `
    width:50%;
    height:auto;
    `}
`;
export const StyledLink = styled(NavLink)`
  ${space}
  ${layout}
  ${({ lastImage }) =>
      lastImage &&
      `
      @media screen and (max-width: 425px) {
        grid-column: 1/span 2;
        height: 100px;
        margin-top: 30px;
      }
      @media screen and (max-width: 375px) {
        height: 80px;
      }
      @media screen and (max-width: 320px) {
        height: 60px;
      }
`}
`;

export const IconContainer = styled.div`
    position: relative;
    &:hover > div {
        visibility: visible;
    }
`;

export const GreenFooter = styled.div`
    background-color: ${colors.green};
    width: 100%;
    background-position: center;
    ${space};
`;

export const StyledContainer = styled.div`
    ${grid}
    ${space};
    width: 100%;
    display: grid;
    //  grid-template-columns: 3fr 2fr;
    grid-column-gap: 80px;
    grid-row-gap: 80px;
`;

export const LeftContainer = styled.div`
    @media screen and (max-width: 425px) {
        display: none;
    }
`;

export const StyledBottomContainer = styled.div`
  ${grid}
  ${layout}
  ${space}
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  & ul {
    margin: 0px;
  }
`;

export const StyledLinksContainer = styled.div`
    color: ${colors.white};
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
`;

export const NavItem = styled(NavLink)`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding-top: 18px;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
    color: white;
`;

export const StyledSmallPara = styled.div`
    ${grid}
    ${flexbox}
  display: flex;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: white;
    margin: 0px;

    & span {
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: white;
    }

    & a:hover {
        text-decoration: none;
    }
`;

export const NavHeadingItem = styled(NavLink)`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
    color: white;
`;

export const NavHeading = styled.h4`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    color: white;
`;

export const StyledSmallHeading = styled.h4`
    ${space}
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    color: white;
    margin: 0px;
    @media screen and (max-width: 425px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
`;

export const StyledHeading = styled.h2`
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 15px;
    @media screen and (max-width: 425px) {
        display: none;
    }
`;

// export const ImagesContainer = styled.div`
//   ${space}
//   ${grid}
//   ${layout}
//   display: grid;
//   align-items: center;
//   justify-content: center;

//   @media screen and (max-width: 425px) {
//     margin-left: 0px;
//   }
// `;

export const GridContainer = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
    padding: 40px;
    grid-gap: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    @media screen and (max-width: 425px) {
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
        grid-gap: 10px;
    }
`;

export const StyledImg = styled.img`
    ${space}
    ${layout}
  cursor: pointer;
    filter: grayscale(1);
    object-fit: fill;
    width: 100%;
    &:hover {
        filter: grayscale(0);
    }
`;

// ${({ src }) => `background: url(${src}) no-repeat;`}
//   ${({ hoverSrc }) =>
//     hoverSrc &&
//     `&:hover {
//       background: url(${hoverSrc}) no-repeat;
//     }
//   `}

export const RightContainer = styled.div``;

export const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
`;

export const SocialIcons = styled.div`
    ${space}
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: auto auto;
    grid-column-gap: 22px;
    grid-row-gap: 30px;

    & img {
        width: 38px;
    }
`;

export const AllIconsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-bottom: 60px;
    grid-row-gap: 50px;

    @media screen and (max-width: 425px) {
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

export const CountryIcons = styled.div`
    ${space}
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: auto auto;
    grid-column-gap: 14px;
    grid-row-gap: 11px;

    @media screen and (max-width: 425px) {
        grid-column-gap: 0px;
    }

    & img {
        width: 54px;
    }
`;

export const StyledInput = styled.input`
    font-size: 18px;
    padding: 12px 20px;
    border-radius: 5px;
    border: 3px solid ${colors.white};
    background: ${colors.white};
    ${({ status }) => status === 'failure' && `border: 3px solid ${colors.pink};`}
`;

export const InputsContainer = styled.div`
    ${space}
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 10px;
`;

export const ConsentContainer = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    gap: 13px;
    margin-top: 10px;
`;

export const CheckboxContainer = styled.div`
    position: relative;
`;

export const StyledCheckIcon = styled(CheckIcon)`
    display: block;
    position: absolute;
    cursor: pointer;
    top: 4px;
    ${({ checked }) => !checked && `display: none;`}
`;

export const Checkbox = styled.input`
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #ddedd0;

    cursor: pointer;
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus,
    &:hover {
        border: 1px solid ${colors.actionGreen};

        outline-color: ${colors.actionGreen};
    }
`;

export const ErrorCheckbox = styled(Checkbox)`
    border: 3px solid #ff8989;
    &:hover {
        border-bottom-color: #ff8989;
    }
    &:focus {
        border-bottom-color: #ff8989;
    }
`;

export const SubmitButtonContainer = styled.div`
    margin-top: 12px;
    ${space}
    ${layout}

  & > button {
        width: 100%;
    }
`;

export const Message = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.title_green};
    margin: 0px;
    line-height: 180%;
`;

export const InputContainer = styled.div`
    position: relative;
    ${space}
    & > input:focus, input:hover {
        border-bottom-color: ${colors.white} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
  & > input:focus, input:hover {
    border-bottom-color: ${colors.pink} !important;
}
  `}
    & > input::placeholder {
        color: ${colors.black};
    }
`;

export const MainLinks = styled.div`
    ${space}
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 35 px;
`;

export const StyledButton = styled.button`
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
`;

export const StyledLanguagesList = styled.ul`
    display: flex;
    list-style: none;
    color: ${colors.white};
    margin: 0px;
    ${flexbox}
`;

export const StyledListItem = styled.li`
    & > button {
        font-weight: 400;
        padding: 0px 6px;
        ${({ index }) => index === 0 && `padding-left: 0px;`}
        ${({ active }) =>
            active &&
            `
     font-weight: 700; 
    `}
    }
`;

export const StyledSuccessMessage = styled.p`
    font-size: 17px;
    font-weight: 500;
    color: ${colors.light_green};
    line-height: 17px;
`;

export const StyledSuccessMessageBlog = styled.p`
  font-size: 17px;
  font-weight: 500;
  font-family: 'Galano Grotesque';
  color: #000000
  line-height: 17px;
`;

export const FooterParent = styled.footer`
    padding: 6.875rem 4.8rem 1.75rem;
    .footer-main {
        max-width: 100%;
        align-self: stretch;
        flex-wrap: wrap;
        gap: 0 23px;
        color: ${({ theme }) => theme.colors.light_green};
        padding-bottom: 50px;

        .company-info {
            max-width: 280px;
            width: 100%;
            align-self: stretch;

            .logo-info {
                gap: 20px 0;
                z-index: 1;
                align-self: stretch;
                img {
                    width: 100%;
                    max-width: 230px;
                }
                .power-info {
                    align-self: stretch;
                    line-height: 180%;

                    p {
                        margin-top: 0;
                    }
                }
            }

            .follow-us-parent {
                align-self: stretch;
                gap: 12px 0;
                font-size: 16px;
                .social-icons-parent {
                    width: 168px;
                    gap: 0 8px;
                    text-align: center;
                    font-size: 22px;
                    color: ${({ theme }) => theme.colors.white};

                    .social-icons {
                        height: 36px;
                        width: 36px;
                        position: relative;
                        border-radius: 50%;
                        background-color: ${({ theme }) => theme.colors.title_green};
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        &:hover {
                            background-color: ${({ theme }) => theme.colors.light_green};
                        }
                    }
                }
            }
        }
        .footer-actions-parent {
            max-width: 100%;
            flex: 1;
            gap: 0 20px;
            min-width: 619px;
            z-index: 1;
            font-size: 16px;
            .footer-actions {
                max-width: 196px;
                padding: 0 10px 0 0;
                box-sizing: border-box;
                gap: 20px 0;

                .footer-action-head {
                    line-height: 180%;
                    display: inline-block;
                    flex-shrink: 0;
                }
                .subscrib-head {
                    width: 276px;
                }
                form {
                    .input-group {
                        gap: 9px;
                    }
                }
                .footer-links {
                    gap: 16px 0;
                    a {
                        font-weight: bold;
                        color: ${({ theme }) => theme.colors.title_green};
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;

                        &:hover {
                            color: ${({ theme }) => theme.colors.light_green};
                        }
                    }
                }
                &:last-child {
                    padding-right: 0;
                    max-width: 304px;
                }
            }
        }
    }
    .footer-copyright-parent {
        height: 40px;
        padding-top: 15px;
        border-top: 1px solid #f6f6f6;
        color: ${({ theme }) => theme.colors.text_color};

        .footer-copy-lists {
            align-self: stretch;
            max-width: 100%;
            gap: 20px;

            .lang-foooter-link {
                font-weight: 600;
                li {
                    position: relative;
                    a {
                        font-weight: 400;
                    }
                    &::after {
                        content: '/';
                        position: absolute;
                        left: 70px;
                    }
                    &:last-child {
                        &::after {
                            content: '';
                        }
                    }
                }
            }

            .footer-copy-links {
                gap: 0 40px;
                max-width: 100%;
                z-index: 1;
                font-size: 14px;
                a {
                    font-weight: 600;
                    color: ${({ theme }) => theme.colors.text_color};

                    &:hover {
                        color: black;
                    }
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.desktop}) {
        .footer-main {
            flex-direction: column;
            .footer-actions-parent {
                align-self: stretch;
                margin-top: 36px;
            }
            .company-info {
                gap: 24px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 26px 20px 30px;
        .footer-main {
            padding-bottom: 24px;
            .company-info {
                max-width: 100%;
                gap: 24px;
                .logo-info {
                    gap: 10px 0;
                    img {
                        width: 100%;
                        max-width: 207px;
                    }
                    .power-info {
                        p {
                            font-size: 12px;
                        }
                    }
                }
                .follow-us-parent {
                    font-size: 14px;
                }
            }
            .footer-actions-parent {
                font-size: 14px;
                flex-wrap: wrap;
                min-width: auto;
                gap: 24px;
                margin-top: 24px;
                .footer-actions {
                    flex: 1 0 auto;
                    gap: 12px;
                    min-width: auto;
                    form {
                        .input-group {
                            gap: 8px;
                            flex-direction: column;
                            input {
                                &:first-child {
                                    flex: 1 0 40%;
                                }
                            }
                        }
                    }
                    .footer-links {
                        gap: 8px;
                    }
                    .subscrib-head {
                        width: 276px;
                    }
                    &:first-child {
                        min-width: 190px;
                    }
                    &:last-child {
                        min-width: 100%;
                    }
                }
            }
        }
        .footer-copyright-parent {
            height: auto;
            padding-top: 24px;
            .footer-copy-lists {
                flex-direction: column;
                gap: 24px;
                .footer-copy-links {
                    gap: 5px;
                    font-size: 12px;
                    flex-direction: column;
                }
                .lang-foooter-link {
                    font-weight: 600;
                    gap: 20px;
                    flex-direction: row;
                    li {
                        position: relative;
                        &::after {
                            left: 55px;
                        }
                    }
                }
            }
        }
    }
`;
export const FormContainer = styled.div`
    margin: 0 auto;
    .consent-check {
        label {
            align-items: flex-start !important;
        }
    }
    .subscribe-btn {
        width: 100%;
    }
`;

export const Form = styled.form`
    gap: 12px 0;
`;

export const Input = styled.input`
    width: 100%;
    padding: 4px 16px;
    border: 1px solid #ddedd0;
    border-radius: 25px;
    line-height: 27px;
    font-size: 1rem;

    &:focus,
    &:hover {
        border: 1px solid ${colors.actionGreen};

        outline-color: ${colors.actionGreen};
    }
    ${({ status }) => status === 'failure' && `border: 1px solid ${colors.actionOrange};`}
`;
