import styled from 'styled-components';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';

export const HomeMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3EC;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 920px) {
        width: 75%;
        height: auto;
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;
export const Title = styled.h2`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    width: ${props => (props.noData ? '40%' : '100%')};
    color: ${props => (props.section ? '#000000' : '#FFFFFF')};
    @media screen and (max-width: 568px) {
        width: 70%;
        margin-right: 0px;
    }
`;

export const SubTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    align-items: baseline;
    margin-bottom: 20px;
    @media screen and (max-width: 568px) {
        margin-bottom: 0px;
    }
`;

export const WhatsNew = styled.div`
    margin: 35px auto;
    padding: 25px;
    width: 97%;
    height: auto;
    @media screen and (max-width: 568px) {
        padding: 0px;
        width: 90%;
    }
`;
export const GridContainer = styled.div`
    columns: 2;
    column-gap: 20px;
    background-color: inherit;
    padding-bottom: 20px;
    width: 100%;
    .grid-item {
        cursor:pointer;
        background-color: white;
        padding: 40px;
        margin-bottom: 20px;
        border-radius: 10px;
        break-inside: avoid;
        text-align: center;
        .link {
            color: #000000;
            text-decoration: none;
        }
    }
    .grid-item:hover {
        box-shadow: 0px 8px 8px 0 #f2f2f2, 0px 10px 20px 0 #f2f2f2;
        transition: 0.5s;
        margin-top: -2px;
    }
    @media screen and (max-width: 568px) {
        columns: 1;
        width: 100%;
    }
`;
