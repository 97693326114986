import React, { useState, useEffect } from 'react'
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import Footer from '../../../components/Footer';
import ImpactImage from '../../../components/ImpactImage';
import CarbonLineChart from '../../../components/Chart/CarbonImpactChart';
import EcoLineChart from '../../../components/Chart/EcoImpactChart';
import CustomHook from '../../../components/CustomHook';
import thumbprintRed from '../../../../../assets/newAssets/myNaturethumbprintRed.svg';
import thumbprintGreen from '../../../../../assets/newAssets/thumbprintGreen.svg';
import Button from '../../../../../newComponents/Button';
import { FormattedMessage } from 'react-intl';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';

import {
    ImpactMain, Title, CardTitle,
    Chart, CardChart, Tbody,
    SubTitle, Input, Text,
    InputBox, Data, Body,
    ButtonContainer,Disclaimer
} from './Styles';
const MyNatureImpactPage = ({ location, history, user }) => {
    const [carbonArray, setCarbonArray] = useState([]);
    const [ecoArray, setEcoArray] = useState([]);
    const [isCheckedC, setIsCheckedC] = useState(true);
    const [isCheckedE, setIsCheckedE] = useState(false);
    const historyPath = useHistory();
    const plans = CustomHook();

    useEffect(() => {
        if (user && user.userType === "empresa") {
            historyPath.push('/partner-profile/impact');
        }
    }, [user])

    useEffect(() => {
        if (!plans) {
            return;
        }
        if (carbonArray.length > 0)
            return;
        if (plans.planArrayEco && plans.planArrayEco.length > 0 &&plans.planArrayCar && plans.planArrayCar.length > 0 && plans.Cf && plans.Cf.length > 0 && plans.Ef && plans.Ef.length > 0) {
            compute(plans.planArrayEco,plans.planArrayCar, plans.Cf, plans.Ef);
        }

    }, [plans]);
    function compute(planArrayEco,planArrayCar, Cf, Ef) {
        let carbon = Cf.map((item, i) => {
            return planArrayCar[i] - item;
        })

        let eco = Ef.map((item, i) => {
            return planArrayEco[i] - item;
        })
        setCarbonArray(carbon);
        setEcoArray(eco);
    }
    function onEcoChange() {
        setIsCheckedE(!isCheckedE);
        setIsCheckedC(!isCheckedC);
    }
    function onCarbonChange() {
        setIsCheckedC(!isCheckedC);
        setIsCheckedE(!isCheckedE);
    }
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Friend profile<br/>& account</>}
                image={HeroImage}
            />
            <ImpactMain >
                <Title>
                    <FormattedMessage id="MyNaturePage.Impact.Title" />
                </Title>
                <CardTitle>
                    <FormattedMessage id="MyNaturePage.Impact.Title.CurrentImpact" />
                </CardTitle>
                <ImpactImage />
                <CardTitle>
                    <FormattedMessage id="MyNaturePage.Impact.Title.NetImpact" />
                </CardTitle>
                <CardChart>
                    <CardTitle chart><FormattedMessage id="MyNaturePage.Impact.Title.ViewImpact" /></CardTitle>
                    <Body>
                        <Tbody>
                            <InputBox>
                                <Input type="radio" name="ecologist"
                                    checked={isCheckedE}
                                    onChange={onEcoChange} />
                                <SubTitle><FormattedMessage id="MyNaturePage.Impact.Title.EcoFootprintReduced" /></SubTitle>
                            </InputBox>
                            <Data>
                                {plans.ecoResults.total_ef_icl_biodiversity &&
                                    (plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact > 0) ?
                                    <>
                                        <Text red>
                                            - {plans.ecoResults.total_ef_icl_biodiversity
                                                && Math.abs(plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact).toFixed(1)}
                                            &nbsp;<br/>global hectares
                                        </Text>
                                        <img className="smallThumbprint" src={thumbprintRed} />
                                    </>
                                    :
                                    <>
                                        <Text green>
                                            + {plans.ecoResults.total_ef_icl_biodiversity
                                                && Math.abs(plans.ecoResults.total_ef_icl_biodiversity.toFixed(1) - plans.ecoImpact).toFixed(1)}
                                            &nbsp;<br/>global hectares
                                        </Text>
                                        <img className="smallThumbprint" src={thumbprintGreen} />
                                    </>
                                }
                            </Data>
                        </Tbody>
                        <Tbody>
                            <InputBox>
                                <Input type="radio" name="carbon"
                                    checked={isCheckedC}
                                    onChange={onCarbonChange} />
                                <SubTitle><FormattedMessage id="MyNaturePage.Impact.Title.CarbonFootprintReduced" /></SubTitle>
                            </InputBox>
                            <Data>
                                {plans.ecoResults.total_cf && (plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact > 0) ?
                                    <>
                                        <Text red>
                                           - {plans.ecoResults.total_cf && Math.abs(plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact).toFixed(1)} <br/>tonnes
                                        </Text>
                                        <img className="smallThumbprint" src={thumbprintRed} />
                                    </>
                                    :
                                    <>
                                        <Text green>
                                            + {plans.ecoResults.total_cf && Math.abs(plans.ecoResults.total_cf.toFixed(1) - plans.climateImpact).toFixed(1)} <br/>tonnes
                                        </Text>
                                        <img className="smallThumbprint" src={thumbprintGreen} />
                                    </>
                                }
                            </Data>
                        </Tbody>
                    </Body>
                    {isCheckedC && carbonArray.length > 0 && (
                        <Chart>
                            {/* {carbonArray.length>1 && <Disclaimer>*<FormattedMessage id="MyNaturePage.Footprint.Disclaimer" /> </Disclaimer>} */}
                            <CarbonLineChart data={carbonArray} year = {plans.year}/>
                        </Chart>)
                    }
                    {isCheckedE && ecoArray.length > 0 && (
                        <Chart>
                            {/* {ecoArray.length>1 && <Disclaimer>*<FormattedMessage id="MyNaturePage.Footprint.Disclaimer" /> </Disclaimer>} */}
                            <EcoLineChart data={ecoArray} year = {plans.year}/>
                        </Chart>)
                    }
                    <ButtonContainer>
                        <Button
                            variant="filled"
                            size="md"
                            path="/people"
                        >
                            <FormattedMessage id="MyNaturePage.Impact.Button.Invest" />
                        </Button>
                    </ButtonContainer>
                    <br />
                </CardChart>
                <Footer />
                <MobileComp><RightNav /></MobileComp>
            </ImpactMain>
            <RightNav />
        </Frame>
    )
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
            userType: storeState.user.currentUser.attributes.profile.userType,
        }
        : null,
});
export default connect(mapStateToProps)(withRouter(MyNatureImpactPage));
