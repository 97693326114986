import styled from 'styled-components';
// styled

export const SitemapSection = styled.section`
    position: relative;
    .green-bg {
        min-height: 300px;
        align-items: flex-start;
        background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
        background-size: cover;
        margin-bottom: -4px;
        padding-bottom: 50px;

        .title-box {
            padding: 30px 0;
            margin-bottom: 16px;
            h1 {
                font-size: 48px;
                line-height: 58px;
                .border {
                    position: relative;
                    text-wrap: nowrap;
                    img {
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }
        .text-white {
            color: white;
        }
        .wrapper {
            max-width: 1275px;
            gap: 25px;
            padding-left: 20px;
            padding-right: 20px;
            z-index: 9;
            .col-box {
                background-color: white;
                padding: 40px;
                border-radius: 20px;
                gap: 20px 120px;
                .list-col {
                    flex: 1 0 auto;
                    .title-links {
                        margin-bottom: 25px;
                        .small-title {
                            margin-bottom: 15px;
                        }
                        .sitemap-links {
                            li {
                                margin: 10px 0;
                                a {
                                    font-size: 18px;
                                    display: block;
                                    color: #6ea44c;
                                    &:hover {
                                        color: #497d28;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .green-bg {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 45px;
            gap: 0;
            width: 100%;
            .wrapper {
                position: relative;
                flex-wrap: wrap;
                padding: 20px 20px 30px;
                .col-box {
                    min-height: auto;
                    min-width: auto;
                    width: 100%;
                    padding: 30px 25px 25px;
                    gap: 20px 50px;
                    .list-col {
                        .title-links {
                            margin-bottom: 25px;
                            .small-title {
                                margin-bottom: 15px;
                            }
                            .sitemap-links {
                                li {
                                    margin: 10px 0;
                                    a {
                                        font-size: 18px;
                                        display: block;
                                        color: #6ea44c;
                                        &:hover {
                                            color: #497d28;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
