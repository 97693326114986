import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { StyledButton, StyledSpinner } from './Styles';

const Button = ({
    children,
    onClick,
    size,
    variant,
    path,
    disabled,
    inProgress,
    minWidth,
    testId,
}) => {
    if (path) {
        return (
            <NavLink data-testid={testId} to={path}>
                <StyledButton
                    disabled={disabled}
                    inProgress={inProgress}
                    size={size}
                    variant={variant}
                    onClick={onClick}
                    width={size !== 'md' ? [1, '465px'] : undefined}
                    px={size !== 'md' && [0, 30]}
                    //for mobile view
                >
                    {children}
                </StyledButton>
            </NavLink>
        );
    }
    return (
        <StyledButton
            size={size}
            disabled={disabled}
            variant={variant}
            onClick={onClick}
            minWidth={minWidth}
            width={size !== 'md' ? [1, '465px'] : undefined}
            px={size !== 'md' && [0, 30]}
            data-testid={testId} // Add data-testid here
            //for mobile view
        >
            {inProgress ? <StyledSpinner /> : children}
        </StyledButton>
    );
};

Button.propTypes = {
    /**
     * Button contents
     */
    children: PropTypes.node.isRequired,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func.isRequired,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['md', 'lg', 'xl']).isRequired,
    /**
     * Button type?
     */

    variant: PropTypes.oneOf([
        'filled',
        'filledBlack',
        'footprintChoiceCalculate',
        'footprintChoiceManage',
        'ghost',
        'invertedGhost',
        'disabled',
        'disabledShare',
    ]).isRequired,
};

Button.defaultProps = {
    children: '',
    onClick: () => {},
    size: 'md',
    variant: 'filled',
};

export default Button;
