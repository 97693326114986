import React from 'react';

import styled from 'styled-components';

const CustomTooltip = ({ label, children }) => (
    <TooltipContainer>
        {children}
        <TooltipText>{label}</TooltipText>
    </TooltipContainer>
);

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipText = styled.span`
    visibility: hidden;
    width: max-content;
    background-color: ${({ theme }) => theme.colors.title_green};
    color: white;
    text-align: center;
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    ${TooltipContainer}:hover & {
        visibility: visible;
        opacity: 1;
    }
    ::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.title_green} transparent transparent transparent;
    }
`;

export default CustomTooltip;
