import { graphqlFetchQuery } from '../../coreApi';

export const getLatestBlogsQuery = lang => {
    return `
      query getLatestBlogs {
        blogPosts (first: 3, where: {wpmlLanguage:"${lang}", orderby: {field: DATE, order: DESC}}){
          nodes {
            title
            content
            slug
            blog_details {
              readTime
              thumbnail
              featuredImage
              summary
              publishDate
            }
            writer {
              name
              about
              photo
            }
            taxonomy {
              category {
                name
                id
              }
              tags {
                name
                id
              }
            }
          }
        }
      }
    `;
};

export const getLatestBlogs = async lang => {
    try {
        const result = await graphqlFetchQuery(getLatestBlogsQuery(lang));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getAllCategoriesQuery = lang => {
    return ` 
      query getAllCategories {
        categories(first: 1000, where: {wpmlLanguage: "${lang}"}) {
          nodes {
            name
            categoryId
          }
        }
      }   
    `;
};

export const getAllCategories = async lang => {
    try {
        const result = await graphqlFetchQuery(getAllCategoriesQuery(lang));
        return { status: 'success', data: result.categories.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getBlogsByCategoryQuery = (lang, categoryName, count = 3) => {
    return `
    query getDataByCategory {
      blogPosts(
        first: ${count},
        where: {
          wpmlLanguage:"${lang}",
          orderby: {field: DATE, order: DESC},
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: ["${categoryName}"],
                taxonomy: CATEGORY,
                operator: IN,
                field: NAME
              },
            ]
          }
        }
      ){
        nodes{
            title
            slug
            blog_details {
              thumbnail
              featuredImage
              readTime
              summary
              publishDate
            }
            taxonomy {
              tags {
                name
                id
              }
            }
            writer {
              name
            }
          }
        }
      }
      `;
};

export const getBlogsByCategory = async (lang, categoryName) => {
    try {
        const result = await graphqlFetchQuery(getBlogsByCategoryQuery(lang, categoryName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getAllBlogsByCategoryQuery = (lang, categoryName) => {
    return `query getBlogsByCategory {
      blogPosts(
        first: 1000,
        where: {
          wpmlLanguage:"${lang}",
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: ["${categoryName}"],
                taxonomy: CATEGORY,
                operator: IN,
                field: NAME
              },
            ]
          }
        }
      ){
        nodes {
          title
          slug
          blog_details {
            thumbnail
            featuredImage
            readTime
            summary
            publishDate
          }
          taxonomy {
            tags {
              name
              id
            }
          }
          writer {
            name
          }
        }
      }
    } 
    `;
};

export const getAllBlogsByCategory = async (lang, categoryName) => {
    try {
        const result = await graphqlFetchQuery(getAllBlogsByCategoryQuery(lang, categoryName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getBlogBySlugQuery = slug => {
    return `query getSingleBlogBySlug {
      blogPost(id: "${slug}", idType: SLUG) {
        title
        content
        blog_details {
          readTime
          thumbnail
          featuredImage
          summary
          publishDate
        }
        writer {
          name
          photo
          about
        }
        taxonomy {
          category {
            name
            id
          }
          tags {
            name
            id
          }
        }
        locale {
          id
        }
        translated {
          title
          slug
          content
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            photo
            about
          }
          taxonomy {
            category {
              name
              id
            }
            tags {
              name
              id
            }
          }
          locale {
            id
          }
        }
        translations {
          locale
          href
        }
      }
    }
    `;
};

export const getBlogBySlug = async slug => {
    try {
        const result = await graphqlFetchQuery(getBlogBySlugQuery(slug));
        return { status: 'success', data: result.blogPost };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

const searchBlogsQuery = (lang, query) => {
    return `query getLatestBlogs {
      blogPosts (where: {wpmlLanguage: "${lang}", search: "${query}"}, first: 1000){
        nodes {
          title
          content
          slug
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            about
            photo
          }
          taxonomy {
            category {
              name
            }
            tags {
              name
            }
          }
        }
      }
    }`;
};

const searchBlogsWithCategoryFilterQuery = (lang, query, category) => {
    return `query getBlogsByCategory {
      blogPosts(
        first: 1000,
        where: {
          wpmlLanguage:"${lang}",
          search: "${query}",
          taxQuery: {
            relation: OR,
            taxArray: [
              {
                terms: ["${category}"],
                taxonomy: CATEGORY,
                operator: IN,
                field: NAME
              },
            ]
          }
        }
      ){
        nodes {
          title
          content
          slug
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            about
            photo
          }
          taxonomy {
            category {
              name
            }
            tags {
              name
            }
          }
        }
      }
    } 
    `;
};

export const searchBlogs = async (lang, query, category = '') => {
    try {
        let result;
        if (!category) {
            result = await graphqlFetchQuery(searchBlogsQuery(lang, query));
        } else {
            result = await graphqlFetchQuery(
                searchBlogsWithCategoryFilterQuery(lang, query, category)
            );
        }
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

const getAllBlogsByTagQuery = (lang, tagName) => {
    return `query getDataByTag {
    blogPosts(
      first: 1000,
      where: {
        wpmlLanguage: "${lang}",
        taxQuery: {
          relation: OR,
          taxArray: [
            {
              terms: ["${tagName}"],
              taxonomy: TAG,
              operator: IN,
              field: NAME
            },
          ]
        }
      }
    ){
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          readTime
          summary
          publishDate
        }
        taxonomy {
          tags {
            name
            id
          }
        }
        writer {
          name
        }
      }
    }
  }  
  `;
};

export const getAllBlogsByTag = async (lang, tagName) => {
    try {
        const result = await graphqlFetchQuery(getAllBlogsByTagQuery(lang, tagName));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const handleQueryLanguage = async lang => {
    if (lang === 'ES') {
        return 'es';
    }
    if (lang === 'PT') {
        return 'pt-br';
    }
    return 'en';
};

export const getTenLatestBlogsQuery = lang => {
    return `
    query getLatestBlogs {
      blogPosts (first: 10, where: {wpmlLanguage:"${lang}", orderby: {field: DATE, order: DESC}}){
        nodes {
          title
          content
          slug
          blog_details {
            readTime
            thumbnail
            featuredImage
            summary
            publishDate
          }
          writer {
            name
            about
            photo
          }
          taxonomy {
            category {
              name
              id
            }
            tags {
              name
              id
            }
          }
        }
      }
    }
  `;
};

export const getTenLatestBlogs = async lang => {
    try {
        const result = await graphqlFetchQuery(getTenLatestBlogsQuery(lang));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};

export const getLatestBlogsByTagQuery = (lang, tagSlug) => {
    return `
  query getLatestBlogsByTag {

    blogPosts(
      first: 3
      where: {
       wpmlLanguage: "${lang}",
       taxQuery: {
        relation: AND, taxArray: [{terms: ["${tagSlug}"], taxonomy: LANDSCAPE, operator: IN, field: SLUG}]
        }
      }
    ) {
      nodes {
        title
        slug
        blog_details {
          thumbnail
          featuredImage
          publishDate
        }
      }
    }
  }
`;
};

export const getLatestBlogsByTag = async (lang, tagSlug) => {
    try {
        const result = await graphqlFetchQuery(getLatestBlogsByTagQuery(lang, tagSlug));
        return { status: 'success', data: result.blogPosts.nodes };
    } catch (err) {
        return { status: 'error', message: 'Something went wrong. Please try again later.' };
    }
};
