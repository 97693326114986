
import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import { useDispatch } from 'react-redux';
import { activateUserRole} from '../../../../../coreApi';
import { fetchCurrentUser } from '../../../../../ducks/user.duck';

import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import {
    AccountRoleMain,
    RoleCardWrapper,
    SubRoleCardWrapper,
    titleStyle,
    descriptionStyle,
    imageContainer,
    container,
    textContainer,
    MainSubRoleWrapper,
    ModalSubTitle,
    ModalTitle,
    Error,

} from './Styles'

import ImageDescription from '../../../../../newComponents/ImageDescription/ImageDescription'
import partnerSubrole1 from './../../../../../assets/newAssets/partner_subrole_1.png';
import partnerSubrole2 from './../../../../../assets/newAssets/partner_subrole_2.png';
import partnerSubrole3 from './../../../../../assets/newAssets/partner_subrole_3.png';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/partner_profile.png';
import { consoleSandbox } from '@sentry/utils';
const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
                activatedRole : storeState.user.currentUser.activatedRoles,
                activatedUserRoles : storeState.user.currentUser.activatedUserRoles
        }
        : null,

});

const ActivatePartnerRole = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {

    const [error, setError] = useState({value:false, message:"", role:""});
    const [isDisable, setIsDisable] = useState({value:false, role:""});
    const dispatch = useDispatch();

    //please make subsquent chnages in the ImageDescription props if sequence in the below array is changed
    const subRoles = [
        {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.1" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.1" />,
        image: partnerSubrole1,
        role: "partner",
        userRole:"landscape partner"
    },
    {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.2" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.2" />,
        image: partnerSubrole2,
        role: "partner",
        userRole:"product partner"
    },
    {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.3" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.3" />,
        image: partnerSubrole3,
        role: "partner",
        userRole:"impact partner"
    },
]
const activateRole = async (role) =>{
    setIsDisable({
        value:true,
        role:role
    })
    await activateUserRole(role)
    .then (async (response)=>{
        dispatch(fetchCurrentUser());
        setIsDisable({
            value:false,
            role:role
        })
    },
    async (error)=>{
        setIsDisable({
            value:false,
            role:role
        })
        const response = error.response.data.errors;
        if(response === "role already present"){
            setError({
                value:true,
                message:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.Role.Error" />,
                role:role
            });
        }
        else{
            setError({
                value:true,
                message:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.Role.Error" />,
                role:role
            });
        }
    });

}
    const userRoles = user && user.activatedUserRoles && user.activatedUserRoles.partner;
    const landscapeRole = userRoles && userRoles.includes(subRoles[0].userRole);
    const productPartnerRole = userRoles && userRoles.includes(subRoles[1].userRole);
    const systemPartnerRole = userRoles && userRoles.includes(subRoles[2].userRole);
    
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Partner profile<br/>& account</>}
                image={HeroImage}
            />
            <AccountRoleMain>
                <RoleCardWrapper>
                    <ImageDescription
                        src={subRoles[0].image}
                        description={subRoles[0].description}
                        container={container}
                        titleStyle = {titleStyle}
                        descriptionStyle = {descriptionStyle}
                        imageContainer={imageContainer}
                        textContainer={textContainer}
                        title={subRoles[0].title}
                        inverse={true}
                        button={true}
                        buttonDisable={landscapeRole}
                        error={error.role === subRoles[0].userRole && error }
                        isLoader={true}
                        isDisable={isDisable.role === subRoles[0].userRole && isDisable.value}
                        handleClick= {async () =>{
                            await activateRole(subRoles[0].userRole)
                        }}
                        buttonformattedMessageId={landscapeRole?"MyNaturePage.ActivateRole.AccountPresent":"MyNaturePage.ActivateRole.ActivateAccount"}
                    />
                </RoleCardWrapper>
                <RoleCardWrapper>
                    <ImageDescription
                            src={subRoles[1].image}
                            description={subRoles[1].description}
                            container={container}
                            titleStyle = {titleStyle}
                            descriptionStyle = {descriptionStyle}
                            imageContainer={imageContainer}
                            textContainer={textContainer}
                            title={subRoles[1].title}
                            inverse={true}
                            button={true}
                            buttonDisable={productPartnerRole}
                            error={error.role === subRoles[1].userRole && error }
                            isLoader={true}
                            isDisable={isDisable.role === subRoles[1].userRole && isDisable.value}
                            handleClick= {async () =>{
                                await activateRole(subRoles[1].userRole)
                            }}
                            buttonformattedMessageId={productPartnerRole?"MyNaturePage.ActivateRole.AccountPresent":"MyNaturePage.ActivateRole.ActivateAccount"}
                        />
                </RoleCardWrapper>
                <RoleCardWrapper>
                    <ImageDescription
                            src={subRoles[2].image}
                            description={subRoles[2].description}
                            container={container}
                            titleStyle = {titleStyle}
                            descriptionStyle = {descriptionStyle}
                            imageContainer={imageContainer}
                            textContainer={textContainer}
                            title={subRoles[2].title}
                            inverse={true}
                            button={true}
                            buttonDisable={systemPartnerRole}
                            error={error.role === subRoles[2].userRole && error }
                            isLoader={true}
                            isDisable={isDisable.role === subRoles[2].userRole && isDisable.value}
                            handleClick= {async () =>{
                                await activateRole(subRoles[2].userRole)
                            }}
                            buttonformattedMessageId={systemPartnerRole?"MyNaturePage.ActivateRole.AccountPresent":"MyNaturePage.ActivateRole.ActivateAccount"}
                        />
                </RoleCardWrapper>
                <MobileComp><RightNav /></MobileComp>
            </AccountRoleMain>
            <RightNav />
        </Frame>
    )
}
);
const ActivatePartnerRolePage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(ActivatePartnerRole);
export default ActivatePartnerRolePage;
