import styles from './SetupAccount.module.css';
import React, { useRef, useState } from 'react';
import FormikControl from '../../../newComponents/Form/FormikControl';
import Button from '../../../newComponents/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { GoogleLogin } from 'react-google-login';
import * as Yup from 'yup';
import ReCaptcha from 'react-google-recaptcha';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { signup, signUpWithGoogle } from '../../../ducks/Auth.duck';
import { fetchCurrentUser } from '../../../ducks/user.duck';
import { activateRole } from '../../../coreApi';
import { isSignupEmailTakenError } from '../../../util/errors';
import { NavLink } from 'react-router-dom';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;

const accountSetupValScheme = Yup.object({
    firstName: Yup.string().required(
        <FormattedMessage id="NewGuardianPage.Setup.Account.firstName.errMsg" />
    ),
    lastName: Yup.string().required(
        <FormattedMessage id="NewGuardianPage.Setup.Account.lastName.errMsg" />
    ),
    email: Yup.string()
        .email(<FormattedMessage id="NewGuardianPage.Setup.Account.emailInv.errMsg" />)
        .required(<FormattedMessage id="NewGuardianPage.Setup.Account.emailReq.errMsg" />),
    password: Yup.string().required(
        <FormattedMessage id="NewGuardianPage.Setup.Account.pwd.errMsg" />
    ),
});

export const accountSetupInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
};

const SetupAccount = props => {
    const [isLoading, setLoading] = useState(false);
    const [isLoadingGoogleSignup, setLoadingGoogleSignup] = useState(false);
    const { intl } = props;
    const reCaptchaRef = useRef(null);
    const { loginError, signupError } = props;

    const responseGoogle = async response => {
        setLoadingGoogleSignup(true);
        if (response.tokenId) {
            let captchaToken;
            if (reCaptchaRef.current) {
                captchaToken = await reCaptchaRef.current.executeAsync();
                reCaptchaRef.current.reset();
            }
            await props
                .submitSignupWithGoogle({
                    tokenId: response.tokenId,
                    captchaToken,
                })
                .then(async () => {
                    await props.fetchCurrentUser();
                });
        }
        setLoadingGoogleSignup(false);
    };

    return (
        <>
            <div className={styles.accountSetupContainer}>
                <div className={styles.accountSetup}>
                    <h5 className={styles.setupAccountHeading}>
                        <FormattedMessage id="NewPartnersPage.Setup.Account.subTitle" />
                    </h5>

                    <Formik
                        initialValues={accountSetupInitialValues}
                        validationSchema={accountSetupValScheme}
                        onSubmit={async values => {
                            try {
                                setLoading(true);
                                let captchaToken;
                                if (reCaptchaRef.current) {
                                    captchaToken = await reCaptchaRef.current.executeAsync();
                                    reCaptchaRef.current.reset();
                                }
                                await props
                                    .submitSignup({ ...values, captchaToken })
                                    .then(async () => {
                                        await props.fetchCurrentUser();
                                    });
                            } catch (err) {
                                /** TODO: Try to find where signup function is throwing error and handle that. This is a temporary solution. */
                                console.log(err);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        {({ values, errors, isValid, setFieldValue }) => (
                            <div className={styles.loginFormContainer}>
                                <Form className={styles.loginForm}>
                                    <ReCaptcha
                                        ref={reCaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        size="invisible"
                                    />
                                    <div className={styles.loginFormRow}>
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label={intl.formatMessage({
                                                id: 'NewPartnersPage.Setup.Account.firstName',
                                            })}
                                            name="firstName"
                                            style={{ maxWidth: '268px' }}
                                        />
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label={intl.formatMessage({
                                                id: 'NewPartnersPage.Setup.Account.lastName',
                                            })}
                                            name="lastName"
                                            style={{ maxWidth: '260px' }}
                                        />
                                    </div>
                                    <div className={styles.loginFormRow}>
                                        <FormikControl
                                            control="input"
                                            type="email"
                                            label={intl.formatMessage({
                                                id: 'NewPartnersPage.Setup.Account.emailAddress',
                                            })}
                                            name="email"
                                            style={{ maxWidth: '268px' }}
                                        />
                                        <FormikControl
                                            control="input"
                                            type="password"
                                            label={intl.formatMessage({
                                                id: 'NewPartnersPage.Setup.Account.password',
                                            })}
                                            name="password"
                                            style={{ maxWidth: '260px' }}
                                        />
                                    </div>
                                    <Button
                                        testId="btn-guardian-accountsetup-continue"
                                        variant={isLoading ? 'disabled' : 'filled'}
                                    >
                                        <FormattedMessage id="NewPartnersPage.Setup.Account.continue" />
                                    </Button>
                                </Form>
                            </div>
                        )}
                    </Formik>

                    <h5>or</h5>

                    {googleClientId && (
                        <GoogleLogin
                            className={styles.googleButton}
                            clientId={googleClientId}
                            disabled={isLoadingGoogleSignup}
                            buttonText={intl.formatMessage({
                                id: 'NewPartnersPage.Setup.Account.GoogleBtn',
                            })}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    )}
                    {(signupError || loginError) && (
                        <div
                            style={{
                                fontSize: 16,
                                color: '#ff0000',
                                fontWeight: 500,
                                fontFamily: 'Galano Grotesque',
                            }}
                        >
                            {isSignupEmailTakenError(signupError) ? (
                                <>
                                    <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />{' '}
                                    <NavLink to={'/login'}>
                                        <span className={styles.loginButton}>Login</span>
                                    </NavLink>
                                </>
                            ) : (
                                <FormattedMessage id="AuthenticationPage.signupFailed" />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { loginError, signupError } = state.Auth;
    return {
        loginError,
        signupError,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSignup: params => dispatch(signup(params)),
    fetchCurrentUser: params => dispatch(fetchCurrentUser()),
    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
});

export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(SetupAccount));
