import React, { Component } from 'react';
import willy from '../../assets/willy.jpg';
import butterfly from '../../assets/icons/butterfly.svg';
import mountainsfull from '../../assets/icons/mountainsfull.svg';
import { FormattedMessage } from 'react-intl';
import plant from '../../assets/icons/plant.svg';
import alejandra from '../../assets/herrera.jpg';
import heroimage from '../../assets/personas-hero.jpg';
import claudia from '../../assets/claudia.jpg';
import {
    Hero,
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    ProductComparison,
    UpButton,
    Modal,
    SecondaryButton,
} from '../../components';
import { StaticPage } from '../../containers';
import css from './Personas.css';
import { FaRegPlayCircle } from 'react-icons/fa';
import PlansComparison from '../../components/ChoosePlans/PlansComparison';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class PeoplePage extends Component {
    state = { videoModalOpen: false };

    render() {
        console.log('history', this.props);
        return (
            <StaticPage
                title="Regeneradores"
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'AboutPage',
                    description:
                        'Personas como tú que aman la naturaleza y la cuidan a través de nuestros planes.',
                    name: 'Regeneradores',
                }}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={this.props.location.pathname}
                            user={this.props.user}
                            history={this.props.history}
                        />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain>
                        <Hero
                            title={<FormattedMessage id="Individuals.title" />}
                            backgroundPosition="right -50px"
                            subtitle={<FormattedMessage id="Individuals.subtitle" />}
                            image={heroimage}
                        />
                        <section className={css.sectionContent}>
                            <h2 className={css.title}>
                                <FormattedMessage id="Individuals.3Icon.Title" />
                            </h2>
                            <ul className={css.list}>
                                <li className={css.listItem}>
                                    <img src={plant} />
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Individuals.3Icon.item1" />
                                    </p>
                                </li>
                                <li className={css.listItem}>
                                    <img src={mountainsfull} />
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Individuals.3Icon.item2" />
                                    </p>
                                </li>
                                <li className={css.listItem}>
                                    <img src={butterfly} />
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Individuals.3Icon.item3" />
                                    </p>
                                </li>
                            </ul>
                            <div className={css.modalButtonContainer}>
                                <SecondaryButton
                                    className={css.videoButton}
                                    onClick={() => this.setState({ videoModalOpen: true })}
                                >
                                    <span className={css.videoButton_text}>
                                        <FormattedMessage id="Individuals.VideoText" />
                                    </span>
                                    <FaRegPlayCircle />
                                </SecondaryButton>
                            </div>
                        </section>

                        <section className={`${css.sectionContent} ${css.joinWrapper}`}>
                            <h2 className={css.title}>
                                <FormattedMessage id="Individuals.howToJoin.title" />
                            </h2>
                            <div className={css.jointext}>
                                <p>
                                    <FormattedMessage id="Individuals.howToJoin.description" />
                                </p>
                            </div>
                        </section>

                        <section id="become" className={css.plans}>
                            <div className={css.sectionContent}>
                                {/* <ProductComparison numerOfProducts={5}/> */}
                                <PlansComparison userType="personas" />
                            </div>
                        </section>

                        <section id="testimonials" className={css.testimony}>
                            <div className={css.sectionContent}>
                                <h2 className={css.title}>
                                    <FormattedMessage id="Testimonials.title.landingPage" />
                                </h2>
                                <div className={css.testimonyList}>
                                    <div>
                                        <img className={css.testimonyPic} src={alejandra} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.alejandra.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.alejandra.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.alejandra.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <img className={css.testimonyPic} src={willy} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.guillermo.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.guillermo.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.guillermo.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <img className={css.testimonyPic} src={claudia} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.claudia.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.claudia.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.claudia.title" />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <UpButton />
                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer history={this.props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>

                <Modal
                    lightCloseButton={true}
                    containerClassName={css.modalVideo}
                    isOpen={this.state.videoModalOpen}
                    onClose={() => this.setState({ videoModalOpen: false })}
                    onManageDisableScrolling={() => {}}
                >
                    {this.state.videoModalOpen ? (
                        <React.Fragment>
                            <iframe
                                className={css.video}
                                src={`https://player.vimeo.com/video/374552164?autoplay=1&color=2DC000&title=0&byline=0&portrait=0`}
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            ></iframe>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </React.Fragment>
                    ) : null}
                </Modal>
            </StaticPage>
        );
    }
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(PeoplePage));
