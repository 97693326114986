import React from 'react';
import { injectIntl } from 'react-intl';
import { NewAction, Page } from '../../components';
import css from './NewActionPage.css';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const NewActionPage = props => {
  const title = 'New action';
  const scrollingDisabled = true;
  return (
    <Page title={title} >
      <Header currentPath={props.location.pathname} user={props.user} history={props.history} />
      <NewAction />
    </Page>
  );
};

const mapStateToProps = storeState => ({
  user: storeState.user.currentUser
    ? {
        initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
        name:
          storeState.user.currentUser.attributes.profile.firstName +
          ' ' +
          storeState.user.currentUser.attributes.profile.lastName,
      }
    : null,
});

export default connect(mapStateToProps)(withRouter(injectIntl(NewActionPage)));
