import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import { LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';
import titleBorderSingle from '../../../../assets/newAssets/title-border-single.svg';
import listLeaf from '../../../../assets/newAssets/icons/list-leaf.svg';
import benefitsMembers from '../../../../assets/newAssets/benefits-members.svg';
import benefitsMembersEs from '../../../../assets/newAssets/benefits-members-es.svg';
import { useSelector } from 'react-redux';

import { Benefits } from './Styles';

const Membership = () => {
    const { selectedLanguage } = useSelector(storeState => storeState.Languages);

    return (
        <Benefits listLeaf={listLeaf}>
            <div className="two-cols flex justify-start items-center mx-auto">
                <div className="Benefits-info flex flex-col">
                    <div className="title-box">
                        <h3>
                            <FormattedMessage id="NewLandingPage.benefits.title" />
                        </h3>
                        <img src={titleBorderSingle} alt="border" />
                        <div className="description">
                            <p>
                                <FormattedMessage id="NewLandingPage.benefits.description" />
                            </p>
                        </div>
                    </div>
                    <div className="list-of-benefits flex flex-col">
                        <ul className="flex flex-col">
                            <li>
                                <FormattedMessage id="NewLandingPage.benefits.bullets.footprint" />
                            </li>
                            <li>
                                <FormattedMessage id="NewLandingPage.benefits.bullets.action" />
                            </li>
                            <li>
                                <FormattedMessage id="NewLandingPage.benefits.bullets.certify" />
                            </li>
                            <li>
                                <FormattedMessage id="NewLandingPage.benefits.bullets.updates" />
                            </li>
                            <li>
                                <FormattedMessage id="NewLandingPage.benefits.bullets.digital" />
                            </li>
                        </ul>
                        <LightGreenBtn className="light-btn">
                            <Link to="/organisation/calculator">
                                <FormattedMessage id="NewLandingPage.benefits.calculateFootprint" />
                            </Link>
                        </LightGreenBtn>
                    </div>
                </div>
                <div className="img-box">
                    <img
                        src={selectedLanguage.short === 'EN' ? benefitsMembers : benefitsMembersEs}
                        alt="membership benefits"
                    />
                </div>
            </div>
        </Benefits>
    );
};

export default Membership;
