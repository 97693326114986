import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import Header from '../../NewLandingPage/Sections/Header/Header';
import Footer from '../../NewLandingPage/Sections/Footer/Footer';
import { Page } from '../../../components';
import styled from 'styled-components';
import { saveUserCountryForGuardianRole } from '../utils';
import CustomRadioSelect from '../../../newComponents/SelectRadioDropdown';
import { OrangeBtn } from '../../../newComponents/Button/ButtonSkin';
import { countryCodes } from '../../../translations/countryCodes';

const SelectCountrySection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .thankyou-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 30px;
            > label {
                margin-bottom: 15px;
                display: block;
            }
            .submit-btn {
                max-width: 216px;
                margin-top: 25px;
            }
        }
    }
    @media (max-width: 500px) {
        .thankyou-box {
            padding: 100px 20px 120px;
        }
    }
`;

const ThankyouOutsideSection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .thankyou-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 30px;
            > label {
                margin-bottom: 15px;
                display: block;
            }
            .submit-btn {
                max-width: 216px;
            }
        }
    }
    @media (max-width: 500px) {
        .thankyou-box {
            padding: 100px 20px 120px;
        }
    }
`;

const transformedCountryCodes = [
    {
        name: 'countryTarget',
        value: <FormattedMessage id="GuardiansPage.SelectCountry.SelectCountry" />,
    }, // Default object
    ...countryCodes.map(({ code, en }) => ({
        name: en,
        value: en,
    })),
];

const SelectCountry = ({ location, history, user }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [updateCountry, setCountryUpdate] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleSubmit = async () => {
        // update interest table with country of the prospective guardian
        const result = await saveUserCountryForGuardianRole({ regionCountry: selectedCountry });
        if (result.status === 'success') {
            setCountryUpdate(true);
        } else {
            setShowError(result.message);
        }
    };
    return (
        <>
            <Page>
                <Header currentPath={location.pathname} user={user} history={history} />
                {updateCountry === false ? (
                    <SelectCountrySection className="mx-auto flex justify-center items-center">
                        <div className="thankyou-box mx-auto text-center relative">
                            {showError && (
                                <div className="error-text small">Country updation failed!</div>
                            )}

                            <div className="title-box text-center">
                                <h2>
                                    <FormattedMessage id="GuardiansPage.SelectCountry.Title" />
                                </h2>
                            </div>

                            <FormattedHTMLMessage
                                id="GuardiansPage.SelectCountry.Desc"
                                values={{
                                    p: chunks => <p>{chunks}</p>,
                                }}
                            />
                            <div className="input-box-section">
                                <label className="small">
                                    <FormattedMessage id="GuardiansPage.SelectCountry.CountryTitle" />
                                </label>

                                <CustomRadioSelect
                                    name="targetCountry"
                                    classname="form-control text-left"
                                    optionsList={transformedCountryCodes}
                                    disabled={false}
                                    value={selectedCountry}
                                    onChange={value => {
                                        setSelectedCountry(value);
                                    }}
                                />
                                <OrangeBtn
                                    className="submit-btn mx-auto w-full"
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    <FormattedMessage id="GuardiansPage.SelectCountry.Submit" />
                                </OrangeBtn>
                            </div>
                        </div>
                    </SelectCountrySection>
                ) : (
                    <ThankyouOutsideSection className="mx-auto flex justify-center items-center">
                        <div className="thankyou-box mx-auto text-center relative">
                            <div className="title-box text-center">
                                <h2>
                                    <FormattedMessage id="GuardiansPage.SelectCountry.Thanks" />
                                </h2>
                            </div>
                            <FormattedHTMLMessage
                                id="GuardiansPage.SelectCountry.ThanksDesc"
                                values={{
                                    p: chunks => <p>{chunks}</p>,
                                }}
                            />
                            <div className="input-box-section">
                                <OrangeBtn className="submit-btn mx-auto w-full">
                                    <Link to="/land-managers">
                                        <FormattedMessage id="GuardiansPage.SelectCountry.Back" />
                                    </Link>
                                </OrangeBtn>
                            </div>
                        </div>
                    </ThankyouOutsideSection>
                )}
                <Footer history={history} />
            </Page>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    // fetchCurrentUser: params => dispatch(fetchCurrentUser()),
});

const mapStateToProps = state => {
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
              email: state.user.currentUser.attributes.email,
              firstName: state.user.currentUser.attributes.profile.firstName,
              lastName: state.user.currentUser.attributes.profile.lastName,
              cartCount: state.user.currentUser.cartCount,
              currentRole: state.user.currentUser.currentRole,
              activatedRoles: state.user.currentUser.activatedRoles,
          }
        : null;

    const { isAuthenticated } = state.Auth;
    const { currentUser } = state.user;
    return {
        user,
        currentUser,
        isAuthenticated,
    };
};

export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(SelectCountry));
