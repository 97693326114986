import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.css';
import Button from '../../newComponents/Button';
import { FormattedMessage } from 'react-intl';

const BlogContributeOptions = props => {

    const handleCalculateClick = () => {
        if(props.user) {
            props.user.currentRole === 'partner' ? props.history.push('/organisation/calculator') : props.history.push('/calculator');
        }
        else {
            props.history.push('/calculator')
        }
    }

    const handleManageClick = () => {
        if(props.user) {
            props.user.currentRole === 'partner' ? props.history.push('/organizations') : props.history.push('/people');
        }
        else {
            props.history.push('/people')
        }
    }

    return (
        <div className={styles.body}>
            <p className={styles.heading}>
                <FormattedMessage id="Blogs.Contribute.heading" />
            </p>
            <div className={styles.buttons}>
                <Button
                    onClick={handleCalculateClick}
                    variant="footprintChoiceCalculate"
                    size="md"
                >
                    <FormattedMessage id="Blogs.Contribute.calculate" />
                </Button>
                <Button
                    onClick={handleManageClick}
                    variant="footprintChoiceManage"
                    size="md"
                >
                    <FormattedMessage id="Blogs.Contribute.manage" />
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(BlogContributeOptions));