import styled from 'styled-components';

export const UploadDocumentMain = styled.div`
    margin: 55px 35px 35px 35px;
    border-radius: 10px;
    background-color: white;
    color: #000000;
    @media screen and (max-width: 1024px) {
        margin: 25px;
    }
`;

export const UploadDocumentTitle = styled.p`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    margin-top: 55px;
    margin-left: 85px;
    @media screen and (max-width: 1024px) {
        margin-top: 25px;
        margin-left: 35px;
    }
`;

export const UploadDocumentMessage = styled.p`
    font-style: normal;
    font-family: 'Galano Grotesque';
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    max-width: 600px;
    margin-top: 20px;
    margin-left: 85px;
    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-left: 35px;
    }
`;

export const DragAndDropDocumentMain = styled.div`
    margin: 25px 65px 75px 65px;
    padding-bottom: 65px;
    border-radius: 16px;
    background-color: ${props => props.bkgColor};
    color: #000000;
    @media screen and (max-width: 1024px) {
        margin: 25px;
    }
`;

export const DragAndDropDocumentIcon = styled.div`
    display:flex;
    justify-content:center;
`;

export const DragAndDropDocumentText = styled.div`
    display:flex;
    justify-content:center;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #000000;
    margin-top: 18px;
    margin-bottom: 18px;
    @media screen and (max-width: 1024px) {
        font-size: 22px;
        line-height: 30px;
    }
`;

export const SpaceBreak = styled.div`
    display:flex;
    justify-content:center;
    background-color: ${props => props.bkgColor};
    margin: ${props => props.margin};
`;

export const DragAndDropDocumentMessage = styled.div`
    text-align:center;
    margin:auto;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    max-width:700px;
    color: #000000;
`;