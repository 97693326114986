import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NamedLink  from '../../../../components/NamedLink/NamedLink';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './TabNav.css';

const Tab = props => {
  const { className, id, disabled, text, selected, linkProps } = props;
  const linkClasses = classNames(
    props.user === 'guardian' ? css.guardianLink : css.link, {
    [props.user === 'guardian' ? css.guardianSelectedLink : css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false, user: 'friends' };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} user={props.user.currentRole} {...tab} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

const mapStateToProps = storeState => ({
  user: storeState.user.currentUser
      ? {
            currentRole: storeState.user.currentUser.currentRole,
            activatedRoles: storeState.user.currentUser.activatedRoles,
        }
      : null,
});

export default compose( withRouter, connect( mapStateToProps ))(TabNav); 

