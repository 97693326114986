import React from 'react';
import { injectIntl } from 'react-intl';
import SpanishFaq from './Faq.es';
import EnglishFaq from './Faq.en';

const FaqsPage = ({ intl }) => {
    const locale = (intl && intl.locale) || 'es';
    return locale === 'en' ? <EnglishFaq /> : <SpanishFaq />;
};

export default injectIntl(FaqsPage);
