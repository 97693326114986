import React from 'react';
import RelatedSlider from 'react-slick';
import styled from 'styled-components';
import BlogPostCard from '../../../../newComponents/Blog/BlogPostCard';
import blogPostImg from '../../../../assets/newAssets/blog-post-img.png';

const RelatedBlogSection = ({ title, relatedBlogs }) => {
    // blog posts data
    const data = relatedBlogs;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick',
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    speed: 300,
                    infinite: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    className: 'center',
                    centerPadding: '30px',
                    arrows: false,
                    dots: false,
                    speed: 300,
                    infinite: true,
                },
            },
        ],
    };

    const RelatedSection = styled.section`
        padding: 80px 20px 90px 20px;
        max-width: 1280px;
        .heading {
            margin-bottom: 25px;
        }
        .regular {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        @media (max-width: ${({ theme }) => theme.media.tab}) {
            padding: 25px 0 35px 20px;
            .heading {
                margin-bottom: 0;
            }
            .slick-slider {
                .slick-list {
                    padding-left: 0 !important;
                    padding-top: 15px !important;
                    padding-bottom: 15px !important;
                }
            }
            .card-container {
                width: auto;
                min-width: 290px;
                padding: 0 12px;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    `;

    return (
        <RelatedSection className="mx-auto">
            <div className="related-slider">
                <h2 className="heading">{title}</h2>
                <RelatedSlider {...settings}>
                    {data.length > 0 &&
                        data.map(item => (
                            <BlogPostCard
                                classname="card-container"
                                image={item.blog_details.featuredImage}
                                category={item.taxonomy.category}
                                landscapes={item.taxonomy.landscape}
                                title={item.title}
                                authorName={item.writer.name}
                                postDate={item.blog_details.publishDate}
                                slug={item.slug}
                            />
                        ))}
                </RelatedSlider>
            </div>
        </RelatedSection>
    );
};

export default RelatedBlogSection;
