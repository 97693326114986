import React, { Component } from 'react';
import { StaticPage } from '../../containers';
import { FormattedMessage } from 'react-intl';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    ProductComparison,
    Hero,
    NamedLink,
    ListingCard,
    PrimaryButton,
    Modal,
    UpButton,
    SecondaryButton,
} from '../../components';

import { FaLink, FaRegPlayCircle } from 'react-icons/fa';

import rufina from '../../assets/Rufina-Rivera.jpg';
import luis from '../../assets/luis.jpg';
import andres from '../../assets/andres.jpg';

import plant from '../../assets/icons/plant.svg';
import network from '../../assets/icons/network.svg';
import payment from '../../assets/icons/payment-method.svg';
import co2 from '../../assets/icons/co2.svg';

import css from './AlliesPage.css';
import heroimage from '../../assets/guardianeshero.jpg';
import logosBusinessPic from '../../assets/logosEmpresas.png';

import CREES from '../../assets/logos-guardianes/CREES.jpg';
import IslaDeLosValles from '../../assets/logos-guardianes/IslaDeLosValles.jpg';
import ccKiunalla from '../../assets/logos-guardianes/ccKiunalla.svg';
import cnNuevaEsperanza from '../../assets/logos-guardianes/cnNuevaEsperanza.svg';
import PalotoaTeparo from '../../assets/logos-guardianes/PalotoaTeparo.jpg';
import comunidadNativaShipetari from '../../assets/logos-guardianes/comunidadNativaShipetari.jpg';
import cnWicungo from '../../assets/logos-guardianes/cnWicungo.svg';
import cedia from '../../assets/logo-aliados/cedia.jpg';
import cedes from '../../assets/logo-aliados/cedes.jpg';
import patronatoNYC from '../../assets/logo-aliados/patronatoNYC.jpg';
import SePeru from '../../assets/logo-aliados/SePeru.jpg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';

class BusinessPage extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, videoModalOpen: false };
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen() {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <StaticPage
                title="Guardianes y aliados"
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'AboutPage',
                    description:
                        'Comunidades, concesionarios o dueños de tierras que protegen y reconstruyen la naturaleza con su trabajo en campo.',
                    name: 'Guardianes y aliados',
                }}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={this.props.location.pathname}
                            user={this.props.user}
                            history={this.props.history}
                        />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain>
                        <Hero
                            title={<FormattedMessage id="Allies.title" />}
                            subtitle={<FormattedMessage id="Allies.subtitle" />}
                            image={heroimage}
                        />
                        <section className={css.sectionContent}>
                            <h2 className={css.title}>
                                <FormattedMessage id="Allies.aboutSection.title" />
                            </h2>
                            <ul className={css.list}>
                                <li className={css.listItem}>
                                    <div className={css.iconWrapperList}>
                                        <img className={css.listIcon} src={plant} />
                                    </div>
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Allies.aboutSection.item1" />
                                    </p>
                                </li>

                                <li className={css.listItem}>
                                    <div className={css.iconWrapperList}>
                                        <img className={css.listIcon} src={network} />
                                    </div>
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Allies.aboutSection.item2" />
                                    </p>
                                </li>
                                <li className={css.listItem}>
                                    <div className={css.iconWrapperList}>
                                        <img className={css.listIcon} src={co2} />
                                    </div>
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Allies.aboutSection.item3" />
                                    </p>
                                </li>
                                <li className={css.listItem}>
                                    <div className={css.iconWrapperList}>
                                        <img className={css.listIcon} src={payment} />
                                    </div>
                                    <p className={css.listItemText}>
                                        <FormattedMessage id="Allies.aboutSection.item4" />
                                    </p>
                                </li>
                            </ul>
                            <div className={css.modalButtonContainer}>
                                <SecondaryButton
                                    className={css.videoButton}
                                    onClick={() => this.setState({ videoModalOpen: true })}
                                >
                                    <span className={css.videoButton_text}>
                                        <FormattedMessage id="Allies.guardianVideo" />{' '}
                                    </span>
                                    <FaRegPlayCircle />
                                </SecondaryButton>
                            </div>
                        </section>

                        <section className={`${css.sectionContent}`}>
                            <h2 className={css.title}>
                                <FormattedMessage id="Allies.organizations.title" />
                            </h2>
                            <div className={css.logosPicWrapper}>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae35e9-031c-4e99-ba02-7cb2df3c3f4e' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={CREES} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae39b9-9f24-40bd-904a-dca71886381d' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={IslaDeLosValles} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae3a20-78a9-45c4-a4f0-692c60c2800b' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={ccKiunalla} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae3af4-7c86-42df-a7c8-b69ac5ea02ee' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={cnNuevaEsperanza} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae4710-0efd-4df4-b390-0afa9626549b' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={PalotoaTeparo} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae4764-abad-402f-a7e2-98ad9fa566e9' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={comunidadNativaShipetari} />
                                    </NamedLink>
                                </div>
                                <div className={`${css.logoSrcContainer}`}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5dae4814-891e-496f-8681-82141fc09d7e' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={cnWicungo} />
                                    </NamedLink>
                                </div>

                                <div className={css.logoSrcContainer}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5da3fb93-cbea-471a-b951-cc50b1baa00f' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={cedia} />
                                    </NamedLink>
                                </div>
                                <div className={css.logoSrcContainer}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5da3fd2e-4f0a-46b5-8dcf-5f69b0bd8afc' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={cedes} />
                                    </NamedLink>
                                </div>
                                <div className={css.logoSrcContainer}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5da3fdd5-ce09-4a44-81bd-562f2e602d53' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={patronatoNYC} />
                                    </NamedLink>
                                </div>
                                <div className={css.logoSrcContainer}>
                                    <NamedLink
                                        className={css.picLinksLogo}
                                        name="ProfilePage"
                                        params={{ slug: '5d9e6559-ba65-4ad5-8700-f17a5aa1343a' }}
                                    >
                                        <FaLink className={css.IconPic} />
                                        <img src={SePeru} />
                                    </NamedLink>
                                </div>
                            </div>
                        </section>

                        <section id="become" className={`${css.sectionContent} ${css.joinWrapper}`}>
                            <h2 className={css.title}>
                                <FormattedMessage id="Allies.howJoint.title" />
                            </h2>
                            <div className={css.jointext}>
                                <p>
                                    <FormattedMessage id="Allies.howJoint.text1" />
                                </p>
                                <p>
                                    <FormattedMessage id="Allies.howJoint.text2" />
                                </p>
                            </div>
                            <div className={css.buttonWrapper}>
                                <PrimaryButton className={css.buttonForm} onClick={this.handleOpen}>
                                    <FormattedMessage id="Allies.howJoint.contact" />
                                </PrimaryButton>
                            </div>
                            <Modal
                                {...this.props}
                                isOpen={this.state.isOpen}
                                onClose={() => {
                                    this.setState({ isOpen: false });
                                    console.log('Closing modal');
                                }}
                                onManageDisableScrolling={() => {}}
                            >
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSeCCj9WBl3nUsNgE1KtuQyFCymRIVgyCq6pIXu7zcyO3O1onA/viewform?embedded=true"
                                    width="640"
                                    height="1132"
                                    frameborder="0"
                                    marginheight="0"
                                    marginwidth="0"
                                >
                                    Cargando…
                                </iframe>
                            </Modal>
                        </section>

                        <section id="testimonials" className={css.testimony}>
                            <div className={css.sectionContent}>
                                <h2 className={css.title}>
                                    <FormattedMessage id="Testimonials.title.guardians" />
                                </h2>
                                <div className={css.testimonyList}>
                                    <div>
                                        <img className={css.testimonyPic} src={rufina} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.rufina.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.rufina.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                - Jefa -{' '}
                                                <FormattedMessage id="Testimonials.rufina.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <img className={css.testimonyPic} src={luis} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.luis.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.luis.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                - <FormattedMessage id="Testimonials.luis.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <img className={css.testimonyPic} src={andres} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.andres.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.andres.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                - <FormattedMessage id="Testimonials.luis.title" />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <UpButton />
                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer history={this.props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
                <Modal
                    lightCloseButton={true}
                    containerClassName={css.modalVideo}
                    isOpen={this.state.videoModalOpen}
                    onClose={() => this.setState({ videoModalOpen: false })}
                    onManageDisableScrolling={() => {}}
                >
                    {this.state.videoModalOpen ? (
                        <React.Fragment>
                            <iframe
                                className={css.video}
                                src={`https://player.vimeo.com/video/262300183?autoplay=1&color=2DC000&title=0&byline=0&portrait=0`}
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            ></iframe>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </React.Fragment>
                    ) : null}
                </Modal>
            </StaticPage>
        );
    }
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(BusinessPage));
