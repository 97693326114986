import styled from 'styled-components';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';

export const ImpactMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3D6;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;
export const Title = styled.h1`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 41px;
    margin-left: 42px;
    @media screen and (max-width: 568px) {
        width: 70%;
        margin-right: 0px;
        margin-left: 20px;
    }
`;
export const CardTitle = styled.h2`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    margin-left: ${props => (props.chart ? '120px' : '42px')};
    width: fit-content;
    color: ${props => (props.chart ? '#ffffff' : '#000000')};
    @media screen and (max-width: 568px) {
        width: 70%;
        margin-right: 0px;
        margin-left: ${props => (props.chart ? '40px' : '20px')};
    }
`;
export const Wrapper = styled.div`
    width: 97%;
    height: auto;
    @media screen and (max-width: 568px) {
        padding: 0px;
        width: 90%;
    }
`;
export const Chart = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    & > p {
        float: right;
    }
`;
export const Disclaimer = styled.p`
    font-size: 12px;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    line-height: normal;
    color: #666666;
    width: fit-content;
`;
export const CardChart = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
`;
export const Tbody = styled.div`
    display: flex;
    padding: 3% 15% 2% 10%;
    flex-direction: column;
    background: #2b2b2b;
`;
export const Input = styled.input`
    width: 16px;
    height: 16px;
    float: left;
    color: #7bb548;
`;
export const SubTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 20px;
    float: right;
    width: 200px;
    margin-left: 10px;
    margin-top: -5px;
    @media screen and (max-width: 568px) {
        margin-bottom: 0px;
    }
`;
export const Text = styled.p`
    color: ${props => (props.red ? '#FF0505' : '#7BB548')};
    width: 140px;
    font-size: 30px;
    font-weight: 700;
    margin-left: 18px;
    line-height: 30px;
    font-family: 'Helvetica';
    margin-right: 10px;
    word-break: break-all;
`;
export const Data = styled.div`
    display: flex;
    justify-content: center;
    .smallThumbprint {
        color: green;
        height: 80px;
        width: 70px;
        float: left;
    }
    @media screen and (max-width: 568px) {
        align-items: center;
        justify-content: space-between;
        height: 130px;
        .smallThumbprint {
            height: 55%;
            width: 20%;
            margin-right: 20px;
        }
         ${Text} { 
            width:80%;
        }
    }
`;
export const Body = styled.div`
    width: 100%;
    display: flex;
    height: auto;
    justify-content: end;
    margin-bottom: 50px;
    @media screen and (max-width: 568px) {
        flex-direction: column;
        height: auto;
        justify-content: center;
    }
`;
export const InputBox = styled.div`
    width: fit-content;
    margin: 0px auto;
    color: #7bb548;
`;
export const ButtonContainer = styled.div`
    box-sizing: border-box;
    width: fit-content;
    margin: auto;
    & > a > button {
        padding-left: 30px;
        padding-right: 30px;
        width: 300px;
    }
    @media screen and (max-width: 768px) {
        width: 55%;
        margin-left: 10%;
        & > a > button {
            padding-left: 30px;
            padding-right: 30px;
            width: 260px;
        }
    }
`;
export const NoData = styled.div`
    margin: 30px auto;
    width: 92%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
`;
export const GrayText = styled.div`
    padding-top: 20px;
    color: #5a5a5a;
    margin: auto;
    width: 70%;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: ${props => (props.empty ? '24px' : '19px')};
    line-height: 26px;
    text-align: center;
`;
