import styled from 'styled-components';

export const GuardianNetworkSection = styled.div`
    max-width: 570px;
    img {
        margin-bottom: 70px;
    }
    .green-link {
        line-height: 28px;
        margin-top: 8px;
        display: inline-block;
    }

    @media (max-width: 1138px) {
        .guardian-network {
            max-width: fit-content;
            img {
                margin-bottom: 20px;
            }
            h3,
            a {
                padding: 0 20px;
                br {
                    display: none;
                }
            }
            a {
                font-size: 16px;
                margin-top: 5px;
            }
        }
    }
`;
