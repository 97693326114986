export const STRIPE_SUPPORTED_TAX_COUNTRIES = [
    {
        country: 'Australia',
        taxIdTypes: [
            {
                id: 'au_abn',
                defaultTaxId: '12345678912',
            },
            {
                id: 'au_arn',
                defaultTaxId: '123456789123',
            },
        ],
    },
    {
        country: 'Austria',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'ATU12345678',
            },
        ],
    },
    {
        country: 'Belgium',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'BE0123456789',
            },
        ],
    },
    {
        country: 'Brazil',
        taxIdTypes: [
            {
                id: 'br_cnpj',
                defaultTaxId: '01.234.456/5432-10',
            },
            {
                id: 'br_cpf',
                defaultTaxId: '123.456.789-87',
            },
        ],
    },
    {
        country: 'Bulgaria',
        taxIdTypes: [
            {
                id: 'bg_uic',
                defaultTaxId: '123456789',
            },
            {
                id: 'eu_vat',
                defaultTaxId: 'BG0123456789',
            },
        ],
    },
    {
        country: 'Canada',
        taxIdTypes: [
            {
                id: 'ca_bn',
                defaultTaxId: '123456789',
            },
            {
                id: 'ca_gst_hst',
                defaultTaxId: '123456789RT0002',
            },
            {
                id: 'ca_pst_bc',
                defaultTaxId: 'PST-1234-5678',
            },
            {
                id: 'ca_pst_mb',
                defaultTaxId: '123456-7',
            },
            {
                id: 'ca_pst_sk',
                defaultTaxId: '1234567',
            },
            {
                id: 'ca_qst',
                defaultTaxId: '1234567890TQ1234',
            },
        ],
    },
    {
        country: 'Chile',
        taxIdTypes: [
            {
                id: 'cl_tin',
                defaultTaxId: '12.345.678-K',
            },
        ],
    },
    {
        country: 'Croatia',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'HR12345678912',
            },
        ],
    },
    {
        country: 'Cyprus',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'CY12345678Z',
            },
        ],
    },
    {
        country: 'Czech Republic',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'CZ1234567890',
            },
        ],
    },
    {
        country: 'Denmark',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'DK12345678',
            },
        ],
    },

    {
        country: 'Egypt',
        taxIdTypes: [
            {
                id: 'eg_tin',
                defaultTaxId: '123456789',
            },
        ],
    },
    {
        country: 'Estonia',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'EE123456789',
            },
        ],
    },
    {
        country: 'EU',
        taxIdTypes: [
            {
                id: 'eu_oss_vat',
                defaultTaxId: 'EU123456789',
            },
        ],
    },
    {
        country: 'Finland',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'FI12345678',
            },
        ],
    },
    {
        country: 'France',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'FRAB123456789',
            },
        ],
    },
    {
        country: 'Georgia',
        taxIdTypes: [
            {
                id: 'ge_vat',
                defaultTaxId: '123456789',
            },
        ],
    },
    {
        country: 'Germany',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'DE123456789',
            },
        ],
    },
    {
        country: 'Greece',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'EL123456789',
            },
        ],
    },

    {
        country: 'Hong Kong',
        taxIdTypes: [
            {
                id: 'hk_br',
                defaultTaxId: '12345678',
            },
        ],
    },
    {
        country: 'Hungary',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'HU12345678912',
            },
            {
                id: 'hu_tin',
                defaultTaxId: '12345678-1-23',
            },
        ],
    },
    {
        country: 'Iceland',
        taxIdTypes: [
            {
                id: 'is_vat',
                defaultTaxId: '123456',
            },
        ],
    },
    {
        country: 'India',
        taxIdTypes: [
            {
                id: 'in_gst',
                defaultTaxId: '12ABCDE3456FGZH',
            },
        ],
    },
    {
        country: 'Indonesia',
        taxIdTypes: [
            {
                id: 'id_npwp',
                defaultTaxId: '12.345.678.9-012.345',
            },
        ],
    },
    {
        country: 'Ireland',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'IE1234567AB',
            },
        ],
    },
    {
        country: 'Israel',
        taxIdTypes: [
            {
                id: 'il_vat',
                defaultTaxId: '000012345',
            },
        ],
    },
    {
        country: 'Italy',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'IT12345678912',
            },
        ],
    },
    {
        country: 'Japan',
        taxIdTypes: [
            {
                id: 'jp_cn',
                defaultTaxId: '1234567891234',
            },
            {
                id: 'jp_rn',
                defaultTaxId: '12345',
            },
            {
                id: 'jp_trn',
                defaultTaxId: 'T1234567891234',
            },
        ],
    },
    {
        country: 'Kenya',
        taxIdTypes: [
            {
                id: 'ke_pin',
                defaultTaxId: 'P000111111A',
            },
        ],
    },
    {
        country: 'Latvia',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'LV12345678912',
            },
        ],
    },

    {
        country: 'Liechtenstein',
        taxIdTypes: [
            {
                id: 'li_uid',
                defaultTaxId: 'CHE123456789',
            },
        ],
    },
    {
        country: 'Lithuania',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'LT123456789123',
            },
        ],
    },
    {
        country: 'Luxembourg',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'LU12345678',
            },
        ],
    },
    {
        country: 'Malaysia',
        taxIdTypes: [
            {
                id: 'my_frp',
                defaultTaxId: '12345678',
            },
            {
                id: 'my_itn',
                defaultTaxId: 'C 1234567890',
            },
            {
                id: 'my_sst',
                defaultTaxId: 'A12-3456-78912345',
            },
        ],
    },
    {
        country: 'Malta',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'MT12345678',
            },
        ],
    },
    {
        country: 'Mexico',
        taxIdTypes: [
            {
                id: 'mx_rfc',
                defaultTaxId: 'ABC010203AB9',
            },
        ],
    },
    {
        country: 'Netherlands',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'NL123456789B12',
            },
        ],
    },
    {
        country: 'New Zealand',
        taxIdTypes: [
            {
                id: 'nz_gst',
                defaultTaxId: '123456789',
            },
        ],
    },
    {
        country: 'Norway',
        taxIdTypes: [
            {
                id: 'no_vat',
                defaultTaxId: '123456789MVA',
            },
        ],
    },
  {
    country: 'Peru',
    taxIdTypes: [
      {
        id: "pe_ruc",
        defaultTaxId: "12345678901"
      },
    ]
  },
    {
        country: 'Philippines',
        taxIdTypes: [
            {
                id: 'ph_tin',
                defaultTaxId: '123456789012',
            },
        ],
    },
    {
        country: 'Poland',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'PL1234567890',
            },
        ],
    },
    {
        country: 'Portugal',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'PT123456789',
            },
        ],
    },
    {
        country: 'Romania',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'RO1234567891',
            },
        ],
    },
    {
        country: 'Russia',
        taxIdTypes: [
            {
                id: 'ru_inn',
                defaultTaxId: '1234567891',
            },
            {
                id: 'ru_kpp',
                defaultTaxId: '123456789',
            },
        ],
    },
    {
        country: 'Saudi Arabia',
        taxIdTypes: [
            {
                id: 'sa_vat',
                defaultTaxId: '123456789012345',
            },
        ],
    },
    {
        country: 'Singapore',
        taxIdTypes: [
            {
                id: 'sg_gst',
                defaultTaxId: 'M12345678X',
            },
            {
                id: 'sg_uen',
                defaultTaxId: '123456789F',
            },
        ],
    },
    {
        country: 'Slovakia',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'SK1234567891',
            },
        ],
    },
    {
        country: 'Slovenia',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'SI12345678',
            },
            {
                id: 'si_tin',
                defaultTaxId: '12345678',
            },
        ],
    },
    {
        country: 'South Africa',
        taxIdTypes: [
            {
                id: 'za_vat',
                defaultTaxId: '4123456789',
            },
        ],
    },
    {
        country: 'South Korea',
        taxIdTypes: [
            {
                id: 'kr_brn',
                defaultTaxId: '123-45-67890',
            },
        ],
    },
    {
        country: 'Spain',
        taxIdTypes: [
            {
                id: 'es_cif',
                defaultTaxId: 'A12345678',
            },
            {
                id: 'eu_vat',
                defaultTaxId: 'ESA1234567Z',
            },
        ],
    },
    {
        country: 'Sweden',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'SE123456789123',
            },
        ],
    },
    {
        country: 'Switzerland',
        taxIdTypes: [
            {
                id: 'ch_vat',
                defaultTaxId: 'CHE-123.456.789 MWST',
            },
        ],
    },
    {
        country: 'Taiwan',
        taxIdTypes: [
            {
                id: 'tw_vat',
                defaultTaxId: '12345678',
            },
        ],
    },
    {
        country: 'Thailand',
        taxIdTypes: [
            {
                id: 'th_vat',
                defaultTaxId: '1234567891234',
            },
        ],
    },
    {
        country: 'Turkey',
        taxIdTypes: [
            {
                id: 'tr_tin',
                defaultTaxId: '0123456789',
            },
        ],
    },
    {
        country: 'Ukraine',
        taxIdTypes: [
            {
                id: 'ua_vat',
                defaultTaxId: '123456789',
            },
        ],
    },
    {
        country: 'United Arab Emirates',
        taxIdTypes: [
            {
                id: 'ae_trn',
                defaultTaxId: '123456789012345',
            },
        ],
    },
    {
        country: 'United Kingdom',
        taxIdTypes: [
            {
                id: 'eu_vat',
                defaultTaxId: 'XI123456789',
            },
            {
                id: 'gb_vat',
                defaultTaxId: 'GB123456789',
            },
        ],
    },
    {
        country: 'United States',
        taxIdTypes: [
            {
                id: 'us_ein',
                defaultTaxId: '12-3456789',
            },
        ],
    },
];
