import React, { useState, useRef, useEffect } from 'react';
import {
  ItemContainer,
  Dropdown,
  DropdownItem,
  Input,
  Holder,
  Row,
  LabelHead,
  InputButton,
  ButtonContainer,
  AddfuelContainer,
} from './Styles.js';
import Button from '../../../../../newComponents/Button';
import ActivityItem from './activityItem.js';
import { v4 as uuid } from 'uuid';
import { isNil, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
export const ActivityList = props => {
  const generateItem = () => {
    return {
      id: uuid(),
      fuelType: '',
      value: 0,
      unit: '',
      activityId: '',
    };
  };

  const [userData, setUserData] = useState([generateItem()]);
  useEffect(() => {
    const activities = [];
    if (
      !isNil(props.emissionActivitiesData[props.scope]) ||
      !isEmpty(props.emissionActivitiesData[props.scope])
    ) {
      props.emissionActivitiesData[props.scope].map(data => {
        if (data.groupName === props.type) {
          activities.push({
            id: uuid(),
            fuelType: data.activityName,
            value: data.value,
            unit: data.unit,
            activityId: data.id,
          });
        }
      });
      if (activities.length > 0) {
        props.emissionActivities(activities, props.type);
        setUserData(activities);
      } else {
        setUserData([generateItem()]);
      }
    }
  }, []);
  const deleteData = id => {
    const index = userData.findIndex(data => data.id === id);
    if (index != -1) {
      userData.splice(index, 1);
    }
    props.emissionActivities(userData, props.type);
    setUserData([...userData]);
  };
  const saveData = inputData => {
    const index = userData.findIndex(data => data.id === inputData.id);
    if (index != -1) {
      userData[index] = inputData;
    }
    const data = userData.slice();
    setUserData(data);
    props.emissionActivities(data, props.type);
  };
  const handleIncrement = () => {
    const length = userData.length;
    if (
      !isEmpty(userData) &&
      userData[length - 1].fuelType != '' &&
      userData[length - 1].unit != '' &&
      userData[length - 1].value >= 0
    ) {
      const data = userData.slice();
      data.push(generateItem());
      setUserData(data);
    }
    if (userData.length == 0) {
      const data = userData.slice();
      data.push(generateItem());
      setUserData(data);
    }
  };
  let id = 0;
  return (
    <ItemContainer>
      <Row>
        <Holder>
          <LabelHead>Fuel Type</LabelHead>
        </Holder>
        <Holder>
          <LabelHead>Value</LabelHead>
        </Holder>
        <Holder>
          <LabelHead>Unit</LabelHead>
        </Holder>
        <Holder>
          <LabelHead></LabelHead>
        </Holder>
      </Row>
      {userData.map((data, i) => {
        return (
          <ActivityItem
            key={data.id}
            type={props.type}
            scope={props.scope}
            data={data}
            emissionActivityDetails={props.emissionActivityDetails}
            units={props.units}
            saveData={saveData}
            deleteData={deleteData}
          />
        );
      })}

      <AddfuelContainer>
        <Button onClick={handleIncrement}>
          <FormattedMessage id="OrganisationCalculator.Button.FuelType" />
        </Button>
      </AddfuelContainer>
    </ItemContainer>
  );
};

export default ActivityList;
