import styled from 'styled-components';
export const Benefits = styled.section`
    background: rgba(221, 237, 208, 0.16);
    padding: 125px 4.8rem 93px;
    text-align: left;
    .two-cols {
        width: 100%;
        max-width: 1200px;
        flex: 1;
        gap: 120px;
        .Benefits-info {
            width: 100%;
            max-width: 522px;
            gap: 28px;
            .title-box {
                position: relative;
                img {
                    position: absolute;
                    left: 0;
                    top: 37px;
                }
                .description {
                    line-height: 180%;
                }
            }
            .list-of-benefits {
                gap: 28px;
                ul {
                    gap: 8px;
                    li {
                        position: relative;
                        padding-left: 29px;
                        line-height: 180%;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 19px;
                            height: 23px;
                            left: 0;
                            top: 3px;
                            background-image: url(${({ listLeaf }) => listLeaf});
                        }
                    }
                }
                .light-btn {
                    max-width: 400px;
                    padding: 0 35px;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px 20px 40px;
        .two-cols {
            flex-wrap: wrap;
            gap: 34px;
            .Benefits-info {
                max-width: 100%;
                .title-box {
                    h3 {
                        font-size: 28px;
                        line-height: 38px;
                    }
                }
                .list-of-benefits {
                    li {
                        font-size: 16px;
                    }
                }
            }
            .img-box {
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
        }
    }
`;
