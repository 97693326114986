import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '../assets/newAssets/checkbox.svg';

const CheckboxSection = styled.div`
    .form-check {
        gap: 12px;
        text-align: left;
        align-items: flex-start;

        input[type='checkbox'] {
            border-radius: 0.25em;
            width: 25px;
            height: 18px;
            position: relative;

            border: 1px solid #ccc;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &:checked {
                background-image: url(${CheckIcon});
                background-position: center center; /* Center the background image */
                border-color: var(--Trust-Green, #004d37);
            }
        }
    }
`;

const CheckBoxInfo = ({ labelName, labelId, checkboxSection, onChange, isChecked }) => {
    const handleCheckboxChange = () => {
        onChange(labelId, !isChecked);
    };

    return (
        <CheckboxSection className={`${checkboxSection}`}>
            <div className="form-check flex" onClick={evt => evt.stopPropagation()}>
                <input
                    type="checkbox"
                    id={labelId}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={labelId}>
                    {labelName}
                </label>
            </div>
        </CheckboxSection>
    );
};

export default CheckBoxInfo;
