import styled from 'styled-components';
import {colors} from '../../theme';
import { space } from 'styled-system';
import { ReactComponent as CheckIcon } from '../../assets/newAssets/filledCheckbox.svg';

export const StyledInput = styled.input`
  font-size: 22px;
  padding: 18px 27px;
  border-radius: 8px;
  border: 1px solid #B0B0B0;
  background: ${colors.white};
  ${({ status }) => status === 'failure' && `border: 3px solid ${colors.pink};`}
`;

export const InputContainer = styled.div`
  position: relative;
  ${space}
  @media screen and (max-width: 450px) {
    width:80%;
  }
  & > input:focus, input:hover {
    border-bottom-color: ${colors.white} !important;
  }
  ${({ status }) =>
    status === 'failure' &&
    `
  & > input:focus, input:hover {
    border-bottom-color: ${colors.pink} !important;
}
  `}
  & > input::placeholder {
    color: #797979;
  }
`;

export const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin-top: 4px;
  cursor: pointer;
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  display: block;
  background-color: black;
  position: absolute;
  cursor: pointer;
  top: 4px;
  border: 5px solid white;
  outline: 1px solid #BCBCBC;
  border-radius: 4px;
  ${({ checked }) => !checked && `display: none;`}
`;

export const Message = styled.p`
  font-size: 17px;
  font-weight: 500;
  font-family: 'Galano Grotesque';
  color: black;
  margin: 0px;
  line-height: 124%;
`;