import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import { LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';
import blogTitleImage from '../../../../assets/newAssets/icons/blog-title-line-d.svg';
import blogTitleImageMobile from '../../../../assets/newAssets/icons/blog-title-line-m.svg';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';

const HeroContainer = styled.section`
    min-height: 370px;
    background-image: url(${({ bgGreenTexture }) => bgGreenTexture});
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        min-height: 300px;
        margin-top: 60px;
    }
`;
const Center = styled.div`
    max-width: 925px;
    align-self: center;
    top: -18px;
    margin: 26px 20px;
    .title-box {
        h1 {
            font-size: 48px;
            line-height: 58px;
            color: white;
            position: relative;
            .border {
                img {
                    position: absolute;
                    bottom: -8px;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }
        .subtitle {
            color: white;
            margin-top: 20px;
            max-width: 522px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        top: 0;
        margin-top: 0;
        .title-box {
            h1 {
                font-size: 42px;
                line-height: 52px;
                .border {
                    display: block;
                    img {
                        width: auto;
                    }
                }
            }
        }
    }
`;
const SearchBox = styled.div`
    max-width: 630px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
    .form-control {
        width: 100%;
        font-size: 1rem;
        border-radius: 25px;
        padding: 8px 26px;
        height: 45px;
        &::placeholder {
            color: #004d37;
        }
        border: 1px solid #eaeaea;
    }
    .search-btn {
        right: 0;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        bottom: -20px;
        width: auto;
        left: 20px;
        right: 20px;
        transform: none;
    }
`;

const BlogSearchSection = ({ title, subtitle, history, intl }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleClickSearch = event => {
        event.preventDefault(); // Prevent the default form submission behavior
        if (searchQuery.length > 0) {
            history.push(`/blog/search/${searchQuery}`);
            //window.location.reload(true);
        }
    };
    const handleChange = e => {
        setSearchQuery(e.target.value);
    };
    const searchPlaceholder = intl.formatMessage({
        id: 'Blogs.Search.placeholder',
    });

    return (
        <HeroContainer className="flex justify-center relative" bgGreenTexture={bgGreenTexture}>
            <Center className="mx-auto relative">
                <div className="title-box text-center">
                    <h1>
                        <span className="border">
                            {title}
                            <picture>
                                <source media="(max-width:768px)" srcset={blogTitleImageMobile} />
                                <img src={blogTitleImage} className="w-auto" alt="" />
                            </picture>
                        </span>
                    </h1>
                    <div className="subtitle mx-auto">{subtitle}</div>
                </div>
            </Center>
            <SearchBox
                as="form"
                className="mx-auto w-full flex absolute"
                onSubmit={handleClickSearch}
            >
                <input
                    type="text"
                    className="form-control w-full"
                    placeholder={searchPlaceholder}
                    aria-label="blog search"
                    aria-describedby="blog search"
                    value={searchQuery}
                    onChange={handleChange}
                />
                <LightGreenBtn type="submit" className="search-btn absolute">
                    <FormattedMessage id="Blogs.Search.button" />
                </LightGreenBtn>
            </SearchBox>
        </HeroContainer>
    );
};

export default injectIntl(withRouter(BlogSearchSection));
