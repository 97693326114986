/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalLink, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import css from './TopbarMobileMenu.css';



const homeLink = (
  <NamedLink name="LandingPage" className={css.staticLinks}>
    <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.home" />
    </span>
  </NamedLink>
)

const people = (
  <NamedLink name="Personas" className={css.staticLinks}>
    <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.people" />
    </span>
  </NamedLink>
)

const business = (
  <NamedLink name="BusinessPage" className={css.staticLinks}>
    <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.bussiness" />
    </span>
  </NamedLink>
)

const guardianesAliados = (
  <NamedLink name="AlliesPage" className={css.staticLinks}>
    <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.allies" />
    </span>
  </NamedLink>
)

const blog = (
  <ExternalLink className={css.staticLinks} href="https://medium.com/regenera">
    <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.blog" />
    </span>
  </ExternalLink>
)

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.staticLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.staticLinks}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    return (
      <div className={css.root}>
        {homeLink}
        {people}
        {business}
        {guardianesAliados}
        <hr/>
        {signup}
        {login}
        {
          /*
            <div className={css.footer}>
              <NamedLink className={css.createNewListingLink} name="NewListingPage">
                <FormattedMessage id="TopbarMobileMenu.newListingLink" />
              </NamedLink>
            </div>
           */
        }
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {homeLink}
      {people}
      {business}
      {guardianesAliados}
      {blog}
      <hr/>
      <p className={css.accountSeparator}>Tu cuenta</p>
      {
     }
      <div className={css.content}>
        <NamedLink
            className={classNames(css.staticLinks, currentPageClass('YourListingsPage'))}
            name="YourListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>

        <NamedLink
          className={classNames(css.staticLinks, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
      {
      }
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
