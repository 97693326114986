import React from 'react';
import PropTypes from 'prop-types';
import {StyledContainer, StyledHeading, StyledParagraph, StyledText, StyledDataContainer} from './Styles'

const stringHighlight = (text, highlightedPart) => {
  const startIndex = text.toLowerCase().indexOf(highlightedPart.toLowerCase());
  if (startIndex === -1) return text;

  return (
    <>
      {text.substring(0, startIndex)}
      <StyledText>{highlightedPart}</StyledText>
      {text.substring(startIndex + highlightedPart.length)}
    </>
  );
};

const SectionInfo = ({ align, bold, color, data, heading, textSize }) => {
  return (
    <StyledContainer color={color} align={align}>
      <StyledHeading m={[0]} textSize={textSize} fontSize={textSize === 'md' ? [2] : [3]}>
        {heading}
      </StyledHeading>
      {Array.isArray(data) ? (
        <StyledDataContainer data={data} m={0} mt={3}>
          {data.map((item, index) => (
            <StyledParagraph data={data} bold={bold} fontSize={1} key={index}>
              {item.highlightedPart ? stringHighlight(item.text, item.highlightedPart) : item.text}
            </StyledParagraph>
          ))}
        </StyledDataContainer>
      ) : (
        <StyledParagraph bold={bold} m={0} mt={3} fontSize={1}>
          {data}
        </StyledParagraph>
      )}
    </StyledContainer>
  );
};

SectionInfo.propTypes = {
  /**
   * align center or left
   */
  align: PropTypes.oneOf(['center', 'left']).isRequired,
  /**
   * is data bold
   */
  bold: PropTypes.bool.isRequired,
  /**
   * color for data
   */
  color: PropTypes.oneOf(['gray', 'black', 'white']).isRequired,
  /**
    data array or string
  **/
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
    Heading
  **/
  heading: PropTypes.string.isRequired,
  /**
   * text size
   */
  textSize: PropTypes.oneOf(['md', 'lg']).isRequired,
};

SectionInfo.defaultProps = {
  align: 'center',
  bold: false,
  color: 'black',
  data: '',
  heading: '',
  textSize: 'md',
};

export default SectionInfo;
