import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import founderImage from '../../../../assets/newAssets/founder.png';
import founderImageMobile from '../../../../assets/newAssets/founder-m.png';
import borderImg from '../../../../assets/newAssets/title-border-small.png';
import heroLeaf from '../../../../assets/newAssets/hero-leaf.png';
import { FounderContainer, ImageText } from './Styles';

const FounderBox = () => {
    return (
        <FounderContainer heroLeaf={heroLeaf}>
            <ImageText className="autor-section">
                <div className="flex items-center justify-space-between container img-text-container">
                    <picture className="img-box imgBoxClass">
                        <source media="(max-width:768px)" srcset={founderImageMobile} />
                        <img src={founderImage} className="w-full" alt="" />
                    </picture>
                    <div className="content mx-auto">
                        <div className="title-box">
                            <div className="small-title">
                                <FormattedMessage id="About.founder.label" />
                            </div>
                            <h3>
                                <FormattedHTMLMessage
                                    id="About.founder.title"
                                    values={{
                                        img: `${borderImg}`,
                                    }}
                                />
                            </h3>
                        </div>
                        <div className="content-box">
                            <FormattedHTMLMessage
                                id="About.founder.message"
                                values={{
                                    p: chunks => <p>{chunks}</p>,
                                }}
                            />

                            <div className="sign-text">Frank Hajek CEO Regenera</div>
                        </div>
                    </div>
                </div>
            </ImageText>
        </FounderContainer>
    );
};

export default FounderBox;
