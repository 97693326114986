import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../theme';

export const HeadingContainer=styled.div`
    text-align:center;
    margin:60px 0px;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #242424;
    @media screen and (max-width: 800px){
        width:100%;
        text-align:initial;
        padding:0px 30px
    }
`;

export const SubHeadingContainer=styled.div`
    width:52%;
    margin:auto;
    text-align:center;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: #242424;
    @media screen and (max-width: 800px){
        width:100%;
        text-align:initial;
        padding:0px 30px
    }
`;