import React from 'react';
import styles from './styles.module.css';
import SelectSearch from '../../../newComponents/SelectSearch';
import { getAllCategories } from '../utils';
import { useEffect, useState, useRef } from 'react';
import Header from '../../../newPages/NewLandingPage/Sections/Header/Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../../newComponents/Button';
import parse from 'html-react-parser';
import { getBlogBySlug, getAllBlogsByCategory, handleQueryLanguage } from '../utils';
import Footer from '../../../newPages/NewLandingPage/Sections/Footer/Footer';
import { IconSpinner } from '../../../components';
import AliceCarousel from 'react-alice-carousel';
import BlogCard from '../../../newComponents/BlogCard';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import BlogTag from '../../../newComponents/BlogTag';
import BlogNewsletterForm from '../../../newComponents/BlogNewsletterForm';
import NewFooter from '../../../newComponents/NewFooter';
import BlogContributeOptions from '../../../newComponents/BlogContributeOptions';
import ShareBlog from '../../../newComponents/ShareBlog';
import { FormattedMessage } from 'react-intl';
import { changeLanguage } from '../../../ducks/Languages.duck';

const BlogDetailPage = props => {
    const [categories, setCategories] = useState('');
    const [display, setDisplay] = useState(false);
    const [blogDetails, setBlogDetails] = useState('');
    const [relatedBlogs, setRelatedBlogs] = useState('');
    let CarouselListings = useRef(null);

    const newsSlug = props.params.newsSlug;

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            const categoriesResponse = await getAllCategories(queryLang);
            if (categoriesResponse.status === 'success') {
                setCategories(categoriesResponse.data);
            }
            const blogResponse = await getBlogBySlug(newsSlug);
            if (blogResponse.status === 'success') {
                console.log(blogResponse.data.locale.id.split('_')[0], props.language);
                if (props.initLanguage)
                    if (
                        props.language !== blogResponse.data.locale.id.split('_')[0].toUpperCase()
                    ) {
                        const searchIndex = blogResponse.data.translations.findIndex(
                            lang => lang.locale.split('_')[0].toUpperCase() === props.language
                        );
                        console.log(searchIndex);
                        setBlogDetails(blogResponse.data.translated[searchIndex]);
                        props.history.push(
                            `/blog/${blogResponse.data.translated[searchIndex].slug}`,
                            {
                                shallow: true,
                            }
                        );
                    } else setBlogDetails(blogResponse.data);
                else {
                    console.log('initlang', props.initLanguage);
                    setBlogDetails(blogResponse.data);
                    props.changeLanguage(blogResponse.data.locale.id.split('_')[0].toUpperCase());
                }
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const queryLang = await handleQueryLanguage(props.language);
            if (blogDetails) {
                const relatedBlogs = await getAllBlogsByCategory(
                    queryLang,
                    blogDetails.taxonomy.category.name
                );
                if (relatedBlogs.status === 'success') {
                    setRelatedBlogs(relatedBlogs.data);
                }
            }
        })();
    }, [blogDetails]);

    const allRelatedBlogs =
        relatedBlogs &&
        relatedBlogs.map(element => {
            return (
                <BlogCard
                    fullWidth={true}
                    key={uuidv4()}
                    imageUrl={element.blog_details.featuredImage}
                    title={element.title}
                    summary={element.blog_details.summary}
                    writer={element.writer.name}
                    publishDate={element.blog_details.publishDate}
                    readTime={element.blog_details.readTime}
                    tags={element.taxonomy.tags}
                    slug={element.slug}
                />
            );
        });

    const handleClickShare = () => {
        setDisplay(true);
        navigator.clipboard.writeText(window.location.href);
    };

    const handleHideShare = () => {
        setDisplay(false);
    };

    return (
        <>
            <div style={{ display: display ? 'block' : 'none' }}>
                <div className={styles.component} onClick={handleHideShare} />
                <ShareBlog blogDetails={blogDetails} />
            </div>
            <div className={styles.blogDetailPage}>
                <Header
                    currentPath={props.location.pathname}
                    user={props.user}
                    history={props.history}
                />
                {categories ? <SelectSearch categories={categories} /> : null}
                {blogDetails ? (
                    <>
                        <div className={styles.titleSection}>
                            <div className={styles.metaSection}>
                                <h1 className={styles.blogTitle}>{blogDetails.title}</h1>
                                <div className={styles.meta}>
                                    <span className={styles.readTime}>
                                        {blogDetails.blog_details.publishDate}
                                    </span>
                                    <span className={styles.readTime}>
                                        {blogDetails.blog_details.readTime}min read
                                    </span>
                                    <div className={styles.tags}>
                                        {blogDetails.taxonomy.tags
                                            ? blogDetails.taxonomy.tags
                                                  .slice(0, 3)
                                                  .map(element => (
                                                      <BlogTag
                                                          key={element.id}
                                                          tagName={element.name}
                                                          cardType="Blogcard"
                                                      />
                                                  ))
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.shareButton}>
                                <Button onClick={handleClickShare} variant={'filled'} size={'md'}>
                                    <FormattedMessage id="Blogs.ShareBlog.button" />
                                </Button>
                            </div>
                        </div>

                        <div className={styles.featuredImage}>
                            <img
                                src={blogDetails.blog_details.featuredImage}
                                alt="featured image"
                            />
                        </div>

                        <div className={styles.blogContent}>
                            <div className={styles.authorSection}>
                                <img src={blogDetails.writer.photo} alt="author" />
                                <p>STORY BY- {blogDetails.writer.name}</p>
                            </div>
                            <div className={styles.blogContentHtml}>
                                {parse(blogDetails.content || '')}
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconSpinner />
                    </div>
                )}
                {relatedBlogs && relatedBlogs.length > 0 ? (
                    <div className={styles.relatedBlogs}>
                        <AliceCarousel
                            buttonsDisabled={true}
                            autoPlayInterval={2000}
                            dotsDisabled={true}
                            infinite={false}
                            disableAutoPlayOnAction={true}
                            autoPlay={false}
                            responsive={{
                                0: { items: 1, itemsFit: 'contain' },
                                805: { items: 2, itemsFit: 'contain' },
                                1200: { items: 3, itemsFit: 'contain' },
                            }}
                            items={allRelatedBlogs}
                            ref={el => (CarouselListings = el)}
                        />
                        <button
                            className={styles.carouselBackButton}
                            onClick={() => CarouselListings.slidePrev()}
                        >
                            <FaChevronLeft className={styles.carouselChevron} />
                        </button>
                        <button
                            className={styles.carouselNextButton}
                            onClick={() => CarouselListings.slideNext()}
                        >
                            <FaChevronRight className={styles.carouselChevron} />
                        </button>
                    </div>
                ) : null}
            </div>
            <BlogNewsletterForm />
            <BlogContributeOptions />
            <NewFooter />
        </>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
    initLanguage: storeState.Languages.initLanguage,
});

export default connect(mapStateToProps, { changeLanguage })(withRouter(BlogDetailPage));
