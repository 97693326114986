import styled from 'styled-components';

export const StepSection = styled.section`
    width: 100%;
    max-width: 36%;
    padding: 70px 20px;
    position: relative;
    background: ${({ theme }) => theme.colors.title_green};
    z-index: 9;
    .arrow-btn {
        color: white;
        background: none;
        font-size: 20px;
        right: 0;
        top: 0;
        padding: 20px;
        z-index: 99;
    }
    .step-each {
        max-width: 258px;
        margin-top: 58px;
        position: relative;
        min-height: 70px;
        left: 25px;
        .step-title,
        .step-text {
            color: white;
        }
        .step-title {
            color: white;
            font-weight: 600;
            line-height: 28px;
        }
        .step-text {
            line-height: 150%;
        }
        &:first-child {
            margin-top: 0;
        }
        &::before {
            position: absolute;
            content: '•';
            color: white;
            font-size: 77px;
            line-height: 0;
            height: 13px;
            padding: 10px 5px 10px;
            border-radius: 50%;
            border: 1px solid #fff;
            left: -59px;
            top: 0;
        }
        &::after {
            position: absolute;
            content: '';
            background: white;
            width: 1px;
            height: 84px;
            top: 40px;
            left: -43px;
        }
        &:last-child {
            &::after {
                content: none;
            }
        }
    }
    .togglestep {
        .step-each {
            max-width: 0;
            left: 40px;
            .step-title,
            .step-text {
                display: none;
            }
        }
    }
    .step-one {
        &::after {
            height: 68px;
            top: 40px;
        }
    }
    .step-two {
        margin-top: 45px;
    }
    .step-each.start {
        &::before {
            background: white;
            color: #699d49;
        }
    }
    .complete-check {
        &::before {
            background: #fff
                url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12' fill='none'%3E%3Cpath d='M1 5L5.29453 9.72399C5.6786 10.1465 6.33785 10.1621 6.74158 9.75842L15.5 1' stroke='%236EA44C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E")
                no-repeat center center;
            content: '';
            width: 22px;
            height: 12px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        display: none;
    }
`;
