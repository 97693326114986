import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { setSelectedRole } from '../../../../ducks/GuardianReducer.duck';

import IndividualGuardian from '../../../../assets/newAssets/guardians/role1.webp';
import CollectiveGuardian from '../../../../assets/newAssets/guardians/role2.webp';
import ConcessionGuardian from '../../../../assets/newAssets/guardians/role3.webp';
import IndividualGuardianM from '../../../../assets/newAssets/guardians/role1m.webp';
import CollectiveGuardianM from '../../../../assets/newAssets/guardians/role2m.webp';
import ConcessionGuardianM from '../../../../assets/newAssets/guardians/role3m.webp';
import { getUserInterest, getGuardianRoles } from '../../utils';
import GuardianRole from '../../../../newComponents/GuardianRoleBox/RoleBox';
import { GuardianRoleSection } from './Styles';

const formatGuardianRoles = (roleData, roles) => {
    roleData.forEach(roleElement => {
        roles.forEach(({ id, role }) => {
            let regex = new RegExp(`\\b${role}\\b`, 'i');
            if (regex.test(roleElement.englishName)) {
                roleElement.id = id;
            }
        });
    });
    return roleData;
};

const GuardianRolesData = [
    {
        img: IndividualGuardian,
        imgM: IndividualGuardianM,
        title: <FormattedMessage id="GuardianRoles.3.title" />,
        description: <FormattedMessage id="GuardianRoles.3.description" />,
        englishName: 'Individual Guardian role',
    },
    {
        img: CollectiveGuardian,
        imgM: CollectiveGuardianM,
        IndividualGuardianM,
        title: <FormattedMessage id="GuardianRoles.1.title" />,
        description: <FormattedMessage id="GuardianRoles.1.description" />,
        englishName: 'Collective Guardian role',
    },
    {
        img: ConcessionGuardian,
        imgM: ConcessionGuardianM,
        title: <FormattedMessage id="GuardianRoles.2.title" />,
        description: <FormattedMessage id="GuardianRoles.2.description" />,
        englishName: 'Concession Guardian role',
    },
];

const GuardianRoleOptions = ({ handleRoleSubmit }) => {
    const dispatch = useDispatch();
    const [guardianRoles, setGuardianRoles] = useState('');
    //const [selectedRole, setSelectedRole] = useState('');
    const [roleActivated, setRoleActivated] = useState(null);
    const selectedRole = useSelector(state => state.GuardianReducer.selectedRole);
    console.log('selectedRole', selectedRole);
    useEffect(() => {
        (async () => {
            const guardianRoles = await getGuardianRoles();
            const formattedRolesData = formatGuardianRoles(GuardianRolesData, guardianRoles);
            console.log('formattedRolesData', formattedRolesData);
            setGuardianRoles(formattedRolesData);
            const result = await getUserInterest();
            if (result.status === 'success') {
                if (!selectedRole) dispatch(setSelectedRole(result.subRoleId));
                setRoleActivated(result.accountRoleId);
            }
        })();
    }, []);

    const handleRoleChange = e => {
        dispatch(setSelectedRole(e.target.value));
        handleRoleSubmit();
        //setShowError(false);
    };

    return (
        <GuardianRoleSection className="mx-auto">
            <div className="title-box text-center">
                <div className="small-title">
                    <FormattedMessage id="GuardiansPage.GuardianRoles.title" />
                </div>
                <div className="choose-title">
                    <FormattedMessage id="GuardiansPage.GuardianRoles.choose" />
                </div>
            </div>
            <form action="">
                {guardianRoles &&
                    guardianRoles.map(({ id, selectedId, title, description, img, imgM }) => (
                        // <ChooseGuardianRoleCard
                        //     handleClickSelect={handleClickSelect}
                        //     id={id}
                        //     selectedId={selectedId}
                        //     key={id}
                        //     selectedCardId={selectedRole}
                        //     title={title}
                        //     description={description}
                        //     submitted={subRoleSubmitted}
                        //     handleClickSelectClick={handleClickSelectClick}
                        //     selectClickedCardId={selectedClickRole}
                        //     isSubmitting={isSubmitting}
                        //     img={img}
                        // />

                        <label class="radio-box">
                            <input
                                name="selectRole"
                                type="radio"
                                value={id}
                                checked={Number(selectedRole) === Number(id)}
                                onClick={handleRoleChange}
                            />
                            <GuardianRole
                                classrole="guardian-role"
                                roleImg={img}
                                roleImgM={imgM}
                                roleTitle={title}
                                rolePara={description}
                                linkText={
                                    <FormattedMessage id="GuardiansPage.GuardianRoles.SelectSignup" />
                                }
                            />
                        </label>
                    ))}
            </form>
        </GuardianRoleSection>
    );
};

export default GuardianRoleOptions;
