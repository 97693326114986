import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import css from './TermsOfService.css';

const EnglishTermsOfService = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: October 21, 2019</p>

      <p>
      These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with the website <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> (the "Service") operated by Nature Services Peru SA ("we", or "our"), Identified with RUC 20385016582. Read these Terms and Conditions carefully before using the Service. Your access to and use of the Service is conditioned on your acceptance and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to any part of the terms, you will not be able to access the Service.
      </p>

      <h2>Subscriptions</h2>
      <p>Portions of the Service are billed on a subscription basis ("Subscriptions"). You will be billed in advance on a periodic basis ("Billing Cycle"). Billing cycles are established quarterly, based on the type of subscription plan you select when purchasing a subscription.</p>
      <p>At the end of each Billing Cycle, your Subscription will automatically renew under the same conditions, unless you cancel it or Nature Services Peru SA cancels it. You can cancel the renewal of your subscription through the administration page of your online account or by contacting the customer service team of Nature Services Peru SA</p>
      <p>A valid payment method is required to process your subscription payment. You must provide Nature Services Peru SA with accurate and complete billing information, including full name, address, state, zip code, phone number, and valid payment method information. By submitting such payment information, you automatically authorize Nature Services Peru SA to charge all subscription fees incurred through your account to such payment instruments.</p>
      <p>In the event that automatic billing does not occur for any reason, Nature Services Peru SA will issue an electronic invoice indicating that it must proceed manually, within a certain deadline, with the full payment corresponding to the billing period indicated on the invoice.</p>

      <h2>Rate changes</h2>
      <p>Nature Services Peru SA, at its sole discretion and at any time, may modify the Subscription rates. Any changes to the subscription fee will take effect at the end of the current billing cycle.</p>
      <p>Nature Services Peru SA will provide you with reasonable advance notice of any change in Subscription fees to give you the opportunity to cancel your Subscription before such change takes effect.</p>
      <p>Your continued use of the Service after the change in the Subscription fee takes effect constitutes your agreement to pay the amount of the modified Subscription fee.</p>

      <h2>Refunds</h2>
      <p>Except where required by law, subscription fees paid are non-refundable.</p>

      <h2>Content</h2>
      <p>Our Service enables you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content you post on the Service, including its legality, reliability, and appropriateness.</p>
      <p>By posting Content on the Service, you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service. You retain any and all rights to any Content that you submit, post, or display on or through the Service, and you are responsible for protecting those rights.</p>
      <p>You represent and warrant that: (i) the Content is yours (you own it) or have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through of the Service does not violate the rights of privacy, rights of publicity, copyrights, contractual rights or any other rights of any person.</p>

      <h2>User account</h2>
      <p>When you create a user account ("Account") with us, you must provide us with accurate, complete and up-to-date information at all times. Failure to do so constitutes a violation of the Terms, which may result in the immediate termination of your account on our Service.</p>
      <p>You are responsible for safeguarding the password you use to access the Service and for any activity or action under your password, whether it is on our Service or a third party service.</p>
      <p>You agree not to reveal your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      <p>You may not use as a username the name of another person or entity or that is not legally available for use, a name or trademark that is subject to the rights of another person or entity other than you without the corresponding authorization, or a name that is otherwise offensive, vulgar, or obscene.</p>

      <h2>Intellectual property</h2>
      <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Nature Services Peru SA and its licensors. The Service is protected by copyrights, trademarks and other laws of both Peru and foreign countries. Our trademarks and our commercial image cannot be used in relation to any product or service without the prior written consent of Nature Services Peru SA</p>

      <h2>Links to other websites</h2>
      <p>Our Service may contain links to third party websites or services that are not owned or controlled by Nature Services Peru SA Nature Services Peru SA has no control or assumes responsibility for the content, privacy policies or practices of websites or services from third parties. Furthermore, you acknowledge and accept that Nature Services Peru SA will not be responsible, directly or indirectly, for any damage or loss caused or supposedly caused by or in relation to the use or dependence of said content, goods or services available. on or through such websites or services.</p>
      <p>We strongly recommend that you read the terms and conditions and privacy policies of the third-party websites or services that you visit.</p>

      <h2>Termination</h2>
      <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason, including, but not limited to, if you do not comply with the Terms.</p>
      <p>Upon termination, your right to use the Service will cease immediately. If you wish to cancel your account, you can simply stop using the Service.</p>

      <h2>Governing law</h2>
      <p>These Terms will be governed and interpreted in accordance with the laws of Peru, without taking into account its provisions on conflicts of laws.</p>
      <p>Our breach of any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and supersede any prior agreements that we may have between us regarding the Service.</p>

      <h2>Changes</h2>
      <p>We reserve the right, in our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will attempt to provide at least 30 days notice before the new terms take effect. What constitutes a material change will be determined in our sole discretion.</p>
      <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us.</p>

    </div>
  );
};

EnglishTermsOfService.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

EnglishTermsOfService.propTypes = {
    rootClassName: string,
    className: string,
};

export default EnglishTermsOfService;
