import React, { useState, useEffect } from 'react'
import { fetch_calculateFootprint, getMySubscriptionPlans } from '../../../../coreApi';
function CustomHook() {
    const [ecoResults, setEcoResults] = useState({});
    const [dataPlans, setDataPlans] = useState([]);
    const [ecoImpact, setEcoImpact] = useState(0);
    const [climateImpact, setClimateImpact] = useState(0);
    const [Cf, setCf] = useState(null);
    const [Ef, setEf] = useState(null);
    const [planArrayEco, setPlanArrayEco] = useState([]);
    const [planArrayCar, setPlanArrayCar] = useState([]);
    const [year, setYear] = useState();
    useEffect(() => {
        let isComponentMounted = true;
        const fetchData = async () => {
            const res = await fetch_calculateFootprint();
            const plans = await getMySubscriptionPlans();
            if (isComponentMounted) {
                if (res.data.data) {
                    setEcoResults(res.data.data[0]);
                    setCf(res.data.carbonFootprint);
                    setEf(res.data.ecoFootprint);
                    setYear(res.data.data[0].updatedAt);
                }
                setDataPlans(plans.data);
                calculateData(plans.data);

            }
        }
        fetchData();
        return () => {
            isComponentMounted = false;
        }
    }, []);
    const planDetails = {
        'polen': {
            'Ef': 0.2,
            'Cf': 1,
        },
        'polen_price': {
            'Ef': 0.2,
            'Cf': 1,
        },
        'semilla': {
            'Ef': 1,
            'Cf': 5,
        },
        'arbol': {
            'Ef': 4,
            'Cf': 10,
        },
        'bosque': {
            'Ef': 10,
            'Cf': 25,
        },
        'paisaje': {
            'Ef': 20,
            'Cf': 100,
        },
        'semillaEmpresas': {
            'Ef': 10,
            'Cf': 25,
        },
        'arbolEmpresas': {
            'Ef': 40,
            'Cf': 100,
        },
        'bosqueEmpresas': {
            'Ef': 100,
            'Cf': 250,
        }
    }
    const calculateData = (dataPlans) => {
        let dataEco = 0;
        let dataCarbon = 0;
        const arrayEco = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const arrayCar = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        dataPlans && dataPlans.map((item) => {
            let str = item.updatedAt.split('-');
            let m = parseInt(str[1], 10);
            const plan = planDetails[item.planType];
            dataEco = dataEco + plan.Ef;
            dataCarbon = dataCarbon + plan.Cf;
            arrayEco[m - 1] = arrayEco[m - 1] + plan.Ef;
            arrayCar[m - 1] = arrayCar[m - 1] + plan.Cf;
        })
        let previousArrayEco = arrayEco[0];
        arrayEco.map((item, i) => {
            if (i == 0) {
                previousArrayEco = arrayEco[0];
            }
            else {
                if (item == 0) {
                    arrayEco[i] = previousArrayEco;
                }
                previousArrayEco = arrayEco[i];
            }
        })
        let previousArrayCar = arrayCar[0];
        arrayCar.map((item, i) => {
            if (i == 0) {
                previousArrayCar = arrayCar[0];
            }
            else {
                if (item == 0) {
                    arrayCar[i] = previousArrayCar;
                }
                previousArrayCar = arrayCar[i];
            }
        })
        setPlanArrayEco(arrayEco);
        setPlanArrayCar(arrayCar);
        // console.log("In hook");
        // console.log(array);
        setEcoImpact(dataEco);
        setClimateImpact(dataCarbon);
    }
    return {
        planArrayEco: planArrayEco,planArrayCar: planArrayCar,
        Cf: Cf, Ef: Ef, ecoResults: ecoResults,
        ecoImpact: ecoImpact, dataPlans: dataPlans,
        climateImpact: climateImpact, year: year
    }
}



export default CustomHook;
