import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useState, useEffect } from 'react';
import * as coreAPI from '../../coreApi';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors } from '../../theme';
import styles from './styles.module.css';
import Button from '../../newComponents/Button';
import greenhands from '../../assets/blogs/greenhands.png';
import {
    StyledSuccessMessageBlog,
    InputsContainer,
    ConsentContainer,
    CheckboxContainer,
    ErrorCheckbox,
    SubmitButtonContainer
} from '../../newPages/NewLandingPage/Sections/Footer/Styles';
import {
    StyledInput,
    InputContainer,
    Checkbox,
    StyledCheckIcon,
    Message
} from './Styles';
export const ErrorMessage = styled(Message)`
    color: ${colors.lightPink} !important;
    position: absolute;
    top: -3px;
    left: 0px;
    @media screen and (max-width: 425px) {
        top: 0px;
        left: 0px;
    }
`;

const BlogNewsletterForm = props => {

    const reCaptchaRef = React.createRef();
    const [error, setError] = useState({ firstname: false, lastname: false, email: false });
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState({ firstname: '', lastname: '' });
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [messageStatus, setmessageStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [submitTry, setSubmitTry] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);

    useEffect(() => {
        if (isChecked) setCheckboxError(false);
    }, [isChecked]);

    const handleNameChange = e => {
        const target = e.target;
        setName({ ...name, [target.name]: target.value });
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const checkEmailSubscriptionCaptcha = async captchaToken => {
        console.log('Token', captchaToken);
        if (!!captchaToken && name.firstname !== '' && name.lastname !== '' && email !== '') {
            try {
                console.log('in check email subscription');
                await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: name.firstname,
                        lastname: name.lastname,
                        email,
                        language: props.language,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
                setmessageStatus('sended');
                setIsLoading(false);
                setSuccess(true);
            } catch (e) {
                setmessageStatus('error');
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (name.firstname !== '' && name.lastname !== '' && email.length > 2) {
            setError({
                firstname: name.firstname === '',
                lastname: name.lastname === '',
                email: !/.+@.+\.[A-Za-z]+$/.test(email),
            });
            setIsLoading(false);
        }
        if (!error.firstname && !error.lastname && !error.email) {
            setError(false);
        }
    }, [name.firstname, name.lastname, email]);

    const handleNewsletter = async e => {
        e.preventDefault();
        setSubmitTry(true);
        if (name.firstname.length != 0 && name.lastname.length != 0 && email.length > 2) {
            setError({
                firstname: name.firstname.length == 0,
                lastname: name.lastname.length == 0,
                email: !/.+@.+\.[A-Za-z]+$/.test(email),
            });
            setIsLoading(false);
        }
        if (name.firstname.length == 0 && name.lastname.length == 0 && email.length < 2) {
            setError({ firstname: true, lastname: true, email: true });
            setmessageStatus('error');
            setIsLoading(false);
        }
        if (name.firstname.length == 0 || name.lastname.length == 0) {
            setError({ firstname: true, lastname: true, email: false });
            setmessageStatus('error');
            setIsLoading(false);
        }
        if (email.length < 2) {
            setError({ firstname: false, lastname: false, email: true });
            setmessageStatus('error');
            setIsLoading(false);
        }
        if (isChecked === false) {
            setmessageStatus('error');
            setCheckboxError(true);
            setIsLoading(false);
        }
        if (!error.firstname && !error.lastname && !error.email && isChecked == true) {
            setIsLoading(true);
            reCaptchaRef.current.execute();
            try {
                await coreAPI.subscribeToNewsLetter({
                    firstname: name.firstname,
                    lastname: name.lastname,
                    email,
                    language: props.language,
                    statusIfNew: 'subscribed',
                    status: 'subscribed',
                });
                setmessageStatus('sended');
                setIsLoading(false);
                setSuccess(true);
                console.log(success);
            } catch (e) {
                setmessageStatus('error');
            }
        }
    };

    const SuccessMessage = (
        <StyledSuccessMessageBlog>
            <FormattedMessage id="Blogs.NewsletterForm.success" />
        </StyledSuccessMessageBlog>
    );

    return (
        <div className={styles.subscribeBox}>
            <img src={greenhands} className={styles.greenhands} />
            <div className={styles.formBox}>
                <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={checkEmailSubscriptionCaptcha}
                />
                <div className={styles.heading}>
                    <FormattedMessage id="Blogs.NewsletterForm.heading" />
                </div>
                <div className={styles.message}>
                <FormattedMessage id="Blogs.NewsletterForm.message" />
                </div>
                {!success ? (
                    <>
                        <InputsContainer>
                            <InputContainer
                                status={error.firstname && 'failure'}
                                pt={
                                    error.firstname || error.lastname || error.email
                                        ? '45px'
                                        : '15px'
                                }
                            >
                                {error.firstname && (
                                    <ErrorMessage>
                                        <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                    </ErrorMessage>
                                )}
                                <StyledInput
                                    type="text"
                                    status={error.firstname && 'failure'}
                                    placeholder="First Name"
                                    name="firstname"
                                    onChange={handleNameChange}
                                />
                            </InputContainer>
                            <InputContainer
                                status={error.lastname && 'failure'}
                                pt={
                                    error.email || error.firstname || error.lastname
                                        ? '45px'
                                        : '15px'
                                }
                            >
                                {error.lastname && (
                                    <ErrorMessage>
                                        <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                    </ErrorMessage>
                                )}
                                <StyledInput
                                    type="text"
                                    status={error.lastname && 'failure'}
                                    placeholder="Last Name"
                                    name="lastname"
                                    onChange={handleNameChange}
                                />
                            </InputContainer>
                            <InputContainer
                                status={error.email && 'failure'}
                                pt={
                                    error.email || error.firstname || error.lastname
                                        ? '45px'
                                        : '15px'
                                }
                            >
                                {error.email && (
                                    <ErrorMessage>
                                        <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                    </ErrorMessage>
                                )}
                                <StyledInput
                                    type="email"
                                    status={error.email && 'failure'}
                                    placeholder="Email"
                                    onChange={handleEmailChange}
                                />
                            </InputContainer>
                        </InputsContainer>
                        <ConsentContainer>
                            <CheckboxContainer>
                                {checkboxError ? (
                                    <ErrorCheckbox
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    />
                                ) : (
                                    <Checkbox
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    />
                                )}
                                <StyledCheckIcon
                                    checked={isChecked}
                                    onClick={() => setIsChecked(!isChecked)}
                                />
                            </CheckboxContainer>
                            <Message>
                                <FormattedMessage id="NewLandingPage.footer.consentMessage" />
                            </Message>
                        </ConsentContainer>
                        <SubmitButtonContainer width={{ _: '100%', md: 250 }}>
                            <Button
                                disabled={isLoading}
                                inProgress={isLoading}
                                onClick={handleNewsletter}
                                variant="filledBlack"
                                size="md"
                            >
                            <FormattedMessage id="Blogs.NewsletterForm.submit" />
                            </Button>
                        </SubmitButtonContainer>
                    </>
                ) : (
                    SuccessMessage
                )}
            </div>
        </div>
    );
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              email: storeState.user.currentUser.attributes.email,
              firstName: storeState.user.currentUser.attributes.profile.firstName,
              lastName: storeState.user.currentUser.attributes.profile.lastName,
              cartCount: storeState.user.currentUser.cartCount,
              currentRole: storeState.user.currentUser.currentRole,
              activatedRoles: storeState.user.currentUser.activatedRoles,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});

export default connect(mapStateToProps)(withRouter(BlogNewsletterForm));