import ReactGA from 'react-ga';
import { console } from 'window-or-global';
export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

export class GoogleAnalyticsHandler {
  constructor(ANALYTICS_ID) {
    // if (typeof ga !== 'function') {
    //   throw new Error('Variable `ga` missing for Google Analytics');
    // }
    // this.ga = ga;
    this.ANALYTICS_ID = ANALYTICS_ID
    ReactGA.initialize(ANALYTICS_ID)
  }
  trackPageView(url) {
    console.log(url)
    ReactGA.pageview(url)
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    
    // this.ga('set', 'page', url);
    // this.ga('send', 'pageview');
  }
}
