import React from 'react';
import CheckBoxInfo from '../checkbox';

const BlogFilterLandscape = ({ landscapes, onLandscapeChange, selectedLandscapes }) => {
    return (
        <div className="submenu">
            <ul>
                {landscapes &&
                    landscapes.map(landscape => (
                        <li key={landscape.slug}>
                            <CheckBoxInfo
                                labelName={landscape.name}
                                labelId={landscape.slug}
                                checkboxSection="checkbox-section"
                                onChange={onLandscapeChange}
                                isChecked={selectedLandscapes.includes(landscape.slug)}
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default BlogFilterLandscape;
