import React, { useRef, useEffect } from 'react';
import { plans as allPlans, plans } from '../../../paymentDataNew';
import css from './ChoosePlans.css';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as Hectores } from '../../../assets/Partners/hectores.svg';
import { ReactComponent as Tonnes } from '../../../assets/Partners/tonnes.svg';
import { ReactComponent as TreeNum } from '../../../assets/newAssets/treenum.svg';
import Button from '../../../newComponents/Button';
import { min } from 'moment';
import { FaCheck } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { getCart, createCart, updateCart } from '../../../coreApi';

const organizationPlans = ['semillaEmpresas', 'bosqueEmpresas', 'arbolEmpresas'];

const isOrganizationPlan = planType => organizationPlans.includes(planType);

const PlansComparison = ({
    selectedPlan,
    selectedLandscape,
    onSelectionPlan,
    recommendedPlan,
    handlePlanSelection,
    handleCartStatus,
    cart,
    user,
    ...props
}) => {
    let CarouselListings = useRef(null);

    let { userType } = props;
    if (!userType) {
        userType = 'empresa';
    }

    const cartItem = {
        user: user && user.email + '_' + user.currentRole + '_' + user.cartCount,
        customer: {
            id: user && user.email,
            email_address: user && user.email,
            opt_in_status: true,
            first_name: user && user.firstName,
            last_name: user && user.lastName,
        },
        product: [
            {
                id: user && user.email,
                product_id: selectedPlan && `${selectedPlan.plan}_${selectedPlan.planType}`,
                product_variant_id: selectedPlan && `${selectedPlan.plan}_${selectedPlan.planType}`,
                quantity: 1,
                price: selectedPlan.amount / 100,
            },
        ],
        checkout_url: 'https://www.regenera.earth/organizations',
    };

    useEffect(() => {
        if (user && Object.keys(selectedPlan).length > 0) {
            console.log('qwcartItem', cartItem);
            if (!cart) {
                getCart(cartItem.user)
                    .then(response => {
                        console.log('qwgetcart', response);
                        updateCart({ user: cartItem.user, payload: cartItem.product }).then(
                            response => {
                                console.log('qwget then UpdateCart', response.data);
                            }
                        );
                        handleCartStatus(true);
                    })
                    .catch(err => {
                        createCart(cartItem).then(response => {
                            console.log('qwcreateCart', response.data);
                            handleCartStatus(true);
                        });
                    });
            } else {
                updateCart({ user: cartItem.user, payload: cartItem.product }).then(response => {
                    console.log('qwelseUpdateCart', response.data);
                });
            }
        }
    }, [selectedPlan]);

    const isPlanApplicable = planDetails =>
        userType === 'empresa' ? isOrganizationPlan(planDetails.planType) : false;

    const filteredPlans = selectedLandscape
        ? selectedLandscape.plans
              .filter(isPlanApplicable)
              .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount)
        : plans
              .filter(isPlanApplicable)
              .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount);

    const currentSelectedPlan = selectedPlan
        ? allPlans.find(frontendPlans => frontendPlans.planType === selectedPlan.planType)
        : undefined;

    const images = filteredPlans.map((plan, index) => {
        const currentPlan = allPlans.find(
            frontendPlans => frontendPlans.planType === plan.planType
        );
        if (!currentPlan) {
            return null;
        }
        const isRecommend = recommendedPlan ? recommendedPlan == plan.planType : false;
        const isSelected = selectedPlan.planType == plan.planType;

        return (
            <div key={index} className={userType !== 'empresa' ? css.planCard : css.planCard}>
                <div className={css.planCardContent}>
                    <h5 style={{ fontSize: 15, color: '#FF7A00', fontWeight: 700 }}>
                        {isRecommend ? 'Recommended for you' : ''}
                    </h5>
                    <img src={currentPlan.image} alt={currentPlan.title} />
                    <h5 className={css.planCardTitle}>
                        <FormattedMessage
                            id="PlanComparison.plan.title"
                            values={{ planName: currentPlan.title }}
                        />
                    </h5>
                    <p className={css.planCardDesc}>{currentPlan.description}</p>

                    <div className={css.planPromises}>
                        <div className={css.planPromise}>
                            <Hectores />
                            <div className={css.planMetricDisp}>
                                <NumberFormat
                                    value={currentPlan.landProtected / 10000}
                                    className={`${css.planMetric}`}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value, props) => (
                                        <p {...props}>
                                            {value}{' '}
                                            <FormattedMessage id="NewPartnersPage.Plans.hectares" />
                                        </p>
                                    )}
                                />
                                <p className={css.planMetricDesc}>
                                    <FormattedMessage id="NewPartnersPage.Plans.landProtectedAllYear" />
                                </p>
                            </div>
                        </div>
                        <div className={css.planPromise}>
                            <Tonnes />
                            <div className={css.planMetricDisp}>
                                <NumberFormat
                                    value={currentPlan.emissionComp / 1000}
                                    className={`${css.planMetric}`}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value, props) => (
                                        <p {...props}>
                                            {value}{' '}
                                            <FormattedMessage id="NewPartnersPage.Plans.tonnes" />
                                        </p>
                                    )}
                                />
                                <p className={css.planMetricDesc}>
                                    <FormattedMessage id="NewPartnersPage.Setup.ChooseLS.coEmission" />
                                </p>
                            </div>
                        </div>
                        <div className={css.planPromise}>
                            <TreeNum />
                            <div className={css.planMetricDisp}>
                                <NumberFormat
                                    value={currentPlan.treePlanted}
                                    className={`${css.planMetric}`}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value, props) => (
                                        <p {...props}>
                                            {value}{' '}
                                            {value > 1 ? (
                                                <FormattedMessage id="NewPartnersPage.Setup.ChooseLS.trees" />
                                            ) : (
                                                <FormattedMessage id="NewPartnersPage.Setup.ChooseLS.tree" />
                                            )}
                                        </p>
                                    )}
                                />
                                <p className={css.planMetricDesc}>
                                    <FormattedMessage id="NewPartnersPage.Setup.ChooseLS.plantedAndCared" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className={css.price}>
                        <FormattedMessage
                            values={{
                                price: <>{plan.amount / 300}</>,
                                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
                            }}
                            id="ChoosePlanPage.plan.price"
                        />
                    </h3>
                    <Button
                        onClick={() => handlePlanSelection(currentPlan)}
                        testId="btn-partner-choose-plan"
                    >
                        {isSelected && <FaCheck className={css.checkIcon} />}
                        {isSelected ? (
                            <FormattedMessage id="NewPartnersPage.Setup.ChoosePlan.selected" />
                        ) : (
                            <FormattedMessage id="NewPartnersPage.Setup.ChoosePlan.choose" />
                        )}
                    </Button>
                </div>
            </div>
        );
    });

    return (
        <>
            <div
                className={css.planCardsDesktop}
                style={{
                    gridTemplateColumns: `repeat(${images.length}, 1fr)`,
                    maxWidth: `min(1237px, calc(${images.length} * 378px + ${images.length -
                        1} * 40px))`,
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                {images}
            </div>
            <div className={css.planCardsMobile}>
                <AliceCarousel
                    buttonsDisabled={true}
                    autoPlayInterval={2000}
                    dotsDisabled={true}
                    infinite={false}
                    disableAutoPlayOnAction={true}
                    autoPlay={false}
                    responsive={{ 0: { items: 1 }, 767: { items: 2 }, 1023: { items: 4 } }}
                    items={images}
                    ref={el => (CarouselListings = el)}
                />
                <button
                    className={css.carouselBackButton}
                    onClick={() => CarouselListings.slidePrev()}
                >
                    <FaChevronLeft className={css.carouselChevron} />
                </button>
                <button
                    className={css.carouselNextButton}
                    onClick={() => CarouselListings.slideNext()}
                >
                    <FaChevronRight className={css.carouselChevron} />
                </button>
            </div>
        </>
    );
};

export default PlansComparison;
