import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

import { ExternalLink } from '..';

const PrivacyPolicy = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Fecha efectiva: Enero 1, 2019</p>

      <p>Nature Services Peru SA ("nosotros", "nuestro") opera el sitio web <ExternalLink href={`${process.env.REACT_APP_BASE_URL}`}> {process.env.REACT_APP_BASE_URL} </ExternalLink> (en adelante, el "Servicio").</p>
      <p>Esta página le informa de nuestras políticas en materia de recopilación, uso y divulgación de datos personales cuando utiliza nuestro Servicio y de las opciones de las que dispone en relación con esos datos.</p>
      <p>Utilizamos sus datos para prestarle el Servicio y mejorarlo. Al utilizar el Servicio, Usted acepta la recopilación y el uso de información de conformidad con esta política. A menos que esta Política de privacidad defina lo contrario, los términos utilizados en ella tienen los mismos significados que nuestros Términos y Condiciones, disponibles en <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>${process.env.REACT_APP_BASE_URL}</ExternalLink></p>
      <h2>
            <span>Definiciones</span>
        </h2>
        <p>
            <span>Servicio</span>
        </p>
        <p>
            <span>Servicio es el sitio web <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</ExternalLink> operado por Nature Services Peru SA</span>
        </p>
        <p>
            <span>Datos personales</span>
        </p>
        <p>
            <span>Datos personales significa los datos sobre una persona f&iacute;sica viva que puede ser identificada a partir de esos datos (o con esos datos y otra informaci&oacute;n de la que dispongamos o probablemente podamos disponer).</span>
        </p>
        <p>
            <span>Datos de uso</span>
        </p>
        <p>
            <span>Datos de uso son los datos recopilados autom&aacute;ticamente, generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duraci&oacute;n de la visita a una p&aacute;gina).</span>
        </p>
        <p>
            <span>Cookies</span>
        </p>
        <p>
            <span>Las cookies son peque&ntilde;os archivos almacenados en su dispositivo (ordenador o dispositivo m&oacute;vil).</span>
        </p>
        <h2>
            <span>Recopilaci&oacute;n y uso de la informaci&oacute;n</span>
        </h2>
        <p>
            <span>Recopilamos diferentes tipos de informaci&oacute;n con diversas finalidades para prestarle el Servicio y mejorarlo.</span>
        </p>
        <h3>
            <span>Tipos de datos recopilados</span>
        </h3>
        <h4>
            <span>Datos personales</span>
        </h4>
        <p>
            <span>Cuando utilice nuestro Servicio, es posible que le pidamos que nos proporcione determinada informaci&oacute;n personalmente identificable que podr&aacute;ser utilizada para contactar con usted o para identificarle (&quot;Datos personales&quot;). La informaci&oacute;n personalmente identificable puede incluir, entre otras, la siguiente:</span>
        </p>
        <ul>
            <li>
                <span>Direcci&oacute;n de e-mail</span>
            </li>
            <li>
                <span>Nombre y apellidos</span>
            </li>
            <li>
                <span>N&uacute;mero de tel&eacute;fono</span>
            </li>
            <li>
                <span>Direcci&oacute;n, localidad, provincia, c&oacute;digo postal, ciudad</span>
            </li>
        </ul>
        <p>
            <span>Podemos utilizar sus Datos personales para contactarnos con Usted a fin de proporcionarle hojas informativas, materiales de marketing o promoci&oacute;n y otra informaci&oacute;n que pueda ser de su inter&eacute;s. Puede optar por no recibir parte o la totalidad de estas comunicaciones, haciendo clic en el enlace de anulaci&oacute;n de la suscripci&oacute;n, siguiendo las instrucciones proporcionadas en el mensaje de e-mail que le enviemos o contactando con nosotros.</span>
        </p>
        <h4>
            <span>Datos de uso</span>
        </h4>
        <p>
            <span>Tambi&eacute;n recopilamos informaci&oacute;n sobre la forma en la que se accede y utiliza el Servicio (&laquo;Datos de uso&raquo;). Estos Datos de uso pueden incluir informaci&oacute;n como la direcci&oacute;n del protocolo de Internet de su ordenador (por ejemplo, direcci&oacute;n IP), tipo de navegador, versi&oacute;n del navegador, las p&aacute;ginas que visita de nuestro Servicio, la hora y la fecha de su visita, el tiempo que pasa en esas p&aacute;ginas, identificadores exclusivos de dispositivos y otros datos de diagn&oacute;stico.</span>
        </p>
        <h4>
            <span>Datos de cookies y seguimiento</span>
        </h4>
        <p>
            <span>Utilizamos cookies y tecnolog&iacute;as de seguimiento similares para rastrear la actividad de nuestro Servicio y mantener determinada informaci&oacute;n.</span>
        </p>
        <p>
            <span>Las cookies son archivos con una peque&ntilde;a cantidad de datos que pueden incluir un identificador exclusivo an&oacute;nimo. Las cookies son enviadas a su navegador desde un sitio web y se almacenan en su dispositivo. Otras tecnolog&iacute;as de seguimiento tambi&eacute;n utilizadas son balizas, etiquetas y scripts para recopilar y rastrear la informaci&oacute;n, as&iacute;como para mejorar y analizar nuestro Servicio.</span>
        </p>
        <p>
            <span>Usted puede ordenar a su navegador que rechace todas las cookies o que le avise cuando se env&iacute;a una cookie. Sin embargo, si no acepta cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.</span>
        </p>
        <p>
            <span>Ejemplos de Cookies que utilizamos:</span>
        </p>
        <ul>
            <li>
                <span>Cookies de sesi&oacute;n.</span>
                <span>&nbsp;Utilizamos Cookies de sesi&oacute;n para operar nuestro Servicio.</span>
            </li>
            <li>
                <span>Cookies de preferencia.</span>
                <span>&nbsp;Utilizamos Cookies de preferencia para recordar sus preferencias y diversos ajustes.</span>
            </li>
            <li>
                <span>Cookies de seguridad.</span>
                <span>&nbsp;Utilizamos Cookies de seguridad para fines de seguridad.</span>
            </li>
        </ul>
        <h2>
            <span>Uso de datos</span>
        </h2>
        <p>
            <span>Nature Services Peru SA utiliza los datos recopilados con diversas finalidades:</span>
        </p>
        <ul>
            <li>
                <span>Suministrar y mantener nuestro Servicio</span>
            </li>
            <li>
                <span>Notificarle cambios en nuestro Servicio</span>
            </li>
            <li>
                <span>Permitirle participar en funciones interactivas de nuestro Servicio cuando decida hacerlo</span>
            </li>
            <li>
                <span>Prestar asistencia al cliente</span>
            </li>
            <li>
                <span>Recopilar an&aacute;lisis que nos permitan mejorar nuestro Servicio</span>
            </li>
            <li>
                <span>Controlar el uso de nuestro Servicio</span>
            </li>
            <li>
                <span>Detectar, evitar y abordar problemas t&eacute;cnicos</span>
            </li>
            <li>
                <span>Ofrecerle noticias, ofertas especiales e informaci&oacute;n general sobre otros bienes, servicios y eventos que podemos ofrecer y que son similares a los que ya ha adquirido o sobre los que ha realizado consultas, a menos que haya optado por no recibir esta informaci&oacute;n.</span>
            </li>
        </ul>
        <h2>
            <span>Transferencia de datos</span>
        </h2>
        <p>
            <span>Su informaci&oacute;n, incluyendo Datos personales, puede ser transferida a&mdash;y mantenida en&mdash;ordenadores localizados fuera de su estado, provincia, pa&iacute;s u otra jurisdicci&oacute;n gubernamental donde las leyes de protecci&oacute;n de datos pueden diferir de las de su jurisdicci&oacute;n.</span>
        </p>
        <p>
            <span>Si usted se encuentra fuera de Peru y decide facilitarnos informaci&oacute;n, tenga en cuenta que nosotros transferimos los datos, incluyendo Datos personales, a Peru y que los tratamos all&iacute;.</span>
        </p>
        <p>
            <span>Su aceptaci&oacute;n de esta Pol&iacute;tica de privacidad seguida de su env&iacute;o de esta informaci&oacute;n representa que est&aacute;de acuerdo con dicha transferencia.</span>
        </p>
        <p>
            <span>Nature Services Peru SA emprender&aacute;todas las medidas razonables necesarias para garantizar que sus datos sean tratados de forma segura y de conformidad con esta Pol&iacute;tica de privacidad y no se realizar&aacute;ninguna transferencia de sus Datos personales a una organizaci&oacute;n o pa&iacute;s, salvo que existan unos controles adecuados establecidos incluyendo la seguridad de sus datos y otra informaci&oacute;n personal.</span>
        </p>
        <h2>
            <span>Divulgaci&oacute;n de datos</span>
        </h2>
        <h3>
            <span>Divulgaci&oacute;n por obligaci&oacute;n legal</span>
        </h3>
        <p>
            <span>En determinadas circunstancias, Nature Services Peru SA puede estar obligada a divulgar sus Datos personales cuando as&iacute;lo exija la legislaci&oacute;n o en respuesta a solicitudes v&aacute;lidas de autoridades p&uacute;blicas (por ejemplo, un tribunal o un organismo p&uacute;blico).</span>
        </p>
        <h3>
            <span>Requisitos legales</span>
        </h3>
        <p>
            <span>Nature Services Peru SA puede divulgar sus Datos personales de buena fe cuando considere que esta acci&oacute;n es necesaria para lo siguiente:</span>
        </p>
        <ul>
            <li>
                <span>Cumplir una obligaci&oacute;n legal</span>
            </li>
            <li>
                <span>Proteger y defender los derechos o bienes de Nature Services Peru SA</span>
            </li>
            <li>
                <span>Prevenir o investigar posibles infracciones en relaci&oacute;n con el Servicio</span>
            </li>
            <li>
                <span>Proteger la seguridad personal de usuarios del Servicio o del p&uacute;blico</span>
            </li>
            <li>
                <span>Protegerse frente a consecuencias legales</span>
            </li>
        </ul>
        <h2>
            <span>Seguridad de los datos</span>
        </h2>
        <p>
            <span>La seguridad de sus datos es importante para nosotros, pero recuerde que ning&uacute;n m&eacute;todo de transmisi&oacute;n por Internet o m&eacute;todo de almacenamiento electr&oacute;nico resulta 100% seguro. A pesar de que nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta.</span>
        </p>
        <h2>
            <span>Proveedores de servicios</span>
        </h2>
        <p>
            <span>Podemos contratar a personas f&iacute;sicas y jur&iacute;dicas terceras para facilitar nuestro Servicio (&quot;Proveedores de servicios&quot;), para que presten el Servicio en nuestro nombre, para que suministren servicios relacionados con el Servicio o para que nos ayuden a analizar c&oacute;mo se utiliza nuestro Servicio.</span>
        </p>
        <p>
            <span>Estos terceros tienen acceso a sus Datos personales&uacute;nicamente para realizar estas tareas en nuestro nombre y est&aacute;n obligados a no divulgarlos ni utilizarlos con ning&uacute;n otro fin.</span>
        </p>
        <h3>
            <span>Pagos</span>
        </h3>
        <p>
            <span>Podemos ofrecer productos y/o servicios de pago dentro del Servicio. En ese caso, utilizamos servicios de terceros para el procesamiento de los pagos (por ejemplo, procesadores de pagos). Proporcionamos directamente a nuestros procesadores de pagos terceros, cuyo uso de los datos personales est&aacute;n regulados por su Pol&iacute;tica de privacidad. Estos procesadores de pagos cumplen las normas establecidas por el est&aacute;ndar PCI-DSS gestionado por el comit&eacute;PCI SSC (PCI Security Standards Council), que es un esfuerzo conjunto de marcas como Visa, MasterCard, American Express y Discover. Los requisitos del est&aacute;ndar PCI-DSS ayudan a garantizar una gesti&oacute;n segura de los datos del pago.</span>
        </p>
        <p>
            <span>Los procesadores de pagos con los que trabajamos son: </span>
        </p>
        <p>
            <span>Stripe</span>
        </p>
        <p>
            <span>Su Pol&iacute;tica de privacidad se puede consultar en </span>
            <span>
                <ExternalLink href="https://www.google.com/url?q=https://stripe.com/us/privacy&amp;sa=D&amp;ust=1571856898984000">https://stripe.com/us/privacy</ExternalLink>
            </span>
        </p>
        <h2>
            <span>Enlaces a otros sitios</span>
        </h2>
        <p>
            <span>Nuestro Servicio puede contener enlaces a otros sitios no operados por nosotros. Si hace clic en el enlace de un tercero, ser&aacute;dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Pol&iacute;tica de privacidad de todos los sitios que visite.</span>
        </p>
        <p>
            <span>No tenemos ning&uacute;n control ni asumimos responsabilidad alguna con respecto al contenido, las pol&iacute;ticas o pr&aacute;cticas de privacidad de sitios o servicios de terceros.</span>
        </p>
        <h2>
            <span>Privacidad del menor</span>
        </h2>
        <p>
            <span>Nuestro servicio no est&aacute;dirigido a ning&uacute;n menor de 18 a&ntilde;os (en adelante,&quot;Menor&quot;).</span>
        </p>
        <p>
            <span>No recopilamos de forma consciente informaci&oacute;n personalmente identificable de menores de 18 a&ntilde;os. Si es usted un padre/madre o tutor y tiene conocimiento de que su hijo nos ha facilitado Datos personales, contacte con nosotros. Si tenemos conocimiento de que hemos recopilado Datos personales de menores sin verificaci&oacute;n del consentimiento parental, tomamos medidas para eliminar esa informaci&oacute;n de nuestros servidores.</span>
        </p>
        <h2>
            <span>Cambios en esta Pol&iacute;tica de Privacidad</span>
        </h2>
        <p>
            <span>Podemos actualizar nuestra Pol&iacute;tica de privacidad peri&oacute;dicamente. Le notificaremos cualquier cambio publicando la nueva Pol&iacute;tica de privacidad en esta p&aacute;gina.</span>
        </p>
        <p>
            <span>Le informaremos a trav&eacute;s del e-mail y/o de un aviso destacado sobre nuestro Servicio antes de que el cambio entre en vigor y actualizaremos la&laquo;fecha efectiva&raquo;en la parte superior de esta Pol&iacute;tica de privacidad.</span>
        </p>
        <p>
            <span>Le recomendamos que revise esta Pol&iacute;tica de privacidad peri&oacute;dicamente para comprobar si se ha introducido alg&uacute;n cambio. Los cambios en esta Pol&iacute;tica de privacidad entran en vigor cuando se publican en esta p&aacute;gina.</span>
        </p>
        <h2>
            <span>Contacte con nosotros</span>
        </h2>
        <p>
            <span>Si tiene alguna pregunta sobre esta Pol&iacute;tica de privacidad, contacte con nosotros: </span>
        </p>
        <ul>
            <li>
                <span>Por e-mail: {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}</span>
            </li>
            <li>
                <span>Visitando esta p&aacute;gina en nuestro sitio web: <ExternalLink href={`https://${process.env.REACT_APP_BASE_URL}/privacy-policy`}>{process.env.REACT_APP_BASE_URL}/privacy-policy</ExternalLink></span>
            </li>
        </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default PrivacyPolicy;
