import React from 'react';
import { ExternalLink } from '../../components';
import { StaticPage } from '../../containers';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { OrangeBtn, LightGreenBtn } from '../../newComponents/Button/ButtonSkin';

import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import imageD from '../../assets/newAssets/icons/blog-title-line-d.svg';
import imageM from '../../assets/newAssets/icons/blog-title-line-m.svg';
import { TitleContainer, Center, FaqFlow, HelpBoxContainer } from './Styles';

const SpanishFaq = ({ location, history, user }) => {
    return (
        <StaticPage
            title="Preguntas frecuentes"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AboutPage',
                description: '¿Qué es la huella ambiental?',
                name: 'Preguntas frecuentes',
            }}
        >
            <Header currentPath={location.pathname} user={user} history={history} />

            <TitleContainer
                className="flex justify-center relative"
                bgGreenTexture={bgGreenTexture}
            >
                <Center className="mx-auto relative">
                    <div className="title-box text-center">
                        <h1>
                            <span className="border">
                                Preguntas Frecuentes
                                <picture>
                                    <source media="(max-width:768px)" srcset={imageM} />
                                    <img
                                        src={imageD}
                                        className="w-auto"
                                        alt="Preguntas Frecuentes"
                                    />
                                </picture>
                            </span>
                        </h1>
                    </div>
                </Center>
            </TitleContainer>

            <FaqFlow>
                <div className="faq-wrap flex mx-auto">
                    <div id="huellaambiental" className={'sectionWhite'}>
                        <section className="sectionContent">
                            <h2>¿Qué es la huella ambiental?</h2>

                            <p>
                                Es el efecto que cada persona, organización o producto tiene a lo
                                largo de su ciclo de vida. Por ejemplo, en una persona, la huella
                                ambiental es la suma de la energía que consume, la cantidad de
                                emisiones de CO2 que genera, el agua que consume, los desechos que
                                produce, entre otros. En el caso de un producto, como una
                                hamburguesa, su huella ambiental es todo que se necesita de la
                                naturaleza para producirla: agua, alimentación para el ganado,
                                energía, producción y transporte.
                            </p>
                            <p>
                                De acuerdo a qué tipo de recurso se trate, existen diversos tipos de
                                huellas y cómo medirlas:
                            </p>
                            <ul className="list">
                                <li>
                                    <h3>Huella de carbono:</h3>
                                    <p>
                                        Mide cantidad de emisiones de CO2 que realizamos a la
                                        atmósfera como parte de nuestras actividades diarias o en
                                        los procesos de producción. Se mide en toneladas equivalente
                                        de dióxido de carbono (tCO2eq). Si deseas conocer tu huella
                                        de carbono (carbon footprint en inglés), puedes visitar
                                        estas calculadoras:
                                    </p>
                                    <ul className="list">
                                        <li>
                                            <ExternalLink
                                                href="https://libelula.com.pe/descubre/"
                                                className="green-link flex items-center"
                                            >
                                                https://libelula.com.pe/descubre/
                                            </ExternalLink>
                                        </li>
                                        <li>
                                            <ExternalLink
                                                href="https://calculator.carbonfootprint.com"
                                                className="green-link flex items-center"
                                            >
                                                https://calculator.carbonfootprint.com
                                            </ExternalLink>{' '}
                                            (Tip: escoge la versión en español)
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Huella hídrica:</h3>
                                    <p>
                                        Se refiere a la cantidad de agua dulce que consumimos en
                                        nuestra vida diaria (uso directo), así como la que se
                                        necesita para producir los bienes que consumimos (uso
                                        indirecto). Puede expresarse en litros de agua al día para
                                        las personas y en metros cúbicos para empresas y
                                        organizaciones. Si deseas conocer tu huella hídrica puedes
                                        visitar esta calculadora de la Autoridad Nacional del Agua
                                        (ANA):
                                    </p>
                                </li>
                                <li>
                                    <h3>Huella de residuos sólidos:</h3>
                                    <p>
                                        Es la cantidad de residuos orgánicos e inorgánicos que
                                        produces al día como parte de tus actividades. Se expresa en
                                        kilos por día. En el Perú, una persona promedio produce un
                                        kilo de desechos cada día.{' '}
                                        <ExternalLink
                                            href="http://www.vidasostenible.org/ciudadanos/mide-tu-huella-ecologica/encuesta-del-residuos-y-materiales/"
                                            className="green-link flex items-center"
                                        >
                                            En esta página podrás calcular la cantidad de desechos
                                            que produces
                                        </ExternalLink>
                                    </p>
                                </li>
                                <li>
                                    <h3>Huella ecológica:</h3>
                                    <p>
                                        Es una herramienta de contabilidad de los recursos naturales
                                        que parte de un enfoque integral. Compara la demanda de
                                        recursos naturales de nuestra sociedad con la capacidad de
                                        la naturaleza de satisfacer dicha demanda; su objetivo es
                                        mostrar los activos ecológicos disponibles y dónde se están
                                        utilizando. Esta herramienta es vital para hacer frente a
                                        los riesgos de nuestro actual sobreuso de recursos a nivel
                                        nacional y global.{' '}
                                        <ExternalLink
                                            href="https://www.footprintcalculator.org/"
                                            className="green-link flex items-center"
                                        >
                                            En la página de Global Footprint Network podrás medir tu
                                            huella ecológica
                                        </ExternalLink>{' '}
                                        (escoge la versión en español)
                                    </p>
                                </li>
                            </ul>
                            <p>
                                Cada Huella nos da indicaciones sobre el efecto de nuestras
                                costumbres y comportamientos sobre los recursos y la salud del
                                planeta. Mide tu huella frecuentemente con las diferentes
                                calculadoras y crea un registro de cómo va evolucionando.
                            </p>
                        </section>
                    </div>

                    <div id="serviciosnaturaleza">
                        <section className="sectionContent">
                            <h2>¿Qué son los servicios de la naturaleza?</h2>
                            <p>
                                Imaginemos a la Tierra como una enorme fábrica de bienes y servicios
                                que todos utilizamos: agua limpia, aire puro, terrenos estables
                                donde vivir, alimentos, recursos para la producción, espacios verdes
                                donde relajarnos, montañas y nevados que forman parte de la
                                cosmovisión de poblaciones indígenas. A todos ellos –y otros más– se
                                les denomina servicios de la naturaleza, ambientales o
                                ecosistémicos.
                            </p>

                            <p>
                                La sociedad y la economía no existirían sin los bienes y servicios
                                que nos proveen los ecosistemas naturales. Por ejemplo, si los
                                ecosistemas perdieran la capacidad de regular el ciclo del agua, o
                                si las abejas desaparecieran impidiendo la polinización natural de
                                las plantas, entonces la vida se haría más difícil para quienes la
                                habitamos, incluso desencadenando una crisis mundial a niveles
                                social y económico.
                            </p>

                            <p>
                                En 2005, la Evaluación de los Ecosistemas del Milenio definió a los
                                servicios de la naturaleza como “los beneficios que la gente obtiene
                                de los ecosistemas” y propuso una clasificación para ellos:
                                sostenedores, reguladores, provisionadores y culturales.
                            </p>
                            <ul className="list">
                                <li>
                                    <h4>
                                        <strong>Los servicios de provisión </strong>son los que
                                        brindan recursos de uso directo por los seres vivos, como
                                        agua, alimentos, madera, fibras vegetales, combustible,
                                        necesarios para las actividades diarias.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Los servicios de regulación</strong> son aquellos
                                        que mantienen en adecuado funcionamiento al planeta y sus
                                        recursos, como el control del clima, la circulación del agua
                                        y el control de la erosión.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Los servicios culturales</strong> son los que nos
                                        brindan valores espirituales, esparcimiento, educación. Por
                                        ejemplo, un paisaje bien conservado nos permite disfrutarlo
                                        en diversas actividades como el turismo y el deporte, así
                                        como es parte primordial para la religión de muchas
                                        personas.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Los servicios sostenedores</strong> se encargan del
                                        reciclaje de nutrientes, la formación de los suelos y la
                                        fotosíntesis. Como su nombre lo indica, son aquellos que se
                                        encargan de ‘sostener’ las funciones básicas del planeta.
                                    </h4>
                                </li>
                            </ul>
                            <p>
                                A pesar de su vital importancia, nuestra sociedad y nuestra economía
                                no reconoce los servicios que nos provee la naturaleza y por
                                consiguiente la mayoría de ellos están sufriendo un proceso de
                                degradación.
                            </p>
                            <p>
                                Existen formas de hacer que estos servicios se mantengan y la
                                naturaleza se restaure. A esto le llamamos esquemas de retribución o
                                incentivos por servicios ecosistémicos. Se trata de forjar alianzas
                                entre los usuarios de los servicios y aquellos que los mantienen a
                                través de su trabajo de conservación y de sistemas de producción
                                sostenible. El servicio que brinda Regenera es una forma de
                                retribución mediante la compensación de la huella de carbono.
                            </p>
                        </section>
                    </div>

                    <div id="reducirhuella" className={'sectionWhite'}>
                        <section className="sectionContent">
                            <h2>¿Cómo puedo reducir mi huella ambiental?</h2>
                            <p>
                                Existen muchas formas en las que podemos contribuir con la reducción
                                de nuestra huella ambiental. Algunas exigen un cambio profundo en
                                nuestros hábitos, mientras que otras requieren solo un poco de orden
                                en nuestra rutina. En términos generales, debemos revisar nuestro
                                patrón de consumo y hacerlo más eficiente.
                            </p>

                            <p>
                                A continuación, te presentamos una lista de cambios y acciones que
                                puedes adoptar para que tengas una huella más pequeña:
                            </p>
                            <ul className="list">
                                <li>
                                    <h3>Come menos carne</h3>Es difícil para muchos, pero toma en
                                    cuenta que la industria de la carne genera casi un tercio de los
                                    gases de efecto invernadero global. Llevar una vida vegetariana
                                    o vegana es lo más recomendable, pero si no estás dispuesto a
                                    dejar de comer carne, entonces trata de reducir su consumo a una
                                    o dos veces por semana, evitando lar carnes rojas que son las
                                    que tienen el impacto ambiental más negativo.
                                </li>
                                <li>
                                    <h3>Ahorra energía</h3>Evalúa tu consumo de energía en casa y en
                                    el trabajo. Usa tecnología LED para iluminar, desenchufa los
                                    electrodomésticos que no estás utilizando, evita mantener
                                    cargados los dispositivos que requieren de carga. Y si está
                                    dentro de tus posibilidades, adquiere fuente renovables de
                                    energía, como paneles solares. Si gerencias una empresa grande,
                                    averigua sobre energías renovables de tu proveedor eléctrico.
                                </li>
                                <li>
                                    <h3>Muévete sin motores</h3>El ahorro de los conbustibles
                                    fósiles es un punto importante en la reducción de nuestra huella
                                    ambiental. Si tienes un auto, utilízalo de forma responsable,
                                    organiza bien tu agenda diaria para evitar trayectos
                                    inncesarios. Utiliza gas natural de ser posible. Trata de
                                    moverte en bicicleta, camina más, utiliza el trasnporte público.
                                    Si no puedes prescindir del auto, organiza un carpool, es decir,
                                    movilízate en grupos, así ahorras combustible. Si eres un
                                    usuario frecuente de aviones, trata de reducir tus vuelos al
                                    máximo.
                                </li>
                                <li>
                                    <h3>Consume responsable</h3>Parte del problema actual es que
                                    nuestros patrones de consumo se han disparado, haciendo que las
                                    empresas produzcan cada vez más. Haz que tu moda sea usar la
                                    ropa y equipos hasta que se agoten, no cambiarlos si aun
                                    funcionan bien.
                                </li>
                                <li>
                                    <h3>Cuida lo que comes</h3>En promedio, una familia desperdicia
                                    el 30% de sus alimentos. Compra tus alimentos de forma
                                    responsable, cocina lo necesario. Porcionar tus alimentos te
                                    ayudará a minimizar las sobras. Verás como esto impacta de forma
                                    positiva en tu economía (y en el planeta).
                                </li>
                                <li>
                                    <h3>Compra local</h3>En la medida de lo posible, compra tus
                                    alimentos frescos, de preferencia de productores locales. En
                                    muchas ciudades existe una variada oferta de ferias que venden
                                    productos orgánicos, explora tu ciudad en su búsqueda.
                                </li>
                                <li>
                                    <h3>Elimina el plástico de un solo uso</h3>Botellas, sorbetes,
                                    bolsas, menaje, elimínalos de tu vida. Existen en el mercado
                                    numerosas alternativas para suplantarlos. Haz de esta práctica
                                    un hábito, es más fácil de lo que crees.
                                </li>
                                <li>
                                    <h3>Recicla</h3>Segrega tus residuos y recicla todo lo que
                                    puedas: plástico, papel, vidrio, pilas, artefactos eléctricos,
                                    baterías, todo tiene un punto de reciclaje en tu ciudad. Si
                                    deseas dar un paso más, fabrica compost con tus desechos
                                    orgánicos.
                                </li>
                                <li>
                                    <h3>Cuida el agua</h3>Este es el elemento primordial para la
                                    vida, y es el que más va a escasear conforme la crisis climática
                                    avance. Báñate solo por 5 minutos, cierra el grifo cuando te
                                    cepilles los dientes, cuando laves tu servicio, haz más
                                    eficiente tu sistema de lavado de ropa, revisa constantemente
                                    tus tuberías y sistemas de tu hogar y oficina en busca de
                                    posibles fugas.
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="trasnformasHuella">
                        <section className="sectionContent">
                            <h2>
                                ¿Cómo puedo transformar mi huella ambiental en acciones positivas?
                            </h2>
                            <p>
                                No es suficiente reducir nuestra huella ambiental, es clave también
                                realizar acciones concretas para tener un impacto neto positivo en
                                el planeta. Aquí algunas ideas para empezar:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h3>Planta árboles o ayuda a restaurar la naturaleza</h3>Los
                                    árboles son el mejor mecanismo para capturar carbono del aire.
                                    Busca en tu localidad organizaciones que apoyen la reforestación
                                    de espacios degradados y participa como voluntario. Si tienes el
                                    espacio suficiente, planta árboles en tu jardín, en la puerta de
                                    tu casa. Únete a tus vecinos y transforma tu barrio o vecindario
                                    con árboles. Además de la captura de carbono los árboles crean
                                    un microclima templado en los meses de calor, estabilizan el
                                    suelo, mejoran el ornato y criarlos te dará mucha alegría.
                                </li>
                                <li>
                                    <h3>Cuida el mar</h3>Sabemos que los océanos –que cubren el 71%
                                    de la superficie terrestre– están amenazados por la
                                    contaminación y el calentamiento global. Cuando visites la
                                    playa, no arrojes basura y deja el espacio que ocupaste lo más
                                    limpio posible. Recuerda que los desagües de las principales
                                    ciudades terminan en el mar, muchas veces sin tratamiento
                                    previo, así que piensa bien en lo que arrojas por los inodoros y
                                    los lavaderos. El aceite de cocina usado es un fuerte
                                    contaminante. Si puedes, participa de campañas de limpieza de
                                    playas, hay muchas iniciativas activas.
                                </li>
                                <li>
                                    <h3>
                                        Apoya áreas protegidas o protege naturaleza en tu localidad
                                    </h3>
                                    Si vives cerca a áreas naturales protegidas puedes ser
                                    guardaparque voluntario o participar de las actividades que las
                                    jefaturas del área organicen de acuerdo a tus habilidades o
                                    profesión, como vigilancia, restauración, monitoreo, entre
                                    otras. Desde las ciudades también podemos apoyar con trabajo
                                    voluntario en campañas específicas y cuando las visitamos como
                                    turistas respetando la naturaleza y las indicaciones de los
                                    guardaparques. Si en tu localidad hay áreas naturales sin
                                    protección o degradadas puedes unirte a tus vecinos para apoyar
                                    su cuidado o restauración.
                                </li>
                                <li>
                                    <h3>
                                        Escoge uno de los planes de Regenera para apoyar a
                                        pobladores rurales a cuidar sus tierras
                                    </h3>
                                    En todo el mundo, hay personas que están trabajando día a día
                                    por proteger y restaurar espacios naturales –bosques, humedales,
                                    praderas– que brindan servicios a la cuenca y al país donde se
                                    ubican. Regenera cuantifica estos servicios y conecta a estos
                                    pobladores y comunidades rurales con personas y empresas que
                                    quieren reconocer su esfuerzo.
                                </li>
                            </ul>
                        </section>
                    </div>

                    <div id="comofunciona" className={'sectionWhite'}>
                        <section className={'sectionContent'}>
                            <h2>¿Cómo funciona Regenera?</h2>
                            <p>
                                En Regenera trabajamos con aliados urbanos para medir, reducir y
                                compensar su huella ambiental, apoyando a guardianes rurales para
                                conservar y restaurar la naturaleza en el Perú. Así, contribuimos a
                                resolver el problema de la degradación de ecosistemas y el cambio
                                climático, en línea con las políticas y valores de nuestro país. El
                                80% del pago por compensación es dirigido a nuestros guardianes
                                rurales y aliados técnicos, divididos de esta manera:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong>
                                            60% para las comunidades y propietarios privados{' '}
                                        </strong>
                                        que realizan los trabajos de conservación, protección y
                                        restauración de sus tierras.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>
                                            20% para los aliados técnicos, organizaciones
                                        </strong>{' '}
                                        que trabajan de la mano con los guardianes y garantizan el
                                        cumplimiento de los acuerdos.
                                    </h4>
                                </li>
                            </ul>

                            <h3>Así trabajamos con los aliados urbanos</h3>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong>Conoce: </strong>ayudamos a personas, organizaciones
                                        y empresas a medir su huella de carbono, ya sea mediante una
                                        calculadora abierta o un inventario detallado de acuerdo a
                                        una metodología de medición adecuada a cada caso.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Mejora: </strong>sugerimos formas de reducir las
                                        emisiones, para luego compensar la huella residual con
                                        acciones de conservación y reforestación realizadas por
                                        nuestros guardianes.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Comparte: </strong>transferimos el 80% de los
                                        ingresos por compensación, sea personal o empresarial, a
                                        nuestros guardianes rurales.
                                    </h4>
                                </li>
                            </ul>

                            <h3>Así trabajamos con los guardianes rurales y aliados técnicos</h3>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong>Conoce: </strong>los guardianes rurales –que cuentan
                                        con títulos y derechos claros sobre sus territorios– son
                                        asesorados para conocer el carbono y otros activos naturales
                                        en sus ecosistemas.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Mejora: </strong>se apoyan acciones de organización,
                                        vigilancia, restauración y producción sostenible.
                                        Establecemos un esquema de incentivos por resultados de
                                        deforestación evitada y restauración de ecosistemas.
                                        Realizamos una evaluación mensual y anual del trabajo, lo
                                        cual se debate y aprueba en su Asamblea Comunal o de
                                        Asociados.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Comparte: </strong>el pago que reciben los
                                        guardianes se invierte en: (1) pago de salarios / jornales
                                        comunales, (2) prioridades comunales como educación, salud y
                                        energía, y (3) necesidades familiares según acuerdo de
                                        Asamblea.
                                    </h4>
                                </li>
                            </ul>

                            <h3>
                                Así nos alineamos con los Objetivos de Desarrollo Sostenible (ODS)
                            </h3>
                            <p>
                                Trabajar con Regenera es una forma concreta de trabajar hacia los
                                Objetivos de Desarrollo Sostenible de la ONU, pues estamos alineados
                                a alcanzar las siguientes metas:
                            </p>
                            <ul className={'list'}>
                                <li>
                                    <h4>
                                        <strong>Fin de la pobreza: </strong> estamos dando valor al
                                        capital natural renovable, un activo de las poblaciones
                                        rurales pobres.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Igualdad de género: </strong> trabajamos con
                                        comunidades y organizaciones donde se promueve la igualdad
                                        de género.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Agua limpia y saneamiento: </strong> contribuimos
                                        con la regulación y la seguridad hídrica en diversos
                                        paisajes.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Energía no contaminante: </strong> Promovemos el uso
                                        de energías renovables.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Trabajo decente y crecimiento económico: </strong>{' '}
                                        generamos puestos de trabajo para poblaciones rurales
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Acción por el clima: </strong> apoyamos a reducer
                                        emisiones y capturamos carbono en ecosistemas.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Vida submarina: </strong> protegemos ríos y lagos, y
                                        venimos iniciando trabajos en ecosistemas marino-costeros.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Vida terrestre: </strong> cuidamos más de 50,000
                                        hectareas bajo acuerdos de conservación y restauración.
                                    </h4>
                                </li>
                                <li>
                                    <h4>
                                        <strong>Alianzas para los ods: </strong> impulsamos alianzas
                                        público-privadas comunales.
                                    </h4>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </FaqFlow>
            <HelpBoxContainer bgGreenTexture={bgGreenTexture}>
                <div className="green-box flex justify-center flex-col mx-auto">
                    <div className="title-box text-center mx-auto">
                        <h3>Still need help?</h3>
                        <p>Contact a team member.</p>
                    </div>
                    <div className="btn-group flex justify-center flex-wrap mx-auto">
                        <OrangeBtn>
                            <Link to="#">Send an e-mail</Link>
                        </OrangeBtn>
                        <LightGreenBtn>
                            <Link to="#">Schedule a call</Link>
                        </LightGreenBtn>
                    </div>
                </div>
            </HelpBoxContainer>
            <Footer history={history} />
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(SpanishFaq));
