import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../theme';

export const TabsContainer=styled.div`
    text-align:center;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin: 36px 0px;
    @media screen and (max-width: 600px){
        display:none;
    }
`;

export const Span=styled.span`
    margin:0px 10px;
    color:${props => props.selected? '#F29509':'black'}
`;