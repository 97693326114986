import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as validators from '../../util/validators';
import { login, authenticationInProgress, signup, signUpWithGoogle } from '../../ducks/Auth.duck';
import ReCaptcha from 'react-google-recaptcha';
import { GoogleLogin } from 'react-google-login';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, NamedLink } from '../../components';
import css from './SignupForm.css';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isSignupEmailTakenError } from '../../util/errors';

const KEY_CODE_ENTER = 13;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;
const SignupFormComponent = props => {
    const { isAuthenticated, loginError, signupError, intl } = props;
    const [showError, setShowError] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [googleLoginFailed, setGoogleLoginFailed] = useState(false);
    const [regularAccError, setRegularAccError] = useState(false);
    const reCaptchaRef = useRef(null);

    const responseGoogle = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRef.current) {
                    captchaToken = await reCaptchaRef.current.executeAsync();
                    reCaptchaRef.current.reset();
                }
                await props.submitSignupWithGoogle({
                    tokenId: response.tokenId,
                    captchaToken,
                });
                setRegularAccError(false);
                setShowError(true);
                setScroll(true);
            } catch (error) {
                setGoogleLoginFailed(true);
                console.log({ 'Google Sign In Error': error });
            }
        }
    };
    return (
        <>
            <FinalForm
                {...props}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        formId,
                        handleSubmit,
                        inProgress,
                        invalid,
                        intl,
                        onOpenTermsOfService,
                        onOpenPrivacyPolicy,
                    } = fieldRenderProps;
                    console.log('fieldRenderProps', fieldRenderProps);
                    // email
                    const emailLabel = intl.formatMessage({
                        id: 'SignupForm.emailLabel',
                    });
                    const emailPlaceholder = intl.formatMessage({
                        id: 'SignupForm.emailPlaceholder',
                    });
                    const emailRequiredMessage = intl.formatMessage({
                        id: 'SignupForm.emailRequired',
                    });
                    const emailRequired = validators.required(emailRequiredMessage);
                    const emailInvalidMessage = intl.formatMessage({
                        id: 'SignupForm.emailInvalid',
                    });
                    const emailValid = validators.emailFormatValid(emailInvalidMessage);

                    // password
                    const passwordLabel = intl.formatMessage({
                        id: 'SignupForm.passwordLabel',
                    });
                    const passwordPlaceholder = intl.formatMessage({
                        id: 'SignupForm.passwordPlaceholder',
                    });
                    const passwordRequiredMessage = intl.formatMessage({
                        id: 'SignupForm.passwordRequired',
                    });
                    const passwordMinLengthMessage = intl.formatMessage(
                        {
                            id: 'SignupForm.passwordTooShort',
                        },
                        {
                            minLength: validators.PASSWORD_MIN_LENGTH,
                        }
                    );
                    const passwordMaxLengthMessage = intl.formatMessage(
                        {
                            id: 'SignupForm.passwordTooLong',
                        },
                        {
                            maxLength: validators.PASSWORD_MAX_LENGTH,
                        }
                    );
                    const passwordMinLength = validators.minLength(
                        passwordMinLengthMessage,
                        validators.PASSWORD_MIN_LENGTH
                    );
                    const passwordMaxLength = validators.maxLength(
                        passwordMaxLengthMessage,
                        validators.PASSWORD_MAX_LENGTH
                    );
                    const passwordRequired = validators.requiredStringNoTrim(
                        passwordRequiredMessage
                    );
                    const passwordValidators = validators.composeValidators(
                        passwordRequired,
                        passwordMinLength,
                        passwordMaxLength
                    );

                    // firstName
                    const firstNameLabel = intl.formatMessage({
                        id: 'SignupForm.firstNameLabel',
                    });
                    const firstNamePlaceholder = intl.formatMessage({
                        id: 'SignupForm.firstNamePlaceholder',
                    });
                    const firstNameRequiredMessage = intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                    });
                    const firstNameRequired = validators.required(firstNameRequiredMessage);

                    // lastName
                    const lastNameLabel = intl.formatMessage({
                        id: 'SignupForm.lastNameLabel',
                    });
                    const lastNamePlaceholder = intl.formatMessage({
                        id: 'SignupForm.lastNamePlaceholder',
                    });
                    const lastNameRequiredMessage = intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                    });
                    const lastNameRequired = validators.required(lastNameRequiredMessage);

                    const classes = classNames(rootClassName || css.root, className);
                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || submitInProgress;

                    // User type
                    const userTypeRequired = intl.formatMessage({
                        id: 'SignupForm.SelectOption',
                    });
                    const requiredUserType = validators.required(userTypeRequired);

                    const handleTermsKeyUp = e => {
                        // Allow click action with keyboard like with normal links
                        if (e.keyCode === KEY_CODE_ENTER) {
                            onOpenTermsOfService();
                            onOpenPrivacyPolicy();
                        }
                    };
                    const termsLink = (
                        <span
                            className={css.termsLink}
                            onClick={onOpenTermsOfService}
                            role="button"
                            tabIndex="0"
                            onKeyUp={handleTermsKeyUp}
                        >
                            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                        </span>
                    );

                    const privacyLink = (
                        <span
                            className={css.termsLink}
                            onClick={onOpenPrivacyPolicy}
                            role="button"
                            tabIndex="0"
                            onKeyUp={handleTermsKeyUp}
                        >
                            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
                        </span>
                    );
                    const linkText = (
                        <NamedLink name="LoginPage">
                            <FormattedMessage id="SignupForm.login.linkText" />
                        </NamedLink>
                    );
                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            <h2 style={{ fontSize: '34px', textAlign: 'center' }}>
                                <FormattedMessage id="SignupForm.title" />
                            </h2>
                            <p>
                                <FormattedMessage
                                    id="SignupForm.login.text"
                                    values={{ linkText }}
                                />
                            </p>
                            <div>
                                <FieldTextInput
                                    type="email"
                                    id={formId ? `${formId}.email` : 'email'}
                                    name="email"
                                    autoComplete="email"
                                    label={emailLabel}
                                    // placeholder={emailPlaceholder}
                                    validate={validators.composeValidators(
                                        emailRequired,
                                        emailValid
                                    )}
                                />
                                <div className={css.name}>
                                    <FieldTextInput
                                        className={css.firstNameRoot}
                                        type="text"
                                        id={formId ? `${formId}.fname` : 'fname'}
                                        name="fname"
                                        autoComplete="given-name"
                                        label={firstNameLabel}
                                        // placeholder={firstNamePlaceholder}
                                        validate={firstNameRequired}
                                    />
                                    <FieldTextInput
                                        className={css.lastNameRoot}
                                        type="text"
                                        id={formId ? `${formId}.lname` : 'lname'}
                                        name="lname"
                                        autoComplete="family-name"
                                        label={lastNameLabel}
                                        // placeholder={lastNamePlaceholder}
                                        validate={lastNameRequired}
                                    />
                                </div>
                                <FieldTextInput
                                    className={css.password}
                                    type="password"
                                    id={formId ? `${formId}.password` : 'password'}
                                    name="password"
                                    autoComplete="new-password"
                                    label={passwordLabel}
                                    placeholder={passwordPlaceholder}
                                    validate={passwordValidators}
                                />
                            </div>

                            <div className={css.bottomWrapper}>
                                <p className={css.bottomWrapperText}>
                                    <span className={css.termsText}>
                                        <FormattedMessage
                                            id="SignupForm.termsAndConditionsAcceptText"
                                            values={{ termsLink }}
                                        />

                                        <FormattedMessage
                                            id="SignupForm.privacyPoliceAcceptText"
                                            values={{ privacyLink }}
                                        />
                                    </span>
                                    <span className={css.termsText}>
                                        <FormattedMessage id="SignupForm.newsletterDisclaimerText" />
                                    </span>
                                </p>
                                <PrimaryButton
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    data-testid="btn-signup-form"
                                >
                                    <FormattedMessage id="SignupForm.signUp" />
                                </PrimaryButton>
                            </div>
                            <ReCaptcha
                                ref={reCaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                size="invisible"
                            />
                        </Form>
                    );
                }}
            />
            <div className={css.googleSignUp}>
                <h5>or</h5>
                {googleClientId && (
                    <GoogleLogin
                        className={css.googleButton}
                        clientId={googleClientId}
                        disabled={isAuthenticated}
                        buttonText={intl.formatMessage({
                            id: 'NewPartnersPage.Setup.Account.GoogleBtn',
                        })}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                )}
                {(signupError || loginError) && !regularAccError && showError && (
                    <div
                        style={{
                            fontSize: 16,
                            color: '#ff0000',
                            fontWeight: 500,
                            fontFamily: 'Galano Grotesque',
                        }}
                    >
                        {isSignupEmailTakenError(signupError) ? (
                            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                        ) : (
                            <FormattedMessage id="AuthenticationPage.signupFailed" />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),

    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
    fetchCurrentUser: params => dispatch(fetchCurrentUser()),
});

const mapStateToProps = state => {
    const { isAuthenticated, loginError, signupError } = state.Auth;
    const { currentUser } = state.user;
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        signupError,
    };
};

export default compose(injectIntl)(
    connect(mapStateToProps, mapDispatchToProps)(SignupFormComponent)
);
