/*
 * Marketplace specific configuration.
 */

export const species = [
  {
    key: 'colibri',
    label:'colibri'
  },
  {
    key: 'primate',
    label:'primate'
  },
  {
    key: 'aguilaArpia',
    label:'Aguila arpia'
  },
  {
    key: 'arahuana',
    label:'Arahuana'
  },
  {
    key: 'camaronDeRio',
    label:'camaron de rio'
  },
  {
    key: 'colibriDeRaquetas',
    label:'Colibri de raquetas'
  },
  {
    key: 'colibriDeVientreBlanco',
    label:'Colibri de vientre blanco'
  },
  {
    key: 'condorAndino',
    label:'Condor andino'
  },
  {
    key: 'mirloAcuático',
    label:'Mirlo acuático'
  },
  {
    key: 'nutriaDeRio',
    label:'nutria de rio'
  },
  {
    key: 'osoDeAnteojos',
    label:'oso de anteojos'
  },
  {
    key: 'tapir',
    label:'tapir'
  },
  {
    key: 'venadoDeColaBlanca',
    label:'venado de cola blanca'
  },
  {
    key: 'vicunas',
    label:'Vicuñas'
  },
  {
    key: 'guacamayoEscarlata',
    label:'guacamayo escarlata'
  },
  {
    key: 'jaguar',
    label:'jaguar'
  },
  {
    key: 'loboDeRio',
    label:'lobo de rio'
  },
]

export const guardians = [
  {
    key: 'ccArin',
    label:'CC Arin'
  },
  {
    key: 'ccKiunalla',
    label:'CC Kiunalla'
  },
  {
    key: 'ccLaraos',
    label:'CC Laraos'
  },
  {
    key: 'ccOllantaytambo',
    label:'CC Ollantaytambo'
  },
  {
    key: 'ccPiscacucho',
    label:'CC Piscacucho'
  },
  {
    key: 'ccTanta',
    label:'CC Tanta'
  },
  {
    key: 'cnNuevaEsperanza',
    label:'CC Nueva Esperanza'
  },
  {
    key: 'cnWicungo',
    label:'CN Wicungo'
  },
  {
    key: 'comunidadNativaShipetari',
    label:'Comunidad Nativa Shipetari'
  },
  {
    key: 'PalotoaTeparo',
    label:'Palotoa Teparo'
  },
  {
    key: 'FamiliaChallco',
    label:'Familia Challco'
  },
  {
    key: 'FamiliaIsidro',
    label:'Familia Isidro'
  },
  {
    key: 'IslaDeLosValles',
    label:'Isla de los Valles'
  },
  {
    key: 'CREES',
    label:'CREES'
  },
  {
    key: 'cedia',
    label:'CEDIA'
  },
  {
    key: 'fecoritayb',
    label:'FECORITAYB'
  },
  {
    key: 'cnMatoriato',
    label:'cnMatoriato'
  },
  {
    key: 'SanDiegoZoo',
    label:'San Diego Zoo'
  },
  {
    key: 'SePeru',
    label:'SePeru'
  },
  {
    key: 'cedes',
    label:'CEDES'
  },
  {
    key: 'helvetasPeru',
    label:'Helvetas Peru'
  },
]

export const activities = [
  {
    key: 'acuicultura',
    label:'Acuicultura'
  },
  {
    key: 'agriculturaOrganica',
    label:'Agricultura organica'
  },
  {
    key: 'artesanias',
    label:'Artesanias'
  },
  {
    key: 'artesaniaManu',
    label:'Artesania en Manu'
  },
  {
    key: 'turismoManu',
    label:'Turismo en Manu'
  },
  {
    key: 'agriculturaManu',
    label:'Agricultura en Manu'
  },
  {
    key: 'ganaderiaAlpaquera',
    label:'Ganaderia alpaquera'
  },
  {
    key: 'manejoForestal',
    label:'Manejo forestal'
  },
  {
    key: 'manejoPesquero',
    label:'Manejo pesquero'
  },
  {
    key: 'turismo',
    label:'Turismo'
  },
  {
    key: 'turismoComunitario',
    label:'Turismo comunitario'
  },
]

export const allies = [
  {
    key: 'afpPrima',
    label:'Prima AFP'
  },
  {
    key: 'AmazonasExplorer',
    label:'Amazonas Explorer'
  },
  {
    key: 'AndeanLodges',
    label:'Andean Lodges'
  },
  {
    key: 'auqui',
    label:'AUQUI'
  },

  {
    key: 'cientifica',
    label:'Cientifica'
  },
  {
    key: 'explorandes',
    label:'Explorandes'
  },
  {
    key: 'gerpal',
    label:'Gerpal'
  },
  {
    key: 'hoseg',
    label:'hoseg'
  },
  {
    key: 'patronatoNYC',
    label:'Patronato NYC'
  },
  {
    key: 'pukanina',
    label:'Pukanina'
  },
  {
    key: 'strategik',
    label:'Strategik'
  },
  {
    key: 'FloraYFauna',
    label:'Flora y Fauna'
  },
  {
    key: 'peru2021',
    label:'peru 2021'
  },
  {
    key: 'Rainforest',
    label:'Rainforest'
  },
]

export const amenities = [
  {
    key: 'towels',
    label: 'Towels',
  },
  {
    key: 'bathroom',
    label: 'Bathroom',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming pool',
  },
  {
    key: 'own_drinks',
    label: 'Own drinks allowed',
  },
  {
    key: 'jacuzzi',
    label: 'Jacuzzi',
  },
  {
    key: 'audiovisual_entertainment',
    label: 'Audiovisual entertainment',
  },
  {
    key: 'barbeque',
    label: 'Barbeque',
  },
  {
    key: 'own_food_allowed',
    label: 'Own food allowed',
  },
];

export const staticProfilePhotos = [
  {
    key: 'cedesPhotos',
    label: 'Fotos de cedes',
  },
  {
    key: 'cediaPhotos',
    label: 'Fotos de cedia',
  },
  {
    key: 'patronatoPhotos',
    label: 'Fotos de Patronato',
  },
  {
    key: 'seperuPhotos',
    label: 'Fotos de seperu',
  },
  {
    key: 'amazonasExplorerPhotos',
    label: 'Fotos de Amazonas Explorer',
  },
  {
    key: 'auquiPhotos',
    label: 'Fotos de Auqui',
  },
  {
    key: 'anyniPhotos',
    label: 'Fotos de Ayni',
  },
  {
    key: 'cientificaPhotos',
    label: 'Fotos de Universidad Científica',
  },
  {
    key: 'elalberguePhotos',
    label: 'Fotos de El Albergue',
  },
  {
    key: 'explorandesPhotos',
    label: 'Fotos de Explorandes',
  },
  {
    key: 'florayfaunaPhotos',
    label: 'Fotos de Flora y fauna',
  },
  {
    key: 'gerpalPhotos',
    label: 'Fotos de Gerpal',
  },
  {
    key: 'hosegPhotos',
    label: 'Fotos de Hoseg',
  },
  {
    key: 'peru2021Photos',
    label: 'Fotos de Peru2021',
  },
  {
    key: 'perugbcPhotos',
    label: 'Fotos de Peru GBC',
  },
  {
    key: 'primaafpPhotos',
    label: 'Fotos de Prima AFP',
  },
  {
    key: 'pukaninaPhotos',
    label: 'Fotos de Puka Nina',
  },
  {
    key: 'rainforestPhotos',
    label: 'Fotos de Rain Forest',
  },
  {
    key: 'sistemabPhotos',
    label: 'Fotos de Sistema B',
  },
  {
    key: 'strategikPhotos',
    label: 'Fotos de Strategik',
  },
  {
    key: 'tedPhotos',
    label: 'Fotos de TED',
  },
  {
    key: 'andeanPhotos',
    label: 'Fotos de Andean Lodges',
  },
  {
    key: 'creesPhotos',
    label: 'Fotos de CREES',
  },
  {
    key: 'islavallesPhotos',
    label: 'Fotos de Isla Valles',
  },
  {
    key: 'kiunallaPhotos',
    label: 'Fotos de kiuñalla',
  },
  {
    key: 'nuevaesperanzaPhotos',
    label: 'Fotos de Nueva esperanza',
  },
  {
    key: 'paloateparoPhotos',
    label: 'Fotos de Paloa Teparo',
  },
  {
    key: 'shipetiariPhotos',
    label: 'Fotos de Shipetiari',
  },
  {
    key: 'wicungoPhotos',
    label: 'Fotos de Wicungo',
  },
]

export const staticmaps = [
  {
    key: 'abancay',
    label: 'Abancay',
  },
  {
    key: 'canete',
    label: 'Cañete',
  },
  {
    key: 'manu',
    label: 'Manu',
  },
  {
    key: 'tapiche',
    label: 'Tapiche',
  },
  {
    key: 'urubamba',
    label: 'Urubamba',
  },
];

export const categories = [
  { key: 'smoke', label: 'Smoke' },
  { key: 'electric', label: 'Electric' },
  { key: 'wood', label: 'Wood' },
  { key: 'other', label: 'Other' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: false,
};
