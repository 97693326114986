import styled from 'styled-components';
import { colors } from '../../../../../theme';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';
import {
    Holder,
    InputButton,
    Dropdown,
    DropdownItem,
  } from "../../../components/ProfileForm/Styles"

export const ProfileAndAccountMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3D6;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;
export const InputPassword = styled.div`
    position: relative;
`;
export const Eye = styled.button`
    outline: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 10px;
`;
export const AccountForm = styled.div`
    margin: 35px 20px 35px 20px;
`;
export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-size: ${props => (props.light ? '19px' : '24px')};
    font-weight: ${props => (props.light ? '500' : '700')};
    margin: 15px;
    color: ${props => (props.light ? '#C5C5C5' : 'black')};
`;
export const SubTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-size: ${props => (props.small ? '14px' : '19px')};
    font-weight: 500;
    margin: 20px 0px 15px 45px;
    position: ${props => (props.small ? 'relative' : '')};
    top: ${props => (props.small ? '-40px' : '')};
    left: ${props => (props.small ? '40%' : '')};
    width: ${props => (props.small ? '330px' : '')};
    @media screen and (max-width: 768px) {
        margin: 20px 0px 15px 10px;
        top: ${props => (props.small ? '0px' : '')};
        left: ${props => (props.small ? '0px' : '')};
        width: ${props => (props.small ? '200px' : '')};
    }
`;
export const AccountContainer = styled.div`
    padding: 15px 10px 15px 10px;
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        height: auto;
    }
`;
export const PasswordTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 23.1858px;
    line-height: 31px;
    color: #F29509;
    padding:10px;
    margin:45px 10px 10px 80px;
    @media screen and (max-width: 768px) {
        height: auto;
        margin: 5px 16px;
    }
`;
export const PasswordForm = styled.div`
    margin: 20px auto;
    width: 92%;
    background-color: #ffffff;
`;
export const PasswordDescription = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 16.1858px;
    line-height: 25px;
    color: #000000;
    width:50%;
    margin: 45px 10px 45px 80px;
    box-sizing:border-box;
    @media screen and (max-width: 768px) {
        width:90%;
        margin: 5px 16px;
        text-align: left;
    }
`;
export const PasswordContainer = styled.div`
    display:flex;
    padding: 15px 10px 15px 10px;
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;

export const Label = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
`;

export const Form = styled.div`
    display: flex;
    margin: 10px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;
export const NewDiv = styled.div`
    display: flex;
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;
export const Row1 = styled.div`
    display: flex;
    flex: 2;
    order: 1;
    justify-content: space-between;
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;
export const FirstName = styled.div`
    margin-right: 20px;
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        margin-right: 0px;
    }
`;
export const LastName = styled.div`
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
`;
export const Row2 = styled.div`
    display: flex;
    flex: 2;
    order: 1;
    justify-content: space-between;
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;
export const Email = styled.div`
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        margin-right: 0px;
    }
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
`;
export const Number = styled.div`
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
  & > input:focus, input:hover {
    border-color: ${colors.pink} !important;
    }
`}
`;
export const City = styled.div`
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        margin-right: 0px;
    }
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
`;
export const Country = styled.div`
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
  & > input:focus, input:hover {
    border-color: ${colors.pink} !important;
    }
`}
`;
export const Row3 = styled.div`
    display: flex;
    flex: 2;
    order: 2;
    justify-content: space-between;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
`;
export const Row4 = styled.div`
    width: 500px;
    display: flex;
    order: 4;
    flex-direction: column;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    & > input:focus,
    input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
    & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
`;

export const PhotoUpload = styled.div`
    margin: 0 auto;
    cursor: pointer;
`;
export const Input = styled.input`
    padding: 10px;
    border: 1px solid #c5c5c5;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 57px;
`;
export const Message = styled.p`
    font-size: 17px;
    font-weight: 500;
    font-family: 'Galano Grotesque';
    color: ${colors.white};
    margin: 0px;
`;
export const ErrorMessage = styled(Message)`
    color: red;
    position: relative;
`;

export const ButtonContainer = styled.div`
    position: relative;
    width: 300px;

    & > button {
        max-width: 300px;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        margin: 0 auto;
        width: 95%;
    }
`;

export const PasswordFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: self;
    width:50%;
    margin: 45px;
    @media screen and (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }
`;
export const Row = styled.div`
    display: flex;
    flex-direction: ${(props) => props.column ? 'column' : 'row'};
    margin-bottom: 30px;
    @media screen and (max-width: 500px) {
        margin: 10px;
        flex-direction: column;
    }
`;
export const Password = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    @media screen and (max-width: 500px) {
        margin-right: 0px;
    }
`;
export const ReEnterPassword = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;
export const ConfirmPassword = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const Element = styled.div`
    margin: 35px auto;
    width: 92%;
`;
export const ElementRow = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

export const PaymentHistory = styled.div`
    display: none;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 45px;
    @media screen and (max-width: 500px) {
        padding: 10px;
    }
`;
export const IncreaseImpact = styled.div`
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 45px;
    @media screen and (max-width: 500px) {
        padding: 10px;
    }
`;
export const Para = styled.div`
    font-size: 19px;
    font-weight: 500;
    font-family: 'Galano Grotesque';
    padding: 12px;
    line-height: 25.65px;
    margin-top: 30px;
`;
export const ElementButtonContainer1 = styled.div`
    width: 100%;
    margin-left: 15px;
`;
export const ElementButtonContainer2 = styled.div`
    margin-top: 20px;
    width: 100%;
    margin-left: 15px;
`;

export const Story = styled.div`
    margin: 35px auto;
    width: 92%;
`;
export const TextArea = styled.textarea`
    margin-bottom: 20px;
    padding: 15px;
    width: 95%;
    height: ${props => (props.small ? '40px' : '120px')};
    border: 1px solid #c5c5c5;
    @media screen and (max-width: 500px) {
        width: 88%;
        margin-left: 3%;
    }
`;
export const Content = styled.div`
    padding: 45px;
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 500px) {
        padding: 10px;
        height: auto;
    }
`;
export const File = styled.div`
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    @media screen and (max-width: 500px) {
        padding: 10px;
        height: auto;
    }
`;
export const UploadContainer = styled.div`
    margin: 15px;
    height: 440px;
    border-radius: 10px;
    border: 3px dotted #c5c5c5;
    @media screen and (max-width: 500px) {
        padding: 10px;
        height: auto;
    }
`;

export const Work = styled.div`
    margin: 35px auto;
    width: 92%;
`;
export const Footer = styled.div`
    margin: 35px 20px 35px 20px;
    height: auto;
    @media screen and (max-width: 500px) {
        margin: 0 auto;
        width: 100%;
        height: 380px;
        padding: 20px;
    }
`;
export const CardDetailsForm = styled.div`
    margin: 35px auto;
    width: 92%;
`;
export const CardContainer = styled.div`
    padding: 35px 30px 35px 30px;
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 500px) {
        padding: 20px 25px 10px 25px;
    }
`;

export const ImageDisplay = styled.img`
    margin: 0 auto;
    width: 159px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid #c5c5c5;
`;

export const InterestButton = styled.button`
    background-color: ${props => (props.selected ? '#FFC8A6' : '#ffffff')};
    width: fit-content;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
`;
export const ImgContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -130px;
    align-items: center;
    @media screen and (max-width: 500px) {
        top: -310px;
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const ImgText = styled.div`
    font-family: Galano Grotesque;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    z-index: 4;
    width: 40%;
    margin-left: 20px;
    @media screen and (max-width: 500px) {
        font-size: 34px;
    }
`;
export const ImgBtn = styled.div`
    margin-right: 20px;
    @media screen and (max-width: 500px) {
        margin-left: 20px;
        margin-top: 10px;
    }
`;

export const StyledSuccessMessage = styled.p`
    font-size: 17px;
    font-weight: 500;
    font-family: 'Galano Grotesque';
    color: #000000;
    line-height: 17px;
`;

export const ImageContainer = styled.div`
    display: flex;
    margin: 0px auto 20px auto;
    width: 100%;
    height: 145px;
    .imagebox {
        position: relative;
        height: 100%;
        & > svg {
            cursor: pointer;
            position: absolute;
            top: 0%;
            right: 5%;
        }
    }
    @media screen and (max-width: 568px) {
        flex-direction: column;
        height: auto;
        .imagebox {
            & > svg {
                right: 0%;
            }
        }
    }
`;
export const Image = styled.img`
    width: 95%;
    height: inherit;
    margin-right: 10px;
    object-fit: fill;
    @media screen and (max-width: 568px) {
        margin-right: 0px;
        margin-bottom: 10px;
        width: 100%;
    }
`;

export const FileImage = styled.div`
    background-color: #ffffff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
    @media screen and (max-width: 500px) {
        padding: 10px;
        height: auto;
    }
`;
export const UploadContainerImage = styled.div`
    margin: 15px;
    height: 440px;
    border-radius: 10px;
    border: 3px dotted #c5c5c5;
    @media screen and (max-width: 500px) {
        padding: 10px;
        height: auto;
    }
`;
export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 140px auto;
`;

export const ErrorDiv = styled.div`
    color: red;
    width: fit-content;
    padding: 10px;
    border: 1px solid red;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const LanguageSelectionContainer = styled(PasswordForm)``;
export const LanguageSelectionContent = styled(PasswordContainer)``;
export const LanguageSelectionTitle = styled(PasswordTitle)``;
export const LanguageSelectionDescription = styled(PasswordDescription)``;
export const LanguagePreferencesContainer = styled(PasswordFormContainer)`
    margin: 30px 45px;
    @media screen and (max-width: 768px) {
        margin: 30px 5px 5px 20px;
        width: 100%;
    }
    @media screen and (max-width: 400px) {
        margin: 5px;
        width: 100%;
    }
`;

export const DropdownHolder = styled(Holder)`
   max-width: 300px; 
`;
export const DropdownInputButton = styled(InputButton)`
    margin: 5px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    & span {
        width: 80%;
    }
    .arrow {
        position: relative;
        top: auto;
        right: auto;
        fill: #a9a9a9;
    }
`;
export const LanguageDropdown = styled(Dropdown)``;
export const LanguageDropdownItem = styled(DropdownItem)``;
export const LanguageButton = styled(ButtonContainer)`
    @media screen and (max-width: 768px) {
        margin: 0 ;
        width: 100%;
    }
`;