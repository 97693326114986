import React, { useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form as FinalForm, Field } from 'react-final-form';
import {
    Form,
    NamedLink,
    IconEmailAttention,
    IconEmailSuccess,
    PrimaryButton,
} from '../../components';
import { propTypes } from '../../util/types';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import css from './EmailVerificationForm.css';
const SHOW_EMAIL_SENT_TIMEOUT = 2000;
const EmailVerificationFormComponent = props => {
    const [showVerificationEmailSentMessage, setShowVerificationEmailSentMessage] = useState(false);

    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    currentUser,
                    inProgress,
                    handleSubmit,
                    onResendVerificationEmail,
                    verificationError,
                    sendVerificationEmailError,
                    sendVerificationEmailInProgress,
                } = formRenderProps;

                const { email, emailVerified, pendingEmail, profile } = currentUser.attributes;
                const emailToVerify = <strong>{pendingEmail || email}</strong>;
                const name = profile.firstName;

                const errorMessage = (
                    <div className={css.error}>
                        <FormattedMessage id="EmailVerificationForm.verificationFailed" />
                    </div>
                );

                const submitInProgress = inProgress;
                const submitDisabled = submitInProgress;

                const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
                    sendVerificationEmailError
                );
                const handleResendVerificationEmail = () => {
                    setShowVerificationEmailSentMessage(true);

                    onResendVerificationEmail().then(() => {
                        // Show "verification email sent" text for a bit longer.
                        const emailSentTimeoutId = window.setTimeout(() => {
                            setShowVerificationEmailSentMessage(false);
                        }, SHOW_EMAIL_SENT_TIMEOUT);

                        // Clear the timeout when the component unmounts or when the effect runs again.
                        return () => clearTimeout(emailSentTimeoutId);
                    });
                };

                let resendEmailMessage = null;
                if (tooManyVerificationRequests) {
                    resendEmailMessage = (
                        <span className={css.tooMany}>
                            <FormattedMessage id="EmailVerificationForm.tooManyVerificationRequests" />
                        </span>
                    );
                } else {
                    resendEmailMessage = (
                        <div className={css.bottomWrapper}>
                            <PrimaryButton
                                type="button"
                                onClick={handleResendVerificationEmail}
                                disabled={
                                    sendVerificationEmailInProgress ||
                                    showVerificationEmailSentMessage
                                }
                            >
                                <FormattedMessage id="EmailVerificationForm.resendEmailVerificationText" />
                            </PrimaryButton>
                            <div>
                                {sendVerificationEmailInProgress ||
                                showVerificationEmailSentMessage ? (
                                    <p className={css.modalMessage}>
                                        <FormattedMessage id="EmailVerificationForm.emailSent" />
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    );
                }

                const verifyEmail = (
                    <div className={css.root}>
                        <div>
                            <IconEmailAttention className={css.modalIcon} />
                            <h1 className={css.modalTitle}>
                                <FormattedMessage id="EmailVerificationForm.verifyEmailAddress" />
                            </h1>

                            <p className={css.modalMessage}>
                                <FormattedMessage
                                    id="EmailVerificationForm.finishAccountSetup"
                                    values={{ email: emailToVerify }}
                                />
                            </p>

                            {verificationError ? errorMessage : null}
                        </div>
                        {verificationError ? (
                            <div>{resendEmailMessage}</div>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Field component="input" type="hidden" name="verificationToken" />

                                <div className={css.bottomWrapper}>
                                    <PrimaryButton
                                        type="submit"
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                    >
                                        {inProgress ? (
                                            <FormattedMessage id="EmailVerificationForm.verifying" />
                                        ) : (
                                            <FormattedMessage id="EmailVerificationForm.verify" />
                                        )}
                                    </PrimaryButton>
                                </div>
                            </Form>
                        )}
                    </div>
                );

                const alreadyVerified = (
                    <div className={css.root}>
                        <div>
                            <IconEmailSuccess className={css.modalIcon} />
                            <h1 className={css.modalTitle}>
                                <FormattedMessage
                                    id="EmailVerificationForm.successTitle"
                                    values={{ name }}
                                />
                            </h1>

                            <p className={css.modalMessage}>
                                <FormattedMessage id="EmailVerificationForm.successText" />
                            </p>
                        </div>

                        <div className={css.bottomWrapper}>
                            <NamedLink className={css.submitButton} name="JoinUse">
                                <FormattedMessage id="EmailVerificationForm.successButtonText" />
                            </NamedLink>
                        </div>
                    </div>
                );

                return emailVerified && !pendingEmail ? alreadyVerified : verifyEmail;
            }}
        />
    );
};

EmailVerificationFormComponent.defaultProps = {
    currentUser: null,
    inProgress: false,
    verificationError: null,
};

EmailVerificationFormComponent.propTypes = {
    inProgress: bool,
    currentUser: propTypes.currentUser.isRequired,
    verificationError: propTypes.error,
};

const EmailVerificationForm = compose(injectIntl)(EmailVerificationFormComponent);

export default EmailVerificationForm;
