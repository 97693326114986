import React, { useState, useRef } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import ReCaptcha from 'react-google-recaptcha';

import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { authenticationInProgress, signup, signUpWithGoogle } from '../../ducks/Auth.duck';
import config from '../../config';
import { fetchCurrentUser, sendVerificationEmail } from '../../ducks/user.duck';

import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import {
    isSignupEmailTakenError,
    isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import * as coreAPI from '../../coreApi';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';

import { connect } from 'react-redux';
import { Page, NamedLink, NamedRedirect, IconEmailSent } from '../../components';
import TextField from '../../newComponents/Form/TextField';

import { signupInitialValues } from './SignupInitialValues';
import { signupValScheme } from './SignupValScheme';

import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import CheckBoxInfo from '../../newComponents/checkbox';
import leftLeafSignup from '../../assets/newAssets/left-leaf-signup.webp';
import rightLeafSignup from '../../assets/newAssets/right-leaf-signup.webp';
import titleBorderLine from '../../assets/newAssets/title-border-line.svg';
import bkgImage from '../../assets/mainhero.jpg';
import { GoogleLogin } from 'react-google-login';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SignupSection = styled.section`
    .left-section {
        width: 100%;
        max-width: 36%;
        padding: 60px 30px 70px 80px;
        background-color: ${({ theme }) => theme.colors.title_green};
        background-image: url(${({ leftLeafSignup }) => leftLeafSignup});
        background-repeat: no-repeat;
        background-position: left bottom;

        background-image: url(${({ leftLeafSignup }) => leftLeafSignup}),
            url(${({ bkgImage }) => bkgImage});
        background-repeat: no-repeat, no-repeat;
        background-position: left bottom, -950px 0;

        h1 {
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
        }
        .border {
            position: relative;
            img {
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
            }
        }
        .circle-box {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            border: 3px solid white;
            overflow: hidden;
            bottom: 10px;
            left: 70px;
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
    .googleSignUp {
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    .googleSignUp h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    .googleSignUp > button {
        width: 100%;
        border-radius: 15px;
    }
    .form-box {
        flex: 1 1 auto;
        padding: 80px 5% 70px;
        box-sizing: content-box;
        position: relative;
        max-width: 420px;
        .form-title {
            font-size: 19px;
            line-height: 27px;
            font-weight: 600;
            margin-bottom: 27px;
        }
        .form-section {
            gap: 16px;
        }
        .cta {
            padding: 0 14px;
            max-width: 305px;
            margin-top: 20px;
            margin-bottom: 7px;
            text-align: center;
            button {
                width: 100%;
            }
            .login-cta {
                line-height: 150%;
                margin-top: 27px;
                a {
                    font-weight: 600;
                    color: ${({ theme }) => theme.colors.title_green};
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .left-section {
            max-width: 100%;
            background-image: url(${({ rightLeafSignup }) => rightLeafSignup});
            background-position: right -40px;
            padding: 28px 10px;
            margin-top: 65px;
            h1 {
                font-size: 42px;
                line-height: 53px;
                text-align: center;
                br {
                    display: none;
                }
            }
        }
        .form-box {
            padding-top: 15px;
            .form-title {
                font-size: 16px;
                line-height: 180%;
                margin-bottom: 10px;
            }
            .cta {
                .login-cta {
                    margin-top: 10px;
                }
            }
        }
        .form-section {
            gap: 24px;
        }
    }
`;

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;

const Signup = ({
    location,
    history,
    user,
    currentUser,
    isAuthenticated,
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    submitSignup,
    submitSignupWithGoogle,
    onResendVerificationEmail,
    fetchCurrentUser,
    intl,
}) => {
    const reCaptchaRefSignup = useRef(null);
    const reCaptchaRefSignupNewsletter = useRef(null);
    const formikRef = useRef(); // Create a ref to access Formik's methods

    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(true);
    const [regularAccError, setRegularAccError] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const [googleLoginFailed, setGoogleLoginFailed] = useState(false);

    const from = location.state && location.state.from ? location.state.from : null;
    const userLoaded = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!userLoaded.id;

    const showEmailVerification = currentUserLoaded && !userLoaded.attributes.emailVerified;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
        return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
        return <NamedRedirect name="LandingPage" />;
    }

    const togglepassword = () => {
        setShowPassword(!showPassword);
    };

    const currentLocale = intl.locale;

    const signupErrorMessage = (
        <div className="error-text small">
            {isSignupEmailTakenError(signupError) ? (
                <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
            ) : (
                <FormattedMessage id="AuthenticationPage.signupFailed" />
            )}
        </div>
    );

    const signupErrorDisplay = signupError || googleLoginFailed ? signupErrorMessage : null;

    const consentLabel = intl.formatMessage({
        id: 'SignupForm.NewsletterForm.Consent',
    });

    const checkCaptchaNewsLetter = async restValues => {
        let captchaToken;
        if (reCaptchaRefSignupNewsletter.current) {
            captchaToken = await reCaptchaRefSignupNewsletter.current.executeAsync();
            reCaptchaRefSignupNewsletter.current.reset();
        }

        if (!!captchaToken && !!restValues) {
            try {
                await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: restValues.firstName,
                        lastname: restValues.lastName,
                        email: restValues.email,
                        language: intl.locale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
            } catch (e) {
                console.log(e);
            }
        }
    };
    const validationSchema = signupValScheme;
    const onSubmit = async values => {
        const { ...restValues } = values;

        try {
            setShowError(false);

            if (isChecked) {
                await checkCaptchaNewsLetter(restValues);
            }

            let captchaToken;
            if (reCaptchaRefSignup.current) {
                captchaToken = await reCaptchaRefSignup.current.executeAsync();
                reCaptchaRefSignup.current.reset();
            }

            if (!!captchaToken && !!restValues) {
                await submitSignup({ ...restValues, captchaToken });

                await fetchCurrentUser();
            } else {
                console.error('Failed to generate captchaToken');

                return; // Exit early if captchaToken is undefined
            }
        } catch (err) {
            console.log(err);
        } finally {
            setRegularAccError(true);
            setShowError(true);
        }
    };

    const responseGoogle = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRefSignup.current) {
                    captchaToken = await reCaptchaRefSignup.current.executeAsync();
                    reCaptchaRefSignup.current.reset();
                }
                await submitSignupWithGoogle({
                    tokenId: response.tokenId,
                    captchaToken,
                });
            } catch (error) {
                console.log({ 'Google Sign In Error': error });
            } finally {
                setRegularAccError(false);
                setShowError(true);
            }
        }
    };

    //////////// email verification section /////////////

    const name = userLoaded.attributes.profile.firstName;
    const email = <span>{userLoaded.attributes.email}</span>;

    const resendEmailLink = (
        <OrangeBtn onClick={onResendVerificationEmail}>
            <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
        </OrangeBtn>
    );
    const fixEmailLink = (
        <NamedLink name="ContactDetailsPage">
            <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
        </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
        sendVerificationEmailError
    )
        ? 'AuthenticationPage.resendFailedTooManyRequests'
        : 'AuthenticationPage.resendFailed';

    const resendErrorMessage = sendVerificationEmailError ? (
        <p className="error-text small">
            <FormattedMessage id={resendErrorTranslationId} />
        </p>
    ) : null;

    const emailVerificationContent = (
        <div>
            <IconEmailSent />
            <h1>
                <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
            </h1>
            <p>
                <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
            </p>
            {resendErrorMessage}

            <div>
                <p>
                    {sendVerificationEmailInProgress ? (
                        <FormattedMessage id="AuthenticationPage.sendingEmail" />
                    ) : (
                        <FormattedMessage
                            id="AuthenticationPage.resendEmail"
                            values={{ resendEmailLink }}
                        />
                    )}
                </p>
                <p>
                    <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
                </p>
            </div>
        </div>
    );

    ////////////////////////////////////////////////////////

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
        { id: 'AuthenticationPage.schemaTitleSignup' },
        { siteTitle }
    );

    const initialValues = { ...signupInitialValues };
    console.log('currentUserDetails', signupInitialValues, initialValues);

    ///

    // firstName
    const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
    });
    const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
    });

    // lastName
    const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
    });
    const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
    });

    const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
    });

    // password
    const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
    });
    const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
    });

    ///

    const termsLink = (
        <Link to="/terms-of-service" target="_blank">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </Link>
    );

    const privacyLink = (
        <Link to="/privacy-policy" target="_blank">
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </Link>
    );

    const linkText = (
        <NamedLink name="LoginPage">
            <FormattedMessage id="SignupForm.login.linkText" />
        </NamedLink>
    );

    return (
        <Page
            title={schemaTitle}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                name: schemaTitle,
            }}
        >
            <Header currentPath={location.pathname} user={user} history={history} />
            <SignupSection
                className="flex flex-wrap"
                leftLeafSignup={leftLeafSignup}
                rightLeafSignup={rightLeafSignup}
                bkgImage={bkgImage}
            >
                <div className="left-section relative">
                    <h1 className="text-white">
                        <FormattedHTMLMessage
                            id="AuthenticationPage.hero.title"
                            values={{
                                img: `${titleBorderLine}`,
                            }}
                        />
                    </h1>
                </div>
                <div className="form-box mx-auto flex flex-col">
                    {showEmailVerification ? (
                        emailVerificationContent
                    ) : (
                        <>
                            {signupError && regularAccError && showError && (
                                <div className="error-text small">
                                    {isSignupEmailTakenError(signupError) ? (
                                        <>
                                            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                                        </>
                                    ) : (
                                        <FormattedMessage id="AuthenticationPage.signupFailed" />
                                    )}
                                </div>
                            )}
                            <div className="form-title text-center">
                                <FormattedMessage id="SignupForm.signUp" />
                            </div>
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                validateOnMount={true}
                            >
                                {formik => {
                                    return (
                                        <Form onChange={() => setShowError(false)}>
                                            <ReCaptcha
                                                ref={reCaptchaRefSignup}
                                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                size="invisible"
                                            />
                                            <ReCaptcha
                                                ref={reCaptchaRefSignupNewsletter}
                                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                size="invisible"
                                            />
                                            <div className="form-section flex flex-col">
                                                <div className="two-col flex space-between">
                                                    <div className="input-box-section">
                                                        <TextField
                                                            type="text"
                                                            label={firstNameLabel}
                                                            name="firstName"
                                                            placeholder={firstNamePlaceholder}
                                                            aria-describedby="firstnamehelp"
                                                            value={formik.values.firstName}
                                                            className={`form-control  ${formik
                                                                .touched.firstName &&
                                                                formik.errors.firstName &&
                                                                'input-error'}`}
                                                            disabled={isAuthenticated}
                                                        />
                                                    </div>
                                                    <div className="input-box-section">
                                                        <TextField
                                                            type="text"
                                                            label={lastNameLabel}
                                                            name="lastName"
                                                            placeholder={lastNamePlaceholder}
                                                            value={formik.values.lastName}
                                                            aria-describedby="lastnamehelp"
                                                            className={`form-control  ${formik
                                                                .touched.lastName &&
                                                                formik.errors.lastName &&
                                                                'input-error'}`}
                                                            disabled={isAuthenticated}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="one-col">
                                                    <div className="input-box-section">
                                                        <TextField
                                                            type="email"
                                                            label={emailLabel}
                                                            name="email"
                                                            placeholder={emailPlaceholder}
                                                            aria-describedby="emailhelp"
                                                            className={`form-control  ${formik
                                                                .touched.email &&
                                                                formik.errors.email &&
                                                                'input-error'}`}
                                                            disabled={isAuthenticated}
                                                        />
                                                    </div>
                                                </div>
                                                {!isAuthenticated && (
                                                    <div className="one-col">
                                                        <div className="input-box-section">
                                                            <div className="input-group relative">
                                                                <TextField
                                                                    type={
                                                                        showPassword
                                                                            ? 'text'
                                                                            : 'password'
                                                                    }
                                                                    label={passwordLabel}
                                                                    name="password"
                                                                    placeholder={
                                                                        passwordPlaceholder
                                                                    }
                                                                    aria-describedby="passwordhelp"
                                                                    className={`form-control  ${formik
                                                                        .touched.password &&
                                                                        formik.errors.password &&
                                                                        'input-error'}`}
                                                                    disabled={isAuthenticated}
                                                                />
                                                                <span
                                                                    onClick={togglepassword}
                                                                    className="input-group-prepend absolute"
                                                                >
                                                                    {showPassword ? (
                                                                        <FaRegEye />
                                                                    ) : (
                                                                        <FaRegEyeSlash />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {!isAuthenticated && (
                                                    <div className="one-col">
                                                        <CheckBoxInfo
                                                            labelName={consentLabel}
                                                            labelId="consent"
                                                            checkboxSection="consent-box"
                                                            isChecked={isChecked}
                                                            onChange={() =>
                                                                setIsChecked(!isChecked)
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="cta mx-auto">
                                                <OrangeBtn
                                                    type="submit"
                                                    disabled={!formik.isValid}
                                                    className={!formik.isValid ? 'disabled' : null}
                                                >
                                                    {isAuthenticated ? (
                                                        <FormattedMessage id="NewPartnersPage.Setup.Account.continue" />
                                                    ) : (
                                                        <FormattedMessage id="SignupForm.signUp" />
                                                    )}
                                                </OrangeBtn>

                                                {!isAuthenticated && (
                                                    <>
                                                        <div className="googleSignUp">
                                                            <h5>or</h5>
                                                            {googleClientId && (
                                                                <GoogleLogin
                                                                    className="login-w-google flex items-center justify-center"
                                                                    clientId={googleClientId}
                                                                    disabled={isAuthenticated}
                                                                    buttonText={
                                                                        <FormattedMessage id="NewPartnersPage.Setup.Account.GoogleBtn" />
                                                                    }
                                                                    onSuccess={responseGoogle}
                                                                    onFailure={responseGoogle}
                                                                    cookiePolicy={
                                                                        'single_host_origin'
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="login-cta small">
                                                            <FormattedMessage
                                                                id="SignupForm.termsAndConditionsAcceptText"
                                                                values={{ termsLink }}
                                                            />
                                                            <FormattedMessage
                                                                id="SignupForm.privacyPoliceAcceptText"
                                                                values={{ privacyLink }}
                                                            />
                                                        </div>

                                                        <div className="login-cta small">
                                                            <FormattedMessage
                                                                id="SignupForm.login.text"
                                                                values={{ linkText }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    )}
                </div>
            </SignupSection>
            <Footer history={history} />
        </Page>
    );
};

const mapDispatchToProps = dispatch => ({
    submitSignup: params => dispatch(signup(params)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
    fetchCurrentUser: params => dispatch(fetchCurrentUser()),
});

const mapStateToProps = state => {
    const { isAuthenticated, signupError } = state.Auth;
    const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
          }
        : null;
    return {
        user,
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        signupError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
    };
};

export default compose(injectIntl)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup))
);
