import styled from 'styled-components';

export const Alliances = styled.section`
    background: #f8fbf6;
    padding: 110px 30px 110px;
    text-align: center;
    .company-group {
        max-width: 100%;
        flex: 1;
        gap: 35px 0;
        .company-logo-container {
            .co-logo {
                height: 80px;
                padding: 0 40px;
                display: flex;
                align-items: center;
                img {
                    filter: grayscale();
                    max-height: 50px;
                }
            }
        }
        .title-box {
            margin-bottom: 36px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px 0 20px;
        .company-group {
            .title-box {
                padding: 0 20px;
            }
        }
    }
`;
