import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Right = styled.div`
    height: 100vh;
    width: 15%;
    background: rgba(255, 255, 255);
    @media screen and (max-width: 1023px) {
        height:auto;
        width:100%;
    }
`;
export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
    font-family: 'Galano Grotesque';
    margin: 30px 10px 30px 15px;
    width: fit-content;
`;

export const Card = styled.div`
    width: 90%;
    height: 160px;
    background: #ffffff;
    margin: 10px auto;
    border-radius: 5px;
    padding: 15px 10px 10px 20px;
    box-sizing: border-box;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.11);
`;
export const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: #f29509;
    font-family: 'Galano Grotesque';
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    .title {
        width: 60%;
        float: left;
        margin: 0px;
    }
    img {
        float: right;
        margin-top: 5px;
    }
`;
export const Text = styled.p`
    margin-top: 20px;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    width: 160px;
`;
