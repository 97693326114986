import React, { Component } from 'react';
import rssParser from 'rss-parser'
import css from './BlogHome.css'
import { ExternalLink } from '../../components';


const CORS_PROXY = "" //"https://cors-anywhere.herokuapp.com/"

class BlogHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    let parser = new rssParser();
    parser.parseURL( '/api/engagement/blog-feed', (err, feed) => {
      if(feed && feed.items){
        this.setState({posts:feed.items})
      }
    })
  }

  render(){
    const intl = this.props.intl
    const blogLinkText = intl.formatMessage({ id: 'LandingPage.blog.linkText' })
    return(
      <div className={css.BlogHome}>
        {
          this.state.posts.slice(0,4).map((item,key)=>
            <div key={key} className={css.BlogHomeItems}>
              <img className={css.BlogHomePic} src={item['content:encoded'].match('img alt="" src="(.*?)" />')[1]}/>
              <div className={css.BlogHomeContent}>
                <h4 className={css.BlogHomeTitle}>{item.title}</h4>
                  <ExternalLink className={css.BlogHomeLink} href={item.link}>{blogLinkText}</ExternalLink>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default BlogHome;
