import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
    login,
    signInWithGoogle,
    authenticationInProgress,
    signup,
    signUpWithGoogle,
} from '../../../../ducks/Auth.duck';
import FormikControl from '../../../../newComponents/Form/FormikControl';
import css from './AccountSetup.css';
import { GoogleLogin } from 'react-google-login';
import ReCaptcha from 'react-google-recaptcha';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '../../../../newComponents/Button';
import { accountSetupInitialValues } from './AccountSetupInitialValues';
import { accountSetupValScheme } from './AccountSetupValScheme';
import { FaSpinner } from 'react-icons/fa';
import { isSignupEmailTakenError } from '../../../../util/errors';
import { NavLink } from 'react-router-dom';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;

const AccountSetup = props => {
    const { isAuthenticated, loginError, signupError, intl, scrollToPayment, currentUser } = props;
    const [isLoading, setLoading] = useState(false);
    const [showError, setShowError] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [googleLoginFailed, setGoogleLoginFailed] = useState(false);
    const [regularAccError, setRegularAccError] = useState(false);
    const reCaptchaRef = useRef(null);
    const currentUserDetails = isAuthenticated
        ? {
              firstName: (currentUser && currentUser.attributes.profile.firstName) || '',
              lastName: (currentUser && currentUser.attributes.profile.lastName) || '',
              email: (currentUser && currentUser.attributes.email) || '',
              password: 'dummytext@123',
          }
        : {
              firstName: '',
              lastName: '',
              email: '',
              password: '',
          };

    useEffect(() => {
        if (!signupError && scroll) {
            scrollToPayment();
            setScroll(false);
        }
    }, [scroll]);

    const onSubmit = async (values, actions) => {
        setLoading(true);
        setShowError(false);
        let captchaToken;
        if (reCaptchaRef.current) {
            captchaToken = await reCaptchaRef.current.executeAsync();
            reCaptchaRef.current.reset();
        }
        await props.submitSignup({ ...values, captchaToken });
        setScroll(true);
        setLoading(false);
        setRegularAccError(true);
        setShowError(true);
    };
    const responseGoogle = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRef.current) {
                    captchaToken = await reCaptchaRef.current.executeAsync();
                    reCaptchaRef.current.reset();
                }
                await props.submitSignupWithGoogle({
                    tokenId: response.tokenId,
                    captchaToken,
                });
                setRegularAccError(false);
                setShowError(true);
                setScroll(true);
            } catch (error) {
                setGoogleLoginFailed(true);
                console.log({ 'Google Sign In Error': error });
            }
        }
    };
    const initialValues = { ...accountSetupInitialValues, ...currentUserDetails };
    const validationSchema = accountSetupValScheme;
    return (
        <div className={css.accountSetup}>
            <h5>
                <FormattedMessage id="NewFriendsPage.Setup.Account.subTitle" />
            </h5>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, errors, isValid, setFieldValue }) => (
                    <div className={css.loginFormContainer}>
                        <Form
                            className={css.loginForm}
                            onChange={() => {
                                setShowError(false);
                            }}
                        >
                            <ReCaptcha
                                ref={reCaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                size="invisible"
                            />
                            <div className={css.loginFormRow}>
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label={intl.formatMessage({
                                        id: 'NewFriendsPage.Setup.Account.firstName',
                                    })}
                                    name="firstName"
                                    disabled={isAuthenticated}
                                    style={{ maxWidth: '268px' }}
                                />
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label={intl.formatMessage({
                                        id: 'NewFriendsPage.Setup.Account.lastName',
                                    })}
                                    name="lastName"
                                    disabled={isAuthenticated}
                                    style={{ maxWidth: '260px' }}
                                />
                            </div>
                            <div className={css.loginFormRow}>
                                <FormikControl
                                    control="input"
                                    type="email"
                                    label={intl.formatMessage({
                                        id: 'NewFriendsPage.Setup.Account.emailAddress',
                                    })}
                                    name="email"
                                    disabled={isAuthenticated}
                                    style={{ maxWidth: '268px' }}
                                />
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label={intl.formatMessage({
                                        id: 'NewFriendsPage.Setup.Account.password',
                                    })}
                                    name="password"
                                    disabled={isAuthenticated}
                                    style={{ maxWidth: '260px' }}
                                />
                            </div>
                            <Button
                                disabled={isAuthenticated}
                                variant={isAuthenticated ? 'disabled' : 'filled'}
                                testId={`btn-friend-accountsetup-${
                                    isAuthenticated ? 'continue' : 'next'
                                }`}
                            >
                                {isLoading && !signupError && <FaSpinner className={css.spinner} />}
                                {isAuthenticated ? (
                                    <FormattedMessage id="NewFriendsPage.Setup.Account.continue" />
                                ) : (
                                    <FormattedMessage id="NewFriendsPage.Setup.Account.next" />
                                )}
                            </Button>
                            {(signupError || loginError) && regularAccError && showError && (
                                <div className={css.error}>
                                    {isSignupEmailTakenError(signupError) ? (
                                        <>
                                            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />{' '}
                                            <NavLink to={'/login'}>
                                                <span className={css.loginButton}>Login</span>
                                            </NavLink>
                                        </>
                                    ) : (
                                        <FormattedMessage id="AuthenticationPage.signupFailed" />
                                    )}
                                </div>
                            )}
                        </Form>
                    </div>
                )}
            </Formik>

            <h5>or</h5>

            {googleClientId && (
                <GoogleLogin
                    className={css.googleButton}
                    clientId={googleClientId}
                    disabled={isAuthenticated}
                    buttonText={intl.formatMessage({
                        id: 'NewFriendsPage.Setup.Account.GoogleBtn',
                    })}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
            )}
            {(signupError || loginError) && !regularAccError && showError && (
                <div
                    style={{
                        fontSize: 16,
                        color: '#ff0000',
                        fontWeight: 500,
                        fontFamily: 'Galano Grotesque',
                    }}
                >
                    {isSignupEmailTakenError(signupError) ? (
                        <>
                            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />{' '}
                            <NavLink to={'/login'}>
                                <span className={css.loginButton}>Login</span>
                            </NavLink>
                        </>
                    ) : (
                        <FormattedMessage id="AuthenticationPage.signupFailed" />
                    )}
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
    submitSignup: params => dispatch(signup(params)),
    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
});

const mapStateToProps = state => {
    const { isAuthenticated, loginError, signupError } = state.Auth;
    const { currentUser } = state.user;
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        signupError,
    };
};

export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(AccountSetup));
