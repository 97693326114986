import * as React from 'react';

export const facebook = ({ fillColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fillColor={fillColor} viewBox="0 0 24 24">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
        </svg>
    );
};

export const instagram = ({ fillColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fillColor={fillColor} viewBox="0 0 24 24">
            <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
        </svg>
    );
};

export const twitter = ({ fillColor }) => {
    return (
        <svg
            fillColor={fillColor}
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 61"
        >
            <path
                style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                d="m35.7 25.8l24.3 35.2h-17.9l-16.3-23.7-20.5 23.7h-5.3l23.4-27.1-23.4-33.9h17.9l15.5 22.4 19.4-22.4h5.2l-22.3 25.8zm-7.2 8.4l16.1 23h8.2l-19.9-28.2-2.3-3.4-15.3-21.6h-8.1l18.9 26.8 2.4 3.4z"
            />
        </svg>
    );
};

export const linkedin = ({ fillColor }) => {
    return (
        <svg viewBox="0 0 455 455">
            <g>
                <path
                    style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                    d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"
                />
                <path
                    style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                    d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z
            M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087
            c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345
            c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537
            c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002
            z"
                />
            </g>
        </svg>
    );
};

export const telegram = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Telegram"
            role="img"
            viewBox="0 0 512 512"
            fillColor={fillColor}
        >
            <rect width="512" height="512" rx="20%" fill="black" />
            <path fill="black" d="M199 404c-11 0-10-4-13-14l-32-105 245-144" />
            <path fill="black" d="M199 404c7 0 11-4 16-8l45-43-56-34" />
            <path
                fill="white"
                d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"
            />
        </svg>
    );
};

export const linkcopy = ({ fillColor }) => {
    return (
        <svg viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" category="social" icon="copy">
            <path
                style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                d="M0.128906 0.480469V11.759H3.90691V15.518H13.8699V4.23997H10.0914V0.480469H0.128906ZM1.46341 1.79647H8.75691V4.23997H3.90691V10.4425H1.46341V1.79647ZM5.24191 14.2025H12.5344V5.55547H5.24191V14.2025Z"
            ></path>
        </svg>
    );
};
