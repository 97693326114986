import styled from "styled-components";
import MyNatureFooter  from '../../../../assets/newAssets/MyNatureFooter.jpeg';
import MyNatureFooterSmall  from '../../../../assets/newAssets/MyNatureFooterSmall.jpeg';
export const Container = styled.div`
    margin:35px 40px 35px 40px;
    height:100%;
    background-position: center;
    padding: 30px 60px 20px 60px;
    background: url(${MyNatureFooter});
    background-size: cover;
    @media screen and (max-width: 500px) {
        background-image: url(${MyNatureFooterSmall});
        padding: 10px 30px 20px 30px;
        background-position: center;
        border-radius: 5px;
        margin:20px;
        width: 90%;
        height: 400px;
        object-fit: none;
        background-size: cover;
    }
    
`;
export const ImgContainer = styled.div`
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-top:20px;
    @media screen and (max-width: 500px) {
        flex-direction:column;
        align-items:flex-start;
    }
`;
export const ImgText = styled.div`
    font-family: Galano Grotesque;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color:#ffffff;
    z-index:4;
    width:40%;
    margin-left:20px;
    @media screen and (max-width: 500px) {
        font-size: 34px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
`;
export const ImgBtn = styled.div`
    margin-right:20px;
    @media screen and (max-width: 500px) {
        margin-left:20px;
        margin-top:10px;
    }
`;
export const ButtonContainer = styled.div`
    margin: 40px auto;
    width:fit-content;
`;