import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.css';
import ChooseGuardianRoleCard from '../../../newComponents/ChooseGuardianRoleCard';
import { FormattedMessage } from 'react-intl';
import CollectiveGuardian from '../../../assets/guardians/collectiveGuardian.png';
import IndividualGuardian from '../../../assets/guardians/individualGuardian.png';
import ConcessionGuardian from '../../../assets/guardians/concessionGuardian.png';
import Button from '../../../newComponents/Button';
import { saveUserInterestForGuardianRole, getUserInterest, getGuardianRoles } from './utils';
import { IconSpinner } from '../../../components';
import { withRouter } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import css from '../../Partners/ChoosePlans/ChoosePlans.css';

const formatGuardianRoles = (roleData, roles) => {
    roleData.forEach(roleElement => {
        roles.forEach(({ id, role }) => {
            let regex = new RegExp(`\\b${role}\\b`, 'i');
            if (regex.test(roleElement.englishName)) {
                roleElement.id = id;
            }
        });
    });
    return roleData;
};
const findGuardianRoleById = (guardianRoles, id) => {
    const targetRole = guardianRoles.find(element => element.id === id);
    return targetRole.title;
};

const GuardianRolesData = [
    {
        img: CollectiveGuardian,
        title: <FormattedMessage id="GuardianRoles.1.title" />,
        description: <FormattedMessage id="GuardianRoles.1.description" />,
        englishName: 'Collective Guardian role',
    },
    {
        img: ConcessionGuardian,
        title: <FormattedMessage id="GuardianRoles.2.title" />,
        description: <FormattedMessage id="GuardianRoles.2.description" />,
        englishName: 'Concession Guardian role',
    },
    {
        img: IndividualGuardian,
        title: <FormattedMessage id="GuardianRoles.3.title" />,
        description: <FormattedMessage id="GuardianRoles.3.description" />,
        englishName: 'Individual Guardian role',
    },
];

const GuardianRoles = ({ history }) => {
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedClickRole, setSelectedCLickRole] = useState('');
    const [subRoleSubmitted, setSubRoleSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [guardianRoles, setGuardianRoles] = useState('');

    useEffect(() => {
        (async () => {
            const guardianRoles = await getGuardianRoles();
            const formattedRolesData = formatGuardianRoles(GuardianRolesData, guardianRoles);
            console.log('formattedRolesData', formattedRolesData);
            setGuardianRoles(formattedRolesData);
            const result = await getUserInterest(setFetching);
            if (result.status === 'success') {
                setSelectedRole(result.subRoleId);
                setSubRoleSubmitted(true);
            }
        })();
    }, []);

    const handleClickSelect = id => async () => {
        const result = await saveUserInterestForGuardianRole(id, setSubmitting);
        if (result.status === 'success') {
            setSelectedRole(id);
            setSubRoleSubmitted(true);
        } else {
            setError(result.message);
        }
    };
    const handleClickSelectClick = id => () => setSelectedCLickRole(id);

    const handleClickRedirect = () => {
        history.push('/profile-check');
    };
    let CarouselListings = useRef(null);
    const allCards =
        guardianRoles &&
        guardianRoles.map(({ id, selectedId, title, description, img }) => {
            return (
                <ChooseGuardianRoleCard
                    handleClickSelect={handleClickSelect}
                    id={id}
                    selectedId={selectedId}
                    key={id}
                    selectedCardId={selectedRole}
                    title={title}
                    description={description}
                    submitted={subRoleSubmitted}
                    handleClickSelectClick={handleClickSelectClick}
                    selectClickedCardId={selectedClickRole}
                    isSubmitting={isSubmitting}
                    img={img}
                />
            );
        });

    return (
        <>
            <div>
                <h1 className={styles.guardianRoleHeading}>
                    <FormattedMessage id="GuardianRoles.heading" />
                </h1>
                <div
                    className={css.planCardsDesktop}
                    style={{
                        gridTemplateColumns: `repeat(${3}, 1fr)`,
                        maxWidth: `min(1250px, calc(${3} * 378px + ${3 - 1} * 80px))`,
                        width: '100%',
                        margin: '0 auto',
                    }}
                >
                    {guardianRoles ? (
                        allCards
                    ) : (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <IconSpinner />
                        </div>
                    )}
                </div>
                {guardianRoles ? (
                    <div className={css.planCardsMobile}>
                        <AliceCarousel
                            buttonsDisabled={true}
                            autoPlayInterval={2000}
                            dotsDisabled={true}
                            infinite={false}
                            disableAutoPlayOnAction={true}
                            autoPlay={false}
                            responsive={{
                                0: { items: 1, itemsFit: 'contain' },
                                767: { items: 2 },
                                1023: { items: 4 },
                            }}
                            items={allCards}
                            ref={el => (CarouselListings = el)}
                        />
                        <button
                            className={css.carouselBackButton}
                            onClick={() => CarouselListings.slidePrev()}
                        >
                            <FaChevronLeft className={css.carouselChevron} />
                        </button>
                        <button
                            className={css.carouselNextButton}
                            onClick={() => CarouselListings.slideNext()}
                        >
                            <FaChevronRight className={css.carouselChevron} />
                        </button>
                    </div>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconSpinner />
                    </div>
                )}

                {/* <div className={styles.guardianRolesCard}>{allCards}</div> */}
                {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>}
            </div>
            {subRoleSubmitted && (
                <div className={styles.thankyouCard}>
                    <h1 className={styles.heading}>
                        <FormattedMessage id="Guardian.congratulations.heading" />{' '}
                        {guardianRoles && findGuardianRoleById(guardianRoles, selectedRole)}
                    </h1>
                    <p className={styles.subHeadingOne}>
                        <FormattedMessage id="Guardian.congratulations.subheading1" />
                    </p>
                    <p className={styles.subHeadingTwo}>
                        <FormattedMessage id="Guardian.profile.heading" />
                    </p>
                    <div className={styles.viewProfileButton}>
                        <Button onClick={handleClickRedirect} variant={'filled'}>
                            <FormattedMessage id="Guardian.profile.button.text" />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
export default withRouter(GuardianRoles);
