import styled from 'styled-components';
import { variant, typography, width, space, minWidth } from 'styled-system';
import { colors, borderRadius, fontWeights, fontSizes } from '../../theme';
import { IconSpinner } from '../../components';

export const buttonsCommon = {
    border: 'none',
    borderRadius: borderRadius[1],
    fontWeight: fontWeights[6],
    cursor: 'pointer',
};

export const buttons = {
    filled: {
        ...buttonsCommon,
        backgroundColor: colors.orange,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.darkOrange,
        },
    },
    filledRounded: {
        ...buttonsCommon,
        backgroundColor: colors.light_green,
        borderRadius: borderRadius[3],
        color: colors.white,
        height: '45px',

        '&:hover': {
            backgroundColor: '#497d28',
        },
    },
    filledBlack: {
        ...buttonsCommon,
        backgroundColor: '#F29509',
        color: colors.black,
        maxWidth: 180,
        '&:hover': {
            backgroundColor: colors.darkOrange,
        },
    },
    footprintChoiceCalculate: {
        ...buttonsCommon,
        backgroundColor: '#F29509',
        color: colors.black,
        minWidth: 300,
        '&:hover': {
            backgroundColor: colors.darkOrange,
        },
    },
    footprintChoiceManage: {
        ...buttonsCommon,
        backgroundColor: '#FFC672',
        color: colors.black,
        minWidth: 300,
        '&:hover': {
            backgroundColor: colors.darkOrange,
        },
    },
    ghost: {
        ...buttonsCommon,
        border: `2px solid ${colors.white}`,
        color: colors.white,
        background: 'transparent',
        '&:hover': {
            borderColor: colors.orange,
            color: colors.orange,
        },
    },
    ghostFilledRounded: {
        ...buttonsCommon,
        border: `2px solid ${colors.white}`,
        color: colors.light_green,
        background: 'transparent',
        borderRadius: borderRadius[3],
        height: '45px',
        '&:hover': {
            color: colors.white,
            backgroundColor: colors.light_green,
        },
    },
    disabled: {
        ...buttonsCommon,
        border: `2px solid ${colors.white}`,
        background: '#e7e7e7',
        color: '#fff',
        cursor: 'not-allowed',
        '&:hover': {
            borderColor: '#e7e7e7',
            color: colors.white,
        },
    },
    disabledShare: {
        ...buttonsCommon,
        border: `2px solid ${colors.white}`,
        background: '#BBBBBB',
        color: '#fff',
        cursor: 'not-allowed',
        '&:hover': {
            borderColor: '#BBBBBB',
            color: colors.white,
        },
    },
    invertedGhost: {
        ...buttonsCommon,
        border: `3px solid ${colors.black}`,
        color: colors.black,
        background: 'transparent',
        '&:hover': {
            borderColor: colors.orange,
            color: colors.orange,
        },
    },
};

export const buttonSizeVariants = {
    md: {
        fontSize: [1],
        padding: '12px 35px',
        lineHeight: '26px',
    },
    mdt: {
        fontSize: [1],
        padding: '0 39px',
        lineHeight: '26px',
    },
    lg: {
        fontSize: [2],
        padding: '17px 49px',
        lineHeight: '31px',
    },
    xl: {
        fontSize: [2],
        padding: '24px 52px',
        lineHeight: '31px',
    },
};

export const buttonSizes = variant({
    variants: buttonSizeVariants,
    prop: 'size',
});

export const buttonVariants = variant({
    variants: buttons,
    prop: 'variant',
});

export const StyledButton = styled.button`
    ${buttonVariants}
    ${buttonSizes}
    ${typography}
    ${width}
    ${space}
    ${minWidth}
  `;

export const StyledSpinner = styled(IconSpinner)`
    width: 28px;
    height: 28px;
    stroke: ${colors.white};
    stroke-width: 3px;
`;
