import styled from 'styled-components';

export const LandscapeListSection = styled.section`
    .listing-first-section {
        &::before {
            background-image: url(${({ bgGreenTexture }) => bgGreenTexture});
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 650px;
        }
        padding: 90px 5px 55px 80px;
        position: relative;
        .landscape-herobox {
            max-width: 1440px;
            gap: 8%;
            padding-right: 25px;
            height: 100%;
            .left-side-content {
                gap: 32px;
                width: 100%;
                max-width: 740px;
                .title-box {
                    h1 {
                        font-size: 47px;
                        line-height: 58px;
                    }
                    .border-img {
                        left: 0;
                        bottom: -10px;
                    }
                }
                p {
                    line-height: 180%;
                }
                .select-lists {
                    min-width: 848px;
                    .input-box-section {
                        max-width: 49%;
                        .map-list-btn {
                            display: none;
                            margin: 15px auto 0;
                            div {
                                gap: 10px;
                            }
                        }
                    }
                    .article-section {
                        gap: 24px 16px;
                        margin-top: 18px;
                        .card-slider {
                            background-color: white;
                            max-width: 414px;
                            box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
                            border-radius: 8px;
                            .discover-card {
                                position: relative;
                                .tag {
                                    left: 18px;
                                    top: 18px;
                                    color: #6ea44c;
                                    line-height: 30px;
                                    padding: 2px 16px;
                                    height: 33px;
                                    background-color: #ddedd0;
                                    border-radius: 25px;
                                    font-weight: bold;
                                    z-index: 9;
                                }
                                .card-img-btn {
                                    position: relative;
                                    .card-img {
                                        border-radius: 8px;
                                        .w-full {
                                            border-radius: 8px;
                                            height: 180px;
                                            object-fit: cover;
                                        }
                                    }
                                    .card-button {
                                        position: absolute;
                                        right: 20px;
                                        bottom: 0;
                                        max-width: 200px;
                                        z-index: 2;
                                    }
                                }
                                .discover-card-info {
                                    background-color: white;
                                    padding: 32px 24px;
                                    border-radius: 8px;
                                    position: relative;
                                    top: -24px;
                                    .card-title {
                                        margin-bottom: 8px;
                                    }
                                    .card-info {
                                        line-height: 180%;
                                        margin-top: 0;
                                    }
                                    hr {
                                        background: #f6f6f6;
                                        border: 0 none;
                                        height: 1px;
                                        margin: 20px 0;
                                    }
                                    .icon-with-numbers {
                                        li {
                                            margin-bottom: 8px;
                                            .icon-box {
                                                width: 24px;
                                                height: 24px;
                                                margin-right: 12px;
                                            }
                                            &:last-child {
                                                margin-bottom: 0;
                                                .icon-box {
                                                    img {
                                                        width: 18px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .card-button {
                                        display: none;
                                    }
                                }
                            }
                            .propose-box {
                                padding: 23px 27px 27px;
                                .green-link {
                                    margin-top: 8px;
                                    gap: 8px;
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                }
            }
            .peru-map {
                position: sticky;
                z-index: 99;
                right: 25px;
                float: right;
                top: 10%;
                .top-one-location,
                .center-two-location,
                .bottom-three-location,
                .bottom-four-location,
                .bottom-five-location,
                .bottom-six-location {
                    position: absolute;
                    &:hover img {
                        content: url(${({ pinHover }) => pinHover});
                    }
                }
                .top-one-location {
                    right: 169px;
                    top: 133px;
                }
                .center-two-location {
                    left: 155px;
                    bottom: 266px;
                }
                .bottom-three-location {
                    right: 143px;
                    bottom: 175px;
                }
                .bottom-four-location {
                    right: 102px;
                    bottom: 190px;
                }
                .bottom-five-location {
                    right: 88px;
                    bottom: 252px;
                }
                .bottom-six-location {
                    right: 78px;
                    bottom: 207px;
                }
            }
        }
        @media (max-width: 1420px) {
            .landscape-herobox {
                .left-side-content {
                    width: 55%;
                }
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            padding: 86px 0 28px;
            .landscape-herobox {
                padding-right: 0;
                .left-side-content {
                    width: 100%;
                    max-width: none;
                    gap: 16px;
                    padding: 0 20px;
                    .title-box {
                        h1 {
                            font-size: 28px;
                            line-height: 38px;
                        }
                        .border-img {
                            max-width: 420px;
                            width: 85%;
                        }
                    }
                    .select-lists {
                        min-width: 100%;
                        margin: 0 -20px;
                        .input-box-section {
                            max-width: 100%;
                            padding: 0 20px;
                            .map-list-btn {
                                display: block;
                                margin: 15px auto 0;
                                div {
                                    gap: 10px;
                                }
                            }
                        }
                        .article-section {
                            gap: 16px;
                            padding-left: 0;
                            padding-right: 0;
                            .card-slider {
                                max-width: 49%;
                                margin: 0 20px;
                                .discover-card {
                                    .card-button {
                                        display: none;
                                    }
                                    .discover-card-info {
                                        padding: 20px 16px;
                                        border-radius: 8px;
                                        position: relative;
                                        top: -20px;
                                        .card-title {
                                            margin-bottom: 4px;
                                        }
                                        hr {
                                            margin: 16px 0;
                                        }
                                        .icon-with-numbers {
                                            li {
                                                &:last-child {
                                                    margin-bottom: 16px;
                                                }
                                            }
                                        }
                                        .card-button {
                                            display: block;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 18px;
                                }
                            }
                        }
                    }
                    .bg-green {
                        background-color: #004d37;
                    }
                }
                .peru-map {
                    background-color: #004d37;
                    position: relative;
                    right: 0;
                    padding: 30px 0;
                    text-align: center;
                    width: 477px;
                    margin: 0 auto;
                }
                .hidden {
                    display: none;
                }
            }
        }
        @media (max-width: 840px) {
            .landscape-herobox {
                .left-side-content {
                    .select-lists {
                        .article-section {
                            .card-slider {
                                max-width: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 720px) {
            .landscape-herobox {
                .peru-map {
                    img {
                        position: relative;
                        left: -50px;
                    }
                    .center-two-location {
                        left: 150px;
                        bottom: 315px;
                    }
                }
            }
        }
    }
`;
export const LandscapeGuardians = styled.section`
    position: relative;
    .team-section {
        min-height: 490px;
        background-color: #f8fbf6;
        text-align: center;
        .title-box {
            max-width: 630px;
            margin-bottom: 4px;
            padding: 0 20px;
            .small-title {
                font-size: 19px;
                line-height: 180%;
                letter-spacing: 3.5px;
                font-weight: 400;
                color: #6ea44c;
                text-transform: uppercase;
                margin-bottom: 4px;
            }
        }
        .content {
            max-width: 630px;
            padding: 0 20px;
            .group-btns {
                gap: 16px;
                margin-top: 16px;
            }
        }
    }
    .left-team,
    .right-team {
        height: 522px;
        position: absolute;
        left: -18px;
        bottom: -18px;
        z-index: 1;
        img {
            height: 522px;
        }
    }
    .right-team {
        left: auto;
        right: -18px;
        bottom: -11px;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .team-section {
            min-height: auto;
            padding: 149px 20px 167px;
            .title-box,
            .content {
                padding: 0;
            }
        }
        .left-team,
        .right-team {
            left: 50%;
            transform: translateX(-50%);
            top: -14px;
            bottom: auto;
            width: 100%;
            text-align: center;
            height: 160px;
            img {
                height: 160px;
            }
        }
        .right-team {
            top: auto;
            bottom: -12px;
        }
    }
    @media (max-width: 400px) {
        .left-team,
        .right-team {
            height: 132px;
            img {
                height: 100%;
            }
        }
    }
`;
