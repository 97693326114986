import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import Header from '../../../../myNaturePage/sections/Header/Header';
import LeftNav from '../../../sections/LeftNav/LeftNav';
import RightNav from '../../../../myNaturePage/sections/RightNav/RightNav';
import { Frame, MobileComp } from "../../../Styles";
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import HeroImage from '../../../../../assets/newAssets/guardian_profile.png';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import { ProfileAndAccountMain } from '../ProfileAndAccount/Styles';
import MyIncome from './components/MyIncome';
import IncomeDetails from './components/IncomeDetails';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
        }
        : null,

});

const IncomePage = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Guardian profile<br/>& account</>}
                image={HeroImage}
            />
            <ProfileAndAccountMain>
                <MyIncome />
                <IncomeDetails />
                <MobileComp><RightNav /></MobileComp>
            </ProfileAndAccountMain>
            <RightNav />
        </Frame>
    );
});

const GuardianIncomePage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(IncomePage);
export default GuardianIncomePage;
