import React from "react";
import { NamedRedirect } from "../../components";
import { connect } from 'react-redux';

const UserRoleCheck = (props) =>{

    const {children, user, accpetedRole } = props;
    const currentRole = user && user.currentRole;
    return (
        currentRole && currentRole === accpetedRole ? children : 
        <NamedRedirect
            name={'ProfileCheck'}
        />
    )
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              currentRole: storeState.user.currentUser.currentRole,
          }
        : null,
});
export default connect(mapStateToProps)(UserRoleCheck);