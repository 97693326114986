import React, { useEffect, useState } from 'react';
import css from './LandscapeSelection.css';
import Button from '../../../../newComponents/Button';
import { fetchLandscapes, fetchDescription } from './fetchLandscapes';
import { applicableLandscapes, extractPlanID } from '../../helper';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

const LandScapeSelection = ({ selectedPlan, updatePlanID, handleLandscapeSelection }) => {
    const { landProtected, emissionComp, treePlanted, planType } = selectedPlan;
    const [landscapes, setLandscapes] = useState([]);
    const [selectedLandScape, setSelectedLandscape] = useState({});
    // use default selection here(if reqd. later)
    const [selectedLandScapeID, setSelectedLandscapeID] = useState(3);
    useEffect(() => {
        fetchLandscapes().then(resp => {
            const applicableLS = applicableLandscapes(resp, planType);
            const planID = extractPlanID(applicableLS, selectedPlan.planType, selectedLandScapeID);
            updatePlanID(planID);
            setLandscapes(applicableLS);
        });
    }, [selectedPlan]);
    useEffect(() => {
        fetchDescription(selectedLandScapeID).then(resp => {
            setSelectedLandscape(resp);
            handleLandscapeSelection(resp);
        });
    }, [selectedLandScapeID]);

    const landScapeChange = event => {
        setSelectedLandscapeID(event.target.value);
        const planID = extractPlanID(landscapes, selectedPlan.planType, event.target.value);
        updatePlanID(planID);
    };
    const { name, image, metadata } = selectedLandScape;
    const { slogan, impact_description, description } = metadata || {
        slogan: '',
        impact_description: '',
        description: '',
    };
    return (
        <div className={css.landScapeSelectCont}>
            {/* <h3>Congratulations! Your transaction was successful</h3> */}
            <h5 className={css.chooseLS}>
                <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.title" />
            </h5>

            <div className={css.landScapeSelectTag}>
                <select onChange={landScapeChange}>
                    {landscapes.map(landscape => (
                        <option
                            value={landscape.id}
                            key={landscape.id}
                            selected={landscape.id == 3}
                        >
                            {`${landscape.name} - ${landscape.metadata.slogan}`}
                        </option>
                    ))}
                </select>
                <span className={css.customArrow}></span>
            </div>

            <div className={css.landScapeDetail}>
                <div className={css.landScapeImgCont}>
                    <img src={image} />
                </div>
                <div className={css.landScapeInfo}>
                    <h4>
                        {name} - {slogan}
                    </h4>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: description && description.replace(/\n/g, '<br>'),
                        }}
                    />
                    <p className={css.landScapeArea}>{impact_description}</p>
                </div>
            </div>

            <div className={css.landScapeSuppImp}>
                <h2>
                    <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.impact" />
                </h2>
                <div className={css.impactStrip}>
                    <div className={css.landProtected}>
                        <NumberFormat
                            value={landProtected}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) => <h4 {...props}>
                                {value}{' '}
                                <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.sqm" />
                            </h4>}
                        />
                        <p>
                            <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.landProtected" />
                        </p>
                    </div>
                    <div className={css.coemission}>
                        <NumberFormat
                            value={emissionComp}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) => <h4 {...props}>
                                {value} <br />
                                <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.kg" />
                            </h4>}
                        />
                        <p>
                            <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.coEmission" />
                        </p>
                    </div>
                    <div className={css.trees}>
                        <NumberFormat
                            value={treePlanted}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) => <h4 {...props}>
                                {value}{' '}
                                {value > 1 ? (
                                <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.trees" />
                            ) : (
                                <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.tree" />
                            )}
                            </h4>}
                        />
                        <p>
                            <FormattedMessage id="NewFriendsPage.Setup.ChooseLS.plantedAndCared" />
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className={css.actionBtns}>
        <Button onClick={supportLandscape}>Support this landscape</Button>
        <Button variant="disabled">Tell a friend</Button>
      </div> */}
        </div>
    );
};

export default LandScapeSelection;
