import React, {useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import "./styles.css";
export default function Doughnut(props) {
  const reference = useRef();
  const [emissionData,setEmissionData]=useState(props.emissionData);
  let chart;
  const data = {
    datasets: [
      {
        label: "My First Dataset",
        data: emissionData,
        backgroundColor: ["#8E8670", "#781515", "#E95527"],
        hoverOffset: 4
      }
    ]
  };
  const config = {
    type: "doughnut",
    data: data,
    options: {
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          position: "bottom"
        }
      },
    }
  };
  useEffect(() => {
    const ctx = reference.current;
    chart=new Chart(ctx, {
      ...config
    });
    chart.data.datasets[0].data = props.emissionData;
    chart.update();
    return () => {
      chart.destroy()
    }
  }, [props.emissionData]);
  return (
    <div className="App">
      <canvas ref={reference} id="myChart" width="100%" height="100%"></canvas>
    </div>
  );
}
