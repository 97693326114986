import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { getGuardianStripePayoutsList } from "../../../../../../coreApi";
import { getDateFromTimestamp } from "../utils";
import Pagination from "../Pagination";
import { 
    IncomeDetailsTitle,
    IncomeDetailsMain,
    IncomeDetailsItem,
    IncomeDetailsItemData,
    Line
} from '../Styles';

const IncomeDetails = () => {

    const [payoutList, setPayoutList] = useState('');

    useEffect(() => {
        (async () => {
            const response = await getGuardianStripePayoutsList()
            const payoutData = response.data.response.data;
            if (payoutData) {
                setPayoutList(payoutData);
            }
        })();
    }, []);

    const data = 
        payoutList &&
        payoutList.map(pay => (
            <>
            <IncomeDetailsItem color="black" bkgColor="white">
                <IncomeDetailsItemData>{getDateFromTimestamp(pay.arrival_date)}</IncomeDetailsItemData>
                <IncomeDetailsItemData>{pay.currency == "usd" ? "$" : "S/."}{pay.amount/100}</IncomeDetailsItemData>
            </IncomeDetailsItem>
            <Line />
            </>
        ))

    return (
        <>
            <IncomeDetailsTitle><FormattedMessage id="NewGuardiansPage.Income.detailsTitle" /></IncomeDetailsTitle>
            <IncomeDetailsMain>
                <IncomeDetailsItem color="white" bkgColor="black">
                    <IncomeDetailsItemData><FormattedMessage id="NewGuardiansPage.Income.tableMonth" /></IncomeDetailsItemData>
                    <IncomeDetailsItemData><FormattedMessage id="NewGuardiansPage.Income.tableAmount" /></IncomeDetailsItemData>
                </IncomeDetailsItem>
                <Pagination itemsArray={data} />
            </IncomeDetailsMain>
        </>
    );
}

export default IncomeDetails;