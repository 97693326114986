import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../newComponents/Button';
import { Container, ImgContainer, ImgText, ImgBtn } from './Styles.js';
function Footer() {
    return (
        <div>
            <Container>
                <ImgContainer>
                    <ImgText>
                        <FormattedMessage id="MyNaturePage.Footer.Title" />
                    </ImgText>
                    <ImgBtn>
                        <Button variant="filled" size="md" path="/land-managers">
                            <FormattedMessage id="MyNaturePage.Footer.LearnMore" />
                        </Button>
                    </ImgBtn>
                </ImgContainer>
            </Container>
        </div>
    );
}

export default Footer;
