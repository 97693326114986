import styled from 'styled-components';

export const Alliances = styled.section`
    background: #f8fbf6;
    padding: 110px 30px 110px;
    text-align: center;
    .company-group {
        max-width: 100%;
        flex: 1;
        gap: 48px 0;
        .company-logo-container {
            .co-logo {
                max-height: 102px;
                padding: 0 70px;
                img {
                    filter: grayscale();
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 24px 0 20px;
        .company-group {
            .title-box {
                padding: 0 20px;
            }
            .company-logo-container {
                .co-logo {
                    padding: 0 20px;
                }
            }
        }
    }
`;
