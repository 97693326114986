import React from 'react';
import CheckBoxInfo from '../checkbox';

const BlogFilter = ({ categories, onCategoryChange, selectedCategories }) => {
    return (
        <div className="submenu">
            <ul>
                {categories &&
                    categories.map(category => (
                        <li key={category.slug}>
                            <CheckBoxInfo
                                labelName={category.name}
                                labelId={category.slug}
                                checkboxSection="checkbox-section"
                                onChange={onCategoryChange}
                                isChecked={selectedCategories.includes(category.slug)}
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default BlogFilter;
