import React from 'react';
import { HeadingContainer, SubHeadingContainer } from './Styles.js'
import { FormattedMessage } from 'react-intl';
const CardHeading = (props) => {

    return (
        <>
            <HeadingContainer>
                <FormattedMessage id={props.headingId}/>
            </HeadingContainer>
            <SubHeadingContainer>
                <FormattedMessage id={props.subHeadingId}/>
            </SubHeadingContainer>
        </>
    );
}

export default CardHeading ;