import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdArrowDropdown } from 'react-icons/io';

const CustomSelectBox = styled.div`
    position: relative;
    text-align: left;
    button {
        border-radius: 8px;
        border: 1px solid #ddedd0;
        width: 100%;
        padding: 4px 12px;
        position: relative;
        background-color: white;
        text-align: left;
        .drop-icon {
            right: 12px;
            top: 11px;
            position: absolute;
        }
    }
    .options {
        border: 1px solid #eaeaea;
        display: none;
        border-radius: 8px;
        list-style: none;
        padding: 6px 12px 10px;
        background-color: white;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.05);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 200;
        li {
            line-height: 180%;
            font-size: 16px;
            font-weight: 400;
            margin-top: 4px;
            &:active,
            &:focus,
            &:hover,
            [aria-selected='true'] {
                cursor: pointer;
            }
        }
    }
    .show {
        display: block;
        background-color: white;
    }
`;

const CustomRadioSelect = ({ name, optionsList, onChange, classname, value, disabled }) => {
    console.log('value', value, disabled);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        value && optionsList.findIndex(option => option.name === value) > 0
            ? optionsList.findIndex(option => option.name === value)
            : 0
    );

    const toggleOptions = () => {
        if (!disabled) {
            setIsOptionsOpen(!isOptionsOpen);
        }
    };

    const setSelectedThenCloseDropdown = index => {
        setSelectedOption(index);
        setIsOptionsOpen(false);
        index === 0 ? onChange('') : onChange(optionsList[index].name);
    };

    const selectOption = e => {
        console.log('selection', e.target.value);
        onChange(e.target.value);
    };

    useEffect(() => {
        if (value !== optionsList[selectedOption].name) {
            const selectedIndex = optionsList.findIndex(option => option.name === value);
            setSelectedOption(selectedIndex >= 0 ? selectedIndex : 0);
        }
    }, [value, optionsList, selectedOption]);

    return (
        <CustomSelectBox>
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className={`${classname} ${isOptionsOpen ? 'expanded' : ''}`}
                onClick={toggleOptions}
            >
                {optionsList[selectedOption].value || optionsList[0].value}
                <IoMdArrowDropdown className="drop-icon" />
            </button>
            <ul
                className={`options ${isOptionsOpen ? 'show' : ''}`}
                role="listbox"
                aria-activedescendant={optionsList[selectedOption]}
                tabIndex={-1}
            >
                {optionsList.map((option, index) => (
                    <li
                        id={option.name}
                        role="option"
                        aria-selected={selectedOption === index}
                        tabIndex={0}
                        onClick={() => {
                            setSelectedThenCloseDropdown(index);
                        }}
                    >
                        {index === 0 ? (
                            <label>{option.value}</label>
                        ) : (
                            <label class="radio-box">
                                {option.value}
                                <input
                                    type="radio"
                                    id={`${name}-${index}`}
                                    value={option.value}
                                    name={name}
                                    checked={selectedOption === index}
                                    disabled={disabled}
                                />
                                <span class="checkmark"></span>
                            </label>
                        )}
                    </li>
                ))}
            </ul>
        </CustomSelectBox>
    );
};

export default CustomRadioSelect;
