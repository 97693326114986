import styled from 'styled-components';

export const TeamSection = styled.section`
    position: relative;
    .team-section {
        min-height: 490px;
        background-image: url(${({ bgGreenTexture }) => bgGreenTexture});
        background-size: cover;
        text-align: center;

        .title-box {
            max-width: 630px;
            margin-bottom: 4px;
            padding: 0 20px;
            .small-title {
                font-size: 19px;
                line-height: 180%;
                letter-spacing: 3.5px;
                font-weight: 400;
                color: #ddedd0;
                text-transform: uppercase;
                margin-bottom: 4px;
            }
            h3 {
                color: white;
            }
        }
        .content {
            max-width: 630px;
            color: white;
            padding: 0 20px;
        }
    }
    .left-team,
    .right-team {
        height: 522px;
        position: absolute;
        left: 0;
        bottom: 8px;
        z-index: 1;
        img {
            height: 522px;
        }
    }
    .right-team {
        left: auto;
        right: 0;
        bottom: -11px;
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .team-section {
            min-height: auto;
            padding: 149px 20px 167px;
            .title-box,
            .content {
                padding: 0;
            }
        }
        .left-team,
        .right-team {
            left: 50%;
            transform: translateX(-50%);
            top: -14px;
            bottom: auto;
            width: 100%;
            text-align: center;
            height: 160px;
            img {
                height: 160px;
            }
        }
        .right-team {
            top: auto;
            bottom: -12px;
        }
    }
    @media (max-width: 400px) {
        .left-team,
        .right-team {
            height: 132px;
            img {
                height: 100%;
            }
        }
    }
`;
