import React, { useState, useEffect } from 'react';
import { isNil } from 'lodash';
import {
    getMySubscriptionPlans,
    getCurrentOrganization,
    getOrganizationEmissionResultOurNature,
} from '../../../../coreApi';

function CustomHook() {
    const [ecoResults, setEcoResults] = useState({});
    const [dataPlans, setDataPlans] = useState([]);
    const [ecoImpact, setEcoImpact] = useState(0);
    const [climateImpact, setClimateImpact] = useState(0);
    const [totalEmission, setTotalEmission] = useState(0);
    const [planArray, setPlanArray] = useState([]);
    const [year, setYear] = useState();
    useEffect(() => {
        let isComponentMounted = true;
        const fetchData = async () => {
            const Details = await getCurrentOrganization();
            const plans = await getMySubscriptionPlans();
            if (isComponentMounted) {
                setDataPlans(plans.data);
                calculateData(plans.data);
                if (!isNil(Details)) {
                    const emission = await getOrganizationEmissionResultOurNature(
                        Details.data[0].id
                    );
                    if (!isNil(emission)) {
                        let length = emission.data.length - 1;
                        const em = (emission.data[length].totalEmission / 1000).toFixed(2);
                        setTotalEmission(em);
                        setYear(emission.data[length].year);
                    }
                }
            }
        };
        fetchData();
        return () => {
            isComponentMounted = false;
        };
    }, []);
    useEffect(() => {
        const fetchData = async () => {};
        fetchData();
    }, []);

    const planDetails = {
        polen: {
            Ef: 0.2,
            Cf: 4,
        },
        polen_price: {
            Ef: 0.2,
            Cf: 4,
        },
        semilla: {
            Ef: 1,
            Cf: 2,
        },
        arbol: {
            Ef: 4,
            Cf: 8,
        },
        bosque: {
            Ef: 10,
            Cf: 20,
        },
        paisaje: {
            Ef: 20,
            Cf: 40,
        },
        semillaEmpresas: {
            Ef: 10,
            Cf: 25,
        },
        arbolEmpresas: {
            Ef: 40,
            Cf: 100,
        },
        bosqueEmpresas: {
            Ef: 100,
            Cf: 250,
        },
    };
    const calculateData = dataPlans => {
        let dataCarbon = 0;
        const array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        dataPlans &&
            dataPlans.map(item => {
                let str = item.updatedAt.split('-');
                let m = parseInt(str[1], 10);
                const plan = planDetails[item.planType];
                dataCarbon = dataCarbon + plan.Cf;
                array[m - 1] = array[m - 1] + plan.Cf;
            });
        let previousArray = array[0];
        array.map((item, i) => {
            if (i == 0) {
                previousArray = array[0];
            } else {
                if (item == 0) {
                    array[i] = previousArray;
                }
                previousArray = array[i];
            }
        });
        setPlanArray(array);
        setClimateImpact(dataCarbon);
    };
    return {
        planArray: planArray,
        ecoResults: ecoResults,
        totalEmission: totalEmission,
        ecoImpact: ecoImpact,
        dataPlans: dataPlans,
        climateImpact: climateImpact,
        year: year,
    };
}

export default CustomHook;
