import styled from 'styled-components';

export const PositiveImpact = styled.section`
    padding: 40px 5px 55px 80px;
    position: relative;
    position: sticky;
    background-image: url(${({ bgGreenTexture }) => bgGreenTexture});
    background-size: cover;
    .slick-slider .slick-slide {
        padding: 0 5px;
    }
    .peru-location-info {
        max-width: 1440px;
        gap: 30px;
        align-items: flex-start;
        .impact-list-info {
            width: 60%;
            max-width: 847px;
            gap: 45px;
            .title-box {
                h3 {
                    color: white;
                }
                .custom-select-container {
                    margin: 20px 0;
                    display: none;
                }
            }
            .article-section {
                .card-slider {
                    width: 61%;
                }
                gap: 24px;
                .discover-card {
                    max-width: 518px;
                    .card-img-btn {
                        position: relative;
                        .card-img {
                            border-radius: 8px;
                            .w-full {
                                border-radius: 8px;
                                height: 180px;
                                object-fit: cover;
                            }
                        }
                        .card-button {
                            position: absolute;
                            right: 20px;
                            bottom: 0;
                            max-width: 200px;
                            z-index: 2;
                        }
                        .new-label{
 
                                position: absolute;
                                top: 1px;
                                right: 1px;
                                width: 0;
                                height: 0;
                                border-top: 90px solid rgb(221, 107, 23); /* Adjust the height of the triangle */
                                border-left: 90px solid transparent; /* Adjust the width of the triangle */
                                border-radius: 6px;
                                span {
 
                                    position: absolute;
                                    top: -64px; /* Adjust to center the text */
                                    right: 3px; /* Adjust to center the text */
                                    color: white;
                                    font-weight: bold;
                                    transform: rotate(45deg);
                                    white-space: nowrap;
                                    font-size: 16px;
                                    letter-spacing: 1px;

                                }

                        }
                    }
                    .discover-card-info {
                        background-color: white;
                        padding: 32px 24px;
                        border-radius: 8px;
                        position: relative;
                        top: -24px;
                        .card-title {
                            margin-bottom: 8px;
                        }
                        .card-info {
                            line-height: 180%;
                            margin-top: 0;
                        }
                        hr {
                            background: #f6f6f6;
                            border: 0 none;
                            height: 1px;
                            margin: 20px 0;
                        }
                        .icon-with-numbers {
                            li {
                                margin-bottom: 8px;
                                .icon-box {
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 12px;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                    .icon-box {
                                        img {
                                            width: 18px;
                                        }
                                    }
                                }
                            }
                        }
                        .card-button-mobile {
                            display: none;
                        }
                    }
                }
                .discover-card.mobile-show {
                    display: none;
                }
                .recent-actions {
                    h6 {
                        font-size: 16px;
                        line-height: 21px;
                        color: white;
                        margin-bottom: 12px;
                        font-weight: 500;
                    }
                    .list-recent-action {
                        gap: 12px;
                        .article-thumb-section {
                            gap: 12px;
                            background-color: white;
                            max-height: 112px;
                            border-radius: 8px;

                            h6 {
                                color: ${({ theme }) => theme.colors.title_green};
                            }
                            img {
                                max-width: 80px;
                                min-height:112px;
                                border-radius:8px;
                                object-fit:cover;
                                
                            }
                            .placeholderImg {
                                padding: 10px;
                            }
                            .action-content {
                                padding: 12px 12px 12px 0;
                                .link-text {
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: 500;
                                    color: ${({ theme }) => theme.colors.light_green};
                                }
                            }
                        }
                    }
                }
            }
        }
        .peru-map {
            position: relative;
            .location {
                position: absolute;
                img {
                    cursor: pointer;
                }
            }
            .top-one-location {
                right: 169px;
                top: 133px;
            }
            .center-two-location {
                left: 155px;
                bottom: 266px;
            }
            .bottom-three-location {
                right: 143px;
                bottom: 175px;
            }
            .bottom-four-location {
                right: 102px;
                bottom: 190px;
            }
            .bottom-five-location {
                right: 88px;
                bottom: 252px;
            }
            .bottom-six-location {
                right: 78px;
                bottom: 207px;
            }
            .click-info {
                text-align: center;
                font-size: 19px;
                line-height: 27px;
                max-width: 180px;
                color: ${({ theme }) => theme.colors.light_green};
                position: absolute;
                bottom: 0;
                left: 0;
                &::after {
                    position: absolute;
                    content: '';
                    width: 119px;
                    height: 40px;
                    right: -120px;
                    top: 15px;
                    background: url(${({ arrowPointer }) => arrowPointer}) no-repeat center center;
                }
            }
        }
    }
    
    @media (max-width: 1366px) {

        .peru-location-info {
            gap: 10px;
        }
            
            
    }


    @media (max-width: ${({ theme }) => theme.media.desktop}) {

        .peru-location-info {
            .impact-list-info {
                width: 45%;
                .title-box {
                    h3 {
                        font-size: 22px;
                    }
                    
                }
                .article-section {
                    flex-direction: column;
                    .card-slider {
                        width: 100%;
                    }
                  
                }
            }
            
            
    }

    

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        padding: 20px 0 28px 0;
        .peru-location-info {
            .impact-list-info {
                width: 100%;
                max-width: none;
                gap: 0;
                .title-box {
                    padding: 0 20px;
                    .custom-select-container {
                        display: block;
                    }
                }
                .article-section {
                    gap: 16px;
                    padding-left: 0;
                    padding-right: 0;
                    overflow-x: auto;
                    .card-slider {
                        width: 100%;
                        .discover-card {
                            margin: 0 8px;
                            max-width: 292px;
                            .card-button {
                                display: none;
                            }
                            .discover-card-info {
                                padding: 20px 16px;
                                border-radius: 8px;
                                position: relative;
                                top: -20px;
                                .card-title {
                                    margin-bottom: 4px;
                                }
                                hr {
                                    margin: 16px 0;
                                }
                                .icon-with-numbers {
                                    li {
                                        &:last-child {
                                            margin-bottom: 16px;
                                        }
                                    }
                                }
                                .card-button-mobile {
                                    display: block;
                                }
                            }
                        }
                        .discover-card.mobile-show {
                            display: block;
                        }
                    }
                    .recent-actions {
                        display: none;
                    }
                }
            }
            .peru-map {
                display: none;
            }
        }
    }
`;
