import React from 'react';
import css from './Form.css';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Input = props => {
    const { label, name, ...rest } = props;
    return (
        <div className={css.formControl}>
            <label htmlFor={name}>{label}</label>
            <Field name={name} id={name} {...rest} />
            <ErrorMessage component={TextError} name={name} className={css.error} />
        </div>
    );
};

export default Input;
