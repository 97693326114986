import React, { Component } from 'react';
import { string, func, oneOfType } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { NamedLink, InlineTextButton } from '../../components';
import { propTypes } from '../../util/types';

import css from './UserCard.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, bio } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const { rootClassName, className, user, currentUser, onContactUser, userDetails } = props;

  const classes = classNames(rootClassName || css.root, className);

  if(!userDetails) {
    return null
  } 

  const links = userDetails.id ? (
    <p className={css.links}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: userDetails.id, slug: userDetails.slug }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
    </p>
  ) : null;

  return (
    <div className={classes}>
          <div className={css.avatar}>
                <div className={css.profilePictureWrapper}>
                    <img className={css.profilePicture} src={userDetails.profilePicture}></img>
                </div>
                {links}
          </div>
    </div>
  )
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
};

export default UserCard;
