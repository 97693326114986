import React, { useState } from 'react';
import { connect } from 'react-redux';

import Header from '../../../NewLandingPage/Sections/Header/Header';
import Footer from '../../../NewLandingPage/Sections/Footer/Footer';
import { Page } from '../../../../components';
import StepNavigation from '../StepSection';
import { withRouter, useLocation } from 'react-router-dom';

import CreatingDocument from './CreatingDocument';
import DocumentSign from './DocumentSign';
import CreatingSign from './CreatingSign';
import ThankYouGuardian from './ThankYouGuardian';
import { MainForm, FormSection } from '../Styles';

const DocumentWorkFlow = ({ location, history, user, language }) => {
    const [pdfWidth, setPdfWidth] = useState(true);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [signatureDataUrl, setSignatureDataUrl] = useState(null);
    const [signedDocumentUrl, setSignedDocumentUrl] = useState(null);

    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    const region = searchParams.get('region');
    const lang = searchParams.get('lang');

    console.log('params', region);
    function checkToggle(isToggled) {
        setPdfWidth(isToggled);
    }
    const handleDocumentReady = url => {
        setDocumentUrl(url);
        // Navigate to the next step, e.g., display the document for signing
    };

    const handleSignatureDataUrl = imageUrl => {
        setSignatureDataUrl(imageUrl);
        // Navigate to the next step, e.g., display the document for signing
    };

    const handleSignedDocumentUrl = url => {
        setSignedDocumentUrl(url);
        // Navigate to the next step, e.g., display the document for signing
    };

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <MainForm>
                <StepNavigation step={3} checkToggle={checkToggle} />
                {!documentUrl && user && (
                    <CreatingDocument
                        name={user.name}
                        email={user.email}
                        region={region}
                        documentLanguage={lang}
                        onDocumentReady={handleDocumentReady}
                    />
                )}
                {documentUrl && !signatureDataUrl && (
                    <DocumentSign
                        documentUrl={documentUrl}
                        pdfWidth={pdfWidth}
                        name={user.name}
                        onSignatureDataUrlReady={handleSignatureDataUrl}
                    />
                )}
                {documentUrl && signatureDataUrl && !signedDocumentUrl && (
                    <CreatingSign
                        name={user.name}
                        email={user.email}
                        signatureDataUrl={signatureDataUrl}
                        onSignedDocumentReady={handleSignedDocumentUrl}
                    />
                )}
                {signedDocumentUrl && <ThankYouGuardian signedDocumentUrl={signedDocumentUrl} />};
            </MainForm>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
              email: storeState.user.currentUser.attributes.email,
          }
        : null,
    language: storeState.Languages.selectedLanguage.short,
});
export default connect(mapStateToProps)(withRouter(DocumentWorkFlow));
