import styled from 'styled-components';

export const FriendSection = styled.section`
    .green-bg {
        .text-white,
        .text-white p {
            br {
                display: none;
            }
        }
    }

    .center-text-btn {
        margin-top: 30px;
        gap: 0;
        button {
            max-width: 250px;
            margin: 20px auto 0;
        }
    }

    .panel-link {
        display: block;
        margin: 24px 0 48px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.light_green};
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .center-text-btn {
            p {
                font-size: 16px;
                margin-top: 5px;
            }
            button {
                margin-top: 12px;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
    }
`;

export const PlanContainer = styled.section`
    padding: 78px 4.8rem 110px;
    position: relative;
    &::before {
        content: ' ';
        background-image: url(${({ heroLeaf }) => heroLeaf});
        background-size: 100% auto;
        width: 104px;
        height: 178px;
        position: absolute;
        right: 0;
        top: -60px;
    }
    .title-box {
        margin-bottom: 60px;
    }
    .plan-tabs {
        display: flex;
        flex-wrap: no-wrap;
        justify-content: center;
        text-align: center;
        max-width: 350px;
        margin: 0 auto;
        border: 1px solid #ddedd0;
        border-radius: 40px;
        padding: 8px;
        gap: 10px;
    }
    .plan-group {
        gap: 20px;
        .plan-box {
            max-width: 415px;
            width: 100%;
            border: 2px solid #f3f2f2;
            &:hover {
                border: 2px solid #6ea44c;
            }
        }
        .plan-box-active {
            border: 2px solid #6ea44c;
        }
    }
    .showPlans {
        display: flex;
    }
    .hidePlans {
        display: none;
    }

    @media (max-width: 1175px) {
        padding: 32px 20px 50px;
        &::before,
        &::after {
            content: none;
        }
        .title-box {
            margin-bottom: 20px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.mobile}) {
        .plan-group {
            flex-wrap: wrap;
            gap: 10px;
        }
        .plan-tabs {
            flex-wrap: wrap;
        }
    }
`;
