import React, { Component } from 'react';
import { FaLink, FaRegPlayCircle } from "react-icons/fa";
import { FormattedMessage } from 'react-intl';
import eileen from '../../assets/Eileen-Wong.jpg';
import heroimage from '../../assets/heroempresas.jpg';
import co2 from '../../assets/icons/co2.svg';
import likestars from '../../assets/icons/likeStars.svg';
import network from '../../assets/icons/network.svg';
import payment from '../../assets/icons/payment-method.svg';
import kurt from '../../assets/Kurt-Holle2.jpg';
import afpPrima from '../../assets/logo-aliados/afpPrima.jpg';
import albergue from '../../assets/logo-aliados/Albergue.png';
import AmazonasExplorer from '../../assets/logo-aliados/AmazonasExplorer.png';
import AndeanLodges from '../../assets/logo-aliados/AndeanLodges.png';
import auqui from '../../assets/logo-aliados/auqui.jpg';
import ayni from '../../assets/logo-aliados/Ayni.png';
import cientifica from '../../assets/logo-aliados/cientifica.png';
import delosi from '../../assets/logo-aliados/delosi.jpg';
import explorandes from '../../assets/logo-aliados/explorandes.png';
import FloraYFauna from '../../assets/logo-aliados/FloraYFauna.jpg';
import GBC from '../../assets/logo-aliados/GBC.png';
import gerpal from '../../assets/logo-aliados/gerpal.png';
import hoseg from '../../assets/logo-aliados/hoseg.png';
import peru2021 from '../../assets/logo-aliados/peru2021.jpg';
import pukanina from '../../assets/logo-aliados/pukanina.jpg';
import Rainforest from '../../assets/logo-aliados/Rainforest.jpg';
import sistemab from '../../assets/logo-aliados/siste.jpg';
import strategik from '../../assets/logo-aliados/strategik.png';
import tedx from '../../assets/logo-aliados/tedx.png';
import toyota from '../../assets/logo-aliados/toyota.jpg';
import renzo from '../../assets/renzo.jpg';
import PlansComparison from '../../components/ChoosePlans/PlansComparison'
import { Hero, LayoutSingleColumn, LayoutWrapperFooter, LayoutWrapperMain, LayoutWrapperTopbar, Modal, NamedLink, ProductComparison, SecondaryButton, UpButton } from '../../components';
import { StaticPage } from '../../containers';
import css from './BusinessPage.css';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';

const orgVideosId=[
  {name:'HOSEG ',id:'301856766'},
  {name:'TEDxTukuy ',id:'294922118'},
  {name:'Explorandes  ',id:'280582005'},
  {name:'Flora & Fauna ',id:'280660735'},
  {name:'Amazonas',id:'278549592'},
  {name:'Andean Lodges',id:'278020970'},
  {name:'Auquí',id:'276474362'},
  {name:'GBC',id:'302335654'},
]

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}


class BusinessPage extends Component{

  constructor(props) {
    super(props);
    this.state = { isOpen: false, randomVideo:randomIntFromInterval(0,orgVideosId.length - 1) };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true});
  }

  render(){
    return (
      <StaticPage
        title="Organizaciones"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'AboutPage',
          description: 'Empresas e instituciones de todo tamaño que velan por la naturaleza transformando sus impactos ambientales en acciones positivas.',
          name: 'Organizaciones',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <Header transparent currentPath={this.props.location.pathname} user={this.props.user} history={this.props.history} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <Hero
              title={<FormattedMessage id='Business.title'/>}
              subtitle={<FormattedMessage id='Business.subtitle'/>}
              image={heroimage}
            />
            <section className={css.sectionContent}>
              <h2 className={css.title}>{<FormattedMessage id='Business.aboutSection.title'/>}
              </h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <div className={css.iconWrapperList}>
                    <img className={css.listIcon} src={likestars}/>
                  </div>
                  <p className={css.listItemText}><FormattedMessage id='Business.aboutSection.item1'/></p>
                </li>
                <li className={css.listItem}>
                  <div className={css.iconWrapperList}>
                    <img className={css.listIcon} src={co2}/>
                  </div>
                  <p className={css.listItemText}><FormattedMessage id='Business.aboutSection.item2'/></p>
                </li>
                <li className={css.listItem}>
                  <div className={css.iconWrapperList}>
                    <img className={css.listIcon} src={payment}/>
                  </div>
                  <p className={css.listItemText}><FormattedMessage id='Business.aboutSection.item3'/></p>
                </li>
                <li className={css.listItem}>
                  <div className={css.iconWrapperList}>
                    <img className={css.listIcon} src={network}/>
                  </div>
                  <p className={css.listItemText}><FormattedMessage id='Business.aboutSection.item4'/></p>
                </li>
              </ul>
              <div className={css.modalButtonContainer}>
                <SecondaryButton className={css.videoButton} onClick={()=>this.setState({videoModalOpen:true})}>
                  <span className={css.videoButton_text}><FormattedMessage id="Business.VideoText" values={{organizationName: orgVideosId[this.state.randomVideo]['name']}}/></span>
                  <FaRegPlayCircle/>
                </SecondaryButton>
              </div>
            </section>

            <section className={`${css.sectionContent} ${css.businessSection}`}>
              <h2 className={css.title}><FormattedMessage id='Business.organizations.title'/></h2>
              <div className={css.logosPicWrapper}>
                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b6cf-b5bb-4d42-b1ab-736c3f74e287"}}>
                    <FaLink className={css.IconPic}/><img src={AmazonasExplorer}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b454-5edc-4c2d-9922-a8455051a93f"}}>
                    <FaLink className={css.IconPic}/><img src={AndeanLodges}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b485-ba34-455d-b58a-33c46e616f64"}}>
                    <FaLink className={css.IconPic}/><img src={auqui}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b718-3427-404d-986a-c3ae5dcac06f"}}>
                    <FaLink className={css.IconPic}/><img src={ayni}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b526-60cd-4a75-8961-00c7ff8eab60"}}>
                    <FaLink className={css.IconPic}/><img src={albergue}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b296-901f-4c85-ae01-834edb9477a2"}}>
                    <FaLink className={css.IconPic}/><img src={explorandes}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b4a7-832f-4c37-b576-ef3f55edbc87"}}>
                    <FaLink className={css.IconPic}/><img src={FloraYFauna}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b6f6-1d41-4f30-97dd-74ad33066d55"}}>
                    <FaLink className={css.IconPic}/><img src={gerpal}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b6a8-3ae4-4ef5-9313-5d6ec787414c"}}>
                    <FaLink className={css.IconPic}/><img src={hoseg}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b5cc-d0b6-4113-9eac-a1cb9e3ead38"}}>
                    <FaLink className={css.IconPic}/><img src={afpPrima}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b325-8a45-4867-83d4-33ca35e76a18"}}>
                    <FaLink className={css.IconPic}/><img src={pukanina}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b59d-94d0-4adb-9ebe-8221c2343adf"}}>
                    <FaLink className={css.IconPic}/><img src={Rainforest}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b56a-ccd5-433f-b5f6-bcfdd0c08214"}}>
                    <FaLink className={css.IconPic}/><img src={strategik}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b603-8664-4164-baf0-b2693347b655"}}>
                    <FaLink className={css.IconPic}/><img src={cientifica}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9"}}>
                    <FaLink className={css.IconPic}/><img src={peru2021}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b650-6609-43fd-b677-5d93500b633b"}}>
                    <FaLink className={css.IconPic}/><img src={GBC}/>
                  </NamedLink>
                </div>

                <div className={css.logoSrcContainer}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b385-c220-4af4-bd73-ce817e93f34a"}}>
                    <FaLink className={css.IconPic}/><img src={sistemab}/>
                  </NamedLink>
                </div>

                <div className={`${css.logoSrcContainer} `}>
                  <NamedLink className={css.picLinksLogo} name="ProfilePage" params={{ slug:"5da7b352-8b0f-4d6b-8088-2981d17f8367"}}>
                    <FaLink className={css.IconPic}/><img src={tedx}/>
                  </NamedLink>
                </div>

                <div className={`${css.logoSrcContainer} ${css.logoNoLinks}`}>
                  <img src={toyota}/>
                </div>

                <div className={`${css.logoSrcContainer} ${css.logoNoLinks}`}>
                  <img src={delosi}/>
                </div>

              </div>
            </section>
            
            <section id="become" className={`${css.plans}`}>
              <div className={css.sectionContent}>
                <h2 className={css.title}><FormattedMessage id='SectionHome.helpTitle'/></h2>
                <p className={css.helpCopy}><FormattedMessage id='SectionHome.helpTextBusiness'/></p>
                <PlansComparison userType='empresas'/>
              <div className={css.sectionContent}>
                <div className={css.formContent}>
                  <p className={css.textForm}><FormattedMessage id='Business.bigOrganization.title'/></p>
                  <SecondaryButton className={css.buttonForm} onClick={this.handleOpen}><FormattedMessage id='Business.bigOrganization.button'/></SecondaryButton>
                </div>

                <Modal
                  {...this.props}
                  isOpen={this.state.isOpen}
                  onClose={() => {
                    this.setState({ isOpen: false });
                    console.log('Closing modal');
                  }}
                  onManageDisableScrolling={()=>{}}
                >
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdIKFuCEnpgdTL1R0gG401t3QbiiZZ1sbR8RapUxajCMCRBkg/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>

                </Modal>

              </div>
              </div>
            </section>



            <section id="testimonials" className={css.testimony}>
              <div className={css.sectionContent}>
                <h2 className={css.title}><FormattedMessage id='Testimonials.title.organisation'/></h2>
                <div className={css.testimonyList}>
                  <div>
                    <img className={css.testimonyPic}src={renzo}/>
                    <p className={css.testimonyDescription}><FormattedMessage id='Testimonials.renzo.description'/></p>
                    <p className={css.testimonyPeople}><strong><FormattedMessage id='Testimonials.renzo.name'/></strong> <span className={css.testimonyPeopleName}> - <FormattedMessage id='Testimonials.renzo.title'/></span></p>
                  </div>
                  <div>
                    <img className={css.testimonyPic}src={kurt}/>
                    <p className={css.testimonyDescription}><FormattedMessage id='Testimonials.kurt.description'/></p>
                    <p className={css.testimonyPeople}><strong><FormattedMessage id='Testimonials.kurt.name'/></strong> <span className={css.testimonyPeopleName}>  - <FormattedMessage id='Testimonials.kurt.title'/></span></p>
                  </div>
                  <div>
                    <img className={css.testimonyPic}src={eileen}/>
                    <p className={css.testimonyDescription}><FormattedMessage id='Testimonials.eileen.description'/></p>
                    <p className={css.testimonyPeople}><strong><FormattedMessage id='Testimonials.eileen.name'/></strong> <span className={css.testimonyPeopleName}>  - <FormattedMessage id='Testimonials.eileen.title'/></span></p>
                  </div>
                </div>
              </div>
            </section>
            <UpButton/>

          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer history={this.props.history} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        <Modal
          lightCloseButton={true}
          containerClassName={css.modalVideo}
          isOpen={this.state.videoModalOpen}
          onClose={()=>this.setState({videoModalOpen:false})}
          onManageDisableScrolling={()=>{}}
        >
          {
            this.state.videoModalOpen ?
            <React.Fragment>
              <iframe className={css.video} src={`https://player.vimeo.com/video/${orgVideosId[this.state.randomVideo]['id']}?autoplay=1&color=2DC000&title=0&byline=0&portrait=0`} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>
            </React.Fragment>
            : null

          }
        </Modal>
      </StaticPage>
    )
  }
};

const mapStateToProps = storeState => ({
  user: storeState.user.currentUser
    ? {
        initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
        name:
          storeState.user.currentUser.attributes.profile.firstName +
          ' ' +
          storeState.user.currentUser.attributes.profile.lastName,
      }
    : null,
});

export default connect(mapStateToProps)(withRouter(BusinessPage));

