import { FormattedMessage } from 'react-intl';
import React from 'react';

export const PlanPaymentInfo = {
    polen: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewFriendsPage.Setup.ChoosePlan.paymentInfo.polen"
        />
    ),
    semilla: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewFriendsPage.Setup.ChoosePlan.paymentInfo.seed"
        />
    ),
    arbol: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewFriendsPage.Setup.ChoosePlan.paymentInfo.tree"
        />
    ),
    bosque: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewFriendsPage.Setup.ChoosePlan.paymentInfo.forest"
        />
    ),
    paisaje: (
        <FormattedMessage
            values={{
                currency: <>{process.env.REACT_APP_CURRENCY_TYPE}</>,
            }}
            id="NewFriendsPage.Setup.ChoosePlan.paymentInfo.ls"
        />
    ),
};
