import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineTextButton ,NamedLink } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.css';




const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    isOwnListing,
    hostLink,
    listing,
    showContactUser,
    onContactUser,
    className,
    userType
  } = props;

  const profile = userType === "personas" ? "Personas" : "BusinessPage";
  const joinButton = <React.Fragment> 
          <NamedLink
                className={css.joinButton}
                name={`${profile}`}
                to={{selectedLandscape:listing.id}}
            >
            <FormattedMessage id="LandscapeListing.supportLandscapePageLink"/>
          </NamedLink>
  </React.Fragment>

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';



  return (
    <div className={`${css.sectionHeading} ${className ? className : ''}`}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        {joinButton}
      </div>
    </div>
  );
};

export default SectionHeading;
