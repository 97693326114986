import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { LightGreenBtn, OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import thanksIc from '../../../../assets/newAssets/icons/thankyou-ic.svg';
import { ExternalLink } from '../../../../components';

const ThankYouGuardianSection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .title-box {
        img {
            max-width: 67px;
            margin-bottom: 32px;
        }
    }
    .creation-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 20px;
            gap: 10px;
        }
    }
    @media (max-width: 500px) {
        .creation-box {
            padding: 100px 20px 120px;
            .input-box-section {
                margin-top: 40px;
            }
        }
    }
`;

const ThankYouGuardian = ({ signedDocumentUrl }) => {
    return (
        <ThankYouGuardianSection className="mx-auto flex justify-center items-center">
            <div className="creation-box mx-auto text-center relative">
                <div className="title-box text-center">
                    <img src={thanksIc} alt="thanks for document signing" />
                    <h2>
                        <FormattedMessage id="GuardianSubscription.DocuSign.ThanksDoc.title" />
                    </h2>
                </div>
                <p>
                    <FormattedMessage id="GuardianSubscription.DocuSign.ThanksDoc.desc" />
                </p>
                <div className="input-box-section flex flex-col">
                    <OrangeBtn>
                        <ExternalLink href={signedDocumentUrl}>
                            <FormattedMessage id="GuardianSubscription.DocuSign.DownloadDoc.label" />
                        </ExternalLink>
                    </OrangeBtn>
                    <LightGreenBtn href="/land-managers">
                        <Link to={'/land-managers'}>
                            <FormattedMessage id="GuardianSubscription.DocuSign.ThanksDoc.back" />
                        </Link>
                    </LightGreenBtn>
                </div>
            </div>
        </ThankYouGuardianSection>
    );
};

export default ThankYouGuardian;
