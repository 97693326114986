import React from 'react';
import PropTypes from 'prop-types';
import { StyledImage } from './Styles';

const Image = ({ rounded, size, source, title }) => {
  return <StyledImage src={source} alt={title} rounded={rounded} size={size} />;
};

Image.propTypes = {
  rounded: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'full']).isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Image.defaultProps = {
  rounded: true,
  size: '',
  source: '',
  title: '',
};

export default Image;
