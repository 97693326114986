import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import Header from '../../NewLandingPage/Sections/Header/Header';
import Footer from '../../NewLandingPage/Sections/Footer/Footer';
import { Page } from '../../../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import GuardianRoleOptions from '../Sections/GuardianRoleOptions';

import StepNavigation from './StepSection';
import { MainForm, FormSection } from './Styles';

const GuardiansRoleForm = ({ location, history, user }) => {
    const selectedRole = useSelector(state => state.GuardianReducer.selectedRole);

    const handleRoleSubmit = () => {
        history.push('/land-managers/signup');
    };
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <MainForm>
                <StepNavigation step={1} />
                <FormSection>
                    <div className="show-flex-tab justify-center breadcrumb relative mx-auto flex">
                        <span className="breadbrumb-link active">
                            <FormattedMessage id="GuardianSubscription.StepForm.Step1.title" />
                        </span>

                        {selectedRole ? (
                            <Link
                                to={selectedRole ? '/land-managers/signup' : '#'}
                                className="breadbrumb-link"
                            >
                                <FormattedMessage id="GuardianSubscription.StepForm.FillDetails" />
                            </Link>
                        ) : (
                            <span className="breadbrumb-link">
                                <FormattedMessage id="GuardianSubscription.StepForm.FillDetails" />
                            </span>
                        )}

                        <span className="breadbrumb-link">Sign Letter</span>
                    </div>
                    <div className="formnextprev justify-space-between relative w-full flex items-center">
                        <button className="prev-btn absolute flex items-center hidden">
                            <IoIosArrowBack />{' '}
                            <small>
                                <FormattedMessage id="Subscription.StepForm.previous" />
                            </small>
                        </button>
                        <button
                            className={`next-btn absolute flex items-center ${
                                !selectedRole ? 'disable' : null
                            }`}
                            onClick={selectedRole && handleRoleSubmit}
                        >
                            <small>
                                <FormattedMessage id="Subscription.StepForm.next" />
                            </small>{' '}
                            <IoIosArrowForward />
                        </button>
                    </div>
                    <GuardianRoleOptions handleRoleSubmit={handleRoleSubmit} />

                    <div className="step-nav hide-tab flex mx-auto">
                        <div className="bullet current"></div>
                        <div className="bullet"></div>
                        <div className="bullet"></div>
                    </div>
                </FormSection>
            </MainForm>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = state => {
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
              email: state.user.currentUser.attributes.email,
              firstName: state.user.currentUser.attributes.profile.firstName,
              lastName: state.user.currentUser.attributes.profile.lastName,
              cartCount: state.user.currentUser.cartCount,
              currentRole: state.user.currentUser.currentRole,
              activatedRoles: state.user.currentUser.activatedRoles,
          }
        : null;

    const { isAuthenticated } = state.Auth;
    const { currentUser } = state.user;
    return {
        user,
        currentUser,
        isAuthenticated,
    };
};

export default connect(mapStateToProps)(withRouter(GuardiansRoleForm));
