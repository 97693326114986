import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';

export const Error = styled.div`
    color:#ff0000;
    text-align:center;
    padding:20px 0px;
`;
export const DetailsContainer =styled.div`
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
`;
export const SaveButton = styled.div`
    width:100%;
    text-align:center;
    & > Button{
        width:347px;
        height:73px;
        margin: 40px auto;
        margin-top:50px;
        margin-bottom:80px;
        background: #F29509;
        border-radius: 5px;
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-weight: bold;
        font-size: 23.1858px;
        line-height: 31px;
        color: #FFFFFF;
    }
`;