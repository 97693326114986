

const initialState = {
    visible : false,
    error : ''
  };
  
  export const INDIVIDUAL_USER_SHOW_ERROR = 'app/Toast/INDIVIDUAL_USER_SHOW_ERROR';
  export const ORGANISATION_USER_SHOW_ERROR = 'app/Toast/ORGANISATION_USER_SHOW_ERROR';
//'Log in as organisation to access footprint calculator for organisations'
//'Log in as individual to access footprint calculator for individuals'
  export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
      case INDIVIDUAL_USER_SHOW_ERROR:
        return { ...state, visible : payload.visible, error: payload.error };
      case ORGANISATION_USER_SHOW_ERROR:
        return { ...state, visible : payload.visible, error: payload.error };
      default:
        return state;
    }
  }



  export const IndividualErrorAction = error => ({
    type: INDIVIDUAL_USER_SHOW_ERROR,
    payload: error,
  });

  export const OrganisationErrorAction = error => ({
    type: ORGANISATION_USER_SHOW_ERROR,
    payload: error,
  });

  