import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as ArrowDown } from '../../../../../assets/newAssets/arrow-down-grey.svg';
import { ItemContainer,Dropdown, DropdownItem, Input, Holder, Row, Label,InputButton, ButtonContainer } from './Styles.js';
import { getEmissionActivities, getUnits} from '../../../../../coreApi';
import Button from '../../../../../newComponents/Button';
import { FormattedMessage } from 'react-intl';
 export const ActivityItem = (props) =>{


    const [isOpenFuel, setIsOpenFuel] = useState(false);
    const [selectedOptionFuel, setSelectedOptionFuel] = useState("");
    const [isOpenUnit, setIsOpenUnit] = useState(false);
    const [selectedOptionUnit, setSelectedOptionUnit] = useState("");
    const [activities,setActivities]=useState([]);
    const [fuelType,setFuelType] = useState([]);
    const [Units,setUnits] = useState([]);
    const [UnitType,setUnitType] = useState([]);
    const [userData, setUserData] = useState(props.data);
    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);
    const inputField    = useRef(null);

    const handleOutsideClick = e => {
        if (containerRef1.current && !containerRef1.current.contains(e.target)) {
            setIsOpenFuel(false);
        }
        if (containerRef2.current && !containerRef2.current.contains(e.target)) {
            setIsOpenUnit(false);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            const emissionActivitiesData = props.emissionActivityDetails;
            let fuelType=[];
            emissionActivitiesData.map((data) => {
                if(data.groupName===props.type)
                {
                    setActivities(data.activities);
                    data.activities.map((data,i) => {
                        fuelType[i]=data.activityName;
                    })
                }
            })
                setFuelType(fuelType);
            
             const Units = props.units;
             setUnits(Units);
        }
        fetchData();
    }, []);
   
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [containerRef1,containerRef2]);

    const openFuelType = () => {
        setIsOpenFuel(!isOpenFuel);
    };
    const openUnit = () => {
        activities.map((data,i) => {
            if(props.data.fuelType!=''&&data.activityName===props.data.fuelType)
            {
                setUnitType(Units[data.quantityType]);
            }
        })
        if(props.data.fuelType==''){
            setIsOpenUnit(false);
        }
        else{
            setIsOpenUnit(!isOpenUnit);
        }
        
    };

    function onSelectedFuel(value) {
        setSelectedOptionFuel(value);
        setIsOpenFuel(false);
        setUserData({...userData,fuelType:value});
        // activities.map((data,i) => {
        //     if(props.data.fuelType!=''&&data.activityName===props.data.fuelType)
        //     {
        //         setUnitType(Units[data.quantityType]);
        //     }
        // })
        props.data.fuelType=value;
        activities.map((data,i) => {
            if(props.data.fuelType!=''&&data.activityName===props.data.fuelType)
            {
                setUnitType(Units[data.quantityType]);
            }
        })
        const index= activities.findIndex((data) => data.activityName===value);
        if(index!=-1){
            props.data.activityId=activities[index].id;
        }
        props.saveData(props.data);
        if(index!=-1){
            onSelectedUnit(activities[index].efUnit);
        }
    };

    function onSelectedUnit(value) {
        setSelectedOptionUnit(value);
        setUserData({...userData,unit:value});
        setIsOpenUnit(false);
        props.data.unit=value;
        props.saveData(props.data);
    };
    let name, value;
    const handleChange = e => {
        const validNumber=/^(\d*|\d+\.\d{0,3}|\d*\.\d{1,3})$/;
        if(validNumber.test(e.target.value))
        {
            name = e.target.name;
            value =e.target.value;
            setUserData({ ...userData, [name]: value });
            if(value===""){
                props.data.value=0;
            }
            else{
                props.data.value=parseFloat(value);
            }
            
            props.saveData(props.data);
        }
    };
    
     return(
        <ItemContainer>
            <Row>
                <Holder>
                <Label htmlFor="name">
                            {props.label}
                        </Label>
                    <InputButton
                        name="fuelType"
                        onClick={openFuelType}
                    >
                        {(selectedOptionFuel || userData && userData.fuelType)} <ArrowDown className="arrow" width="20px" height="20px" />
                    </InputButton>
                    {isOpenFuel && (
                        <Dropdown ref={containerRef1}>
                            {fuelType.map((item,i) => (
                                <DropdownItem key={i} onClick={() => onSelectedFuel(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )
                    }
                </Holder>
                <Holder>
                <Label htmlFor="name">
                            Value
                        </Label>
                    <Input
                        type="text"
                        placeholder="Value"
                        name="value"
                        value={userData.value}
                        onChange={handleChange}
                        ref={inputField}
                    />
                </Holder>
                <Holder>
                <Label htmlFor="name">
                            Unit
                        </Label>
                    <InputButton
                        name="unit"
                        onClick={openUnit}
                    >
                        {(selectedOptionUnit|| userData && userData.unit)} <ArrowDown className="arrow" width="20px" height="20px" />
                    </InputButton>
                    {isOpenUnit && (
                        <Dropdown ref={containerRef2}>
                            {UnitType.map((item,i) => (
                                <DropdownItem key={i} onClick={() => onSelectedUnit(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )
                    }
                </Holder>
                <ButtonContainer>
                        <Button
                            onClick={() => {props.deleteData(props.data.id)}}
                            variant="filled"
                            size="lg"
                        >
                            <FormattedMessage id="OrganisationCalculator.Button.Remove"/>
                        </Button>
                </ButtonContainer>
            </Row>
            
        </ItemContainer>
     );
 }

 export default ActivityItem;