import React, { useState, useEffect } from 'react';
import Header from '../../../../myNaturePage/sections/Header/Header';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../../sections/LeftNav/LeftNav';
import RightNav from '../../../../myNaturePage/sections/RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import Button from '../../../../../newComponents/Button';
import { getCurrentUser, updateUser, updateAccount, uploadImage } from '../../../../../coreApi';
import { fetchCurrentUser } from '../../../../../ducks/user.duck';
import FileUpload from '../../../../myNaturePage/FileUpload';
import { format } from '../../../../myNaturePage/sections/pages/ProfileAndAcount/fiFormatter';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { isNil, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
    AccountForm,
    Title,
    AccountContainer,
    SubTitle,
    Form,
    FormContainer,
    PhotoUpload,
    Label,
    ErrorMessage,
    ButtonContainer,
    StyledSuccessMessage,
    FirstName, LastName, NewDiv,
    Row1, Row2, Row3, Email, Number, City, Country, Input, 
} from '../../../../myNaturePage/sections/pages/ProfileAndAcount/Styles';
import { ProfileAndAccountMain } from './Styles';
import HeroImage from '../../../../../assets/newAssets/guardian_profile.png';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
        }
        : null,

});

const ProfileAndAccountPage = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false);
    const [errorCountry, setErrorCountry] = useState(false);
    const [errorCity, setErrorCity] = useState(false);
    const [successAccount, setSuccessAccount] = useState(false);
    const [metadata, setMetadata] = useState();
    const dispatch = useDispatch();
    const SuccessMessage = <StyledSuccessMessage><FormattedMessage  id="OurNaturePage.Profile.Success"/></StyledSuccessMessage>;

    const [imageData, setImageData] = useState();
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
        city: "",
        metadata: {},
    });
    
    let checkForm = (isNil(userData.firstName) || isEmpty(userData.firstName)) || (isNil(userData.lastName) || isEmpty(userData.lastName))
        || (isNil(userData.email) || isEmpty(userData.email)) || (isNil(userData.phoneNumber) || isEmpty(userData.phoneNumber))
        || (isNil(userData.city) || isEmpty(userData.city)) || (isNil(userData.country) || isEmpty(userData.country));
    useEffect(() => {
        const fetchData = async () => {
            const res = await getCurrentUser();
            setUserData(res.data);
            if (res.data.metadata) {
                setMetadata(res.data.metadata);
            }
        }

        fetchData();
    }, []);


    let name, value;
    const handleChange = e => {
        name = e.target.name;
        value = e.target.value;
        if((name==='firstName'||name==='lastName'||name==='city'||name==='country')){
            value=value.replace(/[^A-Za-z ]/ig, '')
            setUserData({ ...userData, [name]: value });
        }
        else if(name==='phoneNumber'){
            setUserData({ ...userData, [name]: value });
        }
    };

    useEffect(() => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email) === true) {
            setErrorEmail(false);
        }
        if (userData.firstName && userData.firstName.length > 2) {
            setErrorFirstName(false);
        }
        if (userData.lastName && userData.lastName.length > 2) {
            setErrorLastName(false);
        }
        if (userData.city && userData.city.length > 2) {
            setErrorCity(false);
        }
        if (userData.country && userData.country.length > 2) {
            setErrorCountry(false);
        }
        if (userData.phoneNumber && userData.phoneNumber.length > 8) {
            setErrorNumber(false);
        }
    });
    
    const postData = async e => {
        e.preventDefault();
        let emailError = false;
        let firstNameError = false;
        let lastNameError = false;
        let cityError = false;
        let countryError = false;
        let numberError = false;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email) === false) {
            emailError = true;
            setErrorEmail(true);
            setSuccessAccount(false);
        }
        if (userData.firstName && userData.firstName.length < 2) {
            firstNameError = true;
            setErrorFirstName(true);
            setSuccessAccount(false);
        }
        if (userData.lastName && userData.lastName.length < 2) {
            lastNameError = true;
            setErrorLastName(true);
            setSuccessAccount(false);
        }
        if (userData.city && userData.city.length < 2) {
            cityError = true;
            setErrorCity(true);
            setSuccessAccount(false);
        }
        if (userData.country && userData.country.length < 2) {
            countryError = true;
            setErrorCountry(true);
            setSuccessAccount(false);
        }
        if (userData.phoneNumber && userData.phoneNumber.length < 10) {
            numberError = true;
            setErrorNumber(true);
            setSuccessAccount(false);
        }

        if (emailError === false && firstNameError === false &&
            lastNameError === false && cityError === false &&
            countryError === false && numberError === false) {
            const newUserDetails = {
                city: userData.city, country: userData.country,
            };
            const newAccountDetails = {
                phoneNumber: userData.phoneNumber,
                firstName: userData.firstName, lastName: userData.lastName,
            };
            if (imageData) {
                const send = await uploadImage(imageData);
                if (send.data && send.data.fileId) {
                    newAccountDetails.profilePicture = send.data.fileId;
                }
            }
            await updateAccount(newAccountDetails).then(async() =>{
                await updateUser(newUserDetails).then(()=>{
                    dispatch(fetchCurrentUser());
                })
                
            });
            setSuccessAccount(true);

        }
    };

    const firstNamePlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.firstNamePlaceholder' });
    const lastNamePlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.lastNamePlaceholder' });
    const emailPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.EmailPlaceholder' });
    const numberPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.NumberPlaceholder' });
    const cityPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.CityPlaceholder' });
    const countryPlaceholder = intl.formatMessage({ id: 'MyNaturePage.Profile.CountryPlaceholder' });

    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Guardian profile<br/>& account</>}
                image={HeroImage}
            />
            <ProfileAndAccountMain>
                <AccountForm>
                    <Title>
                        <FormattedMessage id="MyNaturePage.Profile.ProfileTitle" />
                    </Title>
                    <AccountContainer>
                        <SubTitle>
                            <FormattedMessage id="MyNaturePage.Profile.ProfileSubTitle" />
                        </SubTitle>
                        <FormContainer>
                            <NewDiv>
                                <Form>
                                    <Row1
                                        status={(errorFirstName) && 'failure'}
                                    >
                                        <FirstName
                                            status={(errorFirstName) && 'failure'}
                                        >
                                            <Label htmlFor="name">
                                                <FormattedMessage id="MyNaturePage.Profile.firstNameLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={firstNamePlaceholder}
                                                name="firstName"
                                                status={(errorFirstName) && 'failure'}
                                                value={userData.firstName}
                                                onChange={handleChange}

                                            />
                                            {(errorFirstName) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.LessWords" />
                                                </ErrorMessage>
                                            )}
                                        </FirstName>
                                        <LastName
                                            status={(errorLastName) && 'failure'}
                                        >
                                            <Label htmlFor="name">
                                                <FormattedMessage id="MyNaturePage.Profile.lastNameLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={lastNamePlaceholder}
                                                name="lastName"
                                                status={(errorLastName) && 'failure'}
                                                value={userData.lastName}
                                                onChange={handleChange}
                                            />
                                            {(errorLastName) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.LessWords" />
                                                </ErrorMessage>
                                            )}
                                        </LastName>
                                    </Row1>
                                    <Row2>
                                        <Email
                                            status={(errorEmail) && 'failure'}
                                        >
                                            <Label htmlFor="email">
                                                <FormattedMessage id="MyNaturePage.Profile.EmailLabel" />
                                            </Label>
                                            <Input
                                                type="email"
                                                placeholder={emailPlaceholder}
                                                status={(errorEmail) && 'failure'}
                                                name="email"
                                                value={userData.email}
                                                onChange={handleChange}
                                            />
                                            {(errorEmail) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Email" />
                                                </ErrorMessage>
                                            )}
                                        </Email>
                                        <Number
                                            status={(errorNumber) && 'failure'}
                                        >
                                            <Label htmlFor="number">
                                                <FormattedMessage id="MyNaturePage.Profile.NumberLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder={numberPlaceholder}
                                                status={(errorNumber) && 'failure'}
                                                name="phoneNumber"
                                                value={format(userData.phoneNumber)}
                                                onChange={handleChange}
                                            />
                                            {(errorNumber) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Number" />
                                                </ErrorMessage>
                                            )}
                                        </Number>
                                    </Row2>
                                    <Row3>
                                        <City
                                            status={(errorCity) && 'failure'}
                                        >
                                            <Label htmlFor="city">
                                                <FormattedMessage id="MyNaturePage.Profile.CityLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                maxLength="20"
                                                placeholder={cityPlaceholder}
                                                status={(errorCity) && 'failure'}
                                                name="city"
                                                value={userData.city}
                                                onChange={handleChange}
                                            />
                                            {(errorCity) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.City" />
                                                </ErrorMessage>
                                            )}
                                        </City>
                                        <Country
                                            status={(errorCountry) && 'failure'}
                                        >
                                            <Label htmlFor="country">
                                                <FormattedMessage id="MyNaturePage.Profile.CountryLabel" />
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder={countryPlaceholder}
                                                status={(errorCountry) && 'failure'}
                                                name="country"
                                                value={userData.country}
                                                onChange={handleChange}
                                            />
                                            {(errorCountry) && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="MyNaturePage.Error.Country" />
                                                </ErrorMessage>
                                            )}
                                        </Country>
                                    </Row3>
                                </Form>

                                <PhotoUpload>
                                    <FileUpload picture={userData.profilePicture} imgData={(data) => { setImageData(data) }} />
                                </PhotoUpload>
                            </NewDiv>
                            <ButtonContainer>
                                {!checkForm ? <Button
                                    onClick={postData}
                                    variant="filled"
                                    size="lg"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.UpdateUser" />
                                </Button> : <Button
                                    variant="disabled"
                                    size="lg"
                                >
                                    <FormattedMessage id="MyNaturePage.Profile.UpdateUser" />
                                </Button>}

                            </ButtonContainer>
                            {successAccount && (SuccessMessage)}
                        </FormContainer>
                    </AccountContainer>
                </AccountForm>
                <MobileComp><RightNav /></MobileComp>
            </ProfileAndAccountMain>
            <RightNav />
        </Frame>
    )
}
);
const GuardianProfileAndAccountPage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(ProfileAndAccountPage);
export default GuardianProfileAndAccountPage;
