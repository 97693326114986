import React, { useState, useEffect, useRef } from 'react';
import RegionMobile from '../../assets/calculator/region-mobile.svg';
import RegionWeb from '../../assets/calculator/region-web.svg';
import Fact from '../../assets/calculator/exclamation.svg';
import Sectors from '../../assets/calculator/green-sectors.svg';
import Residence from '../../assets/calculator/residence.svg';
import People from '../../assets/calculator/people.svg';
import Appliances from '../../assets/calculator/appliances.svg';
import Transport from '../../assets/calculator/transport.svg';
import Travel from '../../assets/calculator/travel.svg';
import FoodCart1 from '../../assets/calculator/blob-food-cart.svg';
import Cart from '../../assets/calculator/single-cart.svg';
import Bin from '../../assets/calculator/recycle-bin.svg';
import Co2Cloud from '../../assets/calculator/co2cloud-new.svg';
import serious from '../../assets/calculator/serious.svg';
import deadly from '../../assets/calculator/deadly.svg';
import catastrophic from '../../assets/calculator/catastrophic.svg';
import { ReactComponent as Earth } from '../../assets/calculator/earth.svg';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { TopbarCalculator, NamedLink } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import css from './CalculatorPage.css';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import Timeline from '../../components/Timeline/CalculatorTimeline';
import { ComponentTransition, AnimationTypes } from 'react-component-transition';
import { calculateFootprint } from '../../coreApi';
import { console, document } from 'window-or-global';
import { TopbarContainer } from '../../containers';
import { useDispatch } from 'react-redux';
import { IndividualErrorAction } from '../../ducks/Toast.duck';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
});

const PersonalFootprintCalculatorPage = connect(mapStateToProps)(({ intl, history, user }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [ecoResults, setEcoResults] = useState({});
    const [calculationFlag, setCalculationFlag] = useState(true);
    const [fractionEarth, setFractionEarth] = useState(0);
    const [selected, setSelected] = useState(0);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [LandDeforested, setLandDeforested] = useState(null);
    const historyPath = useHistory();
    const dispatch = useDispatch();
    const questionsObject = {
        regionSection: '',
        residenceSection: '',
        householdMembers: '',
        energyConsumption: '',
        commuteMode: '',
        travelMode: '',
        foodHabits: '',
        foodSource: '',
        wasteGenerated: '',
    };

    const images = [
        RegionMobile,
        RegionWeb,
        Fact,
        Sectors,
        Residence,
        People,
        Appliances,
        Transport,
        Travel,
        FoodCart1,
        Cart,
        Bin,
        Co2Cloud,
    ];

    const cacheImages = async images => {
        const promises = await images.map(image => {
            return new Promise((resolve, reject) => {
                const img = new Image();

                img.src = image;
                image.onload = resolve();
                image.onerror = reject();
            });
        });
        await Promise.all(promises);
    };

    useEffect(() => {
        cacheImages(images);
    }, []);

    const titles = [
        'regionSection',
        'residenceSection',
        'householdMembers',
        'energyConsumption',
        'commuteMode',
        'travelMode',
        'foodHabits',
        'foodSource',
        'wasteGenerated',
    ];

    const [calculatorState, setCalculatorState] = useState(questionsObject);
    const handleAction = () => {
        history.push('/subscribe/login');
    };

    useEffect(() => {
        if (user && user.userType === 'empresa') {
            dispatch(
                IndividualErrorAction({
                    visible: true,
                    error: 'Log in as individual to access footprint calculator for individuals',
                })
            );
            historyPath.push('/');
        }
    }, [user]);
    useEffect(() => {
        setNextEnabled(false);
    }, [currentStep]);

    const handleClick = (event, section) => {
        const objMutable = { ...calculatorState };
        objMutable[section] = event.target.value;
        setCalculatorState(objMutable);
        setNextEnabled(true);
        setIsBackButtonPressed(false);
        setCurrentStep(currentStep + 1);
        if (section == 'wasteGenerated') {
            setCalculationFlag(true);
        }
    };

    const handleOptionClick = (val, section) => {
        const objMutable = { ...calculatorState };
        if (val !== objMutable[section]) {
            objMutable[section] = val;
            setCalculatorState(objMutable);
        }
        setCurrentStep(currentStep + 1);
        if (section == 'wasteGenerated') {
            setCalculationFlag(true);
        }
    };

    const planetHandler = planets => {
        let whole = Math.floor(planets);
        let fraction = planets - whole;
        fraction = 132 * fraction;
        setFractionEarth(fraction);
    };

    const capPlanets = planets => {
        if (planets > 5) {
            return 5;
        } else {
            return planets;
        }
    };

    const squareMetersHandler = hectars => {
        let squareMeters = (hectars * 10000).toString().split('.')[0];
        let result = '';

        let i = squareMeters.length - 1;

        while (i > 0) {
            if (i - 3 > 0) {
                result = ',' + squareMeters.substring(i - 2, i + 1) + result;
            } else {
                result = squareMeters.substring(i - 2, i + 1) + result;
            }
            i = i - 3;
        }
        return result;
        /* convert hectars into square meters */
    };

    const footballFieldsHandler = hectars => {
        return Math.round(hectars * 0.82);
        /* convert hectars into football fields */
    };

    useEffect(() => {
        if (currentStep === 9 && calculationFlag) {
            calculateFootprint(calculatorState).then(res => {
                console.log(res.data);
                planetHandler(res.data.answers.planets);
                setEcoResults(res.data.answers);
                setLandDeforested((res.data.answers.total_cf * 10000) / 400);
            });
        }
    }, [currentStep, calculationFlag]);

    const earths = Array.from(
        Array(ecoResults.planets ? Math.floor(capPlanets(ecoResults.planets)) : 0),
        (e, i) => {
            return (
                <Earth width="80px" key={i}>
                    {i}
                </Earth>
            );
        }
    );

    let chartData = [];

    if (ecoResults && ecoResults.ef_percentage) {
        chartData = [
            ecoResults.ef_percentage.residence.toFixed(1),
            ecoResults.ef_percentage.energy.toFixed(1),
            ecoResults.ef_percentage.transport.toFixed(1),
            ecoResults.ef_percentage.food.toFixed(1),
            ecoResults.ef_percentage.waste.toFixed(1),
        ];
    }

    useEffect(() => {
        Array.from(document.getElementsByClassName('regionSelect')).forEach(element => {
            element.addEventListener('mouseup', e => {
                if (e.target.value === calculatorState.regionSection) {
                    setCurrentStep(currentStep + 1);
                }
            });
        });
    }, []);

    /*** new code */
    const getCurrentData = () => {
        if (currentStep === 1) {
            return questionsData.residenceSection;
        } else if (currentStep === 2) {
            return questionsData.householdMembers;
        } else if (currentStep === 3) {
            return questionsData.energyConsumption;
        } else if (currentStep === 4) {
            return questionsData.commuteMode;
        } else if (currentStep === 5) {
            return questionsData.travelMode;
        } else if (currentStep === 6) {
            return questionsData.foodHabits;
        } else if (currentStep === 7) {
            return questionsData.foodSource;
        } else if (currentStep === 8) {
            return questionsData.wasteGenerated;
        }
    };

    const questionsData = {
        residenceSection: {
            id: 1,
            title: 'residenceSection',
            image: Residence,
            questionId: 'CalculatorPage.residence.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.residence.options.title', value: '' },
                { formatted: true, id: 'CalculatorPage.residence.options.large', value: 'large' },
                { formatted: true, id: 'CalculatorPage.residence.options.medium', value: 'medium' },
                { formatted: true, id: 'CalculatorPage.residence.options.small', value: 'small' },
                { formatted: true, id: 'CalculatorPage.residence.options.flat', value: 'flat' },
                { formatted: true, id: 'CalculatorPage.residence.options.zero', value: 'zero' },
            ],
            disclaimerId: 'CalculatorPage.residence.fact',
        },
        householdMembers: {
            id: 2,
            image: People,
            title: 'householdMembers',
            questionId: 'CalculatorPage.people.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.people.options.title', value: '' },
                { formatted: false, value: 'zero', id: '0' },
                { formatted: false, value: 'one', id: '1' },
                { formatted: false, value: 'two', id: '2' },
                { formatted: false, value: 'three', id: '3' },
                { formatted: false, value: 'four', id: '4' },
                { formatted: false, value: 'five', id: '5' },
                { formatted: false, value: 'six', id: '6' },
                { formatted: false, value: 'seven', id: '6+' },
            ],
            disclaimerId: 'CalculatorPage.people.fact',
        },
        energyConsumption: {
            id: 3,
            image: Appliances,
            title: 'energyConsumption',
            questionId: 'CalculatorPage.energyConsumption.text',
            optionIds: [
                {
                    formatted: true,
                    id: 'CalculatorPage.energyConsumption.options.title',
                    value: '',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.energyConsumption.options.high',
                    value: 'high',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.energyConsumption.options.average',
                    value: 'average',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.energyConsumption.options.low',
                    value: 'low',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.energyConsumption.options.xlow',
                    value: 'xlow',
                },
            ],
            disclaimerId: 'CalculatorPage.energyConsumption.fact',
        },
        commuteMode: {
            id: 4,
            image: Transport,
            title: 'commuteMode',
            questionId: 'CalculatorPage.commuteMode.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.commuteMode.options.title', value: '' },
                { formatted: true, id: 'CalculatorPage.commuteMode.options.plane', value: 'plane' },
                { formatted: true, id: 'CalculatorPage.commuteMode.options.car', value: 'car' },
                {
                    formatted: true,
                    id: 'CalculatorPage.commuteMode.options.carpooling',
                    value: 'carpooling',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.commuteMode.options.public',
                    value: 'public',
                },
                { formatted: true, id: 'CalculatorPage.commuteMode.options.bike', value: 'bike' },
                {
                    formatted: true,
                    id: 'CalculatorPage.commuteMode.options.electricV',
                    value: 'electricV',
                },
                { formatted: true, id: 'CalculatorPage.commuteMode.options.cycle', value: 'cycle' },
            ],
            disclaimerId: 'CalculatorPage.commuteMode.fact',
        },
        travelMode: {
            id: 5,
            image: Travel,
            title: 'travelMode',
            questionId: 'CalculatorPage.travelMode.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.travelMode.options.title' },
                { formatted: true, id: 'CalculatorPage.travelMode.options.close', value: 'close' },
                { formatted: true, id: 'CalculatorPage.travelMode.options.short', value: 'short' },
                { formatted: true, id: 'CalculatorPage.travelMode.options.long', value: 'long' },
            ],
            disclaimerId: 'CalculatorPage.travelMode.fact',
        },
        foodHabits: {
            id: 6,
            image: FoodCart1,
            title: 'foodHabits',
            questionId: 'CalculatorPage.foodHabits.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.foodHabits.options.title' },
                {
                    formatted: true,
                    id: 'CalculatorPage.foodHabits.options.carnivore',
                    value: 'carnivore',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.foodHabits.options.regular',
                    value: 'regular',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.foodHabits.options.occasional',
                    value: 'occasional',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.foodHabits.options.vegetarian',
                    value: 'vegetarian',
                },
                { formatted: true, id: 'CalculatorPage.foodHabits.options.vegan', value: 'vegan' },
            ],
            disclaimerId: 'CalculatorPage.foodHabits.fact',
        },
        foodSource: {
            id: 7,
            image: Cart,
            title: 'foodSource',
            questionId: 'CalculatorPage.foodSource.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.foodSource.options.title', value: '' },
                {
                    formatted: true,
                    id: 'CalculatorPage.foodSource.options.processed',
                    value: 'processed',
                },
                { formatted: true, id: 'CalculatorPage.foodSource.options.half', value: 'half' },
                { formatted: true, id: 'CalculatorPage.foodSource.options.local', value: 'local' },
            ],
            disclaimerId: 'CalculatorPage.foodSource.fact',
        },
        wasteGenerated: {
            id: 8,
            image: Bin,
            title: 'wasteGenerated',
            questionId: 'CalculatorPage.wasteGenerated.text',
            optionIds: [
                { formatted: true, id: 'CalculatorPage.wasteGenerated.options.title', value: '' },
                {
                    formatted: true,
                    id: 'CalculatorPage.wasteGenerated.options.above',
                    value: 'above',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.wasteGenerated.options.average',
                    value: 'average',
                },
                {
                    formatted: true,
                    id: 'CalculatorPage.wasteGenerated.options.below',
                    value: 'below',
                },
            ],
            disclaimerId: 'CalculatorPage.wasteGenerated.fact',
        },
    };

    const QuestionnaireContainer = ({ data }) => {
        return (
            <div>
                <div className={css.Container}>
                    <div className={css.leftContainer}>
                        <h2 className={css.question}>
                            <FormattedMessage id={data.questionId} />
                        </h2>
                        <select
                            className={css.select}
                            defaultValue={
                                calculatorState[titles[data.id]] !== ''
                                    ? calculatorState[titles[data.id]]
                                    : ''
                            }
                            onChange={e => handleClick(e, data.title)}
                        >
                            <FormattedMessage id={data.optionIds[0].id}>
                                {message => (
                                    <option disabled={true} value="">
                                        {message}
                                    </option>
                                )}
                            </FormattedMessage>
                            {data.optionIds.slice(1).map(option =>
                                option.formatted ? (
                                    <FormattedMessage id={option.id} key={`${option.id}`}>
                                        {message => (
                                            <option
                                                onClick={e => handleOptionClick(e)}
                                                value={option.value}
                                            >
                                                {message}
                                            </option>
                                        )}
                                    </FormattedMessage>
                                ) : (
                                    <option
                                        key={option.id}
                                        onClick={e => handleOptionClick(e)}
                                        value={option.value}
                                    >
                                        {option.id}
                                    </option>
                                )
                            )}
                        </select>
                        <div className={css.navigationButtonsContainer}>
                            <button
                                onClick={() => {
                                    setIsBackButtonPressed(true);
                                    setCurrentStep(currentStep - 1);
                                }}
                                className={css.navigationButtons}
                            >
                                <FormattedMessage id="CalculatorPage.backButton" />
                            </button>
                            {(nextEnabled || calculatorState[titles[data.id]] !== '') && (
                                <button
                                    onClick={() => {
                                        setIsBackButtonPressed(false);
                                        setCurrentStep(currentStep + 1);
                                    }}
                                    className={css.navigationButtons}
                                >
                                    <FormattedMessage id="CalculatorPage.nextButton" />
                                </button>
                            )}
                        </div>
                        <div className={css.disclaimer}>
                            <img src={Fact} className={css.exclaim}></img>
                            <FormattedMessage id={data.disclaimerId}></FormattedMessage>
                        </div>
                    </div>
                    <div className={css.rightContainer}>
                        <img className={css.image} src={data.image} alt="image title" />
                    </div>
                </div>
            </div>
        );
    };
    /** new code end */

    const regionSection = (
        <div className={css.regionWrapper}>
            <div className={css.logoContainer}>
                <TopbarCalculator />
            </div>
            <img className={css.regionMobile} src={RegionMobile}></img>
            <img className={css.regionWeb} src={RegionWeb}></img>
            <div className={css.regionSelection}>
                <h2 className={css.regionHeader}>
                    <FormattedMessage id="CalculatorPage.region.text"></FormattedMessage>
                </h2>
                <select
                    key="CalculatorPage.region"
                    className={css.regionSelect}
                    defaultValue={calculatorState.regionSection}
                    onChange={e => {
                        handleClick(e, 'regionSection');
                    }}
                >
                    <FormattedMessage id="CalculatorPage.region.options.title">
                        {message => (
                            <option disabled={true} default value="">
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.CentralAsia">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="CentralAsia"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.EastAsiaPacific">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="EastAsiaPacific"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.EasternEurope">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="EasternEurope"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.LatinAmericaCaribbean">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="LatinAmericaCaribbean"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.MiddleEastNorthAfrica">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="MiddleEastNorthAfrica"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.NorthAmerica">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="NorthAmerica"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.SouthAsia">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="SouthAsia"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.SubSaharanAfrica">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="SubSaharanAfrica"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="CalculatorPage.region.options.WesternEurope">
                        {message => (
                            <option
                                onClick={e => handleOptionClick(e.target.value, 'regionSection')}
                                value="WesternEurope"
                            >
                                {message}
                            </option>
                        )}
                    </FormattedMessage>
                </select>
            </div>

            {(nextEnabled || calculatorState['regionSection'] !== '') && (
                <div className={css.nextButtonContainer}>
                    <button
                        onClick={() => {
                            setIsBackButtonPressed(false);
                            setCurrentStep(currentStep + 1);
                        }}
                        className={css.navigationButtons}
                    >
                        <FormattedMessage id="CalculatorPage.nextButton" />
                    </button>
                </div>
            )}

            <div className={css.regionFact}>
                <img src={Fact} className={css.exclaim}></img>
                <FormattedMessage id="CalculatorPage.region.fact"></FormattedMessage>
            </div>
        </div>
    );

    const inputRef = useRef(null);
    const result = (
        <div className={css.Result}>
            <TopbarContainer />
            <div className={css.resultWrapper}>
                <div className={css.ecologicalResult}>
                    <div className={css.resultCardHeader}>
                        <FormattedMessage id="CalculatorPage.results.ef.Heading" />
                    </div>

                    <div className={css.resultCardWrapper}>
                        <div className={css.resultCardItems}>
                            <span className={css.highlightNumber}>
                                {ecoResults.total_ef_icl_biodiversity
                                    ? ecoResults.total_ef_icl_biodiversity.toFixed(1)
                                    : ''}
                                &nbsp;global hectares
                            </span>
                            <div className={css.ecologicalChartWrapper}>
                                <div className={css.ecologicalChartDonut}>
                                    <Doughnut
                                        data={{
                                            datasets: [
                                                {
                                                    data: chartData,
                                                    backgroundColor: [
                                                        '#c511bd',
                                                        '#d70505',
                                                        '#e96201',
                                                        '#b7d972',
                                                        '#008ad8',
                                                    ],
                                                    borderWidth: 0,
                                                },
                                            ],
                                            // labels: [
                                            //   intl.formatMessage({ id: 'CalculatorPage.results.ef.category.residence' }),
                                            //   intl.formatMessage({ id: 'CalculatorPage.results.ef.category.energy' }),
                                            //   intl.formatMessage({ id: 'CalculatorPage.results.ef.category.transport' }),
                                            //   intl.formatMessage({ id: 'CalculatorPage.results.ef.category.food' }),
                                            //   intl.formatMessage({ id: 'CalculatorPage.results.ef.category.waste' }),
                                            // ],
                                        }}
                                        height={156}
                                        options={{
                                            maintainAspectRatio: false,
                                            cutoutPercentage: 40,
                                            legend: {
                                                display: false,
                                            },
                                            tooltips: {
                                                enabled: true,
                                            },
                                        }}
                                    />
                                </div>
                                <div className={css.ecologicalChartLabels}>
                                    <div>
                                        <span style={{ color: '#c511bd', fontSize: '20px' }}>
                                            &#9632;
                                        </span>
                                        {intl.formatMessage({
                                            id: 'CalculatorPage.results.ef.category.residence',
                                        })}
                                    </div>
                                    <div>
                                        <span style={{ color: '#d70505', fontSize: '20px' }}>
                                            &#9632;
                                        </span>
                                        {intl.formatMessage({
                                            id: 'CalculatorPage.results.ef.category.energy',
                                        })}
                                    </div>
                                    <div>
                                        <span style={{ color: '#e96201', fontSize: '20px' }}>
                                            &#9632;
                                        </span>
                                        {intl.formatMessage({
                                            id: 'CalculatorPage.results.ef.category.transport',
                                        })}
                                    </div>
                                    <div>
                                        <span style={{ color: '#b7d972', fontSize: '20px' }}>
                                            &#9632;
                                        </span>
                                        {intl.formatMessage({
                                            id: 'CalculatorPage.results.ef.category.food',
                                        })}
                                    </div>
                                    <div>
                                        <span style={{ color: '#008ad8', fontSize: '20px' }}>
                                            &#9632;
                                        </span>
                                        {intl.formatMessage({
                                            id: 'CalculatorPage.results.ef.category.waste',
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={css.ecologicalFootballFields}>
                                <FormattedMessage
                                    className={css.ecologicalFootballFieldsValues}
                                    id="CalculatorPage.results.ef.hectares"
                                    values={{
                                        squareMeters: (
                                            <>
                                                {squareMetersHandler(
                                                    ecoResults.total_ef_icl_biodiversity
                                                )}
                                            </>
                                        ),
                                        footballFields: (
                                            <>
                                                {footballFieldsHandler(
                                                    ecoResults.total_ef_icl_biodiversity
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css.resultCardItemBorder}>
                            <div className={css.earthWrapper}>
                                {earths}
                                {fractionEarth > 0 &&
                                Math.floor(capPlanets(ecoResults.planets)) < 5 ? (
                                    <Earth
                                        width="80px"
                                        viewBox={`${-(132 - fractionEarth)} 0 133 132`}
                                        //style={{ marginLeft: `${60 - (132 - fractionEarth) - 30}` }}
                                        style={{
                                            marginLeft: `${-(80 - (fractionEarth / 132) * 80)}`,
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className={css.resultEarthText}>
                                <FormattedMessage
                                    id="CalculatorPage.results.ef.planet"
                                    values={{
                                        planet: ecoResults.planets ? (
                                            <span style={{ color: '#fd9c29' }}>
                                                {Number(capPlanets(ecoResults.planets)).toFixed(1)}
                                            </span>
                                        ) : (
                                            ''
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css.resultCardActionItem}>
                            <div style={{ width: 270 }}>
                                <h2 style={{ fontSize: 30 }}>
                                    <FormattedMessage id="CalculatorPage.results.ef.action.heading" />
                                </h2>
                            </div>
                            <div className={css.actionText}>
                                <p>
                                    <FormattedMessage id="CalculatorPage.results.ef.action.subheading" />
                                </p>
                            </div>
                            <div>
                                <NamedLink name="friends-profile/home">
                                    <button className={css.resultButton}>
                                        <FormattedMessage id="CalculatorPage.results.ef.action.button" />
                                    </button>
                                </NamedLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.carbonResult}>
                    <div className={css.resultCardHeader}>
                        <FormattedMessage id="CalculatorPage.results.cf.Heading" />
                    </div>
                    <div className={css.resultCardWrapper}>
                        <div className={css.resultCardCFItem}>
                            {LandDeforested && (
                                <div className={css.resultCardGrayText}>
                                    {ecoResults.total_cf
                                        ? parseInt(ecoResults.total_cf.toFixed(1) * 1000)
                                        : ''}{' '}
                                    kg CO<sub>2</sub> emmission equivalent to{' '}
                                    {parseInt(LandDeforested.toFixed(1))}
                                    <FormattedMessage id="CalculatorPage.Amazon.Deforested" />
                                </div>
                            )}
                            <div className={css.resultCo2CloudImageContainer}>
                                <img className={css.resultCo2CloudImage} src={Co2Cloud}></img>
                                <p className={css.resultCo2CloudImageText}>
                                    <FormattedMessage
                                        id="CalculatorPage.results.cf.cloudText"
                                        values={{
                                            CO2: (
                                                <>
                                                    CO<sub>2</sub>
                                                    <br />
                                                </>
                                            ),
                                            warning: (
                                                <>
                                                    <br />
                                                    {ecoResults.increase_in_temp < 2 ? (
                                                        <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.serious" />
                                                    ) : ecoResults.increase_in_temp <= 4 ? (
                                                        <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.critical" />
                                                    ) : (
                                                        <FormattedMessage id="CalculatorPage.results.cf.cloudText.warning.catastrophic" />
                                                    )}
                                                </>
                                            ),
                                        }}
                                    />
                                </p>
                            </div>
                            <div>
                                <span className={css.highlightNumber}>
                                    {ecoResults.total_cf ? ecoResults.total_cf.toFixed(1) : ''}{' '}
                                    tonnes
                                </span>
                                <br></br>
                                <FormattedMessage
                                    id="CalculatorPage.results.cf.co2"
                                    className={css.highlightNumber}
                                    style={{ color: 'black', fontSize: '20px' }}
                                    values={{
                                        CO2: (
                                            <>
                                                CO<sub>2</sub>
                                            </>
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={css.resultCardItemBorderImage}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                alignItems: 'center',
                            }}
                        >
                            <div className={css.resultGlobalWarmingText}>
                                <FormattedMessage id="CalculatorPage.results.cf.planet.context" />
                                <span className={css.orange}>
                                    <FormattedMessage
                                        id="CalculatorPage.results.cf.planet.result"
                                        values={{
                                            warming: ecoResults.increase_in_temp ? (
                                                <span style={{ color: '#fd9c29' }}>
                                                    {ecoResults.increase_in_temp < 6
                                                        ? ecoResults.increase_in_temp.toFixed(1)
                                                        : 6}
                                                    &nbsp;degrees
                                                </span>
                                            ) : (
                                                ''
                                            ),
                                            result: (
                                                <FormattedMessage
                                                    id={
                                                        ecoResults.increase_in_temp <= 2
                                                            ? 'CalculatorPage.results.cf.planet.result.serious'
                                                            : ecoResults.increase_in_temp <= 4
                                                            ? 'CalculatorPage.results.cf.planet.result.deadly'
                                                            : 'CalculatorPage.results.cf.planet.result.catastrophic'
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </span>
                            </div>
                            <img
                                style={{ margin: 'auto', alignSelf: 'flex-end' }}
                                src={
                                    ecoResults.increase_in_temp <= 2
                                        ? serious
                                        : ecoResults.increase_in_temp <= 4
                                        ? deadly
                                        : catastrophic
                                }
                            ></img>
                        </div>

                        <div className={css.resultCardActionItem}>
                            <div style={{ width: 270 }}>
                                <h2 style={{ fontSize: 30 }}>
                                    <FormattedMessage id="CalculatorPage.results.cf.action.heading" />
                                </h2>
                            </div>
                            <div className={css.actionText}>
                                <p>
                                    <FormattedMessage id="CalculatorPage.results.cf.action.subheading" />
                                </p>
                            </div>
                            <div>
                                <NamedLink name="friends-profile/home">
                                    <button className={css.resultButton}>
                                        <FormattedMessage id="CalculatorPage.results.cf.action.button" />
                                    </button>
                                </NamedLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '1.5rem' }} className={css.resultShareCard}>
                    <div style={{ textAlign: 'center' }}>
                        <h2>
                            <FormattedMessage id="CalculatorPage.results.share.heading" />
                        </h2>
                    </div>
                    <div className={css.resultShareCardSubHeading}>
                        <strong>
                            <FormattedMessage id="CalculatorPage.results.share.subheading" />
                        </strong>
                    </div>
                    <button
                        onClick={() =>
                            window.open(
                                `mailto:email@example.com?subject=Calculate your ecological and carbon footprint&body=Hi, Calculate your ecological and carbon footprint`
                            )
                        }
                        className={css.inviteFriendButton}
                    >
                        <FormattedMessage id="CalculatorPage.results.share.button" />
                    </button>
                </div>
                <div style={{ borderTop: '1px solid white' }} className={css.resultShareCardBottom}>
                    <div>
                        <input
                            id="myInput"
                            type="text"
                            ref={inputRef}
                            defaultValue={`https://${process.env.REACT_APP_BASE_URL}/calculator`}
                        />
                        <button
                            onClick={() => {
                                var copyText = document.getElementById('myInput');
                                copyText.select();
                                copyText.setSelectionRange(0, 99999); /* For mobile devices */
                                document.execCommand('copy');
                            }}
                            className={css.copyLinkButton}
                        >
                            Copy Link
                        </button>
                    </div>
                    <div className={css.shareButtons}>
                        <a
                            className="_2vmz"
                            href={`https://www.facebook.com/sharer/sharer.php?u=https://${process.env.REACT_APP_BASE_URL}/calculator`}
                            target="_blank"
                            id="u_0_1_Bl"
                        >
                            <button className={css.fbButton}>
                                <FaFacebook className={css.socialIcons} />
                                <span>Share on Facebook</span>
                            </button>
                        </a>
                        <a
                            className="_2vmz"
                            href={`http://twitter.com/share?text=Regenera Footprint Calculator&url=https://${process.env.REACT_APP_BASE_URL}/calculator`}
                            target="_blank"
                            id="u_0_1_Bl"
                        >
                            <button className={css.twButton}>
                                <FaTwitter className={css.socialIcons} />
                                Share on Twitter
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    const getCurrentItem = currentStep => {
        if (currentStep < 3) {
            return 0;
        } else if (currentStep === 3) {
            return 1;
        } else if (currentStep >= 4 && currentStep < 6) {
            return 2;
        } else if (currentStep >= 6 && currentStep < 8) {
            return 3;
        } else if (currentStep === 8) {
            return 4;
        }
    };

    const sectionItems = [
        {
            id: 0,
            label: <FormattedMessage id="CalculatorPage.section.residence" />,
        },
        {
            id: 1,
            label: <FormattedMessage id="CalculatorPage.section.energy" />,
        },
        {
            id: 2,
            label: <FormattedMessage id="CalculatorPage.section.transport" />,
        },
        {
            id: 3,
            label: <FormattedMessage id="CalculatorPage.section.food" />,
        },
        {
            id: 4,
            label: <FormattedMessage id="CalculatorPage.section.waste" />,
        },
    ];

    const residenceClass = currentStep < 3 ? [css.active] : '';
    const energyClass = currentStep == 3 ? [css.active] : '';
    const transportClass = currentStep >= 4 && currentStep < 6 ? [css.active] : '';
    const foodClass = currentStep >= 6 && currentStep < 8 ? [css.active] : '';
    const wasteClass = currentStep == 8 ? [css.active] : '';
    const progressBar = (
        <ul className={css.ListWrapper}>
            <div className={residenceClass}>
                <FormattedMessage id="CalculatorPage.section.residence"></FormattedMessage>&nbsp;
            </div>
            |&nbsp;
            <div className={energyClass}>
                <FormattedMessage id="CalculatorPage.section.energy"></FormattedMessage>&nbsp;
            </div>
            |&nbsp;
            <div className={transportClass}>
                <FormattedMessage id="CalculatorPage.section.transport"></FormattedMessage>&nbsp;
            </div>
            |&nbsp;
            <div className={foodClass}>
                &nbsp;<FormattedMessage id="CalculatorPage.section.food"></FormattedMessage>&nbsp;
            </div>
            |&nbsp;
            <div className={wasteClass}>
                &nbsp;<FormattedMessage id="CalculatorPage.section.waste"></FormattedMessage>&nbsp;
            </div>
        </ul>
    );

    const timeline = (
        <div className={css.navbar}>
            <TopbarCalculator />
            <Timeline currentItem={getCurrentItem(currentStep)} timelineItems={sectionItems} />
        </div>
    );

    return (
        <div className={css.calculatorContainer}>
            {currentStep > 0 && currentStep < 9 && (
                <img className={css.sectorsImage} src={Sectors} alt="sectors" />
            )}
            {currentStep > 0 && currentStep < 9 ? timeline : null}
            <ComponentTransition
                enterAnimation={
                    isBackButtonPressed
                        ? AnimationTypes.slideLeft.enter
                        : AnimationTypes.slideRight.enter
                }
            >
                {currentStep === 0 ? (
                    regionSection
                ) : currentStep === 9 ? (
                    result
                ) : (
                    <QuestionnaireContainer data={getCurrentData()} />
                )}
            </ComponentTransition>
            <Footer />
        </div>
    );
});

PersonalFootprintCalculatorPage.propTypes = {
    intl: intlShape.isRequired,
};

export default withRouter(injectIntl(PersonalFootprintCalculatorPage));
