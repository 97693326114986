import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';

const ToJoin = () => {
    const JoinSection = styled.section`
        position: relative;
        .team-section {
            min-height: 347px;
            background-image: url(${({ bgGreenTexture }) => bgGreenTexture});
            background-size: cover;
            text-align: center;
            gap: 40px;
            .title-box {
                max-width: 630px;
                padding: 0 30px;
                margin-bottom: 4px;
                h3 {
                    color: white;
                }
            }
            .content {
                gap: 12px;
                max-width: 535px;
                color: white;
                .connect {
                    font-weight: 600;
                }
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            .team-section {
                min-height: 300px;
                gap: 24px;
                .content {
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }
    `;
    return (
        <JoinSection bgGreenTexture={bgGreenTexture}>
            <div className="team-section flex justify-center flex-col">
                <div className="title-box mx-auto">
                    <h3>
                        <FormattedMessage id="About.join.readyTitle" />
                    </h3>
                </div>
                <div className="content flex items-center mx-auto">
                    <OrangeBtn>
                        <Link to="/organizations">
                            <FormattedMessage id="About.join.becomePartner" />
                        </Link>
                    </OrangeBtn>
                    <div className="connect">
                        <FormattedMessage id="About.join.connect" />
                    </div>
                </div>
            </div>
        </JoinSection>
    );
};

export default ToJoin;
