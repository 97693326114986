import tree from '.././src/assets/tree.png';
import pollen from '.././src/assets/icons/pollen.svg';
import seedling from '.././src/assets/icons/leaf.svg';
import trees from '.././src/assets/icons/trees.svg';
import landscape from '.././src/assets/icons/landscape.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';

export const plans = [
    {
        id: 0,
        title: <FormattedMessage id="ProductComparison.people.pollen.planName" />,
        membership: <FormattedMessage id="ProductComparison.people.pollen.subtitle" />,
        description: <FormattedMessage id="ProductComparison.people.pollen.description" />,
        amount: 600,
        image: pollen,
        planType: 'polen',
        list: [
            <FormattedMessage
                values={{
                    data: <strong>{'2,000'}</strong>,
                    superScript: <sup>{'2'}</sup>,
                }}
                id="ProductComparison.people.pollen.planList1"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'4,000'}</strong>,
                }}
                id="ProductComparison.people.pollen.planList2"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'800'}</strong>,
                }}
                id="ProductComparison.people.pollen.planList3"
            />,
            <FormattedMessage
                values={{
                    data: <strong>1</strong>,
                }}
                id="ProductComparison.people.pollen.planList4"
            />,
        ],
        isRecommended: false,
    },
    {
        id: 1,
        title: <FormattedMessage id="ProductComparison.people.seed.planName" />,
        membership: <FormattedMessage id="ProductComparison.people.seed.subtitle" />,
        description: <FormattedMessage id="ProductComparison.people.seed.description" />,
        amount: 1500,
        image: seedling,
        planType: 'semilla',
        list: [
            <FormattedMessage
                values={{
                    data: <strong>{'10,000'}</strong>,
                    superScript: <sup>{'2'}</sup>,
                }}
                id="ProductComparison.people.seed.planList1"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'2,000'}</strong>,
                }}
                id="ProductComparison.people.seed.planList2"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'4,000'}</strong>,
                }}
                id="ProductComparison.people.seed.planList3"
            />,
            <FormattedMessage
                values={{
                    data: <strong>1</strong>,
                }}
                id="ProductComparison.people.seed.planList4"
            />,
        ],
        isRecommended: true,
    },
    {
        id: 2,
        title: <FormattedMessage id="ProductComparison.people.tree.planName" />,
        membership: <FormattedMessage id="ProductComparison.people.tree.subtitle" />,
        description: <FormattedMessage id="ProductComparison.people.tree.description" />,
        amount: 6000,
        image: tree,
        planType: 'arbol',
        list: [
            <FormattedMessage
                values={{
                    data: <strong>{'40,000'}</strong>,
                    superScript: <sup>{'2'}</sup>,
                }}
                id="ProductComparison.people.tree.planList1"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'8,000'}</strong>,
                }}
                id="ProductComparison.people.tree.planList2"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'16,000'}</strong>,
                }}
                id="ProductComparison.people.tree.planList3"
            />,
            <FormattedMessage
                values={{
                    data: <strong>2</strong>,
                }}
                id="ProductComparison.people.tree.planList4"
            />,
        ],
        isRecommended: false,
    },
    {
        id: 3,
        title: <FormattedMessage id="ProductComparison.people.forest.planName" />,
        membership: <FormattedMessage id="ProductComparison.people.forest.subtitle" />,
        description: <FormattedMessage id="ProductComparison.people.forest.description" />,
        amount: 15000,
        image: trees,
        planType: 'bosque',
        list: [
            <FormattedMessage
                values={{
                    data: <strong>{'100,000'}</strong>,
                    superScript: <sup>{'2'}</sup>,
                }}
                id="ProductComparison.people.forest.planList1"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'20,000'}</strong>,
                }}
                id="ProductComparison.people.forest.planList2"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'40,000'}</strong>,
                }}
                id="ProductComparison.people.forest.planList3"
            />,
            <FormattedMessage
                values={{
                    data: <strong>4</strong>,
                }}
                id="ProductComparison.people.forest.planList4"
            />,
        ],
        isRecommended: false,
    },
    {
        id: 4,
        title: <FormattedMessage id="ProductComparison.people.landscape.planName" />,
        membership: <FormattedMessage id="ProductComparison.people.landscape.subtitle" />,
        description: <FormattedMessage id="ProductComparison.people.landscape.description" />,
        amount: 60000,
        image: landscape,
        planType: 'paisaje',
        list: [
            <FormattedMessage
                values={{
                    data: <strong>{'200,000'}</strong>,
                    superScript: <sup>{'2'}</sup>,
                }}
                id="ProductComparison.people.landscape.planList1"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'40,000'}</strong>,
                }}
                id="ProductComparison.people.landscape.planList2"
            />,
            <FormattedMessage
                values={{
                    data: <strong>{'80,000'}</strong>,
                }}
                id="ProductComparison.people.landscape.planList3"
            />,
            <FormattedMessage
                values={{
                    data: <strong>1</strong>,
                }}
                id="ProductComparison.people.landscape.planList4"
            />,
        ],
        isRecommended: false,
    },
    {
        id: 5,
        title: <FormattedMessage id="ProductComparison.business.seed.planName" />,
        membership: <FormattedMessage id="ProductComparison.business.seed.subtitle" />,
        description: <FormattedMessage id="ProductComparison.business.seed.description" />,
        amount: 9000,
        image: seedling,
        planType: 'semillaEmpresas',
        list: [
            <FormattedMessage id="ProductComparison.business.seed.planList1" />,
            <FormattedMessage id="ProductComparison.business.seed.planList2" />,
            <FormattedMessage id="ProductComparison.business.seed.planList3" />,
            <FormattedMessage id="ProductComparison.business.seed.planList4" />,
        ],
        isRecommended: false,
    },
    {
        id: 6,
        title: <FormattedMessage id="ProductComparison.business.tree.planName" />,
        membership: <FormattedMessage id="ProductComparison.business.tree.subtitle" />,
        description: <FormattedMessage id="ProductComparison.business.tree.description" />,
        amount: 36000,
        image: tree,
        planType: 'arbolEmpresas',
        list: [
            <FormattedMessage id="ProductComparison.business.tree.planList1" />,
            <FormattedMessage id="ProductComparison.business.tree.planList2" />,
            <FormattedMessage id="ProductComparison.business.tree.planList3" />,
            <FormattedMessage id="ProductComparison.business.tree.planList4" />,
        ],
        isRecommended: false,
    },
    {
        id: 7,
        title: <FormattedMessage id="ProductComparison.business.forest.planName" />,
        membership: <FormattedMessage id="ProductComparison.business.forest.subtitle" />,
        description: <FormattedMessage id="ProductComparison.business.forest.description" />,
        amount: 90000,
        image: trees,
        planType: 'bosqueEmpresas',
        list: [
            <FormattedMessage id="ProductComparison.business.forest.planList1" />,
            <FormattedMessage id="ProductComparison.business.forest.planList2" />,
            <FormattedMessage id="ProductComparison.business.forest.planList3" />,
            <FormattedMessage id="ProductComparison.business.forest.planList4" />,
        ],
        isRecommended: false,
    },
];
