import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    justify-content: center;
    align-items: center;
    & > div > iframe {
        width: 800px;
        height: 600px;
        max-width: 1100px;
    }
    & > iframe {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1100px;
        outline: none;
        cursor: pointer;
        padding: 60px;
    }
    & > img {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        max-width: 32px;
    }
    @media screen and (max-width: 900px) {
        & > iframe {
            position: relative;
            max-width: 90%;
            padding: 0px 40px 0px 0px;
        }
        & > img {
            top: 100px;
            right: 60px;
        }

        & > div > iframe {
            width: 600px;
            height: 400px;
        }
    }
    @media screen and (max-width: 625px) {
        & > div > iframe {
            width: 400px;
            height: 300px;
        }
    }
    @media screen and (max-width: 430px) {
        & > div > iframe {
            width: 340px;
            height: 250px;
        }
    }
`;
export const Container2 = styled.div`
    position: relative;
    margin: auto;
    width: 100%;
    height: 340px;

    & > img:nth-child(1) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
    }
    & > img:nth-child(2) {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 48%;
        top: 50%;
        color: white;
        z-index: 3;
        cursor: pointer;
        margin-left: -48px;
        margin-top: -48px;
    }
    @media screen and (max-width: 425px) {
        height: 200px;
        & > img:nth-child(2) {
            position: absolute;
            width: 80px;
            height: 80px;
            top: 55%;
            left: 50%;
        }
        & > img:nth-child(1) {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.9;
        }
    }
`;
