import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 20px;
    height: 40px;
    cursor: pointer;

    @media (max-width: 1100px) {
        width: 20px;
        height: 40px;
    }

    & > svg {
        fill: black;
    }
    & > svg:hover {
        fill: black;
        opacity: 0.8;
    }
`;
