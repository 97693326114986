import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './Styles';

const Icon = ({ icon: SourceIcon }) => {
  return (
    <StyledContainer>
      <SourceIcon />
    </StyledContainer>
  );
};

Icon.propTypes = {
  icon: PropTypes.node.isRequired,
};

Icon.defaultProps = {
  icon: '',
};

export default Icon;
