import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPlan } from '../../ducks/subscriptionReducer.duck';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import { plans } from '../../util/paymentData';

import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SelectPlanBox from '../../newComponents/SelectPlanBox';
import { IoIosInformationCircle } from 'react-icons/io';

import StepForm from './StepForm';

const StepTwoSection = styled.section`
    margin-top: 60px;
    max-width: 630px;
    .fee-n-box {
        .fee {
            line-height: 180%;
        }
        .tooltip-box {
            top: 35px;
            right: 0;
            background-color: white;
            z-index: 9;
        }
    }
    .plan-group {
        gap: 20px;
        .planradio {
            position: relative;
            .radio-box {
                .checkmark {
                    right: 8px;
                    left: auto;
                    top: 8px;
                }
                .plan-box {
                    max-width: 196px;
                    position: relative;
                    top: -18px;
                    &:hover {
                        border: 1px solid ${({ theme }) => theme.colors.light_green};
                    }
                }
                input:checked + .plan-box {
                    border: 1px solid #6ea44c;
                }
            }
        }
        /* Add hover effect for the checkmark */
        .planradio:hover .checkmark {
            border: 1px solid ${({ theme }) => theme.colors.light_green};
        }
    }
    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 20px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .fee-n-box {
            margin-left: auto;
            margin-right: auto;
            .tooltip-box {
                bottom: 35px;
                top: auto;
            }
        }
        .plan-group {
            flex-wrap: wrap;
            gap: 20px;
            .planradio {
                position: relative;
                .radio-box {
                    .checkmark {
                        right: 8px;
                        left: auto;
                        top: 8px;
                    }
                    .plan-box {
                        position: relative;
                        top: -18px;
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        .plan-group {
            .planradio {
                .radio-box {
                    .plan-box {
                        max-width: none;
                    }
                }
            }
        }
    }
`;

const ConfirmPlan = ({ classname }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPlan = useSelector(state => state.subscription.selectedPlan);
    const planTarget = useSelector(state => state.subscription.planTarget);

    const [selectedPlanType, setSelectedPlanType] = useState(selectedPlan.planType);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (selectedPlan.planTarget !== planTarget) {
            // Reset the selected plan if it doesn't match the current planTarget
            const defaultPlan = {}; // Choose a default plan or an empty object
            dispatch(setSelectedPlan(defaultPlan));
        }
    }, []);

    const handlePlanChange = e => {
        setSelectedPlanType(e.target.value);
        dispatch(
            setSelectedPlan(
                filteredPlans.find(planDetails => planDetails.planType === e.target.value)
            )
        );
        setShowError(false);
    };

    const handlePlanSubmit = event => {
        event.preventDefault();
        setShowError(false);
        if (selectedPlan && selectedPlanType) {
            history.push('/subscription/select-landscape');
        } else {
            setShowError(true);
        }
    };

    const isPlanSelected = () => {
        return selectedPlan && Object.keys(selectedPlan).length > 0;
    };

    const filteredPlans = plans
        .filter(planDetails => planDetails.planTarget === planTarget)
        .sort((firstItem, secondItem) => firstItem.amount - secondItem.amount);

    console.log('plantargee', planTarget);
    console.log('selectedPlan', selectedPlan);
    console.log('selectedPlanType', selectedPlanType);
    console.log('selectedPlanType', selectedPlan.planType);

    return (
        <StepForm step={1} isNextDisabled={!isPlanSelected()}>
            <StepTwoSection className={`mx-auto flex flex-col ${classname}`}>
                {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                    <div className="fee-n-box w-full box-shadow absolute hide-tab">
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.MonthlyFee" />
                            </div>
                            <div className="big-txt flex items-center small">
                                ${selectedPlan.planCost / 300}{' '}
                                <Link className="tooltip-ic">
                                    <IoIosInformationCircle />
                                </Link>
                                <div className="tooltip-box">
                                    <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                </div>
                            </div>
                        </div>
                        <div className="fee flex items-center justify-space-between">
                            <div className="small-txt small">
                                <FormattedMessage id="Subscription.StepForm.Impact" />
                            </div>
                            <div className="big-txt flex items-center small">
                                {selectedPlan.impactList[0].listTxt} /{' '}
                                <FormattedMessage id="Subscription.StepForm.Year" />
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-title">
                    <FormattedMessage id="Subscription.StepForm.Step2.title" />

                    <div className="title-info text-center show-tab">
                        <FormattedMessage id="Subscription.StepForm.Step2.desc" />
                    </div>
                </div>
                <form>
                    <div className="plan-group flex justify-center">
                        {filteredPlans.map((plan, index) => (
                            <div key={index} className="planradio">
                                <label for={`planOne_${plan.planType}`} className="radio-box">
                                    <input
                                        id={`planOne_${plan.planType}`}
                                        name="selectplan"
                                        type="radio"
                                        value={plan.planType}
                                        checked={selectedPlanType === plan.planType}
                                        onChange={handlePlanChange}
                                    />
                                    <SelectPlanBox
                                        planBoxClass="plan-box w-full"
                                        planImg={plan.planImage}
                                        planName={plan.planTitle}
                                        planInfo={plan.planDescription}
                                        planCost={plan.planCost / 300}
                                        listTitle="Impact:"
                                        planduration={
                                            <FormattedMessage id="ProductComparison.perMonth" />
                                        }
                                        actionLinkShow={
                                            <FormattedMessage id="ProductComparison.ShowFeatures" />
                                        }
                                        actionLinkHide={
                                            <FormattedMessage id="ProductComparison.HideFeatures" />
                                        }
                                        impactList={plan.impactList}
                                        featureList={plan.featureList}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        ))}
                    </div>
                    {selectedPlan && Object.keys(selectedPlan).length > 0 && (
                        <div className="fee-n-box w-full box-shadow relative show-tab">
                            <div className="fee flex items-center justify-space-between">
                                <div className="small-txt small">Monthly fee:</div>
                                <div className="big-txt flex items-center small">
                                    ${selectedPlan.planCost / 300}{' '}
                                    <Link className="tooltip-ic">
                                        <IoIosInformationCircle />
                                    </Link>
                                    <div className="tooltip-box">
                                        <FormattedMessage id="Subscription.StepForm.StripeToolTip" />
                                    </div>
                                </div>
                            </div>
                            <div className="fee flex items-center justify-space-between">
                                <div className="small-txt small">
                                    <FormattedMessage id="Subscription.StepForm.Impact" />
                                </div>
                                <div className="big-txt flex items-center small">
                                    {selectedPlan.impactList[0].listTxt} /{' '}
                                    <FormattedMessage id="Subscription.StepForm.Year" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="cta mx-auto">
                        <OrangeBtn
                            type="button"
                            onClick={handlePlanSubmit}
                            disabled={!isPlanSelected()}
                            className={!isPlanSelected() ? 'disabled' : null}
                        >
                            <FormattedMessage id="Subscription.StepForm.SelectContinue" />
                        </OrangeBtn>
                    </div>
                    {showError && (
                        <div className="error-text small">
                            <FormattedMessage id="MultiStep.ConfirmPlan.planSelectionFailed" />
                        </div>
                    )}
                </form>
                <div className="step-nav flex mx-auto">
                    <div className="bullet"></div>
                    <div className="bullet current"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                </div>
            </StepTwoSection>
        </StepForm>
    );
};

export default ConfirmPlan;
