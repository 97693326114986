import React from 'react';
import { FormattedMessage } from 'react-intl';
import {ToggleWrapper, Title, PlusLogo} from './Styles';
import Plus from './../../assets/newAssets/orangePlus.svg';

const ToggleComponent = (props) =>{

    const {onClick, isVisible, titleId} = props;

    return (

        <ToggleWrapper>
            <Title>
                <FormattedMessage id={titleId}/>
            </Title>
            <PlusLogo>
                <img src={Plus} width="100%" height="100%" onClick={onClick}/>
            </PlusLogo>
        </ToggleWrapper>
    );
}

export default ToggleComponent;