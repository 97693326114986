import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);

    width: 32%;
    figure {
        width: 100%;
        height: 275px;
    }
    img {
        border-radius: 8px;
    }
    .card-box {
        background-color: white;
        gap: 20px;
        border-radius: 8px;
        margin-top: -20px;
        padding: 24px 32px;
        position: relative;
        z-index: 99;

        .title-box {
            gap: 8px;
            h4 {
                font-size: 19px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.title_green};
                line-height: 27px;
                @media (max-width: ${({ theme }) => theme.media.tab}) {
                    font-size: 17px;
                }
            }
            .author {
                font-weight: 400;
                text-transform: capitalize;
                a {
                    color: ${({ theme }) => theme.colors.title_green};
                }
            }
        }
    }
    @media (max-width: 700px) {
        width: 50%;
    }
    @media (max-width: 699) {
        width: 100%;
    }
`;

const BlogPostCard = ({
    classname,
    image,
    category,
    landscapes,
    title,
    authorName,
    postDate,
    slug,
}) => {
    return (
        <CardContainer className={classname}>
            <Link to={`/blog/${slug}`}>
                <figure>
                    <img src={image} className="w-full h-full img-cover" alt={title} />
                </figure>
            </Link>
            <div className="card-box flex flex-col">
                <ul className="taxonomy-list flex flex-wrap">
                    <li className="category-list">
                        <Link to={`/blog/category/${category.slug}`}>
                            <small>{category.name}</small>
                        </Link>
                    </li>
                    {landscapes
                        ? landscapes.map(element => (
                              <li className="landscape-list" key={element.id}>
                                  <Link to={`/blog/landscape/${element.slug}`}>
                                      <small>{element.name}</small>
                                  </Link>
                              </li>
                          ))
                        : null}
                </ul>

                <div className="title-box flex flex-col">
                    <Link to={`/blog/${slug}`}>
                        <h4>{title}</h4>
                    </Link>
                    <div className="author">
                        <small>
                            <Link to={`/blog/author/${authorName}`}>{authorName}</Link> | {postDate}
                        </small>
                    </div>
                </div>
            </div>
        </CardContainer>
    );
};

export default BlogPostCard;
