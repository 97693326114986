import styled from 'styled-components';
import { typography, space } from 'styled-system';

export const StyledContainer = styled.div`
text-align: center;
${({ color }) => color === 'gray' && `color: #5B5B5B;`}
${({ color }) => color === 'white' && `color: #ffffff;`}
${({ align }) => align === 'left' && `text-align: left;`}
`;

export const StyledHeading = styled.h3`
  font-family: 'Galano Grotesque';
  font-style: normal;
  ${typography}
  ${space}
font-weight: 700;
  ${({ textSize }) => (textSize === 'md' ? `line-height: 36px;` : `line-height: 28px;`)}
`;

export const StyledParagraph = styled.p`
  font-family: 'Galano Grotesque';
  font-style: normal;
  ${typography}
  ${space}
line-height: 19px;
  font-weight: 400;
  ${({ bold }) => bold && `font-weight: 500;`}
  ${({ data }) => data && `max-width: 190px;`}
`;

export const StyledDataContainer = styled.div`
  display: grid;
  ${({ data }) =>
    data.length &&
    `
  grid-template-columns: repeat(auto-fit, minmax(190px, auto));
  grid-column-gap: 1rem;
  
`}
`;

export const StyledText = styled.span`
  font-weight: 700;
`;
