import { map, set } from 'lodash';
import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import arrow from './../../assets/newAssets/arrow-down-dropdown.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage} from 'react-intl';
import styles from './styles.module.css';
import {
    InputWraper,
    InpuField,
    LogoWrapper,
    Dropdown,
    DropdownField,
    DropdownWrapper
} from './Styles';

const DropdownComponent = (props) => {


    const {
        fields,
        inputStyle,
        inputFieldStyle,
        onClick,
        dropdownFieldStyle,
        backgroundStyle,
        defaultInput,
        currentRole,
    }= props;

    const [view, setView] = useState(false);

    const [selected,setSelected] = useState(defaultInput);

    useEffect(()=>{
        currentRole && setSelected(currentRole);
    },[currentRole])

    const viewDropdown = () => {
        setView(!view);
    }
    return(
        <DropdownWrapper>
            <InputWraper style={inputStyle}>
                <InpuField style={inputFieldStyle}>
                    <span>{
                    selected=="friend"?
                    <FormattedMessage id="NewFriendsPage.Profile.Dropdown.Title"/>
                    : ( selected=="partner" ? <FormattedMessage id="NewPartnersPage.Profile.Dropdown.Title"/> : <FormattedMessage id="NewGuardiansPage.Profile.Dropdown.Title"/>)
                    }</span>
                </InpuField>
                <LogoWrapper onClick={viewDropdown}>
                    <KeyboardArrowDownIcon className={styles.dropdown} style={{"height" : "50%", "width" : "100%"}}/>
                </LogoWrapper>
            </InputWraper>
            {view && <Dropdown style={backgroundStyle}>
                {fields && fields.map((field , id) => {
                    return (
                        <DropdownField key={id} onClick={() => {
                            onClick(field);
                            setSelected(field);
                        }} style={dropdownFieldStyle}>
                            <span>{
                            field=="friend"?
                             <FormattedMessage id="NewFriendsPage.Profile.Dropdown.Title"/>
                             :( field=="partner" ? <FormattedMessage id="NewPartnersPage.Profile.Dropdown.Title"/> : <FormattedMessage id="NewGuardiansPage.Profile.Dropdown.Title"/>)
                            }</span>
                        </DropdownField>
                    )
                })}
            </Dropdown>} 
        </DropdownWrapper>
    )
}

const { string, func, object, arrayOf} = PropTypes;

DropdownComponent.defaultProps = {
    defaultInput: "activate account",
    fields: [],
    onClick: null,
    backgroundStyle: {},
    inputStyle: {},
    dropdownFieldStyle: {},
    inputFieldStyle: {},
};

DropdownComponent.propTypes = {
    defaultInput: string.isRequired,
    fields: arrayOf(string).isRequired,
    onClick: func.isRequired,
    backGroundStyle: object,
    inputStyle: object,
    dropdownFieldStyle: object,
    inputFieldStyle: object,
};

export default DropdownComponent;