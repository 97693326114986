import styled from "styled-components";
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';
export const FootprintMain = styled.div`
    height:auto;
    width: 67%;
    background-color: #FFF3EC;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family:'Galano Grotesque';
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height:auto;
    }
`;
export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 30px;
    font-weight:700;
    width:fit-content;
    margin: ${props=>props.box?'40px auto':'45px'};
    @media screen and (max-width: 768px) {
      display:${props=>props.box?'none':''};
      margin: ${props=>props.box?'40px auto':'35px 0px 20px 20px'};
  }
`;
export const Navigation = styled.div`
    margin:0px auto;
    width: 92%;
    padding:20px;
    height: 72px;
    background-color: #ffffff;
    border-radius: 10px;
    display:flex;
    .visible{
        cursor: pointer;
        visibility: visible;
    }
    .invisible{
        cursor: pointer;
        visibility: hidden;
    }
    @media screen and (max-width: 768px) {
      display:none;
  }
`;
export const Date = styled.div`
    font-family: 'Galano Grotesque';
    font-size: 26px;
    font-weight:700;
    width:100%;
    text-align:center;
`;
export const Footprint = styled.div`
    margin:30px auto;
    width: 92%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
`;
export const Chart = styled.div`
  margin:30px auto;
    width: 92%;
    height: auto;
    padding:15px;
    background-color: #ffffff;
    border-radius: 10px;
  & > p{
    float: right;
  }
`;

export const Disclaimer = styled.p`
  font-size: 12px;
  font-family: Galano Grotesque;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  line-height: normal;
  color: #666666;
  width: fit-content;
`;

export const ResultCardWrapper = styled.div`
    width: 90%;
    display:flex;
    align-items: baseline;
    @media screen and (max-width: 768px) {
        width: 90%;
        display: flex;
        background: #ffffff;
        min-height: 300px;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 10px;
        flex-wrap: wrap;
        border-radius: 10px;
    }
`;
export const ResultCardItems = styled.div`
    flex: 1;
    text-align: center;
    min-width: 200px;
    border: 1px solid transparent;
    background-color: #ffffff;
    margin: auto;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    @media screen and (max-width: 500px) {
        margin-bottom:0px;
    }
`;
export const HighlightNumber = styled.div`
    font-size: 30px;
    font-weight: 700;
    margin: 0px auto;
    color: ${props=>props.black?'#000000':'#fd9c28'};
    font-family: 'Galano Grotesque';
`;


export const EcologicalChartWrapper = styled.div`
    display: flex;
    font-size: 12px;
    margin-top: 22px;
    justify-content: center;
`;
export const EcologicalChartDonut = styled.div`
    width: 180px;
`;
export const EcologicalChartLabels = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-weight: bold;
    min-width: max-content;

    @media screen and (min-width: 800px){
    margin-left: inherit;
    }
`;
export const GrayText = styled.div`
    padding-top: 20px;
    color: #5a5a5a;
    margin: auto;
    width:70%;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: ${props=>props.empty?'24px':'19px'};
    line-height: 26px;
    text-align: center;
`;

export const ResultCardItemBorder = styled.div`
    text-align: center;
    flex: 1;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 1rem;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 700px) {
      margin: auto;
    }
`;

export const EarthWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 700px) {
      flex-flow: row;
      width: 70%;
    }

`;
export const ResultEarthText = styled.div`
    flex: 1;
    font-family: Galano Grotesque;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    @media screen and (max-width: 700px) {
      font-size: 21px;
      width:260px;
      line-height:38px;
    }
`;

export const CarbonResult = styled.div`
margin-top: 20px;
`;
export const ResultCardCFItem = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  border: 1px solid transparent;
  background-color: #ffffff;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  padding: 10px;

  @media screen and (max-width: 500px) {
    border: none;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 700px) {
    margin: auto;
    margin-bottom: 0px;
  }
`;

export const ResultCo2CloudImageContainer = styled.div`
    position: relative;
    margin-bottom: 10px;

`;
export const ResultCo2CloudImage = styled.img`
  width: 180px;
  padding-left: 10px;
  margin-top: 10px;

  @media screen and (max-width: 700px) {
    width: 195px;
  }

  @media screen and (min-width: 700px) {
    width: 212px;
  }
`;
export const ResultCo2CloudImageText = styled.p`
position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0px auto;
  font-size: 11px;
  font-weight: bold;

  @media screen and (max-width: 700px)  {
    font-size: 13px;
    bottom: 16px;
  }

  @media screen and (min-width: 700px)  {
    font-size: 16px;
    left: 6px;
  }
`;

export const ResultCardItemBorderImage = styled.div`
  text-align: center;
  flex: 1;
  background-color: #ffffff;
  margin: auto;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  padding: 10px;
  display: flex;

  @media screen and (max-width: 500px)  {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
    padding-bottom: 0px;
    width:auto;
  }

  @media screen and (max-width: 700px)  {
    margin-bottom: 0px;
  }

@media screen and (min-width: 700px)  {
  margin-bottom: 0px;
  justify-content: center;
}

`;

export const ResultGlobalWarmingText = styled.div`

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  flex: 1;

  @media screen and (max-width: 500px) {
    grid-column: 1 / span 2;
  }

  @media screen and (max-width: 700px) {
    font-size: 21px;
    grid-row: 2;
  }
  @media screen and (min-width: 700px) {
    font-size: 25px;
    line-height: normal;
  }
  .orange{
    color: #fd9c29;
  }
`;
export const WarnImage = styled.img`
    margin: auto;
    align-self: flex-start;
    height: 224px;
    width: 224px;
    @media screen and (max-width: 500px) {
      width:100%;
    }
`;

export const Footer = styled.div`
    margin:35px 20px 35px 20px;
    height:auto;
    @media screen and (max-width: 500px) {
        margin: 0 auto;
        width:100%;
        height:380px;
        padding:20px;
    }
    
`;
export const ImgContainer = styled.div`
    display:flex;
    justify-content: space-between;
    position:relative;
    top:-130px;
    align-items:center;
    @media screen and (max-width: 500px) {
        top:-310px;
        flex-direction:column;
        align-items:flex-start;
    }
`;
export const ImgText = styled.div`
    font-family: Galano Grotesque;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color:#ffffff;
    z-index:4;
    width:40%;
    margin-left:20px;
    @media screen and (max-width: 500px) {
        font-size: 34px;
    }
`;
export const ImgBtn = styled.div`
    margin-right:20px;
    @media screen and (max-width: 500px) {
        margin-left:20px;
        margin-top:10px;
    }
`;
export const ButtonContainer = styled.div`
    margin: 40px auto;
    width:fit-content;
`;