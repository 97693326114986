import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import heroimage from '../../assets/detalleselva.jpg';
import {
  Hero,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Modal,
  NamedLink,
  Page,
} from '../../components';
import * as coreAPI from '../../coreApi';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './YourListingsPage.css';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

const ListingCard = ({ subscription, handleUnsubscription }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { subscriptionId, landscape } = subscription;

  console.log('subscription', subscription);

  const slug = createSlug(landscape.name);
  return (
    <div className={css.infoListingCard}>
      <img className={css.imageSubscription} src={landscape.image} />
      <div className={css.detailsInfo}>
        <div className={css.infoListing}>
          <h3 className={css.titleCard}>{landscape.name}</h3>
          <NamedLink name="ListingPage" params={{ id: landscape.id, slug }}>
            <FormattedMessage id="YourListingsPage.LandscapeLinkText" />
          </NamedLink>
        </div>
        <button onClick={() => setModalOpen(true)} type="button" className={css.unsubscribeButton}>
          <FormattedMessage id="YourListingsPage.LandscapeUnsubscribeButton" />
        </button>
      </div>

      <Modal
        id={landscape.name}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onManageDisableScrolling={() => {}}
      >
        <p className={css.modalUnsuscribeText}>
          <FormattedMessage id="YourListingsPage.UnSubscribeDialogTitle" />
        </p>
        <p className={css.modalUnsuscribeText}>
          <FormattedMessage
            id="YourListingsPage.UnSubscribeDialogText"
            values={{ landscapeName: landscape.name }}
          />
        </p>
        <div className={css.modalButtons}>
          {/* <button onClick={() => setModalOpen(false)} type='button' className={css.unsubscribeButtonCancel}><FormattedMessage id="YourListingsPage.UnSubscribeDialogCancelButton"/></button> */}
          <button
            onClick={() => handleUnsubscription(subscriptionId)}
            type="button"
            className={css.unsubscribeButtonModal}
          >
            <FormattedMessage id="YourListingsPage.UnSubscribeDialogUnsubscribeButton" />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export const YourListingsPageComponent = props => {
  const [selectedListing, setSelectedListing] = useState('loading');
  const [listingData, setListingData] = useState();
  const [userSubscription, setUserSubscription] = useState([]);
  const [reloadSubscription, setReloadSubscriptions] = useState(false);

  const { currentUser, scrollingDisabled, intl } = props;

  const tabs = [
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.paisaje" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'YourListingsPage',
    //   },
    // },
    // // {
    // //   text: <FormattedMessage id="ContactDetailsPage.messages" />,
    // //   selected: false,
    // //   linkProps: {
    // //     name: 'InboxPageOrders',
    // //   },
    // // },
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.profileSettings" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'ProfileSettingsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.contactDetailsTabTitle" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'ContactDetailsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.passwordTabTitle" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'PasswordChangePage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="ContactDetailsPage.updateCard" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'UpdateCardPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="PaymentHistoryPage.title" />,
    //   selected: false,
    //   linkProps: {
    //     name: 'PaymentHistoryPage',
    //   },
    // },
  ];

  const handleUnsubscription = async subscriptionId => {
    try {
      console.log(subscriptionId);
      const unSubscriptionReponse = await coreAPI.cancelSubscription(subscriptionId);
      setSelectedListing('unsuscribed');
      setReloadSubscriptions(!reloadSubscription);
    } catch (e) {
      console.error(e);
    }
  };

  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';
  const currentFirstName = user.attributes.profile.firstName || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const privateData = user.attributes.profile.privateData || {};

  useEffect(() => {
    coreAPI
      .getMySubscription()
      .then(userSubscriptionResponse => {
        const fetchedUserSubscription = userSubscriptionResponse.data;
        console.log(fetchedUserSubscription);
        if (fetchedUserSubscription.length === 0) {
          setSelectedListing('nolisting');
        } else {
          setUserSubscription(fetchedUserSubscription);
          setSelectedListing('fetched');
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [reloadSubscription]);

  const title = intl.formatMessage({ id: 'ContactDetailsPage.paisaje' });
  const linkText = (
    <NamedLink name="Paisajes">
      {' '}
      <FormattedMessage id="YourListingsPage.LinkText" />{' '}
    </NamedLink>
  );
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <Header
            transparent
            currentPath={props.location.pathname}
            user={props.user}
            history={props.history}
          />
          <Hero
            size="medium"
            title={intl.formatMessage(
              { id: 'TopbarMobileMenu.greeting' },
              { displayName: currentFirstName }
            )}
            image={heroimage}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ContactDetailsPage.paisaje" />
            </h1>
            {selectedListing === 'loading' ? (
              <p>
                <FormattedMessage id="YourListingsPage.Loading" />
              </p>
            ) : selectedListing === 'nolisting' ? (
              <p>
                {' '}
                <FormattedMessage id="YourListingsPage.NoListing" values={{ linkText }} />{' '}
              </p>
            ) : selectedListing === 'unsuscribed' ? (
              <p>
                <FormattedMessage id="YourListingsPage.Unsubscribed" values={{ linkText }} />{' '}
              </p>
            ) : (
              <div className={css.infoListingContainer}>
                {userSubscription.map((item, index) => {
                  return (
                    <ListingCard
                      key={index}
                      subscription={item}
                      handleUnsubscription={handleUnsubscription}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer history={props.history} />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

YourListingsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool, func } = PropTypes;

YourListingsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;
  const user = state.user.currentUser
    ? {
        initials: state.user.currentUser.attributes.profile.abbreviatedName,
        name:
          state.user.currentUser.attributes.profile.firstName +
          ' ' +
          state.user.currentUser.attributes.profile.lastName,
      }
    : null;
  return {
    user,
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const YourListingsPage = compose(connect(mapStateToProps), injectIntl)(YourListingsPageComponent);

YourListingsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default YourListingsPage;
