import styled from 'styled-components';
// styled

export const TeamUpSection = styled.section`
    position: relative;
    .green-bg {
        align-items: flex-start;
        padding-top: 85px;
        background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
        background-size: cover;
        margin-bottom: -4px;
        .title-box {
            margin-top: 35px;
        }
        .text-white {
            color: white;
            br {
                display: none;
            }
        }
        .small-title {
            left: 0;
            top: 0;
        }
        .right-section {
            margin-bottom: -4px;
            margin-top: -85px;
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .green-bg {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 45px;
            gap: 0;
            width: 100%;
            .title-box {
                text-align: center;
                margin-bottom: 22px;
            }
            .small-title {
                left: 20px;
                width: auto;
                top: 0;
                right: 20px;
                text-align: center;
            }
            .right-section {
                display: none;
            }
        }
    }
`;
export const MultiColAction = styled.section`
    position: relative;
    .green-bg {
        background: url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
        background-size: cover;
        min-height: 225px;
        .wrapper {
            max-width: 1275px;
            gap: 25px;
            position: absolute;
            left: 50%;
            top: -230px;
            transform: translateX(-50%);
            z-index: 9;
            .col-box {
                background-color: white;
                padding: 40px;
                border-radius: 20px;
                min-height: 360px;
                min-width: 406px;
                p {
                    margin-top: 8px;
                }
                button {
                    margin-top: 16px;
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .green-bg {
            padding: 0 22px 48px;
            .wrapper {
                position: relative;
                flex-wrap: wrap;
                left: 0;
                top: 0;
                transform: none;
                gap: 25px;
                .col-box {
                    min-height: auto;
                    min-width: auto;
                    width: 100%;
                    padding: 20px;
                }
            }
        }
    }
`;
