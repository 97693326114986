import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Right = styled.div`
    height: 100vh;
    width: 15%;
    background: rgba(255, 255, 255);
    @media screen and (max-width: 1024px) {
        display: none;
        width: 0;
    }
`;
export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
    font-family: 'Galano Grotesque';
    margin: 30px 10px 30px 15px;
    width: fit-content;
`;

export const Card = styled.div`
    width: 90%;
    height: 200px;
    background: #ffffff;
    margin: 10px auto;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.11);
`;
export const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: #f29509;
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    .title {
        width: 100%;
        text-align: center;
    }
`;

