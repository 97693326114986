import React from 'react';
import { FormattedMessage } from 'react-intl';

import StepSlider from 'react-slick';
import ImageWithText from '../../../../newComponents/ImageWithText';
import downBendedArrow from '../../../../assets/newAssets/down-bended-arrow.svg';
import gdnWorkStep1 from '../../../../assets/newAssets/guardians/gdn_workstep1.webp';
import gdnWorkStep2 from '../../../../assets/newAssets/guardians/gdn_workstep2.webp';
import gdnWorkStep3 from '../../../../assets/newAssets/guardians/gdn_workstep3.webp';

import { HowitWorksSection } from './Styles';

// step slider setting

const stepSettings = {
    responsive: [
        {
            breakpoint: 9999,
            settings: 'unslick',
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                speed: 300,
                infinite: false,
                adaptiveHeight: true,
            },
        },
    ],
};

const listingdata = [
    {
        textintro: <FormattedMessage id="GuardiansPage.hwit.listingIntro" />,
    },
    { textinfo: <FormattedMessage id="GuardiansPage.hwit.textInfo1" /> },
    { textinfo: <FormattedMessage id="GuardiansPage.hwit.textInfo2" /> },
    { textinfo: <FormattedMessage id="GuardiansPage.hwit.textInfo3" /> },
];

const lastStepData = listingdata.map(list => {
    return (
        <>
            {list.textintro && <p>{list.textintro}</p>}
            <div className="li-list">
                <strong>{list.strongText}</strong> {list.textinfo}
            </div>
        </>
    );
});

const HowitWorks = () => {
    return (
        <HowitWorksSection>
            <div className="title-box text-center">
                <h2>
                    <FormattedMessage id="GuardiansPage.hwit.title" />
                </h2>
                <img src={downBendedArrow} className="hide-mobile arrow-img" alt="arrow" />
            </div>
            <div className="steps-how mx-auto relative">
                <StepSlider {...stepSettings}>
                    <ImageWithText
                        section="step-row"
                        imageDesktop={gdnWorkStep1}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="GuardiansPage.hwit.step1" />}
                        firstTitle={<FormattedMessage id="GuardiansPage.hwit.firstTitle" />}
                        text={<FormattedMessage id="GuardiansPage.hwit.firstDesc" />}
                    />

                    <ImageWithText
                        section="step-row"
                        imageDesktop={gdnWorkStep2}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="GuardiansPage.hwit.step2" />}
                        firstTitle={<FormattedMessage id="GuardiansPage.hwit.secondTitle" />}
                        text={lastStepData}
                    />
                    <ImageWithText
                        section="step-row"
                        imageDesktop={gdnWorkStep3}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="GuardiansPage.hwit.step3" />}
                        firstTitle={<FormattedMessage id="GuardiansPage.hwit.thirdTitle" />}
                        text={<FormattedMessage id="GuardiansPage.hwit.thirdDesc" />}
                    />
                </StepSlider>
            </div>
        </HowitWorksSection>
    );
};

export default HowitWorks;
