import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Styles.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { FormattedMessage } from 'react-intl';

const Pagination = props => {
    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item) => (
                <>
                  {item}
                </>
              ))}
          </>
        );
    }

    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 6;
    const currentItems = props.itemsArray.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(props.itemsArray.length / 6);

    const handlePageClick = (event) => {
    const newOffset = (event.selected * 6) % props.itemsArray.length;
    setItemOffset(newOffset);
    };
    
    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
                nextLabel= {<div><FormattedMessage id="NewGuardiansPage.Income.pageNext" /> <ArrowForwardIosIcon style={{ fontSize: 18, width: 50 }} /></div>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={-1}
                marginPagesDisplayed={-1}
                pageCount={pageCount}
                previousLabel={<div><ArrowBackIosIcon style={{ fontSize: 18, width: 50 }} /> <FormattedMessage id="NewGuardiansPage.Income.pageBack" /></div>}
                renderOnZeroPageCount={null}
                containerClassName={styles.pagination}
                previousClassName={styles.previous}
                nextClassName={styles.next}
                previousLinkClassName={styles.link}
                nextLinkClassName={styles.link}
                disabledLinkClassName={styles.disableLink}
            />
        </>
    )
}

export default Pagination;