import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import bgTogether from '../../../../assets/newAssets/bg-together-2.png';
import bgTogetherMobile from '../../../../assets/newAssets/bg-together-mobile.png';
import hangPetal from '../../../../assets/newAssets/hang-petal.png';
import { NatureTogether, PositiveImpact } from './Styles';
import { useCountUp } from 'react-countup';
const Counter = ({ end, delay, separator }) => {
    const [isVisible, setIsVisible] = useState(false);
    const counter = useCountUp({
        end,
        delay,
        separator,
    });

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById('counterContainer');
            if (element) {
                const rect = element.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                const newIsVisible = rect.top < windowHeight && rect.bottom >= 0;
                if (newIsVisible && !isVisible) {
                    setIsVisible(true);
                    counter.start();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);

    return <div>{counter.countUp}</div>;
};

const Mission = () => {
    const missionBackground = [bgTogether, bgTogetherMobile];
    return (
        <>
            <NatureTogether
                missionBackground={missionBackground}
                className="flex items-center justify-center"
            >
                <div className="mission-group flex justify-center flex-col">
                    <div className="title-box">
                        <h5 className="small">
                            <FormattedMessage id="NewLandingPage.mission.label" />
                        </h5>
                        <h3>
                            <FormattedMessage id="NewLandingPage.mission.title" />
                        </h3>
                    </div>
                    <div className="learn-more">
                        <OrangeBtn>
                            <Link to="/about">
                                <FormattedMessage id="NewLandingPage.mission.learn" />
                            </Link>
                        </OrangeBtn>
                    </div>
                </div>
            </NatureTogether>

            <PositiveImpact hangPetal={hangPetal}>
                <div className="impact-info mx-auto flex flex-col">
                    <h3>
                        <FormattedMessage id="NewLandingPage.mission.positiveImpact" />
                    </h3>
                    <div className="impact-group">
                        <div id="counterContainer" className="grid grid-three-column">
                            <div className="impact-info-numbers flex flex-col">
                                <div className="total-numbers-info">
                                    <Counter end={13} delay={4} separator={','} />
                                    <span>
                                        <FormattedMessage id="NewLandingPage.mission.landManager" />
                                    </span>
                                </div>
                                <div className="short-info">
                                    <FormattedMessage id="NewLandingPage.mission.technical" />
                                </div>
                            </div>

                            <div className="impact-info-numbers flex flex-col">
                                <div className="total-numbers-info">
                                    <Counter end={127313} delay={10} separator={','} />
                                    <FormattedMessage id="NewLandingPage.mission.hectares" />
                                </div>
                                <div className="short-info">
                                    <FormattedMessage id="NewLandingPage.mission.protected" />
                                </div>
                            </div>

                            <div className="impact-info-numbers flex flex-col">
                                <div className="total-numbers-info">
                                    <Counter end={180010} delay={4} separator={','} />
                                    <FormattedMessage id="NewLandingPage.mission.tonnes" />
                                </div>
                                <div className="short-info">
                                    <FormattedMessage id="NewLandingPage.mission.carbon" />
                                </div>
                            </div>
                        </div>
                        <div className="green-info">
                            <FormattedMessage id="NewLandingPage.mission.dedication" />
                        </div>
                    </div>
                </div>
            </PositiveImpact>
        </>
    );
};

export default Mission;
