import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import heroimage from '../../assets/detalleselva.jpg';
import { Hero, LayoutSideNavigation, LayoutWrapperFooter, LayoutWrapperMain, LayoutWrapperSideNav, LayoutWrapperTopbar, NamedLink, Page } from '../../components';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ProfileSettingsForm } from '../../forms';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import css from './ProfileSettingsPage.css';
import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
    } = this.props;
    const tabs = [
      {
        text: <FormattedMessage id="ContactDetailsPage.paisaje" />,
        selected: false,
        linkProps: {
          name: 'YourListingsPage',
        },
      },
      // {
      //   text: <FormattedMessage id="ContactDetailsPage.messages" />,
      //   selected: false,
      //   linkProps: {
      //     name: 'InboxPageOrders',
      //   },
      // },
      {
        text: <FormattedMessage id="ContactDetailsPage.profileSettings" />,
        selected: true,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.contactDetailsTabTitle" />,
        selected: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.passwordTabTitle" />,
        selected: false,
        linkProps: {
          name: 'PasswordChangePage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.updateCard" />,
        selected: false,
        linkProps: {
          name: 'UpdateCardPage',
        },
      },
      {
        text: <FormattedMessage id="PaymentHistoryPage.title" />,
        selected: false,
        linkProps: {
          name: 'PaymentHistoryPage',
        },
      },
    ];
    const handleSubmit = values => {
      const {
        firstName,
        lastName,
        bio: rawBio,
        organizationName: rawOrgName,
        ourWorkWithRegenera: rawRegeneraWorkName,
        ourPassion: rawPassion,
        staticProfileImages: rawStatic,

      } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';
      const organizationName = rawOrgName || '';
      const ourWorkWithRegenera = rawRegeneraWorkName || '';
      const ourPassion = rawPassion || '';
      const staticProfileImages = rawStatic || '';

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
        publicData:{
          organizationName: organizationName,
          ourWorkWithRegenera: ourWorkWithRegenera,
          ourPassion: ourPassion,
          staticProfileImages: staticProfileImages,
        }
      };
      const uploadedImage = this.props.image;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues);
    };

    const user = ensureCurrentUser(currentUser);
    const {organizationName,ourWorkWithRegenera,ourPassion,staticProfileImages} = user.attributes.profile.metadata || {};
    const {userType} = user.attributes.profile || {};
    const { firstName, lastName, bio } = user.attributes.profile;
    const profileImageId = user.profileImage && user.profileImage.url ? user.profileImage.url : null;
    const profileImage = image || { imageId: profileImageId, url:  user.profileImage.url};

    // const profileImageId = user.profileImage ? user.profileImage.id : null;
    // const profileImage = image || { imageId: profileImageId };


    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ firstName, lastName, bio, profileImage: user.profileImage ,organizationName:organizationName,ourWorkWithRegenera:ourWorkWithRegenera,ourPassion:ourPassion,staticProfileImages: staticProfileImages}}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <Header transparent currentPath={this.props.location.pathname} user={this.props.user} history={this.props.history} />
            <Hero size='medium' title={intl.formatMessage({ id: 'TopbarMobileMenu.greeting'}, {displayName: firstName})}  image={heroimage}/>
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={tabs} />
          <LayoutWrapperMain>

            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id && userType !== 'personas' ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer history={this.props.history} />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  const user = state.user.currentUser
    ? {
        initials: state.user.currentUser.attributes.profile.abbreviatedName,
        name:
        state.user.currentUser.attributes.profile.firstName +
          ' ' +
          state.user.currentUser.attributes.profile.lastName,
      }
    : null;
  return {
    user,
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
