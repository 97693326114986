import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Left = styled.div`
font-family: 'Galano Grotesque';
    height:auto;
    width: 18%;
    background-color: #ffffff;
    padding:0px 10px;
    @media screen and (max-width: 860px) {
      width: 100%;
      background-color: black;
      padding: 30px 0px 30px 32px;
    }
`;

export const Hide = styled.div`
@media screen and (max-width: 860px) {
  display:none;
  width: 0;
  height:0;
}
`;

export const Container = styled.div`
    padding:0px;
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  text-align: center;
`;

export const List = styled.div`
  list-style: none;
  margin: 0;
  padding-bottom: 15px;
  padding-top: 5px;
`;

export const ListItems = styled.div`
  padding: 15px 15px 5px 18px;
  font-family: 'Galano Grotesque';
  a{
    list-style: none;
    text-decoration: none;
    color: #000000;
    font-family: 'Galano Grotesque';
    font-weight: 700;
    font-size:18px;
  }
  :hover {
  background-color: #F5F5F5;
  cursor: pointer;
  }
`;