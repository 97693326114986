import React, { useState } from 'react';
import styled from 'styled-components';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';
import CheckBoxInfo from '../../../../newComponents/checkbox';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';
import * as coreAPI from '../../../../coreApi';
import ReCAPTCHA from 'react-google-recaptcha';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Input, StyledSuccessMessage, ErrorMessage } from './Styles';

const BlogUpdate = styled.section`
    position: relative;
    background: url(${({ bgGreenTexture }) => bgGreenTexture});
    .news-section {
        min-height: 400px;
        max-width: 630px;
        text-align: center;
        gap: 32px;
        color: #fff;
        padding: 24px 20px;
        .title-box {
            padding: 0 30px;
            h3 {
                color: white;
            }
        }
        .content {
            .news-form {
                gap: 16px;
                .form-group {
                    flex: 1 0 40%;
                }
                .checkbox-section {
                    width: 68%;
                    .form-check-label {
                        font-weight: 300;
                        line-height: 1.5;
                    }
                }
            }
        }
        .success-fail {
            margin-top: 30px;
            font-weight: 600;
            height: 18px;
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .news-section {
            min-height: 320px;
            gap: 12px;
            .content {
                .news-form {
                    gap: 12px;
                    .form-group {
                        flex: 1 0 100%;
                    }
                    .checkbox-section {
                        width: 100%;
                    }
                    .orange-btn {
                        width: 100%;
                    }
                }
            }
        }
    }
`;

const BlogSubscribe = ({ ...props }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [success, setSuccess] = useState(false);
    const [messageStatus, setmessageStatus] = useState();
    const [checkboxError, setCheckboxError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const reCaptchaRefBlog = React.createRef();

    const namePlaceholder = props.intl.formatMessage({
        id: 'NewLandingPage.footer.newsLetter.name.placeHolder',
    });

    const consentMessage = props.intl.formatMessage({
        id: 'Blogs.NewsletterForm.consent',
    });

    const handleNameChange = e => {
        setName(e.target.value);
        setNameError('');
        setmessageStatus('');
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
        setEmailError('');
        setmessageStatus('');
    };

    const currentLocale = props.intl.locale;

    const checkEmailSubscriptionCaptcha = async captchaToken => {
        console.log('captchatoken', captchaToken);
        if (!!captchaToken && name !== '' && email !== '' && isChecked) {
            try {
                console.log('in check email subscription');
                await coreAPI.subscribeToNewsLetter(
                    {
                        name,
                        email,
                        language: currentLocale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
                setIsLoading(false);
                setmessageStatus('sended');
                setSuccess(true);
            } catch (e) {
                setIsLoading(false);
                setmessageStatus('error');
            }
        } else {
            setIsLoading(false);
        }
    };

    const validateEmail = email => {
        // Regular expression for basic email format validation
        const re = /.+@.+\.[A-Za-z]+$/;
        return re.test(email);
    };

    const handleNewsletter = async e => {
        e.preventDefault();

        if (name.trim() === '') {
            setNameError(true);
            return;
        }
        if (email.trim() === '') {
            setEmailError(true);
            return;
        }

        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }

        if (isChecked === false) {
            setCheckboxError(true);
            return;
        }
        if (!nameError && !emailError && !checkboxError) {
            setmessageStatus('');
            setIsLoading(true);
            reCaptchaRefBlog.current.execute();
        }
    };

    const SuccessMessage = (
        <StyledSuccessMessage>Thank you. Good news coming your way!</StyledSuccessMessage>
    );

    return (
        <BlogUpdate bgGreenTexture={bgGreenTexture}>
            <div className="news-section flex justify-center flex-col mx-auto">
                <div className="title-box mx-auto">
                    <h3>
                        <FormattedMessage id="Blogs.NewsletterForm.heading" />
                    </h3>
                </div>
                {!success ? (
                    <>
                        <div className="content">
                            <form onSubmit={handleNewsletter}>
                                <div className="news-form flex items-center justify-space-between flex-wrap">
                                    <div className="form-group">
                                        <Input
                                            type="name"
                                            aria-describedby="name"
                                            placeholder={namePlaceholder}
                                            value={name}
                                            onChange={handleNameChange}
                                            status={nameError && 'failure'}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Input
                                            type="email"
                                            aria-describedby="email id"
                                            placeholder="Email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            status={emailError && 'failure'}
                                        />
                                    </div>
                                    <CheckBoxInfo
                                        labelName={consentMessage}
                                        labelId="agreeconsent"
                                        checkboxSection="checkbox-section"
                                        isChecked={isChecked}
                                        onChange={() => {
                                            setIsChecked(!isChecked);
                                            setCheckboxError(false);
                                            setmessageStatus('');
                                        }}
                                    />
                                    <OrangeBtn
                                        className="orange-btn"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <FormattedMessage id="Blogs.NewsletterForm.submit" />
                                    </OrangeBtn>
                                </div>
                            </form>
                        </div>
                        {isLoading ? 'Captcha reloading...Please try again later.' : ''}
                        {messageStatus === 'error' && (
                            <ErrorMessage>
                                <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                            </ErrorMessage>
                        )}
                        {(nameError || emailError || checkboxError) && (
                            <ErrorMessage>
                                <FormattedMessage id="NewLandingPage.footer.validationMessage" />
                            </ErrorMessage>
                        )}
                    </>
                ) : (
                    SuccessMessage
                )}
            </div>
            <ReCAPTCHA
                ref={reCaptchaRefBlog}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={checkEmailSubscriptionCaptcha}
            />
        </BlogUpdate>
    );
};

export default injectIntl(BlogSubscribe);
