// CheckoutForm.js
import React from 'react';
import { createInstance } from '../../util/sdkLoader';
import { FormattedMessage } from 'react-intl';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  PaymentRequestButtonElement,
  IbanElement,
  IdealBankElement,
  StripeProvider,
  Elements,
  injectStripe,
}
  from 'react-stripe-elements';
import Button from '../../newComponents/Button';
import amex from '../../assets/cardIcons/amex.png';
import diners from '../../assets/cardIcons/diners.png';
import discover from '../../assets/cardIcons/discover.png';
import jcb from '../../assets/cardIcons/jcb.png';
import master from '../../assets/cardIcons/master.png';
import visa from '../../assets/cardIcons/visa.png';
import union from '../../assets/cardIcons/unionpay.png';
import stripe from '../../assets/cardIcons/stripe.png';
import { FieldTextInput, PrimaryButton, NamedRedirect } from '../../components';
import css from './CustomPayment.css'

import * as coreAPI from '../../coreApi'

const pintCardBrand = (state, cardBrand) => {
  if (state === cardBrand || state === 'unknown') {
    return {
      opacity: 1,
    }
  }
  else {
    return {
      opacity: 0.2,
      filter: 'grayscale(1)'
    }
  }
}

const createOptions = (fontSize, padding) => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '20px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'IBM Plex Sans, Helvetica, Arial, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        fontWeight: '200',
        padding: '4px 0 10px 0',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cardChangeStatus: 'unchanged',
      cardBrand: 'unknown',
      transactionStatus: 'pending',
      userid: '',
      email: '',
      cardHolderName: '',
      cardNumberComplete: false,
      expiredDateComplete: false,
      ccvComplete: false,
      hasStripeAccount: false
    };
  }

  componentDidMount() {

    coreAPI.getCurrentUser().then((response) => {
      const currentUserData = response.data
      if (!!currentUserData.stripeDetails) {
        this.setState({
          userid: currentUserData.stripeDetails.stripeId
        })
      }
    })

    // sdk.currentUser.show({include:["stripeCustomer"]}).then(response=>{
    //   this.setState({
    //     userid: response.data.data.attributes.profile.protectedData.stripeid,
    //   })
    // })
  }
  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ transactionStatus: 'loading' })
    try {
      let { token } = await this.props.stripe.createToken({ name: 'Luke Sky' })
      let response = await coreAPI.updateCardDetails(token.id)
      // await response.json();
      this.setState({ cardChangeStatus: 'changed' })
    } catch (e) {
      this.setState({ cardChangeStatus: 'error' })
    }
  };
  render() {
    let cardHolderNameCompleted = this.state.cardHolderName.length > 0 ? false : true
    let submitDisabled = !this.state.cardNumberComplete || !this.state.expiredDateComplete || !this.state.ccvComplete || cardHolderNameCompleted
    return (
      <React.Fragment>
        {
          <form onSubmit={this.handleSubmit}>
            <div className={css.formElementsWrapper}>
              <label><FormattedMessage id="StripePaymentForm.Name.title" /></label>
              <input
                type="text"
                maxLength="20"
                className={css.cardinputName}
                placeholder='Juan Perez'
                value={this.state.cardHolderName.replace(/[^A-Za-z ]/ig, '')}
                onChange={(e) => this.setState({ cardHolderName: e.target.value })}
              />
            </div>

            <div className={css.formElementsWrapper}>
              <label><FormattedMessage id="StripePaymentForm.cardNumber.title" /></label>
              <div className={css.cardIconContainer}>
                <img className={css.cardBrandIcon} src={visa} style={pintCardBrand(this.state.cardBrand, 'visa')} />
                <img className={css.cardBrandIcon} src={master} style={pintCardBrand(this.state.cardBrand, 'mastercard')} />
                <img className={css.cardBrandIcon} src={amex} style={pintCardBrand(this.state.cardBrand, 'amex')} />
                <img className={css.cardBrandIcon} src={diners} style={pintCardBrand(this.state.cardBrand, 'diners')} />
                <img className={css.cardBrandIcon} src={discover} style={pintCardBrand(this.state.cardBrand, 'discover')} />
                <img className={css.cardBrandIcon} src={jcb} style={pintCardBrand(this.state.cardBrand, 'jcb')} />
                <img className={css.cardBrandIcon} src={union} style={pintCardBrand(this.state.cardBrand, 'unionpay')} />
              </div>
              <div className={css.stripeElemWrapper}>
                <CardNumberElement
                  onChange={(StripeElement) => {
                    this.setState({ cardBrand: StripeElement.brand })
                    this.setState({ cardNumberComplete: StripeElement.complete }
                    )
                  }}
                  {...createOptions(this.props.fontSize)}
                />
              </div>

            </div>

            <div className={css.formElementsWrapper}>
              <label><FormattedMessage id="StripePaymentForm.cardExpiry.title" /></label>
              <div className={`${css.stripeElemWrapper} ${css.halfForm}`}>
                <CardExpiryElement
                  onChange={(StripeElement) => { this.setState({ expiredDateComplete: StripeElement.complete }) }}
                  {...createOptions(this.props.fontSize)}
                />
              </div>
            </div>

            <div className={css.formElementsWrapper}>
              <label>CCV</label>
              <div className={`${css.stripeElemWrapper} ${css.halfForm}`}>
                <CardCVCElement
                  onChange={(StripeElement) => { this.setState({ ccvComplete: StripeElement.complete }) }}
                  {...createOptions(this.props.fontSize)}
                />
              </div>
            </div>

            <img src={stripe} />
            <div className={css.paybutton}>
              {!cardHolderNameCompleted && !submitDisabled ? <Button
                onClick={this.handleSubmit}
                variant="filled"
                size="lg"
              >
                <FormattedMessage id="StripePaymentForm.updateCardButton" />
              </Button> : <Button
                variant="disabled"
                size="lg"
                disabled={true}
              >
                <FormattedMessage id="StripePaymentForm.updateCardButton" />
              </Button>}
            </div>
          </form>
        }
        {
          this.state.cardChangeStatus === 'changed' ?
            <p>You have successfully changed your card</p> : null
        }

        {
          this.state.cardChangeStatus === 'error' ?
            <>
              <p>The payment could not be processed because the card was declined</p>
            </> : null

        }

      </React.Fragment>
    );
  }
}

CheckoutForm.defaultProps = {
  currentUser: null,
};


export default injectStripe(CheckoutForm);
