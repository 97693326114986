import styled from 'styled-components';

export const Left = styled.div`
font-family: 'Galano Grotesque';
    height:auto;
    width: 18%;
    background-color: #ffffff;
    padding:0px 10px;
    @media screen and (max-width: 860px) {
      width:100%;
      background-color: black;
      padding: 30px 0px 30px 32px;
    }
`;

export const Hide = styled.div`
@media screen and (max-width: 860px) {
  display:none;
  width: 0;
  height:0;
}
`;
