import styled from 'styled-components';

export const Frame = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    position: relative;
    width: 100%;
    height: auto;

    & > :nth-child(1) {
        position: absolute;
        width: 100%;
        top: 0;
        @media screen and (min-width: 861px) and (max-width: 1024px) {
            width: 115%;
        }
    }
    & > :nth-child(2) {
        position: absolute;
        left: 0;
        top: 126px;
    }
    & > :nth-child(3) {
        position: absolute;
        left: 18%;
        top: 126px;
        height: 240px;
        @media screen and (min-width: 860px) and (max-width: 1024px) {
            width: 100%;
        }
        @media screen and (max-width: 860px) {
            left: 0;
            width: 100vw;
            height: 110px;
            top: 228px;
        }
    }
    & > :nth-child(4) {
        position: absolute;
        left: 18%;
        top: 366px;
        @media screen and (max-width: 860px) {
            left: 0;
            top: 338px;
        }
    }
    & > :nth-child(5) {
        position: relative;
        right: 0;
        top: 366px;
        margin: auto;
        margin-right: 0;

        @media screen and (max-width: 768px) {
            display: none;
            width: 0;
            height: 0;
        }
    }
`;

export const MobileComp = styled.div`
    display: none;
    @media screen and (max-width: 860px) {
        display: block;
        background-color: white;
    }
`;
