import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../assets/newAssets/arrow-down-grey.svg';
import {
    ItemContainer,
    Dropdown,
    DropdownItem,
    Input,
    Holder,
    Row,
    LabelHead,
    InputButton,
    ButtonContainer,
    AddfuelContainer,
} from './Styles.js';
import { getOrganizationSectors } from '../../../../../coreApi';
import Button from '../../../../../newComponents/Button';
import ActivityItem4 from './activityItem4.js';
import { v4 as uuid } from 'uuid';
import { isNil, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
export const ActivityList4 = props => {
    const generateItem = () => {
        return {
            id: uuid(),
            Waste: '',
            WasteType: '',
            value: 0,
            unit: '',
            activityId: '',
        };
    };

    const [userData, setUserData] = useState([generateItem()]);
    useEffect(() => {
        const activities = [];
        if (
            !isNil(props.emissionActivitiesData[props.scope]) ||
            !isEmpty(props.emissionActivitiesData[props.scope])
        ) {
            props.emissionActivitiesData[props.scope].map(data => {
                if (data.groupName === props.type) {
                    activities.push({
                        id: uuid(),
                        Waste: data.activityName,
                        WasteType: data.subType,
                        value: data.value,
                        unit: data.unit,
                        activityId: data.id,
                    });
                }
            });
            if (activities.length > 0) {
                props.emissionActivities(activities, props.type);
                setUserData(activities);
            } else {
                setUserData([generateItem()]);
            }
        }
    }, []);
    const deleteData = id => {
        const index = userData.findIndex(data => data.id === id);
        if (index != -1) {
            userData.splice(index, 1);
        }
        props.emissionActivities(userData, props.type);
        setUserData([...userData]);
    };
    const saveData = inputData => {
        const index = userData.findIndex(data => data.id === inputData.id);
        if (index != -1) {
            userData[index] = inputData;
        }
        const data = userData.slice();
        setUserData(data);
        props.emissionActivities(data, props.type);
    };
    const handleIncrement = () => {
        const length = userData.length;
        if (
            !isEmpty(userData) &&
            userData[length - 1].Waste != '' &&
            userData[length - 1].WasteType != '' &&
            userData[length - 1].unit != '' &&
            userData[length - 1].value >= 0
        ) {
            const data = userData.slice();
            data.push(generateItem());
            setUserData(data);
        }
        if (userData.length == 0) {
            const data = userData.slice();
            data.push(generateItem());
            setUserData(data);
        }
    };
    let id = 0;
    return (
        <ItemContainer>
            <Row>
                <Holder>
                    <LabelHead>{props.label}</LabelHead>
                </Holder>
                <Holder>
                    <LabelHead>{props.label2}</LabelHead>
                </Holder>
                <Holder>
                    <LabelHead>Value</LabelHead>
                </Holder>
                <Holder>
                    <LabelHead>Unit</LabelHead>
                </Holder>
                <Holder>
                    <LabelHead></LabelHead>
                </Holder>
            </Row>
            {userData.map((data, i) => {
                return (
                    <ActivityItem4
                        key={data.id}
                        type={props.type}
                        scope={props.scope}
                        data={data}
                        emissionActivityDetails={props.emissionActivityDetails}
                        units={props.units}
                        saveData={saveData}
                        deleteData={deleteData}
                        label={props.label}
                        label2={props.label2}
                    />
                );
            })}

            <AddfuelContainer>
                <Button onClick={handleIncrement}>
                    <FormattedMessage id="OrganisationCalculator.Button.Activity" />
                </Button>
            </AddfuelContainer>
        </ItemContainer>
    );
};

export default ActivityList4;
