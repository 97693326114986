import styled from 'styled-components';
import { colors } from '../../../../../theme';
import thumbprintWhite from '../../../../../assets/newAssets/thumbprintWhite.svg';
export const AccountRoleMain = styled.div`
    height: auto;
    width: 67%;
    background-color: #FFF3EC;
    background-image: url(${thumbprintWhite});
    background-position: center;
    display: flex;
    font-family: 'Galano Grotesque';
    flex-direction: column;
    padding:30px;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;

export const RoleCardWrapper = styled.div`

    padding-left:30px;
    background-color: #FFF3D6;
    border-radius: 6px;
    width:100%;
    box-sizing:border-box;
    height: 350px;
`;

export const SubRoleCardWrapper = styled.div`

    padding-left:30px;
    background-color: #FFF3D6;
    border-radius: 6px;
    width:100%;
    box-sizing:border-box;
    height: 300px;
    margin:25px auto;
`;

export const MainSubRoleWrapper = styled.div`
    width:100%;
    padding: 0px 100px;
`;

export const titleStyle = {
    textAlign:'left',
    paddingLeft:'10px',
    fontFamily: 'Galano Grotesque',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '23.1858px',
    lineHeight: '31px',
    color: '#000000',

}

export const descriptionStyle = {
    textAlign:'left',
    padding:'10px',
    
}

export const imageContainer={
    height:'100%',
    width:'auto'
}

export const container={
    marginBottom:'0px',
    height:'100%'
}

export const textContainer={
    padding:'30px 0px'
}

export const ModalTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 36.1858px;
    line-height: 49px;
    color: #F29509;
    margin: 50px 0px;
`

export const ModalSubTitle = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 23.5164px;
    line-height: 32px;
    color: #000000;
    margin: 0px 0px 10px 20px;
    padding-bottom: 80px;
`