const languages = [
    { name: 'English', short: 'EN' },
    { name: 'Español', short: 'ES' },
    // { name: 'Portuguese', short: 'PT' },
];

const initialState = {
    selectedLanguage: { name: 'English', short: 'EN' },
    languages: languages,
    initLanguage: false,
};

const getLanguage = language => {
    console.log('language this', language);
    const filtered = languages.filter(lang => lang.short === language || lang.name === language);
    console.log('filtered', filtered[0]);
    return filtered[0];
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    console.log('in reducer');
    switch (type) {
        case 'CHANGE_LANGUAGE':
            console.log('payload', payload);
            return {
                ...state,
                selectedLanguage: getLanguage(payload),
                initLanguage: true,
            };
        default:
            return state;
    }
}

export const changeLanguage = language => ({ type: 'CHANGE_LANGUAGE', payload: language });
