import React from 'react';
import PropTypes from 'prop-types';
import { Container, Container2 } from './Styles';
import play from '../../assets/newAssets/playButton.svg';
import close from '../../assets/newAssets/closeButton.svg';
import Vimeo from '@u-wave/react-vimeo';
const Video = ({ source, open, onClick, videoItem, vimeo, onEnd }) => {
    return (
        <>
            {open ? (
                vimeo ? (
                    <Container>
                        <Vimeo video={source} autoplay onEnd={onEnd} />
                        <img onClick={onClick} src={close} />
                    </Container>
                ) : (
                    <Container>
                        <iframe
                            src={source}
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture;"
                            allowfullscreen
                        ></iframe>
                        <img onClick={onClick} src={close} />
                    </Container>
                )
            ) : (
                <Container2>
                    <img src={videoItem.src} srcset={videoItem.srcset} alt={videoItem.alt} />
                    <img src={play} onClick={onClick} />
                </Container2>
            )}
        </>
    );
};

Video.propTypes = {
    source: PropTypes.string.isRequired,
};

Video.defaultProps = {
    source: null,
};

export default Video;
