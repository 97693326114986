import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

const CarbonLineChart = (props) => {
  const reference = useRef();

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
  const labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Net Carbon footprint',
        data: props.data,
        borderColor: "rgba(242, 149, 9, 0.5)",
        // backgroundColor: "rgba(242, 149, 9, 0.5)",
        // fill: "start",
        yAxisID: 'y',
      }
    ]
  };


  const config = {
    type: "line",
    data: data,
    options: {
      layout: {
        padding: {
          top: 5,
        }
      },
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: `Footprint calculation for the year ${props.year.split('-')[0]}`,
          position: "bottom",
          font: function(context) {
            var width = context.chart.width;
            var size = Math.round(width / 45);
             return {
               size: size,
              weight: 700
            };
          },
        }
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          title: { 
            text: "Annual Climate Impact", 
            display: true,
            font: function(context) {
              var width = context.chart.width;
              var size = Math.round(width / 45);
               return {
                 size: size,
                weight: 700
              };
            }, 
          },
          grid: {
            display: false,
          }
        },
        x: {
          grid: {
            display: false,
          }
        }
      }
    }
  };
  useEffect(() => {
    const ctx = reference.current;
    new Chart(ctx, {
      ...config,
    });
  }, []);
  return (
    <div className="line-chart">
      <canvas ref={reference} width="400" height="275" ></canvas>
    </div>
  );
}

export default CarbonLineChart;