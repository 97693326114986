import React from 'react';
import styled from 'styled-components';
import titleBorderLine from '../../../assets/newAssets/title-border-line.svg';
import { FormattedMessage } from 'react-intl';

import planChooseIcon from '../../../assets/newAssets/icons/plan-choose-icon.svg';
import handPlantIcon from '../../../assets/newAssets/icons/hand-plant-icon.svg';
import leadIcon from '../../../assets/newAssets/icons/lead_icon.svg';

import IconWithText from '../../../newComponents/IconWithText';

const TakeAction = () => {
    const TakeActionSection = styled.section`
        padding: 88px 4.8rem 110px;
        text-align: center;

        .take-action-group {
            max-width: 1200px;
            width: 100%;
            flex: 1;
            gap: 72px 0;

            .title-box {
                max-width: 630px;
                margin: 0 auto;
                .border-img-section {
                    position: relative;
                    .head-border {
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                    }
                }
            }
            .icon-text-container {
                align-self: stretch;
                gap: 20px;
            }
        }
        @media (max-width: ${({ theme }) => theme.media.tab}) {
            padding: 56px 20px 42px;
            .take-action-group {
                gap: 32px;
                .icon-text-container {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 40px;
                }
            }
        }
    `;

    return (
        <TakeActionSection>
            <div className="take-action-group flex justify-start flex-col mx-auto">
                <div className="title-box">
                    <h1>
                        <span className="border-img-section">
                            <FormattedMessage id="NewLandingPage.takeAction.titleOne" />
                            <img src={titleBorderLine} alt="border line" className="head-border" />
                        </span>{' '}
                        <FormattedMessage id="NewLandingPage.takeAction.titleTwo" />
                    </h1>
                </div>
                <div className="icon-text-container flex justify-space-between flex-row items-start">
                    <IconWithText
                        icon={planChooseIcon}
                        title={<FormattedMessage id="NewLandingPage.takeAction.title.planChoose" />}
                        text={<FormattedMessage id="NewLandingPage.takeAction.text.planChoose" />}
                    />
                    <IconWithText
                        icon={handPlantIcon}
                        title={<FormattedMessage id="NewLandingPage.takeAction.title.handPlant" />}
                        text={<FormattedMessage id="NewLandingPage.takeAction.text.handPlant" />}
                    />
                    <IconWithText
                        icon={leadIcon}
                        title={<FormattedMessage id="NewLandingPage.takeAction.title.lead" />}
                        text={<FormattedMessage id="NewLandingPage.takeAction.text.lead" />}
                    />
                </div>
            </div>
        </TakeActionSection>
    );
};

export default TakeAction;
