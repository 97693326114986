import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import InjectedCheckoutForm from './CheckoutFormUpdate';
import { StripeProvider, Elements } from 'react-stripe-elements';

import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Page,
  Hero,
} from '../../components';
import heroimage from '../../assets/detalleselva.jpg';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

import css from './UpdateCardPage.css';

class UpdateCardPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };
  }
  componentDidMount() {
    this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) });
  }
  render() {
    const { intl, params: rawParams, scrollingDisabled, currentUser } = this.props;

    const tabs = [
      {
        text: <FormattedMessage id="ContactDetailsPage.paisaje" />,
        selected: false,
        linkProps: {
          name: 'YourListingsPage',
        },
      },
      // {
      //   text: <FormattedMessage id="ContactDetailsPage.messages" />,
      //   selected: false,
      //   linkProps: {
      //     name: 'InboxPageOrders',
      //   },
      // },
      {
        text: <FormattedMessage id="ContactDetailsPage.profileSettings" />,
        selected: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.contactDetailsTabTitle" />,
        selected: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.passwordTabTitle" />,
        selected: false,
        linkProps: {
          name: 'PasswordChangePage',
        },
      },
      {
        text: <FormattedMessage id="ContactDetailsPage.updateCard" />,
        selected: true,
        linkProps: {
          name: 'UpdateCardPage',
        },
      },
      {
        text: <FormattedMessage id="PaymentHistoryPage.title" />,
        selected: false,
        linkProps: {
          name: 'PaymentHistoryPage',
        },
      },
    ];
    const title = intl.formatMessage({
      id: 'UpdateCardPage.title',
    });

    const user = ensureCurrentUser(currentUser);
    const currentFirstName = user.attributes.profile.firstName || '';

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <Header
              transparent
              currentPath={this.props.location.pathname}
              user={this.props.user}
              history={this.props.history}
            />

            <Hero
              size="medium"
              title={intl.formatMessage(
                { id: 'TopbarMobileMenu.greeting' },
                { displayName: currentFirstName }
              )}
              image={heroimage}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={tabs} />
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="UpdateCardPage.title" />
              </h1>
              <StripeProvider stripe={this.state.stripe}>
                <Elements>
                  <InjectedCheckoutForm />
                </Elements>
              </StripeProvider>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer history={this.props.history} />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

UpdateCardPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, func } = PropTypes;

UpdateCardPageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.PasswordChangePage;
  const { currentUser } = state.user;
  const user = state.user.currentUser
    ? {
        initials: state.user.currentUser.attributes.profile.abbreviatedName,
        name:
        state.user.currentUser.attributes.profile.firstName +
          ' ' +
          state.user.currentUser.attributes.profile.lastName,
      }
    : null;
  return {
    user,
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
  };
};

const UpdateCardPage = compose(withRouter, connect(mapStateToProps), injectIntl)(UpdateCardPageComponent);

export default UpdateCardPage;
