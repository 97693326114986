import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import * as coreAPI from '../../coreApi';
import {
    isSignupEmailTakenError,
    isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
    Page,
    NamedLink,
    NamedRedirect,
    IconEmailSent,
    InlineTextButton,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Modal,
    TermsOfService,
} from '../../components';
import PrivacyPolicyPage from '../PrivacyPolicyPage/PrivacyPolicyPage';
import { LoginForm, SignupForm } from '../../forms';
import { login, authenticationInProgress, signup } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import ReCAPTCHA from 'react-google-recaptcha';
import css from './AuthenticationPage.css';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';

export class AuthenticationPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { tosModalOpen: false, privacyModalOpen: false, signUpDetails: null };
    }
    render() {
        const {
            authInProgress,
            currentUser,
            intl,
            isAuthenticated,
            location,
            loginError,
            scrollingDisabled,
            signupError,
            submitLogin,
            submitSignup,
            tab,
            sendVerificationEmailInProgress,
            sendVerificationEmailError,
            onResendVerificationEmail,
            onManageDisableScrolling,
        } = this.props;
        const isLogin = tab === 'login';
        const from = location.state && location.state.from ? location.state.from : null;
        console.log('from', location);
        const user = ensureCurrentUser(currentUser);
        const currentUserLoaded = !!user.id;

        // We only want to show the email verification dialog in the signup
        // tab if the user isn't being redirected somewhere else
        // (i.e. `from` is present). We must also check the `emailVerified`
        // flag only when the current user is fully loaded.
        const showEmailVerification =
            !isLogin && currentUserLoaded && !user.attributes.emailVerified;

        // Already authenticated, redirect away from auth page
        if (isAuthenticated && from) {
            return <Redirect to={from} />;
        } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
            return <NamedRedirect name="LandingPage" />;
        }

        const loginErrorMessage = (
            <div className={css.error}>
                <FormattedMessage id="AuthenticationPage.loginFailed" />
            </div>
        );

        const signupErrorMessage = (
            <div className={css.error}>
                {isSignupEmailTakenError(signupError) ? (
                    <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                ) : (
                    <FormattedMessage id="AuthenticationPage.signupFailed" />
                )}
            </div>
        );

        // eslint-disable-next-line no-confusing-arrow
        const errorMessage = (error, message) => (error ? message : null);
        const loginOrSignupError = isLogin
            ? errorMessage(loginError, loginErrorMessage)
            : errorMessage(signupError, signupErrorMessage);

        const currentLocale = this.props.intl.locale;

        const reCaptchaRef = React.createRef();
        const reCaptchaRefNewsLetter = React.createRef();

        const checkCaptcha = captchaToken => {
            // console.log('form details', this.state.signUpDetails)
            if (!!captchaToken && !!this.state.signUpDetails) {
                try {
                    reCaptchaRefNewsLetter.current.execute();
                    submitSignup({ ...this.state.signUpDetails, captchaToken });
                } catch (e) {
                    console.log(e);
                }
            }
        };

        const checkCaptchaNewsLetter = async captchaToken => {
            if (!!captchaToken && !!this.state.signUpDetails) {
                try {
                    const userFirstName = this.state.signUpDetails.firstName;
                    const userLastName = this.state.signUpDetails.lastName;
                    const userEmail = this.state.signUpDetails.email;
                    await coreAPI.subscribeToNewsLetter(
                        {
                            firstname: userFirstName,
                            lastname: userLastName,
                            email: userEmail,
                            language: currentLocale,
                            statusIfNew: 'subscribed',
                            status: 'subscribed',
                        },
                        captchaToken
                    );
                } catch (e) {
                    console.log(e);
                }
            }
        };

        const handleSubmitSignup = values => {
            const { fname, lname, ...rest } = values;
            const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
            this.setState({ signUpDetails: params });
            reCaptchaRef.current.execute();
        };

        const formContent = (
            <div className={css.content}>
                {loginOrSignupError}
                {isLogin ? (
                    <LoginForm
                        className={css.form}
                        onSubmit={submitLogin}
                        inProgress={authInProgress}
                    />
                ) : (
                    <SignupForm
                        className={css.form}
                        onSubmit={handleSubmitSignup}
                        inProgress={authInProgress}
                        onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
                        onOpenPrivacyPolicy={() => this.setState({ privacyModalOpen: true })}
                    />
                )}
            </div>
        );

        const name = user.attributes.profile.firstName;
        const email = <span className={css.email}>{user.attributes.email}</span>;

        const resendEmailLink = (
            <InlineTextButton
                rootClassName={css.modalHelperLink}
                onClick={onResendVerificationEmail}
            >
                <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
            </InlineTextButton>
        );
        const fixEmailLink = (
            <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
                <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
            </NamedLink>
        );

        const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
        )
            ? 'AuthenticationPage.resendFailedTooManyRequests'
            : 'AuthenticationPage.resendFailed';
        const resendErrorMessage = sendVerificationEmailError ? (
            <p className={css.error}>
                <FormattedMessage id={resendErrorTranslationId} />
            </p>
        ) : null;

        const emailVerificationContent = (
            <div className={css.content}>
                <IconEmailSent className={css.modalIcon} />
                <h1 className={css.modalTitle}>
                    <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
                </h1>
                <p className={css.modalMessage}>
                    <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
                </p>
                {resendErrorMessage}

                <div className={css.bottomWrapper}>
                    <p className={css.modalHelperText}>
                        {sendVerificationEmailInProgress ? (
                            <FormattedMessage id="AuthenticationPage.sendingEmail" />
                        ) : (
                            <FormattedMessage
                                id="AuthenticationPage.resendEmail"
                                values={{ resendEmailLink }}
                            />
                        )}
                    </p>
                    <p className={css.modalHelperText}>
                        <FormattedMessage
                            id="AuthenticationPage.fixEmail"
                            values={{ fixEmailLink }}
                        />
                    </p>
                </div>
            </div>
        );

        const siteTitle = config.siteTitle;
        const schemaTitle = isLogin
            ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
            : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

        const topbarClasses = classNames({
            [css.hideOnMobile]: showEmailVerification,
        });

        return (
            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'WebPage',
                    name: schemaTitle,
                }}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={this.props.location.pathname}
                            user={this.props.user}
                            history={this.props.history}
                        />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain className={css.layoutWrapperMain}>
                        <ReCAPTCHA
                            ref={reCaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                            onChange={checkCaptcha}
                        />
                        <ReCAPTCHA
                            ref={reCaptchaRefNewsLetter}
                            size="invisible"
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                            onChange={checkCaptchaNewsLetter}
                        />
                        <div className={css.root}>
                            {showEmailVerification ? emailVerificationContent : formContent}
                        </div>
                        <Modal
                            id="AuthenticationPage.tos"
                            isOpen={this.state.tosModalOpen}
                            onClose={() => this.setState({ tosModalOpen: false })}
                            onManageDisableScrolling={onManageDisableScrolling}
                        >
                            <div className={css.termsWrapper}>
                                <h2 className={css.termsHeading}>
                                    <FormattedMessage id="AuthenticationPage.termsHeading" />
                                </h2>
                                <TermsOfService />
                            </div>
                        </Modal>

                        <Modal
                            id="PrivacyPolicyPage.privacy"
                            isOpen={this.state.privacyModalOpen}
                            onClose={() => this.setState({ privacyModalOpen: false })}
                            onManageDisableScrolling={onManageDisableScrolling}
                        >
                            <div className={css.termsWrapper}>
                                <h2 className={css.termsHeading}>
                                    <FormattedMessage id="AuthenticationPage.privacyHeading" />
                                </h2>
                                <PrivacyPolicyPage />
                            </div>
                        </Modal>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer history={this.props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }
}

AuthenticationPageComponent.defaultProps = {
    currentUser: null,
    loginError: null,
    signupError: null,
    tab: 'signup',
    sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
    authInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    isAuthenticated: bool.isRequired,
    loginError: propTypes.error,
    scrollingDisabled: bool.isRequired,
    signupError: propTypes.error,
    submitLogin: func.isRequired,
    submitSignup: func.isRequired,
    tab: oneOf(['login', 'signup']),

    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    onManageDisableScrolling: func.isRequired,

    // from withRouter
    location: shape({ state: object }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { isAuthenticated, loginError, signupError } = state.Auth;
    const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
          }
        : null;
    return {
        user,
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        scrollingDisabled: isScrollingDisabled(state),
        signupError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
    };
};

const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
    submitSignup: params => dispatch(signup(params)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
