import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import moment from 'moment';
import amex from '../../assets/cardIcons/amex.png';
import diners from '../../assets/cardIcons/diners.png';
import discover from '../../assets/cardIcons/discover.png';
import jcb from '../../assets/cardIcons/jcb.png';
import master from '../../assets/cardIcons/master.png';
import stripe from '../../assets/cardIcons/stripe.png';
import union from '../../assets/cardIcons/unionpay.png';
import visa from '../../assets/cardIcons/visa.png';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';

import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Page,
  Hero,
  ExternalLink,
  NamedLink,
} from '../../components';
import heroimage from '../../assets/detalleselva.jpg';

import css from './PaymentHistoryPage.css';

import * as coreAPI from '../../coreApi';

const cardsIcons = {
  amex: amex,
  diners: diners,
  discover: discover,
  jcb: jcb,
  master: master,
  stripe: stripe,
  union: union,
  visa: visa,
};

class PaymentHistoryPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      history: [],
    };
  }
  componentDidMount() {
    coreAPI
      .getPaymentHistory()
      .then(response => {
        this.setState({ history: response.data.response });
      })
      .catch(e => {
        console.log(e);
      });
    
  }

  render() {
    const { intl, params: rawParams, scrollingDisabled, currentUser } = this.props;

    const tabs = [
      
    ];
    const title = intl.formatMessage({
      id: 'UpdateCardPage.title',
    });

    const user = ensureCurrentUser(currentUser);
    const currentFirstName = user.attributes.profile.firstName || '';
    console.log(this.state.history);
    const linkText = (
      <NamedLink name="Paisajes">
        {' '}
        <FormattedMessage id="PaymentHistory.noHistoryLinkText" />
      </NamedLink>
    );
    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <Header
              transparent
              currentPath={this.props.location.pathname}
              user={this.props.user}
              history={this.props.history}
            />
            <Hero
              size="medium"
              title={intl.formatMessage(
                { id: 'TopbarMobileMenu.greeting' },
                { displayName: currentFirstName }
              )}
              image={heroimage}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={tabs} />
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="PaymentHistoryPage.title" />
              </h1>
              {this.state.history.length > 0 ? (
                <ol className={css.historyList}>
                  {this.state.history.map((item, index) => (
                    <li key={index} className={css.HistItem}>
                      <div className={css.historyList_heading}>
                        <p className={css.HistItem_date}>
                          {moment.unix(item.created).format('LLLL')}
                        </p>
                        <ExternalLink href={item.receipt_url} className={css.HistItem_receiptLink}>
                          <FormattedMessage id="PaymentHistory.receiptExternalLink" />
                        </ExternalLink>
                      </div>
                      <div className={css.HistItem_details}>
                        <div>
                          <p className={css.HistItem_details_title}>
                            <FormattedMessage id="PaymentHistory.planColumnTitle" />
                          </p>
                          <p className={css.HistItem_details_desc}>
                            {item.invoice.subscription.plan.nickname}
                          </p>
                        </div>
                        <div>
                          <p className={css.HistItem_details_title}>
                            <FormattedMessage id="PaymentHistory.amountColumnTitle" />
                          </p>
                          <p className={css.HistItem_details_desc}>
                            { item.currency === 'usd' ? '$': 'S/' }{(item.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </p>
                        </div>
                        <div>
                          <p className={css.HistItem_details_title}>
                            <FormattedMessage id="PaymentHistory.paymentMethodColumnTitle" />
                          </p>
                          <p className={css.HistItem_details_desc}>
                            <img
                              src={cardsIcons[item.payment_method_details.card.brand]}
                              className={css.cardIcon}
                            />{' '}
                            <span className={css.secretCard}>**** *****</span>{' '}
                            {item.payment_method_details.card.last4}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ol>
              ) : (
                <p className={css.emptyListText}>
                  <FormattedMessage id="PaymentHistory.noHistory" values={{ linkText }} />
                </p>
              )}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer history={this.props.history} />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

PaymentHistoryPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, func } = PropTypes;

PaymentHistoryPageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const user = state.user.currentUser
    ? {
        initials: state.user.currentUser.attributes.profile.abbreviatedName,
        name:
        state.user.currentUser.attributes.profile.firstName +
          ' ' +
          state.user.currentUser.attributes.profile.lastName,
      }
    : null;
  return {
    user,
    currentUser,
  };
};

const PaymentHistoryPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(PaymentHistoryPageComponent);

export default PaymentHistoryPage;
