import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import RangeOption from '../../../../newComponents/RangeSlider/RangeSlide';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import GuardianLeafLeft from '../../../../assets/newAssets/guardians/guardian-leaf-left.webp';
import GuardianLeafRight from '../../../../assets/newAssets/guardians/guardian-leaf-right.webp';

import { ProtectLandSection } from './Styles';

const ProtectLand = () => {
    return (
        <ProtectLandSection
            GuardianLeafLeft={GuardianLeafLeft}
            GuardianLeafRight={GuardianLeafRight}
        >
            <div className="protect-land-box flex justify-start flex-col mx-auto">
                <div className="title-box">
                    <h2>
                        <FormattedMessage id="GuardiansPage.ProtectLand.Title" />
                    </h2>
                </div>
                <RangeOption
                    period={<FormattedMessage id="GuardiansPage.ProtectLand.Period" />}
                    unit={<FormattedMessage id="GuardiansPage.ProtectLand.Unit" />}
                    RangeClass="range-box"
                />
                <p className="base small">
                    <FormattedMessage id="GuardiansPage.ProtectLand.Base" />
                </p>
                <OrangeBtn className="mx-auto">
                    <Link to="/land-managers/role">
                        <FormattedMessage id="GuardiansPage.ProtectLand.Signup" />
                    </Link>
                </OrangeBtn>
            </div>
        </ProtectLandSection>
    );
};

export default ProtectLand;
