import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TabsContainer, Span } from './Style';
const Tabs=(props)=>{
    return(
        <>
        <TabsContainer>
            <Span selected={props.id==='CompanyDetails'?true:false}><FormattedMessage id="OrganisationCalculator.Tabs.CompanyDetails" /></Span> <Span>|</Span>
            <Span selected={props.id==='DirectEmission'?true:false}><FormattedMessage id="OrganisationCalculator.Tabs.DirectEmission" /></Span>< Span>|</Span>
            <Span selected={props.id==='ElectricityEmission'?true:false}><FormattedMessage id="OrganisationCalculator.Tabs.ElectricityEmission" /> </Span><Span>|</Span>  
            <Span selected={props.id==='IndirectEmission'?true:false}><FormattedMessage id="OrganisationCalculator.Tabs.OtherEmission" /></Span> <Span>|</Span>
            <Span selected={props.id==='Result'?true:false}><FormattedMessage id="OrganisationCalculator.Tabs.Results" /></Span>
        </TabsContainer>
        </>
    );
}

export default Tabs;