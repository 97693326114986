import styled from 'styled-components';
import { space, grid, layout } from 'styled-system';
import { colors } from '../../../../../theme';



export const Input = styled.input`
    padding:10px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 5px;
    overflow:hidden;
`;
export const Label = styled.div`
    font-family: 'Galano Grotesque';
    margin:11px 20px 9px 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
    @media screen and (min-width: 800px){
        display:none;
    }
`;
export const LabelHead = styled.div`
    font-family: 'Galano Grotesque';
    margin:11px 20px 9px 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
    @media screen and (max-width: 800px){
        display:none;
    }
`;
export const Dropdown = styled.ul`
    text-decoration: none;
    text-align: left;
    position: absolute;
    top:100%;
    left:0px;
    margin-top:-2px;
    background-color:white;
    font-family: 'Galano Grotesque';
    z-index: 200;
    width:100%;
    cursor:pointer;
    height: 200px;
    overflow-y:scroll;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;
export const DropdownItem = styled.li`
    text-decoration: none;
    text-align: left;
    position: relative;
    padding:10px;
    cursor:pointer;
    font-family: 'Galano Grotesque';
    &:hover {
        background-color: #C5C5C5;
    }
    @media screen and (max-width: 800px) {
        position: relative;
        width:auto;
    }
`;
export const InputButton = styled.div`
    position:relative;
    cursor:pointer;
    background: #FFFFFF;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #A9A9A9;
    padding:10px;
    overflow-y:scroll;
    .arrow{
        position:absolute;
        top:20px;
        right:20px;
        fill:none;
    }
`;
export const Holder = styled.div`
    margin-right:20px;
    position:relative;
    & > input:focus, input:hover {
        border-color: ${colors.green} !important;
    }
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
    @media screen and (max-width: 800px) {
        margin-bottom:15px;
        margin-right:0px;
    }
    
`;

export const Row = styled.div`
    display:flex;
    justify-content:space-between;
    margin:25px auto;
    box-sizing:border-box;
    width:80%;
    @media screen and (max-width: 800px) {
        width: 95%;
        display:flex;
    }
    @media screen and (max-width: 800px) {
        width: 95%;
        display:flex;
        flex-direction: column;
    }
    & :nth-child(1) {
        flex: 37.5%;
        & ${Dropdown} {
            width:100%;
        }
        & ${DropdownItem} {
            width:100%;
        }
    }
    & :nth-child(2) {
        flex: 37.5%;
        & ${Dropdown} {
            width:100%;
        }
        & ${DropdownItem} {
            width:100%;
        }
    }
    & :nth-child(3) {
            flex: 25%;
    }
    & :nth-child(4) {
        flex: 25%;
        & ${Dropdown} {
            width:100%;
        }
        & ${DropdownItem} {
            width:100%;
        }
    }
    & :nth-child(5) {
        flex: 25%;
    }
`;

export const ButtonContainer = styled.div`
  width:100%;
  box-sizing:border-box;
  position:relative;
  & > Button{
      height:50px;
      margin-right:20px;
      background: #F29509;
      border-radius: 0px;
      width:100%;
      font-family: 'Galano Grotesque';
      font-style: normal;
      font-weight: bold;
      font-size: 23.1858px;
      line-height: 31px;
      padding:10px 40px 10px 40px;
  }
  @media screen and (max-width: 800px) {
    width:100%;
    & > Button{
     margin-right:0px;
    }
  }
`;
export const ItemContainer = styled.div`
    box-sizing:border-box;
    & :nth-child(1) {
        flex: 37.5%;
        & ${Dropdown} {
            width:100%;
        }
        & ${DropdownItem} {
            width:100%;
        }
    }
`;
export const AddfuelContainer = styled.div`
    width:100%;
    text-align:center;
    & > Button{
        width:auto;
        height:auto;
        margin: 40px 10px;
        background: #F29509;
        border-radius: 0px;
        font-family: 'Galano Grotesque';
        font-style: normal;
        font-weight: bold;
        font-size: 23.1858px;
        line-height: 31px;
        padding:12px 65px;
    }
`;