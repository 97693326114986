import React, { Component, useRef } from 'react';
import willy from '../../assets/willy.jpg';
import { injectIntl, FormattedMessage } from 'react-intl';
import herrera from '../../assets/herrera.jpg';
import heroimage from '../../assets/personas-hero.jpg';
import claudia from '../../assets/claudia.jpg';
import {
    Hero,
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    ProductComparison,
    UpButton,
    Modal,
    SecondaryButton,
    PrimaryButton,
} from '../../components';
import * as coreAPI from '../../coreApi';

import { StaticPage } from '../../containers';
import css from './Personas.css';
import { FaRegPlayCircle } from 'react-icons/fa';
import PlansComparison from '../../newComponents/ChoosePlans/PlansComparison';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';

import Button from '../../newComponents/Button';
import AccountSetup from './sections/AccountSetup';
import LandScapeSelection from './sections/LandscapeSelection';
import Payment from './sections/Payment';
import PaymentSuccess from './sections/PaymentSuccess';

class PeoplePage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        planType: 'personas',
        selectedPlan: {},
        selectedLandScape: {},
        isPlanSelected: false,
        stripe: null,
        paymentStatus: false,
        subscriptionId: null,
        planID: null,
        cartPresent: false,
        isDisable: false,
    };
    googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
    paymentSuccessRef = React.createRef();
    paymentRef = React.createRef();
    accountSetupRef = React.createRef();

    handleCartStatus = cartPresent => {
        this.setState({
            ...this.state,
            cartPresent: cartPresent,
        });
    };

    handlePlanSelection = selectedPlan => {
        this.setState({
            ...this.state,
            selectedPlan,
            isPlanSelected: true,
            paymentStatus: false,
        });
        if (selectedPlan) {
            this.googleAnalytics &&
                window.gtag('event', 'get_plan_info', {
                    send_to: 'G-34FQJZ2P9G',
                    plan_type: this.state.planType,
                    plan: selectedPlan.plan,
                    price: selectedPlan.amount / 100,
                });
        }
        this.accountSetupRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    handleLandscapeSelection = selectedLandScape => {
        this.setState({ ...this.state, selectedLandScape });
    };
    updatePlanID = planID => {
        this.setState({ ...this.state, planID });
    };

    handlePaymentStatus = paymentStatus => {
        this.setState({ ...this.state, paymentStatus });
        if (paymentStatus) {
            // window.scrollTo({ top: this.paymentSuccessRef.current.offsetTop, behavior: 'smooth' });
            this.paymentSuccessRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
        this.setState({ ...this.state, isDisable: false });
    };

    updateSubscriptionPlan = subscriptionId => {
        this.setState({ ...this.state, subscriptionId });
    };

    supportLandscape = () => {
        coreAPI
            .changeLandscapePreference('plan_JKQm1tmh1QzCIw', this.state.subscriptionId)
            .then(response => {
                console.log({ response });
            });
    };

    scrollToPayment = () => {
        this.paymentRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    componentDidMount() {
        // Create Stripe instance in componentDidMount
        // (componentDidMount only fires in browser/DOM environment)
        this.setState({
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        });
    }

    handleDisable = disable => {
        this.setState({ ...this.state, isDisable: disable });
    };

    render() {
        const { intl } = this.props;
        return (
            <StaticPage
                title={intl.formatMessage({ id: 'Individuals.title' })}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'AboutPage',
                    description:
                        'Personas como tú que aman la naturaleza y la cuidan a través de nuestros planes.',
                    name: 'Regeneradores',
                }}
            >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={this.props.location.pathname}
                            user={this.props.user}
                            history={this.props.history}
                        />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain>
                        <Hero
                            title={<FormattedMessage id="Individuals.title" />}
                            backgroundPosition="right -50px"
                            subtitle={<FormattedMessage id="Individuals.subtitle" />}
                            image={heroimage}
                            isFriendsPage={true}
                        />

                        <section className={`${css.sectionContent} ${css.joinWrapper}`}>
                            <div className={css.jointext}>
                                <p>
                                    <FormattedMessage id="NewFriendsPage.Plans.Intro.subtitle" />
                                </p>
                            </div>
                            <h2 className={css.title}>
                                <FormattedMessage id="NewFriendsPage.Plans.Intro.title" />
                            </h2>
                            <div className={css.planType}>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            planType: 'personas',
                                        })
                                    }
                                    variant={
                                        this.state.planType === 'personas'
                                            ? 'filled'
                                            : 'invertedGhost'
                                    }
                                    testId="btn-friend-personal-plan"
                                >
                                    <FormattedMessage id="NewFriendsPage.Plans.Intro.PersonalBtn" />
                                </Button>
                                <Button
                                    variant={
                                        this.state.planType === 'familia'
                                            ? 'filled'
                                            : 'invertedGhost'
                                    }
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            planType: 'familia',
                                        })
                                    }
                                    testId="btn-friend-family-plan"
                                >
                                    <FormattedMessage id="NewFriendsPage.Plans.Intro.FamilyBtn" />
                                </Button>
                            </div>
                            <Link className={css.link} to="/organizations">
                                <FormattedMessage id="NewFriendsPage.Plans.Intro.RegBusLink" />
                            </Link>
                        </section>

                        <section id="become" className={css.plans}>
                            <div className={`${css.sectionContent} ${css.planWrapper}`}>
                                {/* <ProductComparison numerOfProducts={5}/> */}
                                <PlansComparison
                                    user={this.props.user}
                                    userType={this.state.planType}
                                    handlePlanSelection={this.handlePlanSelection}
                                    selectedPlan={this.state.selectedPlan}
                                    cart={this.state.cartPresent}
                                    handleCartStatus={this.handleCartStatus}
                                    testId="btn-friend-choose-plan"
                                />
                            </div>
                        </section>
                        <div ref={this.accountSetupRef}>
                            {this.state.isPlanSelected && (
                                <section
                                    className={`${css.sectionContent} ${css.planChooseSuccess}`}
                                >
                                    <h4>
                                        <FormattedMessage id="NewFriendsPage.Setup.Account.title" />
                                    </h4>
                                    {!this.props.user && (
                                        <h4>
                                            <FormattedMessage id="NewFriendsPage.Setup.Account.title.subText" />
                                        </h4>
                                    )}
                                </section>
                            )}
                        </div>

                        {this.state.isPlanSelected && (
                            <section className={`${css.sectionContent} ${css.accPaymentCont}`}>
                                <AccountSetup scrollToPayment={this.scrollToPayment} />
                                <div ref={this.paymentRef}>
                                    {this.props.user && (
                                        <StripeProvider stripe={this.state.stripe}>
                                            <Elements>
                                                <Payment
                                                    selectedPlan={this.state.selectedPlan}
                                                    handlePaymentStatus={this.handlePaymentStatus}
                                                    updateSubscriptionPlan={
                                                        this.updateSubscriptionPlan
                                                    }
                                                    supportLandscape={this.supportLandscape}
                                                    selectedLandScape={this.state.selectedLandScape}
                                                    handleLandscapeSelection={
                                                        this.handleLandscapeSelection
                                                    }
                                                    updatePlanID={this.updatePlanID}
                                                    planID={this.state.planID}
                                                    handleDisable={this.handleDisable}
                                                    isDisable={this.state.isDisable}
                                                />
                                            </Elements>
                                        </StripeProvider>
                                    )}
                                </div>
                            </section>
                        )}

                        <section ref={this.paymentSuccessRef}>
                            {this.state.paymentStatus && (
                                <PaymentSuccess
                                    currentSelectedPlan={this.state.selectedPlan}
                                    selectedLandScape={this.state.selectedLandScape}
                                    user={this.props.user}
                                    handleCartStatus={this.handleCartStatus}
                                    subscriptionId={this.state.subscriptionId}
                                />
                            )}
                        </section>

                        <section id="testimonials" className={css.testimony}>
                            <div className={css.sectionContent}>
                                <h3 className={css.testimonySubHeader}>
                                    <FormattedMessage id="NewFriendsPage.Testimonials.title" />
                                </h3>
                                <h2 className={css.testimonyTitle}>
                                    <FormattedMessage id="Testimonials.title.landingPage" />
                                </h2>
                                <div className={css.testimonyList}>
                                    <div className={css.testimonyListItem}>
                                        <img className={css.testimonyPic} src={herrera} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.alejandra.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.alejandra.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.alejandra.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div className={css.testimonyListItem}>
                                        <img className={css.testimonyPic} src={willy} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.guillermo.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.guillermo.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.guillermo.title" />
                                            </span>
                                        </p>
                                    </div>
                                    <div className={css.testimonyListItem}>
                                        <img className={css.testimonyPic} src={claudia} />
                                        <p className={css.testimonyDescription}>
                                            <FormattedMessage id="Testimonials.claudia.description" />
                                        </p>
                                        <p className={css.testimonyPeople}>
                                            <strong>
                                                <FormattedMessage id="Testimonials.claudia.name" />
                                            </strong>{' '}
                                            <span className={css.testimonyPeopleName}>
                                                {' '}
                                                -{' '}
                                                <FormattedMessage id="Testimonials.claudia.title" />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <UpButton />
                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer history={this.props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </StaticPage>
        );
    }
}

const mapStateToProps = storeState => {
    return {
        user: storeState.user.currentUser
            ? {
                  initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
                  name:
                      storeState.user.currentUser.attributes.profile.firstName +
                      ' ' +
                      storeState.user.currentUser.attributes.profile.lastName,
                  email: storeState.user.currentUser.attributes.email,
                  firstName: storeState.user.currentUser.attributes.profile.firstName,
                  lastName: storeState.user.currentUser.attributes.profile.lastName,
                  currentRole: storeState.user.currentUser.currentRole,
                  cartCount: storeState.user.currentUser.cartCount,
              }
            : null,
    };
};

export default connect(mapStateToProps)(injectIntl(withRouter(PeoplePage)));
