import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import StepSlider from 'react-slick';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import ImageWithText from '../../../../newComponents/ImageWithText';
import downBendedArrow from '../../../../assets/newAssets/down-bended-arrow.svg';
import workStep1 from '../../../../assets/newAssets/workstep1.webp';
import workStep2 from '../../../../assets/newAssets/workstep2.webp';
import workStep3 from '../../../../assets/newAssets/workstep3.webp';

import { HowitWorksSection } from './Styles';

const stepSettings = {
    responsive: [
        {
            breakpoint: 9999,
            settings: 'unslick',
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                speed: 300,
                infinite: false,
                adaptiveHeight: true,
            },
        },
    ],
};

const listingdata = [
    { textintro: <FormattedMessage id="PartnersPage.hwit.listingIntro" /> },
    {
        strongText: <FormattedMessage id="PartnersPage.hwit.productPartnerTitle" />,
        textinfo: <FormattedMessage id="PartnersPage.hwit.productPartnerInfo" />,
    },
    {
        strongText: <FormattedMessage id="PartnersPage.hwit.impactPartnerTitle" />,
        textinfo: <FormattedMessage id="PartnersPage.hwit.impactPartnerInfo" />,
    },
    {
        strongText: <FormattedMessage id="PartnersPage.hwit.landscapePartnerTitle" />,
        textinfo: <FormattedMessage id="PartnersPage.hwit.landscapePartnerInfo" />,
    },
];

const lastStepData = listingdata.map(list => {
    return (
        <>
            {list.textintro && <p>{list.textintro}</p>}
            <div className="li-list">
                <strong>{list.strongText}</strong> {list.textinfo}
            </div>
        </>
    );
});

const HowitWorks = ({ data }) => {
    return (
        <HowitWorksSection>
            <div className="title-box text-center">
                <h2>
                    <FormattedMessage id="PartnersPage.hwit.title" />
                </h2>
                <img src={downBendedArrow} className="hide-mobile arrow-img" alt="arrow" />
            </div>
            <div className="steps-how mx-auto relative">
                <StepSlider {...stepSettings}>
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep1}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="PartnersPage.hwit.step1" />}
                        firstTitle={<FormattedMessage id="PartnersPage.hwit.firstTitle" />}
                        text={<FormattedMessage id="PartnersPage.hwit.firstDesc" />}
                    />
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep2}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="PartnersPage.hwit.step2" />}
                        firstTitle={<FormattedMessage id="PartnersPage.hwit.secondTitle" />}
                        text={<FormattedMessage id="PartnersPage.hwit.secondDesc" />}
                    />
                    <ImageWithText
                        section="step-row"
                        imageDesktop={workStep3}
                        imgWithClass="img-text"
                        contentBox="text-section"
                        imgBoxClass="picture-box"
                        smallTitle={<FormattedMessage id="PartnersPage.hwit.step3" />}
                        firstTitle={<FormattedMessage id="PartnersPage.hwit.thirdTitle" />}
                        text={lastStepData}
                    />
                </StepSlider>
                <div className="faq-link">
                    <FormattedMessage id="PartnersPage.hwit.learnmore" />{' '}
                    <Link to="/faq">
                        <FormattedMessage id="PartnersPage.hwit.checkFaq" />
                    </Link>
                </div>
            </div>
            <div className="center-text-btn flex flex-col text-center">
                <h2>
                    <FormattedMessage id="PartnersPage.hwit.footprintCheck" />
                </h2>
                <OrangeBtn className="btnFootprint">
                    <Link to="/organisation/calculator">
                        <FormattedMessage id="PartnersPage.hwit.calculateFootprint" />
                    </Link>
                </OrangeBtn>
            </div>
        </HowitWorksSection>
    );
};

export default HowitWorks;
