
import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNav from '../../LeftNav/LeftNav';
import RightNav from '../../RightNav/RightNav';
import { Frame, MobileComp } from '../../../Styles';
import { changeLanguage } from '../../../../../ducks/Languages.duck';
import  Modal  from '../../../../../newComponents/Modal/modal';
import {activateRole, activateUserRole} from '../../../../../coreApi';

import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import {
    AccountRoleMain,
    RoleCardWrapper,
    SubRoleCardWrapper,
    titleStyle,
    descriptionStyle,
    imageContainer,
    container,
    textContainer,
    MainSubRoleWrapper,
    ModalSubTitle,
    ModalTitle,
    Error,

} from './Styles'

import ImageDescription from '../../../../../newComponents/ImageDescription/ImageDescription'
import partner from './../../../../../assets/newAssets/partner-activate-role.png';
import partnerSubrole1 from './../../../../../assets/newAssets/partner_subrole_1.png';
import partnerSubrole2 from './../../../../../assets/newAssets/partner_subrole_2.png';
import partnerSubrole3 from './../../../../../assets/newAssets/partner_subrole_3.png';
import ProfileImage from '../../../../../newComponents/ProfileImage/profileImage';
import HeroImage from '../../../../../assets/newAssets/friends_profile.png';
const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
            initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
            name:
                storeState.user.currentUser.attributes.profile.firstName +
                ' ' +
                storeState.user.currentUser.attributes.profile.lastName,
                currentRole: storeState.user.currentUser.currentRole,
                activatedRole : storeState.user.currentUser.activatedRoles
        }
        : null,

});

const ActivateAccount = connect(mapStateToProps, { changeLanguage })(({ location, history, user, intl }) => {

    const description = <FormattedMessage id="MyNaturePage.ActivateRole.Partner.Description" />;
    const title =  <FormattedMessage id="MyNaturePage.ActivateRole.Partner.Title" />;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState({value:false, message:""});
    const [isDisable, setIsDisable] = useState(false);

    const subRoles = [
        {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.1" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.1" />,
        image: partnerSubrole1,
        role: "partner",
        userRole:"landscape partner"
    },
    {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.2" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.2" />,
        image: partnerSubrole2,
        role: "partner",
        userRole:"product partner"
    },
    {
        title:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Title.3" />,
        description:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.SubRole.Description.3" />,
        image: partnerSubrole3,
        role: "partner",
        userRole:"impact partner"
    },
]
// const activateUserAccount = async(subRole) =>{
//     await activateUserRole(subRole)
//     .then(()=>{
        
//     },
//     (error) => {
//         const response = error.response.data.errors;
//         if(response === "user role already present"){
//             setError({
//                 value:true,
//                 message:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.UserRole.Error" />
//             });
//         }

//     }) 
// }

// const activateAccount = async (role, subRole) =>{

//     await activateRole(role)
//     .then (async (response)=>{
//         await activateUserAccount(subRole);
//     },
//     async (error)=>{
//         const response = error.response.data.errors;
//         if(response === "role already present"){
//             await activateUserAccount(subRole); 
//         }
//         else{
//             setError({
//                 value:true,
//                 message:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.Role.Error" />
//             });
//         }
//     });

// }

    const activateAccount = async (role, subRole) =>{
        setIsDisable(true);
        await activateRole(role)
        .then (async (response)=>{
            history.push('/profile-check?role=partner')
        },
        async (error)=>{
                setError({
                    value:true,
                    message:<FormattedMessage id="MyNaturePage.ActivateRole.Partner.Role.Error" />
                });
        });
    }
    
    const rolePresent = user && user.activatedRole.includes('partner');
    return (
        <Frame>
            <Header currentPath={location.pathname} user={user} history={history} />
            <LeftNav />
            <ProfileImage
                title={<>Friend profile<br/>& account</>}
                image={HeroImage}
            />
            <AccountRoleMain>
                <RoleCardWrapper>
                    <ImageDescription
                        src={partner}
                        description={description}
                        container={container}
                        titleStyle = {titleStyle}
                        descriptionStyle = {descriptionStyle}
                        imageContainer={imageContainer}
                        textContainer={textContainer}
                        title={title}
                        inverse={true}
                        button={true}
                        buttonDisable={rolePresent}
                        error={error}
                        isLoader={true}
                        isDisable={isDisable}
                        handleClick= {async () =>{
                            // setIsModalOpen(true);
                            await activateAccount('partner');
                        }}
                        buttonformattedMessageId={rolePresent?"MyNaturePage.ActivateRole.AccountPresent":"MyNaturePage.ActivateRole.ActivateAccount"}
                    />
                </RoleCardWrapper>
                <MobileComp><RightNav /></MobileComp>
            </AccountRoleMain>
            <RightNav />
            {/* <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
            >
                <MainSubRoleWrapper>
                    <ModalTitle>
                        {"Open a Partner account "}
                    </ModalTitle>
                    <ModalSubTitle>
                        {"Please select the Partner role that best suits your situation."}
                    </ModalSubTitle>
                {

                subRoles.map((data,index)=>{

                 return(  
                    <SubRoleCardWrapper>
                        <ImageDescription
                            src={data.image}
                            key={index}
                            description={data.description}
                            container={container}
                            titleStyle = {titleStyle}
                            descriptionStyle = {descriptionStyle}
                            imageContainer={imageContainer}
                            textContainer={textContainer}
                            title={data.title}
                            inverse={true}
                            button={true}
                            error={error}
                            handleClick= {async () =>{
                                await activateAccount(data.role, data.userRole);
                            }}
                            buttonformattedMessageId="MyNaturePage.ActivateRole.ActivateAccount"
                        />
                    </SubRoleCardWrapper>
                    )
                })
            }  
            </MainSubRoleWrapper>
            </Modal> */}
        </Frame>
    )
}
);
const ActivateAccountPage = compose(
    withRouter,
    connect(
        mapStateToProps,
    ),
    injectIntl
)(ActivateAccount);
export default ActivateAccountPage;
