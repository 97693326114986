import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import topTeam from '../../../../assets/newAssets/top-team.png';
import bottomTeam from '../../../../assets/newAssets/bottom-team.png';
import leftTeam from '../../../../assets/newAssets/left-team.png';
import rightTeam from '../../../../assets/newAssets/right-team.png';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';

import { TeamSection } from './Styles';

const Team = () => {
    return (
        <TeamSection bgGreenTexture={bgGreenTexture}>
            <div className="team-section flex justify-center flex-col">
                <div className="title-box mx-auto">
                    <div className="small-title">
                        <FormattedMessage id="About.team.label" />
                    </div>
                    <h3>
                        <FormattedMessage id="About.team.title" />
                    </h3>
                </div>
                <div className="content mx-auto">
                    <p>
                        <FormattedMessage id="About.team.message" />
                    </p>
                </div>
            </div>
            <div className="left-team">
                <picture>
                    <source media="(max-width:1023px)" srcset={topTeam} />
                    <img src={leftTeam} alt="team-img" />
                </picture>
            </div>
            <div className="right-team">
                <picture>
                    <source media="(max-width:1023px)" srcset={bottomTeam} />
                    <img src={rightTeam} alt="team-img" />
                </picture>
            </div>
        </TeamSection>
    );
};

export default Team;
