import styled from 'styled-components';

export const GuardianSection = styled.section`
    .green-bg {
        background: #004532 url(${({ bgGreenTexture }) => bgGreenTexture}) no-repeat center top;
        background-size: cover;
        overflow: hidden;
        .title-box {
            width: 110%;
            br {
                display: none;
            }
        }
        .text-white,
        .text-white p {
            color: white;
            .arrow-hero {
                position: absolute;
                gap: 5px;
                top: -56px;
                right: -110px;
                z-index: 99;
                .text {
                    font-size: 19px;
                }
            }
        }
        .cta {
            margin-top: 10px;
        }
        .right-section {
            max-width: 55%;
            flex: 1 0 auto;
            .isvideo {
                .video-section {
                    text-align: right;
                    height: 479px;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .green-bg {
            padding: 25px 0 0;
            gap: 0;
            flex-wrap: wrap;
            .title-box {
                width: auto;
                h1 {
                    br {
                        display: none;
                    }
                }
            }
            .text-white {
                .arrow-hero {
                    right: auto;
                    left: 20px;
                    top: auto;
                    bottom: -50px;
                }
            }
            .cta {
                position: relative;
                margin-top: 0;
            }
            .right-section {
                max-width: none;
                .isvideo {
                    display: flex;
                    justify-content: center;
                    .video-section {
                        max-width: 700px;
                        padding-right: 0;
                        margin-right: 20px;
                        text-align: right;
                        height: auto;
                        .thumbnail-box {
                            margin-bottom: 0;
                            top: 30px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .testimonial-box {
            .two-cols {
                display: block;
                .testimonal-information {
                    margin: 0 auto;
                    .button-box {
                        display: none;
                    }
                    h3.white-text {
                        text-align: center;
                        margin-bottom: 20px;
                        .border {
                            height: auto;
                        }
                    }
                }
            }
        }
    }
`;
