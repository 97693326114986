import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import css from './TermsOfService.css';

const TermsOfService = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Última actualización: 21 de octubre de 2019</p>

      <p>
      Estos Términos y Condiciones ("Términos", "Términos y Condiciones") rigen su relación con el sitio web <ExternalLink href={`${process.env.REACT_APP_BASE_URL}`} >{process.env.REACT_APP_BASE_URL}</ExternalLink> (el "Servicio") operado por Nature Services Peru SA ("nosotros", o "nuestro"), identificada con RUC 20385016582. Lea estos Términos y condiciones detenidamente antes de utilizar el Servicio. Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos Términos se aplican a todos los visitantes, usuarios y otras personas que acceden o utilizan el Servicio. Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, no podrá acceder al Servicio.

      </p>

      <h2>Suscripciones</h2>
      <p>Algunas partes del Servicio se facturan por suscripción ("Suscripciones"). Se le facturará por adelantado de forma periódica ("Ciclo de facturación"). Los ciclos de facturación se establecen trimestralmente, según el tipo de plan de suscripción que seleccione al comprar una suscripción.</p>
      <p>Al final de cada Ciclo de facturación, su Suscripción se renovará automáticamente bajo las mismas condiciones, a menos que la cancele o Nature Services Peru S.A. la cancele. Puede cancelar la renovación de su suscripción a través de la página de administración de su cuenta en línea o contactando al equipo de atención al cliente de Nature Services Peru S.A.</p>
      <p>Se requiere un método de pago válido,  para procesar el pago de su suscripción. Deberá proporcionar a Nature Services Peru S.A. información de facturación precisa y completa, incluido el nombre completo, la dirección, el estado, el código postal, el número de teléfono y una información de método de pago válida. Al enviar dicha información de pago, usted autoriza automáticamente a Nature Services Peru S.A. a cobrar todas las tarifas de suscripción incurridas a través de su cuenta a dichos instrumentos de pago.</p>
      <p>En caso de que no se produzca la facturación automática por algún motivo, Nature Services Peru S.A. emitirá una factura electrónica que indica que debe proceder manualmente, dentro de una fecha límite determinada, con el pago completo correspondiente al período de facturación indicado en la factura.</p>

      <h2>Cambios de tarifa</h2>
      <p>Nature Services Peru S.A., a su sola discreción y en cualquier momento, puede modificar las tarifas de Suscripción. Cualquier cambio en la tarifa de suscripción entrará en vigencia al final del ciclo de facturación actual.</p>
      <p>Nature Services Peru S.A.le proporcionará un aviso previo razonable de cualquier cambio en las tarifas de Suscripción para darle la oportunidad de cancelar su Suscripción antes de que dicho cambio entre en vigencia.</p>
      <p>Su uso continuo del Servicio después de que el cambio en la tarifa de Suscripción entre en vigencia constituye su aceptación de pagar el monto de la tarifa de Suscripción modificada.</p>

      <h2>Reembolsos</h2>
      <p>Excepto cuando lo exija la ley, las tarifas de suscripción pagadas no son reembolsables.</p>

      <h2>Contenido</h2>
      <p>Nuestro Servicio le permite publicar, vincular, almacenar, compartir y de otra manera poner a disposición cierta información, texto, gráficos, videos u otro material ("Contenido"). Usted es responsable del Contenido que publique en el Servicio, incluida su legalidad, confiabilidad y adecuación.</p>
      <p>Al publicar Contenido en el Servicio, nos otorga el derecho y la licencia para usar, modificar, realizar, mostrar, reproducir y distribuir dicho Contenido en el Servicio y a través de él. Usted conserva todos y cada uno de sus derechos sobre cualquier Contenido que envíe, publique o muestre en o a través del Servicio y Usted es responsable de proteger esos derechos.</p>
      <p>Usted declara y garantiza que: (i) el Contenido es suyo (Usted lo posee) o tiene derecho a usarlo y nos otorga los derechos y la licencia según lo dispuesto en estos Términos, y (ii) la publicación de su Contenido en o a través del Servicio no viola los derechos de privacidad, derechos de publicidad, derechos de autor, derechos contractuales o cualquier otro derecho de cualquier persona.</p>

      <h2>Cuenta de Usuario</h2>
      <p>Cuando crea una cuenta de usuario (“Cuenta”) con nosotros, debe proporcionarnos información precisa, completa y actualizada en todo momento. No hacerlo constituye una violación de los Términos, lo que puede resultar en la terminación inmediata de su cuenta en nuestro Servicio.</p>
      <p>Usted es responsable de salvaguardar la contraseña que utiliza para acceder al Servicio y de cualquier actividad o acción bajo su contraseña, ya sea que se encuentre en nuestro Servicio o en un servicio de un tercero.</p>
      <p>Usted acepta no revelar su contraseña a ningún tercero. Debe notificarnos de inmediato al darse cuenta de cualquier violación de seguridad o uso no autorizado de su cuenta.</p>
      <p>No puede utilizar como nombre de usuario el nombre de otra persona o entidad o que no esté legalmente disponible para su uso, un nombre o marca comercial que esté sujeto a los derechos de otra persona o entidad que no sea usted sin la autorización correspondiente, o un nombre que es de otro modo ofensivo, vulgar u obsceno.</p>

      <h2>Propiedad intelectual</h2>
      <p>El Servicio y su contenido original (excluyendo el Contenido proporcionado por los usuarios), las características y la funcionalidad son y seguirán siendo propiedad exclusiva de Nature Services Peru S.A. y sus licenciantes. El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto del Perú como de países extranjeros. Nuestras marcas comerciales y nuestra imagen comercial no pueden utilizarse en relación con ningún producto o servicio sin el consentimiento previo por escrito de Nature Services Peru S.A.</p>

      <h2>Enlaces a otros sitios web</h2>
      <p>Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por Nature Services Peru S.A. Nature Services Peru S.A. no tiene control ni asume responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros. Además, reconoce y acepta que Nature Services Peru SA no será responsable, directa o indirectamente, de ningún daño o pérdida causada o supuestamente causada por o en relación con el uso o la dependencia de dicho contenido, bienes o servicios disponibles. en oa través de dichos sitios web o servicios.</p>
      <p>Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de los sitios web o servicios de terceros que visite.</p>

      <h2>Terminación</h2>
      <p>Podemos cancelar o suspender su cuenta de inmediato, sin previo aviso o responsabilidad, por cualquier motivo, incluyendo, entre otros, si usted no cumple con los Términos.</p>
      <p>Al finalizar, su derecho a utilizar el Servicio cesará inmediatamente. Si desea cancelar su cuenta, simplemente puede dejar de usar el Servicio.</p>

      <h2>Ley que rige</h2>
      <p>Estos Términos se regirán e interpretarán de conformidad con las leyes del Perú, sin tener en cuenta sus disposiciones sobre conflictos de leyes.</p>
      <p>Nuestro incumplimiento de cualquier derecho o disposición de estos Términos no se considerará una renuncia a esos derechos. Si alguna disposición de estos Términos se considera inválida o inaplicable por un tribunal, las disposiciones restantes de estos Términos seguirán vigentes. Estos Términos constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio, y reemplazan y reemplazan cualquier acuerdo anterior que podamos tener entre nosotros con respecto al Servicio.</p>

      <h2>Cambios</h2>
      <p>Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, intentaremos proporcionar un aviso de al menos 30 días antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a nuestro exclusivo criterio.</p>
      <p>Al continuar accediendo o utilizando nuestro Servicio después de que esas revisiones entren en vigencia, usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, deje de usar el Servicio.</p>

      <h2>Contáctenos</h2>
      <p>Si tiene alguna pregunta sobre estos Términos, contáctenos.</p>

    </div>
  );
};

TermsOfService.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
    rootClassName: string,
    className: string,
};

export default TermsOfService;
