import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import * as turf from '@turf/turf';
import { AreaTitle, AreaDetails, NoData } from '../Styles';
import { getGuardianLandscapes, getGuardianShapefiles } from '../../../../../../coreApi';

const dotenv = require('dotenv').config();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapContainer = styled.div`
    @media screen and (max-width: 768px) {
        width: 100%;
        aspect-ratio: 1 / 2;
    }
    & .mapboxgl-marker {
        cursor: pointer;
    }
`;

const MapShapefile = props => {
    const landscapes = {
        Abancay: 'https://s3.amazonaws.com/production.regenera.pe/paisaje_abancay.geojson',
        Cañete: 'https://s3.amazonaws.com/production.regenera.pe/paisaje_canete.geojson',
        Manu: 'https://s3.amazonaws.com/production.regenera.pe/paisaje_manu.geojson',
        Requena: 'https://s3.amazonaws.com/production.regenera.pe/paisaje_requena.geojson',
        'Machu Picchu': 'https://s3.amazonaws.com/production.regenera.pe/paisaje_urubamba.geojson',
    };

    const mapContainer = useRef(null);
    const map = useRef(null);

    const [zoom, setZoom] = useState(8.5);
    const [guardianArea, setGuardianArea] = useState();
    const [landscapeArea, setLandscapeArea] = useState();
    const [show, setShow] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await getGuardianLandscapes();
            if (response.data.right) {
                setShow(true);
            }
            const shapefileResp = await getGuardianShapefiles();

            await fetch(shapefileResp.data[0].file)
                .then(async response => await response.json())
                .then(shapefile => turf.polygon(shapefile.features[0].geometry.coordinates[0]))
                .then(polygon => turf.centroid(polygon).geometry.coordinates)
                .then(async centroid => {
                    const response = await getGuardianLandscapes();

                    if (!map.current) {
                        map.current = new mapboxgl.Map({
                            container: mapContainer.current,
                            style: 'mapbox://styles/mapbox/light-v9',
                            center: [centroid[0], centroid[1]],
                            zoom: zoom,
                            interactive: true,
                        });
                        map.current.addControl(new mapboxgl.NavigationControl());
                    }

                    map.current.on('load', () => {
                        if (
                            !map.current.getSource('maine') &&
                            !map.current.getSource('landscape')
                        ) {
                            map.current.addSource('maine', {
                                type: 'geojson',
                                data: shapefileResp.data[0].file,
                            });
                            map.current.addLayer({
                                id: 'area',
                                type: 'fill',
                                source: 'maine', // reference the data source
                                paint: {
                                    'fill-color': '#006DB2',
                                    'fill-opacity': 0.4,
                                },
                            });
                            map.current.addLayer({
                                id: 'outline',
                                type: 'line',
                                source: 'maine',
                                paint: {
                                    'line-color': '#006DB2',
                                    'line-width': 2,
                                },
                            });
                            map.current.addSource('landscape', {
                                type: 'geojson',
                                data: landscapes[response.data.right[0].name],
                            });
                            map.current.addLayer({
                                id: 'outlinelandscape',
                                type: 'line',
                                source: 'landscape',
                                paint: {
                                    'line-color': '#000000',
                                    'line-width': 2,
                                },
                            });
                        }
                    });
                })
                .catch(error => console.error(error));

            return () => {
                map.current.remove();
            };
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getGuardianShapefiles();
            if (response.data) {
                await fetch(response.data[0].file)
                    .then(async response => await response.json())
                    .then(shapefile => turf.polygon(shapefile.features[0].geometry.coordinates[0]))
                    .then(polygon => setGuardianArea((turf.area(polygon) / 10000).toFixed(2)))
                    .catch(error => console.error(error));
            }
            return () => {
                map.current.remove();
            };
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getGuardianLandscapes();
            if (response.data.right) {
                let land = response.data.right[0].name;
                fetch(landscapes[land])
                    .then(response => response.json())
                    .then(shapefile => turf.polygon(shapefile.features[0].geometry.coordinates[0]))
                    .then(polygon => setLandscapeArea((turf.area(polygon) / 10000).toFixed(2)))
                    .catch(error => console.error(error));
            }
            return () => {
                map.current.remove();
            };
        })();
    }, []);

    return (
        <div>
            {show ? (
                <>
                    <MapContainer>
                        <div style={{ height: 550 }} ref={mapContainer} />
                    </MapContainer>
                    <AreaTitle>
                        <FormattedMessage id="NewGuardiansPage.Landscape.hectare" />
                    </AreaTitle>
                    <div style={{ marginTop: 20, marginBottom: 50 }}>
                        <AreaDetails>
                            <FormattedMessage id="NewGuardiansPage.Landscape.landscapeArea" />
                            {landscapeArea} ha
                        </AreaDetails>
                        <AreaDetails>
                            {props.username}
                            <FormattedMessage id="NewGuardiansPage.Landscape.landArea" />
                            {guardianArea} ha
                        </AreaDetails>
                    </div>
                </>
            ) : (
                <NoData>
                    <FormattedMessage id="NewGuardiansPage.Landscape.noLandData" />
                </NoData>
            )}
        </div>
    );
};

export default MapShapefile;
