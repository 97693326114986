import styled from 'styled-components';
import { colors } from '../../../../theme';

export const Error = styled.div`
    color:#ff0000;
    text-align:center;
    padding:20px 0px;
`;
export const AccountContainer = styled.div`
padding:15px 10px 15px 10px;
height:auto;
width:100%;
box-sizing: border-box;
border-radius:10px;
@media screen and (max-width: 768px) {
    height:auto;
}
`;

export const Holder = styled.div`
    margin-right:20px;
    width: 100%;
    position:relative;
    ${({ status }) =>
        status === 'failure' &&
        `
      & > input:focus, input:hover {
        border-color: ${colors.pink} !important;
        }
    `}
    @media screen and (max-width: 768px) {
        margin-bottom:15px;
        margin-right:0px;
    }
`;

export const Form = styled.span`
    display:flex;
    margin:52px auto;
    width:965px;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 973px) {
        width:100%;
        justify-content:space-between;
    }
`;

export const Row = styled.div`
    display:flex;
    justify-content:space-between;

    &:nth-child(2) {
        & ${Holder}:nth-child(1){
            width:20%
        }
        & ${Holder}:nth-child(2){
            width:20%
        }
        & ${Holder}:nth-child(3){
            width:60%;
        }
      }

      &:nth-child(3) {
        & ${Holder}:nth-child(1){
            width:60%
        }
        & ${Holder}:nth-child(2){
            width:40%
        }
      }

      &:nth-child(4) {
        margin-top:30px;
      }

    @media screen and (max-width: 768px) {
        width: 95%;
        display:flex;

        &:nth-child(2) {
            & ${Holder}:nth-child(1){
                width:100%
            }
            & ${Holder}:nth-child(2){
                width:100%
            }
            & ${Holder}:nth-child(3){
                width:100%;
            }
          }
    
          &:nth-child(3) {
            & ${Holder}:nth-child(1){
                width:100%
            }
            & ${Holder}:nth-child(2){
                width:100%
            }
          }
    }
    @media screen and (max-width: 768px) {
        width: 95%;
        display:flex;
        flex-direction: column;
    }
`;
export const InputButton = styled.div`
    position:relative;
    box-sizing: border-box;
    border-radius: 2px;
    cursor:pointer;
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #A9A9A9;
    padding:10px;
    .arrow{
        position:absolute;
        top:20px;
        right:20px;
        fill:none;
    }
`;
export const Label = styled.div`
    font-family: 'Galano Grotesque';
    margin:11px 0px 9px 0px;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    color: #4A5568;
`;
export const Input = styled.input`
    padding:10px;
    width: 100%;
    height: 57px;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 5px;
`;
export const InputBox = styled.textarea`
    margin-bottom:20px;
    height:120px;
    padding:15px;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 5px;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const ButtonContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  box-sizing:border-box;
  & > Button{
      float: right;
      height:71px;
      margin-right:20px;
      background: #F29509;
      border-radius: 5px;
      width:365px;
      font-family: 'Galano Grotesque';
      font-style: normal;
      font-weight: bold;
      font-size: 23.1858px;
      line-height: 31px;
  }
  @media screen and (max-width: 840px) {
    justify-content:center;
  }
`;

export const Dropdown = styled.ul`
    text-decoration: none;
    text-align: left;
    position: absolute;
    top:100%;
    left:0px;
    margin-top:-2px;
    background-color:white;
    font-family: 'Galano Grotesque';
    z-index: 200;
    width:100%;
    cursor:pointer;
    height: 200px;
    overflow-y:scroll;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;
export const DropdownItem = styled.li`
    text-decoration: none;
    text-align: left;
    position: relative;
    padding:10px;
    cursor:pointer;
    font-family: 'Galano Grotesque';
    &:hover {
        background-color: #C5C5C5;
    }
    @media screen and (max-width: 500px) {
        position: relative;
        width:auto;
    }
`;