import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    Page,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import * as coreAPI from '../../coreApi';
import { richText } from '../../util/richText';
import css from './ListingPage.css';
import ListingStorieContent from './ListingStorieContent';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const ActionPage = props => {
    console.log('ActionPage Props', props);

    const actionSlug = props.params.slug;

    const [loadProgress, setLoadProgress] = useState(true);
    const [actionData, setActionData] = useState(null);

    const scrollingDisabled = false;

    useEffect(() => {
        coreAPI
            .getActionDetails(actionSlug)
            .then(response => {
                console.log(response.data);
                setActionData(response.data);
                setLoadProgress(false);
            })
            .catch(error => {
                setLoadProgress(false);
            });
    }, [actionSlug]);

    if (loadProgress == false && actionData === null) {
        return <NotFoundPage />;
    }

    const topbar = <TopbarContainer theme="light"></TopbarContainer>;

    if (loadProgress === true) {
        const loadingTitle = '';
        // const loadingTitle = intl.formatMessage({
        //    id: 'ListingPage.loadingListingTitle',
        //   });
        return (
            <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
                <LayoutSingleColumn className={css.pageRoot}>
                    <LayoutWrapperTopbar>
                        <Header
                            currentPath={props.location.pathname}
                            user={props.user}
                            history={props.history}
                        />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <p className={css.loadingText}>
                            <FormattedMessage id="ListingPage.loadingListingMessage" />
                        </p>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer history={props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }

    // =======
    const title = actionData != null ? actionData.title : '';
    const schemaTitle = actionData != null ? actionData.title : '';

    const authorDisplayName = '';
    const description = actionData && actionData.description;
    const facebookImages = actionData && (actionData.image ? [actionData.image] : []);
    const twitterImages = actionData && (actionData.image ? [actionData.image] : []);
    const schemaImages = actionData && (actionData.image ? [actionData.image] : []);
    const organizationName = '';
    // =======

    const richTitle = (
        <span>
            {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                longWordClass: css.longWord,
            })}
        </span>
    );

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={scrollingDisabled}
            author={authorDisplayName}
            contentType="website"
            description={description}
            facebookImages={facebookImages}
            twitterImages={twitterImages}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'ItemPage',
                description: description,
                name: schemaTitle,
                image: schemaImages,
            }}
        >
            <LayoutSingleColumn className={css.pageRoot}>
                <LayoutWrapperTopbar>
                    <Header
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain>
                    <div className={css.storyLayout}>
                        <div className={css.hero}>
                            <img
                                className={css.sectionImages}
                                src={actionData.image}
                                alt={actionData.name}
                            ></img>
                            {/* <div className={css.sectionContentHeading}>
                  </div>   */}
                        </div>
                        <div className={css.contentContainer}>
                            <ListingStorieContent
                                title={richTitle}
                                content={description}
                                currentListing={actionData}
                                onContactUser={() => {}}
                                isEnquiryModalOpen={false}
                                onCloseEnquiryModal={() => {}}
                                sendEnquiryError={() => {}}
                                sendEnquiryInProgress={() => {}}
                                onSubmitEnquiry={() => {}}
                                currentUser={{}}
                                onManageDisableScrolling={() => {}}
                                organizationName={organizationName}
                            />
                        </div>
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer history={props.history} />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(injectIntl(ActionPage)));
