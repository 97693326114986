import styled from 'styled-components';

export const StyledContiner = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 81px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 70px;
    }
`;
export const ImageContainer = styled.div`
    width: 48%;
    height: auto;
    @media screen and (max-width: 800px) {
        width: 80%;
        margin-bottom: 35px;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const TextContainer = styled.div`
    width: 48%;
    @media screen and (max-width: 800px) {
        width: 80%;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
export const Title = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 38.4px;
    line-height: 52px;
    margin-bottom: 10px;
    color: #000000;
    text-align: left;
    @media screen and (max-width: 500px) {
        font-size: 20px;
        line-height: 25px;
    }
`;
export const Description = styled.div`
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #000000;
`;

export const ButtonContainer = styled.div`
    width: 80%;
    text-align: left;
    margin-top: 30px;
    & > button {
        width: 100%;
    }
`;

export const Error = styled.div`
    color: #ff0000;
`;
