import React from 'react';
import { FormattedMessage } from 'react-intl';
import {Exclamation, FactContainer } from './Styles.js';
import Fact from '../../../../assets/calculator/exclamation.svg';
const Tabs=(props)=>{
    return(
        <>
        <FactContainer>
        <Exclamation src={Fact}/>
        <FormattedMessage id="CalculatorPage.region.fact"></FormattedMessage>
      </FactContainer>
        </>
    );
}

export default Tabs;