import React,{Component} from 'react';
import { FaChevronUp } from "react-icons/fa";
import css from './Upbutton.css'

function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function(){
      if ( window.scrollY !== 0 ) {
          window.scrollBy( 0, scrollStep );
      }
      else clearInterval(scrollInterval); 
  },15);
}


export class UpButton extends Component {
  render(){
    return(
      <button className={css.upButton} onClick={()=>scrollToTop(500)} id='scrollButton'>
        <FaChevronUp/>
      </button>
    )
  }
}

export default UpButton